<div
  #avatarBox
  #tourAvatar="appTour"
  *transloco="let t; read: 'avatar'"
  class="avatar-box"
  [appTour]="isMe ? OfficeTourSteps.avatar.identifier : OfficeTourSteps.noop.identifier"
  [tourContext]="tourContext"
  [tourDisablePointerEvents]="tourAvatar.isActive"
  [tourEnabled]="enableTour"
  [tourHighlightAtOtherSteps]="tourHighlightAtOtherSteps"
  [tourScrollElementIntoView]="{ nativeElement: avatarBox }"
  [tourTooltip]="tourAvatar.isActive ? tourTooltip : avatarHover"
  [tourTooltipBackgroundColor]="tourAvatar.isActive ? 'white' : 'var(--bnear-light-bg)'"
  [tourTooltipBorderRadius]="tourAvatar.isActive ? '10px' : 'scale-by-office(1)'"
  [tourTooltipCloseTrigger]="closeAvatarHover"
  [tourTooltipDirection]="AvatarHoverDirections"
  [tourTooltipDisabled]="!enableHover || (tourAvatar.isActive && !tourAvatar.showTourTooltip)"
  [tourTooltipFadeInAnimationDelayMs]="tourAvatar.isActive ? undefined : isMe ? 150 : 50"
  [tourTooltipIgnoreCdkOverlayOutsideHover]="true"
  [tourTooltipMaxWidth]="tourAvatar.isActive ? '500px' : 'scale-by-office(25, $min-value: 250px, $max-value: 350px)'"
  [tourTooltipMinWidth]="tourAvatar.isActive ? 'scale-by-office(50)' : undefined"
  [tourTooltipOpen]="
    (tourAvatar.showTourTooltip && isMe) || (showAutoCheckInHover && !tourAvatar.isActive ? true : undefined)
  "
  [tourTooltipOpenDelayMs]="showAutoCheckInHover ? 1250 : isMe ? 100 : 50"
  [tourTooltipPadding]="tourAvatar.isActive ? 'scale-by-office(3)' : '0'"
  [tourTooltipPinColor]="hoverPinColor"
  [tourTooltipPinSize]="tourAvatar.isActive ? 'scale-by-office(1.2)' : undefined"
  [tourTooltipZIndex]="'high'"
  (dblclick)="onAvatarDoubleClick()"
  (pointerenter)="disableEmojiHint()"
  (tourTooltipOpenChange)="onAvatarHoverOpen($event)"
  (tourTooltipOutsideClick)="hideAutoCheckInHover()"
  (tourTooltipPositionChange)="onAvatarHoverPositionChange($event)"
>
  <div
    *ngIf="initialsSize && (isMeetingGuest || isAnonymized || initials || userOrParticipant?.avatar || fallbackAvatar)"
    class="faux-border"
    [ngClass]="fauxBorderColor"
    [ngStyle]="{
      'border-width':
        (isMe && initialsSize / 8 >= 2
          ? initialsSize / 8
          : !isMe && initialsSize / 12 >= 2
            ? initialsSize / 12
            : 2
        ).toString() + 'px',
      'box-shadow':
        isMe && showBounceAnimation ? '0 0 ' + (initialsSize / 2).toString() + 'px 0 rgb(0 0 0 / 50%)' : 'unset'
    }"
  ></div>

  <div
    *ngIf="!isMeetingGuest && !isAnonymized && !initials && !(userOrParticipant?.avatar || fallbackAvatar)"
    class="empty"
  ></div>

  <div *ngIf="initials && initialsSize && !(userOrParticipant?.avatar || fallbackAvatar)" class="initials">
    <span [ngStyle]="{ 'font-size': (initialsSize / 1.2).toString() + 'px' }">{{ initials | uppercase }}</span>
  </div>

  <img
    *ngIf="userOrParticipant?.avatar || fallbackAvatar"
    class="avatar"
    [src]="userOrParticipant?.avatar || fallbackAvatar"
  />

  <div *ngIf="isMeetingGuest && initialsSize" class="meeting-guest">
    <img src="assets/img/icons/meeting-guest.svg" />
    <span
      [ngStyle]="{
        'font-size': (initialsSize / 3.5).toString() + 'px',
        bottom: (initialsSize / 5).toString() + 'px'
      }"
      >{{ t("meetingGuest") | uppercase }}</span
    >
  </div>
  <div *ngIf="isAnonymized && initialsSize" class="anonymized">
    <img src="assets/img/icons/anonymized.svg" />
  </div>

  <div *ngIf="showWaveAnimation" class="wave-animation-container">
    <img class="wave-animation" src="assets/animations/call-wave-purple.gif" />
  </div>

  <app-notification-badge
    *ngIf="enableNotificationBadge && unreadMessageCount > 0 && initialsSize"
    [count]="unreadMessageCount"
    [ngStyle]="{ 'font-size': (initialsSize / 2.5).toString() + 'px' }"
  />

  <app-status-badge
    *ngIf="
      (avatarConfig?.enableStatus ?? enableStatus) &&
      unreadMessageCount <= 0 &&
      userOrParticipant?.statusMessage &&
      (statusHasBeenRead$ | async) === false &&
      initialsSize &&
      !isMe
    "
    [@fadeInOut]="{ value: true, params: { fadeInMs: 200, fadeOutMs: 200 } }"
    [unread]="true"
  ></app-status-badge>

  <div
    *ngIf="showLicenseInvalidBadge && initialsSize"
    class="icon-badge license-invalid"
    [ngStyle]="{
      width: (initialsSize / 1.2).toString() + 'px',
      height: (initialsSize / 1.2).toString() + 'px'
    }"
  >
    <app-circled-cross />
  </div>

  <div
    *ngIf="
      availability &&
      (showInACallBadge ||
        showDoNotDisturbBadge ||
        showOutOfOfficeBadge ||
        showBeRightBackBadge ||
        showPresentingBadge) &&
      (!showInOtherRoomBadge || !showOutOfOfficeBadge) &&
      initialsSize
    "
    class="icon-badge"
    [ngStyle]="{
      width: (initialsSize / 1.5).toString() + 'px',
      height: (initialsSize / 1.5).toString() + 'px'
    }"
  >
    <app-presence-icon
      fill="white"
      [backgroundColor]="
        showOutOfOfficeBadge
          ? 'var(--teams-magenta)'
          : showBeRightBackBadge
            ? 'var(--teams-yellow)'
            : 'var(--teams-red)'
      "
      [iconWithinCircle]="true"
      [kind]="showOutOfOfficeBadge ? 'outOfOffice' : availability"
    ></app-presence-icon>
  </div>

  <div
    *ngIf="showInOtherRoomBadge && initialsSize"
    class="icon-badge in-other-room"
    [ngStyle]="{
      width: (initialsSize / 1.5).toString() + 'px',
      height: (initialsSize / 1.5).toString() + 'px'
    }"
  >
    <app-otr-icon fill="white" />
  </div>

  <div
    *ngIf="showVisitorBadge && initialsSize"
    class="text-badge"
    [ngStyle]="{ 'font-size': (initialsSize / 3.5).toString() + 'px' }"
  >
    {{ t("visitor") | uppercase }}
  </div>

  <div
    *ngIf="showEmoji && initialsSize"
    class="emoji-container"
    [ngClass]="{ 'hide-emoji': hoverOverlayOpen && isMe, 'enlarge-emoji': hoverOverlayOpen && !isMe }"
  >
    <div *ngIf="isMe || selectedEmoji" class="box">
      <div class="background"></div>

      <app-emoji
        #emoji
        [appTooltip]="t('emojiHint')"
        [appTooltipAutoCloseEnabled]="true"
        [appTooltipAutoCloseTimeMs]="30000"
        [appTooltipBounceAnimation]="true"
        [appTooltipOpen]="
          hintService.currentHint === HintType.Emoji && (avatarConfig?.enableEmojiHint ?? enableEmojiHint) && isMe
        "
        [emoji]="(selectedEmoji || '😃' | getEmoji)!"
        [emojiStyle]="hoverOverlayOpen ? 'Animated' : '3D'"
        [enableTitle]="false"
        [ngClass]="{ 'greyed-out': !selectedEmoji }"
        (appTooltipOpenChange)="showNextHint($event)"
      />
    </div>
  </div>
</div>

<ng-template #tourTooltip>
  <app-tour-tooltip [stepId]="OfficeTourSteps.avatar.identifier" [tourId]="'office'" />
</ng-template>

<ng-template #avatarHover>
  <app-avatar-hover
    [availability]="availability"
    [displayName]="userOrParticipant?.displayName ?? fallbackDisplayName"
    [enableAvailabilityBadge]="!showLicenseInvalidBadge"
    [enableEmojiChange]="avatarConfig?.enableEmojiChange ?? true"
    [enableOutOfOfficeStatus]="showOutOfOfficeBadge"
    [enablePresenceChange]="avatarConfig?.enablePresenceChange ?? true"
    [enableSchedule]="enableSchedule"
    [enableTimelinePlanning]="enableTimelinePlanning"
    [firstName]="userOrParticipant?.firstName || fallbackFirstName"
    [hasStatusBeenRead]="(statusHasBeenRead$ | async)!"
    [isAnonymized]="isAnonymized"
    [isMe]="isMe"
    [jobTitle]="userOrParticipant?.jobTitle"
    [lastName]="userOrParticipant?.lastName || fallbackLastName"
    [myCall]="myCall"
    [otherRoomInfo]="otherRoomInfo"
    [outOfOfficeSettings]="userOrParticipant?.outOfOfficeSettings"
    [selectedEmoji]="selectedEmoji"
    [showAutoCheckInHover]="showAutoCheckInHover"
    [showCalendarButton]="showCalendarButton"
    [showCallButton]="showCallButton"
    [showChatButton]="avatarConfig?.enableChatOpening ?? showChatButton"
    [showInOtherRoomBadge]="showInOtherRoomBadge"
    [showInviteButton]="showInviteButton"
    [showJobTitle]="avatarConfig?.enableJobTitle ?? true"
    [showLicenseInvalidBadge]="showLicenseInvalidBadge"
    [showSendChatMessage]="showSendChatMessage"
    [showStatusMessage]="avatarConfig?.enableStatus ?? enableStatus"
    [showWorkLocation]="showWorkLocation"
    [statusMessage]="userOrParticipant?.statusMessage"
    [unreadChatMessageCount]="unreadMessageCount"
    [userEmailAddress]="userOrParticipant?.emailAddress"
    [userObjectId]="userObjectId!"
    [(individualWorkLocation)]="individualWorkLocation"
    [(workLocation)]="workLocation"
    (badgeColor)="hoverBadgeColor = $event"
    (insideOutsideHover)="hideAutoCheckInHover()"
    (openedChatViaHover)="closeAvatarHover = true"
    (readStatusChange)="markStatusAsRead()"
    (statusChange)="userOrParticipant!.statusMessage = $event"
  />
</ng-template>
