import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { OnlineMeeting as MSGraphOnlineMeeting } from '@microsoft/microsoft-graph-types';

import { UserService } from './user.service';

import { CallContext } from '../models/services/call.model';
import { HideMeetingInput } from '../models/services/dialog.model';
import { OnlineMeeting } from '../models/services/online-meeting.model';

import { parseArrayToQueryString } from '../utils/misc.util';
import { createOnlineMeeting } from '../utils/services/online-meeting.util';

import { GRAPH_API_BASE } from '../constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OnlineMeetingService {
  private readonly http = inject(HttpClient);
  private readonly userService = inject(UserService);

  // Create an online meeting
  public create(
    callContext: CallContext,
    subject: string,
    teamObjectId: string,
    // Invited users are not rang, that is a different operation
    inviteUserObjectIds?: string[],
    index?: number,
  ): Observable<MSGraphOnlineMeeting> {
    const error = this.isValidCreateRequest(callContext, inviteUserObjectIds, index, subject);
    if (error) throw error;

    const onlineMeeting = createOnlineMeeting(subject, inviteUserObjectIds);

    return this.http.post<MSGraphOnlineMeeting>(`${GRAPH_API_BASE}/me/onlineMeetings`, onlineMeeting).pipe(
      concatMap((onlineMeeting) =>
        this.http
          .post(`${environment.backendUrl}/api/call`, {
            teamObjectId,
            context: callContext,
            joinWebUrl: onlineMeeting.joinWebUrl,
            index,
            subject,
            ...(callContext === CallContext.ImpromptuMeeting && {
              initialInviteUserObjectId: inviteUserObjectIds![0],
              organizerUserObjectId: this.userService.userInfo!.user.objectId,
            }),
          })
          .pipe(
            map(() => {
              onlineMeeting.joinWebUrl = decodeURI(onlineMeeting.joinWebUrl!);
              return onlineMeeting;
            }),
          ),
      ),
    );
  }

  // Note that if there are duplicated OnlineMeetings, only one instance will be returned
  public getOnlineMeetings(
    joinWebUrls: string[],
    iCalUIds: string[],
    includeShouldBeIgnored = false,
  ): Observable<OnlineMeeting[]> {
    const uniqueJoinWebUrls = [...new Set(joinWebUrls)];
    const uniqueICalUIds = [...new Set(iCalUIds)];

    const chunkSize = 20;

    // If the request fits in one chunk, send it as one request
    const requestFitsInOneChunk = uniqueJoinWebUrls.length + uniqueICalUIds.length <= chunkSize;
    if (requestFitsInOneChunk) {
      const joinWebUrls = parseArrayToQueryString(uniqueJoinWebUrls, 'joinWebUrls');
      const iCalUIds = parseArrayToQueryString(uniqueICalUIds, 'iCalUIds');

      return this.http.get<OnlineMeeting[]>(
        `${environment.backendUrl}/api/online-meetings?${joinWebUrls}&${iCalUIds}&includeShouldBeIgnored=${includeShouldBeIgnored}`,
      );
    }

    // If the request does not fit in one chunk, split it into multiple requests
    const requests: Observable<OnlineMeeting[]>[] = [];

    const missingKey: { [key: string]: string } = {
      joinWebUrls: 'iCalUIds',
      iCalUIds: 'joinWebUrls',
    };

    const createRequests = (data: string[], key: string) => {
      for (let index = 0; index < data.length; index += chunkSize) {
        const mappedData = parseArrayToQueryString(data.slice(index, index + chunkSize), key, missingKey[key]);

        requests.push(
          this.http.get<OnlineMeeting[]>(
            `${environment.backendUrl}/api/online-meetings?${mappedData}&includeShouldBeIgnored=${includeShouldBeIgnored}`,
          ),
        );
      }
    };

    createRequests(uniqueJoinWebUrls, 'joinWebUrls');
    createRequests(uniqueICalUIds, 'iCalUIds');

    return requests.reduce((acc, curr) =>
      acc.pipe(
        concatMap((currentOnlineMeetings) =>
          curr.pipe(map((onlineMeetings) => [...currentOnlineMeetings, ...onlineMeetings])),
        ),
      ),
    );
  }

  public getOrCreateOnlineMeeting(data: {
    eventId?: string | null;
    iCalUId?: string | null;
  }): Observable<OnlineMeeting> {
    return this.http.post<OnlineMeeting>(`${environment.backendUrl}/api/online-meetings`, data);
  }

  public hide(data: HideMeetingInput): Observable<void> {
    const createHideRequest = (iCalUId?: string | null) =>
      this.http.put<void>(`${environment.backendUrl}/api/online-meetings/hide`, {
        joinWebUrl: data.joinWebUrl ? decodeURIComponent(data.joinWebUrl) : null,
        iCalUId: iCalUId,
      });

    if (!data.joinWebUrl) {
      return this.getOrCreateOnlineMeeting({ eventId: data.eventId, iCalUId: data.iCalUId }).pipe(
        concatMap((onlineMeeting) => createHideRequest(onlineMeeting.iCalUId)),
      );
    }

    return createHideRequest(data.iCalUId);
  }

  public updateOnlineMeeting(joinWebUrl: string, callContext: CallContext, subject: string): Observable<OnlineMeeting> {
    return this.http.put<OnlineMeeting>(`${environment.backendUrl}/api/online-meetings`, {
      joinWebUrl: decodeURIComponent(joinWebUrl),
      callContext,
      subject: subject || null,
    });
  }

  public addUsersToChats(joinWebUrls: string[], userObjectIds: string[]): Observable<void> {
    return this.http.post<void>(`${environment.backendUrl}/api/online-meetings/add-chat-members`, {
      joinWebUrls: joinWebUrls.map((joinWebUrl) => decodeURIComponent(joinWebUrl)),
      userObjectIds,
    });
  }

  public deleteTopicMeeting(index: number): Observable<void> {
    return this.http.delete<void>(`${environment.backendUrl}/api/online-meetings/topic-meeting?index=${index}`);
  }

  private isValidCreateRequest(
    callContext: CallContext,
    inviteTargetUserObjectIds?: string[],
    index?: number,
    subject?: string,
  ): Error | null {
    if (callContext === CallContext.ImpromptuMeeting && inviteTargetUserObjectIds?.length !== 1) {
      return new Error('Creating an impromptu meeting must be done with exactly 1 target user');
    }

    if (callContext === CallContext.BreakoutMeeting && index === undefined) {
      return new Error('Creating a breakout meeting must be done by providing an index');
    }

    if (callContext === CallContext.TopicMeeting && !subject) {
      return new Error('Creating a breakout meeting must be done by providing an index');
    }

    return null;
  }
}
