import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { NgStyle } from '@angular/common';

import { ScaleByOfficePipe } from '../../pipes/scale-by-office.pipe';

@Component({
  selector: 'app-button',
  standalone: true,
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  animations: [
    trigger('successAnimation', [
      transition('false => true', [
        animate('250ms', style([{ backgroundColor: 'var(--teams-green)' }])),
        animate('250ms 1.5s', style([{}])),
      ]),
    ]),

    trigger('failureAnimation', [
      transition('false => true', [
        animate('250ms', style([{ backgroundColor: 'var(--teams-red)' }])),
        animate('250ms 1.5s', style([])),
      ]),
    ]),
  ],
  imports: [NgStyle, ScaleByOfficePipe],
})
export class ButtonComponent {
  @Input() public disabled = false;

  // Any valid `CSS` is applicable. In addition our internal `scale-by-office($factor)` method can be used.
  @Input() public cursor = 'pointer';
  @Input() public minWidth = 'unset';
  @Input() public minHeight = 'unset';

  @Input() public width?: string;
  @Input() public height?: string;
  @Input() public fontSize?: string;
  @Input() public fontWeight?: string;
  @Input() public padding?: string;
  @Input() public borderRadius?: string;
  @Input() public color?: string;
  @Input() public backgroundColor?: string;
  @Input() public border?: string;
  @Input() public boxShadow?: string;
  @Input() public justifyContent?: string;

  // Animations are triggered on the transition of `false => true`
  @Input() public triggerSuccessAnimation = false;
  @Input() public triggerFailureAnimation = false;

  @Output() public buttonClick = new EventEmitter<MouseEvent>();
  @Output() public successAnimationChange = new EventEmitter<boolean>();
  @Output() public failureAnimationChange = new EventEmitter<boolean>();

  public onAnimationDone(): void {
    if (!this.triggerSuccessAnimation && !this.triggerFailureAnimation) return;

    this.triggerSuccessAnimation = false;
    this.triggerFailureAnimation = false;

    this.successAnimationChange.emit(this.triggerSuccessAnimation);
    this.failureAnimationChange.emit(this.triggerFailureAnimation);
  }
}
