import { Injectable } from '@angular/core';

import { catchError, concatMap, finalize, from, map, Observable, of, switchMap, throwError } from 'rxjs';
import { app } from '@microsoft/teams-js';
import { Mutex } from 'async-mutex';
import { liveQuery } from 'dexie';

import { AuthData, BackendData, GeneralData, GraphData, PrimaryKey, PrimaryKeyAuth } from '../models/cache.model';
import { Resource } from '../models/services/auth.model';
import { Chat } from '../models/services/chat/chat.model';

import { authDataGuard } from '../utils/indexed-db.util';

import { IndexedDB } from '../indexed-db/indexed-db';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private readonly db = new IndexedDB();
  private primaryKey!: PrimaryKey;

  private readonly graphMutex = new Mutex();
  private readonly generalMutex = new Mutex();

  public readonly generalData$ = from(liveQuery(() => this.db.general.get(this.primaryKey)));

  public initialize(context: app.Context): Observable<void> {
    if (!context.user?.tenant?.id || !context.user.id) {
      throw new Error('user or tenant is undefined');
    }

    this.primaryKey = [context.user.tenant.id, context.user.id];

    return this.db
      .initialize(this.primaryKey)
      .pipe(
        // Open chats should be initially minimized when opening app
        concatMap(() =>
          this.updateGraphDataFn(({ chats }) => ({
            chats: chats.map((chat) => ({ ...chat, metadata: { ...chat.metadata, isMinimized: true } })),
          })),
        ),
      )
      .pipe(map(() => void 0));
  }

  public getAuthData(resource: Resource): Observable<AuthData | undefined> {
    return from(this.db.auth.get([...this.primaryKey, resource, environment.azureActiveDirectory.clientId]));
  }

  public upsertAuthData(resource: Resource, changes: Partial<AuthData>): Observable<PrimaryKeyAuth | null> {
    const key: PrimaryKeyAuth = [...this.primaryKey, resource, environment.azureActiveDirectory.clientId];
    const add = () => {
      if (!authDataGuard(changes)) throw new Error('auth data is only partially defined');

      return this.db.auth.add(changes as AuthData, key);
    };

    return from(this.db.auth.update(key, changes)).pipe(concatMap((res) => (res === 1 ? of(null) : add())));
  }

  public getGeneralData(lockDatabase = true): Observable<GeneralData> {
    // If there is a pending write-operation wait for it to finish before returning data
    const databaseLock$: Observable<unknown> = lockDatabase ? from(this.generalMutex.waitForUnlock()) : of(null);

    return databaseLock$.pipe(
      concatMap(() => this.db.general.get(this.primaryKey)),
      map((generalData) => generalData!),
    );
  }

  public updateGeneralData(changes: Partial<GeneralData>): Observable<number> {
    return from(this.generalMutex.acquire()).pipe(
      switchMap(() => from(this.db.general.update(this.primaryKey, changes))),
      catchError((err) => {
        if (environment.enableLogging) console.error(err);

        return throwError(() => new Error(err));
      }),
      finalize(() => this.generalMutex.release()),
    );
  }

  public updateGeneralDataFn(action: (data: GeneralData) => Partial<GeneralData> | null): Observable<number | null> {
    return this.getGeneralData().pipe(
      map((data) => action(data)),
      concatMap((result) => (result ? this.updateGeneralData(result) : of(null))),
    );
  }

  public getBackendData(): Observable<BackendData> {
    return from(this.db.backend.get(this.primaryKey)).pipe(map((backendData) => backendData!));
  }

  public updateBackendData(changes: Partial<BackendData>): Observable<number> {
    return from(this.db.backend.update(this.primaryKey, changes));
  }

  public getGraphData(lockDatabase = true): Observable<GraphData> {
    // If there is a pending write-operation wait for it to finish before returning data
    const databaseLock$: Observable<unknown> = lockDatabase ? from(this.graphMutex.waitForUnlock()) : of(null);

    return databaseLock$.pipe(
      concatMap(() => this.db.graph.get(this.primaryKey)),
      map((graphData) => graphData!),
    );
  }

  public updateGraphDataFn(action: (data: GraphData) => Partial<GraphData> | null): Observable<number | null> {
    return from(this.graphMutex.acquire()).pipe(
      concatMap(() => this.getGraphData(false)),
      concatMap((graphData) => {
        const result = action(graphData);
        if (!result) return of(null);

        return this.updateGraphData(result);
      }),
      catchError((err) => {
        if (environment.enableLogging) console.error(err);

        return throwError(() => new Error(err));
      }),
      finalize(() => this.graphMutex.release()),
    );
  }

  public getChat(chatId: string): Observable<Chat | undefined> {
    return this.getGraphData().pipe(map(({ chats }) => chats.find((chat) => chat.id === chatId)));
  }

  public upsertChatsFn(
    updateChatIds: string[],
    createAction: (chatId: string) => Chat,
    updateAction: (chatId: string, chat: Chat) => Partial<Chat>,
  ): Observable<number | null> {
    return this.updateGraphDataFn(({ chats: cachedChats }) => {
      for (const updateChatId of updateChatIds) {
        const chatIndex = cachedChats.findIndex((cachedChat) => cachedChat.id === updateChatId);

        if (chatIndex !== -1) {
          cachedChats[chatIndex] = { ...cachedChats[chatIndex], ...updateAction(updateChatId, cachedChats[chatIndex]) };
        } else {
          cachedChats.push(createAction(updateChatId));
        }
      }

      return { chats: cachedChats };
    });
  }

  public updateChatsFn(updateChatIds: string[], action: (data: Chat) => Partial<Chat>): Observable<number | null> {
    return this.updateGraphDataFn(({ chats: cachedChats }) => {
      for (const updateChatId of updateChatIds) {
        const chatIndex = cachedChats.findIndex((cachedChat) => cachedChat.id === updateChatId);

        if (chatIndex !== -1) {
          cachedChats[chatIndex] = { ...cachedChats[chatIndex], ...action(cachedChats[chatIndex]) };
        }
      }

      return { chats: cachedChats };
    });
  }

  // This method is private to ensure that all updates are protected by the graph mutex
  private updateGraphData(changes: Partial<GraphData>): Observable<number> {
    return from(this.db.graph.update(this.primaryKey, changes));
  }
}
