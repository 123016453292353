import { ConnectedPosition } from '@angular/cdk/overlay';

import { ZIndex } from '../../models/directives/tooltip.model';
import {
  OVERLAY_BOTTOM,
  OVERLAY_BOTTOM_CENTER_LEFT_TOP,
  OVERLAY_BOTTOM_CENTER_RIGHT_TOP,
  OVERLAY_CENTER_PUSH_REQUIRED,
  OVERLAY_LEFT,
  OVERLAY_LEFT_CENTER_BOTTOM_RIGHT,
  OVERLAY_LEFT_CENTER_TOP_RIGHT,
  OVERLAY_RIGHT,
  OVERLAY_RIGHT_CENTER_BOTTOM_LEFT,
  OVERLAY_RIGHT_CENTER_TOP_LEFT,
  OVERLAY_TOP,
  OVERLAY_TOP_CENTER_LEFT_BOTTOM,
  OVERLAY_TOP_CENTER_RIGHT_BOTTOM,
  TooltipSpecificDirection,
} from '../../models/tooltip.model';
import { TooltipDirectionList, TooltipGeneralDirection } from '../../models/tooltip.model';

export function getPositions(
  direction: TooltipDirectionList | TooltipGeneralDirection,
  containerOffsetMainAxisPx: number,
  containerOffsetCrossAxisCenterPx: number,
  containerOffsetCrossAxisShiftedPx: number,
): ConnectedPosition[] {
  let directions: TooltipSpecificDirection[];
  if (direction instanceof TooltipDirectionList) directions = direction.directions;
  else directions = [direction];

  const positions = mapDirectionsToPositions(directions);
  setPositionOffsets(
    positions,
    containerOffsetMainAxisPx,
    containerOffsetCrossAxisCenterPx,
    containerOffsetCrossAxisShiftedPx,
  );

  return positions;
}

function mapDirectionsToPositions(directions: TooltipSpecificDirection[]): ConnectedPosition[] {
  const positions: ConnectedPosition[] = [];

  const defaultPositionsTop = [OVERLAY_TOP, OVERLAY_TOP_CENTER_LEFT_BOTTOM, OVERLAY_TOP_CENTER_RIGHT_BOTTOM];
  const defaultPositionsBottom = [OVERLAY_BOTTOM, OVERLAY_BOTTOM_CENTER_LEFT_TOP, OVERLAY_BOTTOM_CENTER_RIGHT_TOP];
  const defaultPositionsLeft = [OVERLAY_LEFT, OVERLAY_LEFT_CENTER_BOTTOM_RIGHT, OVERLAY_LEFT_CENTER_TOP_RIGHT];
  const defaultPositionsRight = [OVERLAY_RIGHT, OVERLAY_RIGHT_CENTER_BOTTOM_LEFT, OVERLAY_RIGHT_CENTER_TOP_LEFT];

  for (const direction of directions) {
    switch (direction) {
      case 'top':
        positions.push(...defaultPositionsTop);
        if (directions.length === 1) positions.push(...defaultPositionsBottom);

        break;
      case 'top-center':
        positions.push(OVERLAY_TOP);

        break;
      case 'top-left':
        positions.push(OVERLAY_TOP_CENTER_LEFT_BOTTOM);

        break;
      case 'top-right':
        positions.push(OVERLAY_TOP_CENTER_RIGHT_BOTTOM);

        break;

      case 'left':
        positions.push(...defaultPositionsLeft);
        if (directions.length === 1) positions.push(...defaultPositionsRight);

        break;
      case 'left-center':
        positions.push(OVERLAY_LEFT);

        break;
      case 'left-bottom':
        positions.push(OVERLAY_LEFT_CENTER_BOTTOM_RIGHT);

        break;
      case 'left-top':
        positions.push(OVERLAY_LEFT_CENTER_TOP_RIGHT);

        break;

      case 'right':
        positions.push(...defaultPositionsRight);
        if (directions.length === 1) positions.push(...defaultPositionsLeft);

        break;
      case 'right-center':
        positions.push(OVERLAY_RIGHT);

        break;
      case 'right-bottom':
        positions.push(OVERLAY_RIGHT_CENTER_BOTTOM_LEFT);

        break;
      case 'right-top':
        positions.push(OVERLAY_RIGHT_CENTER_TOP_LEFT);

        break;

      case 'bottom':
        positions.push(...defaultPositionsBottom);
        if (directions.length === 1) positions.push(...defaultPositionsTop);

        break;
      case 'bottom-center':
        positions.push(OVERLAY_BOTTOM);

        break;
      case 'bottom-left':
        positions.push(OVERLAY_BOTTOM_CENTER_LEFT_TOP);

        break;
      case 'bottom-right':
        positions.push(OVERLAY_BOTTOM_CENTER_RIGHT_TOP);

        break;

      default:
        throw new Error('Unsupported TooltipDirection');
    }
  }

  positions.push(OVERLAY_CENTER_PUSH_REQUIRED);

  // Deep copy positions so that the original exported const is not modified
  return JSON.parse(JSON.stringify(positions)) as ConnectedPosition[];
}

function setPositionOffsets(
  positions: ConnectedPosition[],
  containerOffsetMainAxisPx: number,
  containerOffsetCrossAxisCenterPx: number,
  containerOffsetCrossAxisShiftedPx: number,
): void {
  for (const position of positions) {
    const panelClasses = position.panelClass;
    if (!panelClasses) continue;

    if (panelClasses.includes('overlay-position-bottom')) position.offsetY = containerOffsetMainAxisPx;
    if (panelClasses.includes('overlay-position-top')) position.offsetY = -containerOffsetMainAxisPx;

    if (panelClasses.includes('overlay-position-right')) position.offsetX = containerOffsetMainAxisPx;
    if (panelClasses.includes('overlay-position-left')) position.offsetX = -containerOffsetMainAxisPx;

    if (panelClasses.includes('overlay-position-bottom') || panelClasses.includes('overlay-position-top')) {
      if (panelClasses.includes('overlay-position-center')) position.offsetX = containerOffsetCrossAxisCenterPx;

      if (panelClasses.includes('overlay-position-start')) position.offsetX = -containerOffsetCrossAxisShiftedPx;
      if (panelClasses.includes('overlay-position-end')) position.offsetX = containerOffsetCrossAxisShiftedPx;
    }

    if (panelClasses.includes('overlay-position-left') || panelClasses.includes('overlay-position-right')) {
      if (panelClasses.includes('overlay-position-center')) position.offsetY = containerOffsetCrossAxisCenterPx;

      if (panelClasses.includes('overlay-position-start')) position.offsetY = -containerOffsetCrossAxisShiftedPx;
      if (panelClasses.includes('overlay-position-end')) position.offsetY = containerOffsetCrossAxisShiftedPx;
    }
  }
}

export function hasRectangleChangedFactory(element: HTMLElement): () => boolean {
  const initialRectangle = element.getBoundingClientRect();
  const lastHostPosition = {
    x: initialRectangle.x,
    y: initialRectangle.y,

    width: initialRectangle.width,
    height: initialRectangle.height,
  };

  return () => {
    const rectangle = element.getBoundingClientRect();

    if (
      lastHostPosition.x !== rectangle.x ||
      lastHostPosition.y !== rectangle.y ||
      lastHostPosition.width !== rectangle.width ||
      lastHostPosition.height !== rectangle.height
    ) {
      lastHostPosition.x = rectangle.x;
      lastHostPosition.y = rectangle.y;
      lastHostPosition.width = rectangle.width;
      lastHostPosition.height = rectangle.height;

      return true;
    }

    return false;
  };
}

export function getZIndexValue(zIndex: ZIndex): string {
  return zIndex === 'high' ? '1100' : zIndex === 'medium' ? '1000' : '900';
}

export function getClassForZIndex(zIndex: ZIndex): string {
  return zIndex === 'high' ? 'high-z-index' : zIndex === 'medium' ? 'medium-z-index' : 'low-z-index';
}
