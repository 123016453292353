<ng-container *transloco="let tTour; read: 'tour.' + tourId">
  <div class="title">{{ tTour(stepId + ".title", { name: name }) }}</div>

  <div class="content" [innerHTML]="tTour(stepId + '.content') | safe: 'html'"></div>

  <div *transloco="let tButtons; read: 'tour.buttons'" class="buttons-container">
    @if (!isFirstStep) {
      <app-button
        backgroundColor="transparent"
        border="scale-by-office(0.103) solid var(--bnear-brand)"
        class="previous"
        color="var(--bnear-brand)"
        fontSize="scale-by-office(1.24)"
        height="scale-by-office(3)"
        padding="scale-by-office(0.345) scale-by-office(2)"
        (buttonClick)="tourService.previousStep(stepId)"
      >
        <app-arrow-icon />
      </app-button>
    }

    <app-button
      backgroundColor="var(--bnear-brand)"
      border="scale-by-office(0.103) solid transparent"
      class="next"
      color="white"
      fontSize="scale-by-office(1.24)"
      height="scale-by-office(3)"
      padding="scale-by-office(0.345) scale-by-office(2)"
      width="100%"
      (buttonClick)="tourService.nextStep(stepId)"
    >
      {{ tButtons(isLastStep ? "done" : "next") }}

      @if (isLastStep) {
        <app-emoji emojiStyle="Animated" [emoji]="('🚀' | getEmoji)!" />
      } @else {
        <app-arrow-icon fill="white" />
      }
    </app-button>
  </div>
</ng-container>
