export enum HintType {
  PopOut = 0,
  Emoji = 1,
  JoinDesksCall = 2,
  InteractiveJoinDesksCall = 3,
  InteractiveJoinKitchen = 4,
  InteractiveJoinTopicCall = 5,
}

export type CallParticipantCase = 'moreThanThreeParticipants' | 'oneParticipant' | 'twoOrThreeParticipants';

export interface InteractiveHintText {
  commaSeparatedNames?: string;
  nameOfLastParticipant?: string;
  callParticipantCase?: CallParticipantCase;
}
