import { Directive, Input, OnInit } from '@angular/core';

import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';

@Directive({
  selector: '[appTooltipStyles]',
  standalone: true,
})
export class TooltipStylesDirective implements OnInit {
  @Input({ required: true, alias: 'appTooltipStyles' }) public tooltipDirective!: TooltipDirective;
  @Input({ alias: 'appTooltipStylesAbsoluteCssValues' }) public absoluteCssValues = false;

  public ngOnInit(): void {
    this.tooltipDirective.borderRadius = this.absoluteCssValues ? '7px' : 'scale-by-office(0.5)';
    this.tooltipDirective.containerOffsetCrossAxisShifted = this.absoluteCssValues ? '10px' : 'scale-by-office(1.5)';
    this.tooltipDirective.containerOffsetMainAxis = this.absoluteCssValues ? '10px' : 'scale-by-office(1)';
    this.tooltipDirective.fontSize = this.absoluteCssValues ? '11px' : 'scale-by-office(0.9)';
    this.tooltipDirective.maxWidth = this.absoluteCssValues ? '250px' : 'scale-by-office(20)';
    this.tooltipDirective.padding = this.absoluteCssValues ? '10px 16px' : 'scale-by-office(1) scale-by-office(1.2)';
    this.tooltipDirective.pinSize = this.absoluteCssValues ? '8px' : 'scale-by-office(0.8)';
  }
}
