{
  "🥇": {
    "glyph": "🥇",
    "group": "Activities",
    "keywords": [
      "1st place medal",
      "first",
      "gold",
      "medal"
    ],
    "dirName": "1st place medal",
    "styles": {
      "3D": "1st_place_medal_3d.png",
      "Animated": "1st Place Medal.png"
    },
    "sortOrder": 1048
  },
  "🥈": {
    "glyph": "🥈",
    "group": "Activities",
    "keywords": [
      "2nd place medal",
      "medal",
      "second",
      "silver"
    ],
    "dirName": "2nd place medal",
    "styles": {
      "3D": "2nd_place_medal_3d.png",
      "Animated": "2nd Place Medal.png"
    },
    "sortOrder": 1049
  },
  "🥉": {
    "glyph": "🥉",
    "group": "Activities",
    "keywords": [
      "3rd place medal",
      "bronze",
      "medal",
      "third"
    ],
    "dirName": "3rd place medal",
    "styles": {
      "3D": "3rd_place_medal_3d.png",
      "Animated": "3rd Place Medal.png"
    },
    "sortOrder": 1050
  },
  "🅰️": {
    "glyph": "🅰️",
    "group": "Symbols",
    "keywords": [
      "a",
      "A button (blood type)",
      "blood type"
    ],
    "dirName": "A button blood type",
    "styles": {
      "3D": "a_button_blood_type_3d.png",
      "Animated": "A Button (Blood Type).png"
    },
    "sortOrder": 1518
  },
  "🆎": {
    "glyph": "🆎",
    "group": "Symbols",
    "keywords": [
      "ab",
      "AB button (blood type)",
      "blood type"
    ],
    "dirName": "Ab button blood type",
    "styles": {
      "3D": "ab_button_blood_type_3d.png",
      "Animated": "AB Button (Blood Type).png"
    },
    "sortOrder": 1519
  },
  "🧮": {
    "glyph": "🧮",
    "group": "Objects",
    "keywords": [
      "abacus",
      "calculation"
    ],
    "dirName": "Abacus",
    "styles": {
      "3D": "abacus_3d.png",
      "Animated": "Abacus.png"
    },
    "sortOrder": 1201
  },
  "🪗": {
    "glyph": "🪗",
    "group": "Objects",
    "keywords": [
      "accordion",
      "concertina",
      "squeeze box"
    ],
    "dirName": "Accordion",
    "styles": {
      "3D": "accordion_3d.png",
      "Animated": "Accordion.png"
    },
    "sortOrder": 1174
  },
  "🩹": {
    "glyph": "🩹",
    "group": "Objects",
    "keywords": [
      "adhesive bandage",
      "bandage"
    ],
    "dirName": "Adhesive bandage",
    "styles": {
      "3D": "adhesive_bandage_3d.png"
    },
    "sortOrder": 1329
  },
  "🎟️": {
    "glyph": "🎟️",
    "group": "Activities",
    "keywords": [
      "admission",
      "admission tickets",
      "ticket"
    ],
    "dirName": "Admission tickets",
    "styles": {
      "3D": "admission_tickets_3d.png",
      "Animated": "Admission Tickets.png"
    },
    "sortOrder": 1043
  },
  "🚡": {
    "glyph": "🚡",
    "group": "Travel & Places",
    "keywords": [
      "aerial",
      "cable",
      "car",
      "gondola",
      "tramway"
    ],
    "dirName": "Aerial tramway",
    "styles": {
      "3D": "aerial_tramway_3d.png",
      "Animated": "Aerial Tramway.png"
    },
    "sortOrder": 940
  },
  "✈️": {
    "glyph": "✈️",
    "group": "Travel & Places",
    "keywords": [
      "aeroplane",
      "airplane"
    ],
    "dirName": "Airplane",
    "styles": {
      "3D": "airplane_3d.png",
      "Animated": "Airplane.png"
    },
    "sortOrder": 931
  },
  "🛬": {
    "glyph": "🛬",
    "group": "Travel & Places",
    "keywords": [
      "aeroplane",
      "airplane",
      "airplane arrival",
      "arrivals",
      "arriving",
      "landing"
    ],
    "dirName": "Airplane arrival",
    "styles": {
      "3D": "airplane_arrival_3d.png",
      "Animated": "Airplane Arrival.png"
    },
    "sortOrder": 934
  },
  "🛫": {
    "glyph": "🛫",
    "group": "Travel & Places",
    "keywords": [
      "aeroplane",
      "airplane",
      "check-in",
      "departure",
      "departures"
    ],
    "dirName": "Airplane departure",
    "styles": {
      "3D": "airplane_departure_3d.png",
      "Animated": "Airplane Departure.png"
    },
    "sortOrder": 933
  },
  "⏰": {
    "glyph": "⏰",
    "group": "Travel & Places",
    "keywords": [
      "alarm",
      "clock"
    ],
    "dirName": "Alarm clock",
    "styles": {
      "3D": "alarm_clock_3d.png",
      "Animated": "Alarm Clock.png"
    },
    "sortOrder": 949
  },
  "⚗️": {
    "glyph": "⚗️",
    "group": "Objects",
    "keywords": [
      "alembic",
      "chemistry",
      "tool"
    ],
    "dirName": "Alembic",
    "styles": {
      "3D": "alembic_3d.png",
      "Animated": "Alembic.png"
    },
    "sortOrder": 1319
  },
  "👽": {
    "glyph": "👽",
    "group": "Smileys & Emotion",
    "keywords": [
      "alien",
      "creature",
      "extraterrestrial",
      "face",
      "fantasy",
      "ufo"
    ],
    "dirName": "Alien",
    "styles": {
      "3D": "alien_3d.png",
      "Animated": "Alien.png"
    },
    "sortOrder": 112
  },
  "👾": {
    "glyph": "👾",
    "group": "Smileys & Emotion",
    "keywords": [
      "alien",
      "creature",
      "extraterrestrial",
      "face",
      "monster",
      "ufo"
    ],
    "dirName": "Alien monster",
    "styles": {
      "3D": "alien_monster_3d.png",
      "Animated": "Alien Monster.png"
    },
    "sortOrder": 113
  },
  "🚑": {
    "glyph": "🚑",
    "group": "Travel & Places",
    "keywords": [
      "ambulance",
      "vehicle"
    ],
    "dirName": "Ambulance",
    "styles": {
      "3D": "ambulance_3d.png",
      "Animated": "Ambulance.png"
    },
    "sortOrder": 888
  },
  "🏈": {
    "glyph": "🏈",
    "group": "Activities",
    "keywords": [
      "american",
      "ball",
      "football"
    ],
    "dirName": "American football",
    "styles": {
      "3D": "american_football_3d.png",
      "Animated": "American Football.png"
    },
    "sortOrder": 1056
  },
  "🏺": {
    "glyph": "🏺",
    "group": "Food & Drink",
    "keywords": [
      "amphora",
      "Aquarius",
      "cooking",
      "drink",
      "jug",
      "zodiac"
    ],
    "dirName": "Amphora",
    "styles": {
      "3D": "amphora_3d.png",
      "Animated": "Amphora.png"
    },
    "sortOrder": 805
  },
  "🫀": {
    "glyph": "🫀",
    "group": "People & Body",
    "keywords": [
      "anatomical",
      "cardiology",
      "heart",
      "organ",
      "pulse"
    ],
    "dirName": "Anatomical heart",
    "styles": {
      "3D": "anatomical_heart_3d.png",
      "Animated": "Anatomical Heart.png"
    },
    "sortOrder": 214
  },
  "⚓": {
    "glyph": "⚓",
    "group": "Travel & Places",
    "keywords": [
      "anchor",
      "ship",
      "tool"
    ],
    "dirName": "Anchor",
    "styles": {
      "3D": "anchor_3d.png",
      "Animated": "Anchor.png"
    },
    "sortOrder": 922
  },
  "💢": {
    "glyph": "💢",
    "group": "Smileys & Emotion",
    "keywords": [
      "anger symbol",
      "angry",
      "comic",
      "mad"
    ],
    "dirName": "Anger symbol",
    "styles": {
      "3D": "anger_symbol_3d.png",
      "Animated": "Anger Symbol.png"
    },
    "sortOrder": 151
  },
  "😠": {
    "glyph": "😠",
    "group": "Smileys & Emotion",
    "keywords": [
      "anger",
      "angry",
      "face",
      "mad"
    ],
    "dirName": "Angry face",
    "styles": {
      "3D": "angry_face_3d.png",
      "Animated": "Angry Face.png"
    },
    "sortOrder": 101
  },
  "👿": {
    "glyph": "👿",
    "group": "Smileys & Emotion",
    "keywords": [
      "angry face with horns",
      "demon",
      "devil",
      "face",
      "fantasy",
      "imp"
    ],
    "dirName": "Angry face with horns",
    "styles": {
      "3D": "angry_face_with_horns_3d.png",
      "Animated": "Angry Face with Horns.png"
    },
    "sortOrder": 104
  },
  "😧": {
    "glyph": "😧",
    "group": "Smileys & Emotion",
    "keywords": [
      "anguished",
      "face"
    ],
    "dirName": "Anguished face",
    "styles": {
      "3D": "anguished_face_3d.png",
      "Animated": "Anguished Face.png"
    },
    "sortOrder": 85
  },
  "🐜": {
    "glyph": "🐜",
    "group": "Animals & Nature",
    "keywords": [
      "ant",
      "insect"
    ],
    "dirName": "Ant",
    "styles": {
      "3D": "ant_3d.png",
      "Animated": "Ant.png"
    },
    "sortOrder": 635
  },
  "📶": {
    "glyph": "📶",
    "group": "Symbols",
    "keywords": [
      "antenna",
      "antenna bars",
      "bar",
      "cell",
      "mobile",
      "phone"
    ],
    "dirName": "Antenna bars",
    "styles": {
      "3D": "antenna_bars_3d.png",
      "Animated": "Antenna Bars.png"
    },
    "sortOrder": 1458
  },
  "😰": {
    "glyph": "😰",
    "group": "Smileys & Emotion",
    "keywords": [
      "anxious face with sweat",
      "blue",
      "cold",
      "face",
      "rushed",
      "sweat"
    ],
    "dirName": "Anxious face with sweat",
    "styles": {
      "3D": "anxious_face_with_sweat_3d.png",
      "Animated": "Anxious Face with Sweat.png"
    },
    "sortOrder": 87
  },
  "♒": {
    "glyph": "♒",
    "group": "Symbols",
    "keywords": [
      "Aquarius",
      "bearer",
      "water",
      "zodiac"
    ],
    "dirName": "Aquarius",
    "styles": {
      "3D": "aquarius_3d.png",
      "Animated": "Aquarius.png"
    },
    "sortOrder": 1434
  },
  "♈": {
    "glyph": "♈",
    "group": "Symbols",
    "keywords": [
      "Aries",
      "ram",
      "zodiac"
    ],
    "dirName": "Aries",
    "styles": {
      "3D": "aries_3d.png",
      "Animated": "Aries.png"
    },
    "sortOrder": 1424
  },
  "🚛": {
    "glyph": "🚛",
    "group": "Travel & Places",
    "keywords": [
      "articulated lorry",
      "lorry",
      "semi",
      "truck"
    ],
    "dirName": "Articulated lorry",
    "styles": {
      "3D": "articulated_lorry_3d.png",
      "Animated": "Articulated Lorry.png"
    },
    "sortOrder": 899
  },
  "🧑‍🎨": {
    "glyph": "🧑‍🎨",
    "group": "People & Body",
    "keywords": [
      "artist",
      "palette"
    ],
    "dirName": "Artist",
    "skintones": {
      "Default": {
        "3D": "artist_3d_default.png",
        "Animated": "Artist.png"
      }
    },
    "sortOrder": 317
  },
  "🎨": {
    "glyph": "🎨",
    "group": "Activities",
    "keywords": [
      "art",
      "artist palette",
      "museum",
      "painting",
      "palette"
    ],
    "dirName": "Artist palette",
    "styles": {
      "3D": "artist_palette_3d.png",
      "Animated": "Artist Palette.png"
    },
    "sortOrder": 1105
  },
  "😲": {
    "glyph": "😲",
    "group": "Smileys & Emotion",
    "keywords": [
      "astonished",
      "face",
      "shocked",
      "totally"
    ],
    "dirName": "Astonished face",
    "styles": {
      "3D": "astonished_face_3d.png",
      "Animated": "Astonished Face.png"
    },
    "sortOrder": 80
  },
  "🧑‍🚀": {
    "glyph": "🧑‍🚀",
    "group": "People & Body",
    "keywords": [
      "astronaut",
      "rocket"
    ],
    "dirName": "Astronaut",
    "skintones": {
      "Default": {
        "3D": "astronaut_3d_default.png",
        "Animated": "Astronaut.png"
      }
    },
    "sortOrder": 323
  },
  "🏧": {
    "glyph": "🏧",
    "group": "Symbols",
    "keywords": [
      "atm",
      "ATM sign",
      "automated",
      "bank",
      "teller"
    ],
    "dirName": "Atm sign",
    "styles": {
      "3D": "atm_sign_3d.png",
      "Animated": "ATM Sign.png"
    },
    "sortOrder": 1365
  },
  "⚛️": {
    "glyph": "⚛️",
    "group": "Symbols",
    "keywords": [
      "atheist",
      "atom",
      "atom symbol"
    ],
    "dirName": "Atom symbol",
    "styles": {
      "3D": "atom_symbol_3d.png",
      "Animated": "Atom Symbol.png"
    },
    "sortOrder": 1413
  },
  "🛺": {
    "glyph": "🛺",
    "group": "Travel & Places",
    "keywords": [
      "auto rickshaw",
      "tuk tuk"
    ],
    "dirName": "Auto rickshaw",
    "styles": {
      "3D": "auto_rickshaw_3d.png",
      "Animated": "Auto Rickshaw.png"
    },
    "sortOrder": 906
  },
  "🚗": {
    "glyph": "🚗",
    "group": "Travel & Places",
    "keywords": [
      "automobile",
      "car"
    ],
    "dirName": "Automobile",
    "styles": {
      "3D": "automobile_3d.png",
      "Animated": "Automobile.png"
    },
    "sortOrder": 894
  },
  "🥑": {
    "glyph": "🥑",
    "group": "Food & Drink",
    "keywords": [
      "avocado",
      "food",
      "fruit"
    ],
    "dirName": "Avocado",
    "styles": {
      "3D": "avocado_3d.png",
      "Animated": "Avocado.png"
    },
    "sortOrder": 693
  },
  "🪓": {
    "glyph": "🪓",
    "group": "Objects",
    "keywords": [
      "axe",
      "chop",
      "hatchet",
      "split",
      "wood"
    ],
    "dirName": "Axe",
    "styles": {
      "3D": "axe_3d.png",
      "Animated": "Axe.png"
    },
    "sortOrder": 1295
  },
  "🅱️": {
    "glyph": "🅱️",
    "group": "Symbols",
    "keywords": [
      "b",
      "B button (blood type)",
      "blood type"
    ],
    "dirName": "B button blood type",
    "styles": {
      "3D": "b_button_blood_type_3d.png",
      "Animated": "B Button (Blood Type).png"
    },
    "sortOrder": 1520
  },
  "👶": {
    "glyph": "👶",
    "group": "People & Body",
    "keywords": [
      "baby",
      "young"
    ],
    "dirName": "Baby",
    "skintones": {
      "Default": {
        "3D": "baby_3d_default.png",
        "Animated": "Baby.png"
      }
    },
    "sortOrder": 223
  },
  "👼": {
    "glyph": "👼",
    "group": "People & Body",
    "keywords": [
      "angel",
      "baby",
      "face",
      "fairy tale",
      "fantasy"
    ],
    "dirName": "Baby angel",
    "skintones": {
      "Default": {
        "3D": "baby_angel_3d_default.png",
        "Animated": "Baby Angel.png"
      }
    },
    "sortOrder": 363
  },
  "🍼": {
    "glyph": "🍼",
    "group": "Food & Drink",
    "keywords": [
      "baby",
      "bottle",
      "drink",
      "milk"
    ],
    "dirName": "Baby bottle",
    "styles": {
      "3D": "baby_bottle_3d.png",
      "Animated": "Baby Bottle.png"
    },
    "sortOrder": 779
  },
  "🐤": {
    "glyph": "🐤",
    "group": "Animals & Nature",
    "keywords": [
      "baby",
      "bird",
      "chick"
    ],
    "dirName": "Baby chick",
    "styles": {
      "3D": "baby_chick_3d.png",
      "Animated": "Baby Chick.png"
    },
    "sortOrder": 598
  },
  "🚼": {
    "glyph": "🚼",
    "group": "Symbols",
    "keywords": [
      "baby",
      "baby symbol",
      "changing"
    ],
    "dirName": "Baby symbol",
    "styles": {
      "3D": "baby_symbol_3d.png",
      "Animated": "Baby Symbol.png"
    },
    "sortOrder": 1372
  },
  "🔙": {
    "glyph": "🔙",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "back",
      "BACK arrow"
    ],
    "dirName": "Back arrow",
    "styles": {
      "3D": "back_arrow_3d.png",
      "Animated": "Back Arrow.png"
    },
    "sortOrder": 1407
  },
  "👇": {
    "glyph": "👇",
    "group": "People & Body",
    "keywords": [
      "backhand",
      "backhand index pointing down",
      "down",
      "finger",
      "hand",
      "point"
    ],
    "dirName": "Backhand index pointing down",
    "skintones": {
      "Default": {
        "3D": "backhand_index_pointing_down_3d_default.png",
        "Animated": "Backhand Index Pointing Down.png"
      }
    },
    "sortOrder": 186
  },
  "👈": {
    "glyph": "👈",
    "group": "People & Body",
    "keywords": [
      "backhand",
      "backhand index pointing left",
      "finger",
      "hand",
      "index",
      "point"
    ],
    "dirName": "Backhand index pointing left",
    "skintones": {
      "Default": {
        "3D": "backhand_index_pointing_left_3d_default.png",
        "Animated": "Backhand Index Pointing Left.png"
      }
    },
    "sortOrder": 182
  },
  "👉": {
    "glyph": "👉",
    "group": "People & Body",
    "keywords": [
      "backhand",
      "backhand index pointing right",
      "finger",
      "hand",
      "index",
      "point"
    ],
    "dirName": "Backhand index pointing right",
    "skintones": {
      "Default": {
        "3D": "backhand_index_pointing_right_3d_default.png",
        "Animated": "Backhand Index Pointing Right.png"
      }
    },
    "sortOrder": 183
  },
  "👆": {
    "glyph": "👆",
    "group": "People & Body",
    "keywords": [
      "backhand",
      "backhand index pointing up",
      "finger",
      "hand",
      "point",
      "up"
    ],
    "dirName": "Backhand index pointing up",
    "skintones": {
      "Default": {
        "3D": "backhand_index_pointing_up_3d_default.png",
        "Animated": "Backhand Index Pointing Up.png"
      }
    },
    "sortOrder": 184
  },
  "🎒": {
    "glyph": "🎒",
    "group": "Objects",
    "keywords": [
      "backpack",
      "bag",
      "rucksack",
      "satchel",
      "school"
    ],
    "dirName": "Backpack",
    "styles": {
      "3D": "backpack_3d.png",
      "Animated": "Backpack.png"
    },
    "sortOrder": 1134
  },
  "🥓": {
    "glyph": "🥓",
    "group": "Food & Drink",
    "keywords": [
      "bacon",
      "breakfast",
      "food",
      "meat"
    ],
    "dirName": "Bacon",
    "styles": {
      "3D": "bacon_3d.png",
      "Animated": "Bacon.png"
    },
    "sortOrder": 721
  },
  "🦡": {
    "glyph": "🦡",
    "group": "Animals & Nature",
    "keywords": [
      "badger",
      "honey badger",
      "pester"
    ],
    "dirName": "Badger",
    "styles": {
      "3D": "badger_3d.png",
      "Animated": "Badger.png"
    },
    "sortOrder": 592
  },
  "🏸": {
    "glyph": "🏸",
    "group": "Activities",
    "keywords": [
      "badminton",
      "birdie",
      "game",
      "racquet",
      "shuttlecock"
    ],
    "dirName": "Badminton",
    "styles": {
      "3D": "badminton_3d.png",
      "Animated": "Badminton.png"
    },
    "sortOrder": 1066
  },
  "🥯": {
    "glyph": "🥯",
    "group": "Food & Drink",
    "keywords": [
      "bagel",
      "bakery",
      "breakfast",
      "schmear"
    ],
    "dirName": "Bagel",
    "styles": {
      "3D": "bagel_3d.png",
      "Animated": "Bagel.png"
    },
    "sortOrder": 714
  },
  "🛄": {
    "glyph": "🛄",
    "group": "Symbols",
    "keywords": [
      "baggage",
      "claim"
    ],
    "dirName": "Baggage claim",
    "styles": {
      "3D": "baggage_claim_3d.png",
      "Animated": "Baggage Claim.png"
    },
    "sortOrder": 1376
  },
  "🥖": {
    "glyph": "🥖",
    "group": "Food & Drink",
    "keywords": [
      "baguette",
      "bread",
      "food",
      "french"
    ],
    "dirName": "Baguette bread",
    "styles": {
      "3D": "baguette_bread_3d.png",
      "Animated": "Baguette Bread.png"
    },
    "sortOrder": 711
  },
  "⚖️": {
    "glyph": "⚖️",
    "group": "Objects",
    "keywords": [
      "balance",
      "justice",
      "Libra",
      "scale",
      "zodiac"
    ],
    "dirName": "Balance scale",
    "styles": {
      "3D": "balance_scale_3d.png",
      "Animated": "Balance Scale.png"
    },
    "sortOrder": 1311
  },
  "🩰": {
    "glyph": "🩰",
    "group": "Objects",
    "keywords": [
      "ballet",
      "ballet shoes",
      "dance"
    ],
    "dirName": "Ballet shoes",
    "styles": {
      "3D": "ballet_shoes_3d.png",
      "Animated": "Ballet Shoes.png"
    },
    "sortOrder": 1142
  },
  "🎈": {
    "glyph": "🎈",
    "group": "Activities",
    "keywords": [
      "balloon",
      "celebration"
    ],
    "dirName": "Balloon",
    "styles": {
      "3D": "balloon_3d.png",
      "Animated": "Balloon.png"
    },
    "sortOrder": 1030
  },
  "🗳️": {
    "glyph": "🗳️",
    "group": "Objects",
    "keywords": [
      "ballot",
      "ballot box with ballot",
      "box"
    ],
    "dirName": "Ballot box with ballot",
    "styles": {
      "3D": "ballot_box_with_ballot_3d.png",
      "Animated": "Ballot Box with Ballot.png"
    },
    "sortOrder": 1257
  },
  "🍌": {
    "glyph": "🍌",
    "group": "Food & Drink",
    "keywords": [
      "banana",
      "fruit"
    ],
    "dirName": "Banana",
    "styles": {
      "3D": "banana_3d.png",
      "Animated": "Banana.png"
    },
    "sortOrder": 679
  },
  "🪕": {
    "glyph": "🪕",
    "group": "Objects",
    "keywords": [
      "banjo",
      "music",
      "stringed"
    ],
    "dirName": "Banjo",
    "styles": {
      "3D": "banjo_3d.png",
      "Animated": "Banjo.png"
    },
    "sortOrder": 1179
  },
  "🏦": {
    "glyph": "🏦",
    "group": "Travel & Places",
    "keywords": [
      "bank",
      "building"
    ],
    "dirName": "Bank",
    "styles": {
      "3D": "bank_3d.png",
      "Animated": "Bank.png"
    },
    "sortOrder": 837
  },
  "📊": {
    "glyph": "📊",
    "group": "Objects",
    "keywords": [
      "bar",
      "chart",
      "graph"
    ],
    "dirName": "Bar chart",
    "styles": {
      "3D": "bar_chart_3d.png",
      "Animated": "Bar Chart.png"
    },
    "sortOrder": 1276
  },
  "💈": {
    "glyph": "💈",
    "group": "Travel & Places",
    "keywords": [
      "barber",
      "haircut",
      "pole"
    ],
    "dirName": "Barber pole",
    "styles": {
      "3D": "barber_pole_3d.png",
      "Animated": "Barber Pole.png"
    },
    "sortOrder": 870
  },
  "⚾": {
    "glyph": "⚾",
    "group": "Activities",
    "keywords": [
      "ball",
      "baseball"
    ],
    "dirName": "Baseball",
    "styles": {
      "3D": "baseball_3d.png",
      "Animated": "Baseball.png"
    },
    "sortOrder": 1052
  },
  "🧺": {
    "glyph": "🧺",
    "group": "Objects",
    "keywords": [
      "basket",
      "farming",
      "laundry",
      "picnic"
    ],
    "dirName": "Basket",
    "styles": {
      "3D": "basket_3d.png",
      "Animated": "Basket.png"
    },
    "sortOrder": 1349
  },
  "🏀": {
    "glyph": "🏀",
    "group": "Activities",
    "keywords": [
      "ball",
      "basketball",
      "hoop"
    ],
    "dirName": "Basketball",
    "styles": {
      "3D": "basketball_3d.png",
      "Animated": "Basketball.png"
    },
    "sortOrder": 1054
  },
  "🦇": {
    "glyph": "🦇",
    "group": "Animals & Nature",
    "keywords": [
      "bat",
      "vampire"
    ],
    "dirName": "Bat",
    "styles": {
      "3D": "bat_3d.png",
      "Animated": "Bat.png"
    },
    "sortOrder": 583
  },
  "🛁": {
    "glyph": "🛁",
    "group": "Objects",
    "keywords": [
      "bath",
      "bathtub"
    ],
    "dirName": "Bathtub",
    "styles": {
      "3D": "bathtub_3d.png",
      "Animated": "Bathtub.png"
    },
    "sortOrder": 1343
  },
  "🔋": {
    "glyph": "🔋",
    "group": "Objects",
    "keywords": [
      "battery"
    ],
    "dirName": "Battery",
    "styles": {
      "3D": "battery_3d.png",
      "Animated": "Battery.png"
    },
    "sortOrder": 1188
  },
  "🏖️": {
    "glyph": "🏖️",
    "group": "Travel & Places",
    "keywords": [
      "beach",
      "beach with umbrella",
      "umbrella"
    ],
    "dirName": "Beach with umbrella",
    "styles": {
      "3D": "beach_with_umbrella_3d.png",
      "Animated": "Beach with Umbrella.png"
    },
    "sortOrder": 818
  },
  "😁": {
    "glyph": "😁",
    "group": "Smileys & Emotion",
    "keywords": [
      "beaming face with smiling eyes",
      "eye",
      "face",
      "grin",
      "smile"
    ],
    "dirName": "Beaming face with smiling eyes",
    "styles": {
      "3D": "beaming_face_with_smiling_eyes_3d.png",
      "Animated": "Beaming Face with Smiling Eyes.png"
    },
    "sortOrder": 4
  },
  "🫘": {
    "glyph": "🫘",
    "group": "Food & Drink",
    "keywords": [
      "beans",
      "food",
      "kidney",
      "legume"
    ],
    "dirName": "Beans",
    "styles": {
      "3D": "beans_3d.png",
      "Animated": "Beans.png"
    },
    "sortOrder": 707
  },
  "🐻": {
    "glyph": "🐻",
    "group": "Animals & Nature",
    "keywords": [
      "bear",
      "face"
    ],
    "dirName": "Bear",
    "styles": {
      "3D": "bear_3d.png",
      "Animated": "Bear.png"
    },
    "sortOrder": 584
  },
  "💓": {
    "glyph": "💓",
    "group": "Smileys & Emotion",
    "keywords": [
      "beating",
      "beating heart",
      "heartbeat",
      "pulsating"
    ],
    "dirName": "Beating heart",
    "styles": {
      "3D": "beating_heart_3d.png",
      "Animated": "Beating Heart.png"
    },
    "sortOrder": 133
  },
  "🦫": {
    "glyph": "🦫",
    "group": "Animals & Nature",
    "keywords": [
      "beaver",
      "dam"
    ],
    "dirName": "Beaver",
    "styles": {
      "3D": "beaver_3d.png",
      "Animated": "Beaver.png"
    },
    "sortOrder": 581
  },
  "🛏️": {
    "glyph": "🛏️",
    "group": "Objects",
    "keywords": [
      "bed",
      "hotel",
      "sleep"
    ],
    "dirName": "Bed",
    "styles": {
      "3D": "bed_3d.png",
      "Animated": "Bed.png"
    },
    "sortOrder": 1337
  },
  "🍺": {
    "glyph": "🍺",
    "group": "Food & Drink",
    "keywords": [
      "bar",
      "beer",
      "drink",
      "mug"
    ],
    "dirName": "Beer mug",
    "styles": {
      "3D": "beer_mug_3d.png",
      "Animated": "Beer Mug.png"
    },
    "sortOrder": 789
  },
  "🪲": {
    "glyph": "🪲",
    "group": "Animals & Nature",
    "keywords": [
      "beetle",
      "bug",
      "insect"
    ],
    "dirName": "Beetle",
    "styles": {
      "3D": "beetle_3d.png",
      "Animated": "Beetle.png"
    },
    "sortOrder": 637
  },
  "🔔": {
    "glyph": "🔔",
    "group": "Objects",
    "keywords": [
      "bell"
    ],
    "dirName": "Bell",
    "styles": {
      "3D": "bell_3d.png",
      "Animated": "Bell.png"
    },
    "sortOrder": 1162
  },
  "🫑": {
    "glyph": "🫑",
    "group": "Food & Drink",
    "keywords": [
      "bell pepper",
      "capsicum",
      "pepper",
      "vegetable"
    ],
    "dirName": "Bell pepper",
    "styles": {
      "3D": "bell_pepper_3d.png",
      "Animated": "Bell Pepper.png"
    },
    "sortOrder": 699
  },
  "🔕": {
    "glyph": "🔕",
    "group": "Objects",
    "keywords": [
      "bell",
      "bell with slash",
      "forbidden",
      "mute",
      "quiet",
      "silent"
    ],
    "dirName": "Bell with slash",
    "styles": {
      "3D": "bell_with_slash_3d.png",
      "Animated": "Bell with Slash.png"
    },
    "sortOrder": 1163
  },
  "🛎️": {
    "glyph": "🛎️",
    "group": "Travel & Places",
    "keywords": [
      "bell",
      "bellhop",
      "hotel"
    ],
    "dirName": "Bellhop bell",
    "styles": {
      "3D": "bellhop_bell_3d.png",
      "Animated": "Bellhop Bell.png"
    },
    "sortOrder": 944
  },
  "🍱": {
    "glyph": "🍱",
    "group": "Food & Drink",
    "keywords": [
      "bento",
      "box"
    ],
    "dirName": "Bento box",
    "styles": {
      "3D": "bento_box_3d.png",
      "Animated": "Bento Box.png"
    },
    "sortOrder": 743
  },
  "🧃": {
    "glyph": "🧃",
    "group": "Food & Drink",
    "keywords": [
      "beverage",
      "box",
      "juice",
      "straw",
      "sweet"
    ],
    "dirName": "Beverage box",
    "styles": {
      "3D": "beverage_box_3d.png",
      "Animated": "Beverage Box.png"
    },
    "sortOrder": 796
  },
  "🚲": {
    "glyph": "🚲",
    "group": "Travel & Places",
    "keywords": [
      "bicycle",
      "bike"
    ],
    "dirName": "Bicycle",
    "styles": {
      "3D": "bicycle_3d.png",
      "Animated": "Bicycle.png"
    },
    "sortOrder": 907
  },
  "👙": {
    "glyph": "👙",
    "group": "Objects",
    "keywords": [
      "bikini",
      "clothing",
      "swim"
    ],
    "dirName": "Bikini",
    "styles": {
      "3D": "bikini_3d.png",
      "Animated": "Bikini.png"
    },
    "sortOrder": 1128
  },
  "🧢": {
    "glyph": "🧢",
    "group": "Objects",
    "keywords": [
      "baseball cap",
      "billed cap"
    ],
    "dirName": "Billed cap",
    "styles": {
      "3D": "billed_cap_3d.png",
      "Animated": "Billed Cap.png"
    },
    "sortOrder": 1148
  },
  "☣️": {
    "glyph": "☣️",
    "group": "Symbols",
    "keywords": [
      "biohazard",
      "sign"
    ],
    "dirName": "Biohazard",
    "styles": {
      "3D": "biohazard_3d.png",
      "Animated": "Biohazard.png"
    },
    "sortOrder": 1390
  },
  "🐦": {
    "glyph": "🐦",
    "group": "Animals & Nature",
    "keywords": [
      "bird"
    ],
    "dirName": "Bird",
    "styles": {
      "3D": "bird_3d.png",
      "Animated": "Bird.png"
    },
    "sortOrder": 600
  },
  "🎂": {
    "glyph": "🎂",
    "group": "Food & Drink",
    "keywords": [
      "birthday",
      "cake",
      "celebration",
      "dessert",
      "pastry",
      "sweet"
    ],
    "dirName": "Birthday cake",
    "styles": {
      "3D": "birthday_cake_3d.png",
      "Animated": "Birthday Cake.png"
    },
    "sortOrder": 770
  },
  "🦬": {
    "glyph": "🦬",
    "group": "Animals & Nature",
    "keywords": [
      "bison",
      "buffalo",
      "herd",
      "wisent"
    ],
    "dirName": "Bison",
    "styles": {
      "3D": "bison_3d.png",
      "Animated": "Bison.png"
    },
    "sortOrder": 554
  },
  "🫦": {
    "glyph": "🫦",
    "group": "People & Body",
    "keywords": [
      "anxious",
      "biting lip",
      "fear",
      "flirting",
      "nervous",
      "uncomfortable",
      "worried"
    ],
    "dirName": "Biting lip",
    "styles": {
      "3D": "biting_lip_3d.png",
      "Animated": "Biting Lip.png"
    },
    "sortOrder": 222
  },
  "🐈‍⬛": {
    "glyph": "🐈‍⬛",
    "group": "Animals & Nature",
    "keywords": [
      "black",
      "cat",
      "unlucky"
    ],
    "dirName": "Black cat",
    "styles": {
      "3D": "black_cat_3d.png",
      "Animated": "Black Cat.png"
    },
    "sortOrder": 544
  },
  "⚫": {
    "glyph": "⚫",
    "group": "Symbols",
    "keywords": [
      "black circle",
      "circle",
      "geometric"
    ],
    "dirName": "Black circle",
    "styles": {
      "3D": "black_circle_3d.png",
      "Animated": "Black Circle.png"
    },
    "sortOrder": 1559
  },
  "🏴": {
    "glyph": "🏴",
    "group": "Flags",
    "keywords": [
      "black flag",
      "waving"
    ],
    "dirName": "Black flag",
    "styles": {
      "3D": "black_flag_3d.png",
      "Animated": "Black Flag.png"
    },
    "sortOrder": 1589
  },
  "🖤": {
    "glyph": "🖤",
    "group": "Smileys & Emotion",
    "keywords": [
      "black",
      "black heart",
      "evil",
      "wicked"
    ],
    "dirName": "Black heart",
    "styles": {
      "3D": "black_heart_3d.png",
      "Animated": "Black Heart.png"
    },
    "sortOrder": 148
  },
  "⬛": {
    "glyph": "⬛",
    "group": "Symbols",
    "keywords": [
      "black large square",
      "geometric",
      "square"
    ],
    "dirName": "Black large square",
    "styles": {
      "3D": "black_large_square_3d.png",
      "Animated": "Black Large Square.png"
    },
    "sortOrder": 1568
  },
  "◼️": {
    "glyph": "◼️",
    "group": "Symbols",
    "keywords": [
      "black medium square",
      "geometric",
      "square"
    ],
    "dirName": "Black medium square",
    "styles": {
      "3D": "black_medium_square_3d.png",
      "Animated": "Black Medium Square.png"
    },
    "sortOrder": 1570
  },
  "◾": {
    "glyph": "◾",
    "group": "Symbols",
    "keywords": [
      "black medium-small square",
      "geometric",
      "square"
    ],
    "dirName": "Black medium-small square",
    "styles": {
      "3D": "black_medium-small_square_3d.png",
      "Animated": "Black Medium-Small Square.png"
    },
    "sortOrder": 1572
  },
  "✒️": {
    "glyph": "✒️",
    "group": "Objects",
    "keywords": [
      "black nib",
      "nib",
      "pen"
    ],
    "dirName": "Black nib",
    "styles": {
      "3D": "black_nib_3d.png",
      "Animated": "Black Nib.png"
    },
    "sortOrder": 1259
  },
  "▪️": {
    "glyph": "▪️",
    "group": "Symbols",
    "keywords": [
      "black small square",
      "geometric",
      "square"
    ],
    "dirName": "Black small square",
    "styles": {
      "3D": "black_small_square_3d.png",
      "Animated": "Black Small Square.png"
    },
    "sortOrder": 1574
  },
  "🔲": {
    "glyph": "🔲",
    "group": "Symbols",
    "keywords": [
      "black square button",
      "button",
      "geometric",
      "square"
    ],
    "dirName": "Black square button",
    "styles": {
      "3D": "black_square_button_3d.png",
      "Animated": "Black Square Button.png"
    },
    "sortOrder": 1585
  },
  "🌼": {
    "glyph": "🌼",
    "group": "Animals & Nature",
    "keywords": [
      "blossom",
      "flower"
    ],
    "dirName": "Blossom",
    "styles": {
      "3D": "blossom_3d.png",
      "Animated": "Blossom.png"
    },
    "sortOrder": 657
  },
  "🐡": {
    "glyph": "🐡",
    "group": "Animals & Nature",
    "keywords": [
      "blowfish",
      "fish"
    ],
    "dirName": "Blowfish",
    "styles": {
      "3D": "blowfish_3d.png",
      "Animated": "Blowfish.png"
    },
    "sortOrder": 627
  },
  "📘": {
    "glyph": "📘",
    "group": "Objects",
    "keywords": [
      "blue",
      "book"
    ],
    "dirName": "Blue book",
    "styles": {
      "3D": "blue_book_3d.png",
      "Animated": "Blue Book.png"
    },
    "sortOrder": 1222
  },
  "🔵": {
    "glyph": "🔵",
    "group": "Symbols",
    "keywords": [
      "blue",
      "circle",
      "geometric"
    ],
    "dirName": "Blue circle",
    "styles": {
      "3D": "blue_circle_3d.png",
      "Animated": "Blue Circle.png"
    },
    "sortOrder": 1556
  },
  "💙": {
    "glyph": "💙",
    "group": "Smileys & Emotion",
    "keywords": [
      "blue",
      "blue heart"
    ],
    "dirName": "Blue heart",
    "styles": {
      "3D": "blue_heart_3d.png",
      "Animated": "Blue Heart.png"
    },
    "sortOrder": 145
  },
  "🟦": {
    "glyph": "🟦",
    "group": "Symbols",
    "keywords": [
      "blue",
      "square"
    ],
    "dirName": "Blue square",
    "styles": {
      "3D": "blue_square_3d.png",
      "Animated": "Blue Square.png"
    },
    "sortOrder": 1565
  },
  "🫐": {
    "glyph": "🫐",
    "group": "Food & Drink",
    "keywords": [
      "berry",
      "bilberry",
      "blue",
      "blueberries",
      "blueberry"
    ],
    "dirName": "Blueberries",
    "styles": {
      "3D": "blueberries_3d.png",
      "Animated": "Blueberries.png"
    },
    "sortOrder": 688
  },
  "🐗": {
    "glyph": "🐗",
    "group": "Animals & Nature",
    "keywords": [
      "boar",
      "pig"
    ],
    "dirName": "Boar",
    "styles": {
      "3D": "boar_3d.png",
      "Animated": "Boar.png"
    },
    "sortOrder": 561
  },
  "💣": {
    "glyph": "💣",
    "group": "Smileys & Emotion",
    "keywords": [
      "bomb",
      "comic"
    ],
    "dirName": "Bomb",
    "styles": {
      "3D": "bomb_3d.png",
      "Animated": "Bomb.png"
    },
    "sortOrder": 157
  },
  "🦴": {
    "glyph": "🦴",
    "group": "People & Body",
    "keywords": [
      "bone",
      "skeleton"
    ],
    "dirName": "Bone",
    "styles": {
      "3D": "bone_3d.png",
      "Animated": "Bone.png"
    },
    "sortOrder": 217
  },
  "🔖": {
    "glyph": "🔖",
    "group": "Objects",
    "keywords": [
      "bookmark",
      "mark"
    ],
    "dirName": "Bookmark",
    "styles": {
      "3D": "bookmark_3d.png",
      "Animated": "Bookmark.png"
    },
    "sortOrder": 1233
  },
  "📑": {
    "glyph": "📑",
    "group": "Objects",
    "keywords": [
      "bookmark",
      "mark",
      "marker",
      "tabs"
    ],
    "dirName": "Bookmark tabs",
    "styles": {
      "3D": "bookmark_tabs_3d.png",
      "Animated": "Bookmark Tabs.png"
    },
    "sortOrder": 1232
  },
  "📚": {
    "glyph": "📚",
    "group": "Objects",
    "keywords": [
      "book",
      "books"
    ],
    "dirName": "Books",
    "styles": {
      "3D": "books_3d.png",
      "Animated": "Books.png"
    },
    "sortOrder": 1224
  },
  "🪃": {
    "glyph": "🪃",
    "group": "Objects",
    "keywords": [
      "australia",
      "boomerang",
      "rebound",
      "repercussion"
    ],
    "dirName": "Boomerang",
    "styles": {
      "3D": "boomerang_3d.png",
      "Animated": "Boomerang.png"
    },
    "sortOrder": 1302
  },
  "🍾": {
    "glyph": "🍾",
    "group": "Food & Drink",
    "keywords": [
      "bar",
      "bottle",
      "bottle with popping cork",
      "cork",
      "drink",
      "popping"
    ],
    "dirName": "Bottle with popping cork",
    "styles": {
      "3D": "bottle_with_popping_cork_3d.png",
      "Animated": "Bottle with Popping Cork.png"
    },
    "sortOrder": 785
  },
  "💐": {
    "glyph": "💐",
    "group": "Animals & Nature",
    "keywords": [
      "bouquet",
      "flower"
    ],
    "dirName": "Bouquet",
    "styles": {
      "3D": "bouquet_3d.png",
      "Animated": "Bouquet.png"
    },
    "sortOrder": 648
  },
  "🏹": {
    "glyph": "🏹",
    "group": "Objects",
    "keywords": [
      "archer",
      "arrow",
      "bow",
      "bow and arrow",
      "Sagittarius",
      "zodiac"
    ],
    "dirName": "Bow and arrow",
    "styles": {
      "3D": "bow_and_arrow_3d.png",
      "Animated": "Bow and Arrow.png"
    },
    "sortOrder": 1303
  },
  "🥣": {
    "glyph": "🥣",
    "group": "Food & Drink",
    "keywords": [
      "bowl with spoon",
      "breakfast",
      "cereal",
      "congee"
    ],
    "dirName": "Bowl with spoon",
    "styles": {
      "3D": "bowl_with_spoon_3d.png",
      "Animated": "Bowl with Spoon.png"
    },
    "sortOrder": 737
  },
  "🎳": {
    "glyph": "🎳",
    "group": "Activities",
    "keywords": [
      "ball",
      "bowling",
      "game"
    ],
    "dirName": "Bowling",
    "styles": {
      "3D": "bowling_3d.png",
      "Animated": "Bowling.png"
    },
    "sortOrder": 1060
  },
  "🥊": {
    "glyph": "🥊",
    "group": "Activities",
    "keywords": [
      "boxing",
      "glove"
    ],
    "dirName": "Boxing glove",
    "styles": {
      "3D": "boxing_glove_3d.png",
      "Animated": "Boxing Glove.png"
    },
    "sortOrder": 1067
  },
  "👦": {
    "glyph": "👦",
    "group": "People & Body",
    "keywords": [
      "boy",
      "young"
    ],
    "dirName": "Boy",
    "skintones": {
      "Default": {
        "3D": "boy_3d_default.png",
        "Animated": "Boy.png"
      }
    },
    "sortOrder": 225
  },
  "🧠": {
    "glyph": "🧠",
    "group": "People & Body",
    "keywords": [
      "brain",
      "intelligent"
    ],
    "dirName": "Brain",
    "styles": {
      "3D": "brain_3d.png",
      "Animated": "Brain.png"
    },
    "sortOrder": 213
  },
  "🍞": {
    "glyph": "🍞",
    "group": "Food & Drink",
    "keywords": [
      "bread",
      "loaf"
    ],
    "dirName": "Bread",
    "styles": {
      "3D": "bread_3d.png",
      "Animated": "Bread.png"
    },
    "sortOrder": 709
  },
  "🤱": {
    "glyph": "🤱",
    "group": "People & Body",
    "keywords": [
      "baby",
      "breast",
      "breast-feeding",
      "nursing"
    ],
    "dirName": "Breast feeding",
    "skintones": {
      "Default": {
        "3D": "breast_feeding_3d_default.png",
        "Animated": "Breast-Feeding.png"
      }
    },
    "sortOrder": 359
  },
  "🧱": {
    "glyph": "🧱",
    "group": "Travel & Places",
    "keywords": [
      "brick",
      "bricks",
      "clay",
      "mortar",
      "wall"
    ],
    "dirName": "Brick",
    "styles": {
      "3D": "brick_3d.png",
      "Animated": "Brick.png"
    },
    "sortOrder": 825
  },
  "🌉": {
    "glyph": "🌉",
    "group": "Travel & Places",
    "keywords": [
      "bridge",
      "bridge at night",
      "night"
    ],
    "dirName": "Bridge at night",
    "styles": {
      "3D": "bridge_at_night_3d.png",
      "Animated": "Bridge at Night.png"
    },
    "sortOrder": 864
  },
  "💼": {
    "glyph": "💼",
    "group": "Objects",
    "keywords": [
      "briefcase"
    ],
    "dirName": "Briefcase",
    "styles": {
      "3D": "briefcase_3d.png",
      "Animated": "Briefcase.png"
    },
    "sortOrder": 1265
  },
  "🩲": {
    "glyph": "🩲",
    "group": "Objects",
    "keywords": [
      "bathing suit",
      "briefs",
      "one-piece",
      "swimsuit",
      "underwear"
    ],
    "dirName": "Briefs",
    "styles": {
      "3D": "briefs_3d.png",
      "Animated": "Briefs.png"
    },
    "sortOrder": 1126
  },
  "🔆": {
    "glyph": "🔆",
    "group": "Symbols",
    "keywords": [
      "bright",
      "bright button",
      "brightness"
    ],
    "dirName": "Bright button",
    "styles": {
      "3D": "bright_button_3d.png",
      "Animated": "Bright Button.png"
    },
    "sortOrder": 1457
  },
  "🥦": {
    "glyph": "🥦",
    "group": "Food & Drink",
    "keywords": [
      "broccoli",
      "wild cabbage"
    ],
    "dirName": "Broccoli",
    "styles": {
      "3D": "broccoli_3d.png",
      "Animated": "Broccoli.png"
    },
    "sortOrder": 702
  },
  "💔": {
    "glyph": "💔",
    "group": "Smileys & Emotion",
    "keywords": [
      "break",
      "broken",
      "broken heart"
    ],
    "dirName": "Broken heart",
    "styles": {
      "3D": "broken_heart_3d.png",
      "Animated": "Broken Heart.png"
    },
    "sortOrder": 138
  },
  "🧹": {
    "glyph": "🧹",
    "group": "Objects",
    "keywords": [
      "broom",
      "cleaning",
      "sweeping",
      "witch"
    ],
    "dirName": "Broom",
    "styles": {
      "3D": "broom_3d.png",
      "Animated": "Broom.png"
    },
    "sortOrder": 1348
  },
  "🟤": {
    "glyph": "🟤",
    "group": "Symbols",
    "keywords": [
      "brown",
      "circle"
    ],
    "dirName": "Brown circle",
    "styles": {
      "3D": "brown_circle_3d.png",
      "Animated": "Brown Circle.png"
    },
    "sortOrder": 1558
  },
  "🤎": {
    "glyph": "🤎",
    "group": "Smileys & Emotion",
    "keywords": [
      "brown",
      "heart"
    ],
    "dirName": "Brown heart",
    "styles": {
      "3D": "brown_heart_3d.png",
      "Animated": "Brown Heart.png"
    },
    "sortOrder": 147
  },
  "🟫": {
    "glyph": "🟫",
    "group": "Symbols",
    "keywords": [
      "brown",
      "square"
    ],
    "dirName": "Brown square",
    "styles": {
      "3D": "brown_square_3d.png",
      "Animated": "Brown Square.png"
    },
    "sortOrder": 1567
  },
  "🧋": {
    "glyph": "🧋",
    "group": "Food & Drink",
    "keywords": [
      "bubble",
      "milk",
      "pearl",
      "tea"
    ],
    "dirName": "Bubble tea",
    "styles": {
      "3D": "bubble_tea_3d.png",
      "Animated": "Bubble Tea.png"
    },
    "sortOrder": 795
  },
  "🫧": {
    "glyph": "🫧",
    "group": "Objects",
    "keywords": [
      "bubbles",
      "burp",
      "clean",
      "soap",
      "underwater"
    ],
    "dirName": "Bubbles",
    "styles": {
      "3D": "bubbles_3d.png",
      "Animated": "Bubbles.png"
    },
    "sortOrder": 1353
  },
  "🪣": {
    "glyph": "🪣",
    "group": "Objects",
    "keywords": [
      "bucket",
      "cask",
      "pail",
      "vat"
    ],
    "dirName": "Bucket",
    "styles": {
      "3D": "bucket_3d.png",
      "Animated": "Bucket.png"
    },
    "sortOrder": 1351
  },
  "🐛": {
    "glyph": "🐛",
    "group": "Animals & Nature",
    "keywords": [
      "bug",
      "insect"
    ],
    "dirName": "Bug",
    "styles": {
      "3D": "bug_3d.png",
      "Animated": "Bug.png"
    },
    "sortOrder": 634
  },
  "🏗️": {
    "glyph": "🏗️",
    "group": "Travel & Places",
    "keywords": [
      "building construction",
      "construction"
    ],
    "dirName": "Building construction",
    "styles": {
      "3D": "building_construction_3d.png",
      "Animated": "Building Construction.png"
    },
    "sortOrder": 824
  },
  "🚅": {
    "glyph": "🚅",
    "group": "Travel & Places",
    "keywords": [
      "bullet",
      "railway",
      "shinkansen",
      "speed",
      "train"
    ],
    "dirName": "Bullet train",
    "styles": {
      "3D": "bullet_train_3d.png",
      "Animated": "Bullet Train.png"
    },
    "sortOrder": 875
  },
  "🎯": {
    "glyph": "🎯",
    "group": "Activities",
    "keywords": [
      "bullseye",
      "dart",
      "direct hit",
      "game",
      "hit",
      "target"
    ],
    "dirName": "Bullseye",
    "styles": {
      "3D": "bullseye_3d.png",
      "Animated": "Bullseye.png"
    },
    "sortOrder": 1078
  },
  "🌯": {
    "glyph": "🌯",
    "group": "Food & Drink",
    "keywords": [
      "burrito",
      "mexican",
      "wrap"
    ],
    "dirName": "Burrito",
    "styles": {
      "3D": "burrito_3d.png",
      "Animated": "Burrito.png"
    },
    "sortOrder": 728
  },
  "🚌": {
    "glyph": "🚌",
    "group": "Travel & Places",
    "keywords": [
      "bus",
      "vehicle"
    ],
    "dirName": "Bus",
    "styles": {
      "3D": "bus_3d.png",
      "Animated": "Bus.png"
    },
    "sortOrder": 884
  },
  "🚏": {
    "glyph": "🚏",
    "group": "Travel & Places",
    "keywords": [
      "bus",
      "stop"
    ],
    "dirName": "Bus stop",
    "styles": {
      "3D": "bus_stop_3d.png",
      "Animated": "Bus Stop.png"
    },
    "sortOrder": 911
  },
  "👤": {
    "glyph": "👤",
    "group": "People & Body",
    "keywords": [
      "bust",
      "bust in silhouette",
      "silhouette"
    ],
    "dirName": "Bust in silhouette",
    "styles": {
      "3D": "bust_in_silhouette_3d.png",
      "Animated": "Bust in Silhouette.png"
    },
    "sortOrder": 521
  },
  "👥": {
    "glyph": "👥",
    "group": "People & Body",
    "keywords": [
      "bust",
      "busts in silhouette",
      "silhouette"
    ],
    "dirName": "Busts in silhouette",
    "styles": {
      "3D": "busts_in_silhouette_3d.png",
      "Animated": "Busts in Silhouette.png"
    },
    "sortOrder": 522
  },
  "🧈": {
    "glyph": "🧈",
    "group": "Food & Drink",
    "keywords": [
      "butter",
      "dairy"
    ],
    "dirName": "Butter",
    "styles": {
      "3D": "butter_3d.png",
      "Animated": "Butter.png"
    },
    "sortOrder": 740
  },
  "🦋": {
    "glyph": "🦋",
    "group": "Animals & Nature",
    "keywords": [
      "butterfly",
      "insect",
      "pretty"
    ],
    "dirName": "Butterfly",
    "styles": {
      "3D": "butterfly_3d.png",
      "Animated": "Butterfly.png"
    },
    "sortOrder": 633
  },
  "🌵": {
    "glyph": "🌵",
    "group": "Animals & Nature",
    "keywords": [
      "cactus",
      "plant"
    ],
    "dirName": "Cactus",
    "styles": {
      "3D": "cactus_3d.png",
      "Animated": "Cactus.png"
    },
    "sortOrder": 664
  },
  "📅": {
    "glyph": "📅",
    "group": "Objects",
    "keywords": [
      "calendar",
      "date"
    ],
    "dirName": "Calendar",
    "styles": {
      "3D": "calendar_3d.png",
      "Animated": "Calendar.png"
    },
    "sortOrder": 1269
  },
  "🤙": {
    "glyph": "🤙",
    "group": "People & Body",
    "keywords": [
      "call",
      "call me hand",
      "hand"
    ],
    "dirName": "Call me hand",
    "skintones": {
      "Default": {
        "3D": "call_me_hand_3d_default.png",
        "Animated": "Call Me Hand.png"
      }
    },
    "sortOrder": 181
  },
  "🐪": {
    "glyph": "🐪",
    "group": "Animals & Nature",
    "keywords": [
      "camel",
      "dromedary",
      "hump"
    ],
    "dirName": "Camel",
    "styles": {
      "3D": "camel_3d.png",
      "Animated": "Camel.png"
    },
    "sortOrder": 566
  },
  "📷": {
    "glyph": "📷",
    "group": "Objects",
    "keywords": [
      "camera",
      "video"
    ],
    "dirName": "Camera",
    "styles": {
      "3D": "camera_3d.png",
      "Animated": "Camera.png"
    },
    "sortOrder": 1207
  },
  "📸": {
    "glyph": "📸",
    "group": "Objects",
    "keywords": [
      "camera",
      "camera with flash",
      "flash",
      "video"
    ],
    "dirName": "Camera with flash",
    "styles": {
      "3D": "camera_with_flash_3d.png",
      "Animated": "Camera with Flash.png"
    },
    "sortOrder": 1208
  },
  "🏕️": {
    "glyph": "🏕️",
    "group": "Travel & Places",
    "keywords": [
      "camping"
    ],
    "dirName": "Camping",
    "styles": {
      "3D": "camping_3d.png",
      "Animated": "Camping.png"
    },
    "sortOrder": 817
  },
  "♋": {
    "glyph": "♋",
    "group": "Symbols",
    "keywords": [
      "Cancer",
      "crab",
      "zodiac"
    ],
    "dirName": "Cancer",
    "styles": {
      "3D": "cancer_3d.png",
      "Animated": "Cancer.png"
    },
    "sortOrder": 1427
  },
  "🕯️": {
    "glyph": "🕯️",
    "group": "Objects",
    "keywords": [
      "candle",
      "light"
    ],
    "dirName": "Candle",
    "styles": {
      "3D": "candle_3d.png",
      "Animated": "Candle.png"
    },
    "sortOrder": 1213
  },
  "🍬": {
    "glyph": "🍬",
    "group": "Food & Drink",
    "keywords": [
      "candy",
      "dessert",
      "sweet"
    ],
    "dirName": "Candy",
    "styles": {
      "3D": "candy_3d.png",
      "Animated": "Candy.png"
    },
    "sortOrder": 775
  },
  "🥫": {
    "glyph": "🥫",
    "group": "Food & Drink",
    "keywords": [
      "can",
      "canned food"
    ],
    "dirName": "Canned food",
    "styles": {
      "3D": "canned_food_3d.png",
      "Animated": "Canned Food.png"
    },
    "sortOrder": 742
  },
  "🛶": {
    "glyph": "🛶",
    "group": "Travel & Places",
    "keywords": [
      "boat",
      "canoe"
    ],
    "dirName": "Canoe",
    "styles": {
      "3D": "canoe_3d.png",
      "Animated": "Canoe.png"
    },
    "sortOrder": 925
  },
  "♑": {
    "glyph": "♑",
    "group": "Symbols",
    "keywords": [
      "Capricorn",
      "goat",
      "zodiac"
    ],
    "dirName": "Capricorn",
    "styles": {
      "3D": "capricorn_3d.png",
      "Animated": "Capricorn.png"
    },
    "sortOrder": 1433
  },
  "🗃️": {
    "glyph": "🗃️",
    "group": "Objects",
    "keywords": [
      "box",
      "card",
      "file"
    ],
    "dirName": "Card file box",
    "styles": {
      "3D": "card_file_box_3d.png",
      "Animated": "Card File Box.png"
    },
    "sortOrder": 1285
  },
  "📇": {
    "glyph": "📇",
    "group": "Objects",
    "keywords": [
      "card",
      "index",
      "rolodex"
    ],
    "dirName": "Card index",
    "styles": {
      "3D": "card_index_3d.png",
      "Animated": "Card Index.png"
    },
    "sortOrder": 1273
  },
  "🗂️": {
    "glyph": "🗂️",
    "group": "Objects",
    "keywords": [
      "card",
      "dividers",
      "index"
    ],
    "dirName": "Card index dividers",
    "styles": {
      "3D": "card_index_dividers_3d.png",
      "Animated": "Card Index Dividers.png"
    },
    "sortOrder": 1268
  },
  "🎠": {
    "glyph": "🎠",
    "group": "Travel & Places",
    "keywords": [
      "carousel",
      "horse"
    ],
    "dirName": "Carousel horse",
    "styles": {
      "3D": "carousel_horse_3d.png",
      "Animated": "Carousel Horse.png"
    },
    "sortOrder": 866
  },
  "🎏": {
    "glyph": "🎏",
    "group": "Activities",
    "keywords": [
      "carp",
      "celebration",
      "streamer"
    ],
    "dirName": "Carp streamer",
    "styles": {
      "3D": "carp_streamer_3d.png",
      "Animated": "Carp Streamer.png"
    },
    "sortOrder": 1036
  },
  "🪚": {
    "glyph": "🪚",
    "group": "Objects",
    "keywords": [
      "carpenter",
      "carpentry saw",
      "lumber",
      "saw",
      "tool"
    ],
    "dirName": "Carpentry saw",
    "styles": {
      "3D": "carpentry_saw_3d.png",
      "Animated": "Carpentry Saw.png"
    },
    "sortOrder": 1305
  },
  "🥕": {
    "glyph": "🥕",
    "group": "Food & Drink",
    "keywords": [
      "carrot",
      "food",
      "vegetable"
    ],
    "dirName": "Carrot",
    "styles": {
      "3D": "carrot_3d.png",
      "Animated": "Carrot.png"
    },
    "sortOrder": 696
  },
  "🏰": {
    "glyph": "🏰",
    "group": "Travel & Places",
    "keywords": [
      "castle",
      "European"
    ],
    "dirName": "Castle",
    "styles": {
      "3D": "castle_3d.png",
      "Animated": "Castle.png"
    },
    "sortOrder": 845
  },
  "🐈": {
    "glyph": "🐈",
    "group": "Animals & Nature",
    "keywords": [
      "cat",
      "pet"
    ],
    "dirName": "Cat",
    "styles": {
      "3D": "cat_3d.png",
      "Animated": "Cat.png"
    },
    "sortOrder": 543
  },
  "🐱": {
    "glyph": "🐱",
    "group": "Animals & Nature",
    "keywords": [
      "cat",
      "face",
      "pet"
    ],
    "dirName": "Cat face",
    "styles": {
      "3D": "cat_face_3d.png",
      "Animated": "Cat Face.png"
    },
    "sortOrder": 542
  },
  "😹": {
    "glyph": "😹",
    "group": "Smileys & Emotion",
    "keywords": [
      "cat",
      "cat with tears of joy",
      "face",
      "joy",
      "tear"
    ],
    "dirName": "Cat with tears of joy",
    "styles": {
      "3D": "cat_with_tears_of_joy_3d.png",
      "Animated": "Cat with Tears of Joy.png"
    },
    "sortOrder": 117
  },
  "😼": {
    "glyph": "😼",
    "group": "Smileys & Emotion",
    "keywords": [
      "cat",
      "cat with wry smile",
      "face",
      "ironic",
      "smile",
      "wry"
    ],
    "dirName": "Cat with wry smile",
    "styles": {
      "3D": "cat_with_wry_smile_3d.png",
      "Animated": "Cat with Wry Smile.png"
    },
    "sortOrder": 119
  },
  "⛓️": {
    "glyph": "⛓️",
    "group": "Objects",
    "keywords": [
      "chain",
      "chains"
    ],
    "dirName": "Chains",
    "styles": {
      "3D": "chains_3d.png",
      "Animated": "Chains.png"
    },
    "sortOrder": 1314
  },
  "🪑": {
    "glyph": "🪑",
    "group": "Objects",
    "keywords": [
      "chair",
      "seat",
      "sit"
    ],
    "dirName": "Chair",
    "styles": {
      "3D": "chair_3d.png",
      "Animated": "Chair.png"
    },
    "sortOrder": 1339
  },
  "📉": {
    "glyph": "📉",
    "group": "Objects",
    "keywords": [
      "chart",
      "chart decreasing",
      "down",
      "graph",
      "trend"
    ],
    "dirName": "Chart decreasing",
    "styles": {
      "3D": "chart_decreasing_3d.png",
      "Animated": "Chart Decreasing.png"
    },
    "sortOrder": 1275
  },
  "📈": {
    "glyph": "📈",
    "group": "Objects",
    "keywords": [
      "chart",
      "chart increasing",
      "graph",
      "growth",
      "trend",
      "upward"
    ],
    "dirName": "Chart increasing",
    "styles": {
      "3D": "chart_increasing_3d.png",
      "Animated": "Chart Increasing.png"
    },
    "sortOrder": 1274
  },
  "💹": {
    "glyph": "💹",
    "group": "Objects",
    "keywords": [
      "chart",
      "chart increasing with yen",
      "graph",
      "growth",
      "money",
      "yen"
    ],
    "dirName": "Chart increasing with yen",
    "styles": {
      "3D": "chart_increasing_with_yen_3d.png",
      "Animated": "Chart Increasing with Yen.png"
    },
    "sortOrder": 1244
  },
  "☑️": {
    "glyph": "☑️",
    "group": "Symbols",
    "keywords": [
      "✓",
      "box",
      "check",
      "check box with check"
    ],
    "dirName": "Check box with check",
    "styles": {
      "3D": "check_box_with_check_3d.png",
      "Animated": "Check Box with Check.png"
    },
    "sortOrder": 1487
  },
  "✔️": {
    "glyph": "✔️",
    "group": "Symbols",
    "keywords": [
      "✓",
      "check",
      "mark"
    ],
    "dirName": "Check mark",
    "styles": {
      "3D": "check_mark_3d.png",
      "Animated": "Check Mark.png"
    },
    "sortOrder": 1488
  },
  "✅": {
    "glyph": "✅",
    "group": "Symbols",
    "keywords": [
      "✓",
      "button",
      "check",
      "mark"
    ],
    "dirName": "Check mark button",
    "styles": {
      "3D": "check_mark_button_3d.png",
      "Animated": "Check Mark Button.png"
    },
    "sortOrder": 1486
  },
  "🧀": {
    "glyph": "🧀",
    "group": "Food & Drink",
    "keywords": [
      "cheese",
      "cheese wedge"
    ],
    "dirName": "Cheese wedge",
    "styles": {
      "3D": "cheese_wedge_3d.png",
      "Animated": "Cheese Wedge.png"
    },
    "sortOrder": 717
  },
  "🏁": {
    "glyph": "🏁",
    "group": "Flags",
    "keywords": [
      "checkered",
      "chequered",
      "chequered flag",
      "racing"
    ],
    "dirName": "Chequered flag",
    "styles": {
      "3D": "chequered_flag_3d.png",
      "Animated": "Chequered Flag.png"
    },
    "sortOrder": 1586
  },
  "🍒": {
    "glyph": "🍒",
    "group": "Food & Drink",
    "keywords": [
      "berries",
      "cherries",
      "cherry",
      "fruit",
      "red"
    ],
    "dirName": "Cherries",
    "styles": {
      "3D": "cherries_3d.png",
      "Animated": "Cherries.png"
    },
    "sortOrder": 686
  },
  "🌸": {
    "glyph": "🌸",
    "group": "Animals & Nature",
    "keywords": [
      "blossom",
      "cherry",
      "flower"
    ],
    "dirName": "Cherry blossom",
    "styles": {
      "3D": "cherry_blossom_3d.png",
      "Animated": "Cherry Blossom.png"
    },
    "sortOrder": 649
  },
  "♟️": {
    "glyph": "♟️",
    "group": "Activities",
    "keywords": [
      "chess",
      "chess pawn",
      "dupe",
      "expendable"
    ],
    "dirName": "Chess pawn",
    "styles": {
      "3D": "chess_pawn_3d.png",
      "Animated": "Chess Pawn.png"
    },
    "sortOrder": 1099
  },
  "🌰": {
    "glyph": "🌰",
    "group": "Food & Drink",
    "keywords": [
      "chestnut",
      "plant"
    ],
    "dirName": "Chestnut",
    "styles": {
      "3D": "chestnut_3d.png",
      "Animated": "Chestnut.png"
    },
    "sortOrder": 708
  },
  "🐔": {
    "glyph": "🐔",
    "group": "Animals & Nature",
    "keywords": [
      "bird",
      "chicken"
    ],
    "dirName": "Chicken",
    "styles": {
      "3D": "chicken_3d.png",
      "Animated": "Chicken.png"
    },
    "sortOrder": 595
  },
  "🧒": {
    "glyph": "🧒",
    "group": "People & Body",
    "keywords": [
      "child",
      "gender-neutral",
      "unspecified gender",
      "young"
    ],
    "dirName": "Child",
    "skintones": {
      "Default": {
        "3D": "child_3d_default.png",
        "Animated": "Child.png"
      }
    },
    "sortOrder": 224
  },
  "🚸": {
    "glyph": "🚸",
    "group": "Symbols",
    "keywords": [
      "child",
      "children crossing",
      "crossing",
      "pedestrian",
      "traffic"
    ],
    "dirName": "Children crossing",
    "styles": {
      "3D": "children_crossing_3d.png",
      "Animated": "Children Crossing.png"
    },
    "sortOrder": 1379
  },
  "🐿️": {
    "glyph": "🐿️",
    "group": "Animals & Nature",
    "keywords": [
      "chipmunk",
      "squirrel"
    ],
    "dirName": "Chipmunk",
    "styles": {
      "3D": "chipmunk_3d.png",
      "Animated": "Chipmunk.png"
    },
    "sortOrder": 580
  },
  "🍫": {
    "glyph": "🍫",
    "group": "Food & Drink",
    "keywords": [
      "bar",
      "chocolate",
      "dessert",
      "sweet"
    ],
    "dirName": "Chocolate bar",
    "styles": {
      "3D": "chocolate_bar_3d.png",
      "Animated": "Chocolate Bar.png"
    },
    "sortOrder": 774
  },
  "🥢": {
    "glyph": "🥢",
    "group": "Food & Drink",
    "keywords": [
      "chopsticks",
      "hashi"
    ],
    "dirName": "Chopsticks",
    "styles": {
      "3D": "chopsticks_3d.png",
      "Animated": "Chopsticks.png"
    },
    "sortOrder": 799
  },
  "🎄": {
    "glyph": "🎄",
    "group": "Activities",
    "keywords": [
      "celebration",
      "Christmas",
      "tree"
    ],
    "dirName": "Christmas tree",
    "styles": {
      "3D": "christmas_tree_3d.png",
      "Animated": "Christmas Tree.png"
    },
    "sortOrder": 1025
  },
  "⛪": {
    "glyph": "⛪",
    "group": "Travel & Places",
    "keywords": [
      "Christian",
      "church",
      "cross",
      "religion"
    ],
    "dirName": "Church",
    "styles": {
      "3D": "church_3d.png",
      "Animated": "Church.png"
    },
    "sortOrder": 849
  },
  "🚬": {
    "glyph": "🚬",
    "group": "Objects",
    "keywords": [
      "cigarette",
      "smoking"
    ],
    "dirName": "Cigarette",
    "styles": {
      "3D": "cigarette_3d.png",
      "Animated": "Cigarette.png"
    },
    "sortOrder": 1358
  },
  "🎦": {
    "glyph": "🎦",
    "group": "Symbols",
    "keywords": [
      "camera",
      "cinema",
      "film",
      "movie"
    ],
    "dirName": "Cinema",
    "styles": {
      "3D": "cinema_3d.png",
      "Animated": "Cinema.png"
    },
    "sortOrder": 1455
  },
  "Ⓜ️": {
    "glyph": "Ⓜ️",
    "group": "Symbols",
    "keywords": [
      "circle",
      "circled M",
      "m"
    ],
    "dirName": "Circled m",
    "styles": {
      "3D": "circled_m_3d.png",
      "Animated": "Circled M.png"
    },
    "sortOrder": 1526
  },
  "🎪": {
    "glyph": "🎪",
    "group": "Travel & Places",
    "keywords": [
      "circus",
      "tent"
    ],
    "dirName": "Circus tent",
    "styles": {
      "3D": "circus_tent_3d.png",
      "Animated": "Circus Tent.png"
    },
    "sortOrder": 871
  },
  "🏙️": {
    "glyph": "🏙️",
    "group": "Travel & Places",
    "keywords": [
      "city",
      "cityscape"
    ],
    "dirName": "Cityscape",
    "styles": {
      "3D": "cityscape_3d.png",
      "Animated": "Cityscape.png"
    },
    "sortOrder": 859
  },
  "🌆": {
    "glyph": "🌆",
    "group": "Travel & Places",
    "keywords": [
      "city",
      "cityscape at dusk",
      "dusk",
      "evening",
      "landscape",
      "sunset"
    ],
    "dirName": "Cityscape at dusk",
    "styles": {
      "3D": "cityscape_at_dusk_3d.png",
      "Animated": "Cityscape at Dusk.png"
    },
    "sortOrder": 862
  },
  "🆑": {
    "glyph": "🆑",
    "group": "Symbols",
    "keywords": [
      "cl",
      "CL button"
    ],
    "dirName": "Cl button",
    "styles": {
      "3D": "cl_button_3d.png",
      "Animated": "CL Button.png"
    },
    "sortOrder": 1521
  },
  "🗜️": {
    "glyph": "🗜️",
    "group": "Objects",
    "keywords": [
      "clamp",
      "compress",
      "tool",
      "vice"
    ],
    "dirName": "Clamp",
    "styles": {
      "3D": "clamp_3d.png",
      "Animated": "Clamp.png"
    },
    "sortOrder": 1310
  },
  "🎬": {
    "glyph": "🎬",
    "group": "Objects",
    "keywords": [
      "clapper",
      "clapper board",
      "movie"
    ],
    "dirName": "Clapper board",
    "styles": {
      "3D": "clapper_board_3d.png",
      "Animated": "Clapper Board.png"
    },
    "sortOrder": 1205
  },
  "👏": {
    "glyph": "👏",
    "group": "People & Body",
    "keywords": [
      "clap",
      "clapping hands",
      "hand"
    ],
    "dirName": "Clapping hands",
    "skintones": {
      "Default": {
        "3D": "clapping_hands_3d_default.png",
        "Animated": "Clapping Hands.png"
      }
    },
    "sortOrder": 195
  },
  "🏛️": {
    "glyph": "🏛️",
    "group": "Travel & Places",
    "keywords": [
      "classical",
      "classical building"
    ],
    "dirName": "Classical building",
    "styles": {
      "3D": "classical_building_3d.png",
      "Animated": "Classical Building.png"
    },
    "sortOrder": 823
  },
  "🍻": {
    "glyph": "🍻",
    "group": "Food & Drink",
    "keywords": [
      "bar",
      "beer",
      "clink",
      "clinking beer mugs",
      "drink",
      "mug"
    ],
    "dirName": "Clinking beer mugs",
    "styles": {
      "3D": "clinking_beer_mugs_3d.png",
      "Animated": "Clinking Beer Mugs.png"
    },
    "sortOrder": 790
  },
  "🥂": {
    "glyph": "🥂",
    "group": "Food & Drink",
    "keywords": [
      "celebrate",
      "clink",
      "clinking glasses",
      "drink",
      "glass"
    ],
    "dirName": "Clinking glasses",
    "styles": {
      "3D": "clinking_glasses_3d.png",
      "Animated": "Clinking Glasses.png"
    },
    "sortOrder": 791
  },
  "📋": {
    "glyph": "📋",
    "group": "Objects",
    "keywords": [
      "clipboard"
    ],
    "dirName": "Clipboard",
    "styles": {
      "3D": "clipboard_3d.png",
      "Animated": "Clipboard.png"
    },
    "sortOrder": 1277
  },
  "🔃": {
    "glyph": "🔃",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "clockwise",
      "clockwise vertical arrows",
      "reload"
    ],
    "dirName": "Clockwise vertical arrows",
    "styles": {
      "3D": "clockwise_vertical_arrows_3d.png",
      "Animated": "Clockwise Vertical Arrows.png"
    },
    "sortOrder": 1405
  },
  "📕": {
    "glyph": "📕",
    "group": "Objects",
    "keywords": [
      "book",
      "closed"
    ],
    "dirName": "Closed book",
    "styles": {
      "3D": "closed_book_3d.png",
      "Animated": "Closed Book.png"
    },
    "sortOrder": 1219
  },
  "📪": {
    "glyph": "📪",
    "group": "Objects",
    "keywords": [
      "closed",
      "closed mailbox with lowered flag",
      "lowered",
      "mail",
      "mailbox",
      "postbox"
    ],
    "dirName": "Closed mailbox with lowered flag",
    "styles": {
      "3D": "closed_mailbox_with_lowered_flag_3d.png",
      "Animated": "Closed Mailbox with Lowered Flag.png"
    },
    "sortOrder": 1253
  },
  "📫": {
    "glyph": "📫",
    "group": "Objects",
    "keywords": [
      "closed",
      "closed mailbox with raised flag",
      "mail",
      "mailbox",
      "postbox"
    ],
    "dirName": "Closed mailbox with raised flag",
    "styles": {
      "3D": "closed_mailbox_with_raised_flag_3d.png",
      "Animated": "Closed Mailbox with Raised Flag.png"
    },
    "sortOrder": 1252
  },
  "🌂": {
    "glyph": "🌂",
    "group": "Travel & Places",
    "keywords": [
      "closed umbrella",
      "clothing",
      "rain",
      "umbrella"
    ],
    "dirName": "Closed umbrella",
    "styles": {
      "3D": "closed_umbrella_3d.png",
      "Animated": "Closed Umbrella.png"
    },
    "sortOrder": 1012
  },
  "☁️": {
    "glyph": "☁️",
    "group": "Travel & Places",
    "keywords": [
      "cloud",
      "weather"
    ],
    "dirName": "Cloud",
    "styles": {
      "3D": "cloud_3d.png",
      "Animated": "Cloud.png"
    },
    "sortOrder": 998
  },
  "🌩️": {
    "glyph": "🌩️",
    "group": "Travel & Places",
    "keywords": [
      "cloud",
      "cloud with lightning",
      "lightning"
    ],
    "dirName": "Cloud with lightning",
    "styles": {
      "3D": "cloud_with_lightning_3d.png",
      "Animated": "Cloud with Lightning.png"
    },
    "sortOrder": 1006
  },
  "⛈️": {
    "glyph": "⛈️",
    "group": "Travel & Places",
    "keywords": [
      "cloud",
      "cloud with lightning and rain",
      "rain",
      "thunder"
    ],
    "dirName": "Cloud with lightning and rain",
    "styles": {
      "3D": "cloud_with_lightning_and_rain_3d.png",
      "Animated": "Cloud with Lightning and Rain.png"
    },
    "sortOrder": 1000
  },
  "🌧️": {
    "glyph": "🌧️",
    "group": "Travel & Places",
    "keywords": [
      "cloud",
      "cloud with rain",
      "rain"
    ],
    "dirName": "Cloud with rain",
    "styles": {
      "3D": "cloud_with_rain_3d.png",
      "Animated": "Cloud with Rain.png"
    },
    "sortOrder": 1004
  },
  "🌨️": {
    "glyph": "🌨️",
    "group": "Travel & Places",
    "keywords": [
      "cloud",
      "cloud with snow",
      "cold",
      "snow"
    ],
    "dirName": "Cloud with snow",
    "styles": {
      "3D": "cloud_with_snow_3d.png",
      "Animated": "Cloud with Snow.png"
    },
    "sortOrder": 1005
  },
  "🤡": {
    "glyph": "🤡",
    "group": "Smileys & Emotion",
    "keywords": [
      "clown",
      "face"
    ],
    "dirName": "Clown face",
    "styles": {
      "3D": "clown_face_3d.png",
      "Animated": "Clown Face.png"
    },
    "sortOrder": 108
  },
  "♣️": {
    "glyph": "♣️",
    "group": "Activities",
    "keywords": [
      "card",
      "club suit",
      "game"
    ],
    "dirName": "Club suit",
    "styles": {
      "3D": "club_suit_3d.png",
      "Animated": "Club Suit.png"
    },
    "sortOrder": 1098
  },
  "👝": {
    "glyph": "👝",
    "group": "Objects",
    "keywords": [
      "bag",
      "clothing",
      "clutch bag",
      "pouch"
    ],
    "dirName": "Clutch bag",
    "styles": {
      "3D": "clutch_bag_3d.png",
      "Animated": "Clutch Bag.png"
    },
    "sortOrder": 1132
  },
  "🧥": {
    "glyph": "🧥",
    "group": "Objects",
    "keywords": [
      "coat",
      "jacket"
    ],
    "dirName": "Coat",
    "styles": {
      "3D": "coat_3d.png",
      "Animated": "Coat.png"
    },
    "sortOrder": 1120
  },
  "🪳": {
    "glyph": "🪳",
    "group": "Animals & Nature",
    "keywords": [
      "cockroach",
      "insect",
      "pest",
      "roach"
    ],
    "dirName": "Cockroach",
    "styles": {
      "3D": "cockroach_3d.png",
      "Animated": "Cockroach.png"
    },
    "sortOrder": 640
  },
  "🍸": {
    "glyph": "🍸",
    "group": "Food & Drink",
    "keywords": [
      "bar",
      "cocktail",
      "drink",
      "glass"
    ],
    "dirName": "Cocktail glass",
    "styles": {
      "3D": "cocktail_glass_3d.png",
      "Animated": "Cocktail Glass.png"
    },
    "sortOrder": 787
  },
  "🥥": {
    "glyph": "🥥",
    "group": "Food & Drink",
    "keywords": [
      "coconut",
      "palm",
      "piña colada"
    ],
    "dirName": "Coconut",
    "styles": {
      "3D": "coconut_3d.png",
      "Animated": "Coconut.png"
    },
    "sortOrder": 692
  },
  "⚰️": {
    "glyph": "⚰️",
    "group": "Objects",
    "keywords": [
      "coffin",
      "death"
    ],
    "dirName": "Coffin",
    "styles": {
      "3D": "coffin_3d.png",
      "Animated": "Coffin.png"
    },
    "sortOrder": 1359
  },
  "🪙": {
    "glyph": "🪙",
    "group": "Objects",
    "keywords": [
      "coin",
      "gold",
      "metal",
      "money",
      "silver",
      "treasure"
    ],
    "dirName": "Coin",
    "styles": {
      "3D": "coin_3d.png",
      "Animated": "Coin.png"
    },
    "sortOrder": 1236
  },
  "🥶": {
    "glyph": "🥶",
    "group": "Smileys & Emotion",
    "keywords": [
      "blue-faced",
      "cold",
      "cold face",
      "freezing",
      "frostbite",
      "icicles"
    ],
    "dirName": "Cold face",
    "styles": {
      "3D": "cold_face_3d.png",
      "Animated": "Cold Face.png"
    },
    "sortOrder": 62
  },
  "💥": {
    "glyph": "💥",
    "group": "Smileys & Emotion",
    "keywords": [
      "boom",
      "collision",
      "comic"
    ],
    "dirName": "Collision",
    "styles": {
      "3D": "collision_3d.png",
      "Animated": "Collision.png"
    },
    "sortOrder": 152
  },
  "☄️": {
    "glyph": "☄️",
    "group": "Travel & Places",
    "keywords": [
      "comet",
      "space"
    ],
    "dirName": "Comet",
    "styles": {
      "3D": "comet_3d.png",
      "Animated": "Comet.png"
    },
    "sortOrder": 1020
  },
  "🧭": {
    "glyph": "🧭",
    "group": "Travel & Places",
    "keywords": [
      "compass",
      "magnetic",
      "navigation",
      "orienteering"
    ],
    "dirName": "Compass",
    "styles": {
      "3D": "compass_3d.png",
      "Animated": "Compass.png"
    },
    "sortOrder": 812
  },
  "💽": {
    "glyph": "💽",
    "group": "Objects",
    "keywords": [
      "computer",
      "disk",
      "minidisk",
      "optical"
    ],
    "dirName": "Computer disk",
    "styles": {
      "3D": "computer_disk_3d.png",
      "Animated": "Computer Disk.png"
    },
    "sortOrder": 1197
  },
  "🖱️": {
    "glyph": "🖱️",
    "group": "Objects",
    "keywords": [
      "computer",
      "computer mouse"
    ],
    "dirName": "Computer mouse",
    "styles": {
      "3D": "computer_mouse_3d.png",
      "Animated": "Computer Mouse.png"
    },
    "sortOrder": 1195
  },
  "🎊": {
    "glyph": "🎊",
    "group": "Activities",
    "keywords": [
      "ball",
      "celebration",
      "confetti"
    ],
    "dirName": "Confetti ball",
    "styles": {
      "3D": "confetti_ball_3d.png",
      "Animated": "Confetti Ball.png"
    },
    "sortOrder": 1032
  },
  "😖": {
    "glyph": "😖",
    "group": "Smileys & Emotion",
    "keywords": [
      "confounded",
      "face"
    ],
    "dirName": "Confounded face",
    "styles": {
      "3D": "confounded_face_3d.png",
      "Animated": "Confounded Face.png"
    },
    "sortOrder": 92
  },
  "😕": {
    "glyph": "😕",
    "group": "Smileys & Emotion",
    "keywords": [
      "confused",
      "face",
      "meh"
    ],
    "dirName": "Confused face",
    "styles": {
      "3D": "confused_face_3d.png",
      "Animated": "Confused Face.png"
    },
    "sortOrder": 73
  },
  "🚧": {
    "glyph": "🚧",
    "group": "Travel & Places",
    "keywords": [
      "barrier",
      "construction"
    ],
    "dirName": "Construction",
    "styles": {
      "3D": "construction_3d.png",
      "Animated": "Construction.png"
    },
    "sortOrder": 921
  },
  "👷": {
    "glyph": "👷",
    "group": "People & Body",
    "keywords": [
      "construction",
      "hat",
      "worker"
    ],
    "dirName": "Construction worker",
    "skintones": {
      "Default": {
        "3D": "construction_worker_3d_default.png",
        "Animated": "Construction Worker.png"
      }
    },
    "sortOrder": 339
  },
  "🎛️": {
    "glyph": "🎛️",
    "group": "Objects",
    "keywords": [
      "control",
      "knobs",
      "music"
    ],
    "dirName": "Control knobs",
    "styles": {
      "3D": "control_knobs_3d.png",
      "Animated": "Control Knobs.png"
    },
    "sortOrder": 1169
  },
  "🏪": {
    "glyph": "🏪",
    "group": "Travel & Places",
    "keywords": [
      "convenience",
      "store"
    ],
    "dirName": "Convenience store",
    "styles": {
      "3D": "convenience_store_3d.png",
      "Animated": "Convenience Store.png"
    },
    "sortOrder": 840
  },
  "🧑‍🍳": {
    "glyph": "🧑‍🍳",
    "group": "People & Body",
    "keywords": [
      "chef",
      "cook"
    ],
    "dirName": "Cook",
    "skintones": {
      "Default": {
        "3D": "cook_3d_default.png",
        "Animated": "Cook.png"
      }
    },
    "sortOrder": 296
  },
  "🍚": {
    "glyph": "🍚",
    "group": "Food & Drink",
    "keywords": [
      "cooked",
      "rice"
    ],
    "dirName": "Cooked rice",
    "styles": {
      "3D": "cooked_rice_3d.png",
      "Animated": "Cooked Rice.png"
    },
    "sortOrder": 746
  },
  "🍪": {
    "glyph": "🍪",
    "group": "Food & Drink",
    "keywords": [
      "cookie",
      "dessert",
      "sweet"
    ],
    "dirName": "Cookie",
    "styles": {
      "3D": "cookie_3d.png",
      "Animated": "Cookie.png"
    },
    "sortOrder": 769
  },
  "🍳": {
    "glyph": "🍳",
    "group": "Food & Drink",
    "keywords": [
      "breakfast",
      "cooking",
      "egg",
      "frying",
      "pan"
    ],
    "dirName": "Cooking",
    "styles": {
      "3D": "cooking_3d.png",
      "Animated": "Cooking.png"
    },
    "sortOrder": 733
  },
  "🆒": {
    "glyph": "🆒",
    "group": "Symbols",
    "keywords": [
      "cool",
      "COOL button"
    ],
    "dirName": "Cool button",
    "styles": {
      "3D": "cool_button_3d.png",
      "Animated": "Cool Button.png"
    },
    "sortOrder": 1522
  },
  "©️": {
    "glyph": "©️",
    "group": "Symbols",
    "keywords": [
      "c",
      "copyright"
    ],
    "dirName": "Copyright",
    "styles": {
      "3D": "copyright_3d.png",
      "Animated": "Copyright.png"
    },
    "sortOrder": 1497
  },
  "🪸": {
    "glyph": "🪸",
    "group": "Animals & Nature",
    "keywords": [
      "coral",
      "ocean",
      "reef"
    ],
    "dirName": "Coral",
    "styles": {
      "3D": "coral_3d.png",
      "Animated": "Coral.png"
    },
    "sortOrder": 631
  },
  "🛋️": {
    "glyph": "🛋️",
    "group": "Objects",
    "keywords": [
      "couch",
      "couch and lamp",
      "hotel",
      "lamp"
    ],
    "dirName": "Couch and lamp",
    "styles": {
      "3D": "couch_and_lamp_3d.png",
      "Animated": "Couch and Lamp.png"
    },
    "sortOrder": 1338
  },
  "🔄": {
    "glyph": "🔄",
    "group": "Symbols",
    "keywords": [
      "anticlockwise",
      "arrow",
      "counterclockwise",
      "counterclockwise arrows button",
      "withershins"
    ],
    "dirName": "Counterclockwise arrows button",
    "styles": {
      "3D": "counterclockwise_arrows_button_3d.png",
      "Animated": "Counterclockwise Arrows Button.png"
    },
    "sortOrder": 1406
  },
  "🐄": {
    "glyph": "🐄",
    "group": "Animals & Nature",
    "keywords": [
      "cow"
    ],
    "dirName": "Cow",
    "styles": {
      "3D": "cow_3d.png",
      "Animated": "Cow.png"
    },
    "sortOrder": 558
  },
  "🐮": {
    "glyph": "🐮",
    "group": "Animals & Nature",
    "keywords": [
      "cow",
      "face"
    ],
    "dirName": "Cow face",
    "styles": {
      "3D": "cow_face_3d.png",
      "Animated": "Cow Face.png"
    },
    "sortOrder": 555
  },
  "🤠": {
    "glyph": "🤠",
    "group": "Smileys & Emotion",
    "keywords": [
      "cowboy",
      "cowgirl",
      "face",
      "hat"
    ],
    "dirName": "Cowboy hat face",
    "styles": {
      "3D": "cowboy_hat_face_3d.png",
      "Animated": "Cowboy Hat Face.png"
    },
    "sortOrder": 67
  },
  "🦀": {
    "glyph": "🦀",
    "group": "Food & Drink",
    "keywords": [
      "Cancer",
      "crab",
      "zodiac"
    ],
    "dirName": "Crab",
    "styles": {
      "3D": "crab_3d.png",
      "Animated": "Crab.png"
    },
    "sortOrder": 760
  },
  "🖍️": {
    "glyph": "🖍️",
    "group": "Objects",
    "keywords": [
      "crayon"
    ],
    "dirName": "Crayon",
    "styles": {
      "3D": "crayon_3d.png",
      "Animated": "Crayon.png"
    },
    "sortOrder": 1263
  },
  "💳": {
    "glyph": "💳",
    "group": "Objects",
    "keywords": [
      "card",
      "credit",
      "money"
    ],
    "dirName": "Credit card",
    "styles": {
      "3D": "credit_card_3d.png",
      "Animated": "Credit Card.png"
    },
    "sortOrder": 1242
  },
  "🌙": {
    "glyph": "🌙",
    "group": "Travel & Places",
    "keywords": [
      "crescent",
      "moon"
    ],
    "dirName": "Crescent moon",
    "styles": {
      "3D": "crescent_moon_3d.png",
      "Animated": "Crescent Moon.png"
    },
    "sortOrder": 985
  },
  "🦗": {
    "glyph": "🦗",
    "group": "Animals & Nature",
    "keywords": [
      "cricket",
      "grasshopper"
    ],
    "dirName": "Cricket",
    "styles": {
      "3D": "cricket_3d.png",
      "Animated": "Cricket.png"
    },
    "sortOrder": 639
  },
  "🏏": {
    "glyph": "🏏",
    "group": "Activities",
    "keywords": [
      "ball",
      "bat",
      "cricket game",
      "game"
    ],
    "dirName": "Cricket game",
    "styles": {
      "3D": "cricket_game_3d.png",
      "Animated": "Cricket Game.png"
    },
    "sortOrder": 1061
  },
  "🐊": {
    "glyph": "🐊",
    "group": "Animals & Nature",
    "keywords": [
      "crocodile"
    ],
    "dirName": "Crocodile",
    "styles": {
      "3D": "crocodile_3d.png",
      "Animated": "Crocodile.png"
    },
    "sortOrder": 613
  },
  "🥐": {
    "glyph": "🥐",
    "group": "Food & Drink",
    "keywords": [
      "bread",
      "breakfast",
      "croissant",
      "food",
      "french",
      "roll"
    ],
    "dirName": "Croissant",
    "styles": {
      "3D": "croissant_3d.png",
      "Animated": "Croissant.png"
    },
    "sortOrder": 710
  },
  "❌": {
    "glyph": "❌",
    "group": "Symbols",
    "keywords": [
      "×",
      "cancel",
      "cross",
      "mark",
      "multiplication",
      "multiply",
      "x"
    ],
    "dirName": "Cross mark",
    "styles": {
      "3D": "cross_mark_3d.png",
      "Animated": "Cross Mark.png"
    },
    "sortOrder": 1489
  },
  "❎": {
    "glyph": "❎",
    "group": "Symbols",
    "keywords": [
      "×",
      "cross mark button",
      "mark",
      "square",
      "x"
    ],
    "dirName": "Cross mark button",
    "styles": {
      "3D": "cross_mark_button_3d.png",
      "Animated": "Cross Mark Button.png"
    },
    "sortOrder": 1490
  },
  "🤞": {
    "glyph": "🤞",
    "group": "People & Body",
    "keywords": [
      "cross",
      "crossed fingers",
      "finger",
      "hand",
      "luck"
    ],
    "dirName": "Crossed fingers",
    "skintones": {
      "Default": {
        "3D": "crossed_fingers_3d_default.png",
        "Animated": "Crossed Fingers.png"
      }
    },
    "sortOrder": 177
  },
  "🎌": {
    "glyph": "🎌",
    "group": "Flags",
    "keywords": [
      "celebration",
      "cross",
      "crossed",
      "crossed flags",
      "Japanese"
    ],
    "dirName": "Crossed flags",
    "styles": {
      "3D": "crossed_flags_3d.png",
      "Animated": "Crossed Flags.png"
    },
    "sortOrder": 1588
  },
  "⚔️": {
    "glyph": "⚔️",
    "group": "Objects",
    "keywords": [
      "crossed",
      "swords",
      "weapon"
    ],
    "dirName": "Crossed swords",
    "styles": {
      "3D": "crossed_swords_3d.png",
      "Animated": "Crossed Swords.png"
    },
    "sortOrder": 1300
  },
  "👑": {
    "glyph": "👑",
    "group": "Objects",
    "keywords": [
      "clothing",
      "crown",
      "king",
      "queen"
    ],
    "dirName": "Crown",
    "styles": {
      "3D": "crown_3d.png",
      "Animated": "Crown.png"
    },
    "sortOrder": 1144
  },
  "🩼": {
    "glyph": "🩼",
    "group": "Objects",
    "keywords": [
      "cane",
      "crutch",
      "disability",
      "hurt",
      "mobility aid",
      "stick"
    ],
    "dirName": "Crutch",
    "styles": {
      "3D": "crutch_3d.png",
      "Animated": "Crutch.png"
    },
    "sortOrder": 1330
  },
  "😿": {
    "glyph": "😿",
    "group": "Smileys & Emotion",
    "keywords": [
      "cat",
      "cry",
      "crying cat",
      "face",
      "sad",
      "tear"
    ],
    "dirName": "Crying cat",
    "styles": {
      "3D": "crying_cat_3d.png",
      "Animated": "Crying Cat.png"
    },
    "sortOrder": 122
  },
  "😢": {
    "glyph": "😢",
    "group": "Smileys & Emotion",
    "keywords": [
      "cry",
      "crying face",
      "face",
      "sad",
      "tear"
    ],
    "dirName": "Crying face",
    "styles": {
      "3D": "crying_face_3d.png",
      "Animated": "Crying Face.png"
    },
    "sortOrder": 89
  },
  "🔮": {
    "glyph": "🔮",
    "group": "Activities",
    "keywords": [
      "ball",
      "crystal",
      "fairy tale",
      "fantasy",
      "fortune",
      "tool"
    ],
    "dirName": "Crystal ball",
    "styles": {
      "3D": "crystal_ball_3d.png",
      "Animated": "Crystal Ball.png"
    },
    "sortOrder": 1082
  },
  "🥒": {
    "glyph": "🥒",
    "group": "Food & Drink",
    "keywords": [
      "cucumber",
      "food",
      "pickle",
      "vegetable"
    ],
    "dirName": "Cucumber",
    "styles": {
      "3D": "cucumber_3d.png",
      "Animated": "Cucumber.png"
    },
    "sortOrder": 700
  },
  "🥤": {
    "glyph": "🥤",
    "group": "Food & Drink",
    "keywords": [
      "cup with straw",
      "juice",
      "soda"
    ],
    "dirName": "Cup with straw",
    "styles": {
      "3D": "cup_with_straw_3d.png",
      "Animated": "Cup with Straw.png"
    },
    "sortOrder": 794
  },
  "🧁": {
    "glyph": "🧁",
    "group": "Food & Drink",
    "keywords": [
      "bakery",
      "cupcake",
      "sweet"
    ],
    "dirName": "Cupcake",
    "styles": {
      "3D": "cupcake_3d.png",
      "Animated": "Cupcake.png"
    },
    "sortOrder": 772
  },
  "🥌": {
    "glyph": "🥌",
    "group": "Activities",
    "keywords": [
      "curling stone",
      "game",
      "rock"
    ],
    "dirName": "Curling stone",
    "styles": {
      "3D": "curling_stone_3d.png",
      "Animated": "Curling Stone.png"
    },
    "sortOrder": 1077
  },
  "➰": {
    "glyph": "➰",
    "group": "Symbols",
    "keywords": [
      "curl",
      "curly loop",
      "loop"
    ],
    "dirName": "Curly loop",
    "styles": {
      "3D": "curly_loop_3d.png",
      "Animated": "Curly Loop.png"
    },
    "sortOrder": 1491
  },
  "💱": {
    "glyph": "💱",
    "group": "Symbols",
    "keywords": [
      "bank",
      "currency",
      "exchange",
      "money"
    ],
    "dirName": "Currency exchange",
    "styles": {
      "3D": "currency_exchange_3d.png",
      "Animated": "Currency Exchange.png"
    },
    "sortOrder": 1477
  },
  "🍛": {
    "glyph": "🍛",
    "group": "Food & Drink",
    "keywords": [
      "curry",
      "rice"
    ],
    "dirName": "Curry rice",
    "styles": {
      "3D": "curry_rice_3d.png",
      "Animated": "Curry Rice.png"
    },
    "sortOrder": 747
  },
  "🍮": {
    "glyph": "🍮",
    "group": "Food & Drink",
    "keywords": [
      "custard",
      "dessert",
      "pudding",
      "sweet"
    ],
    "dirName": "Custard",
    "styles": {
      "3D": "custard_3d.png",
      "Animated": "Custard.png"
    },
    "sortOrder": 777
  },
  "🛃": {
    "glyph": "🛃",
    "group": "Symbols",
    "keywords": [
      "customs"
    ],
    "dirName": "Customs",
    "styles": {
      "3D": "customs_3d.png",
      "Animated": "Customs.png"
    },
    "sortOrder": 1375
  },
  "🥩": {
    "glyph": "🥩",
    "group": "Food & Drink",
    "keywords": [
      "chop",
      "cut of meat",
      "lambchop",
      "porkchop",
      "steak"
    ],
    "dirName": "Cut of meat",
    "styles": {
      "3D": "cut_of_meat_3d.png",
      "Animated": "Cut of Meat.png"
    },
    "sortOrder": 720
  },
  "🌀": {
    "glyph": "🌀",
    "group": "Travel & Places",
    "keywords": [
      "cyclone",
      "dizzy",
      "hurricane",
      "twister",
      "typhoon"
    ],
    "dirName": "Cyclone",
    "styles": {
      "3D": "cyclone_3d.png",
      "Animated": "Cyclone.png"
    },
    "sortOrder": 1010
  },
  "🗡️": {
    "glyph": "🗡️",
    "group": "Objects",
    "keywords": [
      "dagger",
      "knife",
      "weapon"
    ],
    "dirName": "Dagger",
    "styles": {
      "3D": "dagger_3d.png",
      "Animated": "Dagger.png"
    },
    "sortOrder": 1299
  },
  "🍡": {
    "glyph": "🍡",
    "group": "Food & Drink",
    "keywords": [
      "dango",
      "dessert",
      "Japanese",
      "skewer",
      "stick",
      "sweet"
    ],
    "dirName": "Dango",
    "styles": {
      "3D": "dango_3d.png",
      "Animated": "Dango.png"
    },
    "sortOrder": 756
  },
  "💨": {
    "glyph": "💨",
    "group": "Smileys & Emotion",
    "keywords": [
      "comic",
      "dash",
      "dashing away",
      "running"
    ],
    "dirName": "Dashing away",
    "styles": {
      "3D": "dashing_away_3d.png",
      "Animated": "Dashing Away.png"
    },
    "sortOrder": 155
  },
  "🌳": {
    "glyph": "🌳",
    "group": "Animals & Nature",
    "keywords": [
      "deciduous",
      "shedding",
      "tree"
    ],
    "dirName": "Deciduous tree",
    "styles": {
      "3D": "deciduous_tree_3d.png",
      "Animated": "Deciduous Tree.png"
    },
    "sortOrder": 662
  },
  "🦌": {
    "glyph": "🦌",
    "group": "Animals & Nature",
    "keywords": [
      "deer"
    ],
    "dirName": "Deer",
    "styles": {
      "3D": "deer_3d.png",
      "Animated": "Deer.png"
    },
    "sortOrder": 553
  },
  "🚚": {
    "glyph": "🚚",
    "group": "Travel & Places",
    "keywords": [
      "delivery",
      "truck"
    ],
    "dirName": "Delivery truck",
    "styles": {
      "3D": "delivery_truck_3d.png",
      "Animated": "Delivery Truck.png"
    },
    "sortOrder": 898
  },
  "🏬": {
    "glyph": "🏬",
    "group": "Travel & Places",
    "keywords": [
      "department",
      "store"
    ],
    "dirName": "Department store",
    "styles": {
      "3D": "department_store_3d.png",
      "Animated": "Department Store.png"
    },
    "sortOrder": 842
  },
  "🏚️": {
    "glyph": "🏚️",
    "group": "Travel & Places",
    "keywords": [
      "derelict",
      "house"
    ],
    "dirName": "Derelict house",
    "styles": {
      "3D": "derelict_house_3d.png",
      "Animated": "Derelict House.png"
    },
    "sortOrder": 830
  },
  "🏜️": {
    "glyph": "🏜️",
    "group": "Travel & Places",
    "keywords": [
      "desert"
    ],
    "dirName": "Desert",
    "styles": {
      "3D": "desert_3d.png",
      "Animated": "Desert.png"
    },
    "sortOrder": 819
  },
  "🏝️": {
    "glyph": "🏝️",
    "group": "Travel & Places",
    "keywords": [
      "desert",
      "island"
    ],
    "dirName": "Desert island",
    "styles": {
      "3D": "desert_island_3d.png",
      "Animated": "Desert Island.png"
    },
    "sortOrder": 820
  },
  "🖥️": {
    "glyph": "🖥️",
    "group": "Objects",
    "keywords": [
      "computer",
      "desktop"
    ],
    "dirName": "Desktop computer",
    "styles": {
      "3D": "desktop_computer_3d.png",
      "Animated": "Desktop Computer.png"
    },
    "sortOrder": 1192
  },
  "🕵️": {
    "glyph": "🕵️",
    "group": "People & Body",
    "keywords": [
      "detective",
      "sleuth",
      "spy"
    ],
    "dirName": "Detective",
    "skintones": {
      "Default": {
        "3D": "detective_3d_default.png",
        "Animated": "Detective.png"
      }
    },
    "sortOrder": 332
  },
  "♦️": {
    "glyph": "♦️",
    "group": "Activities",
    "keywords": [
      "card",
      "diamond suit",
      "game"
    ],
    "dirName": "Diamond suit",
    "styles": {
      "3D": "diamond_suit_3d.png",
      "Animated": "Diamond Suit.png"
    },
    "sortOrder": 1097
  },
  "💠": {
    "glyph": "💠",
    "group": "Symbols",
    "keywords": [
      "comic",
      "diamond",
      "diamond with a dot",
      "geometric",
      "inside"
    ],
    "dirName": "Diamond with a dot",
    "styles": {
      "3D": "diamond_with_a_dot_3d.png",
      "Animated": "Diamond with a Dot.png"
    },
    "sortOrder": 1582
  },
  "🔅": {
    "glyph": "🔅",
    "group": "Symbols",
    "keywords": [
      "brightness",
      "dim",
      "dim button",
      "low"
    ],
    "dirName": "Dim button",
    "styles": {
      "3D": "dim_button_3d.png",
      "Animated": "Dim Button.png"
    },
    "sortOrder": 1456
  },
  "😞": {
    "glyph": "😞",
    "group": "Smileys & Emotion",
    "keywords": [
      "disappointed",
      "face"
    ],
    "dirName": "Disappointed face",
    "styles": {
      "3D": "disappointed_face_3d.png",
      "Animated": "Disappointed Face.png"
    },
    "sortOrder": 94
  },
  "🥸": {
    "glyph": "🥸",
    "group": "Smileys & Emotion",
    "keywords": [
      "disguise",
      "disguised face",
      "face",
      "glasses",
      "incognito",
      "nose"
    ],
    "dirName": "Disguised face",
    "styles": {
      "3D": "disguised_face_3d.png",
      "Animated": "Disguised Face.png"
    },
    "sortOrder": 69
  },
  "➗": {
    "glyph": "➗",
    "group": "Symbols",
    "keywords": [
      "÷",
      "divide",
      "division",
      "math",
      "sign"
    ],
    "dirName": "Divide",
    "styles": {
      "3D": "divide_3d.png",
      "Animated": "Divide.png"
    },
    "sortOrder": 1467
  },
  "🤿": {
    "glyph": "🤿",
    "group": "Activities",
    "keywords": [
      "diving",
      "diving mask",
      "scuba",
      "snorkeling"
    ],
    "dirName": "Diving mask",
    "styles": {
      "3D": "diving_mask_3d.png",
      "Animated": "Diving Mask.png"
    },
    "sortOrder": 1073
  },
  "🪔": {
    "glyph": "🪔",
    "group": "Objects",
    "keywords": [
      "diya",
      "lamp",
      "oil"
    ],
    "dirName": "Diya lamp",
    "styles": {
      "3D": "diya_lamp_3d.png",
      "Animated": "Diya Lamp.png"
    },
    "sortOrder": 1217
  },
  "💫": {
    "glyph": "💫",
    "group": "Smileys & Emotion",
    "keywords": [
      "comic",
      "dizzy",
      "star"
    ],
    "dirName": "Dizzy",
    "styles": {
      "3D": "dizzy_3d.png",
      "Animated": "Dizzy.png"
    },
    "sortOrder": 153
  },
  "🧬": {
    "glyph": "🧬",
    "group": "Objects",
    "keywords": [
      "biologist",
      "dna",
      "evolution",
      "gene",
      "genetics",
      "life"
    ],
    "dirName": "Dna",
    "styles": {
      "3D": "dna_3d.png",
      "Animated": "DNA.png"
    },
    "sortOrder": 1322
  },
  "🦤": {
    "glyph": "🦤",
    "group": "Animals & Nature",
    "keywords": [
      "dodo",
      "extinction",
      "large",
      "Mauritius"
    ],
    "dirName": "Dodo",
    "styles": {
      "3D": "dodo_3d.png",
      "Animated": "Dodo.png"
    },
    "sortOrder": 607
  },
  "🐕": {
    "glyph": "🐕",
    "group": "Animals & Nature",
    "keywords": [
      "dog",
      "pet"
    ],
    "dirName": "Dog",
    "styles": {
      "3D": "dog_3d.png",
      "Animated": "Dog.png"
    },
    "sortOrder": 535
  },
  "🐶": {
    "glyph": "🐶",
    "group": "Animals & Nature",
    "keywords": [
      "dog",
      "face",
      "pet"
    ],
    "dirName": "Dog face",
    "styles": {
      "3D": "dog_face_3d.png",
      "Animated": "Dog Face.png"
    },
    "sortOrder": 534
  },
  "💵": {
    "glyph": "💵",
    "group": "Objects",
    "keywords": [
      "banknote",
      "bill",
      "currency",
      "dollar",
      "money",
      "note"
    ],
    "dirName": "Dollar banknote",
    "styles": {
      "3D": "dollar_banknote_3d.png",
      "Animated": "Dollar Banknote.png"
    },
    "sortOrder": 1238
  },
  "🐬": {
    "glyph": "🐬",
    "group": "Animals & Nature",
    "keywords": [
      "dolphin",
      "flipper"
    ],
    "dirName": "Dolphin",
    "styles": {
      "3D": "dolphin_3d.png",
      "Animated": "Dolphin.png"
    },
    "sortOrder": 623
  },
  "🚪": {
    "glyph": "🚪",
    "group": "Objects",
    "keywords": [
      "door"
    ],
    "dirName": "Door",
    "styles": {
      "3D": "door_3d.png",
      "Animated": "Door.png"
    },
    "sortOrder": 1333
  },
  "🫥": {
    "glyph": "🫥",
    "group": "Smileys & Emotion",
    "keywords": [
      "depressed",
      "disappear",
      "dotted line face",
      "hide",
      "introvert",
      "invisible"
    ],
    "dirName": "Dotted line face",
    "styles": {
      "3D": "dotted_line_face_3d.png",
      "Animated": "Dotted Line Face.png"
    },
    "sortOrder": 42
  },
  "🔯": {
    "glyph": "🔯",
    "group": "Symbols",
    "keywords": [
      "dotted six-pointed star",
      "fortune",
      "star"
    ],
    "dirName": "Dotted six-pointed star",
    "styles": {
      "3D": "dotted_six-pointed_star_3d.png",
      "Animated": "Dotted Six-Pointed Star.png"
    },
    "sortOrder": 1423
  },
  "➿": {
    "glyph": "➿",
    "group": "Symbols",
    "keywords": [
      "curl",
      "double",
      "double curly loop",
      "loop"
    ],
    "dirName": "Double curly loop",
    "styles": {
      "3D": "double_curly_loop_3d.png",
      "Animated": "Double Curly Loop.png"
    },
    "sortOrder": 1492
  },
  "‼️": {
    "glyph": "‼️",
    "group": "Symbols",
    "keywords": [
      "!",
      "!!",
      "bangbang",
      "double exclamation mark",
      "exclamation",
      "mark"
    ],
    "dirName": "Double exclamation mark",
    "styles": {
      "3D": "double_exclamation_mark_3d.png",
      "Animated": "Double Exclamation Mark.png"
    },
    "sortOrder": 1470
  },
  "🍩": {
    "glyph": "🍩",
    "group": "Food & Drink",
    "keywords": [
      "breakfast",
      "dessert",
      "donut",
      "doughnut",
      "sweet"
    ],
    "dirName": "Doughnut",
    "styles": {
      "3D": "doughnut_3d.png",
      "Animated": "Doughnut.png"
    },
    "sortOrder": 768
  },
  "🕊️": {
    "glyph": "🕊️",
    "group": "Animals & Nature",
    "keywords": [
      "bird",
      "dove",
      "fly",
      "peace"
    ],
    "dirName": "Dove",
    "styles": {
      "3D": "dove_3d.png",
      "Animated": "Dove.png"
    },
    "sortOrder": 602
  },
  "⬇️": {
    "glyph": "⬇️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "cardinal",
      "direction",
      "down",
      "south"
    ],
    "dirName": "Down arrow",
    "styles": {
      "3D": "down_arrow_3d.png",
      "Animated": "Down Arrow.png"
    },
    "sortOrder": 1395
  },
  "↙️": {
    "glyph": "↙️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "direction",
      "down-left arrow",
      "intercardinal",
      "southwest"
    ],
    "dirName": "Down-left arrow",
    "styles": {
      "3D": "down-left_arrow_3d.png",
      "Animated": "Down-Left Arrow.png"
    },
    "sortOrder": 1396
  },
  "↘️": {
    "glyph": "↘️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "direction",
      "down-right arrow",
      "intercardinal",
      "southeast"
    ],
    "dirName": "Down-right arrow",
    "styles": {
      "3D": "down-right_arrow_3d.png",
      "Animated": "Down-Right Arrow.png"
    },
    "sortOrder": 1394
  },
  "😓": {
    "glyph": "😓",
    "group": "Smileys & Emotion",
    "keywords": [
      "cold",
      "downcast face with sweat",
      "face",
      "sweat"
    ],
    "dirName": "Downcast face with sweat",
    "styles": {
      "3D": "downcast_face_with_sweat_3d.png",
      "Animated": "Downcast Face with Sweat.png"
    },
    "sortOrder": 95
  },
  "🔽": {
    "glyph": "🔽",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "button",
      "down",
      "downwards button",
      "red"
    ],
    "dirName": "Downwards button",
    "styles": {
      "3D": "downwards_button_3d.png",
      "Animated": "Downwards Button.png"
    },
    "sortOrder": 1449
  },
  "🐉": {
    "glyph": "🐉",
    "group": "Animals & Nature",
    "keywords": [
      "dragon",
      "fairy tale"
    ],
    "dirName": "Dragon",
    "styles": {
      "3D": "dragon_3d.png",
      "Animated": "Dragon.png"
    },
    "sortOrder": 618
  },
  "🐲": {
    "glyph": "🐲",
    "group": "Animals & Nature",
    "keywords": [
      "dragon",
      "face",
      "fairy tale"
    ],
    "dirName": "Dragon face",
    "styles": {
      "3D": "dragon_face_3d.png",
      "Animated": "Dragon Face.png"
    },
    "sortOrder": 617
  },
  "👗": {
    "glyph": "👗",
    "group": "Objects",
    "keywords": [
      "clothing",
      "dress"
    ],
    "dirName": "Dress",
    "styles": {
      "3D": "dress_3d.png",
      "Animated": "Dress.png"
    },
    "sortOrder": 1122
  },
  "🤤": {
    "glyph": "🤤",
    "group": "Smileys & Emotion",
    "keywords": [
      "drooling",
      "face"
    ],
    "dirName": "Drooling face",
    "styles": {
      "3D": "drooling_face_3d.png",
      "Animated": "Drooling Face.png"
    },
    "sortOrder": 53
  },
  "🩸": {
    "glyph": "🩸",
    "group": "Objects",
    "keywords": [
      "bleed",
      "blood donation",
      "drop of blood",
      "injury",
      "medicine",
      "menstruation"
    ],
    "dirName": "Drop of blood",
    "styles": {
      "3D": "drop_of_blood_3d.png",
      "Animated": "Drop of Blood.png"
    },
    "sortOrder": 1327
  },
  "💧": {
    "glyph": "💧",
    "group": "Travel & Places",
    "keywords": [
      "cold",
      "comic",
      "drop",
      "droplet",
      "sweat"
    ],
    "dirName": "Droplet",
    "styles": {
      "3D": "droplet_3d.png",
      "Animated": "Droplet.png"
    },
    "sortOrder": 1022
  },
  "🥁": {
    "glyph": "🥁",
    "group": "Objects",
    "keywords": [
      "drum",
      "drumsticks",
      "music"
    ],
    "dirName": "Drum",
    "styles": {
      "3D": "drum_3d.png",
      "Animated": "Drum.png"
    },
    "sortOrder": 1180
  },
  "🦆": {
    "glyph": "🦆",
    "group": "Animals & Nature",
    "keywords": [
      "bird",
      "duck"
    ],
    "dirName": "Duck",
    "styles": {
      "3D": "duck_3d.png",
      "Animated": "Duck.png"
    },
    "sortOrder": 604
  },
  "🥟": {
    "glyph": "🥟",
    "group": "Food & Drink",
    "keywords": [
      "dumpling",
      "empanada",
      "gyōza",
      "jiaozi",
      "pierogi",
      "potsticker"
    ],
    "dirName": "Dumpling",
    "styles": {
      "3D": "dumpling_3d.png",
      "Animated": "Dumpling.png"
    },
    "sortOrder": 757
  },
  "📀": {
    "glyph": "📀",
    "group": "Objects",
    "keywords": [
      "blu-ray",
      "computer",
      "disk",
      "dvd",
      "optical"
    ],
    "dirName": "Dvd",
    "styles": {
      "3D": "dvd_3d.png",
      "Animated": "DVD.png"
    },
    "sortOrder": 1200
  },
  "📧": {
    "glyph": "📧",
    "group": "Objects",
    "keywords": [
      "e-mail",
      "email",
      "letter",
      "mail"
    ],
    "dirName": "E-mail",
    "styles": {
      "3D": "e-mail_3d.png",
      "Animated": "E-Mail.png"
    },
    "sortOrder": 1246
  },
  "🦅": {
    "glyph": "🦅",
    "group": "Animals & Nature",
    "keywords": [
      "bird",
      "eagle"
    ],
    "dirName": "Eagle",
    "styles": {
      "3D": "eagle_3d.png",
      "Animated": "Eagle.png"
    },
    "sortOrder": 603
  },
  "👂": {
    "glyph": "👂",
    "group": "People & Body",
    "keywords": [
      "body",
      "ear"
    ],
    "dirName": "Ear",
    "skintones": {
      "Default": {
        "3D": "ear_3d_default.png",
        "Animated": "Ear.png"
      }
    },
    "sortOrder": 210
  },
  "🌽": {
    "glyph": "🌽",
    "group": "Food & Drink",
    "keywords": [
      "corn",
      "ear",
      "ear of corn",
      "maize",
      "maze"
    ],
    "dirName": "Ear of corn",
    "styles": {
      "3D": "ear_of_corn_3d.png",
      "Animated": "Ear of Corn.png"
    },
    "sortOrder": 697
  },
  "🦻": {
    "glyph": "🦻",
    "group": "People & Body",
    "keywords": [
      "accessibility",
      "ear with hearing aid",
      "hard of hearing"
    ],
    "dirName": "Ear with hearing aid",
    "skintones": {
      "Default": {
        "3D": "ear_with_hearing_aid_3d_default.png",
        "Animated": "Ear with Hearing Aid.png"
      }
    },
    "sortOrder": 211
  },
  "🥚": {
    "glyph": "🥚",
    "group": "Food & Drink",
    "keywords": [
      "breakfast",
      "egg",
      "food"
    ],
    "dirName": "Egg",
    "styles": {
      "3D": "egg_3d.png",
      "Animated": "Egg.png"
    },
    "sortOrder": 732
  },
  "🍆": {
    "glyph": "🍆",
    "group": "Food & Drink",
    "keywords": [
      "aubergine",
      "eggplant",
      "vegetable"
    ],
    "dirName": "Eggplant",
    "styles": {
      "3D": "eggplant_3d.png",
      "Animated": "Eggplant.png"
    },
    "sortOrder": 694
  },
  "🕗": {
    "glyph": "🕗",
    "group": "Travel & Places",
    "keywords": [
      "00",
      "8",
      "8:00",
      "clock",
      "eight",
      "o’clock"
    ],
    "dirName": "Eight oclock",
    "styles": {
      "3D": "eight_oclock_3d.png",
      "Animated": "Eight O’Clock.png"
    },
    "sortOrder": 969
  },
  "✴️": {
    "glyph": "✴️",
    "group": "Symbols",
    "keywords": [
      "*",
      "eight-pointed star",
      "star"
    ],
    "dirName": "Eight-pointed star",
    "styles": {
      "3D": "eight-pointed_star_3d.png",
      "Animated": "Eight-Pointed Star.png"
    },
    "sortOrder": 1495
  },
  "✳️": {
    "glyph": "✳️",
    "group": "Symbols",
    "keywords": [
      "*",
      "asterisk",
      "eight-spoked asterisk"
    ],
    "dirName": "Eight-spoked asterisk",
    "styles": {
      "3D": "eight-spoked_asterisk_3d.png",
      "Animated": "Eight-Spoked Asterisk.png"
    },
    "sortOrder": 1494
  },
  "🕣": {
    "glyph": "🕣",
    "group": "Travel & Places",
    "keywords": [
      "8",
      "8:30",
      "clock",
      "eight",
      "eight-thirty",
      "thirty"
    ],
    "dirName": "Eight-thirty",
    "styles": {
      "3D": "eight-thirty_3d.png",
      "Animated": "Eight-Thirty.png"
    },
    "sortOrder": 970
  },
  "⏏️": {
    "glyph": "⏏️",
    "group": "Symbols",
    "keywords": [
      "eject",
      "eject button"
    ],
    "dirName": "Eject button",
    "styles": {
      "3D": "eject_button_3d.png",
      "Animated": "Eject Button.png"
    },
    "sortOrder": 1454
  },
  "🔌": {
    "glyph": "🔌",
    "group": "Objects",
    "keywords": [
      "electric",
      "electricity",
      "plug"
    ],
    "dirName": "Electric plug",
    "styles": {
      "3D": "electric_plug_3d.png",
      "Animated": "Electric Plug.png"
    },
    "sortOrder": 1190
  },
  "🐘": {
    "glyph": "🐘",
    "group": "Animals & Nature",
    "keywords": [
      "elephant"
    ],
    "dirName": "Elephant",
    "styles": {
      "3D": "elephant_3d.png",
      "Animated": "Elephant.png"
    },
    "sortOrder": 570
  },
  "🛗": {
    "glyph": "🛗",
    "group": "Objects",
    "keywords": [
      "accessibility",
      "elevator",
      "hoist",
      "lift"
    ],
    "dirName": "Elevator",
    "styles": {
      "3D": "elevator_3d.png",
      "Animated": "Elevator.png"
    },
    "sortOrder": 1334
  },
  "🕚": {
    "glyph": "🕚",
    "group": "Travel & Places",
    "keywords": [
      "00",
      "11",
      "11:00",
      "clock",
      "eleven",
      "o’clock"
    ],
    "dirName": "Eleven oclock",
    "styles": {
      "3D": "eleven_oclock_3d.png",
      "Animated": "Eleven O’Clock.png"
    },
    "sortOrder": 975
  },
  "🕦": {
    "glyph": "🕦",
    "group": "Travel & Places",
    "keywords": [
      "11",
      "11:30",
      "clock",
      "eleven",
      "eleven-thirty",
      "thirty"
    ],
    "dirName": "Eleven-thirty",
    "styles": {
      "3D": "eleven-thirty_3d.png",
      "Animated": "Eleven-Thirty.png"
    },
    "sortOrder": 976
  },
  "🪹": {
    "glyph": "🪹",
    "group": "Animals & Nature",
    "keywords": [
      "empty nest",
      "nesting"
    ],
    "dirName": "Empty nest",
    "styles": {
      "3D": "empty_nest_3d.png",
      "Animated": "Empty Nest.png"
    },
    "sortOrder": 672
  },
  "🔚": {
    "glyph": "🔚",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "end",
      "END arrow"
    ],
    "dirName": "End arrow",
    "styles": {
      "3D": "end_arrow_3d.png",
      "Animated": "End Arrow.png"
    },
    "sortOrder": 1408
  },
  "✉️": {
    "glyph": "✉️",
    "group": "Objects",
    "keywords": [
      "email",
      "envelope",
      "letter"
    ],
    "dirName": "Envelope",
    "styles": {
      "3D": "envelope_3d.png",
      "Animated": "Envelope.png"
    },
    "sortOrder": 1245
  },
  "📩": {
    "glyph": "📩",
    "group": "Objects",
    "keywords": [
      "arrow",
      "e-mail",
      "email",
      "envelope",
      "envelope with arrow",
      "outgoing"
    ],
    "dirName": "Envelope with arrow",
    "styles": {
      "3D": "envelope_with_arrow_3d.png",
      "Animated": "Envelope with Arrow.png"
    },
    "sortOrder": 1248
  },
  "💶": {
    "glyph": "💶",
    "group": "Objects",
    "keywords": [
      "banknote",
      "bill",
      "currency",
      "euro",
      "money",
      "note"
    ],
    "dirName": "Euro banknote",
    "styles": {
      "3D": "euro_banknote_3d.png",
      "Animated": "Euro Banknote.png"
    },
    "sortOrder": 1239
  },
  "🌲": {
    "glyph": "🌲",
    "group": "Animals & Nature",
    "keywords": [
      "evergreen tree",
      "tree"
    ],
    "dirName": "Evergreen tree",
    "styles": {
      "3D": "evergreen_tree_3d.png",
      "Animated": "Evergreen Tree.png"
    },
    "sortOrder": 661
  },
  "🐑": {
    "glyph": "🐑",
    "group": "Animals & Nature",
    "keywords": [
      "ewe",
      "female",
      "sheep"
    ],
    "dirName": "Ewe",
    "styles": {
      "3D": "ewe_3d.png",
      "Animated": "Ewe.png"
    },
    "sortOrder": 564
  },
  "⁉️": {
    "glyph": "⁉️",
    "group": "Symbols",
    "keywords": [
      "!",
      "!?",
      "?",
      "exclamation",
      "interrobang",
      "mark",
      "punctuation",
      "question"
    ],
    "dirName": "Exclamation question mark",
    "styles": {
      "3D": "exclamation_question_mark_3d.png",
      "Animated": "Exclamation Question Mark.png"
    },
    "sortOrder": 1471
  },
  "🤯": {
    "glyph": "🤯",
    "group": "Smileys & Emotion",
    "keywords": [
      "exploding head",
      "mind blown",
      "shocked"
    ],
    "dirName": "Exploding head",
    "styles": {
      "3D": "exploding_head_3d.png",
      "Animated": "Exploding Head.png"
    },
    "sortOrder": 66
  },
  "😑": {
    "glyph": "😑",
    "group": "Smileys & Emotion",
    "keywords": [
      "expressionless",
      "face",
      "inexpressive",
      "meh",
      "unexpressive"
    ],
    "dirName": "Expressionless face",
    "styles": {
      "3D": "expressionless_face_3d.png",
      "Animated": "Expressionless Face.png"
    },
    "sortOrder": 40
  },
  "👁️": {
    "glyph": "👁️",
    "group": "People & Body",
    "keywords": [
      "body",
      "eye"
    ],
    "dirName": "Eye",
    "styles": {
      "3D": "eye_3d.png",
      "Animated": "Eye.png"
    },
    "sortOrder": 219
  },
  "👁️‍🗨️": {
    "glyph": "👁️‍🗨️",
    "group": "Smileys & Emotion",
    "keywords": [
      "eye",
      "eye in speech bubble",
      "speech bubble",
      "witness"
    ],
    "dirName": "Eye in speech bubble",
    "styles": {
      "3D": "eye_in_speech_bubble_3d.png",
      "Animated": "Eye in Speech Bubble.png"
    },
    "sortOrder": 159
  },
  "👀": {
    "glyph": "👀",
    "group": "People & Body",
    "keywords": [
      "eye",
      "eyes",
      "face"
    ],
    "dirName": "Eyes",
    "styles": {
      "3D": "eyes_3d.png",
      "Animated": "Eyes.png"
    },
    "sortOrder": 218
  },
  "😘": {
    "glyph": "😘",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "face blowing a kiss",
      "kiss"
    ],
    "dirName": "Face blowing a kiss",
    "styles": {
      "3D": "face_blowing_a_kiss_3d.png",
      "Animated": "Face Blowing a Kiss.png"
    },
    "sortOrder": 18
  },
  "😮‍💨": {
    "glyph": "😮‍💨",
    "group": "Smileys & Emotion",
    "keywords": [
      "exhale",
      "face exhaling",
      "gasp",
      "groan",
      "relief",
      "whisper",
      "whistle"
    ],
    "dirName": "Face exhaling",
    "styles": {
      "3D": "face_exhaling_3d.png",
      "Animated": "Face Exhaling.png"
    },
    "sortOrder": 48
  },
  "🥹": {
    "glyph": "🥹",
    "group": "Smileys & Emotion",
    "keywords": [
      "angry",
      "cry",
      "face holding back tears",
      "proud",
      "resist",
      "sad"
    ],
    "dirName": "Face holding back tears",
    "styles": {
      "3D": "face_holding_back_tears_3d.png",
      "Animated": "Face Holding Back Tears.png"
    },
    "sortOrder": 83
  },
  "😶‍🌫️": {
    "glyph": "😶‍🌫️",
    "group": "Smileys & Emotion",
    "keywords": [
      "absentminded",
      "face in clouds",
      "face in the fog",
      "head in clouds"
    ],
    "dirName": "Face in clouds",
    "styles": {
      "3D": "face_in_clouds_3d.png",
      "Animated": "Face in Clouds.png"
    },
    "sortOrder": 43
  },
  "😋": {
    "glyph": "😋",
    "group": "Smileys & Emotion",
    "keywords": [
      "delicious",
      "face",
      "face savoring food",
      "savouring",
      "smile",
      "yum"
    ],
    "dirName": "Face savoring food",
    "styles": {
      "3D": "face_savoring_food_3d.png",
      "Animated": "Face Savoring Food.png"
    },
    "sortOrder": 24
  },
  "😱": {
    "glyph": "😱",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "face screaming in fear",
      "fear",
      "munch",
      "scared",
      "scream"
    ],
    "dirName": "Face screaming in fear",
    "styles": {
      "3D": "face_screaming_in_fear_3d.png",
      "Animated": "Face Screaming in Fear.png"
    },
    "sortOrder": 91
  },
  "🤮": {
    "glyph": "🤮",
    "group": "Smileys & Emotion",
    "keywords": [
      "face vomiting",
      "puke",
      "sick",
      "vomit"
    ],
    "dirName": "Face vomiting",
    "styles": {
      "3D": "face_vomiting_3d.png",
      "Animated": "Face Vomiting.png"
    },
    "sortOrder": 59
  },
  "🫤": {
    "glyph": "🫤",
    "group": "Smileys & Emotion",
    "keywords": [
      "disappointed",
      "face with diagonal mouth",
      "meh",
      "skeptical",
      "unsure"
    ],
    "dirName": "Face with diagonal mouth",
    "styles": {
      "3D": "face_with_diagonal_mouth_3d.png",
      "Animated": "Face with Diagonal Mouth.png"
    },
    "sortOrder": 74
  },
  "🤭": {
    "glyph": "🤭",
    "group": "Smileys & Emotion",
    "keywords": [
      "face with hand over mouth",
      "whoops"
    ],
    "dirName": "Face with hand over mouth",
    "styles": {
      "3D": "face_with_hand_over_mouth_3d.png",
      "Animated": "Face with Hand Over Mouth.png"
    },
    "sortOrder": 31
  },
  "🤕": {
    "glyph": "🤕",
    "group": "Smileys & Emotion",
    "keywords": [
      "bandage",
      "face",
      "face with head-bandage",
      "hurt",
      "injury"
    ],
    "dirName": "Face with head-bandage",
    "styles": {
      "3D": "face_with_head-bandage_3d.png",
      "Animated": "Face with Head-Bandage.png"
    },
    "sortOrder": 57
  },
  "😷": {
    "glyph": "😷",
    "group": "Smileys & Emotion",
    "keywords": [
      "cold",
      "doctor",
      "face",
      "face with medical mask",
      "mask",
      "sick"
    ],
    "dirName": "Face with medical mask",
    "styles": {
      "3D": "face_with_medical_mask_3d.png",
      "Animated": "Face with Medical Mask.png"
    },
    "sortOrder": 55
  },
  "🧐": {
    "glyph": "🧐",
    "group": "Smileys & Emotion",
    "keywords": [
      "face with monocle",
      "stuffy"
    ],
    "dirName": "Face with monocle",
    "styles": {
      "3D": "face_with_monocle_3d.png",
      "Animated": "Face with Monocle.png"
    },
    "sortOrder": 72
  },
  "🫢": {
    "glyph": "🫢",
    "group": "Smileys & Emotion",
    "keywords": [
      "amazement",
      "awe",
      "disbelief",
      "embarrass",
      "face with open eyes and hand over mouth",
      "scared",
      "surprise"
    ],
    "dirName": "Face with open eyes and hand over mouth",
    "styles": {
      "3D": "face_with_open_eyes_and_hand_over_mouth_3d.png",
      "Animated": "Face with Open Eyes and Hand Over Mouth.png"
    },
    "sortOrder": 32
  },
  "😮": {
    "glyph": "😮",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "face with open mouth",
      "mouth",
      "open",
      "sympathy"
    ],
    "dirName": "Face with open mouth",
    "styles": {
      "3D": "face_with_open_mouth_3d.png",
      "Animated": "Face with Open Mouth.png"
    },
    "sortOrder": 78
  },
  "🫣": {
    "glyph": "🫣",
    "group": "Smileys & Emotion",
    "keywords": [
      "captivated",
      "face with peeking eye",
      "peep",
      "stare"
    ],
    "dirName": "Face with peeking eye",
    "styles": {
      "3D": "face_with_peeking_eye_3d.png",
      "Animated": "Face with Peeking Eye.png"
    },
    "sortOrder": 33
  },
  "🤨": {
    "glyph": "🤨",
    "group": "Smileys & Emotion",
    "keywords": [
      "distrust",
      "face with raised eyebrow",
      "skeptic"
    ],
    "dirName": "Face with raised eyebrow",
    "styles": {
      "3D": "face_with_raised_eyebrow_3d.png",
      "Animated": "Face with Raised Eyebrow.png"
    },
    "sortOrder": 38
  },
  "🙄": {
    "glyph": "🙄",
    "group": "Smileys & Emotion",
    "keywords": [
      "eyeroll",
      "eyes",
      "face",
      "face with rolling eyes",
      "rolling"
    ],
    "dirName": "Face with rolling eyes",
    "styles": {
      "3D": "face_with_rolling_eyes_3d.png",
      "Animated": "Face with Rolling Eyes.png"
    },
    "sortOrder": 46
  },
  "😵‍💫": {
    "glyph": "😵‍💫",
    "group": "Smileys & Emotion",
    "keywords": [
      "dizzy",
      "face with spiral eyes",
      "hypnotized",
      "spiral",
      "trouble",
      "whoa"
    ],
    "dirName": "Face with spiral eyes",
    "styles": {
      "3D": "face_with_spiral_eyes_3d.png",
      "Animated": "Face with Spiral Eyes.png"
    },
    "sortOrder": 65
  },
  "😤": {
    "glyph": "😤",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "face with steam from nose",
      "triumph",
      "won"
    ],
    "dirName": "Face with steam from nose",
    "styles": {
      "3D": "face_with_steam_from_nose_3d.png",
      "Animated": "Face with Steam From Nose.png"
    },
    "sortOrder": 99
  },
  "🤬": {
    "glyph": "🤬",
    "group": "Smileys & Emotion",
    "keywords": [
      "face with symbols on mouth",
      "swearing"
    ],
    "dirName": "Face with symbols on mouth",
    "styles": {
      "3D": "face_with_symbols_on_mouth_3d.png",
      "Animated": "Face with Symbols on Mouth.png"
    },
    "sortOrder": 102
  },
  "😂": {
    "glyph": "😂",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "face with tears of joy",
      "joy",
      "laugh",
      "tear"
    ],
    "dirName": "Face with tears of joy",
    "styles": {
      "3D": "face_with_tears_of_joy_3d.png",
      "Animated": "Face with Tears of Joy.png"
    },
    "sortOrder": 8
  },
  "🤒": {
    "glyph": "🤒",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "face with thermometer",
      "ill",
      "sick",
      "thermometer"
    ],
    "dirName": "Face with thermometer",
    "styles": {
      "3D": "face_with_thermometer_3d.png",
      "Animated": "Face with Thermometer.png"
    },
    "sortOrder": 56
  },
  "😛": {
    "glyph": "😛",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "face with tongue",
      "tongue"
    ],
    "dirName": "Face with tongue",
    "styles": {
      "3D": "face_with_tongue_3d.png",
      "Animated": "Face with Tongue.png"
    },
    "sortOrder": 25
  },
  "😶": {
    "glyph": "😶",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "face without mouth",
      "mouth",
      "quiet",
      "silent"
    ],
    "dirName": "Face without mouth",
    "styles": {
      "3D": "face_without_mouth_3d.png",
      "Animated": "Face Without Mouth.png"
    },
    "sortOrder": 41
  },
  "🏭": {
    "glyph": "🏭",
    "group": "Travel & Places",
    "keywords": [
      "building",
      "factory"
    ],
    "dirName": "Factory",
    "styles": {
      "3D": "factory_3d.png",
      "Animated": "Factory.png"
    },
    "sortOrder": 843
  },
  "🧑‍🏭": {
    "glyph": "🧑‍🏭",
    "group": "People & Body",
    "keywords": [
      "assembly",
      "factory",
      "industrial",
      "worker"
    ],
    "dirName": "Factory worker",
    "skintones": {
      "Default": {
        "3D": "factory_worker_3d_default.png",
        "Animated": "Factory Worker.png"
      }
    },
    "sortOrder": 302
  },
  "🧆": {
    "glyph": "🧆",
    "group": "Food & Drink",
    "keywords": [
      "chickpea",
      "falafel",
      "meatball"
    ],
    "dirName": "Falafel",
    "styles": {
      "3D": "falafel_3d.png",
      "Animated": "Falafel.png"
    },
    "sortOrder": 731
  },
  "🍂": {
    "glyph": "🍂",
    "group": "Animals & Nature",
    "keywords": [
      "fallen leaf",
      "falling",
      "leaf"
    ],
    "dirName": "Fallen leaf",
    "styles": {
      "3D": "fallen_leaf_3d.png",
      "Animated": "Fallen Leaf.png"
    },
    "sortOrder": 670
  },
  "🧑‍🌾": {
    "glyph": "🧑‍🌾",
    "group": "People & Body",
    "keywords": [
      "farmer",
      "gardener",
      "rancher"
    ],
    "dirName": "Farmer",
    "skintones": {
      "Default": {
        "3D": "farmer_3d_default.png",
        "Animated": "Farmer.png"
      }
    },
    "sortOrder": 293
  },
  "⏬": {
    "glyph": "⏬",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "double",
      "down",
      "fast down button"
    ],
    "dirName": "Fast down button",
    "styles": {
      "3D": "fast_down_button_3d.png",
      "Animated": "Fast Down Button.png"
    },
    "sortOrder": 1450
  },
  "⏪": {
    "glyph": "⏪",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "double",
      "fast reverse button",
      "rewind"
    ],
    "dirName": "Fast reverse button",
    "styles": {
      "3D": "fast_reverse_button_3d.png",
      "Animated": "Fast Reverse Button.png"
    },
    "sortOrder": 1445
  },
  "⏫": {
    "glyph": "⏫",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "double",
      "fast up button"
    ],
    "dirName": "Fast up button",
    "styles": {
      "3D": "fast_up_button_3d.png",
      "Animated": "Fast Up Button.png"
    },
    "sortOrder": 1448
  },
  "⏩": {
    "glyph": "⏩",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "double",
      "fast",
      "fast-forward button",
      "forward"
    ],
    "dirName": "Fast-forward button",
    "styles": {
      "3D": "fast-forward_button_3d.png",
      "Animated": "Fast-Forward Button.png"
    },
    "sortOrder": 1441
  },
  "📠": {
    "glyph": "📠",
    "group": "Objects",
    "keywords": [
      "fax",
      "fax machine"
    ],
    "dirName": "Fax machine",
    "styles": {
      "3D": "fax_machine_3d.png",
      "Animated": "Fax Machine.png"
    },
    "sortOrder": 1187
  },
  "😨": {
    "glyph": "😨",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "fear",
      "fearful",
      "scared"
    ],
    "dirName": "Fearful face",
    "styles": {
      "3D": "fearful_face_3d.png",
      "Animated": "Fearful Face.png"
    },
    "sortOrder": 86
  },
  "🪶": {
    "glyph": "🪶",
    "group": "Animals & Nature",
    "keywords": [
      "bird",
      "feather",
      "flight",
      "light",
      "plumage"
    ],
    "dirName": "Feather",
    "styles": {
      "3D": "feather_3d.png",
      "Animated": "Feather.png"
    },
    "sortOrder": 608
  },
  "♀️": {
    "glyph": "♀️",
    "group": "Symbols",
    "keywords": [
      "female sign",
      "woman"
    ],
    "dirName": "Female sign",
    "styles": {
      "3D": "female_sign_3d.png",
      "Animated": "Female Sign.png"
    },
    "sortOrder": 1461
  },
  "🎡": {
    "glyph": "🎡",
    "group": "Travel & Places",
    "keywords": [
      "amusement park",
      "ferris",
      "wheel"
    ],
    "dirName": "Ferris wheel",
    "styles": {
      "3D": "ferris_wheel_3d.png",
      "Animated": "Ferris Wheel.png"
    },
    "sortOrder": 868
  },
  "⛴️": {
    "glyph": "⛴️",
    "group": "Travel & Places",
    "keywords": [
      "boat",
      "ferry",
      "passenger"
    ],
    "dirName": "Ferry",
    "styles": {
      "3D": "ferry_3d.png",
      "Animated": "Ferry.png"
    },
    "sortOrder": 928
  },
  "🏑": {
    "glyph": "🏑",
    "group": "Activities",
    "keywords": [
      "ball",
      "field",
      "game",
      "hockey",
      "stick"
    ],
    "dirName": "Field hockey",
    "styles": {
      "3D": "field_hockey_3d.png",
      "Animated": "Field Hockey.png"
    },
    "sortOrder": 1062
  },
  "🗄️": {
    "glyph": "🗄️",
    "group": "Objects",
    "keywords": [
      "cabinet",
      "file",
      "filing"
    ],
    "dirName": "File cabinet",
    "styles": {
      "3D": "file_cabinet_3d.png",
      "Animated": "File Cabinet.png"
    },
    "sortOrder": 1286
  },
  "📁": {
    "glyph": "📁",
    "group": "Objects",
    "keywords": [
      "file",
      "folder"
    ],
    "dirName": "File folder",
    "styles": {
      "3D": "file_folder_3d.png",
      "Animated": "File Folder.png"
    },
    "sortOrder": 1266
  },
  "🎞️": {
    "glyph": "🎞️",
    "group": "Objects",
    "keywords": [
      "cinema",
      "film",
      "frames",
      "movie"
    ],
    "dirName": "Film frames",
    "styles": {
      "3D": "film_frames_3d.png",
      "Animated": "Film Frames.png"
    },
    "sortOrder": 1203
  },
  "📽️": {
    "glyph": "📽️",
    "group": "Objects",
    "keywords": [
      "cinema",
      "film",
      "movie",
      "projector",
      "video"
    ],
    "dirName": "Film projector",
    "styles": {
      "3D": "film_projector_3d.png",
      "Animated": "Film Projector.png"
    },
    "sortOrder": 1204
  },
  "🔥": {
    "glyph": "🔥",
    "group": "Travel & Places",
    "keywords": [
      "fire",
      "flame",
      "tool"
    ],
    "dirName": "Fire",
    "styles": {
      "3D": "fire_3d.png",
      "Animated": "Fire.png"
    },
    "sortOrder": 1021
  },
  "🚒": {
    "glyph": "🚒",
    "group": "Travel & Places",
    "keywords": [
      "engine",
      "fire",
      "truck"
    ],
    "dirName": "Fire engine",
    "styles": {
      "3D": "fire_engine_3d.png",
      "Animated": "Fire Engine.png"
    },
    "sortOrder": 889
  },
  "🧯": {
    "glyph": "🧯",
    "group": "Objects",
    "keywords": [
      "extinguish",
      "fire",
      "fire extinguisher",
      "quench"
    ],
    "dirName": "Fire extinguisher",
    "styles": {
      "3D": "fire_extinguisher_3d.png",
      "Animated": "Fire Extinguisher.png"
    },
    "sortOrder": 1356
  },
  "🧨": {
    "glyph": "🧨",
    "group": "Activities",
    "keywords": [
      "dynamite",
      "explosive",
      "firecracker",
      "fireworks"
    ],
    "dirName": "Firecracker",
    "styles": {
      "3D": "firecracker_3d.png",
      "Animated": "Firecracker.png"
    },
    "sortOrder": 1028
  },
  "🧑‍🚒": {
    "glyph": "🧑‍🚒",
    "group": "People & Body",
    "keywords": [
      "firefighter",
      "firetruck"
    ],
    "dirName": "Firefighter",
    "skintones": {
      "Default": {
        "3D": "firefighter_3d_default.png",
        "Animated": "Firefighter.png"
      }
    },
    "sortOrder": 326
  },
  "🎆": {
    "glyph": "🎆",
    "group": "Activities",
    "keywords": [
      "celebration",
      "fireworks"
    ],
    "dirName": "Fireworks",
    "styles": {
      "3D": "fireworks_3d.png",
      "Animated": "Fireworks.png"
    },
    "sortOrder": 1026
  },
  "🌓": {
    "glyph": "🌓",
    "group": "Travel & Places",
    "keywords": [
      "first quarter moon",
      "moon",
      "quarter"
    ],
    "dirName": "First quarter moon",
    "styles": {
      "3D": "first_quarter_moon_3d.png",
      "Animated": "First Quarter Moon.png"
    },
    "sortOrder": 979
  },
  "🌛": {
    "glyph": "🌛",
    "group": "Travel & Places",
    "keywords": [
      "face",
      "first quarter moon face",
      "moon",
      "quarter"
    ],
    "dirName": "First quarter moon face",
    "styles": {
      "3D": "first_quarter_moon_face_3d.png",
      "Animated": "First Quarter Moon Face.png"
    },
    "sortOrder": 987
  },
  "🐟": {
    "glyph": "🐟",
    "group": "Animals & Nature",
    "keywords": [
      "fish",
      "Pisces",
      "zodiac"
    ],
    "dirName": "Fish",
    "styles": {
      "3D": "fish_3d.png",
      "Animated": "Fish.png"
    },
    "sortOrder": 625
  },
  "🍥": {
    "glyph": "🍥",
    "group": "Food & Drink",
    "keywords": [
      "cake",
      "fish",
      "fish cake with swirl",
      "pastry",
      "swirl"
    ],
    "dirName": "Fish cake with swirl",
    "styles": {
      "3D": "fish_cake_with_swirl_3d.png",
      "Animated": "Fish Cake with Swirl.png"
    },
    "sortOrder": 754
  },
  "🎣": {
    "glyph": "🎣",
    "group": "Activities",
    "keywords": [
      "fish",
      "fishing pole",
      "pole"
    ],
    "dirName": "Fishing pole",
    "styles": {
      "3D": "fishing_pole_3d.png",
      "Animated": "Fishing Pole.png"
    },
    "sortOrder": 1072
  },
  "🕔": {
    "glyph": "🕔",
    "group": "Travel & Places",
    "keywords": [
      "00",
      "5",
      "5:00",
      "clock",
      "five",
      "o’clock"
    ],
    "dirName": "Five oclock",
    "styles": {
      "3D": "five_oclock_3d.png",
      "Animated": "Five O’Clock.png"
    },
    "sortOrder": 963
  },
  "🕠": {
    "glyph": "🕠",
    "group": "Travel & Places",
    "keywords": [
      "5",
      "5:30",
      "clock",
      "five",
      "five-thirty",
      "thirty"
    ],
    "dirName": "Five-thirty",
    "styles": {
      "3D": "five-thirty_3d.png",
      "Animated": "Five-Thirty.png"
    },
    "sortOrder": 964
  },
  "⛳": {
    "glyph": "⛳",
    "group": "Activities",
    "keywords": [
      "flag in hole",
      "golf",
      "hole"
    ],
    "dirName": "Flag in hole",
    "styles": {
      "3D": "flag_in_hole_3d.png",
      "Animated": "Flag in Hole.png"
    },
    "sortOrder": 1070
  },
  "🦩": {
    "glyph": "🦩",
    "group": "Animals & Nature",
    "keywords": [
      "flamboyant",
      "flamingo",
      "tropical"
    ],
    "dirName": "Flamingo",
    "styles": {
      "3D": "flamingo_3d.png",
      "Animated": "Flamingo.png"
    },
    "sortOrder": 609
  },
  "🔦": {
    "glyph": "🔦",
    "group": "Objects",
    "keywords": [
      "electric",
      "flashlight",
      "light",
      "tool",
      "torch"
    ],
    "dirName": "Flashlight",
    "styles": {
      "3D": "flashlight_3d.png",
      "Animated": "Flashlight.png"
    },
    "sortOrder": 1215
  },
  "🥿": {
    "glyph": "🥿",
    "group": "Objects",
    "keywords": [
      "ballet flat",
      "flat shoe",
      "slip-on",
      "slipper"
    ],
    "dirName": "Flat shoe",
    "styles": {
      "3D": "flat_shoe_3d.png",
      "Animated": "Flat Shoe.png"
    },
    "sortOrder": 1139
  },
  "🫓": {
    "glyph": "🫓",
    "group": "Food & Drink",
    "keywords": [
      "arepa",
      "flatbread",
      "lavash",
      "naan",
      "pita"
    ],
    "dirName": "Flatbread",
    "styles": {
      "3D": "flatbread_3d.png",
      "Animated": "Flatbread.png"
    },
    "sortOrder": 712
  },
  "⚜️": {
    "glyph": "⚜️",
    "group": "Symbols",
    "keywords": [
      "fleur-de-lis"
    ],
    "dirName": "Fleur-de-lis",
    "styles": {
      "3D": "fleur-de-lis_3d.png",
      "Animated": "Fleur-de-lis.png"
    },
    "sortOrder": 1481
  },
  "💪": {
    "glyph": "💪",
    "group": "People & Body",
    "keywords": [
      "biceps",
      "comic",
      "flex",
      "flexed biceps",
      "muscle"
    ],
    "dirName": "Flexed biceps",
    "skintones": {
      "Default": {
        "3D": "flexed_biceps_3d_default.png",
        "Animated": "Flexed Biceps.png"
      }
    },
    "sortOrder": 205
  },
  "💾": {
    "glyph": "💾",
    "group": "Objects",
    "keywords": [
      "computer",
      "disk",
      "floppy"
    ],
    "dirName": "Floppy disk",
    "styles": {
      "3D": "floppy_disk_3d.png",
      "Animated": "Floppy Disk.png"
    },
    "sortOrder": 1198
  },
  "🎴": {
    "glyph": "🎴",
    "group": "Activities",
    "keywords": [
      "card",
      "flower",
      "flower playing cards",
      "game",
      "Japanese",
      "playing"
    ],
    "dirName": "Flower playing cards",
    "styles": {
      "3D": "flower_playing_cards_3d.png",
      "Animated": "Flower Playing Cards.png"
    },
    "sortOrder": 1102
  },
  "😳": {
    "glyph": "😳",
    "group": "Smileys & Emotion",
    "keywords": [
      "dazed",
      "face",
      "flushed"
    ],
    "dirName": "Flushed face",
    "styles": {
      "3D": "flushed_face_3d.png",
      "Animated": "Flushed Face.png"
    },
    "sortOrder": 81
  },
  "🪰": {
    "glyph": "🪰",
    "group": "Animals & Nature",
    "keywords": [
      "disease",
      "fly",
      "maggot",
      "pest",
      "rotting"
    ],
    "dirName": "Fly",
    "styles": {
      "3D": "fly_3d.png",
      "Animated": "Fly.png"
    },
    "sortOrder": 645
  },
  "🥏": {
    "glyph": "🥏",
    "group": "Activities",
    "keywords": [
      "flying disc",
      "ultimate"
    ],
    "dirName": "Flying disc",
    "styles": {
      "3D": "flying_disc_3d.png",
      "Animated": "Flying Disc.png"
    },
    "sortOrder": 1059
  },
  "🛸": {
    "glyph": "🛸",
    "group": "Travel & Places",
    "keywords": [
      "flying saucer",
      "UFO"
    ],
    "dirName": "Flying saucer",
    "styles": {
      "3D": "flying_saucer_3d.png",
      "Animated": "Flying Saucer.png"
    },
    "sortOrder": 943
  },
  "🌫️": {
    "glyph": "🌫️",
    "group": "Travel & Places",
    "keywords": [
      "cloud",
      "fog"
    ],
    "dirName": "Fog",
    "styles": {
      "3D": "fog_3d.png",
      "Animated": "Fog.png"
    },
    "sortOrder": 1008
  },
  "🌁": {
    "glyph": "🌁",
    "group": "Travel & Places",
    "keywords": [
      "fog",
      "foggy"
    ],
    "dirName": "Foggy",
    "styles": {
      "3D": "foggy_3d.png",
      "Animated": "Foggy.png"
    },
    "sortOrder": 857
  },
  "🙏": {
    "glyph": "🙏",
    "group": "People & Body",
    "keywords": [
      "ask",
      "folded hands",
      "hand",
      "high 5",
      "high five",
      "please",
      "pray",
      "thanks"
    ],
    "dirName": "Folded hands",
    "skintones": {
      "Default": {
        "3D": "folded_hands_3d_default.png",
        "Animated": "Folded Hands.png"
      }
    },
    "sortOrder": 201
  },
  "🫕": {
    "glyph": "🫕",
    "group": "Food & Drink",
    "keywords": [
      "cheese",
      "chocolate",
      "fondue",
      "melted",
      "pot",
      "Swiss"
    ],
    "dirName": "Fondue",
    "styles": {
      "3D": "fondue_3d.png",
      "Animated": "Fondue.png"
    },
    "sortOrder": 736
  },
  "🦶": {
    "glyph": "🦶",
    "group": "People & Body",
    "keywords": [
      "foot",
      "kick",
      "stomp"
    ],
    "dirName": "Foot",
    "skintones": {
      "Default": {
        "3D": "foot_3d_default.png",
        "Animated": "Foot.png"
      }
    },
    "sortOrder": 209
  },
  "👣": {
    "glyph": "👣",
    "group": "People & Body",
    "keywords": [
      "clothing",
      "footprint",
      "footprints",
      "print"
    ],
    "dirName": "Footprints",
    "styles": {
      "3D": "footprints_3d.png",
      "Animated": "Footprints.png"
    },
    "sortOrder": 524
  },
  "🍴": {
    "glyph": "🍴",
    "group": "Food & Drink",
    "keywords": [
      "cooking",
      "cutlery",
      "fork",
      "fork and knife",
      "knife"
    ],
    "dirName": "Fork and knife",
    "styles": {
      "3D": "fork_and_knife_3d.png",
      "Animated": "Fork and Knife.png"
    },
    "sortOrder": 801
  },
  "🍽️": {
    "glyph": "🍽️",
    "group": "Food & Drink",
    "keywords": [
      "cooking",
      "fork",
      "fork and knife with plate",
      "knife",
      "plate"
    ],
    "dirName": "Fork and knife with plate",
    "styles": {
      "3D": "fork_and_knife_with_plate_3d.png",
      "Animated": "Fork and Knife with Plate.png"
    },
    "sortOrder": 800
  },
  "🥠": {
    "glyph": "🥠",
    "group": "Food & Drink",
    "keywords": [
      "fortune cookie",
      "prophecy"
    ],
    "dirName": "Fortune cookie",
    "styles": {
      "3D": "fortune_cookie_3d.png",
      "Animated": "Fortune Cookie.png"
    },
    "sortOrder": 758
  },
  "⛲": {
    "glyph": "⛲",
    "group": "Travel & Places",
    "keywords": [
      "fountain"
    ],
    "dirName": "Fountain",
    "styles": {
      "3D": "fountain_3d.png",
      "Animated": "Fountain.png"
    },
    "sortOrder": 855
  },
  "🖋️": {
    "glyph": "🖋️",
    "group": "Objects",
    "keywords": [
      "fountain",
      "pen"
    ],
    "dirName": "Fountain pen",
    "styles": {
      "3D": "fountain_pen_3d.png",
      "Animated": "Fountain Pen.png"
    },
    "sortOrder": 1260
  },
  "🍀": {
    "glyph": "🍀",
    "group": "Animals & Nature",
    "keywords": [
      "4",
      "clover",
      "four",
      "four-leaf clover",
      "leaf"
    ],
    "dirName": "Four leaf clover",
    "styles": {
      "3D": "four_leaf_clover_3d.png",
      "Animated": "Four Leaf Clover.png"
    },
    "sortOrder": 668
  },
  "🕓": {
    "glyph": "🕓",
    "group": "Travel & Places",
    "keywords": [
      "00",
      "4",
      "4:00",
      "clock",
      "four",
      "o’clock"
    ],
    "dirName": "Four oclock",
    "styles": {
      "3D": "four_oclock_3d.png",
      "Animated": "Four O’Clock.png"
    },
    "sortOrder": 961
  },
  "🕟": {
    "glyph": "🕟",
    "group": "Travel & Places",
    "keywords": [
      "4",
      "4:30",
      "clock",
      "four",
      "four-thirty",
      "thirty"
    ],
    "dirName": "Four-thirty",
    "styles": {
      "3D": "four-thirty_3d.png",
      "Animated": "Four-Thirty.png"
    },
    "sortOrder": 962
  },
  "🦊": {
    "glyph": "🦊",
    "group": "Animals & Nature",
    "keywords": [
      "face",
      "fox"
    ],
    "dirName": "Fox",
    "styles": {
      "3D": "fox_3d.png",
      "Animated": "Fox.png"
    },
    "sortOrder": 540
  },
  "🖼️": {
    "glyph": "🖼️",
    "group": "Activities",
    "keywords": [
      "art",
      "frame",
      "framed picture",
      "museum",
      "painting",
      "picture"
    ],
    "dirName": "Framed picture",
    "styles": {
      "3D": "framed_picture_3d.png",
      "Animated": "Framed Picture.png"
    },
    "sortOrder": 1104
  },
  "🆓": {
    "glyph": "🆓",
    "group": "Symbols",
    "keywords": [
      "free",
      "FREE button"
    ],
    "dirName": "Free button",
    "styles": {
      "3D": "free_button_3d.png",
      "Animated": "Free Button.png"
    },
    "sortOrder": 1523
  },
  "🍟": {
    "glyph": "🍟",
    "group": "Food & Drink",
    "keywords": [
      "french",
      "fries"
    ],
    "dirName": "French fries",
    "styles": {
      "3D": "french_fries_3d.png",
      "Animated": "French Fries.png"
    },
    "sortOrder": 723
  },
  "🍤": {
    "glyph": "🍤",
    "group": "Food & Drink",
    "keywords": [
      "fried",
      "prawn",
      "shrimp",
      "tempura"
    ],
    "dirName": "Fried shrimp",
    "styles": {
      "3D": "fried_shrimp_3d.png",
      "Animated": "Fried Shrimp.png"
    },
    "sortOrder": 753
  },
  "🐸": {
    "glyph": "🐸",
    "group": "Animals & Nature",
    "keywords": [
      "face",
      "frog"
    ],
    "dirName": "Frog",
    "styles": {
      "3D": "frog_3d.png",
      "Animated": "Frog.png"
    },
    "sortOrder": 612
  },
  "🐥": {
    "glyph": "🐥",
    "group": "Animals & Nature",
    "keywords": [
      "baby",
      "bird",
      "chick",
      "front-facing baby chick"
    ],
    "dirName": "Front-facing baby chick",
    "styles": {
      "3D": "front-facing_baby_chick_3d.png",
      "Animated": "Front-Facing Baby Chick.png"
    },
    "sortOrder": 599
  },
  "☹️": {
    "glyph": "☹️",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "frown",
      "frowning face"
    ],
    "dirName": "Frowning face",
    "styles": {
      "3D": "frowning_face_3d.png",
      "Animated": "Frowning Face.png"
    },
    "sortOrder": 77
  },
  "😦": {
    "glyph": "😦",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "frown",
      "frowning face with open mouth",
      "mouth",
      "open"
    ],
    "dirName": "Frowning face with open mouth",
    "styles": {
      "3D": "frowning_face_with_open_mouth_3d.png",
      "Animated": "Frowning Face with Open Mouth.png"
    },
    "sortOrder": 84
  },
  "⛽": {
    "glyph": "⛽",
    "group": "Travel & Places",
    "keywords": [
      "diesel",
      "fuel",
      "fuelpump",
      "gas",
      "pump",
      "station"
    ],
    "dirName": "Fuel pump",
    "styles": {
      "3D": "fuel_pump_3d.png",
      "Animated": "Fuel Pump.png"
    },
    "sortOrder": 915
  },
  "🌕": {
    "glyph": "🌕",
    "group": "Travel & Places",
    "keywords": [
      "full",
      "moon"
    ],
    "dirName": "Full moon",
    "styles": {
      "3D": "full_moon_3d.png",
      "Animated": "Full Moon.png"
    },
    "sortOrder": 981
  },
  "🌝": {
    "glyph": "🌝",
    "group": "Travel & Places",
    "keywords": [
      "bright",
      "face",
      "full",
      "moon"
    ],
    "dirName": "Full moon face",
    "styles": {
      "3D": "full_moon_face_3d.png",
      "Animated": "Full Moon Face.png"
    },
    "sortOrder": 991
  },
  "⚱️": {
    "glyph": "⚱️",
    "group": "Objects",
    "keywords": [
      "ashes",
      "death",
      "funeral",
      "urn"
    ],
    "dirName": "Funeral urn",
    "styles": {
      "3D": "funeral_urn_3d.png",
      "Animated": "Funeral Urn.png"
    },
    "sortOrder": 1361
  },
  "🎲": {
    "glyph": "🎲",
    "group": "Activities",
    "keywords": [
      "dice",
      "die",
      "game"
    ],
    "dirName": "Game die",
    "styles": {
      "3D": "game_die_3d.png",
      "Animated": "Game Die.png"
    },
    "sortOrder": 1089
  },
  "🧄": {
    "glyph": "🧄",
    "group": "Food & Drink",
    "keywords": [
      "flavoring",
      "garlic"
    ],
    "dirName": "Garlic",
    "styles": {
      "3D": "garlic_3d.png",
      "Animated": "Garlic.png"
    },
    "sortOrder": 703
  },
  "⚙️": {
    "glyph": "⚙️",
    "group": "Objects",
    "keywords": [
      "cog",
      "cogwheel",
      "gear",
      "tool"
    ],
    "dirName": "Gear",
    "styles": {
      "3D": "gear_3d.png",
      "Animated": "Gear.png"
    },
    "sortOrder": 1309
  },
  "💎": {
    "glyph": "💎",
    "group": "Objects",
    "keywords": [
      "diamond",
      "gem",
      "gem stone",
      "jewel"
    ],
    "dirName": "Gem stone",
    "styles": {
      "3D": "gem_stone_3d.png",
      "Animated": "Gem Stone.png"
    },
    "sortOrder": 1154
  },
  "♊": {
    "glyph": "♊",
    "group": "Symbols",
    "keywords": [
      "Gemini",
      "twins",
      "zodiac"
    ],
    "dirName": "Gemini",
    "styles": {
      "3D": "gemini_3d.png",
      "Animated": "Gemini.png"
    },
    "sortOrder": 1426
  },
  "👻": {
    "glyph": "👻",
    "group": "Smileys & Emotion",
    "keywords": [
      "creature",
      "face",
      "fairy tale",
      "fantasy",
      "ghost",
      "monster"
    ],
    "dirName": "Ghost",
    "styles": {
      "3D": "ghost_3d.png",
      "Animated": "Ghost.png"
    },
    "sortOrder": 111
  },
  "🦒": {
    "glyph": "🦒",
    "group": "Animals & Nature",
    "keywords": [
      "giraffe",
      "spots"
    ],
    "dirName": "Giraffe",
    "styles": {
      "3D": "giraffe_3d.png",
      "Animated": "Giraffe.png"
    },
    "sortOrder": 569
  },
  "👧": {
    "glyph": "👧",
    "group": "People & Body",
    "keywords": [
      "girl",
      "Virgo",
      "young",
      "zodiac"
    ],
    "dirName": "Girl",
    "skintones": {
      "Default": {
        "3D": "girl_3d_default.png",
        "Animated": "Girl.png"
      }
    },
    "sortOrder": 226
  },
  "🥛": {
    "glyph": "🥛",
    "group": "Food & Drink",
    "keywords": [
      "drink",
      "glass",
      "glass of milk",
      "milk"
    ],
    "dirName": "Glass of milk",
    "styles": {
      "3D": "glass_of_milk_3d.png",
      "Animated": "Glass of Milk.png"
    },
    "sortOrder": 780
  },
  "👓": {
    "glyph": "👓",
    "group": "Objects",
    "keywords": [
      "clothing",
      "eye",
      "eyeglasses",
      "eyewear",
      "glasses"
    ],
    "dirName": "Glasses",
    "styles": {
      "3D": "glasses_3d.png",
      "Animated": "Glasses.png"
    },
    "sortOrder": 1110
  },
  "🌎": {
    "glyph": "🌎",
    "group": "Travel & Places",
    "keywords": [
      "Americas",
      "earth",
      "globe",
      "globe showing Americas",
      "world"
    ],
    "dirName": "Globe showing americas",
    "styles": {
      "3D": "globe_showing_americas_3d.png",
      "Animated": "Globe Showing Americas.png"
    },
    "sortOrder": 807
  },
  "🌏": {
    "glyph": "🌏",
    "group": "Travel & Places",
    "keywords": [
      "Asia",
      "Australia",
      "earth",
      "globe",
      "globe showing Asia-Australia",
      "world"
    ],
    "dirName": "Globe showing asia-australia",
    "styles": {
      "3D": "globe_showing_asia-australia_3d.png",
      "Animated": "Globe Showing Asia-Australia.png"
    },
    "sortOrder": 808
  },
  "🌍": {
    "glyph": "🌍",
    "group": "Travel & Places",
    "keywords": [
      "Africa",
      "earth",
      "Europe",
      "globe",
      "globe showing Europe-Africa",
      "world"
    ],
    "dirName": "Globe showing europe-africa",
    "styles": {
      "3D": "globe_showing_europe-africa_3d.png",
      "Animated": "Globe Showing Europe-Africa.png"
    },
    "sortOrder": 806
  },
  "🌐": {
    "glyph": "🌐",
    "group": "Travel & Places",
    "keywords": [
      "earth",
      "globe",
      "globe with meridians",
      "meridians",
      "world"
    ],
    "dirName": "Globe with meridians",
    "styles": {
      "3D": "globe_with_meridians_3d.png",
      "Animated": "Globe with Meridians.png"
    },
    "sortOrder": 809
  },
  "🧤": {
    "glyph": "🧤",
    "group": "Objects",
    "keywords": [
      "gloves",
      "hand"
    ],
    "dirName": "Gloves",
    "styles": {
      "3D": "gloves_3d.png",
      "Animated": "Gloves.png"
    },
    "sortOrder": 1119
  },
  "🌟": {
    "glyph": "🌟",
    "group": "Travel & Places",
    "keywords": [
      "glittery",
      "glow",
      "glowing star",
      "shining",
      "sparkle",
      "star"
    ],
    "dirName": "Glowing star",
    "styles": {
      "3D": "glowing_star_3d.png",
      "Animated": "Glowing Star.png"
    },
    "sortOrder": 995
  },
  "🥅": {
    "glyph": "🥅",
    "group": "Activities",
    "keywords": [
      "goal",
      "net"
    ],
    "dirName": "Goal net",
    "styles": {
      "3D": "goal_net_3d.png",
      "Animated": "Goal Net.png"
    },
    "sortOrder": 1069
  },
  "🐐": {
    "glyph": "🐐",
    "group": "Animals & Nature",
    "keywords": [
      "Capricorn",
      "goat",
      "zodiac"
    ],
    "dirName": "Goat",
    "styles": {
      "3D": "goat_3d.png",
      "Animated": "Goat.png"
    },
    "sortOrder": 565
  },
  "👺": {
    "glyph": "👺",
    "group": "Smileys & Emotion",
    "keywords": [
      "creature",
      "face",
      "fairy tale",
      "fantasy",
      "goblin",
      "monster"
    ],
    "dirName": "Goblin",
    "styles": {
      "3D": "goblin_3d.png",
      "Animated": "Goblin.png"
    },
    "sortOrder": 110
  },
  "🥽": {
    "glyph": "🥽",
    "group": "Objects",
    "keywords": [
      "eye protection",
      "goggles",
      "swimming",
      "welding"
    ],
    "dirName": "Goggles",
    "styles": {
      "3D": "goggles_3d.png",
      "Animated": "Goggles.png"
    },
    "sortOrder": 1112
  },
  "🦍": {
    "glyph": "🦍",
    "group": "Animals & Nature",
    "keywords": [
      "gorilla"
    ],
    "dirName": "Gorilla",
    "styles": {
      "3D": "gorilla_3d.png",
      "Animated": "Gorilla.png"
    },
    "sortOrder": 532
  },
  "🎓": {
    "glyph": "🎓",
    "group": "Objects",
    "keywords": [
      "cap",
      "celebration",
      "clothing",
      "graduation",
      "hat"
    ],
    "dirName": "Graduation cap",
    "styles": {
      "3D": "graduation_cap_3d.png",
      "Animated": "Graduation Cap.png"
    },
    "sortOrder": 1147
  },
  "🍇": {
    "glyph": "🍇",
    "group": "Food & Drink",
    "keywords": [
      "fruit",
      "grape",
      "grapes"
    ],
    "dirName": "Grapes",
    "styles": {
      "3D": "grapes_3d.png",
      "Animated": "Grapes.png"
    },
    "sortOrder": 674
  },
  "🍏": {
    "glyph": "🍏",
    "group": "Food & Drink",
    "keywords": [
      "apple",
      "fruit",
      "green"
    ],
    "dirName": "Green apple",
    "styles": {
      "3D": "green_apple_3d.png",
      "Animated": "Green Apple.png"
    },
    "sortOrder": 683
  },
  "📗": {
    "glyph": "📗",
    "group": "Objects",
    "keywords": [
      "book",
      "green"
    ],
    "dirName": "Green book",
    "styles": {
      "3D": "green_book_3d.png",
      "Animated": "Green Book.png"
    },
    "sortOrder": 1221
  },
  "🟢": {
    "glyph": "🟢",
    "group": "Symbols",
    "keywords": [
      "circle",
      "green"
    ],
    "dirName": "Green circle",
    "styles": {
      "3D": "green_circle_3d.png",
      "Animated": "Green Circle.png"
    },
    "sortOrder": 1555
  },
  "💚": {
    "glyph": "💚",
    "group": "Smileys & Emotion",
    "keywords": [
      "green",
      "green heart"
    ],
    "dirName": "Green heart",
    "styles": {
      "3D": "green_heart_3d.png",
      "Animated": "Green Heart.png"
    },
    "sortOrder": 144
  },
  "🥗": {
    "glyph": "🥗",
    "group": "Food & Drink",
    "keywords": [
      "food",
      "green",
      "salad"
    ],
    "dirName": "Green salad",
    "styles": {
      "3D": "green_salad_3d.png",
      "Animated": "Green Salad.png"
    },
    "sortOrder": 738
  },
  "🟩": {
    "glyph": "🟩",
    "group": "Symbols",
    "keywords": [
      "green",
      "square"
    ],
    "dirName": "Green square",
    "styles": {
      "3D": "green_square_3d.png",
      "Animated": "Green Square.png"
    },
    "sortOrder": 1564
  },
  "😬": {
    "glyph": "😬",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "grimace",
      "grimacing face"
    ],
    "dirName": "Grimacing face",
    "styles": {
      "3D": "grimacing_face_3d.png",
      "Animated": "Grimacing Face.png"
    },
    "sortOrder": 47
  },
  "😺": {
    "glyph": "😺",
    "group": "Smileys & Emotion",
    "keywords": [
      "cat",
      "face",
      "grinning",
      "mouth",
      "open",
      "smile"
    ],
    "dirName": "Grinning cat",
    "styles": {
      "3D": "grinning_cat_3d.png",
      "Animated": "Grinning Cat.png"
    },
    "sortOrder": 115
  },
  "😸": {
    "glyph": "😸",
    "group": "Smileys & Emotion",
    "keywords": [
      "cat",
      "eye",
      "face",
      "grin",
      "grinning cat with smiling eyes",
      "smile"
    ],
    "dirName": "Grinning cat with smiling eyes",
    "styles": {
      "3D": "grinning_cat_with_smiling_eyes_3d.png",
      "Animated": "Grinning Cat with Smiling Eyes.png"
    },
    "sortOrder": 116
  },
  "😀": {
    "glyph": "😀",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "grin",
      "grinning face"
    ],
    "dirName": "Grinning face",
    "styles": {
      "3D": "grinning_face_3d.png",
      "Animated": "Grinning Face.png"
    },
    "sortOrder": 1
  },
  "😃": {
    "glyph": "😃",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "grinning face with big eyes",
      "mouth",
      "open",
      "smile"
    ],
    "dirName": "Grinning face with big eyes",
    "styles": {
      "3D": "grinning_face_with_big_eyes_3d.png",
      "Animated": "Grinning Face with Big Eyes.png"
    },
    "sortOrder": 2
  },
  "😄": {
    "glyph": "😄",
    "group": "Smileys & Emotion",
    "keywords": [
      "eye",
      "face",
      "grinning face with smiling eyes",
      "mouth",
      "open",
      "smile"
    ],
    "dirName": "Grinning face with smiling eyes",
    "styles": {
      "3D": "grinning_face_with_smiling_eyes_3d.png",
      "Animated": "Grinning Face with Smiling Eyes.png"
    },
    "sortOrder": 3
  },
  "😅": {
    "glyph": "😅",
    "group": "Smileys & Emotion",
    "keywords": [
      "cold",
      "face",
      "grinning face with sweat",
      "open",
      "smile",
      "sweat"
    ],
    "dirName": "Grinning face with sweat",
    "styles": {
      "3D": "grinning_face_with_sweat_3d.png",
      "Animated": "Grinning Face with Sweat.png"
    },
    "sortOrder": 6
  },
  "😆": {
    "glyph": "😆",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "grinning squinting face",
      "laugh",
      "mouth",
      "satisfied",
      "smile"
    ],
    "dirName": "Grinning squinting face",
    "styles": {
      "3D": "grinning_squinting_face_3d.png",
      "Animated": "Grinning Squinting Face.png"
    },
    "sortOrder": 5
  },
  "💗": {
    "glyph": "💗",
    "group": "Smileys & Emotion",
    "keywords": [
      "excited",
      "growing",
      "growing heart",
      "nervous",
      "pulse"
    ],
    "dirName": "Growing heart",
    "styles": {
      "3D": "growing_heart_3d.png",
      "Animated": "Growing Heart.png"
    },
    "sortOrder": 132
  },
  "💂": {
    "glyph": "💂",
    "group": "People & Body",
    "keywords": [
      "guard"
    ],
    "dirName": "Guard",
    "skintones": {
      "Default": {
        "3D": "guard_3d_default.png",
        "Animated": "Guard.png"
      }
    },
    "sortOrder": 335
  },
  "🦮": {
    "glyph": "🦮",
    "group": "Animals & Nature",
    "keywords": [
      "accessibility",
      "blind",
      "guide",
      "guide dog"
    ],
    "dirName": "Guide dog",
    "styles": {
      "3D": "guide_dog_3d.png",
      "Animated": "Guide Dog.png"
    },
    "sortOrder": 536
  },
  "🎸": {
    "glyph": "🎸",
    "group": "Objects",
    "keywords": [
      "guitar",
      "instrument",
      "music"
    ],
    "dirName": "Guitar",
    "styles": {
      "3D": "guitar_3d.png",
      "Animated": "Guitar.png"
    },
    "sortOrder": 1175
  },
  "🍔": {
    "glyph": "🍔",
    "group": "Food & Drink",
    "keywords": [
      "burger",
      "hamburger"
    ],
    "dirName": "Hamburger",
    "styles": {
      "3D": "hamburger_3d.png",
      "Animated": "Hamburger.png"
    },
    "sortOrder": 722
  },
  "🔨": {
    "glyph": "🔨",
    "group": "Objects",
    "keywords": [
      "hammer",
      "tool"
    ],
    "dirName": "Hammer",
    "styles": {
      "3D": "hammer_3d.png",
      "Animated": "Hammer.png"
    },
    "sortOrder": 1294
  },
  "⚒️": {
    "glyph": "⚒️",
    "group": "Objects",
    "keywords": [
      "hammer",
      "hammer and pick",
      "pick",
      "tool"
    ],
    "dirName": "Hammer and pick",
    "styles": {
      "3D": "hammer_and_pick_3d.png",
      "Animated": "Hammer and Pick.png"
    },
    "sortOrder": 1297
  },
  "🛠️": {
    "glyph": "🛠️",
    "group": "Objects",
    "keywords": [
      "hammer",
      "hammer and wrench",
      "spanner",
      "tool",
      "wrench"
    ],
    "dirName": "Hammer and wrench",
    "styles": {
      "3D": "hammer_and_wrench_3d.png",
      "Animated": "Hammer and Wrench.png"
    },
    "sortOrder": 1298
  },
  "🪬": {
    "glyph": "🪬",
    "group": "Activities",
    "keywords": [
      "amulet",
      "Fatima",
      "hamsa",
      "hand",
      "Mary",
      "Miriam",
      "protection"
    ],
    "dirName": "Hamsa",
    "styles": {
      "3D": "hamsa_3d.png",
      "Animated": "Hamsa.png"
    },
    "sortOrder": 1085
  },
  "🐹": {
    "glyph": "🐹",
    "group": "Animals & Nature",
    "keywords": [
      "face",
      "hamster",
      "pet"
    ],
    "dirName": "Hamster",
    "styles": {
      "3D": "hamster_3d.png",
      "Animated": "Hamster.png"
    },
    "sortOrder": 577
  },
  "🖐️": {
    "glyph": "🖐️",
    "group": "People & Body",
    "keywords": [
      "finger",
      "hand",
      "hand with fingers splayed",
      "splayed"
    ],
    "dirName": "Hand with fingers splayed",
    "skintones": {
      "Default": {
        "3D": "hand_with_fingers_splayed_3d_default.png",
        "Animated": "Hand with Fingers Splayed.png"
      }
    },
    "sortOrder": 166
  },
  "🫰": {
    "glyph": "🫰",
    "group": "People & Body",
    "keywords": [
      "expensive",
      "hand with index finger and thumb crossed",
      "heart",
      "love",
      "money",
      "snap"
    ],
    "dirName": "Hand with index finger and thumb crossed",
    "skintones": {
      "Default": {
        "3D": "hand_with_index_finger_and_thumb_crossed_3d_default.png",
        "Animated": "Hand with Index Finger and Thumb Crossed.png"
      }
    },
    "sortOrder": 178
  },
  "👜": {
    "glyph": "👜",
    "group": "Objects",
    "keywords": [
      "bag",
      "clothing",
      "handbag",
      "purse"
    ],
    "dirName": "Handbag",
    "styles": {
      "3D": "handbag_3d.png",
      "Animated": "Handbag.png"
    },
    "sortOrder": 1131
  },
  "🤝": {
    "glyph": "🤝",
    "group": "People & Body",
    "keywords": [
      "agreement",
      "hand",
      "handshake",
      "meeting",
      "shake"
    ],
    "dirName": "Handshake",
    "styles": {
      "3D": "handshake_3d.png",
      "Animated": "Handshake.png"
    },
    "sortOrder": 200
  },
  "🐣": {
    "glyph": "🐣",
    "group": "Animals & Nature",
    "keywords": [
      "baby",
      "bird",
      "chick",
      "hatching"
    ],
    "dirName": "Hatching chick",
    "styles": {
      "3D": "hatching_chick_3d.png",
      "Animated": "Hatching Chick.png"
    },
    "sortOrder": 597
  },
  "🎧": {
    "glyph": "🎧",
    "group": "Objects",
    "keywords": [
      "earbud",
      "headphone"
    ],
    "dirName": "Headphone",
    "styles": {
      "3D": "headphone_3d.png",
      "Animated": "Headphone.png"
    },
    "sortOrder": 1171
  },
  "🪦": {
    "glyph": "🪦",
    "group": "Objects",
    "keywords": [
      "cemetery",
      "grave",
      "graveyard",
      "headstone",
      "tombstone"
    ],
    "dirName": "Headstone",
    "styles": {
      "3D": "headstone_3d.png",
      "Animated": "Headstone.png"
    },
    "sortOrder": 1360
  },
  "🧑‍⚕️": {
    "glyph": "🧑‍⚕️",
    "group": "People & Body",
    "keywords": [
      "doctor",
      "health worker",
      "healthcare",
      "nurse",
      "therapist"
    ],
    "dirName": "Health worker",
    "skintones": {
      "Default": {
        "3D": "health_worker_3d_default.png",
        "Animated": "Health Worker.png"
      }
    },
    "sortOrder": 281
  },
  "🙉": {
    "glyph": "🙉",
    "group": "Smileys & Emotion",
    "keywords": [
      "evil",
      "face",
      "forbidden",
      "hear",
      "hear-no-evil monkey",
      "monkey"
    ],
    "dirName": "Hear-no-evil monkey",
    "styles": {
      "3D": "hear-no-evil_monkey_3d.png",
      "Animated": "Hear-No-Evil Monkey.png"
    },
    "sortOrder": 125
  },
  "💟": {
    "glyph": "💟",
    "group": "Smileys & Emotion",
    "keywords": [
      "heart",
      "heart decoration"
    ],
    "dirName": "Heart decoration",
    "styles": {
      "3D": "heart_decoration_3d.png",
      "Animated": "Heart Decoration.png"
    },
    "sortOrder": 136
  },
  "❣️": {
    "glyph": "❣️",
    "group": "Smileys & Emotion",
    "keywords": [
      "exclamation",
      "heart exclamation",
      "mark",
      "punctuation"
    ],
    "dirName": "Heart exclamation",
    "styles": {
      "3D": "heart_exclamation_3d.png",
      "Animated": "Heart Exclamation.png"
    },
    "sortOrder": 137
  },
  "🫶": {
    "glyph": "🫶",
    "group": "People & Body",
    "keywords": [
      "heart hands",
      "love"
    ],
    "dirName": "Heart hands",
    "skintones": {
      "Default": {
        "3D": "heart_hands_3d_default.png",
        "Animated": "Heart Hands.png"
      }
    },
    "sortOrder": 197
  },
  "❤️‍🔥": {
    "glyph": "❤️‍🔥",
    "group": "Smileys & Emotion",
    "keywords": [
      "burn",
      "heart",
      "heart on fire",
      "love",
      "lust",
      "sacred heart"
    ],
    "dirName": "Heart on fire",
    "styles": {
      "3D": "heart_on_fire_3d.png",
      "Animated": "Heart on Fire.png"
    },
    "sortOrder": 139
  },
  "♥️": {
    "glyph": "♥️",
    "group": "Activities",
    "keywords": [
      "card",
      "game",
      "heart suit"
    ],
    "dirName": "Heart suit",
    "styles": {
      "3D": "heart_suit_3d.png",
      "Animated": "Heart Suit.png"
    },
    "sortOrder": 1096
  },
  "💘": {
    "glyph": "💘",
    "group": "Smileys & Emotion",
    "keywords": [
      "arrow",
      "cupid",
      "heart with arrow"
    ],
    "dirName": "Heart with arrow",
    "styles": {
      "3D": "heart_with_arrow_3d.png",
      "Animated": "Heart with Arrow.png"
    },
    "sortOrder": 129
  },
  "💝": {
    "glyph": "💝",
    "group": "Smileys & Emotion",
    "keywords": [
      "heart with ribbon",
      "ribbon",
      "valentine"
    ],
    "dirName": "Heart with ribbon",
    "styles": {
      "3D": "heart_with_ribbon_3d.png",
      "Animated": "Heart with Ribbon.png"
    },
    "sortOrder": 130
  },
  "💲": {
    "glyph": "💲",
    "group": "Symbols",
    "keywords": [
      "currency",
      "dollar",
      "heavy dollar sign",
      "money"
    ],
    "dirName": "Heavy dollar sign",
    "styles": {
      "3D": "heavy_dollar_sign_3d.png",
      "Animated": "Heavy Dollar Sign.png"
    },
    "sortOrder": 1478
  },
  "🟰": {
    "glyph": "🟰",
    "group": "Symbols",
    "keywords": [
      "equality",
      "heavy equals sign",
      "math"
    ],
    "dirName": "Heavy equals sign",
    "styles": {
      "3D": "heavy_equals_sign_3d.png",
      "Animated": "Heavy Equals Sign.png"
    },
    "sortOrder": 1468
  },
  "🦔": {
    "glyph": "🦔",
    "group": "Animals & Nature",
    "keywords": [
      "hedgehog",
      "spiny"
    ],
    "dirName": "Hedgehog",
    "styles": {
      "3D": "hedgehog_3d.png",
      "Animated": "Hedgehog.png"
    },
    "sortOrder": 582
  },
  "🚁": {
    "glyph": "🚁",
    "group": "Travel & Places",
    "keywords": [
      "helicopter",
      "vehicle"
    ],
    "dirName": "Helicopter",
    "styles": {
      "3D": "helicopter_3d.png",
      "Animated": "Helicopter.png"
    },
    "sortOrder": 937
  },
  "🌿": {
    "glyph": "🌿",
    "group": "Animals & Nature",
    "keywords": [
      "herb",
      "leaf"
    ],
    "dirName": "Herb",
    "styles": {
      "3D": "herb_3d.png",
      "Animated": "Herb.png"
    },
    "sortOrder": 666
  },
  "🌺": {
    "glyph": "🌺",
    "group": "Animals & Nature",
    "keywords": [
      "flower",
      "hibiscus"
    ],
    "dirName": "Hibiscus",
    "styles": {
      "3D": "hibiscus_3d.png",
      "Animated": "Hibiscus.png"
    },
    "sortOrder": 655
  },
  "⚡": {
    "glyph": "⚡",
    "group": "Travel & Places",
    "keywords": [
      "danger",
      "electric",
      "high voltage",
      "lightning",
      "voltage",
      "zap"
    ],
    "dirName": "High voltage",
    "styles": {
      "3D": "high_voltage_3d.png",
      "Animated": "High Voltage.png"
    },
    "sortOrder": 1016
  },
  "👠": {
    "glyph": "👠",
    "group": "Objects",
    "keywords": [
      "clothing",
      "heel",
      "high-heeled shoe",
      "shoe",
      "woman"
    ],
    "dirName": "High-heeled shoe",
    "styles": {
      "3D": "high-heeled_shoe_3d.png",
      "Animated": "High-Heeled Shoe.png"
    },
    "sortOrder": 1140
  },
  "🚄": {
    "glyph": "🚄",
    "group": "Travel & Places",
    "keywords": [
      "high-speed train",
      "railway",
      "shinkansen",
      "speed",
      "train"
    ],
    "dirName": "High-speed train",
    "styles": {
      "3D": "high-speed_train_3d.png",
      "Animated": "High-Speed Train.png"
    },
    "sortOrder": 874
  },
  "🥾": {
    "glyph": "🥾",
    "group": "Objects",
    "keywords": [
      "backpacking",
      "boot",
      "camping",
      "hiking"
    ],
    "dirName": "Hiking boot",
    "styles": {
      "3D": "hiking_boot_3d.png",
      "Animated": "Hiking Boot.png"
    },
    "sortOrder": 1138
  },
  "🛕": {
    "glyph": "🛕",
    "group": "Travel & Places",
    "keywords": [
      "hindu",
      "temple"
    ],
    "dirName": "Hindu temple",
    "styles": {
      "3D": "hindu_temple_3d.png",
      "Animated": "Hindu Temple.png"
    },
    "sortOrder": 851
  },
  "🦛": {
    "glyph": "🦛",
    "group": "Animals & Nature",
    "keywords": [
      "hippo",
      "hippopotamus"
    ],
    "dirName": "Hippopotamus",
    "styles": {
      "3D": "hippopotamus_3d.png",
      "Animated": "Hippopotamus.png"
    },
    "sortOrder": 573
  },
  "🕳️": {
    "glyph": "🕳️",
    "group": "Smileys & Emotion",
    "keywords": [
      "hole"
    ],
    "dirName": "Hole",
    "styles": {
      "3D": "hole_3d.png",
      "Animated": "Hole.png"
    },
    "sortOrder": 156
  },
  "⭕": {
    "glyph": "⭕",
    "group": "Symbols",
    "keywords": [
      "circle",
      "hollow red circle",
      "large",
      "o",
      "red"
    ],
    "dirName": "Hollow red circle",
    "styles": {
      "3D": "hollow_red_circle_3d.png",
      "Animated": "Hollow Red Circle.png"
    },
    "sortOrder": 1485
  },
  "🍯": {
    "glyph": "🍯",
    "group": "Food & Drink",
    "keywords": [
      "honey",
      "honeypot",
      "pot",
      "sweet"
    ],
    "dirName": "Honey pot",
    "styles": {
      "3D": "honey_pot_3d.png",
      "Animated": "Honey Pot.png"
    },
    "sortOrder": 778
  },
  "🐝": {
    "glyph": "🐝",
    "group": "Animals & Nature",
    "keywords": [
      "bee",
      "honeybee",
      "insect"
    ],
    "dirName": "Honeybee",
    "styles": {
      "3D": "honeybee_3d.png",
      "Animated": "Honeybee.png"
    },
    "sortOrder": 636
  },
  "🪝": {
    "glyph": "🪝",
    "group": "Objects",
    "keywords": [
      "catch",
      "crook",
      "curve",
      "ensnare",
      "hook",
      "selling point"
    ],
    "dirName": "Hook",
    "styles": {
      "3D": "hook_3d.png",
      "Animated": "Hook.png"
    },
    "sortOrder": 1315
  },
  "🚥": {
    "glyph": "🚥",
    "group": "Travel & Places",
    "keywords": [
      "horizontal traffic light",
      "light",
      "signal",
      "traffic"
    ],
    "dirName": "Horizontal traffic light",
    "styles": {
      "3D": "horizontal_traffic_light_3d.png",
      "Animated": "Horizontal Traffic Light.png"
    },
    "sortOrder": 918
  },
  "🐎": {
    "glyph": "🐎",
    "group": "Animals & Nature",
    "keywords": [
      "equestrian",
      "horse",
      "racehorse",
      "racing"
    ],
    "dirName": "Horse",
    "styles": {
      "3D": "horse_3d.png",
      "Animated": "Horse.png"
    },
    "sortOrder": 550
  },
  "🐴": {
    "glyph": "🐴",
    "group": "Animals & Nature",
    "keywords": [
      "face",
      "horse"
    ],
    "dirName": "Horse face",
    "styles": {
      "3D": "horse_face_3d.png",
      "Animated": "Horse Face.png"
    },
    "sortOrder": 549
  },
  "🏇": {
    "glyph": "🏇",
    "group": "People & Body",
    "keywords": [
      "horse",
      "jockey",
      "racehorse",
      "racing"
    ],
    "dirName": "Horse racing",
    "skintones": {
      "Default": {
        "3D": "horse_racing_3d_default.png",
        "Animated": "Horse Racing.png"
      }
    },
    "sortOrder": 435
  },
  "🏥": {
    "glyph": "🏥",
    "group": "Travel & Places",
    "keywords": [
      "doctor",
      "hospital",
      "medicine"
    ],
    "dirName": "Hospital",
    "styles": {
      "3D": "hospital_3d.png",
      "Animated": "Hospital.png"
    },
    "sortOrder": 836
  },
  "☕": {
    "glyph": "☕",
    "group": "Food & Drink",
    "keywords": [
      "beverage",
      "coffee",
      "drink",
      "hot",
      "steaming",
      "tea"
    ],
    "dirName": "Hot beverage",
    "styles": {
      "3D": "hot_beverage_3d.png",
      "Animated": "Hot Beverage.png"
    },
    "sortOrder": 781
  },
  "🌭": {
    "glyph": "🌭",
    "group": "Food & Drink",
    "keywords": [
      "frankfurter",
      "hot dog",
      "hotdog",
      "sausage"
    ],
    "dirName": "Hot dog",
    "styles": {
      "3D": "hot_dog_3d.png",
      "Animated": "Hot Dog.png"
    },
    "sortOrder": 725
  },
  "🥵": {
    "glyph": "🥵",
    "group": "Smileys & Emotion",
    "keywords": [
      "feverish",
      "heat stroke",
      "hot",
      "hot face",
      "red-faced",
      "sweating"
    ],
    "dirName": "Hot face",
    "styles": {
      "3D": "hot_face_3d.png",
      "Animated": "Hot Face.png"
    },
    "sortOrder": 61
  },
  "🌶️": {
    "glyph": "🌶️",
    "group": "Food & Drink",
    "keywords": [
      "hot",
      "pepper"
    ],
    "dirName": "Hot pepper",
    "styles": {
      "3D": "hot_pepper_3d.png",
      "Animated": "Hot Pepper.png"
    },
    "sortOrder": 698
  },
  "♨️": {
    "glyph": "♨️",
    "group": "Travel & Places",
    "keywords": [
      "hot",
      "hotsprings",
      "springs",
      "steaming"
    ],
    "dirName": "Hot springs",
    "styles": {
      "3D": "hot_springs_3d.png",
      "Animated": "Hot Springs.png"
    },
    "sortOrder": 865
  },
  "🏨": {
    "glyph": "🏨",
    "group": "Travel & Places",
    "keywords": [
      "building",
      "hotel"
    ],
    "dirName": "Hotel",
    "styles": {
      "3D": "hotel_3d.png",
      "Animated": "Hotel.png"
    },
    "sortOrder": 838
  },
  "⌛": {
    "glyph": "⌛",
    "group": "Travel & Places",
    "keywords": [
      "hourglass done",
      "sand",
      "timer"
    ],
    "dirName": "Hourglass done",
    "styles": {
      "3D": "hourglass_done_3d.png",
      "Animated": "Hourglass Done.png"
    },
    "sortOrder": 946
  },
  "⏳": {
    "glyph": "⏳",
    "group": "Travel & Places",
    "keywords": [
      "hourglass",
      "hourglass not done",
      "sand",
      "timer"
    ],
    "dirName": "Hourglass not done",
    "styles": {
      "3D": "hourglass_not_done_3d.png",
      "Animated": "Hourglass Not Done.png"
    },
    "sortOrder": 947
  },
  "🏠": {
    "glyph": "🏠",
    "group": "Travel & Places",
    "keywords": [
      "home",
      "house"
    ],
    "dirName": "House",
    "styles": {
      "3D": "house_3d.png",
      "Animated": "House.png"
    },
    "sortOrder": 831
  },
  "🏡": {
    "glyph": "🏡",
    "group": "Travel & Places",
    "keywords": [
      "garden",
      "home",
      "house",
      "house with garden"
    ],
    "dirName": "House with garden",
    "styles": {
      "3D": "house_with_garden_3d.png",
      "Animated": "House with Garden.png"
    },
    "sortOrder": 832
  },
  "🏘️": {
    "glyph": "🏘️",
    "group": "Travel & Places",
    "keywords": [
      "houses"
    ],
    "dirName": "Houses",
    "styles": {
      "3D": "houses_3d.png",
      "Animated": "Houses.png"
    },
    "sortOrder": 829
  },
  "🤗": {
    "glyph": "🤗",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "hug",
      "hugging",
      "open hands",
      "smiling face",
      "smiling face with open hands"
    ],
    "dirName": "Hugging face",
    "styles": {
      "3D": "hugging_face_3d.png"
    },
    "sortOrder": 30
  },
  "💯": {
    "glyph": "💯",
    "group": "Smileys & Emotion",
    "keywords": [
      "100",
      "full",
      "hundred",
      "hundred points",
      "score"
    ],
    "dirName": "Hundred points",
    "styles": {
      "3D": "hundred_points_3d.png",
      "Animated": "Hundred Points.png"
    },
    "sortOrder": 150
  },
  "😯": {
    "glyph": "😯",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "hushed",
      "stunned",
      "surprised"
    ],
    "dirName": "Hushed face",
    "styles": {
      "3D": "hushed_face_3d.png",
      "Animated": "Hushed Face.png"
    },
    "sortOrder": 79
  },
  "🛖": {
    "glyph": "🛖",
    "group": "Travel & Places",
    "keywords": [
      "house",
      "hut",
      "roundhouse",
      "yurt"
    ],
    "dirName": "Hut",
    "styles": {
      "3D": "hut_3d.png",
      "Animated": "Hut.png"
    },
    "sortOrder": 828
  },
  "🧊": {
    "glyph": "🧊",
    "group": "Food & Drink",
    "keywords": [
      "cold",
      "ice",
      "ice cube",
      "iceberg"
    ],
    "dirName": "Ice",
    "styles": {
      "3D": "ice_3d.png",
      "Animated": "Ice.png"
    },
    "sortOrder": 798
  },
  "🍨": {
    "glyph": "🍨",
    "group": "Food & Drink",
    "keywords": [
      "cream",
      "dessert",
      "ice",
      "sweet"
    ],
    "dirName": "Ice cream",
    "styles": {
      "3D": "ice_cream_3d.png",
      "Animated": "Ice Cream.png"
    },
    "sortOrder": 767
  },
  "🏒": {
    "glyph": "🏒",
    "group": "Activities",
    "keywords": [
      "game",
      "hockey",
      "ice",
      "puck",
      "stick"
    ],
    "dirName": "Ice hockey",
    "styles": {
      "3D": "ice_hockey_3d.png",
      "Animated": "Ice Hockey.png"
    },
    "sortOrder": 1063
  },
  "⛸️": {
    "glyph": "⛸️",
    "group": "Activities",
    "keywords": [
      "ice",
      "skate"
    ],
    "dirName": "Ice skate",
    "styles": {
      "3D": "ice_skate_3d.png",
      "Animated": "Ice Skate.png"
    },
    "sortOrder": 1071
  },
  "🆔": {
    "glyph": "🆔",
    "group": "Symbols",
    "keywords": [
      "id",
      "ID button",
      "identity"
    ],
    "dirName": "Id button",
    "styles": {
      "3D": "id_button_3d.png",
      "Animated": "ID Button.png"
    },
    "sortOrder": 1525
  },
  "🪪": {
    "glyph": "🪪",
    "group": "Objects",
    "keywords": [
      "credentials",
      "ID",
      "identification card",
      "license",
      "security"
    ],
    "dirName": "Identification card",
    "styles": {
      "3D": "identification_card_3d.png",
      "Animated": "Identification Card.png"
    },
    "sortOrder": 1364
  },
  "📥": {
    "glyph": "📥",
    "group": "Objects",
    "keywords": [
      "box",
      "inbox",
      "letter",
      "mail",
      "receive",
      "tray"
    ],
    "dirName": "Inbox tray",
    "styles": {
      "3D": "inbox_tray_3d.png",
      "Animated": "Inbox Tray.png"
    },
    "sortOrder": 1250
  },
  "📨": {
    "glyph": "📨",
    "group": "Objects",
    "keywords": [
      "e-mail",
      "email",
      "envelope",
      "incoming",
      "letter",
      "receive"
    ],
    "dirName": "Incoming envelope",
    "styles": {
      "3D": "incoming_envelope_3d.png",
      "Animated": "Incoming Envelope.png"
    },
    "sortOrder": 1247
  },
  "🫵": {
    "glyph": "🫵",
    "group": "People & Body",
    "keywords": [
      "index pointing at the viewer",
      "point",
      "you"
    ],
    "dirName": "Index pointing at the viewer",
    "skintones": {
      "Default": {
        "3D": "index_pointing_at_the_viewer_3d_default.png",
        "Animated": "Index Pointing at the Viewer.png"
      }
    },
    "sortOrder": 188
  },
  "☝️": {
    "glyph": "☝️",
    "group": "People & Body",
    "keywords": [
      "finger",
      "hand",
      "index",
      "index pointing up",
      "point",
      "up"
    ],
    "dirName": "Index pointing up",
    "skintones": {
      "Default": {
        "3D": "index_pointing_up_3d_default.png",
        "Animated": "Index Pointing Up.png"
      }
    },
    "sortOrder": 187
  },
  "♾️": {
    "glyph": "♾️",
    "group": "Symbols",
    "keywords": [
      "forever",
      "infinity",
      "unbounded",
      "universal"
    ],
    "dirName": "Infinity",
    "styles": {
      "3D": "infinity_3d.png",
      "Animated": "Infinity.png"
    },
    "sortOrder": 1469
  },
  "ℹ️": {
    "glyph": "ℹ️",
    "group": "Symbols",
    "keywords": [
      "i",
      "information"
    ],
    "dirName": "Information",
    "styles": {
      "3D": "information_3d.png",
      "Animated": "Information.png"
    },
    "sortOrder": 1524
  },
  "🔤": {
    "glyph": "🔤",
    "group": "Symbols",
    "keywords": [
      "abc",
      "alphabet",
      "input",
      "latin",
      "letters"
    ],
    "dirName": "Input latin letters",
    "styles": {
      "3D": "input_latin_letters_3d.png",
      "Animated": "Input Latin Letters.png"
    },
    "sortOrder": 1517
  },
  "🔡": {
    "glyph": "🔡",
    "group": "Symbols",
    "keywords": [
      "abcd",
      "input",
      "latin",
      "letters",
      "lowercase"
    ],
    "dirName": "Input latin lowercase",
    "styles": {
      "3D": "input_latin_lowercase_3d.png",
      "Animated": "Input Latin Lowercase.png"
    },
    "sortOrder": 1514
  },
  "🔠": {
    "glyph": "🔠",
    "group": "Symbols",
    "keywords": [
      "ABCD",
      "input",
      "latin",
      "letters",
      "uppercase"
    ],
    "dirName": "Input latin uppercase",
    "styles": {
      "3D": "input_latin_uppercase_3d.png",
      "Animated": "Input Latin Uppercase.png"
    },
    "sortOrder": 1513
  },
  "🔢": {
    "glyph": "🔢",
    "group": "Symbols",
    "keywords": [
      "1234",
      "input",
      "numbers"
    ],
    "dirName": "Input numbers",
    "styles": {
      "3D": "input_numbers_3d.png",
      "Animated": "Input Numbers.png"
    },
    "sortOrder": 1515
  },
  "🔣": {
    "glyph": "🔣",
    "group": "Symbols",
    "keywords": [
      "〒♪&%",
      "input",
      "input symbols"
    ],
    "dirName": "Input symbols",
    "styles": {
      "3D": "input_symbols_3d.png",
      "Animated": "Input Symbols.png"
    },
    "sortOrder": 1516
  },
  "🎃": {
    "glyph": "🎃",
    "group": "Activities",
    "keywords": [
      "celebration",
      "halloween",
      "jack",
      "jack-o-lantern",
      "lantern"
    ],
    "dirName": "Jack-o-lantern",
    "styles": {
      "3D": "jack-o-lantern_3d.png",
      "Animated": "Jack-O-Lantern.png"
    },
    "sortOrder": 1024
  },
  "🉑": {
    "glyph": "🉑",
    "group": "Symbols",
    "keywords": [
      "“acceptable”",
      "ideograph",
      "Japanese",
      "Japanese “acceptable” button",
      "可"
    ],
    "dirName": "Japanese acceptable button",
    "styles": {
      "3D": "japanese_acceptable_button_3d.png",
      "Animated": "Japanese “Acceptable” Button.png"
    },
    "sortOrder": 1544
  },
  "🈸": {
    "glyph": "🈸",
    "group": "Symbols",
    "keywords": [
      "“application”",
      "ideograph",
      "Japanese",
      "Japanese “application” button",
      "申"
    ],
    "dirName": "Japanese application button",
    "styles": {
      "3D": "japanese_application_button_3d.png",
      "Animated": "Japanese “Application” Button.png"
    },
    "sortOrder": 1545
  },
  "🉐": {
    "glyph": "🉐",
    "group": "Symbols",
    "keywords": [
      "“bargain”",
      "ideograph",
      "Japanese",
      "Japanese “bargain” button",
      "得"
    ],
    "dirName": "Japanese bargain button",
    "styles": {
      "3D": "japanese_bargain_button_3d.png",
      "Animated": "Japanese “Bargain” Button.png"
    },
    "sortOrder": 1540
  },
  "🏯": {
    "glyph": "🏯",
    "group": "Travel & Places",
    "keywords": [
      "castle",
      "Japanese"
    ],
    "dirName": "Japanese castle",
    "styles": {
      "3D": "japanese_castle_3d.png",
      "Animated": "Japanese Castle.png"
    },
    "sortOrder": 844
  },
  "㊗️": {
    "glyph": "㊗️",
    "group": "Symbols",
    "keywords": [
      "“congratulations”",
      "ideograph",
      "Japanese",
      "Japanese “congratulations” button",
      "祝"
    ],
    "dirName": "Japanese congratulations button",
    "styles": {
      "3D": "japanese_congratulations_button_3d.png",
      "Animated": "Japanese “Congratulations” Button.png"
    },
    "sortOrder": 1548
  },
  "🈹": {
    "glyph": "🈹",
    "group": "Symbols",
    "keywords": [
      "“discount”",
      "ideograph",
      "Japanese",
      "Japanese “discount” button",
      "割"
    ],
    "dirName": "Japanese discount button",
    "styles": {
      "3D": "japanese_discount_button_3d.png",
      "Animated": "Japanese “Discount” Button.png"
    },
    "sortOrder": 1541
  },
  "🎎": {
    "glyph": "🎎",
    "group": "Activities",
    "keywords": [
      "celebration",
      "doll",
      "festival",
      "Japanese",
      "Japanese dolls"
    ],
    "dirName": "Japanese dolls",
    "styles": {
      "3D": "japanese_dolls_3d.png",
      "Animated": "Japanese Dolls.png"
    },
    "sortOrder": 1035
  },
  "🈚": {
    "glyph": "🈚",
    "group": "Symbols",
    "keywords": [
      "“free of charge”",
      "ideograph",
      "Japanese",
      "Japanese “free of charge” button",
      "無"
    ],
    "dirName": "Japanese free of charge button",
    "styles": {
      "3D": "japanese_free_of_charge_button_3d.png",
      "Animated": "Japanese “Free of Charge” Button.png"
    },
    "sortOrder": 1542
  },
  "🈁": {
    "glyph": "🈁",
    "group": "Symbols",
    "keywords": [
      "“here”",
      "Japanese",
      "Japanese “here” button",
      "katakana",
      "ココ"
    ],
    "dirName": "Japanese here button",
    "styles": {
      "3D": "japanese_here_button_3d.png",
      "Animated": "Japanese “Here” Button.png"
    },
    "sortOrder": 1535
  },
  "🈷️": {
    "glyph": "🈷️",
    "group": "Symbols",
    "keywords": [
      "“monthly amount”",
      "ideograph",
      "Japanese",
      "Japanese “monthly amount” button",
      "月"
    ],
    "dirName": "Japanese monthly amount button",
    "styles": {
      "3D": "japanese_monthly_amount_button_3d.png",
      "Animated": "Japanese “Monthly Amount” Button.png"
    },
    "sortOrder": 1537
  },
  "🈵": {
    "glyph": "🈵",
    "group": "Symbols",
    "keywords": [
      "“no vacancy”",
      "ideograph",
      "Japanese",
      "Japanese “no vacancy” button",
      "満"
    ],
    "dirName": "Japanese no vacancy button",
    "styles": {
      "3D": "japanese_no_vacancy_button_3d.png",
      "Animated": "Japanese “No Vacancy” Button.png"
    },
    "sortOrder": 1551
  },
  "🈶": {
    "glyph": "🈶",
    "group": "Symbols",
    "keywords": [
      "“not free of charge”",
      "ideograph",
      "Japanese",
      "Japanese “not free of charge” button",
      "有"
    ],
    "dirName": "Japanese not free of charge button",
    "styles": {
      "3D": "japanese_not_free_of_charge_button_3d.png",
      "Animated": "Japanese “Not Free of Charge” Button.png"
    },
    "sortOrder": 1538
  },
  "🈺": {
    "glyph": "🈺",
    "group": "Symbols",
    "keywords": [
      "“open for business”",
      "ideograph",
      "Japanese",
      "Japanese “open for business” button",
      "営"
    ],
    "dirName": "Japanese open for business button",
    "styles": {
      "3D": "japanese_open_for_business_button_3d.png",
      "Animated": "Japanese “Open for Business” Button.png"
    },
    "sortOrder": 1550
  },
  "🈴": {
    "glyph": "🈴",
    "group": "Symbols",
    "keywords": [
      "“passing grade”",
      "ideograph",
      "Japanese",
      "Japanese “passing grade” button",
      "合"
    ],
    "dirName": "Japanese passing grade button",
    "styles": {
      "3D": "japanese_passing_grade_button_3d.png",
      "Animated": "Japanese “Passing Grade” Button.png"
    },
    "sortOrder": 1546
  },
  "🏣": {
    "glyph": "🏣",
    "group": "Travel & Places",
    "keywords": [
      "Japanese",
      "Japanese post office",
      "post"
    ],
    "dirName": "Japanese post office",
    "styles": {
      "3D": "japanese_post_office_3d.png",
      "Animated": "Japanese Post Office.png"
    },
    "sortOrder": 834
  },
  "🈲": {
    "glyph": "🈲",
    "group": "Symbols",
    "keywords": [
      "“prohibited”",
      "ideograph",
      "Japanese",
      "Japanese “prohibited” button",
      "禁"
    ],
    "dirName": "Japanese prohibited button",
    "styles": {
      "3D": "japanese_prohibited_button_3d.png",
      "Animated": "Japanese “Prohibited” Button.png"
    },
    "sortOrder": 1543
  },
  "🈯": {
    "glyph": "🈯",
    "group": "Symbols",
    "keywords": [
      "“reserved”",
      "ideograph",
      "Japanese",
      "Japanese “reserved” button",
      "指"
    ],
    "dirName": "Japanese reserved button",
    "styles": {
      "3D": "japanese_reserved_button_3d.png",
      "Animated": "Japanese “Reserved” Button.png"
    },
    "sortOrder": 1539
  },
  "㊙️": {
    "glyph": "㊙️",
    "group": "Symbols",
    "keywords": [
      "“secret”",
      "ideograph",
      "Japanese",
      "Japanese “secret” button",
      "秘"
    ],
    "dirName": "Japanese secret button",
    "styles": {
      "3D": "japanese_secret_button_3d.png",
      "Animated": "Japanese “Secret” Button.png"
    },
    "sortOrder": 1549
  },
  "🈂️": {
    "glyph": "🈂️",
    "group": "Symbols",
    "keywords": [
      "“service charge”",
      "Japanese",
      "Japanese “service charge” button",
      "katakana",
      "サ"
    ],
    "dirName": "Japanese service charge button",
    "styles": {
      "3D": "japanese_service_charge_button_3d.png",
      "Animated": "Japanese “Service Charge” Button.png"
    },
    "sortOrder": 1536
  },
  "🔰": {
    "glyph": "🔰",
    "group": "Symbols",
    "keywords": [
      "beginner",
      "chevron",
      "Japanese",
      "Japanese symbol for beginner",
      "leaf"
    ],
    "dirName": "Japanese symbol for beginner",
    "styles": {
      "3D": "japanese_symbol_for_beginner_3d.png",
      "Animated": "Japanese Symbol for Beginner.png"
    },
    "sortOrder": 1484
  },
  "🈳": {
    "glyph": "🈳",
    "group": "Symbols",
    "keywords": [
      "“vacancy”",
      "ideograph",
      "Japanese",
      "Japanese “vacancy” button",
      "空"
    ],
    "dirName": "Japanese vacancy button",
    "styles": {
      "3D": "japanese_vacancy_button_3d.png",
      "Animated": "Japanese “Vacancy” Button.png"
    },
    "sortOrder": 1547
  },
  "🫙": {
    "glyph": "🫙",
    "group": "Food & Drink",
    "keywords": [
      "condiment",
      "container",
      "empty",
      "jar",
      "sauce",
      "store"
    ],
    "dirName": "Jar",
    "styles": {
      "3D": "jar_3d.png",
      "Animated": "Jar.png"
    },
    "sortOrder": 804
  },
  "👖": {
    "glyph": "👖",
    "group": "Objects",
    "keywords": [
      "clothing",
      "jeans",
      "pants",
      "trousers"
    ],
    "dirName": "Jeans",
    "styles": {
      "3D": "jeans_3d.png",
      "Animated": "Jeans.png"
    },
    "sortOrder": 1117
  },
  "🃏": {
    "glyph": "🃏",
    "group": "Activities",
    "keywords": [
      "card",
      "game",
      "joker",
      "wildcard"
    ],
    "dirName": "Joker",
    "styles": {
      "3D": "joker_3d.png",
      "Animated": "Joker.png"
    },
    "sortOrder": 1100
  },
  "🕹️": {
    "glyph": "🕹️",
    "group": "Activities",
    "keywords": [
      "game",
      "joystick",
      "video game"
    ],
    "dirName": "Joystick",
    "styles": {
      "3D": "joystick_3d.png",
      "Animated": "Joystick.png"
    },
    "sortOrder": 1087
  },
  "🧑‍⚖️": {
    "glyph": "🧑‍⚖️",
    "group": "People & Body",
    "keywords": [
      "judge",
      "justice",
      "scales"
    ],
    "dirName": "Judge",
    "skintones": {
      "Default": {
        "3D": "judge_3d_default.png",
        "Animated": "Judge.png"
      }
    },
    "sortOrder": 290
  },
  "🕋": {
    "glyph": "🕋",
    "group": "Travel & Places",
    "keywords": [
      "islam",
      "kaaba",
      "Muslim",
      "religion"
    ],
    "dirName": "Kaaba",
    "styles": {
      "3D": "kaaba_3d.png",
      "Animated": "Kaaba.png"
    },
    "sortOrder": 854
  },
  "🦘": {
    "glyph": "🦘",
    "group": "Animals & Nature",
    "keywords": [
      "Australia",
      "joey",
      "jump",
      "kangaroo",
      "marsupial"
    ],
    "dirName": "Kangaroo",
    "styles": {
      "3D": "kangaroo_3d.png",
      "Animated": "Kangaroo.png"
    },
    "sortOrder": 591
  },
  "🔑": {
    "glyph": "🔑",
    "group": "Objects",
    "keywords": [
      "key",
      "lock",
      "password"
    ],
    "dirName": "Key",
    "styles": {
      "3D": "key_3d.png",
      "Animated": "Key.png"
    },
    "sortOrder": 1292
  },
  "⌨️": {
    "glyph": "⌨️",
    "group": "Objects",
    "keywords": [
      "computer",
      "keyboard"
    ],
    "dirName": "Keyboard",
    "styles": {
      "3D": "keyboard_3d.png",
      "Animated": "Keyboard.png"
    },
    "sortOrder": 1194
  },
  "0️⃣": {
    "glyph": "0️⃣",
    "group": "Symbols",
    "keywords": [
      "keycap",
      "zero"
    ],
    "dirName": "Keycap 0",
    "styles": {
      "3D": "keycap_0_3d.png"
    },
    "sortOrder": 1502
  },
  "1️⃣": {
    "glyph": "1️⃣",
    "group": "Symbols",
    "keywords": [
      "keycap",
      "one"
    ],
    "dirName": "Keycap 1",
    "styles": {
      "3D": "keycap_1_3d.png"
    },
    "sortOrder": 1503
  },
  "🔟": {
    "glyph": "🔟",
    "group": "Symbols",
    "keywords": [
      "keycap",
      "ten"
    ],
    "dirName": "Keycap 10",
    "styles": {
      "3D": "keycap_10_3d.png"
    },
    "sortOrder": 1512
  },
  "2️⃣": {
    "glyph": "2️⃣",
    "group": "Symbols",
    "keywords": [
      "keycap",
      "two"
    ],
    "dirName": "Keycap 2",
    "styles": {
      "3D": "keycap_2_3d.png"
    },
    "sortOrder": 1504
  },
  "3️⃣": {
    "glyph": "3️⃣",
    "group": "Symbols",
    "keywords": [
      "keycap",
      "three"
    ],
    "dirName": "Keycap 3",
    "styles": {
      "3D": "keycap_3_3d.png"
    },
    "sortOrder": 1505
  },
  "4️⃣": {
    "glyph": "4️⃣",
    "group": "Symbols",
    "keywords": [
      "keycap",
      "four"
    ],
    "dirName": "Keycap 4",
    "styles": {
      "3D": "keycap_4_3d.png"
    },
    "sortOrder": 1506
  },
  "5️⃣": {
    "glyph": "5️⃣",
    "group": "Symbols",
    "keywords": [
      "keycap",
      "five"
    ],
    "dirName": "Keycap 5",
    "styles": {
      "3D": "keycap_5_3d.png"
    },
    "sortOrder": 1507
  },
  "6️⃣": {
    "glyph": "6️⃣",
    "group": "Symbols",
    "keywords": [
      "keycap",
      "six"
    ],
    "dirName": "Keycap 6",
    "styles": {
      "3D": "keycap_6_3d.png"
    },
    "sortOrder": 1508
  },
  "7️⃣": {
    "glyph": "7️⃣",
    "group": "Symbols",
    "keywords": [
      "keycap",
      "seven"
    ],
    "dirName": "Keycap 7",
    "styles": {
      "3D": "keycap_7_3d.png"
    },
    "sortOrder": 1509
  },
  "8️⃣": {
    "glyph": "8️⃣",
    "group": "Symbols",
    "keywords": [
      "keycap",
      "eight"
    ],
    "dirName": "Keycap 8",
    "styles": {
      "3D": "keycap_8_3d.png"
    },
    "sortOrder": 1510
  },
  "9️⃣": {
    "glyph": "9️⃣",
    "group": "Symbols",
    "keywords": [
      "keycap",
      "nine"
    ],
    "dirName": "Keycap 9",
    "styles": {
      "3D": "keycap_9_3d.png"
    },
    "sortOrder": 1511
  },
  "*️⃣": {
    "glyph": "*️⃣",
    "group": "Symbols",
    "keywords": [
      "keycap",
      "asterisk"
    ],
    "dirName": "Keycap asterisk",
    "styles": {
      "3D": "keycap_asterisk_3d.png"
    },
    "sortOrder": 1501
  },
  "#️⃣": {
    "glyph": "#️⃣",
    "group": "Symbols",
    "keywords": [
      "keycap",
      "hash"
    ],
    "dirName": "Keycap hashtag",
    "styles": {
      "3D": "keycap_hashtag_3d.png"
    },
    "sortOrder": 1500
  },
  "🛴": {
    "glyph": "🛴",
    "group": "Travel & Places",
    "keywords": [
      "kick",
      "scooter"
    ],
    "dirName": "Kick scooter",
    "styles": {
      "3D": "kick_scooter_3d.png",
      "Animated": "Kick Scooter.png"
    },
    "sortOrder": 908
  },
  "👘": {
    "glyph": "👘",
    "group": "Objects",
    "keywords": [
      "clothing",
      "kimono"
    ],
    "dirName": "Kimono",
    "styles": {
      "3D": "kimono_3d.png",
      "Animated": "Kimono.png"
    },
    "sortOrder": 1123
  },
  "💋": {
    "glyph": "💋",
    "group": "Smileys & Emotion",
    "keywords": [
      "kiss",
      "kiss mark",
      "lips"
    ],
    "dirName": "Kiss mark",
    "styles": {
      "3D": "kiss_mark_3d.png",
      "Animated": "Kiss Mark.png"
    },
    "sortOrder": 127
  },
  "😽": {
    "glyph": "😽",
    "group": "Smileys & Emotion",
    "keywords": [
      "cat",
      "eye",
      "face",
      "kiss",
      "kissing cat"
    ],
    "dirName": "Kissing cat",
    "styles": {
      "3D": "kissing_cat_3d.png",
      "Animated": "Kissing Cat.png"
    },
    "sortOrder": 120
  },
  "😗": {
    "glyph": "😗",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "kiss",
      "kissing face"
    ],
    "dirName": "Kissing face",
    "styles": {
      "3D": "kissing_face_3d.png",
      "Animated": "Kissing Face.png"
    },
    "sortOrder": 19
  },
  "😚": {
    "glyph": "😚",
    "group": "Smileys & Emotion",
    "keywords": [
      "closed",
      "eye",
      "face",
      "kiss",
      "kissing face with closed eyes"
    ],
    "dirName": "Kissing face with closed eyes",
    "styles": {
      "3D": "kissing_face_with_closed_eyes_3d.png",
      "Animated": "Kissing Face with Closed Eyes.png"
    },
    "sortOrder": 21
  },
  "😙": {
    "glyph": "😙",
    "group": "Smileys & Emotion",
    "keywords": [
      "eye",
      "face",
      "kiss",
      "kissing face with smiling eyes",
      "smile"
    ],
    "dirName": "Kissing face with smiling eyes",
    "styles": {
      "3D": "kissing_face_with_smiling_eyes_3d.png",
      "Animated": "Kissing Face with Smiling Eyes.png"
    },
    "sortOrder": 22
  },
  "🔪": {
    "glyph": "🔪",
    "group": "Food & Drink",
    "keywords": [
      "cooking",
      "hocho",
      "kitchen knife",
      "knife",
      "tool",
      "weapon"
    ],
    "dirName": "Kitchen knife",
    "styles": {
      "3D": "kitchen_knife_3d.png",
      "Animated": "Kitchen Knife.png"
    },
    "sortOrder": 803
  },
  "🪁": {
    "glyph": "🪁",
    "group": "Activities",
    "keywords": [
      "fly",
      "kite",
      "soar"
    ],
    "dirName": "Kite",
    "styles": {
      "3D": "kite_3d.png",
      "Animated": "Kite.png"
    },
    "sortOrder": 1080
  },
  "🥝": {
    "glyph": "🥝",
    "group": "Food & Drink",
    "keywords": [
      "food",
      "fruit",
      "kiwi"
    ],
    "dirName": "Kiwi fruit",
    "styles": {
      "3D": "kiwi_fruit_3d.png",
      "Animated": "Kiwi Fruit.png"
    },
    "sortOrder": 689
  },
  "😵": {
    "glyph": "😵",
    "group": "Smileys & Emotion",
    "keywords": [
      "crossed-out eyes",
      "dead",
      "face",
      "face with crossed-out eyes",
      "knocked out"
    ],
    "dirName": "Knocked-out face",
    "styles": {
      "3D": "knocked-out_face_3d.png"
    },
    "sortOrder": 64
  },
  "🪢": {
    "glyph": "🪢",
    "group": "Activities",
    "keywords": [
      "knot",
      "rope",
      "tangled",
      "tie",
      "twine",
      "twist"
    ],
    "dirName": "Knot",
    "styles": {
      "3D": "knot_3d.png",
      "Animated": "Knot.png"
    },
    "sortOrder": 1109
  },
  "🐨": {
    "glyph": "🐨",
    "group": "Animals & Nature",
    "keywords": [
      "koala",
      "face",
      "marsupial"
    ],
    "dirName": "Koala",
    "styles": {
      "3D": "koala_3d.png",
      "Animated": "Koala.png"
    },
    "sortOrder": 586
  },
  "🥼": {
    "glyph": "🥼",
    "group": "Objects",
    "keywords": [
      "doctor",
      "experiment",
      "lab coat",
      "scientist"
    ],
    "dirName": "Lab coat",
    "styles": {
      "3D": "lab_coat_3d.png",
      "Animated": "Lab Coat.png"
    },
    "sortOrder": 1113
  },
  "🏷️": {
    "glyph": "🏷️",
    "group": "Objects",
    "keywords": [
      "label"
    ],
    "dirName": "Label",
    "styles": {
      "3D": "label_3d.png",
      "Animated": "Label.png"
    },
    "sortOrder": 1234
  },
  "🥍": {
    "glyph": "🥍",
    "group": "Activities",
    "keywords": [
      "ball",
      "goal",
      "lacrosse",
      "stick"
    ],
    "dirName": "Lacrosse",
    "styles": {
      "3D": "lacrosse_3d.png",
      "Animated": "Lacrosse.png"
    },
    "sortOrder": 1064
  },
  "🪜": {
    "glyph": "🪜",
    "group": "Objects",
    "keywords": [
      "climb",
      "ladder",
      "rung",
      "step"
    ],
    "dirName": "Ladder",
    "styles": {
      "3D": "ladder_3d.png",
      "Animated": "Ladder.png"
    },
    "sortOrder": 1318
  },
  "🐞": {
    "glyph": "🐞",
    "group": "Animals & Nature",
    "keywords": [
      "beetle",
      "insect",
      "lady beetle",
      "ladybird",
      "ladybug"
    ],
    "dirName": "Lady beetle",
    "styles": {
      "3D": "lady_beetle_3d.png",
      "Animated": "Lady Beetle.png"
    },
    "sortOrder": 638
  },
  "💻": {
    "glyph": "💻",
    "group": "Objects",
    "keywords": [
      "computer",
      "laptop",
      "pc",
      "personal"
    ],
    "dirName": "Laptop",
    "styles": {
      "3D": "laptop_3d.png",
      "Animated": "Laptop.png"
    },
    "sortOrder": 1191
  },
  "🔷": {
    "glyph": "🔷",
    "group": "Symbols",
    "keywords": [
      "blue",
      "diamond",
      "geometric",
      "large blue diamond"
    ],
    "dirName": "Large blue diamond",
    "styles": {
      "3D": "large_blue_diamond_3d.png",
      "Animated": "Large Blue Diamond.png"
    },
    "sortOrder": 1577
  },
  "🔶": {
    "glyph": "🔶",
    "group": "Symbols",
    "keywords": [
      "diamond",
      "geometric",
      "large orange diamond",
      "orange"
    ],
    "dirName": "Large orange diamond",
    "styles": {
      "3D": "large_orange_diamond_3d.png",
      "Animated": "Large Orange Diamond.png"
    },
    "sortOrder": 1576
  },
  "🌗": {
    "glyph": "🌗",
    "group": "Travel & Places",
    "keywords": [
      "last quarter moon",
      "moon",
      "quarter"
    ],
    "dirName": "Last quarter moon",
    "styles": {
      "3D": "last_quarter_moon_3d.png",
      "Animated": "Last Quarter Moon.png"
    },
    "sortOrder": 983
  },
  "🌜": {
    "glyph": "🌜",
    "group": "Travel & Places",
    "keywords": [
      "face",
      "last quarter moon face",
      "moon",
      "quarter"
    ],
    "dirName": "Last quarter moon face",
    "styles": {
      "3D": "last_quarter_moon_face_3d.png",
      "Animated": "Last Quarter Moon Face.png"
    },
    "sortOrder": 988
  },
  "⏮️": {
    "glyph": "⏮️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "last track button",
      "previous scene",
      "previous track",
      "triangle"
    ],
    "dirName": "Last track button",
    "styles": {
      "3D": "last_track_button_3d.png",
      "Animated": "Last Track Button.png"
    },
    "sortOrder": 1446
  },
  "✝️": {
    "glyph": "✝️",
    "group": "Symbols",
    "keywords": [
      "Christian",
      "cross",
      "latin cross",
      "religion"
    ],
    "dirName": "Latin cross",
    "styles": {
      "3D": "latin_cross_3d.png",
      "Animated": "Latin Cross.png"
    },
    "sortOrder": 1418
  },
  "🍃": {
    "glyph": "🍃",
    "group": "Animals & Nature",
    "keywords": [
      "blow",
      "flutter",
      "leaf",
      "leaf fluttering in wind",
      "wind"
    ],
    "dirName": "Leaf fluttering in wind",
    "styles": {
      "3D": "leaf_fluttering_in_wind_3d.png"
    },
    "sortOrder": 671
  },
  "🥬": {
    "glyph": "🥬",
    "group": "Food & Drink",
    "keywords": [
      "bok choy",
      "cabbage",
      "kale",
      "leafy green",
      "lettuce"
    ],
    "dirName": "Leafy green",
    "styles": {
      "3D": "leafy_green_3d.png",
      "Animated": "Leafy Green.png"
    },
    "sortOrder": 701
  },
  "📒": {
    "glyph": "📒",
    "group": "Objects",
    "keywords": [
      "ledger",
      "notebook"
    ],
    "dirName": "Ledger",
    "styles": {
      "3D": "ledger_3d.png",
      "Animated": "Ledger.png"
    },
    "sortOrder": 1226
  },
  "⬅️": {
    "glyph": "⬅️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "cardinal",
      "direction",
      "left arrow",
      "west"
    ],
    "dirName": "Left arrow",
    "styles": {
      "3D": "left_arrow_3d.png",
      "Animated": "Left Arrow.png"
    },
    "sortOrder": 1397
  },
  "↪️": {
    "glyph": "↪️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "left arrow curving right"
    ],
    "dirName": "Left arrow curving right",
    "styles": {
      "3D": "left_arrow_curving_right_3d.png",
      "Animated": "Left Arrow Curving Right.png"
    },
    "sortOrder": 1402
  },
  "🛅": {
    "glyph": "🛅",
    "group": "Symbols",
    "keywords": [
      "baggage",
      "left luggage",
      "locker",
      "luggage"
    ],
    "dirName": "Left luggage",
    "styles": {
      "3D": "left_luggage_3d.png",
      "Animated": "Left Luggage.png"
    },
    "sortOrder": 1377
  },
  "🗨️": {
    "glyph": "🗨️",
    "group": "Smileys & Emotion",
    "keywords": [
      "dialog",
      "left speech bubble",
      "speech"
    ],
    "dirName": "Left speech bubble",
    "styles": {
      "3D": "left_speech_bubble_3d.png",
      "Animated": "Left Speech Bubble.png"
    },
    "sortOrder": 160
  },
  "🤛": {
    "glyph": "🤛",
    "group": "People & Body",
    "keywords": [
      "fist",
      "left-facing fist",
      "leftwards"
    ],
    "dirName": "Left-facing fist",
    "skintones": {
      "Default": {
        "3D": "left-facing_fist_3d_default.png",
        "Animated": "Left-Facing Fist.png"
      }
    },
    "sortOrder": 193
  },
  "↔️": {
    "glyph": "↔️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "left-right arrow"
    ],
    "dirName": "Left-right arrow",
    "styles": {
      "3D": "left-right_arrow_3d.png",
      "Animated": "Left-Right Arrow.png"
    },
    "sortOrder": 1400
  },
  "🫲": {
    "glyph": "🫲",
    "group": "People & Body",
    "keywords": [
      "hand",
      "left",
      "leftward",
      "leftwards hand"
    ],
    "dirName": "Leftwards hand",
    "skintones": {
      "Default": {
        "3D": "leftwards_hand_3d_default.png",
        "Animated": "Leftwards Hand.png"
      }
    },
    "sortOrder": 170
  },
  "🦵": {
    "glyph": "🦵",
    "group": "People & Body",
    "keywords": [
      "kick",
      "leg",
      "limb"
    ],
    "dirName": "Leg",
    "skintones": {
      "Default": {
        "3D": "leg_3d_default.png",
        "Animated": "Leg.png"
      }
    },
    "sortOrder": 208
  },
  "🍋": {
    "glyph": "🍋",
    "group": "Food & Drink",
    "keywords": [
      "citrus",
      "fruit",
      "lemon"
    ],
    "dirName": "Lemon",
    "styles": {
      "3D": "lemon_3d.png",
      "Animated": "Lemon.png"
    },
    "sortOrder": 678
  },
  "♌": {
    "glyph": "♌",
    "group": "Symbols",
    "keywords": [
      "Leo",
      "lion",
      "zodiac"
    ],
    "dirName": "Leo",
    "styles": {
      "3D": "leo_3d.png",
      "Animated": "Leo.png"
    },
    "sortOrder": 1428
  },
  "🐆": {
    "glyph": "🐆",
    "group": "Animals & Nature",
    "keywords": [
      "leopard"
    ],
    "dirName": "Leopard",
    "styles": {
      "3D": "leopard_3d.png",
      "Animated": "Leopard.png"
    },
    "sortOrder": 548
  },
  "🎚️": {
    "glyph": "🎚️",
    "group": "Objects",
    "keywords": [
      "level",
      "music",
      "slider"
    ],
    "dirName": "Level slider",
    "styles": {
      "3D": "level_slider_3d.png",
      "Animated": "Level Slider.png"
    },
    "sortOrder": 1168
  },
  "♎": {
    "glyph": "♎",
    "group": "Symbols",
    "keywords": [
      "balance",
      "justice",
      "Libra",
      "scales",
      "zodiac"
    ],
    "dirName": "Libra",
    "styles": {
      "3D": "libra_3d.png",
      "Animated": "Libra.png"
    },
    "sortOrder": 1430
  },
  "💡": {
    "glyph": "💡",
    "group": "Objects",
    "keywords": [
      "bulb",
      "comic",
      "electric",
      "idea",
      "light"
    ],
    "dirName": "Light bulb",
    "styles": {
      "3D": "light_bulb_3d.png",
      "Animated": "Light Bulb.png"
    },
    "sortOrder": 1214
  },
  "🚈": {
    "glyph": "🚈",
    "group": "Travel & Places",
    "keywords": [
      "light rail",
      "railway"
    ],
    "dirName": "Light rail",
    "styles": {
      "3D": "light_rail_3d.png",
      "Animated": "Light Rail.png"
    },
    "sortOrder": 878
  },
  "🔗": {
    "glyph": "🔗",
    "group": "Objects",
    "keywords": [
      "link"
    ],
    "dirName": "Link",
    "styles": {
      "3D": "link_3d.png",
      "Animated": "Link.png"
    },
    "sortOrder": 1313
  },
  "🖇️": {
    "glyph": "🖇️",
    "group": "Objects",
    "keywords": [
      "link",
      "linked paperclips",
      "paperclip"
    ],
    "dirName": "Linked paperclips",
    "styles": {
      "3D": "linked_paperclips_3d.png",
      "Animated": "Linked Paperclips.png"
    },
    "sortOrder": 1281
  },
  "🦁": {
    "glyph": "🦁",
    "group": "Animals & Nature",
    "keywords": [
      "face",
      "Leo",
      "lion",
      "zodiac"
    ],
    "dirName": "Lion",
    "styles": {
      "3D": "lion_3d.png",
      "Animated": "Lion.png"
    },
    "sortOrder": 545
  },
  "💄": {
    "glyph": "💄",
    "group": "Objects",
    "keywords": [
      "cosmetics",
      "lipstick",
      "makeup"
    ],
    "dirName": "Lipstick",
    "styles": {
      "3D": "lipstick_3d.png",
      "Animated": "Lipstick.png"
    },
    "sortOrder": 1152
  },
  "🚮": {
    "glyph": "🚮",
    "group": "Symbols",
    "keywords": [
      "litter",
      "litter bin",
      "litter in bin sign"
    ],
    "dirName": "Litter in bin sign",
    "styles": {
      "3D": "litter_in_bin_sign_3d.png",
      "Animated": "Litter in Bin Sign.png"
    },
    "sortOrder": 1366
  },
  "🦎": {
    "glyph": "🦎",
    "group": "Animals & Nature",
    "keywords": [
      "lizard",
      "reptile"
    ],
    "dirName": "Lizard",
    "styles": {
      "3D": "lizard_3d.png",
      "Animated": "Lizard.png"
    },
    "sortOrder": 615
  },
  "🦙": {
    "glyph": "🦙",
    "group": "Animals & Nature",
    "keywords": [
      "alpaca",
      "guanaco",
      "llama",
      "vicuña",
      "wool"
    ],
    "dirName": "Llama",
    "styles": {
      "3D": "llama_3d.png",
      "Animated": "Llama.png"
    },
    "sortOrder": 568
  },
  "🦞": {
    "glyph": "🦞",
    "group": "Food & Drink",
    "keywords": [
      "bisque",
      "claws",
      "lobster",
      "seafood"
    ],
    "dirName": "Lobster",
    "styles": {
      "3D": "lobster_3d.png",
      "Animated": "Lobster.png"
    },
    "sortOrder": 761
  },
  "🔒": {
    "glyph": "🔒",
    "group": "Objects",
    "keywords": [
      "closed",
      "locked"
    ],
    "dirName": "Locked",
    "styles": {
      "3D": "locked_3d.png",
      "Animated": "Locked.png"
    },
    "sortOrder": 1288
  },
  "🔐": {
    "glyph": "🔐",
    "group": "Objects",
    "keywords": [
      "closed",
      "key",
      "lock",
      "locked with key",
      "secure"
    ],
    "dirName": "Locked with key",
    "styles": {
      "3D": "locked_with_key_3d.png",
      "Animated": "Locked with Key.png"
    },
    "sortOrder": 1291
  },
  "🔏": {
    "glyph": "🔏",
    "group": "Objects",
    "keywords": [
      "ink",
      "lock",
      "locked with pen",
      "nib",
      "pen",
      "privacy"
    ],
    "dirName": "Locked with pen",
    "styles": {
      "3D": "locked_with_pen_3d.png",
      "Animated": "Locked with Pen.png"
    },
    "sortOrder": 1290
  },
  "🚂": {
    "glyph": "🚂",
    "group": "Travel & Places",
    "keywords": [
      "engine",
      "locomotive",
      "railway",
      "steam",
      "train"
    ],
    "dirName": "Locomotive",
    "styles": {
      "3D": "locomotive_3d.png",
      "Animated": "Locomotive.png"
    },
    "sortOrder": 872
  },
  "🍭": {
    "glyph": "🍭",
    "group": "Food & Drink",
    "keywords": [
      "candy",
      "dessert",
      "lollipop",
      "sweet"
    ],
    "dirName": "Lollipop",
    "styles": {
      "3D": "lollipop_3d.png",
      "Animated": "Lollipop.png"
    },
    "sortOrder": 776
  },
  "🪘": {
    "glyph": "🪘",
    "group": "Objects",
    "keywords": [
      "beat",
      "conga",
      "drum",
      "long drum",
      "rhythm"
    ],
    "dirName": "Long drum",
    "styles": {
      "3D": "long_drum_3d.png",
      "Animated": "Long Drum.png"
    },
    "sortOrder": 1181
  },
  "🧴": {
    "glyph": "🧴",
    "group": "Objects",
    "keywords": [
      "lotion",
      "lotion bottle",
      "moisturizer",
      "shampoo",
      "sunscreen"
    ],
    "dirName": "Lotion bottle",
    "styles": {
      "3D": "lotion_bottle_3d.png",
      "Animated": "Lotion Bottle.png"
    },
    "sortOrder": 1346
  },
  "🪷": {
    "glyph": "🪷",
    "group": "Animals & Nature",
    "keywords": [
      "Buddhism",
      "flower",
      "Hinduism",
      "India",
      "lotus",
      "purity",
      "Vietnam"
    ],
    "dirName": "Lotus",
    "styles": {
      "3D": "lotus_3d.png"
    },
    "sortOrder": 651
  },
  "😭": {
    "glyph": "😭",
    "group": "Smileys & Emotion",
    "keywords": [
      "cry",
      "face",
      "loudly crying face",
      "sad",
      "sob",
      "tear"
    ],
    "dirName": "Loudly crying face",
    "styles": {
      "3D": "loudly_crying_face_3d.png",
      "Animated": "Loudly Crying Face.png"
    },
    "sortOrder": 90
  },
  "📢": {
    "glyph": "📢",
    "group": "Objects",
    "keywords": [
      "loud",
      "loudspeaker",
      "public address"
    ],
    "dirName": "Loudspeaker",
    "styles": {
      "3D": "loudspeaker_3d.png",
      "Animated": "Loudspeaker.png"
    },
    "sortOrder": 1159
  },
  "🏩": {
    "glyph": "🏩",
    "group": "Travel & Places",
    "keywords": [
      "hotel",
      "love"
    ],
    "dirName": "Love hotel",
    "styles": {
      "3D": "love_hotel_3d.png",
      "Animated": "Love Hotel.png"
    },
    "sortOrder": 839
  },
  "💌": {
    "glyph": "💌",
    "group": "Smileys & Emotion",
    "keywords": [
      "heart",
      "letter",
      "love",
      "mail"
    ],
    "dirName": "Love letter",
    "styles": {
      "3D": "love_letter_3d.png",
      "Animated": "Love Letter.png"
    },
    "sortOrder": 128
  },
  "🤟": {
    "glyph": "🤟",
    "group": "People & Body",
    "keywords": [
      "hand",
      "ILY",
      "love-you gesture"
    ],
    "dirName": "Love-you gesture",
    "skintones": {
      "Default": {
        "3D": "love-you_gesture_3d_default.png",
        "Animated": "Love-You Gesture.png"
      }
    },
    "sortOrder": 179
  },
  "🪫": {
    "glyph": "🪫",
    "group": "Objects",
    "keywords": [
      "electronic",
      "low battery",
      "low energy"
    ],
    "dirName": "Low battery",
    "styles": {
      "3D": "low_battery_3d.png",
      "Animated": "Low Battery.png"
    },
    "sortOrder": 1189
  },
  "🧳": {
    "glyph": "🧳",
    "group": "Travel & Places",
    "keywords": [
      "luggage",
      "packing",
      "travel"
    ],
    "dirName": "Luggage",
    "styles": {
      "3D": "luggage_3d.png",
      "Animated": "Luggage.png"
    },
    "sortOrder": 945
  },
  "🫁": {
    "glyph": "🫁",
    "group": "People & Body",
    "keywords": [
      "breath",
      "exhalation",
      "inhalation",
      "lungs",
      "organ",
      "respiration"
    ],
    "dirName": "Lungs",
    "styles": {
      "3D": "lungs_3d.png",
      "Animated": "Lungs.png"
    },
    "sortOrder": 215
  },
  "🤥": {
    "glyph": "🤥",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "lie",
      "lying face",
      "pinocchio"
    ],
    "dirName": "Lying face",
    "styles": {
      "3D": "lying_face_3d.png",
      "Animated": "Lying Face.png"
    },
    "sortOrder": 49
  },
  "🪄": {
    "glyph": "🪄",
    "group": "Activities",
    "keywords": [
      "magic",
      "magic wand",
      "witch",
      "wizard"
    ],
    "dirName": "Magic wand",
    "styles": {
      "3D": "magic_wand_3d.png",
      "Animated": "Magic Wand.png"
    },
    "sortOrder": 1083
  },
  "🧲": {
    "glyph": "🧲",
    "group": "Objects",
    "keywords": [
      "attraction",
      "horseshoe",
      "magnet",
      "magnetic"
    ],
    "dirName": "Magnet",
    "styles": {
      "3D": "magnet_3d.png",
      "Animated": "Magnet.png"
    },
    "sortOrder": 1317
  },
  "🔍": {
    "glyph": "🔍",
    "group": "Objects",
    "keywords": [
      "glass",
      "magnifying",
      "magnifying glass tilted left",
      "search",
      "tool"
    ],
    "dirName": "Magnifying glass tilted left",
    "styles": {
      "3D": "magnifying_glass_tilted_left_3d.png",
      "Animated": "Magnifying Glass Tilted Left.png"
    },
    "sortOrder": 1211
  },
  "🔎": {
    "glyph": "🔎",
    "group": "Objects",
    "keywords": [
      "glass",
      "magnifying",
      "magnifying glass tilted right",
      "search",
      "tool"
    ],
    "dirName": "Magnifying glass tilted right",
    "styles": {
      "3D": "magnifying_glass_tilted_right_3d.png",
      "Animated": "Magnifying Glass Tilted Right.png"
    },
    "sortOrder": 1212
  },
  "🀄": {
    "glyph": "🀄",
    "group": "Activities",
    "keywords": [
      "game",
      "mahjong",
      "mahjong red dragon",
      "red"
    ],
    "dirName": "Mahjong red dragon",
    "styles": {
      "3D": "mahjong_red_dragon_3d.png",
      "Animated": "Mahjong Red Dragon.png"
    },
    "sortOrder": 1101
  },
  "♂️": {
    "glyph": "♂️",
    "group": "Symbols",
    "keywords": [
      "male sign",
      "man"
    ],
    "dirName": "Male sign",
    "styles": {
      "3D": "male_sign_3d.png",
      "Animated": "Male Sign.png"
    },
    "sortOrder": 1462
  },
  "🦣": {
    "glyph": "🦣",
    "group": "Animals & Nature",
    "keywords": [
      "extinction",
      "large",
      "mammoth",
      "tusk",
      "woolly"
    ],
    "dirName": "Mammoth",
    "styles": {
      "3D": "mammoth_3d.png",
      "Animated": "Mammoth.png"
    },
    "sortOrder": 571
  },
  "👨": {
    "glyph": "👨",
    "group": "People & Body",
    "keywords": [
      "adult",
      "man"
    ],
    "dirName": "Man",
    "skintones": {
      "Default": {
        "3D": "man_3d_default.png"
      }
    },
    "sortOrder": 229
  },
  "👨‍🎨": {
    "glyph": "👨‍🎨",
    "group": "People & Body",
    "keywords": [
      "artist",
      "man",
      "palette"
    ],
    "dirName": "Man artist",
    "skintones": {
      "Default": {
        "3D": "man_artist_3d_default.png",
        "Animated": "Man Artist.png"
      }
    },
    "sortOrder": 318
  },
  "👨‍🚀": {
    "glyph": "👨‍🚀",
    "group": "People & Body",
    "keywords": [
      "astronaut",
      "man",
      "rocket"
    ],
    "dirName": "Man astronaut",
    "skintones": {
      "Default": {
        "3D": "man_astronaut_3d_default.png",
        "Animated": "Man Astronaut.png"
      }
    },
    "sortOrder": 324
  },
  "👨‍🦲": {
    "glyph": "👨‍🦲",
    "group": "People & Body",
    "keywords": [
      "adult",
      "bald",
      "man"
    ],
    "dirName": "Man bald",
    "skintones": {
      "Default": {
        "3D": "man_bald_3d_default.png"
      }
    },
    "sortOrder": 236
  },
  "🧔‍♂️": {
    "glyph": "🧔‍♂️",
    "group": "People & Body",
    "keywords": [
      "beard",
      "man",
      "man: beard"
    ],
    "dirName": "Man beard",
    "skintones": {
      "Default": {
        "3D": "man_beard_3d_default.png"
      }
    },
    "sortOrder": 231
  },
  "🚴‍♂️": {
    "glyph": "🚴‍♂️",
    "group": "People & Body",
    "keywords": [
      "bicycle",
      "biking",
      "cyclist",
      "man"
    ],
    "dirName": "Man biking",
    "skintones": {
      "Default": {
        "3D": "man_biking_3d_default.png",
        "Animated": "Man Biking.png"
      }
    },
    "sortOrder": 457
  },
  "👱‍♂️": {
    "glyph": "👱‍♂️",
    "group": "People & Body",
    "keywords": [
      "blond",
      "blond-haired man",
      "hair",
      "man",
      "man: blond hair"
    ],
    "dirName": "Man blonde hair",
    "skintones": {
      "Default": {
        "3D": "man_blonde_hair_3d_default.png"
      }
    },
    "sortOrder": 247
  },
  "⛹️‍♂️": {
    "glyph": "⛹️‍♂️",
    "group": "People & Body",
    "keywords": [
      "ball",
      "man",
      "man bouncing ball"
    ],
    "dirName": "Man bouncing ball",
    "skintones": {
      "Default": {
        "3D": "man_bouncing_ball_3d_default.png",
        "Animated": "Man Bouncing Ball.png"
      }
    },
    "sortOrder": 451
  },
  "🙇‍♂️": {
    "glyph": "🙇‍♂️",
    "group": "People & Body",
    "keywords": [
      "apology",
      "bowing",
      "favor",
      "gesture",
      "man",
      "sorry"
    ],
    "dirName": "Man bowing",
    "skintones": {
      "Default": {
        "3D": "man_bowing_3d_default.png",
        "Animated": "Man Bowing.png"
      }
    },
    "sortOrder": 273
  },
  "🤸‍♂️": {
    "glyph": "🤸‍♂️",
    "group": "People & Body",
    "keywords": [
      "cartwheel",
      "gymnastics",
      "man",
      "man cartwheeling"
    ],
    "dirName": "Man cartwheeling",
    "skintones": {
      "Default": {
        "3D": "man_cartwheeling_3d_default.png",
        "Animated": "Man Cartwheeling.png"
      }
    },
    "sortOrder": 463
  },
  "🧗‍♂️": {
    "glyph": "🧗‍♂️",
    "group": "People & Body",
    "keywords": [
      "climber",
      "man climbing"
    ],
    "dirName": "Man climbing",
    "skintones": {
      "Default": {
        "3D": "man_climbing_3d_default.png",
        "Animated": "Man Climbing.png"
      }
    },
    "sortOrder": 432
  },
  "👷‍♂️": {
    "glyph": "👷‍♂️",
    "group": "People & Body",
    "keywords": [
      "construction",
      "man",
      "worker"
    ],
    "dirName": "Man construction worker",
    "skintones": {
      "Default": {
        "3D": "man_construction_worker_3d_default.png",
        "Animated": "Man Construction Worker.png"
      }
    },
    "sortOrder": 340
  },
  "👨‍🍳": {
    "glyph": "👨‍🍳",
    "group": "People & Body",
    "keywords": [
      "chef",
      "cook",
      "man"
    ],
    "dirName": "Man cook",
    "skintones": {
      "Default": {
        "3D": "man_cook_3d_default.png",
        "Animated": "Man Cook.png"
      }
    },
    "sortOrder": 297
  },
  "👨‍🦱": {
    "glyph": "👨‍🦱",
    "group": "People & Body",
    "keywords": [
      "adult",
      "curly hair",
      "man"
    ],
    "dirName": "Man curly hair",
    "skintones": {
      "Default": {
        "3D": "man_curly_hair_3d_default.png"
      }
    },
    "sortOrder": 234
  },
  "🕺": {
    "glyph": "🕺",
    "group": "People & Body",
    "keywords": [
      "dance",
      "dancing",
      "man"
    ],
    "dirName": "Man dancing",
    "skintones": {
      "Default": {
        "3D": "man_dancing_3d_default.png",
        "Animated": "Man Dancing.png"
      }
    },
    "sortOrder": 423
  },
  "🧏‍♂️": {
    "glyph": "🧏‍♂️",
    "group": "People & Body",
    "keywords": [
      "deaf",
      "man"
    ],
    "dirName": "Man deaf",
    "skintones": {
      "Default": {
        "3D": "man_deaf_3d_default.png",
        "Animated": "Deaf Man.png"
      }
    },
    "sortOrder": 270
  },
  "🕵️‍♂️": {
    "glyph": "🕵️‍♂️",
    "group": "People & Body",
    "keywords": [
      "detective",
      "man",
      "sleuth",
      "spy"
    ],
    "dirName": "Man detective",
    "skintones": {
      "Default": {
        "3D": "man_detective_3d_default.png",
        "Animated": "Man Detective.png"
      }
    },
    "sortOrder": 333
  },
  "🧝‍♂️": {
    "glyph": "🧝‍♂️",
    "group": "People & Body",
    "keywords": [
      "magical",
      "man elf"
    ],
    "dirName": "Man elf",
    "skintones": {
      "Default": {
        "3D": "man_elf_3d_default.png",
        "Animated": "Man Elf.png"
      }
    },
    "sortOrder": 386
  },
  "🤦‍♂️": {
    "glyph": "🤦‍♂️",
    "group": "People & Body",
    "keywords": [
      "disbelief",
      "exasperation",
      "facepalm",
      "man",
      "man facepalming"
    ],
    "dirName": "Man facepalming",
    "skintones": {
      "Default": {
        "3D": "man_facepalming_3d_default.png",
        "Animated": "Man Facepalming.png"
      }
    },
    "sortOrder": 276
  },
  "👨‍🏭": {
    "glyph": "👨‍🏭",
    "group": "People & Body",
    "keywords": [
      "assembly",
      "factory",
      "industrial",
      "man",
      "worker"
    ],
    "dirName": "Man factory worker",
    "skintones": {
      "Default": {
        "3D": "man_factory_worker_3d_default.png",
        "Animated": "Man Factory Worker.png"
      }
    },
    "sortOrder": 303
  },
  "🧚‍♂️": {
    "glyph": "🧚‍♂️",
    "group": "People & Body",
    "keywords": [
      "man fairy",
      "Oberon",
      "Puck"
    ],
    "dirName": "Man fairy",
    "skintones": {
      "Default": {
        "3D": "man_fairy_3d_default.png",
        "Animated": "Man Fairy.png"
      }
    },
    "sortOrder": 377
  },
  "👨‍🌾": {
    "glyph": "👨‍🌾",
    "group": "People & Body",
    "keywords": [
      "farmer",
      "gardener",
      "man",
      "rancher"
    ],
    "dirName": "Man farmer",
    "skintones": {
      "Default": {
        "3D": "man_farmer_3d_default.png",
        "Animated": "Man Farmer.png"
      }
    },
    "sortOrder": 294
  },
  "👨‍🍼": {
    "glyph": "👨‍🍼",
    "group": "People & Body",
    "keywords": [
      "baby",
      "feeding",
      "man",
      "nursing"
    ],
    "dirName": "Man feeding baby",
    "skintones": {
      "Default": {
        "3D": "man_feeding_baby_3d_default.png",
        "Animated": "Man Feeding Baby.png"
      }
    },
    "sortOrder": 361
  },
  "👨‍🚒": {
    "glyph": "👨‍🚒",
    "group": "People & Body",
    "keywords": [
      "firefighter",
      "firetruck",
      "man"
    ],
    "dirName": "Man firefighter",
    "skintones": {
      "Default": {
        "3D": "man_firefighter_3d_default.png",
        "Animated": "Man Firefighter.png"
      }
    },
    "sortOrder": 327
  },
  "🙍‍♂️": {
    "glyph": "🙍‍♂️",
    "group": "People & Body",
    "keywords": [
      "frowning",
      "gesture",
      "man"
    ],
    "dirName": "Man frowning",
    "skintones": {
      "Default": {
        "3D": "man_frowning_3d_default.png",
        "Animated": "Man Frowning.png"
      }
    },
    "sortOrder": 252
  },
  "🧞‍♂️": {
    "glyph": "🧞‍♂️",
    "group": "People & Body",
    "keywords": [
      "djinn",
      "man genie"
    ],
    "dirName": "Man genie",
    "styles": {
      "3D": "man_genie_3d.png",
      "Animated": "Man Genie.png"
    },
    "sortOrder": 389
  },
  "🙅‍♂️": {
    "glyph": "🙅‍♂️",
    "group": "People & Body",
    "keywords": [
      "forbidden",
      "gesture",
      "hand",
      "man",
      "man gesturing NO",
      "prohibited"
    ],
    "dirName": "Man gesturing no",
    "skintones": {
      "Default": {
        "3D": "man_gesturing_no_3d_default.png",
        "Animated": "Man Gesturing No.png"
      }
    },
    "sortOrder": 258
  },
  "🙆‍♂️": {
    "glyph": "🙆‍♂️",
    "group": "People & Body",
    "keywords": [
      "gesture",
      "hand",
      "man",
      "man gesturing OK",
      "OK"
    ],
    "dirName": "Man gesturing ok",
    "skintones": {
      "Default": {
        "3D": "man_gesturing_ok_3d_default.png",
        "Animated": "Man Gesturing OK.png"
      }
    },
    "sortOrder": 261
  },
  "💇‍♂️": {
    "glyph": "💇‍♂️",
    "group": "People & Body",
    "keywords": [
      "haircut",
      "man",
      "man getting haircut"
    ],
    "dirName": "Man getting haircut",
    "skintones": {
      "Default": {
        "3D": "man_getting_haircut_3d_default.png",
        "Animated": "Man Getting Haircut.png"
      }
    },
    "sortOrder": 399
  },
  "💆‍♂️": {
    "glyph": "💆‍♂️",
    "group": "People & Body",
    "keywords": [
      "face",
      "man",
      "man getting massage",
      "massage"
    ],
    "dirName": "Man getting massage",
    "skintones": {
      "Default": {
        "3D": "man_getting_massage_3d_default.png",
        "Animated": "Man Getting Massage.png"
      }
    },
    "sortOrder": 396
  },
  "🏌️‍♂️": {
    "glyph": "🏌️‍♂️",
    "group": "People & Body",
    "keywords": [
      "golf",
      "man",
      "man golfing"
    ],
    "dirName": "Man golfing",
    "skintones": {
      "Default": {
        "3D": "man_golfing_3d_default.png",
        "Animated": "Man Golfing.png"
      }
    },
    "sortOrder": 439
  },
  "💂‍♂️": {
    "glyph": "💂‍♂️",
    "group": "People & Body",
    "keywords": [
      "guard",
      "man"
    ],
    "dirName": "Man guard",
    "skintones": {
      "Default": {
        "3D": "man_guard_3d_default.png",
        "Animated": "Man Guard.png"
      }
    },
    "sortOrder": 336
  },
  "👨‍⚕️": {
    "glyph": "👨‍⚕️",
    "group": "People & Body",
    "keywords": [
      "doctor",
      "healthcare",
      "man",
      "man health worker",
      "nurse",
      "therapist"
    ],
    "dirName": "Man health worker",
    "skintones": {
      "Default": {
        "3D": "man_health_worker_3d_default.png",
        "Animated": "Man Health Worker.png"
      }
    },
    "sortOrder": 282
  },
  "🧘‍♂️": {
    "glyph": "🧘‍♂️",
    "group": "People & Body",
    "keywords": [
      "man in lotus position",
      "meditation",
      "yoga"
    ],
    "dirName": "Man in lotus position",
    "skintones": {
      "Default": {
        "3D": "man_in_lotus_position_3d_default.png",
        "Animated": "Man in Lotus Position.png"
      }
    },
    "sortOrder": 478
  },
  "👨‍🦽": {
    "glyph": "👨‍🦽",
    "group": "People & Body",
    "keywords": [
      "accessibility",
      "man",
      "man in manual wheelchair",
      "wheelchair"
    ],
    "dirName": "Man in manual wheelchair",
    "skintones": {
      "Default": {
        "3D": "man_in_manual_wheelchair_3d_default.png",
        "Animated": "Man in Manual Wheelchair.png"
      }
    },
    "sortOrder": 417
  },
  "👨‍🦼": {
    "glyph": "👨‍🦼",
    "group": "People & Body",
    "keywords": [
      "accessibility",
      "man",
      "man in motorized wheelchair",
      "wheelchair"
    ],
    "dirName": "Man in motorized wheelchair",
    "skintones": {
      "Default": {
        "3D": "man_in_motorized_wheelchair_3d_default.png",
        "Animated": "Man in Motorized Wheelchair.png"
      }
    },
    "sortOrder": 414
  },
  "🧖‍♂️": {
    "glyph": "🧖‍♂️",
    "group": "People & Body",
    "keywords": [
      "man in steamy room",
      "sauna",
      "steam room"
    ],
    "dirName": "Man in steamy room",
    "skintones": {
      "Default": {
        "3D": "man_in_steamy_room_3d_default.png",
        "Animated": "Man in Steamy Room.png"
      }
    },
    "sortOrder": 429
  },
  "🤵‍♂️": {
    "glyph": "🤵‍♂️",
    "group": "People & Body",
    "keywords": [
      "man",
      "man in tuxedo",
      "tuxedo"
    ],
    "dirName": "Man in tuxedo",
    "skintones": {
      "Default": {
        "3D": "man_in_tuxedo_3d_default.png",
        "Animated": "Man in Tuxedo.png"
      }
    },
    "sortOrder": 351
  },
  "👨‍⚖️": {
    "glyph": "👨‍⚖️",
    "group": "People & Body",
    "keywords": [
      "judge",
      "justice",
      "man",
      "scales"
    ],
    "dirName": "Man judge",
    "skintones": {
      "Default": {
        "3D": "man_judge_3d_default.png",
        "Animated": "Man Judge.png"
      }
    },
    "sortOrder": 291
  },
  "🤹‍♂️": {
    "glyph": "🤹‍♂️",
    "group": "People & Body",
    "keywords": [
      "juggling",
      "man",
      "multitask"
    ],
    "dirName": "Man juggling",
    "skintones": {
      "Default": {
        "3D": "man_juggling_3d_default.png",
        "Animated": "Man Juggling.png"
      }
    },
    "sortOrder": 475
  },
  "🧎‍♂️": {
    "glyph": "🧎‍♂️",
    "group": "People & Body",
    "keywords": [
      "kneeling",
      "man"
    ],
    "dirName": "Man kneeling",
    "skintones": {
      "Default": {
        "3D": "man_kneeling_3d_default.png",
        "Animated": "Man Kneeling.png"
      }
    },
    "sortOrder": 408
  },
  "🏋️‍♂️": {
    "glyph": "🏋️‍♂️",
    "group": "People & Body",
    "keywords": [
      "man",
      "man lifting weights",
      "weight lifter"
    ],
    "dirName": "Man lifting weights",
    "skintones": {
      "Default": {
        "3D": "man_lifting_weights_3d_default.png",
        "Animated": "Man Lifting Weights.png"
      }
    },
    "sortOrder": 454
  },
  "🧙‍♂️": {
    "glyph": "🧙‍♂️",
    "group": "People & Body",
    "keywords": [
      "man mage",
      "sorcerer",
      "wizard"
    ],
    "dirName": "Man mage",
    "skintones": {
      "Default": {
        "3D": "man_mage_3d_default.png",
        "Animated": "Man Mage.png"
      }
    },
    "sortOrder": 374
  },
  "👨‍🔧": {
    "glyph": "👨‍🔧",
    "group": "People & Body",
    "keywords": [
      "electrician",
      "man",
      "mechanic",
      "plumber",
      "tradesperson"
    ],
    "dirName": "Man mechanic",
    "skintones": {
      "Default": {
        "3D": "man_mechanic_3d_default.png",
        "Animated": "Man Mechanic.png"
      }
    },
    "sortOrder": 300
  },
  "🧜‍♂️": {
    "glyph": "🧜‍♂️",
    "group": "People & Body",
    "keywords": [
      "merman",
      "Triton"
    ],
    "dirName": "Man merpeople",
    "skintones": {
      "Default": {
        "3D": "man_merpeople_3d_default.png",
        "Animated": "Merman.png"
      }
    },
    "sortOrder": 383
  },
  "🚵‍♂️": {
    "glyph": "🚵‍♂️",
    "group": "People & Body",
    "keywords": [
      "bicycle",
      "bike",
      "cyclist",
      "man",
      "man mountain biking",
      "mountain"
    ],
    "dirName": "Man mountain biking",
    "skintones": {
      "Default": {
        "3D": "man_mountain_biking_3d_default.png",
        "Animated": "Man Mountain Biking.png"
      }
    },
    "sortOrder": 460
  },
  "👨‍💼": {
    "glyph": "👨‍💼",
    "group": "People & Body",
    "keywords": [
      "architect",
      "business",
      "man",
      "man office worker",
      "manager",
      "white-collar"
    ],
    "dirName": "Man office worker",
    "skintones": {
      "Default": {
        "3D": "man_office_worker_3d_default.png",
        "Animated": "Man Office Worker.png"
      }
    },
    "sortOrder": 306
  },
  "👨‍✈️": {
    "glyph": "👨‍✈️",
    "group": "People & Body",
    "keywords": [
      "man",
      "pilot",
      "plane"
    ],
    "dirName": "Man pilot",
    "skintones": {
      "Default": {
        "3D": "man_pilot_3d_default.png",
        "Animated": "Man Pilot.png"
      }
    },
    "sortOrder": 321
  },
  "🤾‍♂️": {
    "glyph": "🤾‍♂️",
    "group": "People & Body",
    "keywords": [
      "handball",
      "man",
      "man playing handball"
    ],
    "dirName": "Man playing handball",
    "skintones": {
      "Default": {
        "3D": "man_playing_handball_3d_default.png",
        "Animated": "Man Playing Handball.png"
      }
    },
    "sortOrder": 472
  },
  "🤽‍♂️": {
    "glyph": "🤽‍♂️",
    "group": "People & Body",
    "keywords": [
      "man",
      "man playing water polo",
      "water polo"
    ],
    "dirName": "Man playing water polo",
    "skintones": {
      "Default": {
        "3D": "man_playing_water_polo_3d_default.png",
        "Animated": "Man Playing Water Polo.png"
      }
    },
    "sortOrder": 469
  },
  "👮‍♂️": {
    "glyph": "👮‍♂️",
    "group": "People & Body",
    "keywords": [
      "cop",
      "man",
      "officer",
      "police"
    ],
    "dirName": "Man police officer",
    "skintones": {
      "Default": {
        "3D": "man_police_officer_3d_default.png",
        "Animated": "Man Police Officer.png"
      }
    },
    "sortOrder": 330
  },
  "🙎‍♂️": {
    "glyph": "🙎‍♂️",
    "group": "People & Body",
    "keywords": [
      "gesture",
      "man",
      "pouting"
    ],
    "dirName": "Man pouting",
    "skintones": {
      "Default": {
        "3D": "man_pouting_3d_default.png",
        "Animated": "Man Pouting.png"
      }
    },
    "sortOrder": 255
  },
  "🙋‍♂️": {
    "glyph": "🙋‍♂️",
    "group": "People & Body",
    "keywords": [
      "gesture",
      "man",
      "man raising hand",
      "raising hand"
    ],
    "dirName": "Man raising hand",
    "skintones": {
      "Default": {
        "3D": "man_raising_hand_3d_default.png",
        "Animated": "Man Raising Hand.png"
      }
    },
    "sortOrder": 267
  },
  "👨‍🦰": {
    "glyph": "👨‍🦰",
    "group": "People & Body",
    "keywords": [
      "adult",
      "man",
      "red hair"
    ],
    "dirName": "Man red hair",
    "skintones": {
      "Default": {
        "3D": "man_red_hair_3d_default.png"
      }
    },
    "sortOrder": 233
  },
  "🚣‍♂️": {
    "glyph": "🚣‍♂️",
    "group": "People & Body",
    "keywords": [
      "boat",
      "man",
      "man rowing boat",
      "rowboat"
    ],
    "dirName": "Man rowing boat",
    "skintones": {
      "Default": {
        "3D": "man_rowing_boat_3d_default.png",
        "Animated": "Man Rowing Boat.png"
      }
    },
    "sortOrder": 445
  },
  "🏃‍♂️": {
    "glyph": "🏃‍♂️",
    "group": "People & Body",
    "keywords": [
      "man",
      "marathon",
      "racing",
      "running"
    ],
    "dirName": "Man running",
    "skintones": {
      "Default": {
        "3D": "man_running_3d_default.png",
        "Animated": "Man Running.png"
      }
    },
    "sortOrder": 420
  },
  "👨‍🔬": {
    "glyph": "👨‍🔬",
    "group": "People & Body",
    "keywords": [
      "biologist",
      "chemist",
      "engineer",
      "man",
      "physicist",
      "scientist"
    ],
    "dirName": "Man scientist",
    "skintones": {
      "Default": {
        "3D": "man_scientist_3d_default.png",
        "Animated": "Man Scientist.png"
      }
    },
    "sortOrder": 309
  },
  "🤷‍♂️": {
    "glyph": "🤷‍♂️",
    "group": "People & Body",
    "keywords": [
      "doubt",
      "ignorance",
      "indifference",
      "man",
      "man shrugging",
      "shrug"
    ],
    "dirName": "Man shrugging",
    "skintones": {
      "Default": {
        "3D": "man_shrugging_3d_default.png",
        "Animated": "Man Shrugging.png"
      }
    },
    "sortOrder": 279
  },
  "👨‍🎤": {
    "glyph": "👨‍🎤",
    "group": "People & Body",
    "keywords": [
      "actor",
      "entertainer",
      "man",
      "rock",
      "singer",
      "star"
    ],
    "dirName": "Man singer",
    "skintones": {
      "Default": {
        "3D": "man_singer_3d_default.png",
        "Animated": "Man Singer.png"
      }
    },
    "sortOrder": 315
  },
  "🧍‍♂️": {
    "glyph": "🧍‍♂️",
    "group": "People & Body",
    "keywords": [
      "man",
      "standing"
    ],
    "dirName": "Man standing",
    "skintones": {
      "Default": {
        "3D": "man_standing_3d_default.png",
        "Animated": "Man Standing.png"
      }
    },
    "sortOrder": 405
  },
  "👨‍🎓": {
    "glyph": "👨‍🎓",
    "group": "People & Body",
    "keywords": [
      "graduate",
      "man",
      "student"
    ],
    "dirName": "Man student",
    "skintones": {
      "Default": {
        "3D": "man_student_3d_default.png",
        "Animated": "Man Student.png"
      }
    },
    "sortOrder": 285
  },
  "🦸‍♂️": {
    "glyph": "🦸‍♂️",
    "group": "People & Body",
    "keywords": [
      "good",
      "hero",
      "man",
      "man superhero",
      "superpower"
    ],
    "dirName": "Man superhero",
    "skintones": {
      "Default": {
        "3D": "man_superhero_3d_default.png",
        "Animated": "Man Superhero.png"
      }
    },
    "sortOrder": 368
  },
  "🦹‍♂️": {
    "glyph": "🦹‍♂️",
    "group": "People & Body",
    "keywords": [
      "criminal",
      "evil",
      "man",
      "man supervillain",
      "superpower",
      "villain"
    ],
    "dirName": "Man supervillain",
    "skintones": {
      "Default": {
        "3D": "man_supervillain_3d_default.png",
        "Animated": "Man Supervillain.png"
      }
    },
    "sortOrder": 371
  },
  "🏄‍♂️": {
    "glyph": "🏄‍♂️",
    "group": "People & Body",
    "keywords": [
      "man",
      "surfing"
    ],
    "dirName": "Man surfing",
    "skintones": {
      "Default": {
        "3D": "man_surfing_3d_default.png",
        "Animated": "Man Surfing.png"
      }
    },
    "sortOrder": 442
  },
  "🏊‍♂️": {
    "glyph": "🏊‍♂️",
    "group": "People & Body",
    "keywords": [
      "man",
      "man swimming",
      "swim"
    ],
    "dirName": "Man swimming",
    "skintones": {
      "Default": {
        "3D": "man_swimming_3d_default.png",
        "Animated": "Man Swimming.png"
      }
    },
    "sortOrder": 448
  },
  "👨‍🏫": {
    "glyph": "👨‍🏫",
    "group": "People & Body",
    "keywords": [
      "instructor",
      "man",
      "professor",
      "teacher"
    ],
    "dirName": "Man teacher",
    "skintones": {
      "Default": {
        "3D": "man_teacher_3d_default.png",
        "Animated": "Man Teacher.png"
      }
    },
    "sortOrder": 288
  },
  "💁‍♂️": {
    "glyph": "💁‍♂️",
    "group": "People & Body",
    "keywords": [
      "man",
      "man tipping hand",
      "sassy",
      "tipping hand"
    ],
    "dirName": "Man tipping hand",
    "skintones": {
      "Default": {
        "3D": "man_tipping_hand_3d_default.png",
        "Animated": "Man Tipping Hand.png"
      }
    },
    "sortOrder": 264
  },
  "🧛‍♂️": {
    "glyph": "🧛‍♂️",
    "group": "People & Body",
    "keywords": [
      "Dracula",
      "man vampire",
      "undead"
    ],
    "dirName": "Man vampire",
    "skintones": {
      "Default": {
        "3D": "man_vampire_3d_default.png",
        "Animated": "Man Vampire.png"
      }
    },
    "sortOrder": 380
  },
  "🚶‍♂️": {
    "glyph": "🚶‍♂️",
    "group": "People & Body",
    "keywords": [
      "hike",
      "man",
      "man walking",
      "walk"
    ],
    "dirName": "Man walking",
    "skintones": {
      "Default": {
        "3D": "man_walking_3d_default.png",
        "Animated": "Man Walking.png"
      }
    },
    "sortOrder": 402
  },
  "👳‍♂️": {
    "glyph": "👳‍♂️",
    "group": "People & Body",
    "keywords": [
      "man",
      "man wearing turban",
      "turban"
    ],
    "dirName": "Man wearing turban",
    "skintones": {
      "Default": {
        "3D": "man_wearing_turban_3d_default.png",
        "Animated": "Man Wearing Turban.png"
      }
    },
    "sortOrder": 346
  },
  "👨‍🦳": {
    "glyph": "👨‍🦳",
    "group": "People & Body",
    "keywords": [
      "adult",
      "man",
      "white hair"
    ],
    "dirName": "Man white hair",
    "skintones": {
      "Default": {
        "3D": "man_white_hair_3d_default.png"
      }
    },
    "sortOrder": 235
  },
  "👯‍♂️": {
    "glyph": "👯‍♂️",
    "group": "People & Body",
    "keywords": [
      "bunny ear",
      "dancer",
      "men",
      "men with bunny ears",
      "partying"
    ],
    "dirName": "Man with bunny ears",
    "styles": {
      "3D": "man_with_bunny_ears_3d.png",
      "Animated": "Men with Bunny Ears.png"
    },
    "sortOrder": 426
  },
  "👰‍♂️": {
    "glyph": "👰‍♂️",
    "group": "People & Body",
    "keywords": [
      "man",
      "man with veil",
      "veil"
    ],
    "dirName": "Man with veil",
    "skintones": {
      "Default": {
        "3D": "man_with_veil_3d_default.png",
        "Animated": "Man with Veil.png"
      }
    },
    "sortOrder": 354
  },
  "👨‍🦯": {
    "glyph": "👨‍🦯",
    "group": "People & Body",
    "keywords": [
      "accessibility",
      "blind",
      "man",
      "man with white cane"
    ],
    "dirName": "Man with white cane",
    "skintones": {
      "Default": {
        "3D": "man_with_white_cane_3d_default.png",
        "Animated": "Man with White Cane.png"
      }
    },
    "sortOrder": 411
  },
  "🤼‍♂️": {
    "glyph": "🤼‍♂️",
    "group": "People & Body",
    "keywords": [
      "men",
      "men wrestling",
      "wrestle"
    ],
    "dirName": "Man wrestling",
    "styles": {
      "3D": "man_wrestling_3d.png",
      "Animated": "Men Wrestling.png"
    },
    "sortOrder": 466
  },
  "🧟‍♂️": {
    "glyph": "🧟‍♂️",
    "group": "People & Body",
    "keywords": [
      "man zombie",
      "undead",
      "walking dead"
    ],
    "dirName": "Man zombie",
    "styles": {
      "3D": "man_zombie_3d.png",
      "Animated": "Man Zombie.png"
    },
    "sortOrder": 392
  },
  "🥭": {
    "glyph": "🥭",
    "group": "Food & Drink",
    "keywords": [
      "fruit",
      "mango",
      "tropical"
    ],
    "dirName": "Mango",
    "styles": {
      "3D": "mango_3d.png",
      "Animated": "Mango.png"
    },
    "sortOrder": 681
  },
  "👞": {
    "glyph": "👞",
    "group": "Objects",
    "keywords": [
      "clothing",
      "man",
      "man’s shoe",
      "shoe"
    ],
    "dirName": "Mans shoe",
    "styles": {
      "3D": "mans_shoe_3d.png",
      "Animated": "Man’s Shoe.png"
    },
    "sortOrder": 1136
  },
  "🕰️": {
    "glyph": "🕰️",
    "group": "Travel & Places",
    "keywords": [
      "clock",
      "mantelpiece clock"
    ],
    "dirName": "Mantelpiece clock",
    "styles": {
      "3D": "mantelpiece_clock_3d.png",
      "Animated": "Mantelpiece Clock.png"
    },
    "sortOrder": 952
  },
  "🦽": {
    "glyph": "🦽",
    "group": "Travel & Places",
    "keywords": [
      "accessibility",
      "manual wheelchair"
    ],
    "dirName": "Manual wheelchair",
    "styles": {
      "3D": "manual_wheelchair_3d.png",
      "Animated": "Manual Wheelchair.png"
    },
    "sortOrder": 904
  },
  "🗾": {
    "glyph": "🗾",
    "group": "Travel & Places",
    "keywords": [
      "Japan",
      "map",
      "map of Japan"
    ],
    "dirName": "Map of japan",
    "styles": {
      "3D": "map_of_japan_3d.png",
      "Animated": "Map of Japan.png"
    },
    "sortOrder": 811
  },
  "🍁": {
    "glyph": "🍁",
    "group": "Animals & Nature",
    "keywords": [
      "falling",
      "leaf",
      "maple"
    ],
    "dirName": "Maple leaf",
    "styles": {
      "3D": "maple_leaf_3d.png",
      "Animated": "Maple Leaf.png"
    },
    "sortOrder": 669
  },
  "🥋": {
    "glyph": "🥋",
    "group": "Activities",
    "keywords": [
      "judo",
      "karate",
      "martial arts",
      "martial arts uniform",
      "taekwondo",
      "uniform"
    ],
    "dirName": "Martial arts uniform",
    "styles": {
      "3D": "martial_arts_uniform_3d.png",
      "Animated": "Martial Arts Uniform.png"
    },
    "sortOrder": 1068
  },
  "🧉": {
    "glyph": "🧉",
    "group": "Food & Drink",
    "keywords": [
      "drink",
      "mate"
    ],
    "dirName": "Mate",
    "styles": {
      "3D": "mate_3d.png",
      "Animated": "Mate.png"
    },
    "sortOrder": 797
  },
  "🍖": {
    "glyph": "🍖",
    "group": "Food & Drink",
    "keywords": [
      "bone",
      "meat",
      "meat on bone"
    ],
    "dirName": "Meat on bone",
    "styles": {
      "3D": "meat_on_bone_3d.png",
      "Animated": "Meat on Bone.png"
    },
    "sortOrder": 718
  },
  "🧑‍🔧": {
    "glyph": "🧑‍🔧",
    "group": "People & Body",
    "keywords": [
      "electrician",
      "mechanic",
      "plumber",
      "tradesperson"
    ],
    "dirName": "Mechanic",
    "skintones": {
      "Default": {
        "3D": "mechanic_3d_default.png",
        "Animated": "Mechanic.png"
      }
    },
    "sortOrder": 299
  },
  "🦾": {
    "glyph": "🦾",
    "group": "People & Body",
    "keywords": [
      "accessibility",
      "mechanical arm",
      "prosthetic"
    ],
    "dirName": "Mechanical arm",
    "styles": {
      "3D": "mechanical_arm_3d.png",
      "Animated": "Mechanical Arm.png"
    },
    "sortOrder": 206
  },
  "🦿": {
    "glyph": "🦿",
    "group": "People & Body",
    "keywords": [
      "accessibility",
      "mechanical leg",
      "prosthetic"
    ],
    "dirName": "Mechanical leg",
    "styles": {
      "3D": "mechanical_leg_3d.png",
      "Animated": "Mechanical Leg.png"
    },
    "sortOrder": 207
  },
  "⚕️": {
    "glyph": "⚕️",
    "group": "Symbols",
    "keywords": [
      "aesculapius",
      "medical symbol",
      "medicine",
      "staff"
    ],
    "dirName": "Medical symbol",
    "styles": {
      "3D": "medical_symbol_3d.png",
      "Animated": "Medical Symbol.png"
    },
    "sortOrder": 1479
  },
  "📣": {
    "glyph": "📣",
    "group": "Objects",
    "keywords": [
      "cheering",
      "megaphone"
    ],
    "dirName": "Megaphone",
    "styles": {
      "3D": "megaphone_3d.png",
      "Animated": "Megaphone.png"
    },
    "sortOrder": 1160
  },
  "🍈": {
    "glyph": "🍈",
    "group": "Food & Drink",
    "keywords": [
      "fruit",
      "melon"
    ],
    "dirName": "Melon",
    "styles": {
      "3D": "melon_3d.png",
      "Animated": "Melon.png"
    },
    "sortOrder": 675
  },
  "🫠": {
    "glyph": "🫠",
    "group": "Smileys & Emotion",
    "keywords": [
      "disappear",
      "dissolve",
      "liquid",
      "melt",
      "melting face"
    ],
    "dirName": "Melting face",
    "styles": {
      "3D": "melting_face_3d.png"
    },
    "sortOrder": 11
  },
  "📝": {
    "glyph": "📝",
    "group": "Objects",
    "keywords": [
      "memo",
      "pencil"
    ],
    "dirName": "Memo",
    "styles": {
      "3D": "memo_3d.png",
      "Animated": "Memo.png"
    },
    "sortOrder": 1264
  },
  "❤️‍🩹": {
    "glyph": "❤️‍🩹",
    "group": "Smileys & Emotion",
    "keywords": [
      "healthier",
      "improving",
      "mending",
      "mending heart",
      "recovering",
      "recuperating",
      "well"
    ],
    "dirName": "Mending heart",
    "styles": {
      "3D": "mending_heart_3d.png",
      "Animated": "Mending Heart.png"
    },
    "sortOrder": 140
  },
  "🕎": {
    "glyph": "🕎",
    "group": "Symbols",
    "keywords": [
      "candelabrum",
      "candlestick",
      "menorah",
      "religion"
    ],
    "dirName": "Menorah",
    "styles": {
      "3D": "menorah_3d.png",
      "Animated": "Menorah.png"
    },
    "sortOrder": 1422
  },
  "🚹": {
    "glyph": "🚹",
    "group": "Symbols",
    "keywords": [
      "lavatory",
      "man",
      "men’s room",
      "restroom",
      "wc"
    ],
    "dirName": "Mens room",
    "styles": {
      "3D": "mens_room_3d.png",
      "Animated": "Men’s Room.png"
    },
    "sortOrder": 1369
  },
  "🚇": {
    "glyph": "🚇",
    "group": "Travel & Places",
    "keywords": [
      "metro",
      "subway"
    ],
    "dirName": "Metro",
    "styles": {
      "3D": "metro_3d.png",
      "Animated": "Metro.png"
    },
    "sortOrder": 877
  },
  "🦠": {
    "glyph": "🦠",
    "group": "Animals & Nature",
    "keywords": [
      "amoeba",
      "bacteria",
      "microbe",
      "virus"
    ],
    "dirName": "Microbe",
    "styles": {
      "3D": "microbe_3d.png",
      "Animated": "Microbe.png"
    },
    "sortOrder": 647
  },
  "🎤": {
    "glyph": "🎤",
    "group": "Objects",
    "keywords": [
      "karaoke",
      "mic",
      "microphone"
    ],
    "dirName": "Microphone",
    "styles": {
      "3D": "microphone_3d.png",
      "Animated": "Microphone.png"
    },
    "sortOrder": 1170
  },
  "🔬": {
    "glyph": "🔬",
    "group": "Objects",
    "keywords": [
      "microscope",
      "science",
      "tool"
    ],
    "dirName": "Microscope",
    "styles": {
      "3D": "microscope_3d.png",
      "Animated": "Microscope.png"
    },
    "sortOrder": 1323
  },
  "🖕": {
    "glyph": "🖕",
    "group": "People & Body",
    "keywords": [
      "finger",
      "hand",
      "middle finger"
    ],
    "dirName": "Middle finger",
    "skintones": {
      "Default": {
        "3D": "middle_finger_3d_default.png",
        "Animated": "Middle Finger.png"
      }
    },
    "sortOrder": 185
  },
  "🪖": {
    "glyph": "🪖",
    "group": "Objects",
    "keywords": [
      "army",
      "helmet",
      "military",
      "soldier",
      "warrior"
    ],
    "dirName": "Military helmet",
    "styles": {
      "3D": "military_helmet_3d.png",
      "Animated": "Military Helmet.png"
    },
    "sortOrder": 1149
  },
  "🎖️": {
    "glyph": "🎖️",
    "group": "Activities",
    "keywords": [
      "celebration",
      "medal",
      "military"
    ],
    "dirName": "Military medal",
    "styles": {
      "3D": "military_medal_3d.png",
      "Animated": "Military Medal.png"
    },
    "sortOrder": 1045
  },
  "🌌": {
    "glyph": "🌌",
    "group": "Travel & Places",
    "keywords": [
      "milky way",
      "space"
    ],
    "dirName": "Milky way",
    "styles": {
      "3D": "milky_way_3d.png",
      "Animated": "Milky Way.png"
    },
    "sortOrder": 997
  },
  "🚐": {
    "glyph": "🚐",
    "group": "Travel & Places",
    "keywords": [
      "bus",
      "minibus"
    ],
    "dirName": "Minibus",
    "styles": {
      "3D": "minibus_3d.png",
      "Animated": "Minibus.png"
    },
    "sortOrder": 887
  },
  "➖": {
    "glyph": "➖",
    "group": "Symbols",
    "keywords": [
      "-",
      "−",
      "math",
      "minus",
      "sign"
    ],
    "dirName": "Minus",
    "styles": {
      "3D": "minus_3d.png",
      "Animated": "Minus.png"
    },
    "sortOrder": 1466
  },
  "🪞": {
    "glyph": "🪞",
    "group": "Objects",
    "keywords": [
      "mirror",
      "reflection",
      "reflector",
      "speculum"
    ],
    "dirName": "Mirror",
    "styles": {
      "3D": "mirror_3d.png",
      "Animated": "Mirror.png"
    },
    "sortOrder": 1335
  },
  "🪩": {
    "glyph": "🪩",
    "group": "Activities",
    "keywords": [
      "dance",
      "disco",
      "glitter",
      "mirror ball",
      "party"
    ],
    "dirName": "Mirror ball",
    "styles": {
      "3D": "mirror_ball_3d.png",
      "Animated": "Mirror Ball.png"
    },
    "sortOrder": 1093
  },
  "🗿": {
    "glyph": "🗿",
    "group": "Objects",
    "keywords": [
      "face",
      "moai",
      "moyai",
      "statue"
    ],
    "dirName": "Moai",
    "styles": {
      "3D": "moai_3d.png",
      "Animated": "Moai.png"
    },
    "sortOrder": 1362
  },
  "📱": {
    "glyph": "📱",
    "group": "Objects",
    "keywords": [
      "cell",
      "mobile",
      "phone",
      "telephone"
    ],
    "dirName": "Mobile phone",
    "styles": {
      "3D": "mobile_phone_3d.png",
      "Animated": "Mobile Phone.png"
    },
    "sortOrder": 1182
  },
  "📴": {
    "glyph": "📴",
    "group": "Symbols",
    "keywords": [
      "cell",
      "mobile",
      "off",
      "phone",
      "telephone"
    ],
    "dirName": "Mobile phone off",
    "styles": {
      "3D": "mobile_phone_off_3d.png",
      "Animated": "Mobile Phone Off.png"
    },
    "sortOrder": 1460
  },
  "📲": {
    "glyph": "📲",
    "group": "Objects",
    "keywords": [
      "arrow",
      "cell",
      "mobile",
      "mobile phone with arrow",
      "phone",
      "receive"
    ],
    "dirName": "Mobile phone with arrow",
    "styles": {
      "3D": "mobile_phone_with_arrow_3d.png",
      "Animated": "Mobile Phone with Arrow.png"
    },
    "sortOrder": 1183
  },
  "💰": {
    "glyph": "💰",
    "group": "Objects",
    "keywords": [
      "bag",
      "dollar",
      "money",
      "moneybag"
    ],
    "dirName": "Money bag",
    "styles": {
      "3D": "money_bag_3d.png",
      "Animated": "Money Bag.png"
    },
    "sortOrder": 1235
  },
  "💸": {
    "glyph": "💸",
    "group": "Objects",
    "keywords": [
      "banknote",
      "bill",
      "fly",
      "money",
      "money with wings",
      "wings"
    ],
    "dirName": "Money with wings",
    "styles": {
      "3D": "money_with_wings_3d.png",
      "Animated": "Money with Wings.png"
    },
    "sortOrder": 1241
  },
  "🤑": {
    "glyph": "🤑",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "money",
      "money-mouth face",
      "mouth"
    ],
    "dirName": "Money-mouth face",
    "styles": {
      "3D": "money-mouth_face_3d.png",
      "Animated": "Money-Mouth Face.png"
    },
    "sortOrder": 29
  },
  "🐒": {
    "glyph": "🐒",
    "group": "Animals & Nature",
    "keywords": [
      "monkey"
    ],
    "dirName": "Monkey",
    "styles": {
      "3D": "monkey_3d.png",
      "Animated": "Monkey.png"
    },
    "sortOrder": 531
  },
  "🐵": {
    "glyph": "🐵",
    "group": "Animals & Nature",
    "keywords": [
      "face",
      "monkey"
    ],
    "dirName": "Monkey face",
    "styles": {
      "3D": "monkey_face_3d.png",
      "Animated": "Monkey Face.png"
    },
    "sortOrder": 530
  },
  "🚝": {
    "glyph": "🚝",
    "group": "Travel & Places",
    "keywords": [
      "monorail",
      "vehicle"
    ],
    "dirName": "Monorail",
    "styles": {
      "3D": "monorail_3d.png",
      "Animated": "Monorail.png"
    },
    "sortOrder": 881
  },
  "🥮": {
    "glyph": "🥮",
    "group": "Food & Drink",
    "keywords": [
      "autumn",
      "festival",
      "moon cake",
      "yuèbǐng"
    ],
    "dirName": "Moon cake",
    "styles": {
      "3D": "moon_cake_3d.png",
      "Animated": "Moon Cake.png"
    },
    "sortOrder": 755
  },
  "🎑": {
    "glyph": "🎑",
    "group": "Activities",
    "keywords": [
      "celebration",
      "ceremony",
      "moon",
      "moon viewing ceremony"
    ],
    "dirName": "Moon viewing ceremony",
    "styles": {
      "3D": "moon_viewing_ceremony_3d.png",
      "Animated": "Moon Viewing Ceremony.png"
    },
    "sortOrder": 1038
  },
  "🕌": {
    "glyph": "🕌",
    "group": "Travel & Places",
    "keywords": [
      "islam",
      "mosque",
      "Muslim",
      "religion"
    ],
    "dirName": "Mosque",
    "styles": {
      "3D": "mosque_3d.png",
      "Animated": "Mosque.png"
    },
    "sortOrder": 850
  },
  "🦟": {
    "glyph": "🦟",
    "group": "Animals & Nature",
    "keywords": [
      "disease",
      "fever",
      "malaria",
      "mosquito",
      "pest",
      "virus"
    ],
    "dirName": "Mosquito",
    "styles": {
      "3D": "mosquito_3d.png",
      "Animated": "Mosquito.png"
    },
    "sortOrder": 644
  },
  "🛥️": {
    "glyph": "🛥️",
    "group": "Travel & Places",
    "keywords": [
      "boat",
      "motor boat",
      "motorboat"
    ],
    "dirName": "Motor boat",
    "styles": {
      "3D": "motor_boat_3d.png",
      "Animated": "Motor Boat.png"
    },
    "sortOrder": 929
  },
  "🛵": {
    "glyph": "🛵",
    "group": "Travel & Places",
    "keywords": [
      "motor",
      "scooter"
    ],
    "dirName": "Motor scooter",
    "styles": {
      "3D": "motor_scooter_3d.png",
      "Animated": "Motor Scooter.png"
    },
    "sortOrder": 903
  },
  "🏍️": {
    "glyph": "🏍️",
    "group": "Travel & Places",
    "keywords": [
      "motorcycle",
      "racing"
    ],
    "dirName": "Motorcycle",
    "styles": {
      "3D": "motorcycle_3d.png",
      "Animated": "Motorcycle.png"
    },
    "sortOrder": 902
  },
  "🦼": {
    "glyph": "🦼",
    "group": "Travel & Places",
    "keywords": [
      "accessibility",
      "motorized wheelchair"
    ],
    "dirName": "Motorized wheelchair",
    "styles": {
      "3D": "motorized_wheelchair_3d.png",
      "Animated": "Motorized Wheelchair.png"
    },
    "sortOrder": 905
  },
  "🛣️": {
    "glyph": "🛣️",
    "group": "Travel & Places",
    "keywords": [
      "highway",
      "motorway",
      "road"
    ],
    "dirName": "Motorway",
    "styles": {
      "3D": "motorway_3d.png",
      "Animated": "Motorway.png"
    },
    "sortOrder": 912
  },
  "🗻": {
    "glyph": "🗻",
    "group": "Travel & Places",
    "keywords": [
      "fuji",
      "mount fuji",
      "mountain"
    ],
    "dirName": "Mount fuji",
    "styles": {
      "3D": "mount_fuji_3d.png",
      "Animated": "Mount Fuji.png"
    },
    "sortOrder": 816
  },
  "⛰️": {
    "glyph": "⛰️",
    "group": "Travel & Places",
    "keywords": [
      "mountain"
    ],
    "dirName": "Mountain",
    "styles": {
      "3D": "mountain_3d.png",
      "Animated": "Mountain.png"
    },
    "sortOrder": 814
  },
  "🚠": {
    "glyph": "🚠",
    "group": "Travel & Places",
    "keywords": [
      "cable",
      "gondola",
      "mountain",
      "mountain cableway"
    ],
    "dirName": "Mountain cableway",
    "styles": {
      "3D": "mountain_cableway_3d.png",
      "Animated": "Mountain Cableway.png"
    },
    "sortOrder": 939
  },
  "🚞": {
    "glyph": "🚞",
    "group": "Travel & Places",
    "keywords": [
      "car",
      "mountain",
      "railway"
    ],
    "dirName": "Mountain railway",
    "styles": {
      "3D": "mountain_railway_3d.png",
      "Animated": "Mountain Railway.png"
    },
    "sortOrder": 882
  },
  "🐁": {
    "glyph": "🐁",
    "group": "Animals & Nature",
    "keywords": [
      "mouse"
    ],
    "dirName": "Mouse",
    "styles": {
      "3D": "mouse_3d.png",
      "Animated": "Mouse.png"
    },
    "sortOrder": 575
  },
  "🐭": {
    "glyph": "🐭",
    "group": "Animals & Nature",
    "keywords": [
      "face",
      "mouse"
    ],
    "dirName": "Mouse face",
    "styles": {
      "3D": "mouse_face_3d.png",
      "Animated": "Mouse Face.png"
    },
    "sortOrder": 574
  },
  "🪤": {
    "glyph": "🪤",
    "group": "Objects",
    "keywords": [
      "bait",
      "mouse trap",
      "mousetrap",
      "snare",
      "trap"
    ],
    "dirName": "Mouse trap",
    "styles": {
      "3D": "mouse_trap_3d.png",
      "Animated": "Mouse Trap.png"
    },
    "sortOrder": 1344
  },
  "👄": {
    "glyph": "👄",
    "group": "People & Body",
    "keywords": [
      "lips",
      "mouth"
    ],
    "dirName": "Mouth",
    "styles": {
      "3D": "mouth_3d.png",
      "Animated": "Mouth.png"
    },
    "sortOrder": 221
  },
  "🎥": {
    "glyph": "🎥",
    "group": "Objects",
    "keywords": [
      "camera",
      "cinema",
      "movie"
    ],
    "dirName": "Movie camera",
    "styles": {
      "3D": "movie_camera_3d.png",
      "Animated": "Movie Camera.png"
    },
    "sortOrder": 1202
  },
  "🤶": {
    "glyph": "🤶",
    "group": "People & Body",
    "keywords": [
      "celebration",
      "Christmas",
      "claus",
      "mother",
      "Mrs.",
      "Mrs. Claus"
    ],
    "dirName": "Mrs claus",
    "skintones": {
      "Default": {
        "3D": "mrs_claus_3d_default.png"
      }
    },
    "sortOrder": 365
  },
  "✖️": {
    "glyph": "✖️",
    "group": "Symbols",
    "keywords": [
      "×",
      "cancel",
      "multiplication",
      "multiply",
      "sign",
      "x"
    ],
    "dirName": "Multiply",
    "styles": {
      "3D": "multiply_3d.png",
      "Animated": "Multiply.png"
    },
    "sortOrder": 1464
  },
  "🍄": {
    "glyph": "🍄",
    "group": "Food & Drink",
    "keywords": [
      "mushroom",
      "toadstool"
    ],
    "dirName": "Mushroom",
    "styles": {
      "3D": "mushroom_3d.png",
      "Animated": "Mushroom.png"
    },
    "sortOrder": 705
  },
  "🎹": {
    "glyph": "🎹",
    "group": "Objects",
    "keywords": [
      "instrument",
      "keyboard",
      "music",
      "musical keyboard",
      "piano"
    ],
    "dirName": "Musical keyboard",
    "styles": {
      "3D": "musical_keyboard_3d.png",
      "Animated": "Musical Keyboard.png"
    },
    "sortOrder": 1176
  },
  "🎵": {
    "glyph": "🎵",
    "group": "Objects",
    "keywords": [
      "music",
      "musical note",
      "note"
    ],
    "dirName": "Musical note",
    "styles": {
      "3D": "musical_note_3d.png",
      "Animated": "Musical Note.png"
    },
    "sortOrder": 1165
  },
  "🎶": {
    "glyph": "🎶",
    "group": "Objects",
    "keywords": [
      "music",
      "musical notes",
      "note",
      "notes"
    ],
    "dirName": "Musical notes",
    "styles": {
      "3D": "musical_notes_3d.png",
      "Animated": "Musical Notes.png"
    },
    "sortOrder": 1166
  },
  "🎼": {
    "glyph": "🎼",
    "group": "Objects",
    "keywords": [
      "music",
      "musical score",
      "score"
    ],
    "dirName": "Musical score",
    "styles": {
      "3D": "musical_score_3d.png",
      "Animated": "Musical Score.png"
    },
    "sortOrder": 1164
  },
  "🔇": {
    "glyph": "🔇",
    "group": "Objects",
    "keywords": [
      "mute",
      "muted speaker",
      "quiet",
      "silent",
      "speaker"
    ],
    "dirName": "Muted speaker",
    "styles": {
      "3D": "muted_speaker_3d.png",
      "Animated": "Muted Speaker.png"
    },
    "sortOrder": 1155
  },
  "🧑‍🎄": {
    "glyph": "🧑‍🎄",
    "group": "People & Body",
    "keywords": [
      "Claus, christmas",
      "mx claus"
    ],
    "dirName": "Mx claus",
    "skintones": {
      "Default": {
        "3D": "mx_claus_3d_default.png",
        "Animated": "Mx Claus.png"
      }
    },
    "sortOrder": 366
  },
  "💅": {
    "glyph": "💅",
    "group": "People & Body",
    "keywords": [
      "care",
      "cosmetics",
      "manicure",
      "nail",
      "polish"
    ],
    "dirName": "Nail polish",
    "skintones": {
      "Default": {
        "3D": "nail_polish_3d_default.png",
        "Animated": "Nail Polish.png"
      }
    },
    "sortOrder": 203
  },
  "📛": {
    "glyph": "📛",
    "group": "Symbols",
    "keywords": [
      "badge",
      "name"
    ],
    "dirName": "Name badge",
    "styles": {
      "3D": "name_badge_3d.png",
      "Animated": "Name Badge.png"
    },
    "sortOrder": 1483
  },
  "🏞️": {
    "glyph": "🏞️",
    "group": "Travel & Places",
    "keywords": [
      "national park",
      "park"
    ],
    "dirName": "National park",
    "styles": {
      "3D": "national_park_3d.png",
      "Animated": "National Park.png"
    },
    "sortOrder": 821
  },
  "🤢": {
    "glyph": "🤢",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "nauseated",
      "vomit"
    ],
    "dirName": "Nauseated face",
    "styles": {
      "3D": "nauseated_face_3d.png",
      "Animated": "Nauseated Face.png"
    },
    "sortOrder": 58
  },
  "🧿": {
    "glyph": "🧿",
    "group": "Activities",
    "keywords": [
      "bead",
      "charm",
      "evil-eye",
      "nazar",
      "nazar amulet",
      "talisman"
    ],
    "dirName": "Nazar amulet",
    "styles": {
      "3D": "nazar_amulet_3d.png",
      "Animated": "Nazar Amulet.png"
    },
    "sortOrder": 1084
  },
  "👔": {
    "glyph": "👔",
    "group": "Objects",
    "keywords": [
      "clothing",
      "necktie",
      "tie"
    ],
    "dirName": "Necktie",
    "styles": {
      "3D": "necktie_3d.png",
      "Animated": "Necktie.png"
    },
    "sortOrder": 1115
  },
  "🤓": {
    "glyph": "🤓",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "geek",
      "nerd"
    ],
    "dirName": "Nerd face",
    "styles": {
      "3D": "nerd_face_3d.png",
      "Animated": "Nerd Face.png"
    },
    "sortOrder": 71
  },
  "🪺": {
    "glyph": "🪺",
    "group": "Animals & Nature",
    "keywords": [
      "nest with eggs",
      "nesting"
    ],
    "dirName": "Nest with eggs",
    "styles": {
      "3D": "nest_with_eggs_3d.png",
      "Animated": "Nest with Eggs.png"
    },
    "sortOrder": 673
  },
  "🪆": {
    "glyph": "🪆",
    "group": "Activities",
    "keywords": [
      "doll",
      "nesting",
      "nesting dolls",
      "russia"
    ],
    "dirName": "Nesting dolls",
    "styles": {
      "3D": "nesting_dolls_3d.png",
      "Animated": "Nesting Dolls.png"
    },
    "sortOrder": 1094
  },
  "😐": {
    "glyph": "😐",
    "group": "Smileys & Emotion",
    "keywords": [
      "deadpan",
      "face",
      "meh",
      "neutral"
    ],
    "dirName": "Neutral face",
    "styles": {
      "3D": "neutral_face_3d.png",
      "Animated": "Neutral Face.png"
    },
    "sortOrder": 39
  },
  "🆕": {
    "glyph": "🆕",
    "group": "Symbols",
    "keywords": [
      "new",
      "NEW button"
    ],
    "dirName": "New button",
    "styles": {
      "3D": "new_button_3d.png",
      "Animated": "New Button.png"
    },
    "sortOrder": 1527
  },
  "🌑": {
    "glyph": "🌑",
    "group": "Travel & Places",
    "keywords": [
      "dark",
      "moon",
      "new moon"
    ],
    "dirName": "New moon",
    "styles": {
      "3D": "new_moon_3d.png",
      "Animated": "New Moon.png"
    },
    "sortOrder": 977
  },
  "🌚": {
    "glyph": "🌚",
    "group": "Travel & Places",
    "keywords": [
      "face",
      "moon",
      "new moon face"
    ],
    "dirName": "New moon face",
    "styles": {
      "3D": "new_moon_face_3d.png",
      "Animated": "New Moon Face.png"
    },
    "sortOrder": 986
  },
  "📰": {
    "glyph": "📰",
    "group": "Objects",
    "keywords": [
      "news",
      "newspaper",
      "paper"
    ],
    "dirName": "Newspaper",
    "styles": {
      "3D": "newspaper_3d.png",
      "Animated": "Newspaper.png"
    },
    "sortOrder": 1230
  },
  "⏭️": {
    "glyph": "⏭️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "next scene",
      "next track",
      "next track button",
      "triangle"
    ],
    "dirName": "Next track button",
    "styles": {
      "3D": "next_track_button_3d.png",
      "Animated": "Next Track Button.png"
    },
    "sortOrder": 1442
  },
  "🆖": {
    "glyph": "🆖",
    "group": "Symbols",
    "keywords": [
      "ng",
      "NG button"
    ],
    "dirName": "Ng button",
    "styles": {
      "3D": "ng_button_3d.png",
      "Animated": "NG Button.png"
    },
    "sortOrder": 1528
  },
  "🌃": {
    "glyph": "🌃",
    "group": "Travel & Places",
    "keywords": [
      "night",
      "night with stars",
      "star"
    ],
    "dirName": "Night with stars",
    "styles": {
      "3D": "night_with_stars_3d.png",
      "Animated": "Night with Stars.png"
    },
    "sortOrder": 858
  },
  "🕘": {
    "glyph": "🕘",
    "group": "Travel & Places",
    "keywords": [
      "00",
      "9",
      "9:00",
      "clock",
      "nine",
      "o’clock"
    ],
    "dirName": "Nine oclock",
    "styles": {
      "3D": "nine_oclock_3d.png",
      "Animated": "Nine O’Clock.png"
    },
    "sortOrder": 971
  },
  "🕤": {
    "glyph": "🕤",
    "group": "Travel & Places",
    "keywords": [
      "9",
      "9:30",
      "clock",
      "nine",
      "nine-thirty",
      "thirty"
    ],
    "dirName": "Nine-thirty",
    "styles": {
      "3D": "nine-thirty_3d.png",
      "Animated": "Nine-Thirty.png"
    },
    "sortOrder": 972
  },
  "🥷": {
    "glyph": "🥷",
    "group": "People & Body",
    "keywords": [
      "fighter",
      "hidden",
      "ninja",
      "stealth"
    ],
    "dirName": "Ninja",
    "skintones": {
      "Default": {
        "3D": "ninja_3d_default.png",
        "Animated": "Ninja.png"
      }
    },
    "sortOrder": 338
  },
  "🚳": {
    "glyph": "🚳",
    "group": "Symbols",
    "keywords": [
      "bicycle",
      "bike",
      "forbidden",
      "no",
      "no bicycles",
      "prohibited"
    ],
    "dirName": "No bicycles",
    "styles": {
      "3D": "no_bicycles_3d.png",
      "Animated": "No Bicycles.png"
    },
    "sortOrder": 1382
  },
  "⛔": {
    "glyph": "⛔",
    "group": "Symbols",
    "keywords": [
      "entry",
      "forbidden",
      "no",
      "not",
      "prohibited",
      "traffic"
    ],
    "dirName": "No entry",
    "styles": {
      "3D": "no_entry_3d.png",
      "Animated": "No Entry.png"
    },
    "sortOrder": 1380
  },
  "🚯": {
    "glyph": "🚯",
    "group": "Symbols",
    "keywords": [
      "forbidden",
      "litter",
      "no",
      "no littering",
      "not",
      "prohibited"
    ],
    "dirName": "No littering",
    "styles": {
      "3D": "no_littering_3d.png",
      "Animated": "No Littering.png"
    },
    "sortOrder": 1384
  },
  "📵": {
    "glyph": "📵",
    "group": "Symbols",
    "keywords": [
      "cell",
      "forbidden",
      "mobile",
      "no",
      "no mobile phones",
      "phone"
    ],
    "dirName": "No mobile phones",
    "styles": {
      "3D": "no_mobile_phones_3d.png",
      "Animated": "No Mobile Phones.png"
    },
    "sortOrder": 1387
  },
  "🔞": {
    "glyph": "🔞",
    "group": "Symbols",
    "keywords": [
      "18",
      "age restriction",
      "eighteen",
      "no one under eighteen",
      "prohibited",
      "underage"
    ],
    "dirName": "No one under eighteen",
    "styles": {
      "3D": "no_one_under_eighteen_3d.png",
      "Animated": "No One Under Eighteen.png"
    },
    "sortOrder": 1388
  },
  "🚷": {
    "glyph": "🚷",
    "group": "Symbols",
    "keywords": [
      "forbidden",
      "no",
      "no pedestrians",
      "not",
      "pedestrian",
      "prohibited"
    ],
    "dirName": "No pedestrians",
    "styles": {
      "3D": "no_pedestrians_3d.png",
      "Animated": "No Pedestrians.png"
    },
    "sortOrder": 1386
  },
  "🚭": {
    "glyph": "🚭",
    "group": "Symbols",
    "keywords": [
      "forbidden",
      "no",
      "not",
      "prohibited",
      "smoking"
    ],
    "dirName": "No smoking",
    "styles": {
      "3D": "no_smoking_3d.png",
      "Animated": "No Smoking.png"
    },
    "sortOrder": 1383
  },
  "🚱": {
    "glyph": "🚱",
    "group": "Symbols",
    "keywords": [
      "non-drinking",
      "non-potable",
      "water"
    ],
    "dirName": "Non-potable water",
    "styles": {
      "3D": "non-potable_water_3d.png",
      "Animated": "Non-Potable Water.png"
    },
    "sortOrder": 1385
  },
  "👃": {
    "glyph": "👃",
    "group": "People & Body",
    "keywords": [
      "body",
      "nose"
    ],
    "dirName": "Nose",
    "skintones": {
      "Default": {
        "3D": "nose_3d_default.png",
        "Animated": "Nose.png"
      }
    },
    "sortOrder": 212
  },
  "📓": {
    "glyph": "📓",
    "group": "Objects",
    "keywords": [
      "notebook"
    ],
    "dirName": "Notebook",
    "styles": {
      "3D": "notebook_3d.png",
      "Animated": "Notebook.png"
    },
    "sortOrder": 1225
  },
  "📔": {
    "glyph": "📔",
    "group": "Objects",
    "keywords": [
      "book",
      "cover",
      "decorated",
      "notebook",
      "notebook with decorative cover"
    ],
    "dirName": "Notebook with decorative cover",
    "styles": {
      "3D": "notebook_with_decorative_cover_3d.png",
      "Animated": "Notebook with Decorative Cover.png"
    },
    "sortOrder": 1218
  },
  "🔩": {
    "glyph": "🔩",
    "group": "Objects",
    "keywords": [
      "bolt",
      "nut",
      "nut and bolt",
      "tool"
    ],
    "dirName": "Nut and bolt",
    "styles": {
      "3D": "nut_and_bolt_3d.png",
      "Animated": "Nut and Bolt.png"
    },
    "sortOrder": 1308
  },
  "🅾️": {
    "glyph": "🅾️",
    "group": "Symbols",
    "keywords": [
      "blood type",
      "o",
      "O button (blood type)"
    ],
    "dirName": "O button blood type",
    "styles": {
      "3D": "o_button_(blood_type)_3d.png",
      "Animated": "O Button (Blood Type).png"
    },
    "sortOrder": 1529
  },
  "🐙": {
    "glyph": "🐙",
    "group": "Animals & Nature",
    "keywords": [
      "octopus"
    ],
    "dirName": "Octopus",
    "styles": {
      "3D": "octopus_3d.png",
      "Animated": "Octopus.png"
    },
    "sortOrder": 629
  },
  "🍢": {
    "glyph": "🍢",
    "group": "Food & Drink",
    "keywords": [
      "kebab",
      "oden",
      "seafood",
      "skewer",
      "stick"
    ],
    "dirName": "Oden",
    "styles": {
      "3D": "oden_3d.png",
      "Animated": "Oden.png"
    },
    "sortOrder": 751
  },
  "🏢": {
    "glyph": "🏢",
    "group": "Travel & Places",
    "keywords": [
      "building",
      "office building"
    ],
    "dirName": "Office building",
    "styles": {
      "3D": "office_building_3d.png",
      "Animated": "Office Building.png"
    },
    "sortOrder": 833
  },
  "🧑‍💼": {
    "glyph": "🧑‍💼",
    "group": "People & Body",
    "keywords": [
      "architect",
      "business",
      "manager",
      "office worker",
      "white-collar"
    ],
    "dirName": "Office worker",
    "skintones": {
      "Default": {
        "3D": "office_worker_3d_default.png",
        "Animated": "Office Worker.png"
      }
    },
    "sortOrder": 305
  },
  "👹": {
    "glyph": "👹",
    "group": "Smileys & Emotion",
    "keywords": [
      "creature",
      "face",
      "fairy tale",
      "fantasy",
      "monster",
      "ogre"
    ],
    "dirName": "Ogre",
    "styles": {
      "3D": "ogre_3d.png",
      "Animated": "Ogre.png"
    },
    "sortOrder": 109
  },
  "🛢️": {
    "glyph": "🛢️",
    "group": "Travel & Places",
    "keywords": [
      "drum",
      "oil"
    ],
    "dirName": "Oil drum",
    "styles": {
      "3D": "oil_drum_3d.png",
      "Animated": "Oil Drum.png"
    },
    "sortOrder": 914
  },
  "🆗": {
    "glyph": "🆗",
    "group": "Symbols",
    "keywords": [
      "OK",
      "OK button"
    ],
    "dirName": "Ok button",
    "styles": {
      "3D": "ok_button_3d.png",
      "Animated": "OK Button.png"
    },
    "sortOrder": 1530
  },
  "👌": {
    "glyph": "👌",
    "group": "People & Body",
    "keywords": [
      "hand",
      "OK"
    ],
    "dirName": "Ok hand",
    "skintones": {
      "Default": {
        "3D": "ok_hand_3d_default.png",
        "Animated": "OK Hand.png"
      }
    },
    "sortOrder": 173
  },
  "🗝️": {
    "glyph": "🗝️",
    "group": "Objects",
    "keywords": [
      "clue",
      "key",
      "lock",
      "old"
    ],
    "dirName": "Old key",
    "styles": {
      "3D": "old_key_3d.png",
      "Animated": "Old Key.png"
    },
    "sortOrder": 1293
  },
  "👴": {
    "glyph": "👴",
    "group": "People & Body",
    "keywords": [
      "adult",
      "man",
      "old"
    ],
    "dirName": "Old man",
    "skintones": {
      "Default": {
        "3D": "old_man_3d_default.png",
        "Animated": "Old Man.png"
      }
    },
    "sortOrder": 249
  },
  "👵": {
    "glyph": "👵",
    "group": "People & Body",
    "keywords": [
      "adult",
      "old",
      "woman"
    ],
    "dirName": "Old woman",
    "skintones": {
      "Default": {
        "3D": "old_woman_3d_default.png",
        "Animated": "Old Woman.png"
      }
    },
    "sortOrder": 250
  },
  "🧓": {
    "glyph": "🧓",
    "group": "People & Body",
    "keywords": [
      "adult",
      "gender-neutral",
      "old",
      "older person",
      "unspecified gender"
    ],
    "dirName": "Older person",
    "skintones": {
      "Default": {
        "3D": "older_person_3d_default.png",
        "Animated": "Older Person.png"
      }
    },
    "sortOrder": 248
  },
  "🫒": {
    "glyph": "🫒",
    "group": "Food & Drink",
    "keywords": [
      "food",
      "olive"
    ],
    "dirName": "Olive",
    "styles": {
      "3D": "olive_3d.png",
      "Animated": "Olive.png"
    },
    "sortOrder": 691
  },
  "🕉️": {
    "glyph": "🕉️",
    "group": "Symbols",
    "keywords": [
      "Hindu",
      "om",
      "religion"
    ],
    "dirName": "Om",
    "styles": {
      "3D": "om_3d.png",
      "Animated": "Om.png"
    },
    "sortOrder": 1414
  },
  "🔛": {
    "glyph": "🔛",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "mark",
      "on",
      "ON! arrow"
    ],
    "dirName": "On! arrow",
    "styles": {
      "3D": "on!_arrow_3d.png",
      "Animated": "On! Arrow.png"
    },
    "sortOrder": 1409
  },
  "🚘": {
    "glyph": "🚘",
    "group": "Travel & Places",
    "keywords": [
      "automobile",
      "car",
      "oncoming"
    ],
    "dirName": "Oncoming automobile",
    "styles": {
      "3D": "oncoming_automobile_3d.png",
      "Animated": "Oncoming Automobile.png"
    },
    "sortOrder": 895
  },
  "🚍": {
    "glyph": "🚍",
    "group": "Travel & Places",
    "keywords": [
      "bus",
      "oncoming"
    ],
    "dirName": "Oncoming bus",
    "styles": {
      "3D": "oncoming_bus_3d.png",
      "Animated": "Oncoming Bus.png"
    },
    "sortOrder": 885
  },
  "👊": {
    "glyph": "👊",
    "group": "People & Body",
    "keywords": [
      "clenched",
      "fist",
      "hand",
      "oncoming fist",
      "punch"
    ],
    "dirName": "Oncoming fist",
    "skintones": {
      "Default": {
        "3D": "oncoming_fist_3d_default.png",
        "Animated": "Oncoming Fist.png"
      }
    },
    "sortOrder": 192
  },
  "🚔": {
    "glyph": "🚔",
    "group": "Travel & Places",
    "keywords": [
      "car",
      "oncoming",
      "police"
    ],
    "dirName": "Oncoming police car",
    "styles": {
      "3D": "oncoming_police_car_3d.png",
      "Animated": "Oncoming Police Car.png"
    },
    "sortOrder": 891
  },
  "🚖": {
    "glyph": "🚖",
    "group": "Travel & Places",
    "keywords": [
      "oncoming",
      "taxi"
    ],
    "dirName": "Oncoming taxi",
    "styles": {
      "3D": "oncoming_taxi_3d.png",
      "Animated": "Oncoming Taxi.png"
    },
    "sortOrder": 893
  },
  "🕐": {
    "glyph": "🕐",
    "group": "Travel & Places",
    "keywords": [
      "00",
      "1",
      "1:00",
      "clock",
      "o’clock",
      "one"
    ],
    "dirName": "One oclock",
    "styles": {
      "3D": "one_oclock_3d.png",
      "Animated": "One O’Clock.png"
    },
    "sortOrder": 955
  },
  "🩱": {
    "glyph": "🩱",
    "group": "Objects",
    "keywords": [
      "bathing suit",
      "one-piece swimsuit"
    ],
    "dirName": "One-piece swimsuit",
    "styles": {
      "3D": "one-piece_swimsuit_3d.png",
      "Animated": "One-Piece Swimsuit.png"
    },
    "sortOrder": 1125
  },
  "🕜": {
    "glyph": "🕜",
    "group": "Travel & Places",
    "keywords": [
      "1",
      "1:30",
      "clock",
      "one",
      "one-thirty",
      "thirty"
    ],
    "dirName": "One-thirty",
    "styles": {
      "3D": "one-thirty_3d.png",
      "Animated": "One-Thirty.png"
    },
    "sortOrder": 956
  },
  "🧅": {
    "glyph": "🧅",
    "group": "Food & Drink",
    "keywords": [
      "flavoring",
      "onion"
    ],
    "dirName": "Onion",
    "styles": {
      "3D": "onion_3d.png",
      "Animated": "Onion.png"
    },
    "sortOrder": 704
  },
  "📖": {
    "glyph": "📖",
    "group": "Objects",
    "keywords": [
      "book",
      "open"
    ],
    "dirName": "Open book",
    "styles": {
      "3D": "open_book_3d.png",
      "Animated": "Open Book.png"
    },
    "sortOrder": 1220
  },
  "📂": {
    "glyph": "📂",
    "group": "Objects",
    "keywords": [
      "file",
      "folder",
      "open"
    ],
    "dirName": "Open file folder",
    "styles": {
      "3D": "open_file_folder_3d.png",
      "Animated": "Open File Folder.png"
    },
    "sortOrder": 1267
  },
  "👐": {
    "glyph": "👐",
    "group": "People & Body",
    "keywords": [
      "hand",
      "open",
      "open hands"
    ],
    "dirName": "Open hands",
    "skintones": {
      "Default": {
        "3D": "open_hands_3d_default.png",
        "Animated": "Open Hands.png"
      }
    },
    "sortOrder": 198
  },
  "📭": {
    "glyph": "📭",
    "group": "Objects",
    "keywords": [
      "lowered",
      "mail",
      "mailbox",
      "open",
      "open mailbox with lowered flag",
      "postbox"
    ],
    "dirName": "Open mailbox with lowered flag",
    "styles": {
      "3D": "open_mailbox_with_lowered_flag_3d.png",
      "Animated": "Open Mailbox with Lowered Flag.png"
    },
    "sortOrder": 1255
  },
  "📬": {
    "glyph": "📬",
    "group": "Objects",
    "keywords": [
      "mail",
      "mailbox",
      "open",
      "open mailbox with raised flag",
      "postbox"
    ],
    "dirName": "Open mailbox with raised flag",
    "styles": {
      "3D": "open_mailbox_with_raised_flag_3d.png",
      "Animated": "Open Mailbox with Raised Flag.png"
    },
    "sortOrder": 1254
  },
  "⛎": {
    "glyph": "⛎",
    "group": "Symbols",
    "keywords": [
      "bearer",
      "Ophiuchus",
      "serpent",
      "snake",
      "zodiac"
    ],
    "dirName": "Ophiuchus",
    "styles": {
      "3D": "ophiuchus_3d.png",
      "Animated": "Ophiuchus.png"
    },
    "sortOrder": 1436
  },
  "💿": {
    "glyph": "💿",
    "group": "Objects",
    "keywords": [
      "cd",
      "computer",
      "disk",
      "optical"
    ],
    "dirName": "Optical disk",
    "styles": {
      "3D": "optical_disk_3d.png",
      "Animated": "Optical Disk.png"
    },
    "sortOrder": 1199
  },
  "📙": {
    "glyph": "📙",
    "group": "Objects",
    "keywords": [
      "book",
      "orange"
    ],
    "dirName": "Orange book",
    "styles": {
      "3D": "orange_book_3d.png",
      "Animated": "Orange Book.png"
    },
    "sortOrder": 1223
  },
  "🟠": {
    "glyph": "🟠",
    "group": "Symbols",
    "keywords": [
      "circle",
      "orange"
    ],
    "dirName": "Orange circle",
    "styles": {
      "3D": "orange_circle_3d.png",
      "Animated": "Orange Circle.png"
    },
    "sortOrder": 1553
  },
  "🧡": {
    "glyph": "🧡",
    "group": "Smileys & Emotion",
    "keywords": [
      "orange",
      "orange heart"
    ],
    "dirName": "Orange heart",
    "styles": {
      "3D": "orange_heart_3d.png",
      "Animated": "Orange Heart.png"
    },
    "sortOrder": 142
  },
  "🟧": {
    "glyph": "🟧",
    "group": "Symbols",
    "keywords": [
      "orange",
      "square"
    ],
    "dirName": "Orange square",
    "styles": {
      "3D": "orange_square_3d.png",
      "Animated": "Orange Square.png"
    },
    "sortOrder": 1562
  },
  "🦧": {
    "glyph": "🦧",
    "group": "Animals & Nature",
    "keywords": [
      "ape",
      "orangutan"
    ],
    "dirName": "Orangutan",
    "styles": {
      "3D": "orangutan_3d.png",
      "Animated": "Orangutan.png"
    },
    "sortOrder": 533
  },
  "☦️": {
    "glyph": "☦️",
    "group": "Symbols",
    "keywords": [
      "Christian",
      "cross",
      "orthodox cross",
      "religion"
    ],
    "dirName": "Orthodox cross",
    "styles": {
      "3D": "orthodox_cross_3d.png",
      "Animated": "Orthodox Cross.png"
    },
    "sortOrder": 1419
  },
  "🦦": {
    "glyph": "🦦",
    "group": "Animals & Nature",
    "keywords": [
      "fishing",
      "otter",
      "playful"
    ],
    "dirName": "Otter",
    "styles": {
      "3D": "otter_3d.png",
      "Animated": "Otter.png"
    },
    "sortOrder": 589
  },
  "📤": {
    "glyph": "📤",
    "group": "Objects",
    "keywords": [
      "box",
      "letter",
      "mail",
      "outbox",
      "sent",
      "tray"
    ],
    "dirName": "Outbox tray",
    "styles": {
      "3D": "outbox_tray_3d.png",
      "Animated": "Outbox Tray.png"
    },
    "sortOrder": 1249
  },
  "🦉": {
    "glyph": "🦉",
    "group": "Animals & Nature",
    "keywords": [
      "bird",
      "owl",
      "wise"
    ],
    "dirName": "Owl",
    "styles": {
      "3D": "owl_3d.png",
      "Animated": "Owl.png"
    },
    "sortOrder": 606
  },
  "🐂": {
    "glyph": "🐂",
    "group": "Animals & Nature",
    "keywords": [
      "bull",
      "ox",
      "Taurus",
      "zodiac"
    ],
    "dirName": "Ox",
    "styles": {
      "3D": "ox_3d.png",
      "Animated": "Ox.png"
    },
    "sortOrder": 556
  },
  "🦪": {
    "glyph": "🦪",
    "group": "Food & Drink",
    "keywords": [
      "diving",
      "oyster",
      "pearl"
    ],
    "dirName": "Oyster",
    "styles": {
      "3D": "oyster_3d.png",
      "Animated": "Oyster.png"
    },
    "sortOrder": 764
  },
  "🅿️": {
    "glyph": "🅿️",
    "group": "Symbols",
    "keywords": [
      "P button",
      "parking"
    ],
    "dirName": "P button",
    "styles": {
      "3D": "p_button_3d.png",
      "Animated": "P Button.png"
    },
    "sortOrder": 1531
  },
  "📦": {
    "glyph": "📦",
    "group": "Objects",
    "keywords": [
      "box",
      "package",
      "parcel"
    ],
    "dirName": "Package",
    "styles": {
      "3D": "package_3d.png",
      "Animated": "Package.png"
    },
    "sortOrder": 1251
  },
  "📄": {
    "glyph": "📄",
    "group": "Objects",
    "keywords": [
      "document",
      "page",
      "page facing up"
    ],
    "dirName": "Page facing up",
    "styles": {
      "3D": "page_facing_up_3d.png",
      "Animated": "Page Facing Up.png"
    },
    "sortOrder": 1229
  },
  "📃": {
    "glyph": "📃",
    "group": "Objects",
    "keywords": [
      "curl",
      "document",
      "page",
      "page with curl"
    ],
    "dirName": "Page with curl",
    "styles": {
      "3D": "page_with_curl_3d.png",
      "Animated": "Page with Curl.png"
    },
    "sortOrder": 1227
  },
  "📟": {
    "glyph": "📟",
    "group": "Objects",
    "keywords": [
      "pager"
    ],
    "dirName": "Pager",
    "styles": {
      "3D": "pager_3d.png",
      "Animated": "Pager.png"
    },
    "sortOrder": 1186
  },
  "🖌️": {
    "glyph": "🖌️",
    "group": "Objects",
    "keywords": [
      "paintbrush",
      "painting"
    ],
    "dirName": "Paintbrush",
    "styles": {
      "3D": "paintbrush_3d.png",
      "Animated": "Paintbrush.png"
    },
    "sortOrder": 1262
  },
  "🫳": {
    "glyph": "🫳",
    "group": "People & Body",
    "keywords": [
      "dismiss",
      "drop",
      "palm down hand",
      "shoo"
    ],
    "dirName": "Palm down hand",
    "skintones": {
      "Default": {
        "3D": "palm_down_hand_3d_default.png",
        "Animated": "Palm Down Hand.png"
      }
    },
    "sortOrder": 171
  },
  "🌴": {
    "glyph": "🌴",
    "group": "Animals & Nature",
    "keywords": [
      "palm",
      "tree"
    ],
    "dirName": "Palm tree",
    "styles": {
      "3D": "palm_tree_3d.png",
      "Animated": "Palm Tree.png"
    },
    "sortOrder": 663
  },
  "🫴": {
    "glyph": "🫴",
    "group": "People & Body",
    "keywords": [
      "beckon",
      "catch",
      "come",
      "offer",
      "palm up hand"
    ],
    "dirName": "Palm up hand",
    "skintones": {
      "Default": {
        "3D": "palm_up_hand_3d_default.png",
        "Animated": "Palm Up Hand.png"
      }
    },
    "sortOrder": 172
  },
  "🤲": {
    "glyph": "🤲",
    "group": "People & Body",
    "keywords": [
      "palms up together",
      "prayer"
    ],
    "dirName": "Palms up together",
    "skintones": {
      "Default": {
        "3D": "palms_up_together_3d_default.png",
        "Animated": "Palms Up Together.png"
      }
    },
    "sortOrder": 199
  },
  "🥞": {
    "glyph": "🥞",
    "group": "Food & Drink",
    "keywords": [
      "breakfast",
      "crêpe",
      "food",
      "hotcake",
      "pancake",
      "pancakes"
    ],
    "dirName": "Pancakes",
    "styles": {
      "3D": "pancakes_3d.png",
      "Animated": "Pancakes.png"
    },
    "sortOrder": 715
  },
  "🐼": {
    "glyph": "🐼",
    "group": "Animals & Nature",
    "keywords": [
      "face",
      "panda"
    ],
    "dirName": "Panda",
    "styles": {
      "3D": "panda_3d.png",
      "Animated": "Panda.png"
    },
    "sortOrder": 587
  },
  "🪂": {
    "glyph": "🪂",
    "group": "Travel & Places",
    "keywords": [
      "hang-glide",
      "parachute",
      "parasail",
      "skydive"
    ],
    "dirName": "Parachute",
    "styles": {
      "3D": "parachute_3d.png",
      "Animated": "Parachute.png"
    },
    "sortOrder": 935
  },
  "🦜": {
    "glyph": "🦜",
    "group": "Animals & Nature",
    "keywords": [
      "bird",
      "parrot",
      "pirate",
      "talk"
    ],
    "dirName": "Parrot",
    "styles": {
      "3D": "parrot_3d.png",
      "Animated": "Parrot.png"
    },
    "sortOrder": 611
  },
  "〽️": {
    "glyph": "〽️",
    "group": "Symbols",
    "keywords": [
      "mark",
      "part",
      "part alternation mark"
    ],
    "dirName": "Part alternation mark",
    "styles": {
      "3D": "part_alternation_mark_3d.png",
      "Animated": "Part Alternation Mark.png"
    },
    "sortOrder": 1493
  },
  "🎉": {
    "glyph": "🎉",
    "group": "Activities",
    "keywords": [
      "celebration",
      "party",
      "popper",
      "tada"
    ],
    "dirName": "Party popper",
    "styles": {
      "3D": "party_popper_3d.png",
      "Animated": "Party Popper.png"
    },
    "sortOrder": 1031
  },
  "🥳": {
    "glyph": "🥳",
    "group": "Smileys & Emotion",
    "keywords": [
      "celebration",
      "hat",
      "horn",
      "party",
      "partying face"
    ],
    "dirName": "Partying face",
    "styles": {
      "3D": "partying_face_3d.png",
      "Animated": "Partying Face.png"
    },
    "sortOrder": 68
  },
  "🛳️": {
    "glyph": "🛳️",
    "group": "Travel & Places",
    "keywords": [
      "passenger",
      "ship"
    ],
    "dirName": "Passenger ship",
    "styles": {
      "3D": "passenger_ship_3d.png",
      "Animated": "Passenger Ship.png"
    },
    "sortOrder": 927
  },
  "🛂": {
    "glyph": "🛂",
    "group": "Symbols",
    "keywords": [
      "control",
      "passport"
    ],
    "dirName": "Passport control",
    "styles": {
      "3D": "passport_control_3d.png",
      "Animated": "Passport Control.png"
    },
    "sortOrder": 1374
  },
  "⏸️": {
    "glyph": "⏸️",
    "group": "Symbols",
    "keywords": [
      "bar",
      "double",
      "pause",
      "pause button",
      "vertical"
    ],
    "dirName": "Pause button",
    "styles": {
      "3D": "pause_button_3d.png",
      "Animated": "Pause Button.png"
    },
    "sortOrder": 1451
  },
  "🐾": {
    "glyph": "🐾",
    "group": "Animals & Nature",
    "keywords": [
      "feet",
      "paw",
      "paw prints",
      "print"
    ],
    "dirName": "Paw prints",
    "styles": {
      "3D": "paw_prints_3d.png",
      "Animated": "Paw Prints.png"
    },
    "sortOrder": 593
  },
  "☮️": {
    "glyph": "☮️",
    "group": "Symbols",
    "keywords": [
      "peace",
      "peace symbol"
    ],
    "dirName": "Peace symbol",
    "styles": {
      "3D": "peace_symbol_3d.png",
      "Animated": "Peace Symbol.png"
    },
    "sortOrder": 1421
  },
  "🍑": {
    "glyph": "🍑",
    "group": "Food & Drink",
    "keywords": [
      "fruit",
      "peach"
    ],
    "dirName": "Peach",
    "styles": {
      "3D": "peach_3d.png",
      "Animated": "Peach.png"
    },
    "sortOrder": 685
  },
  "🦚": {
    "glyph": "🦚",
    "group": "Animals & Nature",
    "keywords": [
      "bird",
      "ostentatious",
      "peacock",
      "peahen",
      "proud"
    ],
    "dirName": "Peacock",
    "styles": {
      "3D": "peacock_3d.png",
      "Animated": "Peacock.png"
    },
    "sortOrder": 610
  },
  "🥜": {
    "glyph": "🥜",
    "group": "Food & Drink",
    "keywords": [
      "food",
      "nut",
      "peanut",
      "peanuts",
      "vegetable"
    ],
    "dirName": "Peanuts",
    "styles": {
      "3D": "peanuts_3d.png",
      "Animated": "Peanuts.png"
    },
    "sortOrder": 706
  },
  "🍐": {
    "glyph": "🍐",
    "group": "Food & Drink",
    "keywords": [
      "fruit",
      "pear"
    ],
    "dirName": "Pear",
    "styles": {
      "3D": "pear_3d.png",
      "Animated": "Pear.png"
    },
    "sortOrder": 684
  },
  "🖊️": {
    "glyph": "🖊️",
    "group": "Objects",
    "keywords": [
      "ballpoint",
      "pen"
    ],
    "dirName": "Pen",
    "styles": {
      "3D": "pen_3d.png",
      "Animated": "Pen.png"
    },
    "sortOrder": 1261
  },
  "✏️": {
    "glyph": "✏️",
    "group": "Objects",
    "keywords": [
      "pencil"
    ],
    "dirName": "Pencil",
    "styles": {
      "3D": "pencil_3d.png",
      "Animated": "Pencil.png"
    },
    "sortOrder": 1258
  },
  "🐧": {
    "glyph": "🐧",
    "group": "Animals & Nature",
    "keywords": [
      "bird",
      "penguin"
    ],
    "dirName": "Penguin",
    "styles": {
      "3D": "penguin_3d.png",
      "Animated": "Penguin.png"
    },
    "sortOrder": 601
  },
  "😔": {
    "glyph": "😔",
    "group": "Smileys & Emotion",
    "keywords": [
      "dejected",
      "face",
      "pensive"
    ],
    "dirName": "Pensive face",
    "styles": {
      "3D": "pensive_face_3d.png",
      "Animated": "Pensive Face.png"
    },
    "sortOrder": 51
  },
  "🫂": {
    "glyph": "🫂",
    "group": "People & Body",
    "keywords": [
      "goodbye",
      "hello",
      "hug",
      "people hugging",
      "thanks"
    ],
    "dirName": "People hugging",
    "styles": {
      "3D": "people_hugging_3d.png",
      "Animated": "People Hugging.png"
    },
    "sortOrder": 523
  },
  "🎭": {
    "glyph": "🎭",
    "group": "Activities",
    "keywords": [
      "art",
      "mask",
      "performing",
      "performing arts",
      "theater",
      "theatre"
    ],
    "dirName": "Performing arts",
    "styles": {
      "3D": "performing_arts_3d.png",
      "Animated": "Performing Arts.png"
    },
    "sortOrder": 1103
  },
  "😣": {
    "glyph": "😣",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "persevere",
      "persevering face"
    ],
    "dirName": "Persevering face",
    "styles": {
      "3D": "persevering_face_3d.png",
      "Animated": "Persevering Face.png"
    },
    "sortOrder": 93
  },
  "🧑": {
    "glyph": "🧑",
    "group": "People & Body",
    "keywords": [
      "adult",
      "gender-neutral",
      "person",
      "unspecified gender"
    ],
    "dirName": "Person",
    "skintones": {
      "Default": {
        "3D": "person_3d_default.png",
        "Animated": "Person.png"
      }
    },
    "sortOrder": 227
  },
  "🧑‍🦲": {
    "glyph": "🧑‍🦲",
    "group": "People & Body",
    "keywords": [
      "adult",
      "bald",
      "gender-neutral",
      "person",
      "unspecified gender"
    ],
    "dirName": "Person bald",
    "skintones": {
      "Default": {
        "3D": "person_bald_3d_default.png"
      }
    },
    "sortOrder": 245
  },
  "🧔": {
    "glyph": "🧔",
    "group": "People & Body",
    "keywords": [
      "beard",
      "person",
      "person: beard"
    ],
    "dirName": "Person beard",
    "skintones": {
      "Default": {
        "3D": "person_beard_3d_default.png"
      }
    },
    "sortOrder": 230
  },
  "🚴": {
    "glyph": "🚴",
    "group": "People & Body",
    "keywords": [
      "bicycle",
      "biking",
      "cyclist",
      "person biking"
    ],
    "dirName": "Person biking",
    "skintones": {
      "Default": {
        "3D": "person_biking_3d_default.png",
        "Animated": "Person Biking.png"
      }
    },
    "sortOrder": 456
  },
  "👱": {
    "glyph": "👱",
    "group": "People & Body",
    "keywords": [
      "blond",
      "blond-haired person",
      "hair",
      "person: blond hair"
    ],
    "dirName": "Person blonde hair",
    "skintones": {
      "Default": {
        "3D": "person_blonde_hair_3d_default.png"
      }
    },
    "sortOrder": 228
  },
  "⛹️": {
    "glyph": "⛹️",
    "group": "People & Body",
    "keywords": [
      "ball",
      "person bouncing ball"
    ],
    "dirName": "Person bouncing ball",
    "skintones": {
      "Default": {
        "3D": "person_bouncing_ball_3d_default.png",
        "Animated": "Person Bouncing Ball.png"
      }
    },
    "sortOrder": 450
  },
  "🙇": {
    "glyph": "🙇",
    "group": "People & Body",
    "keywords": [
      "apology",
      "bow",
      "gesture",
      "person bowing",
      "sorry"
    ],
    "dirName": "Person bowing",
    "skintones": {
      "Default": {
        "3D": "person_bowing_3d_default.png",
        "Animated": "Person Bowing.png"
      }
    },
    "sortOrder": 272
  },
  "🤸": {
    "glyph": "🤸",
    "group": "People & Body",
    "keywords": [
      "cartwheel",
      "gymnastics",
      "person cartwheeling"
    ],
    "dirName": "Person cartwheeling",
    "skintones": {
      "Default": {
        "3D": "person_cartwheeling_3d_default.png",
        "Animated": "Person Cartwheeling.png"
      }
    },
    "sortOrder": 462
  },
  "🧗": {
    "glyph": "🧗",
    "group": "People & Body",
    "keywords": [
      "climber",
      "person climbing"
    ],
    "dirName": "Person climbing",
    "skintones": {
      "Default": {
        "3D": "person_climbing_3d_default.png",
        "Animated": "Person Climbing.png"
      }
    },
    "sortOrder": 431
  },
  "🧑‍🦱": {
    "glyph": "🧑‍🦱",
    "group": "People & Body",
    "keywords": [
      "adult",
      "curly hair",
      "gender-neutral",
      "person",
      "unspecified gender"
    ],
    "dirName": "Person curly hair",
    "skintones": {
      "Default": {
        "3D": "person_curly_hair_3d_default.png"
      }
    },
    "sortOrder": 241
  },
  "🧏": {
    "glyph": "🧏",
    "group": "People & Body",
    "keywords": [
      "accessibility",
      "deaf",
      "deaf person",
      "ear",
      "hear"
    ],
    "dirName": "Person deaf",
    "skintones": {
      "Default": {
        "3D": "person_deaf_3d_default.png",
        "Animated": "Deaf Person.png"
      }
    },
    "sortOrder": 269
  },
  "🧝": {
    "glyph": "🧝",
    "group": "People & Body",
    "keywords": [
      "elf",
      "magical"
    ],
    "dirName": "Person elf",
    "skintones": {
      "Default": {
        "3D": "person_elf_3d_default.png",
        "Animated": "Elf.png"
      }
    },
    "sortOrder": 385
  },
  "🤦": {
    "glyph": "🤦",
    "group": "People & Body",
    "keywords": [
      "disbelief",
      "exasperation",
      "face",
      "palm",
      "person facepalming"
    ],
    "dirName": "Person facepalming",
    "skintones": {
      "Default": {
        "3D": "person_facepalming_3d_default.png",
        "Animated": "Person Facepalming.png"
      }
    },
    "sortOrder": 275
  },
  "🧚": {
    "glyph": "🧚",
    "group": "People & Body",
    "keywords": [
      "fairy",
      "Oberon",
      "Puck",
      "Titania"
    ],
    "dirName": "Person fairy",
    "skintones": {
      "Default": {
        "3D": "person_fairy_3d_default.png",
        "Animated": "Fairy.png"
      }
    },
    "sortOrder": 376
  },
  "🧑‍🍼": {
    "glyph": "🧑‍🍼",
    "group": "People & Body",
    "keywords": [
      "baby",
      "feeding",
      "nursing",
      "person"
    ],
    "dirName": "Person feeding baby",
    "skintones": {
      "Default": {
        "3D": "person_feeding_baby_3d_default.png",
        "Animated": "Person Feeding Baby.png"
      }
    },
    "sortOrder": 362
  },
  "🤺": {
    "glyph": "🤺",
    "group": "People & Body",
    "keywords": [
      "fencer",
      "fencing",
      "person fencing",
      "sword"
    ],
    "dirName": "Person fencing",
    "styles": {
      "3D": "person_fencing_3d.png",
      "Animated": "Person Fencing.png"
    },
    "sortOrder": 434
  },
  "🙍": {
    "glyph": "🙍",
    "group": "People & Body",
    "keywords": [
      "frown",
      "gesture",
      "person frowning"
    ],
    "dirName": "Person frowning",
    "skintones": {
      "Default": {
        "3D": "person_frowning_3d_default.png",
        "Animated": "Person Frowning.png"
      }
    },
    "sortOrder": 251
  },
  "🧞": {
    "glyph": "🧞",
    "group": "People & Body",
    "keywords": [
      "djinn",
      "genie"
    ],
    "dirName": "Person genie",
    "styles": {
      "3D": "person_genie_3d.png",
      "Animated": "Genie.png"
    },
    "sortOrder": 388
  },
  "🙅": {
    "glyph": "🙅",
    "group": "People & Body",
    "keywords": [
      "forbidden",
      "gesture",
      "hand",
      "person gesturing NO",
      "prohibited"
    ],
    "dirName": "Person gesturing no",
    "skintones": {
      "Default": {
        "3D": "person_gesturing_no_3d_default.png",
        "Animated": "Person Gesturing No.png"
      }
    },
    "sortOrder": 257
  },
  "🙆": {
    "glyph": "🙆",
    "group": "People & Body",
    "keywords": [
      "gesture",
      "hand",
      "OK",
      "person gesturing OK"
    ],
    "dirName": "Person gesturing ok",
    "skintones": {
      "Default": {
        "3D": "person_gesturing_ok_3d_default.png",
        "Animated": "Person Gesturing OK.png"
      }
    },
    "sortOrder": 260
  },
  "💇": {
    "glyph": "💇",
    "group": "People & Body",
    "keywords": [
      "barber",
      "beauty",
      "haircut",
      "parlor",
      "person getting haircut"
    ],
    "dirName": "Person getting haircut",
    "skintones": {
      "Default": {
        "3D": "person_getting_haircut_3d_default.png",
        "Animated": "Person Getting Haircut.png"
      }
    },
    "sortOrder": 398
  },
  "💆": {
    "glyph": "💆",
    "group": "People & Body",
    "keywords": [
      "face",
      "massage",
      "person getting massage",
      "salon"
    ],
    "dirName": "Person getting massage",
    "skintones": {
      "Default": {
        "3D": "person_getting_massage_3d_default.png",
        "Animated": "Person Getting Massage.png"
      }
    },
    "sortOrder": 395
  },
  "🏌️": {
    "glyph": "🏌️",
    "group": "People & Body",
    "keywords": [
      "ball",
      "golf",
      "person golfing"
    ],
    "dirName": "Person golfing",
    "skintones": {
      "Default": {
        "3D": "person_golfing_3d_default.png",
        "Animated": "Person Golfing.png"
      }
    },
    "sortOrder": 438
  },
  "🛌": {
    "glyph": "🛌",
    "group": "People & Body",
    "keywords": [
      "hotel",
      "person in bed",
      "sleep"
    ],
    "dirName": "Person in bed",
    "skintones": {
      "Default": {
        "3D": "person_in_bed_3d_default.png",
        "Animated": "Person in Bed.png"
      }
    },
    "sortOrder": 481
  },
  "🧘": {
    "glyph": "🧘",
    "group": "People & Body",
    "keywords": [
      "meditation",
      "person in lotus position",
      "yoga"
    ],
    "dirName": "Person in lotus position",
    "skintones": {
      "Default": {
        "3D": "person_in_lotus_position_3d_default.png",
        "Animated": "Person in Lotus Position.png"
      }
    },
    "sortOrder": 477
  },
  "🧑‍🦽": {
    "glyph": "🧑‍🦽",
    "group": "People & Body",
    "keywords": [
      "accessibility",
      "person in manual wheelchair",
      "wheelchair"
    ],
    "dirName": "Person in manual wheelchair",
    "skintones": {
      "Default": {
        "3D": "person_in_manual_wheelchair_3d_default.png",
        "Animated": "Person in Manual Wheelchair.png"
      }
    },
    "sortOrder": 416
  },
  "🧑‍🦼": {
    "glyph": "🧑‍🦼",
    "group": "People & Body",
    "keywords": [
      "accessibility",
      "person in motorized wheelchair",
      "wheelchair"
    ],
    "dirName": "Person in motorized wheelchair",
    "skintones": {
      "Default": {
        "3D": "person_in_motorized_wheelchair_3d_default.png",
        "Animated": "Person in Motorized Wheelchair.png"
      }
    },
    "sortOrder": 413
  },
  "🧖": {
    "glyph": "🧖",
    "group": "People & Body",
    "keywords": [
      "person in steamy room",
      "sauna",
      "steam room"
    ],
    "dirName": "Person in steamy room",
    "skintones": {
      "Default": {
        "3D": "person_in_steamy_room_3d_default.png"
      }
    },
    "sortOrder": 428
  },
  "🕴️": {
    "glyph": "🕴️",
    "group": "People & Body",
    "keywords": [
      "business",
      "person",
      "person in suit levitating",
      "suit"
    ],
    "dirName": "Person in suit levitating",
    "skintones": {
      "Default": {
        "3D": "person_in_suit_levitating_3d_default.png",
        "Animated": "Person in Suit Levitating.png"
      }
    },
    "sortOrder": 424
  },
  "🤵": {
    "glyph": "🤵",
    "group": "People & Body",
    "keywords": [
      "groom",
      "person",
      "person in tuxedo",
      "tuxedo"
    ],
    "dirName": "Person in tuxedo",
    "skintones": {
      "Default": {
        "3D": "person_in_tuxedo_3d_default.png",
        "Animated": "Person in Tuxedo.png"
      }
    },
    "sortOrder": 350
  },
  "🤹": {
    "glyph": "🤹",
    "group": "People & Body",
    "keywords": [
      "balance",
      "juggle",
      "multitask",
      "person juggling",
      "skill"
    ],
    "dirName": "Person juggling",
    "skintones": {
      "Default": {
        "3D": "person_juggling_3d_default.png",
        "Animated": "Person Juggling.png"
      }
    },
    "sortOrder": 474
  },
  "🧎": {
    "glyph": "🧎",
    "group": "People & Body",
    "keywords": [
      "kneel",
      "kneeling",
      "person kneeling"
    ],
    "dirName": "Person kneeling",
    "skintones": {
      "Default": {
        "3D": "person_kneeling_3d_default.png",
        "Animated": "Person Kneeling.png"
      }
    },
    "sortOrder": 407
  },
  "🏋️": {
    "glyph": "🏋️",
    "group": "People & Body",
    "keywords": [
      "lifter",
      "person lifting weights",
      "weight"
    ],
    "dirName": "Person lifting weights",
    "skintones": {
      "Default": {
        "3D": "person_lifting_weights_3d_default.png",
        "Animated": "Person Lifting Weights.png"
      }
    },
    "sortOrder": 453
  },
  "🧙": {
    "glyph": "🧙",
    "group": "People & Body",
    "keywords": [
      "mage",
      "sorcerer",
      "sorceress",
      "witch",
      "wizard"
    ],
    "dirName": "Person mage",
    "skintones": {
      "Default": {
        "3D": "person_mage_3d_default.png",
        "Animated": "Mage.png"
      }
    },
    "sortOrder": 373
  },
  "🧜": {
    "glyph": "🧜",
    "group": "People & Body",
    "keywords": [
      "mermaid",
      "merman",
      "merperson",
      "merwoman"
    ],
    "dirName": "Person merpeople",
    "skintones": {
      "Default": {
        "3D": "person_merpeople_3d_default.png",
        "Animated": "Merperson.png"
      }
    },
    "sortOrder": 382
  },
  "🚵": {
    "glyph": "🚵",
    "group": "People & Body",
    "keywords": [
      "bicycle",
      "bicyclist",
      "bike",
      "cyclist",
      "mountain",
      "person mountain biking"
    ],
    "dirName": "Person mountain biking",
    "skintones": {
      "Default": {
        "3D": "person_mountain_biking_3d_default.png",
        "Animated": "Person Mountain Biking.png"
      }
    },
    "sortOrder": 459
  },
  "🤾": {
    "glyph": "🤾",
    "group": "People & Body",
    "keywords": [
      "ball",
      "handball",
      "person playing handball"
    ],
    "dirName": "Person playing handball",
    "skintones": {
      "Default": {
        "3D": "person_playing_handball_3d_default.png",
        "Animated": "Person Playing Handball.png"
      }
    },
    "sortOrder": 471
  },
  "🤽": {
    "glyph": "🤽",
    "group": "People & Body",
    "keywords": [
      "person playing water polo",
      "polo",
      "water"
    ],
    "dirName": "Person playing water polo",
    "skintones": {
      "Default": {
        "3D": "person_playing_water_polo_3d_default.png",
        "Animated": "Person Playing Water Polo.png"
      }
    },
    "sortOrder": 468
  },
  "🙎": {
    "glyph": "🙎",
    "group": "People & Body",
    "keywords": [
      "gesture",
      "person pouting",
      "pouting"
    ],
    "dirName": "Person pouting",
    "skintones": {
      "Default": {
        "3D": "person_pouting_3d_default.png",
        "Animated": "Person Pouting.png"
      }
    },
    "sortOrder": 254
  },
  "🙋": {
    "glyph": "🙋",
    "group": "People & Body",
    "keywords": [
      "gesture",
      "hand",
      "happy",
      "person raising hand",
      "raised"
    ],
    "dirName": "Person raising hand",
    "skintones": {
      "Default": {
        "3D": "person_raising_hand_3d_default.png",
        "Animated": "Person Raising Hand.png"
      }
    },
    "sortOrder": 266
  },
  "🧑‍🦰": {
    "glyph": "🧑‍🦰",
    "group": "People & Body",
    "keywords": [
      "adult",
      "gender-neutral",
      "person",
      "red hair",
      "unspecified gender"
    ],
    "dirName": "Person red hair",
    "skintones": {
      "Default": {
        "3D": "person_red_hair_3d_default.png"
      }
    },
    "sortOrder": 239
  },
  "🚣": {
    "glyph": "🚣",
    "group": "People & Body",
    "keywords": [
      "boat",
      "person rowing boat",
      "rowboat"
    ],
    "dirName": "Person rowing boat",
    "skintones": {
      "Default": {
        "3D": "person_rowing_boat_3d_default.png",
        "Animated": "Person Rowing Boat.png"
      }
    },
    "sortOrder": 444
  },
  "🏃": {
    "glyph": "🏃",
    "group": "People & Body",
    "keywords": [
      "marathon",
      "person running",
      "running"
    ],
    "dirName": "Person running",
    "skintones": {
      "Default": {
        "3D": "person_running_3d_default.png",
        "Animated": "Person Running.png"
      }
    },
    "sortOrder": 419
  },
  "🤷": {
    "glyph": "🤷",
    "group": "People & Body",
    "keywords": [
      "doubt",
      "ignorance",
      "indifference",
      "person shrugging",
      "shrug"
    ],
    "dirName": "Person shrugging",
    "skintones": {
      "Default": {
        "3D": "person_shrugging_3d_default.png",
        "Animated": "Person Shrugging.png"
      }
    },
    "sortOrder": 278
  },
  "🧍": {
    "glyph": "🧍",
    "group": "People & Body",
    "keywords": [
      "person standing",
      "stand",
      "standing"
    ],
    "dirName": "Person standing",
    "skintones": {
      "Default": {
        "3D": "person_standing_3d_default.png",
        "Animated": "Person Standing.png"
      }
    },
    "sortOrder": 404
  },
  "🦸": {
    "glyph": "🦸",
    "group": "People & Body",
    "keywords": [
      "good",
      "hero",
      "heroine",
      "superhero",
      "superpower"
    ],
    "dirName": "Person superhero",
    "skintones": {
      "Default": {
        "3D": "person_superhero_3d_default.png",
        "Animated": "Superhero.png"
      }
    },
    "sortOrder": 367
  },
  "🦹": {
    "glyph": "🦹",
    "group": "People & Body",
    "keywords": [
      "criminal",
      "evil",
      "superpower",
      "supervillain",
      "villain"
    ],
    "dirName": "Person supervillain",
    "skintones": {
      "Default": {
        "3D": "person_supervillain_3d_default.png",
        "Animated": "Supervillain.png"
      }
    },
    "sortOrder": 370
  },
  "🏄": {
    "glyph": "🏄",
    "group": "People & Body",
    "keywords": [
      "person surfing",
      "surfing"
    ],
    "dirName": "Person surfing",
    "skintones": {
      "Default": {
        "3D": "person_surfing_3d_default.png",
        "Animated": "Person Surfing.png"
      }
    },
    "sortOrder": 441
  },
  "🏊": {
    "glyph": "🏊",
    "group": "People & Body",
    "keywords": [
      "person swimming",
      "swim"
    ],
    "dirName": "Person swimming",
    "skintones": {
      "Default": {
        "3D": "person_swimming_3d_default.png",
        "Animated": "Person Swimming.png"
      }
    },
    "sortOrder": 447
  },
  "🛀": {
    "glyph": "🛀",
    "group": "People & Body",
    "keywords": [
      "bath",
      "bathtub",
      "person taking bath"
    ],
    "dirName": "Person taking bath",
    "skintones": {
      "Default": {
        "3D": "person_taking_bath_3d_default.png",
        "Animated": "Person Taking Bath.png"
      }
    },
    "sortOrder": 480
  },
  "💁": {
    "glyph": "💁",
    "group": "People & Body",
    "keywords": [
      "hand",
      "help",
      "information",
      "person tipping hand",
      "sassy",
      "tipping"
    ],
    "dirName": "Person tipping hand",
    "skintones": {
      "Default": {
        "3D": "person_tipping_hand_3d_default.png",
        "Animated": "Person Tipping Hand.png"
      }
    },
    "sortOrder": 263
  },
  "🧛": {
    "glyph": "🧛",
    "group": "People & Body",
    "keywords": [
      "Dracula",
      "undead",
      "vampire"
    ],
    "dirName": "Person vampire",
    "skintones": {
      "Default": {
        "3D": "person_vampire_3d_default.png",
        "Animated": "Vampire.png"
      }
    },
    "sortOrder": 379
  },
  "🚶": {
    "glyph": "🚶",
    "group": "People & Body",
    "keywords": [
      "hike",
      "person walking",
      "walk",
      "walking"
    ],
    "dirName": "Person walking",
    "skintones": {
      "Default": {
        "3D": "person_walking_3d_default.png",
        "Animated": "Person Walking.png"
      }
    },
    "sortOrder": 401
  },
  "👳": {
    "glyph": "👳",
    "group": "People & Body",
    "keywords": [
      "person wearing turban",
      "turban"
    ],
    "dirName": "Person wearing turban",
    "skintones": {
      "Default": {
        "3D": "person_wearing_turban_3d_default.png",
        "Animated": "Person Wearing Turban.png"
      }
    },
    "sortOrder": 345
  },
  "🧑‍🦳": {
    "glyph": "🧑‍🦳",
    "group": "People & Body",
    "keywords": [
      "adult",
      "gender-neutral",
      "person",
      "unspecified gender",
      "white hair"
    ],
    "dirName": "Person white hair",
    "skintones": {
      "Default": {
        "3D": "person_white_hair_3d_default.png"
      }
    },
    "sortOrder": 243
  },
  "👯": {
    "glyph": "👯",
    "group": "People & Body",
    "keywords": [
      "bunny ear",
      "dancer",
      "partying",
      "people with bunny ears"
    ],
    "dirName": "Person with bunny ears",
    "styles": {
      "3D": "person_with_bunny_ears_3d.png",
      "Animated": "People with Bunny Ears.png"
    },
    "sortOrder": 425
  },
  "🫅": {
    "glyph": "🫅",
    "group": "People & Body",
    "keywords": [
      "monarch",
      "noble",
      "person with crown",
      "regal",
      "royalty"
    ],
    "dirName": "Person with crown",
    "skintones": {
      "Default": {
        "3D": "person_with_crown_3d_default.png",
        "Animated": "Person with Crown.png"
      }
    },
    "sortOrder": 342
  },
  "👲": {
    "glyph": "👲",
    "group": "People & Body",
    "keywords": [
      "cap",
      "gua pi mao",
      "hat",
      "person",
      "person with skullcap",
      "skullcap"
    ],
    "dirName": "Person with skullcap",
    "skintones": {
      "Default": {
        "3D": "person_with_skullcap_3d_default.png",
        "Animated": "Person with Skullcap.png"
      }
    },
    "sortOrder": 348
  },
  "👰": {
    "glyph": "👰",
    "group": "People & Body",
    "keywords": [
      "bride",
      "person",
      "person with veil",
      "veil",
      "wedding"
    ],
    "dirName": "Person with veil",
    "skintones": {
      "Default": {
        "3D": "person_with_veil_3d_default.png",
        "Animated": "Person with Veil.png"
      }
    },
    "sortOrder": 353
  },
  "🧑‍🦯": {
    "glyph": "🧑‍🦯",
    "group": "People & Body",
    "keywords": [
      "accessibility",
      "blind",
      "person with white cane"
    ],
    "dirName": "Person with white cane",
    "skintones": {
      "Default": {
        "3D": "person_with_white_cane_3d_default.png",
        "Animated": "Person with White Cane.png"
      }
    },
    "sortOrder": 410
  },
  "🤼": {
    "glyph": "🤼",
    "group": "People & Body",
    "keywords": [
      "people wrestling",
      "wrestle",
      "wrestler"
    ],
    "dirName": "Person wrestling",
    "styles": {
      "3D": "person_wrestling_3d.png",
      "Animated": "People Wrestling.png"
    },
    "sortOrder": 465
  },
  "🧟": {
    "glyph": "🧟",
    "group": "People & Body",
    "keywords": [
      "undead",
      "walking dead",
      "zombie"
    ],
    "dirName": "Person zombie",
    "styles": {
      "3D": "person_zombie_3d.png",
      "Animated": "Zombie.png"
    },
    "sortOrder": 391
  },
  "🧫": {
    "glyph": "🧫",
    "group": "Objects",
    "keywords": [
      "bacteria",
      "biologist",
      "biology",
      "culture",
      "lab",
      "petri dish"
    ],
    "dirName": "Petri dish",
    "styles": {
      "3D": "petri_dish_3d.png",
      "Animated": "Petri Dish.png"
    },
    "sortOrder": 1321
  },
  "⛏️": {
    "glyph": "⛏️",
    "group": "Objects",
    "keywords": [
      "mining",
      "pick",
      "tool"
    ],
    "dirName": "Pick",
    "styles": {
      "3D": "pick_3d.png",
      "Animated": "Pick.png"
    },
    "sortOrder": 1296
  },
  "🛻": {
    "glyph": "🛻",
    "group": "Travel & Places",
    "keywords": [
      "pick-up",
      "pickup",
      "truck"
    ],
    "dirName": "Pickup truck",
    "styles": {
      "3D": "pickup_truck_3d.png",
      "Animated": "Pickup Truck.png"
    },
    "sortOrder": 897
  },
  "🥧": {
    "glyph": "🥧",
    "group": "Food & Drink",
    "keywords": [
      "filling",
      "pastry",
      "pie"
    ],
    "dirName": "Pie",
    "styles": {
      "3D": "pie_3d.png",
      "Animated": "Pie.png"
    },
    "sortOrder": 773
  },
  "🐖": {
    "glyph": "🐖",
    "group": "Animals & Nature",
    "keywords": [
      "pig",
      "sow"
    ],
    "dirName": "Pig",
    "styles": {
      "3D": "pig_3d.png",
      "Animated": "Pig.png"
    },
    "sortOrder": 560
  },
  "🐷": {
    "glyph": "🐷",
    "group": "Animals & Nature",
    "keywords": [
      "face",
      "pig"
    ],
    "dirName": "Pig face",
    "styles": {
      "3D": "pig_face_3d.png",
      "Animated": "Pig Face.png"
    },
    "sortOrder": 559
  },
  "🐽": {
    "glyph": "🐽",
    "group": "Animals & Nature",
    "keywords": [
      "face",
      "nose",
      "pig"
    ],
    "dirName": "Pig nose",
    "styles": {
      "3D": "pig_nose_3d.png",
      "Animated": "Pig Nose.png"
    },
    "sortOrder": 562
  },
  "💩": {
    "glyph": "💩",
    "group": "Smileys & Emotion",
    "keywords": [
      "dung",
      "face",
      "monster",
      "pile of poo",
      "poo",
      "poop"
    ],
    "dirName": "Pile of poo",
    "styles": {
      "3D": "pile_of_poo_3d.png",
      "Animated": "Pile of Poo.png"
    },
    "sortOrder": 107
  },
  "💊": {
    "glyph": "💊",
    "group": "Objects",
    "keywords": [
      "doctor",
      "medicine",
      "pill",
      "sick"
    ],
    "dirName": "Pill",
    "styles": {
      "3D": "pill_3d.png",
      "Animated": "Pill.png"
    },
    "sortOrder": 1328
  },
  "🧑‍✈️": {
    "glyph": "🧑‍✈️",
    "group": "People & Body",
    "keywords": [
      "pilot",
      "plane"
    ],
    "dirName": "Pilot",
    "skintones": {
      "Default": {
        "3D": "pilot_3d_default.png",
        "Animated": "Pilot.png"
      }
    },
    "sortOrder": 320
  },
  "🤌": {
    "glyph": "🤌",
    "group": "People & Body",
    "keywords": [
      "fingers",
      "hand gesture",
      "interrogation",
      "pinched",
      "sarcastic"
    ],
    "dirName": "Pinched fingers",
    "skintones": {
      "Default": {
        "3D": "pinched_fingers_3d_default.png",
        "Animated": "Pinched Fingers.png"
      }
    },
    "sortOrder": 174
  },
  "🤏": {
    "glyph": "🤏",
    "group": "People & Body",
    "keywords": [
      "pinching hand",
      "small amount"
    ],
    "dirName": "Pinching hand",
    "skintones": {
      "Default": {
        "3D": "pinching_hand_3d_default.png",
        "Animated": "Pinching Hand.png"
      }
    },
    "sortOrder": 175
  },
  "🎍": {
    "glyph": "🎍",
    "group": "Activities",
    "keywords": [
      "bamboo",
      "celebration",
      "Japanese",
      "pine",
      "pine decoration"
    ],
    "dirName": "Pine decoration",
    "styles": {
      "3D": "pine_decoration_3d.png",
      "Animated": "Pine Decoration.png"
    },
    "sortOrder": 1034
  },
  "🍍": {
    "glyph": "🍍",
    "group": "Food & Drink",
    "keywords": [
      "fruit",
      "pineapple"
    ],
    "dirName": "Pineapple",
    "styles": {
      "3D": "pineapple_3d.png",
      "Animated": "Pineapple.png"
    },
    "sortOrder": 680
  },
  "🏓": {
    "glyph": "🏓",
    "group": "Activities",
    "keywords": [
      "ball",
      "bat",
      "game",
      "paddle",
      "ping pong",
      "table tennis"
    ],
    "dirName": "Ping pong",
    "styles": {
      "3D": "ping_pong_3d.png",
      "Animated": "Ping Pong.png"
    },
    "sortOrder": 1065
  },
  "🏴‍☠️": {
    "glyph": "🏴‍☠️",
    "group": "Flags",
    "keywords": [
      "Jolly Roger",
      "pirate",
      "pirate flag",
      "plunder",
      "treasure"
    ],
    "dirName": "Pirate flag",
    "styles": {
      "3D": "pirate_flag_3d.png",
      "Animated": "Pirate Flag.png"
    },
    "sortOrder": 1593
  },
  "♓": {
    "glyph": "♓",
    "group": "Symbols",
    "keywords": [
      "fish",
      "Pisces",
      "zodiac"
    ],
    "dirName": "Pisces",
    "styles": {
      "3D": "pisces_3d.png",
      "Animated": "Pisces.png"
    },
    "sortOrder": 1435
  },
  "🍕": {
    "glyph": "🍕",
    "group": "Food & Drink",
    "keywords": [
      "cheese",
      "pizza",
      "slice"
    ],
    "dirName": "Pizza",
    "styles": {
      "3D": "pizza_3d.png",
      "Animated": "Pizza.png"
    },
    "sortOrder": 724
  },
  "🪅": {
    "glyph": "🪅",
    "group": "Activities",
    "keywords": [
      "celebration",
      "party",
      "piñata"
    ],
    "dirName": "Piñata",
    "styles": {
      "3D": "piñata_3d.png",
      "Animated": "Piñata.png"
    },
    "sortOrder": 1092
  },
  "🪧": {
    "glyph": "🪧",
    "group": "Objects",
    "keywords": [
      "demonstration",
      "picket",
      "placard",
      "protest",
      "sign"
    ],
    "dirName": "Placard",
    "styles": {
      "3D": "placard_3d.png",
      "Animated": "Placard.png"
    },
    "sortOrder": 1363
  },
  "🛐": {
    "glyph": "🛐",
    "group": "Symbols",
    "keywords": [
      "place of worship",
      "religion",
      "worship"
    ],
    "dirName": "Place of worship",
    "styles": {
      "3D": "place_of_worship_3d.png",
      "Animated": "Place of Worship.png"
    },
    "sortOrder": 1412
  },
  "▶️": {
    "glyph": "▶️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "play",
      "play button",
      "right",
      "triangle"
    ],
    "dirName": "Play button",
    "styles": {
      "3D": "play_button_3d.png",
      "Animated": "Play Button.png"
    },
    "sortOrder": 1440
  },
  "⏯️": {
    "glyph": "⏯️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "pause",
      "play",
      "play or pause button",
      "right",
      "triangle"
    ],
    "dirName": "Play or pause button",
    "styles": {
      "3D": "play_or_pause_button_3d.png",
      "Animated": "Play or Pause Button.png"
    },
    "sortOrder": 1443
  },
  "🛝": {
    "glyph": "🛝",
    "group": "Travel & Places",
    "keywords": [
      "amusement park",
      "play",
      "playground slide"
    ],
    "dirName": "Playground slide",
    "styles": {
      "3D": "playground_slide_3d.png",
      "Animated": "Playground Slide.png"
    },
    "sortOrder": 867
  },
  "🥺": {
    "glyph": "🥺",
    "group": "Smileys & Emotion",
    "keywords": [
      "begging",
      "mercy",
      "pleading face",
      "puppy eyes"
    ],
    "dirName": "Pleading face",
    "styles": {
      "3D": "pleading_face_3d.png",
      "Animated": "Pleading Face.png"
    },
    "sortOrder": 82
  },
  "🪠": {
    "glyph": "🪠",
    "group": "Objects",
    "keywords": [
      "force cup",
      "plumber",
      "plunger",
      "suction",
      "toilet"
    ],
    "dirName": "Plunger",
    "styles": {
      "3D": "plunger_3d.png",
      "Animated": "Plunger.png"
    },
    "sortOrder": 1341
  },
  "➕": {
    "glyph": "➕",
    "group": "Symbols",
    "keywords": [
      "+",
      "math",
      "plus",
      "sign"
    ],
    "dirName": "Plus",
    "styles": {
      "3D": "plus_3d.png",
      "Animated": "Plus.png"
    },
    "sortOrder": 1465
  },
  "🐻‍❄️": {
    "glyph": "🐻‍❄️",
    "group": "Animals & Nature",
    "keywords": [
      "arctic",
      "bear",
      "polar bear",
      "white"
    ],
    "dirName": "Polar bear",
    "styles": {
      "3D": "polar_bear_3d.png",
      "Animated": "Polar Bear.png"
    },
    "sortOrder": 585
  },
  "🚓": {
    "glyph": "🚓",
    "group": "Travel & Places",
    "keywords": [
      "car",
      "patrol",
      "police"
    ],
    "dirName": "Police car",
    "styles": {
      "3D": "police_car_3d.png",
      "Animated": "Police Car.png"
    },
    "sortOrder": 890
  },
  "🚨": {
    "glyph": "🚨",
    "group": "Travel & Places",
    "keywords": [
      "beacon",
      "car",
      "light",
      "police",
      "revolving"
    ],
    "dirName": "Police car light",
    "styles": {
      "3D": "police_car_light_3d.png",
      "Animated": "Police Car Light.png"
    },
    "sortOrder": 917
  },
  "👮": {
    "glyph": "👮",
    "group": "People & Body",
    "keywords": [
      "cop",
      "officer",
      "police"
    ],
    "dirName": "Police officer",
    "skintones": {
      "Default": {
        "3D": "police_officer_3d_default.png",
        "Animated": "Police Officer.png"
      }
    },
    "sortOrder": 329
  },
  "🐩": {
    "glyph": "🐩",
    "group": "Animals & Nature",
    "keywords": [
      "dog",
      "poodle"
    ],
    "dirName": "Poodle",
    "styles": {
      "3D": "poodle_3d.png",
      "Animated": "Poodle.png"
    },
    "sortOrder": 538
  },
  "🎱": {
    "glyph": "🎱",
    "group": "Activities",
    "keywords": [
      "8",
      "ball",
      "billiard",
      "eight",
      "game",
      "pool 8 ball"
    ],
    "dirName": "Pool 8 ball",
    "styles": {
      "3D": "pool_8_ball_3d.png",
      "Animated": "Pool 8 Ball.png"
    },
    "sortOrder": 1081
  },
  "🍿": {
    "glyph": "🍿",
    "group": "Food & Drink",
    "keywords": [
      "popcorn"
    ],
    "dirName": "Popcorn",
    "styles": {
      "3D": "popcorn_3d.png",
      "Animated": "Popcorn.png"
    },
    "sortOrder": 739
  },
  "🏤": {
    "glyph": "🏤",
    "group": "Travel & Places",
    "keywords": [
      "European",
      "post",
      "post office"
    ],
    "dirName": "Post office",
    "styles": {
      "3D": "post_office_3d.png",
      "Animated": "Post Office.png"
    },
    "sortOrder": 835
  },
  "📯": {
    "glyph": "📯",
    "group": "Objects",
    "keywords": [
      "horn",
      "post",
      "postal"
    ],
    "dirName": "Postal horn",
    "styles": {
      "3D": "postal_horn_3d.png",
      "Animated": "Postal Horn.png"
    },
    "sortOrder": 1161
  },
  "📮": {
    "glyph": "📮",
    "group": "Objects",
    "keywords": [
      "mail",
      "mailbox",
      "postbox"
    ],
    "dirName": "Postbox",
    "styles": {
      "3D": "postbox_3d.png",
      "Animated": "Postbox.png"
    },
    "sortOrder": 1256
  },
  "🍲": {
    "glyph": "🍲",
    "group": "Food & Drink",
    "keywords": [
      "pot",
      "pot of food",
      "stew"
    ],
    "dirName": "Pot of food",
    "styles": {
      "3D": "pot_of_food_3d.png",
      "Animated": "Pot of Food.png"
    },
    "sortOrder": 735
  },
  "🚰": {
    "glyph": "🚰",
    "group": "Symbols",
    "keywords": [
      "drinking",
      "potable",
      "water"
    ],
    "dirName": "Potable water",
    "styles": {
      "3D": "potable_water_3d.png",
      "Animated": "Potable Water.png"
    },
    "sortOrder": 1367
  },
  "🥔": {
    "glyph": "🥔",
    "group": "Food & Drink",
    "keywords": [
      "food",
      "potato",
      "vegetable"
    ],
    "dirName": "Potato",
    "styles": {
      "3D": "potato_3d.png",
      "Animated": "Potato.png"
    },
    "sortOrder": 695
  },
  "🪴": {
    "glyph": "🪴",
    "group": "Animals & Nature",
    "keywords": [
      "boring",
      "grow",
      "house",
      "nurturing",
      "plant",
      "potted plant",
      "useless"
    ],
    "dirName": "Potted plant",
    "styles": {
      "3D": "potted_plant_3d.png",
      "Animated": "Potted Plant.png"
    },
    "sortOrder": 660
  },
  "🍗": {
    "glyph": "🍗",
    "group": "Food & Drink",
    "keywords": [
      "bone",
      "chicken",
      "drumstick",
      "leg",
      "poultry"
    ],
    "dirName": "Poultry leg",
    "styles": {
      "3D": "poultry_leg_3d.png",
      "Animated": "Poultry Leg.png"
    },
    "sortOrder": 719
  },
  "💷": {
    "glyph": "💷",
    "group": "Objects",
    "keywords": [
      "banknote",
      "bill",
      "currency",
      "money",
      "note",
      "pound"
    ],
    "dirName": "Pound banknote",
    "styles": {
      "3D": "pound_banknote_3d.png",
      "Animated": "Pound Banknote.png"
    },
    "sortOrder": 1240
  },
  "🫗": {
    "glyph": "🫗",
    "group": "Food & Drink",
    "keywords": [
      "drink",
      "empty",
      "glass",
      "pouring liquid",
      "spill"
    ],
    "dirName": "Pouring liquid",
    "styles": {
      "3D": "pouring_liquid_3d.png",
      "Animated": "Pouring Liquid.png"
    },
    "sortOrder": 793
  },
  "😾": {
    "glyph": "😾",
    "group": "Smileys & Emotion",
    "keywords": [
      "cat",
      "face",
      "pouting"
    ],
    "dirName": "Pouting cat",
    "styles": {
      "3D": "pouting_cat_3d.png",
      "Animated": "Pouting Cat.png"
    },
    "sortOrder": 123
  },
  "😡": {
    "glyph": "😡",
    "group": "Smileys & Emotion",
    "keywords": [
      "angry",
      "face",
      "mad",
      "pouting",
      "rage",
      "red"
    ],
    "dirName": "Pouting face",
    "styles": {
      "3D": "pouting_face_3d.png"
    },
    "sortOrder": 100
  },
  "📿": {
    "glyph": "📿",
    "group": "Objects",
    "keywords": [
      "beads",
      "clothing",
      "necklace",
      "prayer",
      "religion"
    ],
    "dirName": "Prayer beads",
    "styles": {
      "3D": "prayer_beads_3d.png",
      "Animated": "Prayer Beads.png"
    },
    "sortOrder": 1151
  },
  "🫃": {
    "glyph": "🫃",
    "group": "People & Body",
    "keywords": [
      "belly",
      "bloated",
      "full",
      "pregnant",
      "pregnant man"
    ],
    "dirName": "Pregnant man",
    "skintones": {
      "Default": {
        "3D": "pregnant_man_3d_default.png",
        "Animated": "Pregnant Man.png"
      }
    },
    "sortOrder": 357
  },
  "🫄": {
    "glyph": "🫄",
    "group": "People & Body",
    "keywords": [
      "belly",
      "bloated",
      "full",
      "pregnant",
      "pregnant person"
    ],
    "dirName": "Pregnant person",
    "skintones": {
      "Default": {
        "3D": "pregnant_person_3d_default.png",
        "Animated": "Pregnant Person.png"
      }
    },
    "sortOrder": 358
  },
  "🤰": {
    "glyph": "🤰",
    "group": "People & Body",
    "keywords": [
      "pregnant",
      "woman"
    ],
    "dirName": "Pregnant woman",
    "skintones": {
      "Default": {
        "3D": "pregnant_woman_3d_default.png",
        "Animated": "Pregnant Woman.png"
      }
    },
    "sortOrder": 356
  },
  "🥨": {
    "glyph": "🥨",
    "group": "Food & Drink",
    "keywords": [
      "pretzel",
      "twisted"
    ],
    "dirName": "Pretzel",
    "styles": {
      "3D": "pretzel_3d.png",
      "Animated": "Pretzel.png"
    },
    "sortOrder": 713
  },
  "🤴": {
    "glyph": "🤴",
    "group": "People & Body",
    "keywords": [
      "prince"
    ],
    "dirName": "Prince",
    "skintones": {
      "Default": {
        "3D": "prince_3d_default.png",
        "Animated": "Prince.png"
      }
    },
    "sortOrder": 343
  },
  "👸": {
    "glyph": "👸",
    "group": "People & Body",
    "keywords": [
      "fairy tale",
      "fantasy",
      "princess"
    ],
    "dirName": "Princess",
    "skintones": {
      "Default": {
        "3D": "princess_3d_default.png",
        "Animated": "Princess.png"
      }
    },
    "sortOrder": 344
  },
  "🖨️": {
    "glyph": "🖨️",
    "group": "Objects",
    "keywords": [
      "computer",
      "printer"
    ],
    "dirName": "Printer",
    "styles": {
      "3D": "printer_3d.png",
      "Animated": "Printer.png"
    },
    "sortOrder": 1193
  },
  "🚫": {
    "glyph": "🚫",
    "group": "Symbols",
    "keywords": [
      "entry",
      "forbidden",
      "no",
      "not",
      "prohibited"
    ],
    "dirName": "Prohibited",
    "styles": {
      "3D": "prohibited_3d.png",
      "Animated": "Prohibited.png"
    },
    "sortOrder": 1381
  },
  "🟣": {
    "glyph": "🟣",
    "group": "Symbols",
    "keywords": [
      "circle",
      "purple"
    ],
    "dirName": "Purple circle",
    "styles": {
      "3D": "purple_circle_3d.png",
      "Animated": "Purple Circle.png"
    },
    "sortOrder": 1557
  },
  "💜": {
    "glyph": "💜",
    "group": "Smileys & Emotion",
    "keywords": [
      "purple",
      "purple heart"
    ],
    "dirName": "Purple heart",
    "styles": {
      "3D": "purple_heart_3d.png",
      "Animated": "Purple Heart.png"
    },
    "sortOrder": 146
  },
  "🟪": {
    "glyph": "🟪",
    "group": "Symbols",
    "keywords": [
      "purple",
      "square"
    ],
    "dirName": "Purple square",
    "styles": {
      "3D": "purple_square_3d.png",
      "Animated": "Purple Square.png"
    },
    "sortOrder": 1566
  },
  "👛": {
    "glyph": "👛",
    "group": "Objects",
    "keywords": [
      "clothing",
      "coin",
      "purse"
    ],
    "dirName": "Purse",
    "styles": {
      "3D": "purse_3d.png",
      "Animated": "Purse.png"
    },
    "sortOrder": 1130
  },
  "📌": {
    "glyph": "📌",
    "group": "Objects",
    "keywords": [
      "pin",
      "pushpin"
    ],
    "dirName": "Pushpin",
    "styles": {
      "3D": "pushpin_3d.png",
      "Animated": "Pushpin.png"
    },
    "sortOrder": 1278
  },
  "🧩": {
    "glyph": "🧩",
    "group": "Activities",
    "keywords": [
      "clue",
      "interlocking",
      "jigsaw",
      "piece",
      "puzzle"
    ],
    "dirName": "Puzzle piece",
    "styles": {
      "3D": "puzzle_piece_3d.png",
      "Animated": "Puzzle Piece.png"
    },
    "sortOrder": 1090
  },
  "🐇": {
    "glyph": "🐇",
    "group": "Animals & Nature",
    "keywords": [
      "bunny",
      "pet",
      "rabbit"
    ],
    "dirName": "Rabbit",
    "styles": {
      "3D": "rabbit_3d.png",
      "Animated": "Rabbit.png"
    },
    "sortOrder": 579
  },
  "🐰": {
    "glyph": "🐰",
    "group": "Animals & Nature",
    "keywords": [
      "bunny",
      "face",
      "pet",
      "rabbit"
    ],
    "dirName": "Rabbit face",
    "styles": {
      "3D": "rabbit_face_3d.png",
      "Animated": "Rabbit Face.png"
    },
    "sortOrder": 578
  },
  "🦝": {
    "glyph": "🦝",
    "group": "Animals & Nature",
    "keywords": [
      "curious",
      "raccoon",
      "sly"
    ],
    "dirName": "Raccoon",
    "styles": {
      "3D": "raccoon_3d.png",
      "Animated": "Raccoon.png"
    },
    "sortOrder": 541
  },
  "🏎️": {
    "glyph": "🏎️",
    "group": "Travel & Places",
    "keywords": [
      "car",
      "racing"
    ],
    "dirName": "Racing car",
    "styles": {
      "3D": "racing_car_3d.png",
      "Animated": "Racing Car.png"
    },
    "sortOrder": 901
  },
  "📻": {
    "glyph": "📻",
    "group": "Objects",
    "keywords": [
      "radio",
      "video"
    ],
    "dirName": "Radio",
    "styles": {
      "3D": "radio_3d.png",
      "Animated": "Radio.png"
    },
    "sortOrder": 1172
  },
  "🔘": {
    "glyph": "🔘",
    "group": "Symbols",
    "keywords": [
      "button",
      "geometric",
      "radio"
    ],
    "dirName": "Radio button",
    "styles": {
      "3D": "radio_button_3d.png",
      "Animated": "Radio Button.png"
    },
    "sortOrder": 1583
  },
  "☢️": {
    "glyph": "☢️",
    "group": "Symbols",
    "keywords": [
      "radioactive",
      "sign"
    ],
    "dirName": "Radioactive",
    "styles": {
      "3D": "radioactive_3d.png",
      "Animated": "Radioactive.png"
    },
    "sortOrder": 1389
  },
  "🚃": {
    "glyph": "🚃",
    "group": "Travel & Places",
    "keywords": [
      "car",
      "electric",
      "railway",
      "train",
      "tram",
      "trolleybus"
    ],
    "dirName": "Railway car",
    "styles": {
      "3D": "railway_car_3d.png",
      "Animated": "Railway Car.png"
    },
    "sortOrder": 873
  },
  "🛤️": {
    "glyph": "🛤️",
    "group": "Travel & Places",
    "keywords": [
      "railway",
      "railway track",
      "train"
    ],
    "dirName": "Railway track",
    "styles": {
      "3D": "railway_track_3d.png",
      "Animated": "Railway Track.png"
    },
    "sortOrder": 913
  },
  "🌈": {
    "glyph": "🌈",
    "group": "Travel & Places",
    "keywords": [
      "rain",
      "rainbow"
    ],
    "dirName": "Rainbow",
    "styles": {
      "3D": "rainbow_3d.png",
      "Animated": "Rainbow.png"
    },
    "sortOrder": 1011
  },
  "🏳️‍🌈": {
    "glyph": "🏳️‍🌈",
    "group": "Flags",
    "keywords": [
      "pride",
      "rainbow",
      "rainbow flag"
    ],
    "dirName": "Rainbow flag",
    "styles": {
      "3D": "rainbow_flag_3d.png"
    },
    "sortOrder": 1591
  },
  "🤚": {
    "glyph": "🤚",
    "group": "People & Body",
    "keywords": [
      "backhand",
      "raised",
      "raised back of hand"
    ],
    "dirName": "Raised back of hand",
    "skintones": {
      "Default": {
        "3D": "raised_back_of_hand_3d_default.png",
        "Animated": "Raised Back of Hand.png"
      }
    },
    "sortOrder": 165
  },
  "✊": {
    "glyph": "✊",
    "group": "People & Body",
    "keywords": [
      "clenched",
      "fist",
      "hand",
      "punch",
      "raised fist"
    ],
    "dirName": "Raised fist",
    "skintones": {
      "Default": {
        "3D": "raised_fist_3d_default.png",
        "Animated": "Raised Fist.png"
      }
    },
    "sortOrder": 191
  },
  "✋": {
    "glyph": "✋",
    "group": "People & Body",
    "keywords": [
      "hand",
      "high 5",
      "high five",
      "raised hand"
    ],
    "dirName": "Raised hand",
    "skintones": {
      "Default": {
        "3D": "raised_hand_3d_default.png",
        "Animated": "Raised Hand.png"
      }
    },
    "sortOrder": 167
  },
  "🙌": {
    "glyph": "🙌",
    "group": "People & Body",
    "keywords": [
      "celebration",
      "gesture",
      "hand",
      "hooray",
      "raised",
      "raising hands"
    ],
    "dirName": "Raising hands",
    "skintones": {
      "Default": {
        "3D": "raising_hands_3d_default.png",
        "Animated": "Raising Hands.png"
      }
    },
    "sortOrder": 196
  },
  "🐏": {
    "glyph": "🐏",
    "group": "Animals & Nature",
    "keywords": [
      "Aries",
      "male",
      "ram",
      "sheep",
      "zodiac"
    ],
    "dirName": "Ram",
    "styles": {
      "3D": "ram_3d.png",
      "Animated": "Ram.png"
    },
    "sortOrder": 563
  },
  "🐀": {
    "glyph": "🐀",
    "group": "Animals & Nature",
    "keywords": [
      "rat"
    ],
    "dirName": "Rat",
    "styles": {
      "3D": "rat_3d.png",
      "Animated": "Rat.png"
    },
    "sortOrder": 576
  },
  "🪒": {
    "glyph": "🪒",
    "group": "Objects",
    "keywords": [
      "razor",
      "sharp",
      "shave"
    ],
    "dirName": "Razor",
    "styles": {
      "3D": "razor_3d.png",
      "Animated": "Razor.png"
    },
    "sortOrder": 1345
  },
  "🧾": {
    "glyph": "🧾",
    "group": "Objects",
    "keywords": [
      "accounting",
      "bookkeeping",
      "evidence",
      "proof",
      "receipt"
    ],
    "dirName": "Receipt",
    "styles": {
      "3D": "receipt_3d.png",
      "Animated": "Receipt.png"
    },
    "sortOrder": 1243
  },
  "⏺️": {
    "glyph": "⏺️",
    "group": "Symbols",
    "keywords": [
      "circle",
      "record",
      "record button"
    ],
    "dirName": "Record button",
    "styles": {
      "3D": "record_button_3d.png",
      "Animated": "Record Button.png"
    },
    "sortOrder": 1453
  },
  "♻️": {
    "glyph": "♻️",
    "group": "Symbols",
    "keywords": [
      "recycle",
      "recycling symbol"
    ],
    "dirName": "Recycling symbol",
    "styles": {
      "3D": "recycling_symbol_3d.png",
      "Animated": "Recycling Symbol.png"
    },
    "sortOrder": 1480
  },
  "🍎": {
    "glyph": "🍎",
    "group": "Food & Drink",
    "keywords": [
      "apple",
      "fruit",
      "red"
    ],
    "dirName": "Red apple",
    "styles": {
      "3D": "red_apple_3d.png",
      "Animated": "Red Apple.png"
    },
    "sortOrder": 682
  },
  "🔴": {
    "glyph": "🔴",
    "group": "Symbols",
    "keywords": [
      "circle",
      "geometric",
      "red"
    ],
    "dirName": "Red circle",
    "styles": {
      "3D": "red_circle_3d.png",
      "Animated": "Red Circle.png"
    },
    "sortOrder": 1552
  },
  "🧧": {
    "glyph": "🧧",
    "group": "Activities",
    "keywords": [
      "gift",
      "good luck",
      "hóngbāo",
      "lai see",
      "money",
      "red envelope"
    ],
    "dirName": "Red envelope",
    "styles": {
      "3D": "red_envelope_3d.png",
      "Animated": "Red Envelope.png"
    },
    "sortOrder": 1039
  },
  "❗": {
    "glyph": "❗",
    "group": "Symbols",
    "keywords": [
      "!",
      "exclamation",
      "mark",
      "punctuation",
      "red exclamation mark"
    ],
    "dirName": "Red exclamation mark",
    "styles": {
      "3D": "red_exclamation_mark_3d.png",
      "Animated": "Red Exclamation Mark.png"
    },
    "sortOrder": 1475
  },
  "❤️": {
    "glyph": "❤️",
    "group": "Smileys & Emotion",
    "keywords": [
      "heart",
      "red heart"
    ],
    "dirName": "Red heart",
    "styles": {
      "3D": "red_heart_3d.png",
      "Animated": "Red Heart.png"
    },
    "sortOrder": 141
  },
  "🏮": {
    "glyph": "🏮",
    "group": "Objects",
    "keywords": [
      "bar",
      "lantern",
      "light",
      "red",
      "red paper lantern"
    ],
    "dirName": "Red paper lantern",
    "styles": {
      "3D": "red_paper_lantern_3d.png",
      "Animated": "Red Paper Lantern.png"
    },
    "sortOrder": 1216
  },
  "❓": {
    "glyph": "❓",
    "group": "Symbols",
    "keywords": [
      "?",
      "mark",
      "punctuation",
      "question",
      "red question mark"
    ],
    "dirName": "Red question mark",
    "styles": {
      "3D": "red_question_mark_3d.png",
      "Animated": "Red Question Mark.png"
    },
    "sortOrder": 1472
  },
  "🟥": {
    "glyph": "🟥",
    "group": "Symbols",
    "keywords": [
      "red",
      "square"
    ],
    "dirName": "Red square",
    "styles": {
      "3D": "red_square_3d.png",
      "Animated": "Red Square.png"
    },
    "sortOrder": 1561
  },
  "🔺": {
    "glyph": "🔺",
    "group": "Symbols",
    "keywords": [
      "geometric",
      "red",
      "red triangle pointed up"
    ],
    "dirName": "Red triangle",
    "styles": {
      "3D": "red_triangle_3d.png",
      "Animated": "Red Triangle Pointed Up.png"
    },
    "sortOrder": 1580
  },
  "🔻": {
    "glyph": "🔻",
    "group": "Symbols",
    "keywords": [
      "down",
      "geometric",
      "red",
      "red triangle pointed down"
    ],
    "dirName": "Red triangle pointed down",
    "styles": {
      "3D": "red_triangle_pointed_down_3d.png",
      "Animated": "Red Triangle Pointed Down.png"
    },
    "sortOrder": 1581
  },
  "®️": {
    "glyph": "®️",
    "group": "Symbols",
    "keywords": [
      "r",
      "registered"
    ],
    "dirName": "Registered",
    "styles": {
      "3D": "registered_3d.png",
      "Animated": "Registered.png"
    },
    "sortOrder": 1498
  },
  "😌": {
    "glyph": "😌",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "relieved"
    ],
    "dirName": "Relieved face",
    "styles": {
      "3D": "relieved_face_3d.png",
      "Animated": "Relieved Face.png"
    },
    "sortOrder": 50
  },
  "🎗️": {
    "glyph": "🎗️",
    "group": "Activities",
    "keywords": [
      "celebration",
      "reminder",
      "ribbon"
    ],
    "dirName": "Reminder ribbon",
    "styles": {
      "3D": "reminder_ribbon_3d.png",
      "Animated": "Reminder Ribbon.png"
    },
    "sortOrder": 1042
  },
  "🔁": {
    "glyph": "🔁",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "clockwise",
      "repeat",
      "repeat button"
    ],
    "dirName": "Repeat button",
    "styles": {
      "3D": "repeat_button_3d.png",
      "Animated": "Repeat Button.png"
    },
    "sortOrder": 1438
  },
  "🔂": {
    "glyph": "🔂",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "clockwise",
      "once",
      "repeat single button"
    ],
    "dirName": "Repeat single button",
    "styles": {
      "3D": "repeat_single_button_3d.png",
      "Animated": "Repeat Single Button.png"
    },
    "sortOrder": 1439
  },
  "⛑️": {
    "glyph": "⛑️",
    "group": "Objects",
    "keywords": [
      "aid",
      "cross",
      "face",
      "hat",
      "helmet",
      "rescue worker’s helmet"
    ],
    "dirName": "Rescue workers helmet",
    "styles": {
      "3D": "rescue_workers_helmet_3d.png",
      "Animated": "Rescue Worker’s Helmet.png"
    },
    "sortOrder": 1150
  },
  "🚻": {
    "glyph": "🚻",
    "group": "Symbols",
    "keywords": [
      "lavatory",
      "restroom",
      "WC"
    ],
    "dirName": "Restroom",
    "styles": {
      "3D": "restroom_3d.png",
      "Animated": "Restroom.png"
    },
    "sortOrder": 1371
  },
  "◀️": {
    "glyph": "◀️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "left",
      "reverse",
      "reverse button",
      "triangle"
    ],
    "dirName": "Reverse button",
    "styles": {
      "3D": "reverse_button_3d.png",
      "Animated": "Reverse Button.png"
    },
    "sortOrder": 1444
  },
  "💞": {
    "glyph": "💞",
    "group": "Smileys & Emotion",
    "keywords": [
      "revolving",
      "revolving hearts"
    ],
    "dirName": "Revolving hearts",
    "styles": {
      "3D": "revolving_hearts_3d.png",
      "Animated": "Revolving Hearts.png"
    },
    "sortOrder": 134
  },
  "🦏": {
    "glyph": "🦏",
    "group": "Animals & Nature",
    "keywords": [
      "rhinoceros"
    ],
    "dirName": "Rhinoceros",
    "styles": {
      "3D": "rhinoceros_3d.png",
      "Animated": "Rhinoceros.png"
    },
    "sortOrder": 572
  },
  "🎀": {
    "glyph": "🎀",
    "group": "Activities",
    "keywords": [
      "celebration",
      "ribbon"
    ],
    "dirName": "Ribbon",
    "styles": {
      "3D": "ribbon_3d.png",
      "Animated": "Ribbon.png"
    },
    "sortOrder": 1040
  },
  "🍙": {
    "glyph": "🍙",
    "group": "Food & Drink",
    "keywords": [
      "ball",
      "Japanese",
      "rice"
    ],
    "dirName": "Rice ball",
    "styles": {
      "3D": "rice_ball_3d.png",
      "Animated": "Rice Ball.png"
    },
    "sortOrder": 745
  },
  "🍘": {
    "glyph": "🍘",
    "group": "Food & Drink",
    "keywords": [
      "cracker",
      "rice"
    ],
    "dirName": "Rice cracker",
    "styles": {
      "3D": "rice_cracker_3d.png",
      "Animated": "Rice Cracker.png"
    },
    "sortOrder": 744
  },
  "🗯️": {
    "glyph": "🗯️",
    "group": "Smileys & Emotion",
    "keywords": [
      "angry",
      "balloon",
      "bubble",
      "mad",
      "right anger bubble"
    ],
    "dirName": "Right anger bubble",
    "styles": {
      "3D": "right_anger_bubble_3d.png",
      "Animated": "Right Anger Bubble.png"
    },
    "sortOrder": 161
  },
  "➡️": {
    "glyph": "➡️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "cardinal",
      "direction",
      "east",
      "right arrow"
    ],
    "dirName": "Right arrow",
    "styles": {
      "3D": "right_arrow_3d.png",
      "Animated": "Right Arrow.png"
    },
    "sortOrder": 1393
  },
  "⤵️": {
    "glyph": "⤵️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "down",
      "right arrow curving down"
    ],
    "dirName": "Right arrow curving down",
    "styles": {
      "3D": "right_arrow_curving_down_3d.png",
      "Animated": "Right Arrow Curving Down.png"
    },
    "sortOrder": 1404
  },
  "↩️": {
    "glyph": "↩️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "right arrow curving left"
    ],
    "dirName": "Right arrow curving left",
    "styles": {
      "3D": "right_arrow_curving_left_3d.png",
      "Animated": "Right Arrow Curving Left.png"
    },
    "sortOrder": 1401
  },
  "⤴️": {
    "glyph": "⤴️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "right arrow curving up"
    ],
    "dirName": "Right arrow curving up",
    "styles": {
      "3D": "right_arrow_curving_up_3d.png",
      "Animated": "Right Arrow Curving Up.png"
    },
    "sortOrder": 1403
  },
  "🤜": {
    "glyph": "🤜",
    "group": "People & Body",
    "keywords": [
      "fist",
      "right-facing fist",
      "rightwards"
    ],
    "dirName": "Right-facing fist",
    "skintones": {
      "Default": {
        "3D": "right-facing_fist_3d_default.png",
        "Animated": "Right-Facing Fist.png"
      }
    },
    "sortOrder": 194
  },
  "🫱": {
    "glyph": "🫱",
    "group": "People & Body",
    "keywords": [
      "hand",
      "right",
      "rightward",
      "rightwards hand"
    ],
    "dirName": "Rightwards hand",
    "skintones": {
      "Default": {
        "3D": "rightwards_hand_3d_default.png",
        "Animated": "Rightwards Hand.png"
      }
    },
    "sortOrder": 169
  },
  "💍": {
    "glyph": "💍",
    "group": "Objects",
    "keywords": [
      "diamond",
      "ring"
    ],
    "dirName": "Ring",
    "styles": {
      "3D": "ring_3d.png",
      "Animated": "Ring.png"
    },
    "sortOrder": 1153
  },
  "🛟": {
    "glyph": "🛟",
    "group": "Travel & Places",
    "keywords": [
      "float",
      "life preserver",
      "life saver",
      "rescue",
      "ring buoy",
      "safety"
    ],
    "dirName": "Ring buoy",
    "styles": {
      "3D": "ring_buoy_3d.png",
      "Animated": "Ring Buoy.png"
    },
    "sortOrder": 923
  },
  "🪐": {
    "glyph": "🪐",
    "group": "Travel & Places",
    "keywords": [
      "ringed planet",
      "saturn",
      "saturnine"
    ],
    "dirName": "Ringed planet",
    "styles": {
      "3D": "ringed_planet_3d.png",
      "Animated": "Ringed Planet.png"
    },
    "sortOrder": 993
  },
  "🍠": {
    "glyph": "🍠",
    "group": "Food & Drink",
    "keywords": [
      "potato",
      "roasted",
      "sweet"
    ],
    "dirName": "Roasted sweet potato",
    "styles": {
      "3D": "roasted_sweet_potato_3d.png",
      "Animated": "Roasted Sweet Potato.png"
    },
    "sortOrder": 750
  },
  "🤖": {
    "glyph": "🤖",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "monster",
      "robot"
    ],
    "dirName": "Robot",
    "styles": {
      "3D": "robot_3d.png",
      "Animated": "Robot.png"
    },
    "sortOrder": 114
  },
  "🪨": {
    "glyph": "🪨",
    "group": "Travel & Places",
    "keywords": [
      "boulder",
      "heavy",
      "rock",
      "solid",
      "stone"
    ],
    "dirName": "Rock",
    "styles": {
      "3D": "rock_3d.png",
      "Animated": "Rock.png"
    },
    "sortOrder": 826
  },
  "🚀": {
    "glyph": "🚀",
    "group": "Travel & Places",
    "keywords": [
      "rocket",
      "space"
    ],
    "dirName": "Rocket",
    "styles": {
      "3D": "rocket_3d.png",
      "Animated": "Rocket.png"
    },
    "sortOrder": 942
  },
  "🧻": {
    "glyph": "🧻",
    "group": "Objects",
    "keywords": [
      "paper towels",
      "roll of paper",
      "toilet paper"
    ],
    "dirName": "Roll of paper",
    "styles": {
      "3D": "roll_of_paper_3d.png",
      "Animated": "Roll of Paper.png"
    },
    "sortOrder": 1350
  },
  "🗞️": {
    "glyph": "🗞️",
    "group": "Objects",
    "keywords": [
      "news",
      "newspaper",
      "paper",
      "rolled",
      "rolled-up newspaper"
    ],
    "dirName": "Rolled-up newspaper",
    "styles": {
      "3D": "rolled-up_newspaper_3d.png",
      "Animated": "Rolled-Up Newspaper.png"
    },
    "sortOrder": 1231
  },
  "🎢": {
    "glyph": "🎢",
    "group": "Travel & Places",
    "keywords": [
      "amusement park",
      "coaster",
      "roller"
    ],
    "dirName": "Roller coaster",
    "styles": {
      "3D": "roller_coaster_3d.png",
      "Animated": "Roller Coaster.png"
    },
    "sortOrder": 869
  },
  "🛼": {
    "glyph": "🛼",
    "group": "Travel & Places",
    "keywords": [
      "roller",
      "skate"
    ],
    "dirName": "Roller skate",
    "styles": {
      "3D": "roller_skate_3d.png",
      "Animated": "Roller Skate.png"
    },
    "sortOrder": 910
  },
  "🤣": {
    "glyph": "🤣",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "floor",
      "laugh",
      "rofl",
      "rolling",
      "rolling on the floor laughing",
      "rotfl"
    ],
    "dirName": "Rolling on the floor laughing",
    "styles": {
      "3D": "rolling_on_the_floor_laughing_3d.png",
      "Animated": "Rolling on the Floor Laughing.png"
    },
    "sortOrder": 7
  },
  "🐓": {
    "glyph": "🐓",
    "group": "Animals & Nature",
    "keywords": [
      "bird",
      "rooster"
    ],
    "dirName": "Rooster",
    "styles": {
      "3D": "rooster_3d.png",
      "Animated": "Rooster.png"
    },
    "sortOrder": 596
  },
  "🌹": {
    "glyph": "🌹",
    "group": "Animals & Nature",
    "keywords": [
      "flower",
      "rose"
    ],
    "dirName": "Rose",
    "styles": {
      "3D": "rose_3d.png",
      "Animated": "Rose.png"
    },
    "sortOrder": 653
  },
  "🏵️": {
    "glyph": "🏵️",
    "group": "Animals & Nature",
    "keywords": [
      "plant",
      "rosette"
    ],
    "dirName": "Rosette",
    "styles": {
      "3D": "rosette_3d.png",
      "Animated": "Rosette.png"
    },
    "sortOrder": 652
  },
  "📍": {
    "glyph": "📍",
    "group": "Objects",
    "keywords": [
      "pin",
      "pushpin",
      "round pushpin"
    ],
    "dirName": "Round pushpin",
    "styles": {
      "3D": "round_pushpin_3d.png",
      "Animated": "Round Pushpin.png"
    },
    "sortOrder": 1279
  },
  "🏉": {
    "glyph": "🏉",
    "group": "Activities",
    "keywords": [
      "ball",
      "football",
      "rugby"
    ],
    "dirName": "Rugby football",
    "styles": {
      "3D": "rugby_football_3d.png",
      "Animated": "Rugby Football.png"
    },
    "sortOrder": 1057
  },
  "🎽": {
    "glyph": "🎽",
    "group": "Activities",
    "keywords": [
      "athletics",
      "running",
      "sash",
      "shirt"
    ],
    "dirName": "Running shirt",
    "styles": {
      "3D": "running_shirt_3d.png",
      "Animated": "Running Shirt.png"
    },
    "sortOrder": 1074
  },
  "👟": {
    "glyph": "👟",
    "group": "Objects",
    "keywords": [
      "athletic",
      "clothing",
      "running shoe",
      "shoe",
      "sneaker"
    ],
    "dirName": "Running shoe",
    "styles": {
      "3D": "running_shoe_3d.png",
      "Animated": "Running Shoe.png"
    },
    "sortOrder": 1137
  },
  "😥": {
    "glyph": "😥",
    "group": "Smileys & Emotion",
    "keywords": [
      "disappointed",
      "face",
      "relieved",
      "sad but relieved face",
      "whew"
    ],
    "dirName": "Sad but relieved face",
    "styles": {
      "3D": "sad_but_relieved_face_3d.png",
      "Animated": "Sad but Relieved Face.png"
    },
    "sortOrder": 88
  },
  "🧷": {
    "glyph": "🧷",
    "group": "Objects",
    "keywords": [
      "diaper",
      "punk rock",
      "safety pin"
    ],
    "dirName": "Safety pin",
    "styles": {
      "3D": "safety_pin_3d.png",
      "Animated": "Safety Pin.png"
    },
    "sortOrder": 1347
  },
  "🦺": {
    "glyph": "🦺",
    "group": "Objects",
    "keywords": [
      "emergency",
      "safety",
      "vest"
    ],
    "dirName": "Safety vest",
    "styles": {
      "3D": "safety_vest_3d.png",
      "Animated": "Safety Vest.png"
    },
    "sortOrder": 1114
  },
  "♐": {
    "glyph": "♐",
    "group": "Symbols",
    "keywords": [
      "archer",
      "Sagittarius",
      "zodiac"
    ],
    "dirName": "Sagittarius",
    "styles": {
      "3D": "sagittarius_3d.png",
      "Animated": "Sagittarius.png"
    },
    "sortOrder": 1432
  },
  "⛵": {
    "glyph": "⛵",
    "group": "Travel & Places",
    "keywords": [
      "boat",
      "resort",
      "sailboat",
      "sea",
      "yacht"
    ],
    "dirName": "Sailboat",
    "styles": {
      "3D": "sailboat_3d.png",
      "Animated": "Sailboat.png"
    },
    "sortOrder": 924
  },
  "🍶": {
    "glyph": "🍶",
    "group": "Food & Drink",
    "keywords": [
      "bar",
      "beverage",
      "bottle",
      "cup",
      "drink",
      "sake"
    ],
    "dirName": "Sake",
    "styles": {
      "3D": "sake_3d.png",
      "Animated": "Sake.png"
    },
    "sortOrder": 784
  },
  "🧂": {
    "glyph": "🧂",
    "group": "Food & Drink",
    "keywords": [
      "condiment",
      "salt",
      "shaker"
    ],
    "dirName": "Salt",
    "styles": {
      "3D": "salt_3d.png",
      "Animated": "Salt.png"
    },
    "sortOrder": 741
  },
  "🫡": {
    "glyph": "🫡",
    "group": "Smileys & Emotion",
    "keywords": [
      "OK",
      "salute",
      "saluting face",
      "sunny",
      "troops",
      "yes"
    ],
    "dirName": "Saluting face",
    "styles": {
      "3D": "saluting_face_3d.png",
      "Animated": "Saluting Face.png"
    },
    "sortOrder": 36
  },
  "🥪": {
    "glyph": "🥪",
    "group": "Food & Drink",
    "keywords": [
      "bread",
      "sandwich"
    ],
    "dirName": "Sandwich",
    "styles": {
      "3D": "sandwich_3d.png",
      "Animated": "Sandwich.png"
    },
    "sortOrder": 726
  },
  "🎅": {
    "glyph": "🎅",
    "group": "People & Body",
    "keywords": [
      "celebration",
      "Christmas",
      "claus",
      "father",
      "santa",
      "Santa Claus"
    ],
    "dirName": "Santa claus",
    "skintones": {
      "Default": {
        "3D": "santa_claus_3d_default.png",
        "Animated": "Santa Claus.png"
      }
    },
    "sortOrder": 364
  },
  "🥻": {
    "glyph": "🥻",
    "group": "Objects",
    "keywords": [
      "clothing",
      "dress",
      "sari"
    ],
    "dirName": "Sari",
    "styles": {
      "3D": "sari_3d.png",
      "Animated": "Sari.png"
    },
    "sortOrder": 1124
  },
  "🛰️": {
    "glyph": "🛰️",
    "group": "Travel & Places",
    "keywords": [
      "satellite",
      "space"
    ],
    "dirName": "Satellite",
    "styles": {
      "3D": "satellite_3d.png",
      "Animated": "Satellite.png"
    },
    "sortOrder": 941
  },
  "📡": {
    "glyph": "📡",
    "group": "Objects",
    "keywords": [
      "antenna",
      "dish",
      "satellite"
    ],
    "dirName": "Satellite antenna",
    "styles": {
      "3D": "satellite_antenna_3d.png",
      "Animated": "Satellite Antenna.png"
    },
    "sortOrder": 1325
  },
  "🦕": {
    "glyph": "🦕",
    "group": "Animals & Nature",
    "keywords": [
      "brachiosaurus",
      "brontosaurus",
      "diplodocus",
      "sauropod"
    ],
    "dirName": "Sauropod",
    "styles": {
      "3D": "sauropod_3d.png",
      "Animated": "Sauropod.png"
    },
    "sortOrder": 619
  },
  "🎷": {
    "glyph": "🎷",
    "group": "Objects",
    "keywords": [
      "instrument",
      "music",
      "sax",
      "saxophone"
    ],
    "dirName": "Saxophone",
    "styles": {
      "3D": "saxophone_3d.png",
      "Animated": "Saxophone.png"
    },
    "sortOrder": 1173
  },
  "🧣": {
    "glyph": "🧣",
    "group": "Objects",
    "keywords": [
      "neck",
      "scarf"
    ],
    "dirName": "Scarf",
    "styles": {
      "3D": "scarf_3d.png",
      "Animated": "Scarf.png"
    },
    "sortOrder": 1118
  },
  "🏫": {
    "glyph": "🏫",
    "group": "Travel & Places",
    "keywords": [
      "building",
      "school"
    ],
    "dirName": "School",
    "styles": {
      "3D": "school_3d.png",
      "Animated": "School.png"
    },
    "sortOrder": 841
  },
  "🧑‍🔬": {
    "glyph": "🧑‍🔬",
    "group": "People & Body",
    "keywords": [
      "biologist",
      "chemist",
      "engineer",
      "physicist",
      "scientist"
    ],
    "dirName": "Scientist",
    "skintones": {
      "Default": {
        "3D": "scientist_3d_default.png",
        "Animated": "Scientist.png"
      }
    },
    "sortOrder": 308
  },
  "✂️": {
    "glyph": "✂️",
    "group": "Objects",
    "keywords": [
      "cutting",
      "scissors",
      "tool"
    ],
    "dirName": "Scissors",
    "styles": {
      "3D": "scissors_3d.png",
      "Animated": "Scissors.png"
    },
    "sortOrder": 1284
  },
  "♏": {
    "glyph": "♏",
    "group": "Symbols",
    "keywords": [
      "Scorpio",
      "scorpion",
      "scorpius",
      "zodiac"
    ],
    "dirName": "Scorpio",
    "styles": {
      "3D": "scorpio_3d.png",
      "Animated": "Scorpio.png"
    },
    "sortOrder": 1431
  },
  "🦂": {
    "glyph": "🦂",
    "group": "Animals & Nature",
    "keywords": [
      "scorpio",
      "Scorpio",
      "scorpion",
      "zodiac"
    ],
    "dirName": "Scorpion",
    "styles": {
      "3D": "scorpion_3d.png",
      "Animated": "Scorpion.png"
    },
    "sortOrder": 643
  },
  "🪛": {
    "glyph": "🪛",
    "group": "Objects",
    "keywords": [
      "screw",
      "screwdriver",
      "tool"
    ],
    "dirName": "Screwdriver",
    "styles": {
      "3D": "screwdriver_3d.png",
      "Animated": "Screwdriver.png"
    },
    "sortOrder": 1307
  },
  "📜": {
    "glyph": "📜",
    "group": "Objects",
    "keywords": [
      "paper",
      "scroll"
    ],
    "dirName": "Scroll",
    "styles": {
      "3D": "scroll_3d.png",
      "Animated": "Scroll.png"
    },
    "sortOrder": 1228
  },
  "🦭": {
    "glyph": "🦭",
    "group": "Animals & Nature",
    "keywords": [
      "sea lion",
      "seal"
    ],
    "dirName": "Seal",
    "styles": {
      "3D": "seal_3d.png",
      "Animated": "Seal.png"
    },
    "sortOrder": 624
  },
  "💺": {
    "glyph": "💺",
    "group": "Travel & Places",
    "keywords": [
      "chair",
      "seat"
    ],
    "dirName": "Seat",
    "styles": {
      "3D": "seat_3d.png",
      "Animated": "Seat.png"
    },
    "sortOrder": 936
  },
  "🙈": {
    "glyph": "🙈",
    "group": "Smileys & Emotion",
    "keywords": [
      "evil",
      "face",
      "forbidden",
      "monkey",
      "see",
      "see-no-evil monkey"
    ],
    "dirName": "See-no-evil monkey",
    "styles": {
      "3D": "see-no-evil_monkey_3d.png",
      "Animated": "See-No-Evil Monkey.png"
    },
    "sortOrder": 124
  },
  "🌱": {
    "glyph": "🌱",
    "group": "Animals & Nature",
    "keywords": [
      "seedling",
      "young"
    ],
    "dirName": "Seedling",
    "styles": {
      "3D": "seedling_3d.png",
      "Animated": "Seedling.png"
    },
    "sortOrder": 659
  },
  "🤳": {
    "glyph": "🤳",
    "group": "People & Body",
    "keywords": [
      "camera",
      "phone",
      "selfie"
    ],
    "dirName": "Selfie",
    "skintones": {
      "Default": {
        "3D": "selfie_3d_default.png",
        "Animated": "Selfie.png"
      }
    },
    "sortOrder": 204
  },
  "🐕‍🦺": {
    "glyph": "🐕‍🦺",
    "group": "Animals & Nature",
    "keywords": [
      "accessibility",
      "assistance",
      "dog",
      "service"
    ],
    "dirName": "Service dog",
    "styles": {
      "3D": "service_dog_3d.png",
      "Animated": "Service Dog.png"
    },
    "sortOrder": 537
  },
  "🕖": {
    "glyph": "🕖",
    "group": "Travel & Places",
    "keywords": [
      "00",
      "7",
      "7:00",
      "clock",
      "o’clock",
      "seven"
    ],
    "dirName": "Seven oclock",
    "styles": {
      "3D": "seven_oclock_3d.png",
      "Animated": "Seven O’Clock.png"
    },
    "sortOrder": 967
  },
  "🕢": {
    "glyph": "🕢",
    "group": "Travel & Places",
    "keywords": [
      "7",
      "7:30",
      "clock",
      "seven",
      "seven-thirty",
      "thirty"
    ],
    "dirName": "Seven-thirty",
    "styles": {
      "3D": "seven-thirty_3d.png",
      "Animated": "Seven-Thirty.png"
    },
    "sortOrder": 968
  },
  "🪡": {
    "glyph": "🪡",
    "group": "Activities",
    "keywords": [
      "embroidery",
      "needle",
      "sewing",
      "stitches",
      "sutures",
      "tailoring"
    ],
    "dirName": "Sewing needle",
    "styles": {
      "3D": "sewing_needle_3d.png",
      "Animated": "Sewing Needle.png"
    },
    "sortOrder": 1107
  },
  "🥘": {
    "glyph": "🥘",
    "group": "Food & Drink",
    "keywords": [
      "casserole",
      "food",
      "paella",
      "pan",
      "shallow",
      "shallow pan of food"
    ],
    "dirName": "Shallow pan of food",
    "styles": {
      "3D": "shallow_pan_of_food_3d.png",
      "Animated": "Shallow Pan of Food.png"
    },
    "sortOrder": 734
  },
  "☘️": {
    "glyph": "☘️",
    "group": "Animals & Nature",
    "keywords": [
      "plant",
      "shamrock"
    ],
    "dirName": "Shamrock",
    "styles": {
      "3D": "shamrock_3d.png",
      "Animated": "Shamrock.png"
    },
    "sortOrder": 667
  },
  "🦈": {
    "glyph": "🦈",
    "group": "Animals & Nature",
    "keywords": [
      "fish",
      "shark"
    ],
    "dirName": "Shark",
    "styles": {
      "3D": "shark_3d.png",
      "Animated": "Shark.png"
    },
    "sortOrder": 628
  },
  "🍧": {
    "glyph": "🍧",
    "group": "Food & Drink",
    "keywords": [
      "dessert",
      "ice",
      "shaved",
      "sweet"
    ],
    "dirName": "Shaved ice",
    "styles": {
      "3D": "shaved_ice_3d.png",
      "Animated": "Shaved Ice.png"
    },
    "sortOrder": 766
  },
  "🌾": {
    "glyph": "🌾",
    "group": "Animals & Nature",
    "keywords": [
      "ear",
      "grain",
      "rice",
      "sheaf of rice"
    ],
    "dirName": "Sheaf of rice",
    "styles": {
      "3D": "sheaf_of_rice_3d.png",
      "Animated": "Sheaf of Rice.png"
    },
    "sortOrder": 665
  },
  "🛡️": {
    "glyph": "🛡️",
    "group": "Objects",
    "keywords": [
      "shield",
      "weapon"
    ],
    "dirName": "Shield",
    "styles": {
      "3D": "shield_3d.png",
      "Animated": "Shield.png"
    },
    "sortOrder": 1304
  },
  "⛩️": {
    "glyph": "⛩️",
    "group": "Travel & Places",
    "keywords": [
      "religion",
      "shinto",
      "shrine"
    ],
    "dirName": "Shinto shrine",
    "styles": {
      "3D": "shinto_shrine_3d.png",
      "Animated": "Shinto Shrine.png"
    },
    "sortOrder": 853
  },
  "🚢": {
    "glyph": "🚢",
    "group": "Travel & Places",
    "keywords": [
      "boat",
      "passenger",
      "ship"
    ],
    "dirName": "Ship",
    "styles": {
      "3D": "ship_3d.png",
      "Animated": "Ship.png"
    },
    "sortOrder": 930
  },
  "🌠": {
    "glyph": "🌠",
    "group": "Travel & Places",
    "keywords": [
      "falling",
      "shooting",
      "star"
    ],
    "dirName": "Shooting star",
    "styles": {
      "3D": "shooting_star_3d.png",
      "Animated": "Shooting Star.png"
    },
    "sortOrder": 996
  },
  "🛍️": {
    "glyph": "🛍️",
    "group": "Objects",
    "keywords": [
      "bag",
      "hotel",
      "shopping",
      "shopping bags"
    ],
    "dirName": "Shopping bags",
    "styles": {
      "3D": "shopping_bags_3d.png",
      "Animated": "Shopping Bags.png"
    },
    "sortOrder": 1133
  },
  "🛒": {
    "glyph": "🛒",
    "group": "Objects",
    "keywords": [
      "cart",
      "shopping",
      "trolley"
    ],
    "dirName": "Shopping cart",
    "styles": {
      "3D": "shopping_cart_3d.png",
      "Animated": "Shopping Cart.png"
    },
    "sortOrder": 1357
  },
  "🍰": {
    "glyph": "🍰",
    "group": "Food & Drink",
    "keywords": [
      "cake",
      "dessert",
      "pastry",
      "shortcake",
      "slice",
      "sweet"
    ],
    "dirName": "Shortcake",
    "styles": {
      "3D": "shortcake_3d.png",
      "Animated": "Shortcake.png"
    },
    "sortOrder": 771
  },
  "🩳": {
    "glyph": "🩳",
    "group": "Objects",
    "keywords": [
      "bathing suit",
      "pants",
      "shorts",
      "underwear"
    ],
    "dirName": "Shorts",
    "styles": {
      "3D": "shorts_3d.png",
      "Animated": "Shorts.png"
    },
    "sortOrder": 1127
  },
  "🚿": {
    "glyph": "🚿",
    "group": "Objects",
    "keywords": [
      "shower",
      "water"
    ],
    "dirName": "Shower",
    "styles": {
      "3D": "shower_3d.png",
      "Animated": "Shower.png"
    },
    "sortOrder": 1342
  },
  "🦐": {
    "glyph": "🦐",
    "group": "Food & Drink",
    "keywords": [
      "food",
      "shellfish",
      "shrimp",
      "small"
    ],
    "dirName": "Shrimp",
    "styles": {
      "3D": "shrimp_3d.png",
      "Animated": "Shrimp.png"
    },
    "sortOrder": 762
  },
  "🔀": {
    "glyph": "🔀",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "crossed",
      "shuffle tracks button"
    ],
    "dirName": "Shuffle tracks button",
    "styles": {
      "3D": "shuffle_tracks_button_3d.png",
      "Animated": "Shuffle Tracks Button.png"
    },
    "sortOrder": 1437
  },
  "🤫": {
    "glyph": "🤫",
    "group": "Smileys & Emotion",
    "keywords": [
      "quiet",
      "shush",
      "shushing face"
    ],
    "dirName": "Shushing face",
    "styles": {
      "3D": "shushing_face_3d.png",
      "Animated": "Shushing Face.png"
    },
    "sortOrder": 34
  },
  "🤘": {
    "glyph": "🤘",
    "group": "People & Body",
    "keywords": [
      "finger",
      "hand",
      "horns",
      "rock-on",
      "sign of the horns"
    ],
    "dirName": "Sign of the horns",
    "skintones": {
      "Default": {
        "3D": "sign_of_the_horns_3d_default.png",
        "Animated": "Sign of the Horns.png"
      }
    },
    "sortOrder": 180
  },
  "🧑‍🎤": {
    "glyph": "🧑‍🎤",
    "group": "People & Body",
    "keywords": [
      "actor",
      "entertainer",
      "rock",
      "singer",
      "star"
    ],
    "dirName": "Singer",
    "skintones": {
      "Default": {
        "3D": "singer_3d_default.png",
        "Animated": "Singer.png"
      }
    },
    "sortOrder": 314
  },
  "🕕": {
    "glyph": "🕕",
    "group": "Travel & Places",
    "keywords": [
      "00",
      "6",
      "6:00",
      "clock",
      "o’clock",
      "six"
    ],
    "dirName": "Six oclock",
    "styles": {
      "3D": "six_oclock_3d.png",
      "Animated": "Six O’Clock.png"
    },
    "sortOrder": 965
  },
  "🕡": {
    "glyph": "🕡",
    "group": "Travel & Places",
    "keywords": [
      "6",
      "6:30",
      "clock",
      "six",
      "six-thirty",
      "thirty"
    ],
    "dirName": "Six-thirty",
    "styles": {
      "3D": "six-thirty_3d.png",
      "Animated": "Six-Thirty.png"
    },
    "sortOrder": 966
  },
  "🛹": {
    "glyph": "🛹",
    "group": "Travel & Places",
    "keywords": [
      "board",
      "skateboard"
    ],
    "dirName": "Skateboard",
    "styles": {
      "3D": "skateboard_3d.png",
      "Animated": "Skateboard.png"
    },
    "sortOrder": 909
  },
  "⛷️": {
    "glyph": "⛷️",
    "group": "People & Body",
    "keywords": [
      "ski",
      "skier",
      "snow"
    ],
    "dirName": "Skier",
    "styles": {
      "3D": "skier_3d.png",
      "Animated": "Skier.png"
    },
    "sortOrder": 436
  },
  "🎿": {
    "glyph": "🎿",
    "group": "Activities",
    "keywords": [
      "ski",
      "skis",
      "snow"
    ],
    "dirName": "Skis",
    "styles": {
      "3D": "skis_3d.png",
      "Animated": "Skis.png"
    },
    "sortOrder": 1075
  },
  "💀": {
    "glyph": "💀",
    "group": "Smileys & Emotion",
    "keywords": [
      "death",
      "face",
      "fairy tale",
      "monster",
      "skull"
    ],
    "dirName": "Skull",
    "styles": {
      "3D": "skull_3d.png",
      "Animated": "Skull.png"
    },
    "sortOrder": 105
  },
  "☠️": {
    "glyph": "☠️",
    "group": "Smileys & Emotion",
    "keywords": [
      "crossbones",
      "death",
      "face",
      "monster",
      "skull",
      "skull and crossbones"
    ],
    "dirName": "Skull and crossbones",
    "styles": {
      "3D": "skull_and_crossbones_3d.png",
      "Animated": "Skull and Crossbones.png"
    },
    "sortOrder": 106
  },
  "🦨": {
    "glyph": "🦨",
    "group": "Animals & Nature",
    "keywords": [
      "skunk",
      "stink"
    ],
    "dirName": "Skunk",
    "styles": {
      "3D": "skunk_3d.png",
      "Animated": "Skunk.png"
    },
    "sortOrder": 590
  },
  "🛷": {
    "glyph": "🛷",
    "group": "Activities",
    "keywords": [
      "sled",
      "sledge",
      "sleigh"
    ],
    "dirName": "Sled",
    "styles": {
      "3D": "sled_3d.png",
      "Animated": "Sled.png"
    },
    "sortOrder": 1076
  },
  "😴": {
    "glyph": "😴",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "sleep",
      "sleeping face",
      "zzz"
    ],
    "dirName": "Sleeping face",
    "styles": {
      "3D": "sleeping_face_3d.png",
      "Animated": "Sleeping Face.png"
    },
    "sortOrder": 54
  },
  "😪": {
    "glyph": "😪",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "sleep",
      "sleepy face"
    ],
    "dirName": "Sleepy face",
    "styles": {
      "3D": "sleepy_face_3d.png",
      "Animated": "Sleepy Face.png"
    },
    "sortOrder": 52
  },
  "🙁": {
    "glyph": "🙁",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "frown",
      "slightly frowning face"
    ],
    "dirName": "Slightly frowning face",
    "styles": {
      "3D": "slightly_frowning_face_3d.png",
      "Animated": "Slightly Frowning Face.png"
    },
    "sortOrder": 76
  },
  "🙂": {
    "glyph": "🙂",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "slightly smiling face",
      "smile"
    ],
    "dirName": "Slightly smiling face",
    "styles": {
      "3D": "slightly_smiling_face_3d.png",
      "Animated": "Slightly Smiling Face.png"
    },
    "sortOrder": 9
  },
  "🎰": {
    "glyph": "🎰",
    "group": "Activities",
    "keywords": [
      "game",
      "slot",
      "slot machine"
    ],
    "dirName": "Slot machine",
    "styles": {
      "3D": "slot_machine_3d.png",
      "Animated": "Slot Machine.png"
    },
    "sortOrder": 1088
  },
  "🦥": {
    "glyph": "🦥",
    "group": "Animals & Nature",
    "keywords": [
      "lazy",
      "sloth",
      "slow"
    ],
    "dirName": "Sloth",
    "styles": {
      "3D": "sloth_3d.png",
      "Animated": "Sloth.png"
    },
    "sortOrder": 588
  },
  "🛩️": {
    "glyph": "🛩️",
    "group": "Travel & Places",
    "keywords": [
      "aeroplane",
      "airplane",
      "small airplane"
    ],
    "dirName": "Small airplane",
    "styles": {
      "3D": "small_airplane_3d.png",
      "Animated": "Small Airplane.png"
    },
    "sortOrder": 932
  },
  "🔹": {
    "glyph": "🔹",
    "group": "Symbols",
    "keywords": [
      "blue",
      "diamond",
      "geometric",
      "small blue diamond"
    ],
    "dirName": "Small blue diamond",
    "styles": {
      "3D": "small_blue_diamond_3d.png",
      "Animated": "Small Blue Diamond.png"
    },
    "sortOrder": 1579
  },
  "🔸": {
    "glyph": "🔸",
    "group": "Symbols",
    "keywords": [
      "diamond",
      "geometric",
      "orange",
      "small orange diamond"
    ],
    "dirName": "Small orange diamond",
    "styles": {
      "3D": "small_orange_diamond_3d.png",
      "Animated": "Small Orange Diamond.png"
    },
    "sortOrder": 1578
  },
  "😻": {
    "glyph": "😻",
    "group": "Smileys & Emotion",
    "keywords": [
      "cat",
      "eye",
      "face",
      "heart",
      "love",
      "smile",
      "smiling cat with heart-eyes"
    ],
    "dirName": "Smiling cat with heart-eyes",
    "styles": {
      "3D": "smiling_cat_with_heart-eyes_3d.png",
      "Animated": "Smiling Cat with Heart-Eyes.png"
    },
    "sortOrder": 118
  },
  "☺️": {
    "glyph": "☺️",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "outlined",
      "relaxed",
      "smile",
      "smiling face"
    ],
    "dirName": "Smiling face",
    "styles": {
      "3D": "smiling_face_3d.png",
      "Animated": "Smiling Face.png"
    },
    "sortOrder": 20
  },
  "😇": {
    "glyph": "😇",
    "group": "Smileys & Emotion",
    "keywords": [
      "angel",
      "face",
      "fantasy",
      "halo",
      "innocent",
      "smiling face with halo"
    ],
    "dirName": "Smiling face with halo",
    "styles": {
      "3D": "smiling_face_with_halo_3d.png",
      "Animated": "Smiling Face with Halo.png"
    },
    "sortOrder": 14
  },
  "😍": {
    "glyph": "😍",
    "group": "Smileys & Emotion",
    "keywords": [
      "eye",
      "face",
      "love",
      "smile",
      "smiling face with heart-eyes"
    ],
    "dirName": "Smiling face with heart-eyes",
    "styles": {
      "3D": "smiling_face_with_heart-eyes_3d.png",
      "Animated": "Smiling Face with Heart-Eyes.png"
    },
    "sortOrder": 16
  },
  "🥰": {
    "glyph": "🥰",
    "group": "Smileys & Emotion",
    "keywords": [
      "adore",
      "crush",
      "hearts",
      "in love",
      "smiling face with hearts"
    ],
    "dirName": "Smiling face with hearts",
    "styles": {
      "3D": "smiling_face_with_hearts_3d.png",
      "Animated": "Smiling Face with Hearts.png"
    },
    "sortOrder": 15
  },
  "😈": {
    "glyph": "😈",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "fairy tale",
      "fantasy",
      "horns",
      "smile",
      "smiling face with horns"
    ],
    "dirName": "Smiling face with horns",
    "styles": {
      "3D": "smiling_face_with_horns_3d.png",
      "Animated": "Smiling Face with Horns.png"
    },
    "sortOrder": 103
  },
  "😊": {
    "glyph": "😊",
    "group": "Smileys & Emotion",
    "keywords": [
      "blush",
      "eye",
      "face",
      "smile",
      "smiling face with smiling eyes"
    ],
    "dirName": "Smiling face with smiling eyes",
    "styles": {
      "3D": "smiling_face_with_smiling_eyes_3d.png",
      "Animated": "Smiling Face with Smiling Eyes.png"
    },
    "sortOrder": 13
  },
  "😎": {
    "glyph": "😎",
    "group": "Smileys & Emotion",
    "keywords": [
      "bright",
      "cool",
      "face",
      "smiling face with sunglasses",
      "sun",
      "sunglasses"
    ],
    "dirName": "Smiling face with sunglasses",
    "styles": {
      "3D": "smiling_face_with_sunglasses_3d.png",
      "Animated": "Smiling Face with Sunglasses.png"
    },
    "sortOrder": 70
  },
  "🥲": {
    "glyph": "🥲",
    "group": "Smileys & Emotion",
    "keywords": [
      "grateful",
      "proud",
      "relieved",
      "smiling",
      "smiling face with tear",
      "tear",
      "touched"
    ],
    "dirName": "Smiling face with tear",
    "styles": {
      "3D": "smiling_face_with_tear_3d.png",
      "Animated": "Smiling Face with Tear.png"
    },
    "sortOrder": 23
  },
  "😏": {
    "glyph": "😏",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "smirk",
      "smirking face"
    ],
    "dirName": "Smirking face",
    "styles": {
      "3D": "smirking_face_3d.png",
      "Animated": "Smirking Face.png"
    },
    "sortOrder": 44
  },
  "🐌": {
    "glyph": "🐌",
    "group": "Animals & Nature",
    "keywords": [
      "snail"
    ],
    "dirName": "Snail",
    "styles": {
      "3D": "snail_3d.png",
      "Animated": "Snail.png"
    },
    "sortOrder": 632
  },
  "🐍": {
    "glyph": "🐍",
    "group": "Animals & Nature",
    "keywords": [
      "bearer",
      "Ophiuchus",
      "serpent",
      "snake",
      "zodiac"
    ],
    "dirName": "Snake",
    "styles": {
      "3D": "snake_3d.png",
      "Animated": "Snake.png"
    },
    "sortOrder": 616
  },
  "🤧": {
    "glyph": "🤧",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "gesundheit",
      "sneeze",
      "sneezing face"
    ],
    "dirName": "Sneezing face",
    "styles": {
      "3D": "sneezing_face_3d.png",
      "Animated": "Sneezing Face.png"
    },
    "sortOrder": 60
  },
  "🏔️": {
    "glyph": "🏔️",
    "group": "Travel & Places",
    "keywords": [
      "cold",
      "mountain",
      "snow",
      "snow-capped mountain"
    ],
    "dirName": "Snow-capped mountain",
    "styles": {
      "3D": "snow-capped_mountain_3d.png",
      "Animated": "Snow-Capped Mountain.png"
    },
    "sortOrder": 813
  },
  "🏂": {
    "glyph": "🏂",
    "group": "People & Body",
    "keywords": [
      "ski",
      "snow",
      "snowboard",
      "snowboarder"
    ],
    "dirName": "Snowboarder",
    "skintones": {
      "Default": {
        "3D": "snowboarder_3d_default.png",
        "Animated": "Snowboarder.png"
      }
    },
    "sortOrder": 437
  },
  "❄️": {
    "glyph": "❄️",
    "group": "Travel & Places",
    "keywords": [
      "cold",
      "snow",
      "snowflake"
    ],
    "dirName": "Snowflake",
    "styles": {
      "3D": "snowflake_3d.png",
      "Animated": "Snowflake.png"
    },
    "sortOrder": 1017
  },
  "☃️": {
    "glyph": "☃️",
    "group": "Travel & Places",
    "keywords": [
      "cold",
      "snow",
      "snowman"
    ],
    "dirName": "Snowman",
    "styles": {
      "3D": "snowman_3d.png",
      "Animated": "Snowman.png"
    },
    "sortOrder": 1018
  },
  "⛄": {
    "glyph": "⛄",
    "group": "Travel & Places",
    "keywords": [
      "cold",
      "snow",
      "snowman",
      "snowman without snow"
    ],
    "dirName": "Snowman without snow",
    "styles": {
      "3D": "snowman_without_snow_3d.png",
      "Animated": "Snowman Without Snow.png"
    },
    "sortOrder": 1019
  },
  "🧼": {
    "glyph": "🧼",
    "group": "Objects",
    "keywords": [
      "bar",
      "bathing",
      "cleaning",
      "lather",
      "soap",
      "soapdish"
    ],
    "dirName": "Soap",
    "styles": {
      "3D": "soap_3d.png",
      "Animated": "Soap.png"
    },
    "sortOrder": 1352
  },
  "⚽": {
    "glyph": "⚽",
    "group": "Activities",
    "keywords": [
      "ball",
      "football",
      "soccer"
    ],
    "dirName": "Soccer ball",
    "styles": {
      "3D": "soccer_ball_3d.png",
      "Animated": "Soccer Ball.png"
    },
    "sortOrder": 1051
  },
  "🧦": {
    "glyph": "🧦",
    "group": "Objects",
    "keywords": [
      "socks",
      "stocking"
    ],
    "dirName": "Socks",
    "styles": {
      "3D": "socks_3d.png",
      "Animated": "Socks.png"
    },
    "sortOrder": 1121
  },
  "🍦": {
    "glyph": "🍦",
    "group": "Food & Drink",
    "keywords": [
      "cream",
      "dessert",
      "ice",
      "icecream",
      "soft",
      "sweet"
    ],
    "dirName": "Soft ice cream",
    "styles": {
      "3D": "soft_ice_cream_3d.png",
      "Animated": "Soft Ice Cream.png"
    },
    "sortOrder": 765
  },
  "🥎": {
    "glyph": "🥎",
    "group": "Activities",
    "keywords": [
      "ball",
      "glove",
      "softball",
      "underarm"
    ],
    "dirName": "Softball",
    "styles": {
      "3D": "softball_3d.png",
      "Animated": "Softball.png"
    },
    "sortOrder": 1053
  },
  "🔜": {
    "glyph": "🔜",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "soon",
      "SOON arrow"
    ],
    "dirName": "Soon arrow",
    "styles": {
      "3D": "soon_arrow_3d.png",
      "Animated": "Soon Arrow.png"
    },
    "sortOrder": 1410
  },
  "🆘": {
    "glyph": "🆘",
    "group": "Symbols",
    "keywords": [
      "help",
      "sos",
      "SOS button"
    ],
    "dirName": "Sos button",
    "styles": {
      "3D": "sos_button_3d.png",
      "Animated": "SOS Button.png"
    },
    "sortOrder": 1532
  },
  "♠️": {
    "glyph": "♠️",
    "group": "Activities",
    "keywords": [
      "card",
      "game",
      "spade suit"
    ],
    "dirName": "Spade suit",
    "styles": {
      "3D": "spade_suit_3d.png",
      "Animated": "Spade Suit.png"
    },
    "sortOrder": 1095
  },
  "🍝": {
    "glyph": "🍝",
    "group": "Food & Drink",
    "keywords": [
      "pasta",
      "spaghetti"
    ],
    "dirName": "Spaghetti",
    "styles": {
      "3D": "spaghetti_3d.png",
      "Animated": "Spaghetti.png"
    },
    "sortOrder": 749
  },
  "❇️": {
    "glyph": "❇️",
    "group": "Symbols",
    "keywords": [
      "*",
      "sparkle"
    ],
    "dirName": "Sparkle",
    "styles": {
      "3D": "sparkle_3d.png",
      "Animated": "Sparkle.png"
    },
    "sortOrder": 1496
  },
  "🎇": {
    "glyph": "🎇",
    "group": "Activities",
    "keywords": [
      "celebration",
      "fireworks",
      "sparkle",
      "sparkler"
    ],
    "dirName": "Sparkler",
    "styles": {
      "3D": "sparkler_3d.png",
      "Animated": "Sparkler.png"
    },
    "sortOrder": 1027
  },
  "✨": {
    "glyph": "✨",
    "group": "Activities",
    "keywords": [
      "*",
      "sparkle",
      "sparkles",
      "star"
    ],
    "dirName": "Sparkles",
    "styles": {
      "3D": "sparkles_3d.png",
      "Animated": "Sparkles.png"
    },
    "sortOrder": 1029
  },
  "💖": {
    "glyph": "💖",
    "group": "Smileys & Emotion",
    "keywords": [
      "excited",
      "sparkle",
      "sparkling heart"
    ],
    "dirName": "Sparkling heart",
    "styles": {
      "3D": "sparkling_heart_3d.png",
      "Animated": "Sparkling Heart.png"
    },
    "sortOrder": 131
  },
  "🙊": {
    "glyph": "🙊",
    "group": "Smileys & Emotion",
    "keywords": [
      "evil",
      "face",
      "forbidden",
      "monkey",
      "speak",
      "speak-no-evil monkey"
    ],
    "dirName": "Speak-no-evil monkey",
    "styles": {
      "3D": "speak-no-evil_monkey_3d.png",
      "Animated": "Speak-No-Evil Monkey.png"
    },
    "sortOrder": 126
  },
  "🔊": {
    "glyph": "🔊",
    "group": "Objects",
    "keywords": [
      "loud",
      "speaker high volume"
    ],
    "dirName": "Speaker high volume",
    "styles": {
      "3D": "speaker_high_volume_3d.png",
      "Animated": "Speaker High Volume.png"
    },
    "sortOrder": 1158
  },
  "🔈": {
    "glyph": "🔈",
    "group": "Objects",
    "keywords": [
      "soft",
      "speaker low volume"
    ],
    "dirName": "Speaker low volume",
    "styles": {
      "3D": "speaker_low_volume_3d.png",
      "Animated": "Speaker Low Volume.png"
    },
    "sortOrder": 1156
  },
  "🔉": {
    "glyph": "🔉",
    "group": "Objects",
    "keywords": [
      "medium",
      "speaker medium volume"
    ],
    "dirName": "Speaker medium volume",
    "styles": {
      "3D": "speaker_medium_volume_3d.png",
      "Animated": "Speaker Medium Volume.png"
    },
    "sortOrder": 1157
  },
  "🗣️": {
    "glyph": "🗣️",
    "group": "People & Body",
    "keywords": [
      "face",
      "head",
      "silhouette",
      "speak",
      "speaking"
    ],
    "dirName": "Speaking head",
    "styles": {
      "3D": "speaking_head_3d.png",
      "Animated": "Speaking Head.png"
    },
    "sortOrder": 520
  },
  "💬": {
    "glyph": "💬",
    "group": "Smileys & Emotion",
    "keywords": [
      "balloon",
      "bubble",
      "comic",
      "dialog",
      "speech"
    ],
    "dirName": "Speech balloon",
    "styles": {
      "3D": "speech_balloon_3d.png",
      "Animated": "Speech Balloon.png"
    },
    "sortOrder": 158
  },
  "🚤": {
    "glyph": "🚤",
    "group": "Travel & Places",
    "keywords": [
      "boat",
      "speedboat"
    ],
    "dirName": "Speedboat",
    "styles": {
      "3D": "speedboat_3d.png",
      "Animated": "Speedboat.png"
    },
    "sortOrder": 926
  },
  "🕷️": {
    "glyph": "🕷️",
    "group": "Animals & Nature",
    "keywords": [
      "insect",
      "spider"
    ],
    "dirName": "Spider",
    "styles": {
      "3D": "spider_3d.png",
      "Animated": "Spider.png"
    },
    "sortOrder": 641
  },
  "🕸️": {
    "glyph": "🕸️",
    "group": "Animals & Nature",
    "keywords": [
      "spider",
      "web"
    ],
    "dirName": "Spider web",
    "styles": {
      "3D": "spider_web_3d.png",
      "Animated": "Spider Web.png"
    },
    "sortOrder": 642
  },
  "🗓️": {
    "glyph": "🗓️",
    "group": "Objects",
    "keywords": [
      "calendar",
      "pad",
      "spiral"
    ],
    "dirName": "Spiral calendar",
    "styles": {
      "3D": "spiral_calendar_3d.png",
      "Animated": "Spiral Calendar.png"
    },
    "sortOrder": 1272
  },
  "🗒️": {
    "glyph": "🗒️",
    "group": "Objects",
    "keywords": [
      "note",
      "pad",
      "spiral",
      "spiral notepad"
    ],
    "dirName": "Spiral notepad",
    "styles": {
      "3D": "spiral_notepad_3d.png",
      "Animated": "Spiral Notepad.png"
    },
    "sortOrder": 1271
  },
  "🐚": {
    "glyph": "🐚",
    "group": "Animals & Nature",
    "keywords": [
      "shell",
      "spiral"
    ],
    "dirName": "Spiral shell",
    "styles": {
      "3D": "spiral_shell_3d.png",
      "Animated": "Spiral Shell.png"
    },
    "sortOrder": 630
  },
  "🧽": {
    "glyph": "🧽",
    "group": "Objects",
    "keywords": [
      "absorbing",
      "cleaning",
      "porous",
      "sponge"
    ],
    "dirName": "Sponge",
    "styles": {
      "3D": "sponge_3d.png",
      "Animated": "Sponge.png"
    },
    "sortOrder": 1355
  },
  "🥄": {
    "glyph": "🥄",
    "group": "Food & Drink",
    "keywords": [
      "spoon",
      "tableware"
    ],
    "dirName": "Spoon",
    "styles": {
      "3D": "spoon_3d.png",
      "Animated": "Spoon.png"
    },
    "sortOrder": 802
  },
  "🚙": {
    "glyph": "🚙",
    "group": "Travel & Places",
    "keywords": [
      "recreational",
      "sport utility",
      "sport utility vehicle"
    ],
    "dirName": "Sport utility vehicle",
    "styles": {
      "3D": "sport_utility_vehicle_3d.png",
      "Animated": "Sport Utility Vehicle.png"
    },
    "sortOrder": 896
  },
  "🏅": {
    "glyph": "🏅",
    "group": "Activities",
    "keywords": [
      "medal",
      "sports medal"
    ],
    "dirName": "Sports medal",
    "styles": {
      "3D": "sports_medal_3d.png",
      "Animated": "Sports Medal.png"
    },
    "sortOrder": 1047
  },
  "🐳": {
    "glyph": "🐳",
    "group": "Animals & Nature",
    "keywords": [
      "face",
      "spouting",
      "whale"
    ],
    "dirName": "Spouting whale",
    "styles": {
      "3D": "spouting_whale_3d.png",
      "Animated": "Spouting Whale.png"
    },
    "sortOrder": 621
  },
  "🦑": {
    "glyph": "🦑",
    "group": "Food & Drink",
    "keywords": [
      "food",
      "molusc",
      "squid"
    ],
    "dirName": "Squid",
    "styles": {
      "3D": "squid_3d.png",
      "Animated": "Squid.png"
    },
    "sortOrder": 763
  },
  "😝": {
    "glyph": "😝",
    "group": "Smileys & Emotion",
    "keywords": [
      "eye",
      "face",
      "horrible",
      "squinting face with tongue",
      "taste",
      "tongue"
    ],
    "dirName": "Squinting face with tongue",
    "styles": {
      "3D": "squinting_face_with_tongue_3d.png",
      "Animated": "Squinting Face with Tongue.png"
    },
    "sortOrder": 28
  },
  "🏟️": {
    "glyph": "🏟️",
    "group": "Travel & Places",
    "keywords": [
      "stadium"
    ],
    "dirName": "Stadium",
    "styles": {
      "3D": "stadium_3d.png",
      "Animated": "Stadium.png"
    },
    "sortOrder": 822
  },
  "⭐": {
    "glyph": "⭐",
    "group": "Travel & Places",
    "keywords": [
      "star"
    ],
    "dirName": "Star",
    "styles": {
      "3D": "star_3d.png",
      "Animated": "Star.png"
    },
    "sortOrder": 994
  },
  "☪️": {
    "glyph": "☪️",
    "group": "Symbols",
    "keywords": [
      "islam",
      "Muslim",
      "religion",
      "star and crescent"
    ],
    "dirName": "Star and crescent",
    "styles": {
      "3D": "star_and_crescent_3d.png",
      "Animated": "Star and Crescent.png"
    },
    "sortOrder": 1420
  },
  "✡️": {
    "glyph": "✡️",
    "group": "Symbols",
    "keywords": [
      "David",
      "Jew",
      "Jewish",
      "religion",
      "star",
      "star of David"
    ],
    "dirName": "Star of david",
    "styles": {
      "3D": "star_of_david_3d.png",
      "Animated": "Star of David.png"
    },
    "sortOrder": 1415
  },
  "🤩": {
    "glyph": "🤩",
    "group": "Smileys & Emotion",
    "keywords": [
      "eyes",
      "face",
      "grinning",
      "star",
      "star-struck"
    ],
    "dirName": "Star-struck",
    "styles": {
      "3D": "star-struck_3d.png",
      "Animated": "Star-Struck.png"
    },
    "sortOrder": 17
  },
  "🚉": {
    "glyph": "🚉",
    "group": "Travel & Places",
    "keywords": [
      "railway",
      "station",
      "train"
    ],
    "dirName": "Station",
    "styles": {
      "3D": "station_3d.png",
      "Animated": "Station.png"
    },
    "sortOrder": 879
  },
  "🗽": {
    "glyph": "🗽",
    "group": "Travel & Places",
    "keywords": [
      "liberty",
      "statue",
      "Statue of Liberty"
    ],
    "dirName": "Statue of liberty",
    "styles": {
      "3D": "statue_of_liberty_3d.png",
      "Animated": "Statue of Liberty.png"
    },
    "sortOrder": 848
  },
  "🍜": {
    "glyph": "🍜",
    "group": "Food & Drink",
    "keywords": [
      "bowl",
      "noodle",
      "ramen",
      "steaming"
    ],
    "dirName": "Steaming bowl",
    "styles": {
      "3D": "steaming_bowl_3d.png",
      "Animated": "Steaming Bowl.png"
    },
    "sortOrder": 748
  },
  "🩺": {
    "glyph": "🩺",
    "group": "Objects",
    "keywords": [
      "doctor",
      "heart",
      "medicine",
      "stethoscope"
    ],
    "dirName": "Stethoscope",
    "styles": {
      "3D": "stethoscope_3d.png",
      "Animated": "Stethoscope.png"
    },
    "sortOrder": 1331
  },
  "⏹️": {
    "glyph": "⏹️",
    "group": "Symbols",
    "keywords": [
      "square",
      "stop",
      "stop button"
    ],
    "dirName": "Stop button",
    "styles": {
      "3D": "stop_button_3d.png",
      "Animated": "Stop Button.png"
    },
    "sortOrder": 1452
  },
  "🛑": {
    "glyph": "🛑",
    "group": "Travel & Places",
    "keywords": [
      "octagonal",
      "sign",
      "stop"
    ],
    "dirName": "Stop sign",
    "styles": {
      "3D": "stop_sign_3d.png",
      "Animated": "Stop Sign.png"
    },
    "sortOrder": 920
  },
  "⏱️": {
    "glyph": "⏱️",
    "group": "Travel & Places",
    "keywords": [
      "clock",
      "stopwatch"
    ],
    "dirName": "Stopwatch",
    "styles": {
      "3D": "stopwatch_3d.png",
      "Animated": "Stopwatch.png"
    },
    "sortOrder": 950
  },
  "📏": {
    "glyph": "📏",
    "group": "Objects",
    "keywords": [
      "ruler",
      "straight edge",
      "straight ruler"
    ],
    "dirName": "Straight ruler",
    "styles": {
      "3D": "straight_ruler_3d.png",
      "Animated": "Straight Ruler.png"
    },
    "sortOrder": 1282
  },
  "🍓": {
    "glyph": "🍓",
    "group": "Food & Drink",
    "keywords": [
      "berry",
      "fruit",
      "strawberry"
    ],
    "dirName": "Strawberry",
    "styles": {
      "3D": "strawberry_3d.png",
      "Animated": "Strawberry.png"
    },
    "sortOrder": 687
  },
  "🧑‍🎓": {
    "glyph": "🧑‍🎓",
    "group": "People & Body",
    "keywords": [
      "graduate",
      "student"
    ],
    "dirName": "Student",
    "skintones": {
      "Default": {
        "3D": "student_3d_default.png",
        "Animated": "Student.png"
      }
    },
    "sortOrder": 284
  },
  "🎙️": {
    "glyph": "🎙️",
    "group": "Objects",
    "keywords": [
      "mic",
      "microphone",
      "music",
      "studio"
    ],
    "dirName": "Studio microphone",
    "styles": {
      "3D": "studio_microphone_3d.png",
      "Animated": "Studio Microphone.png"
    },
    "sortOrder": 1167
  },
  "🥙": {
    "glyph": "🥙",
    "group": "Food & Drink",
    "keywords": [
      "falafel",
      "flatbread",
      "food",
      "gyro",
      "kebab",
      "stuffed"
    ],
    "dirName": "Stuffed flatbread",
    "styles": {
      "3D": "stuffed_flatbread_3d.png",
      "Animated": "Stuffed Flatbread.png"
    },
    "sortOrder": 730
  },
  "☀️": {
    "glyph": "☀️",
    "group": "Travel & Places",
    "keywords": [
      "bright",
      "rays",
      "sun",
      "sunny"
    ],
    "dirName": "Sun",
    "styles": {
      "3D": "sun_3d.png",
      "Animated": "Sun.png"
    },
    "sortOrder": 990
  },
  "⛅": {
    "glyph": "⛅",
    "group": "Travel & Places",
    "keywords": [
      "cloud",
      "sun",
      "sun behind cloud"
    ],
    "dirName": "Sun behind cloud",
    "styles": {
      "3D": "sun_behind_cloud_3d.png",
      "Animated": "Sun Behind Cloud.png"
    },
    "sortOrder": 999
  },
  "🌥️": {
    "glyph": "🌥️",
    "group": "Travel & Places",
    "keywords": [
      "cloud",
      "sun",
      "sun behind large cloud"
    ],
    "dirName": "Sun behind large cloud",
    "styles": {
      "3D": "sun_behind_large_cloud_3d.png",
      "Animated": "Sun Behind Large Cloud.png"
    },
    "sortOrder": 1002
  },
  "🌦️": {
    "glyph": "🌦️",
    "group": "Travel & Places",
    "keywords": [
      "cloud",
      "rain",
      "sun",
      "sun behind rain cloud"
    ],
    "dirName": "Sun behind rain cloud",
    "styles": {
      "3D": "sun_behind_rain_cloud_3d.png",
      "Animated": "Sun Behind Rain Cloud.png"
    },
    "sortOrder": 1003
  },
  "🌤️": {
    "glyph": "🌤️",
    "group": "Travel & Places",
    "keywords": [
      "cloud",
      "sun",
      "sun behind small cloud"
    ],
    "dirName": "Sun behind small cloud",
    "styles": {
      "3D": "sun_behind_small_cloud_3d.png",
      "Animated": "Sun Behind Small Cloud.png"
    },
    "sortOrder": 1001
  },
  "🌞": {
    "glyph": "🌞",
    "group": "Travel & Places",
    "keywords": [
      "bright",
      "face",
      "sun",
      "sun with face"
    ],
    "dirName": "Sun with face",
    "styles": {
      "3D": "sun_with_face_3d.png",
      "Animated": "Sun with Face.png"
    },
    "sortOrder": 992
  },
  "🌻": {
    "glyph": "🌻",
    "group": "Animals & Nature",
    "keywords": [
      "flower",
      "sun",
      "sunflower"
    ],
    "dirName": "Sunflower",
    "styles": {
      "3D": "sunflower_3d.png",
      "Animated": "Sunflower.png"
    },
    "sortOrder": 656
  },
  "🕶️": {
    "glyph": "🕶️",
    "group": "Objects",
    "keywords": [
      "dark",
      "eye",
      "eyewear",
      "glasses",
      "sunglasses"
    ],
    "dirName": "Sunglasses",
    "styles": {
      "3D": "sunglasses_3d.png"
    },
    "sortOrder": 1111
  },
  "🌅": {
    "glyph": "🌅",
    "group": "Travel & Places",
    "keywords": [
      "morning",
      "sun",
      "sunrise"
    ],
    "dirName": "Sunrise",
    "styles": {
      "3D": "sunrise_3d.png",
      "Animated": "Sunrise.png"
    },
    "sortOrder": 861
  },
  "🌄": {
    "glyph": "🌄",
    "group": "Travel & Places",
    "keywords": [
      "morning",
      "mountain",
      "sun",
      "sunrise",
      "sunrise over mountains"
    ],
    "dirName": "Sunrise over mountains",
    "styles": {
      "3D": "sunrise_over_mountains_3d.png",
      "Animated": "Sunrise Over Mountains.png"
    },
    "sortOrder": 860
  },
  "🌇": {
    "glyph": "🌇",
    "group": "Travel & Places",
    "keywords": [
      "dusk",
      "sun",
      "sunset"
    ],
    "dirName": "Sunset",
    "styles": {
      "3D": "sunset_3d.png",
      "Animated": "Sunset.png"
    },
    "sortOrder": 863
  },
  "🍣": {
    "glyph": "🍣",
    "group": "Food & Drink",
    "keywords": [
      "sushi"
    ],
    "dirName": "Sushi",
    "styles": {
      "3D": "sushi_3d.png",
      "Animated": "Sushi.png"
    },
    "sortOrder": 752
  },
  "🚟": {
    "glyph": "🚟",
    "group": "Travel & Places",
    "keywords": [
      "railway",
      "suspension"
    ],
    "dirName": "Suspension railway",
    "styles": {
      "3D": "suspension_railway_3d.png",
      "Animated": "Suspension Railway.png"
    },
    "sortOrder": 938
  },
  "🦢": {
    "glyph": "🦢",
    "group": "Animals & Nature",
    "keywords": [
      "bird",
      "cygnet",
      "swan",
      "ugly duckling"
    ],
    "dirName": "Swan",
    "styles": {
      "3D": "swan_3d.png",
      "Animated": "Swan.png"
    },
    "sortOrder": 605
  },
  "💦": {
    "glyph": "💦",
    "group": "Smileys & Emotion",
    "keywords": [
      "comic",
      "splashing",
      "sweat",
      "sweat droplets"
    ],
    "dirName": "Sweat droplets",
    "styles": {
      "3D": "sweat_droplets_3d.png",
      "Animated": "Sweat Droplets.png"
    },
    "sortOrder": 154
  },
  "🕍": {
    "glyph": "🕍",
    "group": "Travel & Places",
    "keywords": [
      "Jew",
      "Jewish",
      "religion",
      "synagogue",
      "temple"
    ],
    "dirName": "Synagogue",
    "styles": {
      "3D": "synagogue_3d.png",
      "Animated": "Synagogue.png"
    },
    "sortOrder": 852
  },
  "💉": {
    "glyph": "💉",
    "group": "Objects",
    "keywords": [
      "medicine",
      "needle",
      "shot",
      "sick",
      "syringe"
    ],
    "dirName": "Syringe",
    "styles": {
      "3D": "syringe_3d.png",
      "Animated": "Syringe.png"
    },
    "sortOrder": 1326
  },
  "🦖": {
    "glyph": "🦖",
    "group": "Animals & Nature",
    "keywords": [
      "T-Rex",
      "Tyrannosaurus Rex"
    ],
    "dirName": "T-rex",
    "styles": {
      "3D": "t-rex_3d.png",
      "Animated": "T-Rex.png"
    },
    "sortOrder": 620
  },
  "👕": {
    "glyph": "👕",
    "group": "Objects",
    "keywords": [
      "clothing",
      "shirt",
      "t-shirt",
      "tshirt"
    ],
    "dirName": "T-shirt",
    "styles": {
      "3D": "t-shirt_3d.png",
      "Animated": "T-Shirt.png"
    },
    "sortOrder": 1116
  },
  "🌮": {
    "glyph": "🌮",
    "group": "Food & Drink",
    "keywords": [
      "mexican",
      "taco"
    ],
    "dirName": "Taco",
    "styles": {
      "3D": "taco_3d.png",
      "Animated": "Taco.png"
    },
    "sortOrder": 727
  },
  "🥡": {
    "glyph": "🥡",
    "group": "Food & Drink",
    "keywords": [
      "oyster pail",
      "takeout box"
    ],
    "dirName": "Takeout box",
    "styles": {
      "3D": "takeout_box_3d.png",
      "Animated": "Takeout Box.png"
    },
    "sortOrder": 759
  },
  "🫔": {
    "glyph": "🫔",
    "group": "Food & Drink",
    "keywords": [
      "mexican",
      "tamale",
      "wrapped"
    ],
    "dirName": "Tamale",
    "styles": {
      "3D": "tamale_3d.png",
      "Animated": "Tamale.png"
    },
    "sortOrder": 729
  },
  "🎋": {
    "glyph": "🎋",
    "group": "Activities",
    "keywords": [
      "banner",
      "celebration",
      "Japanese",
      "tanabata tree",
      "tree"
    ],
    "dirName": "Tanabata tree",
    "styles": {
      "3D": "tanabata_tree_3d.png",
      "Animated": "Tanabata Tree.png"
    },
    "sortOrder": 1033
  },
  "🍊": {
    "glyph": "🍊",
    "group": "Food & Drink",
    "keywords": [
      "fruit",
      "orange",
      "tangerine"
    ],
    "dirName": "Tangerine",
    "styles": {
      "3D": "tangerine_3d.png",
      "Animated": "Tangerine.png"
    },
    "sortOrder": 677
  },
  "♉": {
    "glyph": "♉",
    "group": "Symbols",
    "keywords": [
      "bull",
      "ox",
      "Taurus",
      "zodiac"
    ],
    "dirName": "Taurus",
    "styles": {
      "3D": "taurus_3d.png",
      "Animated": "Taurus.png"
    },
    "sortOrder": 1425
  },
  "🚕": {
    "glyph": "🚕",
    "group": "Travel & Places",
    "keywords": [
      "taxi",
      "vehicle"
    ],
    "dirName": "Taxi",
    "styles": {
      "3D": "taxi_3d.png",
      "Animated": "Taxi.png"
    },
    "sortOrder": 892
  },
  "🧑‍🏫": {
    "glyph": "🧑‍🏫",
    "group": "People & Body",
    "keywords": [
      "instructor",
      "professor",
      "teacher"
    ],
    "dirName": "Teacher",
    "skintones": {
      "Default": {
        "3D": "teacher_3d_default.png",
        "Animated": "Teacher.png"
      }
    },
    "sortOrder": 287
  },
  "🍵": {
    "glyph": "🍵",
    "group": "Food & Drink",
    "keywords": [
      "beverage",
      "cup",
      "drink",
      "tea",
      "teacup",
      "teacup without handle"
    ],
    "dirName": "Teacup without handle",
    "styles": {
      "3D": "teacup_without_handle_3d.png",
      "Animated": "Teacup Without Handle.png"
    },
    "sortOrder": 783
  },
  "🫖": {
    "glyph": "🫖",
    "group": "Food & Drink",
    "keywords": [
      "drink",
      "pot",
      "tea",
      "teapot"
    ],
    "dirName": "Teapot",
    "styles": {
      "3D": "teapot_3d.png",
      "Animated": "Teapot.png"
    },
    "sortOrder": 782
  },
  "📆": {
    "glyph": "📆",
    "group": "Objects",
    "keywords": [
      "calendar",
      "tear-off calendar"
    ],
    "dirName": "Tear-off calendar",
    "styles": {
      "3D": "tear-off_calendar_3d.png",
      "Animated": "Tear-Off Calendar.png"
    },
    "sortOrder": 1270
  },
  "🧸": {
    "glyph": "🧸",
    "group": "Activities",
    "keywords": [
      "plaything",
      "plush",
      "stuffed",
      "teddy bear",
      "toy"
    ],
    "dirName": "Teddy bear",
    "styles": {
      "3D": "teddy_bear_3d.png",
      "Animated": "Teddy Bear.png"
    },
    "sortOrder": 1091
  },
  "☎️": {
    "glyph": "☎️",
    "group": "Objects",
    "keywords": [
      "phone",
      "telephone"
    ],
    "dirName": "Telephone",
    "styles": {
      "3D": "telephone_3d.png",
      "Animated": "Telephone.png"
    },
    "sortOrder": 1184
  },
  "📞": {
    "glyph": "📞",
    "group": "Objects",
    "keywords": [
      "phone",
      "receiver",
      "telephone"
    ],
    "dirName": "Telephone receiver",
    "styles": {
      "3D": "telephone_receiver_3d.png",
      "Animated": "Telephone Receiver.png"
    },
    "sortOrder": 1185
  },
  "🔭": {
    "glyph": "🔭",
    "group": "Objects",
    "keywords": [
      "science",
      "telescope",
      "tool"
    ],
    "dirName": "Telescope",
    "styles": {
      "3D": "telescope_3d.png",
      "Animated": "Telescope.png"
    },
    "sortOrder": 1324
  },
  "📺": {
    "glyph": "📺",
    "group": "Objects",
    "keywords": [
      "television",
      "tv",
      "video"
    ],
    "dirName": "Television",
    "styles": {
      "3D": "television_3d.png",
      "Animated": "Television.png"
    },
    "sortOrder": 1206
  },
  "🕙": {
    "glyph": "🕙",
    "group": "Travel & Places",
    "keywords": [
      "00",
      "10",
      "10:00",
      "clock",
      "o’clock",
      "ten"
    ],
    "dirName": "Ten oclock",
    "styles": {
      "3D": "ten_oclock_3d.png",
      "Animated": "Ten O’Clock.png"
    },
    "sortOrder": 973
  },
  "🕥": {
    "glyph": "🕥",
    "group": "Travel & Places",
    "keywords": [
      "10",
      "10:30",
      "clock",
      "ten",
      "ten-thirty",
      "thirty"
    ],
    "dirName": "Ten-thirty",
    "styles": {
      "3D": "ten-thirty_3d.png",
      "Animated": "Ten-Thirty.png"
    },
    "sortOrder": 974
  },
  "🎾": {
    "glyph": "🎾",
    "group": "Activities",
    "keywords": [
      "ball",
      "racquet",
      "tennis"
    ],
    "dirName": "Tennis",
    "styles": {
      "3D": "tennis_3d.png",
      "Animated": "Tennis.png"
    },
    "sortOrder": 1058
  },
  "⛺": {
    "glyph": "⛺",
    "group": "Travel & Places",
    "keywords": [
      "camping",
      "tent"
    ],
    "dirName": "Tent",
    "styles": {
      "3D": "tent_3d.png",
      "Animated": "Tent.png"
    },
    "sortOrder": 856
  },
  "🧪": {
    "glyph": "🧪",
    "group": "Objects",
    "keywords": [
      "chemist",
      "chemistry",
      "experiment",
      "lab",
      "science",
      "test tube"
    ],
    "dirName": "Test tube",
    "styles": {
      "3D": "test_tube_3d.png",
      "Animated": "Test Tube.png"
    },
    "sortOrder": 1320
  },
  "🌡️": {
    "glyph": "🌡️",
    "group": "Travel & Places",
    "keywords": [
      "thermometer",
      "weather"
    ],
    "dirName": "Thermometer",
    "styles": {
      "3D": "thermometer_3d.png",
      "Animated": "Thermometer.png"
    },
    "sortOrder": 989
  },
  "🤔": {
    "glyph": "🤔",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "thinking"
    ],
    "dirName": "Thinking face",
    "styles": {
      "3D": "thinking_face_3d.png",
      "Animated": "Thinking Face.png"
    },
    "sortOrder": 35
  },
  "🩴": {
    "glyph": "🩴",
    "group": "Objects",
    "keywords": [
      "beach sandals",
      "sandals",
      "thong sandal",
      "thong sandals",
      "thongs",
      "zōri"
    ],
    "dirName": "Thong sandal",
    "styles": {
      "3D": "thong_sandal_3d.png",
      "Animated": "Thong Sandal.png"
    },
    "sortOrder": 1135
  },
  "💭": {
    "glyph": "💭",
    "group": "Smileys & Emotion",
    "keywords": [
      "balloon",
      "bubble",
      "comic",
      "thought"
    ],
    "dirName": "Thought balloon",
    "styles": {
      "3D": "thought_balloon_3d.png",
      "Animated": "Thought Balloon.png"
    },
    "sortOrder": 162
  },
  "🧵": {
    "glyph": "🧵",
    "group": "Activities",
    "keywords": [
      "needle",
      "sewing",
      "spool",
      "string",
      "thread"
    ],
    "dirName": "Thread",
    "styles": {
      "3D": "thread_3d.png",
      "Animated": "Thread.png"
    },
    "sortOrder": 1106
  },
  "🕒": {
    "glyph": "🕒",
    "group": "Travel & Places",
    "keywords": [
      "00",
      "3",
      "3:00",
      "clock",
      "o’clock",
      "three"
    ],
    "dirName": "Three oclock",
    "styles": {
      "3D": "three_oclock_3d.png",
      "Animated": "Three O’Clock.png"
    },
    "sortOrder": 959
  },
  "🕞": {
    "glyph": "🕞",
    "group": "Travel & Places",
    "keywords": [
      "3",
      "3:30",
      "clock",
      "thirty",
      "three",
      "three-thirty"
    ],
    "dirName": "Three-thirty",
    "styles": {
      "3D": "three-thirty_3d.png",
      "Animated": "Three-Thirty.png"
    },
    "sortOrder": 960
  },
  "👎": {
    "glyph": "👎",
    "group": "People & Body",
    "keywords": [
      "-1",
      "down",
      "hand",
      "thumb",
      "thumbs down"
    ],
    "dirName": "Thumbs down",
    "skintones": {
      "Default": {
        "3D": "thumbs_down_3d_default.png",
        "Animated": "Thumbs Down.png"
      }
    },
    "sortOrder": 190
  },
  "👍": {
    "glyph": "👍",
    "group": "People & Body",
    "keywords": [
      "+1",
      "hand",
      "thumb",
      "thumbs up",
      "up",
      "like"
    ],
    "dirName": "Thumbs up",
    "skintones": {
      "Default": {
        "3D": "thumbs_up_3d_default.png",
        "Animated": "Thumbs Up.png"
      }
    },
    "sortOrder": 189
  },
  "🎫": {
    "glyph": "🎫",
    "group": "Activities",
    "keywords": [
      "admission",
      "ticket"
    ],
    "dirName": "Ticket",
    "styles": {
      "3D": "ticket_3d.png",
      "Animated": "Ticket.png"
    },
    "sortOrder": 1044
  },
  "🐅": {
    "glyph": "🐅",
    "group": "Animals & Nature",
    "keywords": [
      "tiger"
    ],
    "dirName": "Tiger",
    "styles": {
      "3D": "tiger_3d.png",
      "Animated": "Tiger.png"
    },
    "sortOrder": 547
  },
  "🐯": {
    "glyph": "🐯",
    "group": "Animals & Nature",
    "keywords": [
      "face",
      "tiger"
    ],
    "dirName": "Tiger face",
    "styles": {
      "3D": "tiger_face_3d.png",
      "Animated": "Tiger Face.png"
    },
    "sortOrder": 546
  },
  "⏲️": {
    "glyph": "⏲️",
    "group": "Travel & Places",
    "keywords": [
      "clock",
      "timer"
    ],
    "dirName": "Timer clock",
    "styles": {
      "3D": "timer_clock_3d.png",
      "Animated": "Timer Clock.png"
    },
    "sortOrder": 951
  },
  "😫": {
    "glyph": "😫",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "tired"
    ],
    "dirName": "Tired face",
    "styles": {
      "3D": "tired_face_3d.png",
      "Animated": "Tired Face.png"
    },
    "sortOrder": 97
  },
  "🚽": {
    "glyph": "🚽",
    "group": "Objects",
    "keywords": [
      "toilet"
    ],
    "dirName": "Toilet",
    "styles": {
      "3D": "toilet_3d.png",
      "Animated": "Toilet.png"
    },
    "sortOrder": 1340
  },
  "🗼": {
    "glyph": "🗼",
    "group": "Travel & Places",
    "keywords": [
      "Tokyo",
      "tower"
    ],
    "dirName": "Tokyo tower",
    "styles": {
      "3D": "tokyo_tower_3d.png",
      "Animated": "Tokyo Tower.png"
    },
    "sortOrder": 847
  },
  "🍅": {
    "glyph": "🍅",
    "group": "Food & Drink",
    "keywords": [
      "fruit",
      "tomato",
      "vegetable"
    ],
    "dirName": "Tomato",
    "styles": {
      "3D": "tomato_3d.png",
      "Animated": "Tomato.png"
    },
    "sortOrder": 690
  },
  "👅": {
    "glyph": "👅",
    "group": "People & Body",
    "keywords": [
      "body",
      "tongue"
    ],
    "dirName": "Tongue",
    "styles": {
      "3D": "tongue_3d.png",
      "Animated": "Tongue.png"
    },
    "sortOrder": 220
  },
  "🧰": {
    "glyph": "🧰",
    "group": "Objects",
    "keywords": [
      "chest",
      "mechanic",
      "tool",
      "toolbox"
    ],
    "dirName": "Toolbox",
    "styles": {
      "3D": "toolbox_3d.png",
      "Animated": "Toolbox.png"
    },
    "sortOrder": 1316
  },
  "🦷": {
    "glyph": "🦷",
    "group": "People & Body",
    "keywords": [
      "dentist",
      "tooth"
    ],
    "dirName": "Tooth",
    "styles": {
      "3D": "tooth_3d.png",
      "Animated": "Tooth.png"
    },
    "sortOrder": 216
  },
  "🪥": {
    "glyph": "🪥",
    "group": "Objects",
    "keywords": [
      "bathroom",
      "brush",
      "clean",
      "dental",
      "hygiene",
      "teeth",
      "toothbrush"
    ],
    "dirName": "Toothbrush",
    "styles": {
      "3D": "toothbrush_3d.png",
      "Animated": "Toothbrush.png"
    },
    "sortOrder": 1354
  },
  "🔝": {
    "glyph": "🔝",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "top",
      "TOP arrow",
      "up"
    ],
    "dirName": "Top arrow",
    "styles": {
      "3D": "top_arrow_3d.png",
      "Animated": "Top Arrow.png"
    },
    "sortOrder": 1411
  },
  "🎩": {
    "glyph": "🎩",
    "group": "Objects",
    "keywords": [
      "clothing",
      "hat",
      "top",
      "tophat"
    ],
    "dirName": "Top hat",
    "styles": {
      "3D": "top_hat_3d.png",
      "Animated": "Top Hat.png"
    },
    "sortOrder": 1146
  },
  "🌪️": {
    "glyph": "🌪️",
    "group": "Travel & Places",
    "keywords": [
      "cloud",
      "tornado",
      "whirlwind"
    ],
    "dirName": "Tornado",
    "styles": {
      "3D": "tornado_3d.png",
      "Animated": "Tornado.png"
    },
    "sortOrder": 1007
  },
  "🖲️": {
    "glyph": "🖲️",
    "group": "Objects",
    "keywords": [
      "computer",
      "trackball"
    ],
    "dirName": "Trackball",
    "styles": {
      "3D": "trackball_3d.png",
      "Animated": "Trackball.png"
    },
    "sortOrder": 1196
  },
  "🚜": {
    "glyph": "🚜",
    "group": "Travel & Places",
    "keywords": [
      "tractor",
      "vehicle"
    ],
    "dirName": "Tractor",
    "styles": {
      "3D": "tractor_3d.png",
      "Animated": "Tractor.png"
    },
    "sortOrder": 900
  },
  "™️": {
    "glyph": "™️",
    "group": "Symbols",
    "keywords": [
      "mark",
      "tm",
      "trade mark",
      "trademark"
    ],
    "dirName": "Trade mark",
    "styles": {
      "3D": "trade_mark_3d.png",
      "Animated": "Trade Mark.png"
    },
    "sortOrder": 1499
  },
  "🚆": {
    "glyph": "🚆",
    "group": "Travel & Places",
    "keywords": [
      "railway",
      "train"
    ],
    "dirName": "Train",
    "styles": {
      "3D": "train_3d.png",
      "Animated": "Train.png"
    },
    "sortOrder": 876
  },
  "🚊": {
    "glyph": "🚊",
    "group": "Travel & Places",
    "keywords": [
      "tram",
      "trolleybus"
    ],
    "dirName": "Tram",
    "styles": {
      "3D": "tram_3d.png",
      "Animated": "Tram.png"
    },
    "sortOrder": 880
  },
  "🚋": {
    "glyph": "🚋",
    "group": "Travel & Places",
    "keywords": [
      "car",
      "tram",
      "trolleybus"
    ],
    "dirName": "Tram car",
    "styles": {
      "3D": "tram_car_3d.png",
      "Animated": "Tram Car.png"
    },
    "sortOrder": 883
  },
  "🏳️‍⚧️": {
    "glyph": "🏳️‍⚧️",
    "group": "Flags",
    "keywords": [
      "flag",
      "light blue",
      "pink",
      "transgender",
      "white"
    ],
    "dirName": "Transgender flag",
    "styles": {
      "3D": "transgender_flag_3d.png"
    },
    "sortOrder": 1592
  },
  "⚧️": {
    "glyph": "⚧️",
    "group": "Symbols",
    "keywords": [
      "transgender",
      "transgender symbol"
    ],
    "dirName": "Transgender symbol",
    "styles": {
      "3D": "transgender_symbol_3d.png",
      "Animated": "Transgender Symbol.png"
    },
    "sortOrder": 1463
  },
  "🚩": {
    "glyph": "🚩",
    "group": "Flags",
    "keywords": [
      "post",
      "triangular flag"
    ],
    "dirName": "Triangular flag",
    "styles": {
      "3D": "triangular_flag_3d.png",
      "Animated": "Triangular Flag.png"
    },
    "sortOrder": 1587
  },
  "📐": {
    "glyph": "📐",
    "group": "Objects",
    "keywords": [
      "ruler",
      "set",
      "triangle",
      "triangular ruler"
    ],
    "dirName": "Triangular ruler",
    "styles": {
      "3D": "triangular_ruler_3d.png",
      "Animated": "Triangular Ruler.png"
    },
    "sortOrder": 1283
  },
  "🔱": {
    "glyph": "🔱",
    "group": "Symbols",
    "keywords": [
      "anchor",
      "emblem",
      "ship",
      "tool",
      "trident"
    ],
    "dirName": "Trident emblem",
    "styles": {
      "3D": "trident_emblem_3d.png",
      "Animated": "Trident Emblem.png"
    },
    "sortOrder": 1482
  },
  "🧌": {
    "glyph": "🧌",
    "group": "People & Body",
    "keywords": [
      "fairy tale",
      "fantasy",
      "monster",
      "troll"
    ],
    "dirName": "Troll",
    "styles": {
      "3D": "troll_3d.png"
    },
    "sortOrder": 394
  },
  "🚎": {
    "glyph": "🚎",
    "group": "Travel & Places",
    "keywords": [
      "bus",
      "tram",
      "trolley",
      "trolleybus"
    ],
    "dirName": "Trolleybus",
    "styles": {
      "3D": "trolleybus_3d.png",
      "Animated": "Trolleybus.png"
    },
    "sortOrder": 886
  },
  "🏆": {
    "glyph": "🏆",
    "group": "Activities",
    "keywords": [
      "prize",
      "trophy"
    ],
    "dirName": "Trophy",
    "styles": {
      "3D": "trophy_3d.png",
      "Animated": "Trophy.png"
    },
    "sortOrder": 1046
  },
  "🍹": {
    "glyph": "🍹",
    "group": "Food & Drink",
    "keywords": [
      "bar",
      "drink",
      "tropical"
    ],
    "dirName": "Tropical drink",
    "styles": {
      "3D": "tropical_drink_3d.png",
      "Animated": "Tropical Drink.png"
    },
    "sortOrder": 788
  },
  "🐠": {
    "glyph": "🐠",
    "group": "Animals & Nature",
    "keywords": [
      "fish",
      "tropical"
    ],
    "dirName": "Tropical fish",
    "styles": {
      "3D": "tropical_fish_3d.png",
      "Animated": "Tropical Fish.png"
    },
    "sortOrder": 626
  },
  "🎺": {
    "glyph": "🎺",
    "group": "Objects",
    "keywords": [
      "instrument",
      "music",
      "trumpet"
    ],
    "dirName": "Trumpet",
    "styles": {
      "3D": "trumpet_3d.png",
      "Animated": "Trumpet.png"
    },
    "sortOrder": 1177
  },
  "🌷": {
    "glyph": "🌷",
    "group": "Animals & Nature",
    "keywords": [
      "flower",
      "tulip"
    ],
    "dirName": "Tulip",
    "styles": {
      "3D": "tulip_3d.png",
      "Animated": "Tulip.png"
    },
    "sortOrder": 658
  },
  "🥃": {
    "glyph": "🥃",
    "group": "Food & Drink",
    "keywords": [
      "glass",
      "liquor",
      "shot",
      "tumbler",
      "whisky"
    ],
    "dirName": "Tumbler glass",
    "styles": {
      "3D": "tumbler_glass_3d.png",
      "Animated": "Tumbler Glass.png"
    },
    "sortOrder": 792
  },
  "🦃": {
    "glyph": "🦃",
    "group": "Animals & Nature",
    "keywords": [
      "bird",
      "turkey"
    ],
    "dirName": "Turkey",
    "styles": {
      "3D": "turkey_3d.png",
      "Animated": "Turkey.png"
    },
    "sortOrder": 594
  },
  "🐢": {
    "glyph": "🐢",
    "group": "Animals & Nature",
    "keywords": [
      "terrapin",
      "tortoise",
      "turtle"
    ],
    "dirName": "Turtle",
    "styles": {
      "3D": "turtle_3d.png",
      "Animated": "Turtle.png"
    },
    "sortOrder": 614
  },
  "🕛": {
    "glyph": "🕛",
    "group": "Travel & Places",
    "keywords": [
      "00",
      "12",
      "12:00",
      "clock",
      "o’clock",
      "twelve"
    ],
    "dirName": "Twelve oclock",
    "styles": {
      "3D": "twelve_oclock_3d.png",
      "Animated": "Twelve O’Clock.png"
    },
    "sortOrder": 953
  },
  "🕧": {
    "glyph": "🕧",
    "group": "Travel & Places",
    "keywords": [
      "12",
      "12:30",
      "clock",
      "thirty",
      "twelve",
      "twelve-thirty"
    ],
    "dirName": "Twelve-thirty",
    "styles": {
      "3D": "twelve-thirty_3d.png",
      "Animated": "Twelve-Thirty.png"
    },
    "sortOrder": 954
  },
  "💕": {
    "glyph": "💕",
    "group": "Smileys & Emotion",
    "keywords": [
      "love",
      "two hearts"
    ],
    "dirName": "Two hearts",
    "styles": {
      "3D": "two_hearts_3d.png",
      "Animated": "Two Hearts.png"
    },
    "sortOrder": 135
  },
  "🕑": {
    "glyph": "🕑",
    "group": "Travel & Places",
    "keywords": [
      "00",
      "2",
      "2:00",
      "clock",
      "o’clock",
      "two"
    ],
    "dirName": "Two oclock",
    "styles": {
      "3D": "two_oclock_3d.png",
      "Animated": "Two O’Clock.png"
    },
    "sortOrder": 957
  },
  "🐫": {
    "glyph": "🐫",
    "group": "Animals & Nature",
    "keywords": [
      "bactrian",
      "camel",
      "hump",
      "two-hump camel"
    ],
    "dirName": "Two-hump camel",
    "styles": {
      "3D": "two-hump_camel_3d.png",
      "Animated": "Two-Hump Camel.png"
    },
    "sortOrder": 567
  },
  "🕝": {
    "glyph": "🕝",
    "group": "Travel & Places",
    "keywords": [
      "2",
      "2:30",
      "clock",
      "thirty",
      "two",
      "two-thirty"
    ],
    "dirName": "Two-thirty",
    "styles": {
      "3D": "two-thirty_3d.png",
      "Animated": "Two-Thirty.png"
    },
    "sortOrder": 958
  },
  "☂️": {
    "glyph": "☂️",
    "group": "Travel & Places",
    "keywords": [
      "clothing",
      "rain",
      "umbrella"
    ],
    "dirName": "Umbrella",
    "styles": {
      "3D": "umbrella_3d.png",
      "Animated": "Umbrella.png"
    },
    "sortOrder": 1013
  },
  "⛱️": {
    "glyph": "⛱️",
    "group": "Travel & Places",
    "keywords": [
      "rain",
      "sun",
      "umbrella",
      "umbrella on ground"
    ],
    "dirName": "Umbrella on ground",
    "styles": {
      "3D": "umbrella_on_ground_3d.png",
      "Animated": "Umbrella on Ground.png"
    },
    "sortOrder": 1015
  },
  "☔": {
    "glyph": "☔",
    "group": "Travel & Places",
    "keywords": [
      "clothing",
      "drop",
      "rain",
      "umbrella",
      "umbrella with rain drops"
    ],
    "dirName": "Umbrella with rain drops",
    "styles": {
      "3D": "umbrella_with_rain_drops_3d.png",
      "Animated": "Umbrella with Rain Drops.png"
    },
    "sortOrder": 1014
  },
  "😒": {
    "glyph": "😒",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "unamused",
      "unhappy"
    ],
    "dirName": "Unamused face",
    "styles": {
      "3D": "unamused_face_3d.png",
      "Animated": "Unamused Face.png"
    },
    "sortOrder": 45
  },
  "🦄": {
    "glyph": "🦄",
    "group": "Animals & Nature",
    "keywords": [
      "face",
      "unicorn"
    ],
    "dirName": "Unicorn",
    "styles": {
      "3D": "unicorn_3d.png",
      "Animated": "Unicorn.png"
    },
    "sortOrder": 551
  },
  "🔓": {
    "glyph": "🔓",
    "group": "Objects",
    "keywords": [
      "lock",
      "open",
      "unlock",
      "unlocked"
    ],
    "dirName": "Unlocked",
    "styles": {
      "3D": "unlocked_3d.png",
      "Animated": "Unlocked.png"
    },
    "sortOrder": 1289
  },
  "⬆️": {
    "glyph": "⬆️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "cardinal",
      "direction",
      "north",
      "up arrow"
    ],
    "dirName": "Up arrow",
    "styles": {
      "3D": "up_arrow_3d.png",
      "Animated": "Up Arrow.png"
    },
    "sortOrder": 1391
  },
  "🆙": {
    "glyph": "🆙",
    "group": "Symbols",
    "keywords": [
      "mark",
      "up",
      "UP! button"
    ],
    "dirName": "Up! button",
    "styles": {
      "3D": "up!_button_3d.png",
      "Animated": "Up! Button.png"
    },
    "sortOrder": 1533
  },
  "↕️": {
    "glyph": "↕️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "up-down arrow"
    ],
    "dirName": "Up-down arrow",
    "styles": {
      "3D": "up-down_arrow_3d.png",
      "Animated": "Up-Down Arrow.png"
    },
    "sortOrder": 1399
  },
  "↖️": {
    "glyph": "↖️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "direction",
      "intercardinal",
      "northwest",
      "up-left arrow"
    ],
    "dirName": "Up-left arrow",
    "styles": {
      "3D": "up-left_arrow_3d.png",
      "Animated": "Up-Left Arrow.png"
    },
    "sortOrder": 1398
  },
  "↗️": {
    "glyph": "↗️",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "direction",
      "intercardinal",
      "northeast",
      "up-right arrow"
    ],
    "dirName": "Up-right arrow",
    "styles": {
      "3D": "up-right_arrow_3d.png",
      "Animated": "Up-Right Arrow.png"
    },
    "sortOrder": 1392
  },
  "🙃": {
    "glyph": "🙃",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "upside-down"
    ],
    "dirName": "Upside-down face",
    "styles": {
      "3D": "upside-down_face_3d.png",
      "Animated": "Upside-Down Face.png"
    },
    "sortOrder": 10
  },
  "🔼": {
    "glyph": "🔼",
    "group": "Symbols",
    "keywords": [
      "arrow",
      "button",
      "red",
      "upwards button"
    ],
    "dirName": "Upwards button",
    "styles": {
      "3D": "upwards_button_3d.png",
      "Animated": "Upwards Button.png"
    },
    "sortOrder": 1447
  },
  "🚦": {
    "glyph": "🚦",
    "group": "Travel & Places",
    "keywords": [
      "light",
      "signal",
      "traffic",
      "vertical traffic light"
    ],
    "dirName": "Vertical traffic light",
    "styles": {
      "3D": "vertical_traffic_light_3d.png",
      "Animated": "Vertical Traffic Light.png"
    },
    "sortOrder": 919
  },
  "📳": {
    "glyph": "📳",
    "group": "Symbols",
    "keywords": [
      "cell",
      "mobile",
      "mode",
      "phone",
      "telephone",
      "vibration"
    ],
    "dirName": "Vibration mode",
    "styles": {
      "3D": "vibration_mode_3d.png",
      "Animated": "Vibration Mode.png"
    },
    "sortOrder": 1459
  },
  "✌️": {
    "glyph": "✌️",
    "group": "People & Body",
    "keywords": [
      "hand",
      "v",
      "victory"
    ],
    "dirName": "Victory hand",
    "skintones": {
      "Default": {
        "3D": "victory_hand_3d_default.png",
        "Animated": "Victory Hand.png"
      }
    },
    "sortOrder": 176
  },
  "📹": {
    "glyph": "📹",
    "group": "Objects",
    "keywords": [
      "camera",
      "video"
    ],
    "dirName": "Video camera",
    "styles": {
      "3D": "video_camera_3d.png",
      "Animated": "Video Camera.png"
    },
    "sortOrder": 1209
  },
  "📼": {
    "glyph": "📼",
    "group": "Objects",
    "keywords": [
      "tape",
      "vhs",
      "video",
      "videocassette"
    ],
    "dirName": "Videocassette",
    "styles": {
      "3D": "videocassette_3d.png",
      "Animated": "Videocassette.png"
    },
    "sortOrder": 1210
  },
  "🎻": {
    "glyph": "🎻",
    "group": "Objects",
    "keywords": [
      "instrument",
      "music",
      "violin"
    ],
    "dirName": "Violin",
    "styles": {
      "3D": "violin_3d.png",
      "Animated": "Violin.png"
    },
    "sortOrder": 1178
  },
  "♍": {
    "glyph": "♍",
    "group": "Symbols",
    "keywords": [
      "Virgo",
      "zodiac"
    ],
    "dirName": "Virgo",
    "styles": {
      "3D": "virgo_3d.png",
      "Animated": "Virgo.png"
    },
    "sortOrder": 1429
  },
  "🌋": {
    "glyph": "🌋",
    "group": "Travel & Places",
    "keywords": [
      "eruption",
      "mountain",
      "volcano"
    ],
    "dirName": "Volcano",
    "styles": {
      "3D": "volcano_3d.png",
      "Animated": "Volcano.png"
    },
    "sortOrder": 815
  },
  "🏐": {
    "glyph": "🏐",
    "group": "Activities",
    "keywords": [
      "ball",
      "game",
      "volleyball"
    ],
    "dirName": "Volleyball",
    "styles": {
      "3D": "volleyball_3d.png",
      "Animated": "Volleyball.png"
    },
    "sortOrder": 1055
  },
  "🆚": {
    "glyph": "🆚",
    "group": "Symbols",
    "keywords": [
      "versus",
      "vs",
      "VS button"
    ],
    "dirName": "Vs button",
    "styles": {
      "3D": "vs_button_3d.png",
      "Animated": "Vs Button.png"
    },
    "sortOrder": 1534
  },
  "🖖": {
    "glyph": "🖖",
    "group": "People & Body",
    "keywords": [
      "finger",
      "hand",
      "spock",
      "vulcan",
      "vulcan salute"
    ],
    "dirName": "Vulcan salute",
    "skintones": {
      "Default": {
        "3D": "vulcan_salute_3d_default.png",
        "Animated": "Vulcan Salute.png"
      }
    },
    "sortOrder": 168
  },
  "🧇": {
    "glyph": "🧇",
    "group": "Food & Drink",
    "keywords": [
      "breakfast",
      "indecisive",
      "iron",
      "waffle"
    ],
    "dirName": "Waffle",
    "styles": {
      "3D": "waffle_3d.png",
      "Animated": "Waffle.png"
    },
    "sortOrder": 716
  },
  "🌘": {
    "glyph": "🌘",
    "group": "Travel & Places",
    "keywords": [
      "crescent",
      "moon",
      "waning"
    ],
    "dirName": "Waning crescent moon",
    "styles": {
      "3D": "waning_crescent_moon_3d.png",
      "Animated": "Waning Crescent Moon.png"
    },
    "sortOrder": 984
  },
  "🌖": {
    "glyph": "🌖",
    "group": "Travel & Places",
    "keywords": [
      "gibbous",
      "moon",
      "waning"
    ],
    "dirName": "Waning gibbous moon",
    "styles": {
      "3D": "waning_gibbous_moon_3d.png",
      "Animated": "Waning Gibbous Moon.png"
    },
    "sortOrder": 982
  },
  "⚠️": {
    "glyph": "⚠️",
    "group": "Symbols",
    "keywords": [
      "warning"
    ],
    "dirName": "Warning",
    "styles": {
      "3D": "warning_3d.png",
      "Animated": "Warning.png"
    },
    "sortOrder": 1378
  },
  "🗑️": {
    "glyph": "🗑️",
    "group": "Objects",
    "keywords": [
      "wastebasket"
    ],
    "dirName": "Wastebasket",
    "styles": {
      "3D": "wastebasket_3d.png",
      "Animated": "Wastebasket.png"
    },
    "sortOrder": 1287
  },
  "⌚": {
    "glyph": "⌚",
    "group": "Travel & Places",
    "keywords": [
      "clock",
      "watch"
    ],
    "dirName": "Watch",
    "styles": {
      "3D": "watch_3d.png",
      "Animated": "Watch.png"
    },
    "sortOrder": 948
  },
  "🐃": {
    "glyph": "🐃",
    "group": "Animals & Nature",
    "keywords": [
      "buffalo",
      "water"
    ],
    "dirName": "Water buffalo",
    "styles": {
      "3D": "water_buffalo_3d.png",
      "Animated": "Water Buffalo.png"
    },
    "sortOrder": 557
  },
  "🚾": {
    "glyph": "🚾",
    "group": "Symbols",
    "keywords": [
      "closet",
      "lavatory",
      "restroom",
      "water",
      "wc"
    ],
    "dirName": "Water closet",
    "styles": {
      "3D": "water_closet_3d.png",
      "Animated": "Water Closet.png"
    },
    "sortOrder": 1373
  },
  "🔫": {
    "glyph": "🔫",
    "group": "Objects",
    "keywords": [
      "gun",
      "handgun",
      "pistol",
      "revolver",
      "tool",
      "water",
      "weapon"
    ],
    "dirName": "Water pistol",
    "styles": {
      "3D": "water_pistol_3d.png",
      "Animated": "Water Pistol.png"
    },
    "sortOrder": 1301
  },
  "🌊": {
    "glyph": "🌊",
    "group": "Travel & Places",
    "keywords": [
      "ocean",
      "water",
      "wave"
    ],
    "dirName": "Water wave",
    "styles": {
      "3D": "water_wave_3d.png",
      "Animated": "Water Wave.png"
    },
    "sortOrder": 1023
  },
  "🍉": {
    "glyph": "🍉",
    "group": "Food & Drink",
    "keywords": [
      "fruit",
      "watermelon"
    ],
    "dirName": "Watermelon",
    "styles": {
      "3D": "watermelon_3d.png",
      "Animated": "Watermelon.png"
    },
    "sortOrder": 676
  },
  "👋": {
    "glyph": "👋",
    "group": "People & Body",
    "keywords": [
      "hand",
      "wave",
      "waving"
    ],
    "dirName": "Waving hand",
    "skintones": {
      "Default": {
        "3D": "waving_hand_3d_default.png",
        "Animated": "Waving Hand.png"
      }
    },
    "sortOrder": 164
  },
  "〰️": {
    "glyph": "〰️",
    "group": "Symbols",
    "keywords": [
      "dash",
      "punctuation",
      "wavy"
    ],
    "dirName": "Wavy dash",
    "styles": {
      "3D": "wavy_dash_3d.png",
      "Animated": "Wavy Dash.png"
    },
    "sortOrder": 1476
  },
  "🌒": {
    "glyph": "🌒",
    "group": "Travel & Places",
    "keywords": [
      "crescent",
      "moon",
      "waxing"
    ],
    "dirName": "Waxing crescent moon",
    "styles": {
      "3D": "waxing_crescent_moon_3d.png",
      "Animated": "Waxing Crescent Moon.png"
    },
    "sortOrder": 978
  },
  "🌔": {
    "glyph": "🌔",
    "group": "Travel & Places",
    "keywords": [
      "gibbous",
      "moon",
      "waxing"
    ],
    "dirName": "Waxing gibbous moon",
    "styles": {
      "3D": "waxing_gibbous_moon_3d.png",
      "Animated": "Waxing Gibbous Moon.png"
    },
    "sortOrder": 980
  },
  "🙀": {
    "glyph": "🙀",
    "group": "Smileys & Emotion",
    "keywords": [
      "cat",
      "face",
      "oh",
      "surprised",
      "weary"
    ],
    "dirName": "Weary cat",
    "styles": {
      "3D": "weary_cat_3d.png",
      "Animated": "Weary Cat.png"
    },
    "sortOrder": 121
  },
  "😩": {
    "glyph": "😩",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "tired",
      "weary"
    ],
    "dirName": "Weary face",
    "styles": {
      "3D": "weary_face_3d.png",
      "Animated": "Weary Face.png"
    },
    "sortOrder": 96
  },
  "💒": {
    "glyph": "💒",
    "group": "Travel & Places",
    "keywords": [
      "chapel",
      "romance",
      "wedding"
    ],
    "dirName": "Wedding",
    "styles": {
      "3D": "wedding_3d.png",
      "Animated": "Wedding.png"
    },
    "sortOrder": 846
  },
  "🐋": {
    "glyph": "🐋",
    "group": "Animals & Nature",
    "keywords": [
      "whale"
    ],
    "dirName": "Whale",
    "styles": {
      "3D": "whale_3d.png",
      "Animated": "Whale.png"
    },
    "sortOrder": 622
  },
  "🛞": {
    "glyph": "🛞",
    "group": "Travel & Places",
    "keywords": [
      "circle",
      "tire",
      "turn",
      "wheel"
    ],
    "dirName": "Wheel",
    "styles": {
      "3D": "wheel_3d.png"
    },
    "sortOrder": 916
  },
  "☸️": {
    "glyph": "☸️",
    "group": "Symbols",
    "keywords": [
      "Buddhist",
      "dharma",
      "religion",
      "wheel",
      "wheel of dharma"
    ],
    "dirName": "Wheel of dharma",
    "styles": {
      "3D": "wheel_of_dharma_3d.png",
      "Animated": "Wheel of Dharma.png"
    },
    "sortOrder": 1416
  },
  "♿": {
    "glyph": "♿",
    "group": "Symbols",
    "keywords": [
      "access",
      "wheelchair symbol"
    ],
    "dirName": "Wheelchair symbol",
    "styles": {
      "3D": "wheelchair_symbol_3d.png",
      "Animated": "Wheelchair Symbol.png"
    },
    "sortOrder": 1368
  },
  "🦯": {
    "glyph": "🦯",
    "group": "Objects",
    "keywords": [
      "accessibility",
      "blind",
      "white cane"
    ],
    "dirName": "White cane",
    "styles": {
      "3D": "white_cane_3d.png",
      "Animated": "White Cane.png"
    },
    "sortOrder": 1312
  },
  "⚪": {
    "glyph": "⚪",
    "group": "Symbols",
    "keywords": [
      "circle",
      "geometric",
      "white circle"
    ],
    "dirName": "White circle",
    "styles": {
      "3D": "white_circle_3d.png",
      "Animated": "White Circle.png"
    },
    "sortOrder": 1560
  },
  "❕": {
    "glyph": "❕",
    "group": "Symbols",
    "keywords": [
      "!",
      "exclamation",
      "mark",
      "outlined",
      "punctuation",
      "white exclamation mark"
    ],
    "dirName": "White exclamation mark",
    "styles": {
      "3D": "white_exclamation_mark_3d.png",
      "Animated": "White Exclamation Mark.png"
    },
    "sortOrder": 1474
  },
  "🏳️": {
    "glyph": "🏳️",
    "group": "Flags",
    "keywords": [
      "waving",
      "white flag"
    ],
    "dirName": "White flag",
    "styles": {
      "3D": "white_flag_3d.png",
      "Animated": "White Flag.png"
    },
    "sortOrder": 1590
  },
  "💮": {
    "glyph": "💮",
    "group": "Animals & Nature",
    "keywords": [
      "flower",
      "white flower"
    ],
    "dirName": "White flower",
    "styles": {
      "3D": "white_flower_3d.png",
      "Animated": "White Flower.png"
    },
    "sortOrder": 650
  },
  "🤍": {
    "glyph": "🤍",
    "group": "Smileys & Emotion",
    "keywords": [
      "heart",
      "white"
    ],
    "dirName": "White heart",
    "styles": {
      "3D": "white_heart_3d.png",
      "Animated": "White Heart.png"
    },
    "sortOrder": 149
  },
  "⬜": {
    "glyph": "⬜",
    "group": "Symbols",
    "keywords": [
      "geometric",
      "square",
      "white large square"
    ],
    "dirName": "White large square",
    "styles": {
      "3D": "white_large_square_3d.png",
      "Animated": "White Large Square.png"
    },
    "sortOrder": 1569
  },
  "◻️": {
    "glyph": "◻️",
    "group": "Symbols",
    "keywords": [
      "geometric",
      "square",
      "white medium square"
    ],
    "dirName": "White medium square",
    "styles": {
      "3D": "white_medium_square_3d.png",
      "Animated": "White Medium Square.png"
    },
    "sortOrder": 1571
  },
  "◽": {
    "glyph": "◽",
    "group": "Symbols",
    "keywords": [
      "geometric",
      "square",
      "white medium-small square"
    ],
    "dirName": "White medium-small square",
    "styles": {
      "3D": "white_medium-small_square_3d.png",
      "Animated": "White Medium-Small Square.png"
    },
    "sortOrder": 1573
  },
  "❔": {
    "glyph": "❔",
    "group": "Symbols",
    "keywords": [
      "?",
      "mark",
      "outlined",
      "punctuation",
      "question",
      "white question mark"
    ],
    "dirName": "White question mark",
    "styles": {
      "3D": "white_question_mark_3d.png",
      "Animated": "White Question Mark.png"
    },
    "sortOrder": 1473
  },
  "▫️": {
    "glyph": "▫️",
    "group": "Symbols",
    "keywords": [
      "geometric",
      "square",
      "white small square"
    ],
    "dirName": "White small square",
    "styles": {
      "3D": "white_small_square_3d.png",
      "Animated": "White Small Square.png"
    },
    "sortOrder": 1575
  },
  "🔳": {
    "glyph": "🔳",
    "group": "Symbols",
    "keywords": [
      "button",
      "geometric",
      "outlined",
      "square",
      "white square button"
    ],
    "dirName": "White square button",
    "styles": {
      "3D": "white_square_button_3d.png",
      "Animated": "White Square Button.png"
    },
    "sortOrder": 1584
  },
  "🥀": {
    "glyph": "🥀",
    "group": "Animals & Nature",
    "keywords": [
      "flower",
      "wilted"
    ],
    "dirName": "Wilted flower",
    "styles": {
      "3D": "wilted_flower_3d.png",
      "Animated": "Wilted Flower.png"
    },
    "sortOrder": 654
  },
  "🎐": {
    "glyph": "🎐",
    "group": "Activities",
    "keywords": [
      "bell",
      "celebration",
      "chime",
      "wind"
    ],
    "dirName": "Wind chime",
    "styles": {
      "3D": "wind_chime_3d.png",
      "Animated": "Wind Chime.png"
    },
    "sortOrder": 1037
  },
  "🌬️": {
    "glyph": "🌬️",
    "group": "Travel & Places",
    "keywords": [
      "blow",
      "cloud",
      "face",
      "wind"
    ],
    "dirName": "Wind face",
    "styles": {
      "3D": "wind_face_3d.png",
      "Animated": "Wind Face.png"
    },
    "sortOrder": 1009
  },
  "🪟": {
    "glyph": "🪟",
    "group": "Objects",
    "keywords": [
      "frame",
      "fresh air",
      "opening",
      "transparent",
      "view",
      "window"
    ],
    "dirName": "Window",
    "styles": {
      "3D": "window_3d.png",
      "Animated": "Window.png"
    },
    "sortOrder": 1336
  },
  "🍷": {
    "glyph": "🍷",
    "group": "Food & Drink",
    "keywords": [
      "bar",
      "beverage",
      "drink",
      "glass",
      "wine"
    ],
    "dirName": "Wine glass",
    "styles": {
      "3D": "wine_glass_3d.png",
      "Animated": "Wine Glass.png"
    },
    "sortOrder": 786
  },
  "😉": {
    "glyph": "😉",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "wink",
      "winking face"
    ],
    "dirName": "Winking face",
    "styles": {
      "3D": "winking_face_3d.png",
      "Animated": "Winking Face.png"
    },
    "sortOrder": 12
  },
  "😜": {
    "glyph": "😜",
    "group": "Smileys & Emotion",
    "keywords": [
      "eye",
      "face",
      "joke",
      "tongue",
      "wink",
      "winking face with tongue"
    ],
    "dirName": "Winking face with tongue",
    "styles": {
      "3D": "winking_face_with_tongue_3d.png",
      "Animated": "Winking Face with Tongue.png"
    },
    "sortOrder": 26
  },
  "🐺": {
    "glyph": "🐺",
    "group": "Animals & Nature",
    "keywords": [
      "face",
      "wolf"
    ],
    "dirName": "Wolf",
    "styles": {
      "3D": "wolf_3d.png",
      "Animated": "Wolf.png"
    },
    "sortOrder": 539
  },
  "👩": {
    "glyph": "👩",
    "group": "People & Body",
    "keywords": [
      "adult",
      "woman"
    ],
    "dirName": "Woman",
    "skintones": {
      "Default": {
        "3D": "woman_3d_default.png",
        "Animated": "Woman.png"
      }
    },
    "sortOrder": 237
  },
  "👩‍🎨": {
    "glyph": "👩‍🎨",
    "group": "People & Body",
    "keywords": [
      "artist",
      "palette",
      "woman"
    ],
    "dirName": "Woman artist",
    "skintones": {
      "Default": {
        "3D": "woman_artist_3d_default.png",
        "Animated": "Woman Artist.png"
      }
    },
    "sortOrder": 319
  },
  "👩‍🚀": {
    "glyph": "👩‍🚀",
    "group": "People & Body",
    "keywords": [
      "astronaut",
      "rocket",
      "woman"
    ],
    "dirName": "Woman astronaut",
    "skintones": {
      "Default": {
        "3D": "woman_astronaut_3d_default.png",
        "Animated": "Woman Astronaut.png"
      }
    },
    "sortOrder": 325
  },
  "👩‍🦲": {
    "glyph": "👩‍🦲",
    "group": "People & Body",
    "keywords": [
      "adult",
      "bald",
      "woman"
    ],
    "dirName": "Woman bald",
    "skintones": {
      "Default": {
        "3D": "woman_bald_3d_default.png"
      }
    },
    "sortOrder": 244
  },
  "🧔‍♀️": {
    "glyph": "🧔‍♀️",
    "group": "People & Body",
    "keywords": [
      "beard",
      "woman",
      "woman: beard"
    ],
    "dirName": "Woman beard",
    "skintones": {
      "Default": {
        "3D": "woman_beard_3d_default.png"
      }
    },
    "sortOrder": 232
  },
  "🚴‍♀️": {
    "glyph": "🚴‍♀️",
    "group": "People & Body",
    "keywords": [
      "bicycle",
      "biking",
      "cyclist",
      "woman"
    ],
    "dirName": "Woman biking",
    "skintones": {
      "Default": {
        "3D": "woman_biking_3d_default.png",
        "Animated": "Woman Biking.png"
      }
    },
    "sortOrder": 458
  },
  "👱‍♀️": {
    "glyph": "👱‍♀️",
    "group": "People & Body",
    "keywords": [
      "blond-haired woman",
      "blonde",
      "hair",
      "woman",
      "woman: blond hair"
    ],
    "dirName": "Woman blonde hair",
    "skintones": {
      "Default": {
        "3D": "woman_blonde_hair_3d_default.png"
      }
    },
    "sortOrder": 246
  },
  "⛹️‍♀️": {
    "glyph": "⛹️‍♀️",
    "group": "People & Body",
    "keywords": [
      "ball",
      "woman",
      "woman bouncing ball"
    ],
    "dirName": "Woman bouncing ball",
    "skintones": {
      "Default": {
        "3D": "woman_bouncing_ball_3d_default.png",
        "Animated": "Woman Bouncing Ball.png"
      }
    },
    "sortOrder": 452
  },
  "🙇‍♀️": {
    "glyph": "🙇‍♀️",
    "group": "People & Body",
    "keywords": [
      "apology",
      "bowing",
      "favor",
      "gesture",
      "sorry",
      "woman"
    ],
    "dirName": "Woman bowing",
    "skintones": {
      "Default": {
        "3D": "woman_bowing_3d_default.png",
        "Animated": "Woman Bowing.png"
      }
    },
    "sortOrder": 274
  },
  "🤸‍♀️": {
    "glyph": "🤸‍♀️",
    "group": "People & Body",
    "keywords": [
      "cartwheel",
      "gymnastics",
      "woman",
      "woman cartwheeling"
    ],
    "dirName": "Woman cartwheeling",
    "skintones": {
      "Default": {
        "3D": "woman_cartwheeling_3d_default.png",
        "Animated": "Woman Cartwheeling.png"
      }
    },
    "sortOrder": 464
  },
  "🧗‍♀️": {
    "glyph": "🧗‍♀️",
    "group": "People & Body",
    "keywords": [
      "climber",
      "woman climbing"
    ],
    "dirName": "Woman climbing",
    "skintones": {
      "Default": {
        "3D": "woman_climbing_3d_default.png",
        "Animated": "Woman Climbing.png"
      }
    },
    "sortOrder": 433
  },
  "👷‍♀️": {
    "glyph": "👷‍♀️",
    "group": "People & Body",
    "keywords": [
      "construction",
      "woman",
      "worker"
    ],
    "dirName": "Woman construction worker",
    "skintones": {
      "Default": {
        "3D": "woman_construction_worker_3d_default.png",
        "Animated": "Woman Construction Worker.png"
      }
    },
    "sortOrder": 341
  },
  "👩‍🍳": {
    "glyph": "👩‍🍳",
    "group": "People & Body",
    "keywords": [
      "chef",
      "cook",
      "woman"
    ],
    "dirName": "Woman cook",
    "skintones": {
      "Default": {
        "3D": "woman_cook_3d_default.png",
        "Animated": "Woman Cook.png"
      }
    },
    "sortOrder": 298
  },
  "👩‍🦱": {
    "glyph": "👩‍🦱",
    "group": "People & Body",
    "keywords": [
      "adult",
      "curly hair",
      "woman"
    ],
    "dirName": "Woman curly hair",
    "skintones": {
      "Default": {
        "3D": "woman_curly_hair_3d_default.png"
      }
    },
    "sortOrder": 240
  },
  "💃": {
    "glyph": "💃",
    "group": "People & Body",
    "keywords": [
      "dance",
      "dancing",
      "woman"
    ],
    "dirName": "Woman dancing",
    "skintones": {
      "Default": {
        "3D": "woman_dancing_3d_default.png",
        "Animated": "Woman Dancing.png"
      }
    },
    "sortOrder": 422
  },
  "🧏‍♀️": {
    "glyph": "🧏‍♀️",
    "group": "People & Body",
    "keywords": [
      "deaf",
      "woman"
    ],
    "dirName": "Woman deaf",
    "skintones": {
      "Default": {
        "3D": "woman_deaf_3d_default.png",
        "Animated": "Deaf Woman.png"
      }
    },
    "sortOrder": 271
  },
  "🕵️‍♀️": {
    "glyph": "🕵️‍♀️",
    "group": "People & Body",
    "keywords": [
      "detective",
      "sleuth",
      "spy",
      "woman"
    ],
    "dirName": "Woman detective",
    "skintones": {
      "Default": {
        "3D": "woman_detective_3d_default.png",
        "Animated": "Woman Detective.png"
      }
    },
    "sortOrder": 334
  },
  "🧝‍♀️": {
    "glyph": "🧝‍♀️",
    "group": "People & Body",
    "keywords": [
      "magical",
      "woman elf"
    ],
    "dirName": "Woman elf",
    "skintones": {
      "Default": {
        "3D": "woman_elf_3d_default.png",
        "Animated": "Woman Elf.png"
      }
    },
    "sortOrder": 387
  },
  "🤦‍♀️": {
    "glyph": "🤦‍♀️",
    "group": "People & Body",
    "keywords": [
      "disbelief",
      "exasperation",
      "facepalm",
      "woman",
      "woman facepalming"
    ],
    "dirName": "Woman facepalming",
    "skintones": {
      "Default": {
        "3D": "woman_facepalming_3d_default.png",
        "Animated": "Woman Facepalming.png"
      }
    },
    "sortOrder": 277
  },
  "👩‍🏭": {
    "glyph": "👩‍🏭",
    "group": "People & Body",
    "keywords": [
      "assembly",
      "factory",
      "industrial",
      "woman",
      "worker"
    ],
    "dirName": "Woman factory worker",
    "skintones": {
      "Default": {
        "3D": "woman_factory_worker_3d_default.png",
        "Animated": "Woman Factory Worker.png"
      }
    },
    "sortOrder": 304
  },
  "🧚‍♀️": {
    "glyph": "🧚‍♀️",
    "group": "People & Body",
    "keywords": [
      "Titania",
      "woman fairy"
    ],
    "dirName": "Woman fairy",
    "skintones": {
      "Default": {
        "3D": "woman_fairy_3d_default.png",
        "Animated": "Woman Fairy.png"
      }
    },
    "sortOrder": 378
  },
  "👩‍🌾": {
    "glyph": "👩‍🌾",
    "group": "People & Body",
    "keywords": [
      "farmer",
      "gardener",
      "rancher",
      "woman"
    ],
    "dirName": "Woman farmer",
    "skintones": {
      "Default": {
        "3D": "woman_farmer_3d_default.png",
        "Animated": "Woman Farmer.png"
      }
    },
    "sortOrder": 295
  },
  "👩‍🍼": {
    "glyph": "👩‍🍼",
    "group": "People & Body",
    "keywords": [
      "baby",
      "feeding",
      "nursing",
      "woman"
    ],
    "dirName": "Woman feeding baby",
    "skintones": {
      "Default": {
        "3D": "woman_feeding_baby_3d_default.png",
        "Animated": "Woman Feeding Baby.png"
      }
    },
    "sortOrder": 360
  },
  "👩‍🚒": {
    "glyph": "👩‍🚒",
    "group": "People & Body",
    "keywords": [
      "firefighter",
      "firetruck",
      "woman"
    ],
    "dirName": "Woman firefighter",
    "skintones": {
      "Default": {
        "3D": "woman_firefighter_3d_default.png",
        "Animated": "Woman Firefighter.png"
      }
    },
    "sortOrder": 328
  },
  "🙍‍♀️": {
    "glyph": "🙍‍♀️",
    "group": "People & Body",
    "keywords": [
      "frowning",
      "gesture",
      "woman"
    ],
    "dirName": "Woman frowning",
    "skintones": {
      "Default": {
        "3D": "woman_frowning_3d_default.png",
        "Animated": "Woman Frowning.png"
      }
    },
    "sortOrder": 253
  },
  "🧞‍♀️": {
    "glyph": "🧞‍♀️",
    "group": "People & Body",
    "keywords": [
      "djinn",
      "woman genie"
    ],
    "dirName": "Woman genie",
    "styles": {
      "3D": "woman_genie_3d.png",
      "Animated": "Woman Genie.png"
    },
    "sortOrder": 390
  },
  "🙅‍♀️": {
    "glyph": "🙅‍♀️",
    "group": "People & Body",
    "keywords": [
      "forbidden",
      "gesture",
      "hand",
      "prohibited",
      "woman",
      "woman gesturing NO"
    ],
    "dirName": "Woman gesturing no",
    "skintones": {
      "Default": {
        "3D": "woman_gesturing_no_3d_default.png",
        "Animated": "Woman Gesturing No.png"
      }
    },
    "sortOrder": 259
  },
  "🙆‍♀️": {
    "glyph": "🙆‍♀️",
    "group": "People & Body",
    "keywords": [
      "gesture",
      "hand",
      "OK",
      "woman",
      "woman gesturing OK"
    ],
    "dirName": "Woman gesturing ok",
    "skintones": {
      "Default": {
        "3D": "woman_gesturing_ok_3d_default.png",
        "Animated": "Woman Gesturing OK.png"
      }
    },
    "sortOrder": 262
  },
  "💇‍♀️": {
    "glyph": "💇‍♀️",
    "group": "People & Body",
    "keywords": [
      "haircut",
      "woman",
      "woman getting haircut"
    ],
    "dirName": "Woman getting haircut",
    "skintones": {
      "Default": {
        "3D": "woman_getting_haircut_3d_default.png",
        "Animated": "Woman Getting Haircut.png"
      }
    },
    "sortOrder": 400
  },
  "💆‍♀️": {
    "glyph": "💆‍♀️",
    "group": "People & Body",
    "keywords": [
      "face",
      "massage",
      "woman",
      "woman getting massage"
    ],
    "dirName": "Woman getting massage",
    "skintones": {
      "Default": {
        "3D": "woman_getting_massage_3d_default.png",
        "Animated": "Woman Getting Massage.png"
      }
    },
    "sortOrder": 397
  },
  "🏌️‍♀️": {
    "glyph": "🏌️‍♀️",
    "group": "People & Body",
    "keywords": [
      "golf",
      "woman",
      "woman golfing"
    ],
    "dirName": "Woman golfing",
    "skintones": {
      "Default": {
        "3D": "woman_golfing_3d_default.png",
        "Animated": "Woman Golfing.png"
      }
    },
    "sortOrder": 440
  },
  "💂‍♀️": {
    "glyph": "💂‍♀️",
    "group": "People & Body",
    "keywords": [
      "guard",
      "woman"
    ],
    "dirName": "Woman guard",
    "skintones": {
      "Default": {
        "3D": "woman_guard_3d_default.png",
        "Animated": "Woman Guard.png"
      }
    },
    "sortOrder": 337
  },
  "👩‍⚕️": {
    "glyph": "👩‍⚕️",
    "group": "People & Body",
    "keywords": [
      "doctor",
      "healthcare",
      "nurse",
      "therapist",
      "woman",
      "woman health worker"
    ],
    "dirName": "Woman health worker",
    "skintones": {
      "Default": {
        "3D": "woman_health_worker_3d_default.png",
        "Animated": "Woman Health Worker.png"
      }
    },
    "sortOrder": 283
  },
  "🧘‍♀️": {
    "glyph": "🧘‍♀️",
    "group": "People & Body",
    "keywords": [
      "meditation",
      "woman in lotus position",
      "yoga"
    ],
    "dirName": "Woman in lotus position",
    "skintones": {
      "Default": {
        "3D": "woman_in_lotus_position_3d_default.png",
        "Animated": "Woman in Lotus Position.png"
      }
    },
    "sortOrder": 479
  },
  "👩‍🦽": {
    "glyph": "👩‍🦽",
    "group": "People & Body",
    "keywords": [
      "accessibility",
      "wheelchair",
      "woman",
      "woman in manual wheelchair"
    ],
    "dirName": "Woman in manual wheelchair",
    "skintones": {
      "Default": {
        "3D": "woman_in_manual_wheelchair_3d_default.png",
        "Animated": "Woman in Manual Wheelchair.png"
      }
    },
    "sortOrder": 418
  },
  "👩‍🦼": {
    "glyph": "👩‍🦼",
    "group": "People & Body",
    "keywords": [
      "accessibility",
      "wheelchair",
      "woman",
      "woman in motorized wheelchair"
    ],
    "dirName": "Woman in motorized wheelchair",
    "skintones": {
      "Default": {
        "3D": "woman_in_motorized_wheelchair_3d_default.png",
        "Animated": "Woman in Motorized Wheelchair.png"
      }
    },
    "sortOrder": 415
  },
  "🧖‍♀️": {
    "glyph": "🧖‍♀️",
    "group": "People & Body",
    "keywords": [
      "sauna",
      "steam room",
      "woman in steamy room"
    ],
    "dirName": "Woman in steamy room",
    "skintones": {
      "Default": {
        "3D": "woman_in_steamy_room_3d_default.png",
        "Animated": "Woman in Steamy Room.png"
      }
    },
    "sortOrder": 430
  },
  "🤵‍♀️": {
    "glyph": "🤵‍♀️",
    "group": "People & Body",
    "keywords": [
      "tuxedo",
      "woman",
      "woman in tuxedo"
    ],
    "dirName": "Woman in tuxedo",
    "skintones": {
      "Default": {
        "3D": "woman_in_tuxedo_3d_default.png",
        "Animated": "Woman in Tuxedo.png"
      }
    },
    "sortOrder": 352
  },
  "👩‍⚖️": {
    "glyph": "👩‍⚖️",
    "group": "People & Body",
    "keywords": [
      "judge",
      "justice",
      "scales",
      "woman"
    ],
    "dirName": "Woman judge",
    "skintones": {
      "Default": {
        "3D": "woman_judge_3d_default.png",
        "Animated": "Woman Judge.png"
      }
    },
    "sortOrder": 292
  },
  "🤹‍♀️": {
    "glyph": "🤹‍♀️",
    "group": "People & Body",
    "keywords": [
      "juggling",
      "multitask",
      "woman"
    ],
    "dirName": "Woman juggling",
    "skintones": {
      "Default": {
        "3D": "woman_juggling_3d_default.png",
        "Animated": "Woman Juggling.png"
      }
    },
    "sortOrder": 476
  },
  "🧎‍♀️": {
    "glyph": "🧎‍♀️",
    "group": "People & Body",
    "keywords": [
      "kneeling",
      "woman"
    ],
    "dirName": "Woman kneeling",
    "skintones": {
      "Default": {
        "3D": "woman_kneeling_3d_default.png",
        "Animated": "Woman Kneeling.png"
      }
    },
    "sortOrder": 409
  },
  "🏋️‍♀️": {
    "glyph": "🏋️‍♀️",
    "group": "People & Body",
    "keywords": [
      "weight lifter",
      "woman",
      "woman lifting weights"
    ],
    "dirName": "Woman lifting weights",
    "skintones": {
      "Default": {
        "3D": "woman_lifting_weights_3d_default.png",
        "Animated": "Woman Lifting Weights.png"
      }
    },
    "sortOrder": 455
  },
  "🧙‍♀️": {
    "glyph": "🧙‍♀️",
    "group": "People & Body",
    "keywords": [
      "sorceress",
      "witch",
      "woman mage"
    ],
    "dirName": "Woman mage",
    "skintones": {
      "Default": {
        "3D": "woman_mage_3d_default.png",
        "Animated": "Woman Mage.png"
      }
    },
    "sortOrder": 375
  },
  "👩‍🔧": {
    "glyph": "👩‍🔧",
    "group": "People & Body",
    "keywords": [
      "electrician",
      "mechanic",
      "plumber",
      "tradesperson",
      "woman"
    ],
    "dirName": "Woman mechanic",
    "skintones": {
      "Default": {
        "3D": "woman_mechanic_3d_default.png",
        "Animated": "Woman Mechanic.png"
      }
    },
    "sortOrder": 301
  },
  "🧜‍♀️": {
    "glyph": "🧜‍♀️",
    "group": "People & Body",
    "keywords": [
      "mermaid",
      "merwoman"
    ],
    "dirName": "Woman merpeople",
    "skintones": {
      "Default": {
        "3D": "woman_merpeople_3d_default.png",
        "Animated": "Mermaid.png"
      }
    },
    "sortOrder": 384
  },
  "🚵‍♀️": {
    "glyph": "🚵‍♀️",
    "group": "People & Body",
    "keywords": [
      "bicycle",
      "bike",
      "biking",
      "cyclist",
      "mountain",
      "woman"
    ],
    "dirName": "Woman mountain biking",
    "skintones": {
      "Default": {
        "3D": "woman_mountain_biking_3d_default.png",
        "Animated": "Woman Mountain Biking.png"
      }
    },
    "sortOrder": 461
  },
  "👩‍💼": {
    "glyph": "👩‍💼",
    "group": "People & Body",
    "keywords": [
      "architect",
      "business",
      "manager",
      "white-collar",
      "woman",
      "woman office worker"
    ],
    "dirName": "Woman office worker",
    "skintones": {
      "Default": {
        "3D": "woman_office_worker_3d_default.png",
        "Animated": "Woman Office Worker.png"
      }
    },
    "sortOrder": 307
  },
  "👩‍✈️": {
    "glyph": "👩‍✈️",
    "group": "People & Body",
    "keywords": [
      "pilot",
      "plane",
      "woman"
    ],
    "dirName": "Woman pilot",
    "skintones": {
      "Default": {
        "3D": "woman_pilot_3d_default.png"
      }
    },
    "sortOrder": 322
  },
  "🤾‍♀️": {
    "glyph": "🤾‍♀️",
    "group": "People & Body",
    "keywords": [
      "handball",
      "woman",
      "woman playing handball"
    ],
    "dirName": "Woman playing handball",
    "skintones": {
      "Default": {
        "3D": "woman_playing_handball_3d_default.png",
        "Animated": "Woman Playing Handball.png"
      }
    },
    "sortOrder": 473
  },
  "🤽‍♀️": {
    "glyph": "🤽‍♀️",
    "group": "People & Body",
    "keywords": [
      "water polo",
      "woman",
      "woman playing water polo"
    ],
    "dirName": "Woman playing water polo",
    "skintones": {
      "Default": {
        "3D": "woman_playing_water_polo_3d_default.png",
        "Animated": "Woman Playing Water Polo.png"
      }
    },
    "sortOrder": 470
  },
  "👮‍♀️": {
    "glyph": "👮‍♀️",
    "group": "People & Body",
    "keywords": [
      "cop",
      "officer",
      "police",
      "woman"
    ],
    "dirName": "Woman police officer",
    "skintones": {
      "Default": {
        "3D": "woman_police_officer_3d_default.png",
        "Animated": "Woman Police Officer.png"
      }
    },
    "sortOrder": 331
  },
  "🙎‍♀️": {
    "glyph": "🙎‍♀️",
    "group": "People & Body",
    "keywords": [
      "gesture",
      "pouting",
      "woman"
    ],
    "dirName": "Woman pouting",
    "skintones": {
      "Default": {
        "3D": "woman_pouting_3d_default.png",
        "Animated": "Woman Pouting.png"
      }
    },
    "sortOrder": 256
  },
  "🙋‍♀️": {
    "glyph": "🙋‍♀️",
    "group": "People & Body",
    "keywords": [
      "gesture",
      "raising hand",
      "woman",
      "woman raising hand"
    ],
    "dirName": "Woman raising hand",
    "skintones": {
      "Default": {
        "3D": "woman_raising_hand_3d_default.png",
        "Animated": "Woman Raising Hand.png"
      }
    },
    "sortOrder": 268
  },
  "👩‍🦰": {
    "glyph": "👩‍🦰",
    "group": "People & Body",
    "keywords": [
      "adult",
      "red hair",
      "woman"
    ],
    "dirName": "Woman red hair",
    "skintones": {
      "Default": {
        "3D": "woman_red_hair_3d_default.png"
      }
    },
    "sortOrder": 238
  },
  "🚣‍♀️": {
    "glyph": "🚣‍♀️",
    "group": "People & Body",
    "keywords": [
      "boat",
      "rowboat",
      "woman",
      "woman rowing boat"
    ],
    "dirName": "Woman rowing boat",
    "skintones": {
      "Default": {
        "3D": "woman_rowing_boat_3d_default.png",
        "Animated": "Woman Rowing Boat.png"
      }
    },
    "sortOrder": 446
  },
  "🏃‍♀️": {
    "glyph": "🏃‍♀️",
    "group": "People & Body",
    "keywords": [
      "marathon",
      "racing",
      "running",
      "woman"
    ],
    "dirName": "Woman running",
    "skintones": {
      "Default": {
        "3D": "woman_running_3d_default.png",
        "Animated": "Woman Running.png"
      }
    },
    "sortOrder": 421
  },
  "👩‍🔬": {
    "glyph": "👩‍🔬",
    "group": "People & Body",
    "keywords": [
      "biologist",
      "chemist",
      "engineer",
      "physicist",
      "scientist",
      "woman"
    ],
    "dirName": "Woman scientist",
    "skintones": {
      "Default": {
        "3D": "woman_scientist_3d_default.png",
        "Animated": "Woman Scientist.png"
      }
    },
    "sortOrder": 310
  },
  "🤷‍♀️": {
    "glyph": "🤷‍♀️",
    "group": "People & Body",
    "keywords": [
      "doubt",
      "ignorance",
      "indifference",
      "shrug",
      "woman",
      "woman shrugging"
    ],
    "dirName": "Woman shrugging",
    "skintones": {
      "Default": {
        "3D": "woman_shrugging_3d_default.png",
        "Animated": "Woman Shrugging.png"
      }
    },
    "sortOrder": 280
  },
  "👩‍🎤": {
    "glyph": "👩‍🎤",
    "group": "People & Body",
    "keywords": [
      "actor",
      "entertainer",
      "rock",
      "singer",
      "star",
      "woman"
    ],
    "dirName": "Woman singer",
    "skintones": {
      "Default": {
        "3D": "woman_singer_3d_default.png",
        "Animated": "Woman Singer.png"
      }
    },
    "sortOrder": 316
  },
  "🧍‍♀️": {
    "glyph": "🧍‍♀️",
    "group": "People & Body",
    "keywords": [
      "standing",
      "woman"
    ],
    "dirName": "Woman standing",
    "skintones": {
      "Default": {
        "3D": "woman_standing_3d_default.png",
        "Animated": "Woman Standing.png"
      }
    },
    "sortOrder": 406
  },
  "👩‍🎓": {
    "glyph": "👩‍🎓",
    "group": "People & Body",
    "keywords": [
      "graduate",
      "student",
      "woman"
    ],
    "dirName": "Woman student",
    "skintones": {
      "Default": {
        "3D": "woman_student_3d_default.png",
        "Animated": "Woman Student.png"
      }
    },
    "sortOrder": 286
  },
  "🦸‍♀️": {
    "glyph": "🦸‍♀️",
    "group": "People & Body",
    "keywords": [
      "good",
      "hero",
      "heroine",
      "superpower",
      "woman",
      "woman superhero"
    ],
    "dirName": "Woman superhero",
    "skintones": {
      "Default": {
        "3D": "woman_superhero_3d_default.png",
        "Animated": "Woman Superhero.png"
      }
    },
    "sortOrder": 369
  },
  "🦹‍♀️": {
    "glyph": "🦹‍♀️",
    "group": "People & Body",
    "keywords": [
      "criminal",
      "evil",
      "superpower",
      "villain",
      "woman",
      "woman supervillain"
    ],
    "dirName": "Woman supervillain",
    "skintones": {
      "Default": {
        "3D": "woman_supervillain_3d_default.png",
        "Animated": "Woman Supervillain.png"
      }
    },
    "sortOrder": 372
  },
  "🏄‍♀️": {
    "glyph": "🏄‍♀️",
    "group": "People & Body",
    "keywords": [
      "surfing",
      "woman"
    ],
    "dirName": "Woman surfing",
    "skintones": {
      "Default": {
        "3D": "woman_surfing_3d_default.png",
        "Animated": "Woman Surfing.png"
      }
    },
    "sortOrder": 443
  },
  "🏊‍♀️": {
    "glyph": "🏊‍♀️",
    "group": "People & Body",
    "keywords": [
      "swim",
      "woman",
      "woman swimming"
    ],
    "dirName": "Woman swimming",
    "skintones": {
      "Default": {
        "3D": "woman_swimming_3d_default.png",
        "Animated": "Woman Swimming.png"
      }
    },
    "sortOrder": 449
  },
  "👩‍🏫": {
    "glyph": "👩‍🏫",
    "group": "People & Body",
    "keywords": [
      "instructor",
      "professor",
      "teacher",
      "woman"
    ],
    "dirName": "Woman teacher",
    "skintones": {
      "Default": {
        "3D": "woman_teacher_3d_default.png",
        "Animated": "Woman Teacher.png"
      }
    },
    "sortOrder": 289
  },
  "💁‍♀️": {
    "glyph": "💁‍♀️",
    "group": "People & Body",
    "keywords": [
      "sassy",
      "tipping hand",
      "woman",
      "woman tipping hand"
    ],
    "dirName": "Woman tipping hand",
    "skintones": {
      "Default": {
        "3D": "woman_tipping_hand_3d_default.png",
        "Animated": "Woman Tipping Hand.png"
      }
    },
    "sortOrder": 265
  },
  "🧛‍♀️": {
    "glyph": "🧛‍♀️",
    "group": "People & Body",
    "keywords": [
      "undead",
      "woman vampire"
    ],
    "dirName": "Woman vampire",
    "skintones": {
      "Default": {
        "3D": "woman_vampire_3d_default.png",
        "Animated": "Woman Vampire.png"
      }
    },
    "sortOrder": 381
  },
  "🚶‍♀️": {
    "glyph": "🚶‍♀️",
    "group": "People & Body",
    "keywords": [
      "hike",
      "walk",
      "woman",
      "woman walking"
    ],
    "dirName": "Woman walking",
    "skintones": {
      "Default": {
        "3D": "woman_walking_3d_default.png",
        "Animated": "Woman Walking.png"
      }
    },
    "sortOrder": 403
  },
  "👳‍♀️": {
    "glyph": "👳‍♀️",
    "group": "People & Body",
    "keywords": [
      "turban",
      "woman",
      "woman wearing turban"
    ],
    "dirName": "Woman wearing turban",
    "skintones": {
      "Default": {
        "3D": "woman_wearing_turban_3d_default.png",
        "Animated": "Woman Wearing Turban.png"
      }
    },
    "sortOrder": 347
  },
  "👩‍🦳": {
    "glyph": "👩‍🦳",
    "group": "People & Body",
    "keywords": [
      "adult",
      "white hair",
      "woman"
    ],
    "dirName": "Woman white hair",
    "skintones": {
      "Default": {
        "3D": "woman_white_hair_3d_default.png"
      }
    },
    "sortOrder": 242
  },
  "👯‍♀️": {
    "glyph": "👯‍♀️",
    "group": "People & Body",
    "keywords": [
      "bunny ear",
      "dancer",
      "partying",
      "women",
      "women with bunny ears"
    ],
    "dirName": "Woman with bunny ears",
    "styles": {
      "3D": "woman_with_bunny_ears_3d.png",
      "Animated": "Women with Bunny Ears.png"
    },
    "sortOrder": 427
  },
  "🧕": {
    "glyph": "🧕",
    "group": "People & Body",
    "keywords": [
      "headscarf",
      "hijab",
      "mantilla",
      "tichel",
      "woman with headscarf"
    ],
    "dirName": "Woman with headscarf",
    "skintones": {
      "Default": {
        "3D": "woman_with_headscarf_3d_default.png",
        "Animated": "Woman with Headscarf.png"
      }
    },
    "sortOrder": 349
  },
  "👰‍♀️": {
    "glyph": "👰‍♀️",
    "group": "People & Body",
    "keywords": [
      "veil",
      "woman",
      "woman with veil"
    ],
    "dirName": "Woman with veil",
    "skintones": {
      "Default": {
        "3D": "woman_with_veil_3d_default.png",
        "Animated": "Woman with Veil.png"
      }
    },
    "sortOrder": 355
  },
  "👩‍🦯": {
    "glyph": "👩‍🦯",
    "group": "People & Body",
    "keywords": [
      "accessibility",
      "blind",
      "woman",
      "woman with white cane"
    ],
    "dirName": "Woman with white cane",
    "skintones": {
      "Default": {
        "3D": "woman_with_white_cane_3d_default.png",
        "Animated": "Woman with White Cane.png"
      }
    },
    "sortOrder": 412
  },
  "🤼‍♀️": {
    "glyph": "🤼‍♀️",
    "group": "People & Body",
    "keywords": [
      "women",
      "women wrestling",
      "wrestle"
    ],
    "dirName": "Woman wrestling",
    "styles": {
      "3D": "woman_wrestling_3d.png",
      "Animated": "Women Wrestling.png"
    },
    "sortOrder": 467
  },
  "🧟‍♀️": {
    "glyph": "🧟‍♀️",
    "group": "People & Body",
    "keywords": [
      "undead",
      "walking dead",
      "woman zombie"
    ],
    "dirName": "Woman zombie",
    "styles": {
      "3D": "woman_zombie_3d.png",
      "Animated": "Woman Zombie.png"
    },
    "sortOrder": 393
  },
  "👢": {
    "glyph": "👢",
    "group": "Objects",
    "keywords": [
      "boot",
      "clothing",
      "shoe",
      "woman",
      "woman’s boot"
    ],
    "dirName": "Womans boot",
    "styles": {
      "3D": "womans_boot_3d.png",
      "Animated": "Woman’s Boot.png"
    },
    "sortOrder": 1143
  },
  "👚": {
    "glyph": "👚",
    "group": "Objects",
    "keywords": [
      "clothing",
      "woman",
      "woman’s clothes"
    ],
    "dirName": "Womans clothes",
    "styles": {
      "3D": "womans_clothes_3d.png",
      "Animated": "Woman’s Clothes.png"
    },
    "sortOrder": 1129
  },
  "👒": {
    "glyph": "👒",
    "group": "Objects",
    "keywords": [
      "clothing",
      "hat",
      "woman",
      "woman’s hat"
    ],
    "dirName": "Womans hat",
    "styles": {
      "3D": "womans_hat_3d.png",
      "Animated": "Woman’s Hat.png"
    },
    "sortOrder": 1145
  },
  "👡": {
    "glyph": "👡",
    "group": "Objects",
    "keywords": [
      "clothing",
      "sandal",
      "shoe",
      "woman",
      "woman’s sandal"
    ],
    "dirName": "Womans sandal",
    "styles": {
      "3D": "womans_sandal_3d.png",
      "Animated": "Woman’s Sandal.png"
    },
    "sortOrder": 1141
  },
  "🚺": {
    "glyph": "🚺",
    "group": "Symbols",
    "keywords": [
      "lavatory",
      "restroom",
      "wc",
      "woman",
      "women’s room"
    ],
    "dirName": "Womens room",
    "styles": {
      "3D": "womens_room_3d.png",
      "Animated": "Women’s Room.png"
    },
    "sortOrder": 1370
  },
  "🪵": {
    "glyph": "🪵",
    "group": "Travel & Places",
    "keywords": [
      "log",
      "lumber",
      "timber",
      "wood"
    ],
    "dirName": "Wood",
    "styles": {
      "3D": "wood_3d.png",
      "Animated": "Wood.png"
    },
    "sortOrder": 827
  },
  "🥴": {
    "glyph": "🥴",
    "group": "Smileys & Emotion",
    "keywords": [
      "dizzy",
      "intoxicated",
      "tipsy",
      "uneven eyes",
      "wavy mouth",
      "woozy face"
    ],
    "dirName": "Woozy face",
    "styles": {
      "3D": "woozy_face_3d.png",
      "Animated": "Woozy Face.png"
    },
    "sortOrder": 63
  },
  "🗺️": {
    "glyph": "🗺️",
    "group": "Travel & Places",
    "keywords": [
      "map",
      "world"
    ],
    "dirName": "World map",
    "styles": {
      "3D": "world_map_3d.png",
      "Animated": "World Map.png"
    },
    "sortOrder": 810
  },
  "🪱": {
    "glyph": "🪱",
    "group": "Animals & Nature",
    "keywords": [
      "annelid",
      "earthworm",
      "parasite",
      "worm"
    ],
    "dirName": "Worm",
    "styles": {
      "3D": "worm_3d.png",
      "Animated": "Worm.png"
    },
    "sortOrder": 646
  },
  "😟": {
    "glyph": "😟",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "worried"
    ],
    "dirName": "Worried face",
    "styles": {
      "3D": "worried_face_3d.png",
      "Animated": "Worried Face.png"
    },
    "sortOrder": 75
  },
  "🎁": {
    "glyph": "🎁",
    "group": "Activities",
    "keywords": [
      "box",
      "celebration",
      "gift",
      "present",
      "wrapped"
    ],
    "dirName": "Wrapped gift",
    "styles": {
      "3D": "wrapped_gift_3d.png",
      "Animated": "Wrapped Gift.png"
    },
    "sortOrder": 1041
  },
  "🔧": {
    "glyph": "🔧",
    "group": "Objects",
    "keywords": [
      "spanner",
      "tool",
      "wrench"
    ],
    "dirName": "Wrench",
    "styles": {
      "3D": "wrench_3d.png",
      "Animated": "Wrench.png"
    },
    "sortOrder": 1306
  },
  "✍️": {
    "glyph": "✍️",
    "group": "People & Body",
    "keywords": [
      "hand",
      "write",
      "writing hand"
    ],
    "dirName": "Writing hand",
    "skintones": {
      "Default": {
        "3D": "writing_hand_3d_default.png",
        "Animated": "Writing Hand.png"
      }
    },
    "sortOrder": 202
  },
  "🩻": {
    "glyph": "🩻",
    "group": "Objects",
    "keywords": [
      "bones",
      "doctor",
      "medical",
      "skeleton",
      "x-ray"
    ],
    "dirName": "X-ray",
    "styles": {
      "3D": "x-ray_3d.png",
      "Animated": "X-Ray.png"
    },
    "sortOrder": 1332
  },
  "🧶": {
    "glyph": "🧶",
    "group": "Activities",
    "keywords": [
      "ball",
      "crochet",
      "knit",
      "yarn"
    ],
    "dirName": "Yarn",
    "styles": {
      "3D": "yarn_3d.png",
      "Animated": "Yarn.png"
    },
    "sortOrder": 1108
  },
  "🥱": {
    "glyph": "🥱",
    "group": "Smileys & Emotion",
    "keywords": [
      "bored",
      "tired",
      "yawn",
      "yawning face"
    ],
    "dirName": "Yawning face",
    "styles": {
      "3D": "yawning_face_3d.png",
      "Animated": "Yawning Face.png"
    },
    "sortOrder": 98
  },
  "🟡": {
    "glyph": "🟡",
    "group": "Symbols",
    "keywords": [
      "circle",
      "yellow"
    ],
    "dirName": "Yellow circle",
    "styles": {
      "3D": "yellow_circle_3d.png",
      "Animated": "Yellow Circle.png"
    },
    "sortOrder": 1554
  },
  "💛": {
    "glyph": "💛",
    "group": "Smileys & Emotion",
    "keywords": [
      "yellow",
      "yellow heart"
    ],
    "dirName": "Yellow heart",
    "styles": {
      "3D": "yellow_heart_3d.png",
      "Animated": "Yellow Heart.png"
    },
    "sortOrder": 143
  },
  "🟨": {
    "glyph": "🟨",
    "group": "Symbols",
    "keywords": [
      "square",
      "yellow"
    ],
    "dirName": "Yellow square",
    "styles": {
      "3D": "yellow_square_3d.png",
      "Animated": "Yellow Square.png"
    },
    "sortOrder": 1563
  },
  "💴": {
    "glyph": "💴",
    "group": "Objects",
    "keywords": [
      "banknote",
      "bill",
      "currency",
      "money",
      "note",
      "yen"
    ],
    "dirName": "Yen banknote",
    "styles": {
      "3D": "yen_banknote_3d.png",
      "Animated": "Yen Banknote.png"
    },
    "sortOrder": 1237
  },
  "☯️": {
    "glyph": "☯️",
    "group": "Symbols",
    "keywords": [
      "religion",
      "tao",
      "taoist",
      "yang",
      "yin"
    ],
    "dirName": "Yin yang",
    "styles": {
      "3D": "yin_yang_3d.png",
      "Animated": "Yin Yang.png"
    },
    "sortOrder": 1417
  },
  "🪀": {
    "glyph": "🪀",
    "group": "Activities",
    "keywords": [
      "fluctuate",
      "toy",
      "yo-yo"
    ],
    "dirName": "Yo-yo",
    "styles": {
      "3D": "yo-yo_3d.png",
      "Animated": "Yo-Yo.png"
    },
    "sortOrder": 1079
  },
  "🤪": {
    "glyph": "🤪",
    "group": "Smileys & Emotion",
    "keywords": [
      "eye",
      "goofy",
      "large",
      "small",
      "zany face"
    ],
    "dirName": "Zany face",
    "styles": {
      "3D": "zany_face_3d.png",
      "Animated": "Zany Face.png"
    },
    "sortOrder": 27
  },
  "🦓": {
    "glyph": "🦓",
    "group": "Animals & Nature",
    "keywords": [
      "stripe",
      "zebra"
    ],
    "dirName": "Zebra",
    "styles": {
      "3D": "zebra_3d.png",
      "Animated": "Zebra.png"
    },
    "sortOrder": 552
  },
  "🤐": {
    "glyph": "🤐",
    "group": "Smileys & Emotion",
    "keywords": [
      "face",
      "mouth",
      "zipper",
      "zipper-mouth face"
    ],
    "dirName": "Zipper-mouth face",
    "styles": {
      "3D": "zipper-mouth_face_3d.png",
      "Animated": "Zipper-Mouth Face.png"
    },
    "sortOrder": 37
  },
  "💤": {
    "glyph": "💤",
    "group": "Smileys & Emotion",
    "keywords": [
      "comic",
      "sleep",
      "zzz"
    ],
    "dirName": "Zzz",
    "styles": {
      "3D": "zzz_3d.png",
      "Animated": "Zzz.png"
    },
    "sortOrder": 163
  },
  "🤜🤛": {
    "glyph": "🤜🤛",
    "group": "People & Body",
    "keywords": [
      "fist bump",
      "left-facing fist",
      "right-facing fist",
      "leftwards",
      "rightwards"
    ],
    "dirName": "Fist bump",
    "skintones": {
      "Default": {
        "3D": "fist_bump_3d_default.png"
      }
    },
    "sortOrder": 0
  }
}