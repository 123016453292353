import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AsyncPipe, NgClass } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { fromEvent, map } from 'rxjs';

import { EmojiComponent } from '../emojis/emoji/emoji.component';
import { EmojiPickerHoverComponent } from './hover/emoji-picker-hover/emoji-picker-hover.component';

import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

import { GetEmojiPipe } from '../../pipes/get-emoji.pipe';

import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';

import { PICKER_POSITIONS } from '../../models/services/emoji.model';
import { TooltipDirectionList } from '../../models/tooltip.model';

@Component({
  selector: 'app-emoji-picker',
  standalone: true,
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss'],
  imports: [NgClass, TooltipDirective, EmojiPickerHoverComponent, EmojiComponent, GetEmojiPipe, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiPickerComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  public readonly authService = inject(AuthService);

  public readonly userService = inject(UserService);

  public readonly hoverPositions$ = this.authService.isMobileWidth$.pipe(
    map((isMobileWidth) => (isMobileWidth ? new TooltipDirectionList([]) : PICKER_POSITIONS)),
  );

  public hoverCloseTrigger = false;
  public isScrolledToBottom = false;

  public selectingEmoji: string | null = null;

  @Input({ required: true }) public selectedEmoji: string | null = null;
  @Input() public animateEmoji = false;

  @Output() public hoverOpen = new EventEmitter<boolean>();

  public ngOnInit(): void {
    if (this.authService.isIPadOs) {
      fromEvent(window, 'scroll', { passive: true })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          const scrollPosition = window.pageYOffset;
          const documentHeight = document.documentElement.scrollHeight;
          const viewportHeight = window.innerHeight;
          const maxScrollPosition = documentHeight - viewportHeight;
          this.isScrolledToBottom = scrollPosition >= maxScrollPosition;
        });
    }
  }

  public onEmojiChanged(emoji?: string | null): void {
    this.hoverCloseTrigger = true;
    this.changeDetectorRef.detectChanges();

    const emojiHasChanged = emoji !== undefined && emoji !== this.userService.userInfo?.user.selectedEmoji;
    if (!emojiHasChanged) return;

    this.selectingEmoji = emoji;
    this.userService.userInfo!.user.selectedEmoji = emoji;
    this.changeDetectorRef.detectChanges();

    this.userService.updateSelectedEmoji(emoji).subscribe(() => {
      this.selectingEmoji = null;
      this.changeDetectorRef.detectChanges();
    });
  }
}
