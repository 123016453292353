import { Subscription as MSGraphSubscription } from '@microsoft/microsoft-graph-types';
import { addMilliseconds, differenceInMilliseconds, subMinutes } from 'date-fns';

import { AuthService } from '../../services/auth.service';

import { BatchPayload, BatchResponse, Subscription } from '../../models/misc.model';
import { Chat } from '../../models/services/chat/chat.model';
import {
  CHAT_WEBHOOK_EXPIRATION_MS,
  CHAT_WEBHOOK_REFRESH_MS,
  CreateWebhookBody,
  UpdateWebhookBody,
} from '../../models/services/webhook.model';

import { environment } from 'src/environments/environment';

export function getWebhookRefreshDueInMs(chat?: Chat): number {
  if (chat?.subscription) {
    const now = new Date();
    const dueAt = subMinutes(chat.subscription.expiration, 1);

    return differenceInMilliseconds(dueAt, now);
  }

  return 0;
}

export function getWebhookCreateBatchPayload(
  chatId: string,
  notificationUrl: string,
  lifecycleNotificationUrl: string,
): BatchPayload<CreateWebhookBody> {
  return {
    id: chatId,
    method: 'POST',
    url: '/subscriptions',
    body: {
      changeType: 'created,updated,deleted',
      notificationUrl,
      lifecycleNotificationUrl,
      resource: `/chats/${chatId}/messages`,
      expirationDateTime: addMilliseconds(new Date(), CHAT_WEBHOOK_EXPIRATION_MS).toISOString(),
    },
    headers: { 'Content-Type': 'application/json' },
  };
}

export function getChatMessageNotificationUrl(authService: AuthService): string {
  const { user } = authService.context!;

  return `${environment.backendUrl}/api/${user!.tenant!.id}/webhook/chat-message?userObjectId=${user!.id}`;
}

export function getChatMessageNotificationLifecycleUrl(authService: AuthService): string {
  const { user } = authService.context!;

  return `${environment.backendUrl}/api/${user!.tenant!.id}/webhook/lifecycle-notifications?userObjectId=${user!.id}`;
}

export function getChatsForWebhookRequest(chats: Chat[]): Chat[] {
  return chats.filter((chat) => {
    const webhookRefreshDueInMs = getWebhookRefreshDueInMs(chat);
    const webhookExists = webhookRefreshDueInMs > 0;

    // Eligible if webhook does not exist or is about to expire
    return !webhookExists || webhookRefreshDueInMs < CHAT_WEBHOOK_REFRESH_MS;
  });
}

export function getFailedRefreshResponses(chats: Chat[], batchResponses: BatchResponse[]): BatchResponse[] {
  return batchResponses.filter((batchResponse) => {
    const chat = chats.find((chat) => chat.id === batchResponse.id);

    return !isSuccessBatch(batchResponse) && !shouldCreateWebhook(chat);
  });
}

export function getSuccessResponses(batchResponses: BatchResponse[]): BatchResponse[] {
  return batchResponses.filter((batchResponse) => isSuccessBatch(batchResponse));
}

export function getFailedResponses(batchResponses: BatchResponse[]): BatchResponse[] {
  return batchResponses.filter((batchResponses) => !isSuccessBatch(batchResponses));
}

export function getSubscription(chatId: string, batchResponses: BatchResponse[]): Subscription {
  const batchResponse = batchResponses.find((batchResponse) => batchResponse.id === chatId);
  const subscription = batchResponse!.body! as MSGraphSubscription;

  return { id: subscription.id!, expiration: new Date(subscription.expirationDateTime!) };
}

export function getWebhookBatchPayloads(
  chats: Chat[],
  notificationUrl: string,
  lifecycleNotificationUrl: string,
): BatchPayload<CreateWebhookBody | UpdateWebhookBody>[] {
  return chats.map((chat) => {
    if (shouldCreateWebhook(chat)) {
      return getWebhookCreateBatchPayload(chat.id, notificationUrl, lifecycleNotificationUrl);
    }

    return getUpdateWebhookBatchPayload(chat);
  });
}

function getUpdateWebhookBatchPayload(chat: Chat): BatchPayload<UpdateWebhookBody> {
  return {
    id: chat.id,
    method: 'PATCH',
    url: `subscriptions/${chat.subscription!.id}`,
    body: { expirationDateTime: addMilliseconds(new Date(), CHAT_WEBHOOK_EXPIRATION_MS).toISOString() },
    headers: { 'Content-Type': 'application/json' },
  };
}

function shouldCreateWebhook(chat: Chat | undefined): boolean {
  return getWebhookRefreshDueInMs(chat) <= 0;
}

function isSuccessBatch(batchResponse: BatchResponse): boolean {
  return batchResponse.status === 200 || batchResponse.status === 201;
}
