import { environment } from 'src/environments/environment';

export const AUTHENTICATION_CACHE_STORAGE_PREFIX = 'bNearAuthenticationCache';
export const AUTHENTICATION_CACHE_STORAGE_TOKEN_PREFIX = 'accessToken';
export const AUTHENTICATION_CACHE_STORAGE_SEPARATOR = '-';

export type Resource = 'Backend' | 'Graph';

export enum ScopeVersion {
  BackendV1,
  GraphV1,
  GraphV2,
}

export const BACKEND_SCOPE = ['openid', 'offline_access', environment.azureActiveDirectory.webApiScope].join(' ');

export const OFFLINE_ERRORS = [
  'NetworkOffline',
  'isOnline-false',
  'authenticationService_offline',
  'Cannot start retry fetch of ADAL tokens',
];

export interface AccessTokenResult {
  scope: string;
  expires_on: Date;
  access_token: string;
}

export interface BackendAccessTokenResult {
  scope: string;
  expiresOn: string;
  accessToken: string;
  refreshToken: string;
}

// See https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens
export interface SSOTokenInfoBase {
  // intended audience
  aud: string;
  // https://login.microsoftonline.com/{tenantId}/v2.0
  iss: string;
  // timestamp when auth occurred
  iat: number;
  // timestamp before which the JWT must not be accepted for processing
  nbf: number;
  // expiration timestamp
  exp: number;
  // internal claim used by Azure AD to record data for token reuse
  aio: string;
  // identity provider
  idp: string;
  // human-readable value that identifies the subject of the token
  name: string;
  // immutable identifier for the requestor
  oid: string;
  // internal claim used by Azure to revalidate tokens
  rh: string;
  // set of scopes exposed by the application
  scp: string;
  // principal about which the token asserts information
  sub: string;
  // tenantId (for personal ms accounts: 9188040d-6c67-4c5b-b112-36a304b66dad)
  tid: string;
  // token identifier claim, equivalent to jti in the JWT specification
  uti: string;
  // version of the access token
  ver: string;
}

export enum AuthType {
  Organization = 0,
  FederatedExternal = 1,
  PrivateExternal = 2,
}
