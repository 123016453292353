import { inject, Pipe, PipeTransform } from '@angular/core';

import { AuthService } from '../services/auth.service';

@Pipe({ name: 'dateWithLocale', standalone: true })
export class DateWithLocalePipe implements PipeTransform {
  private readonly authService = inject(AuthService);

  public transform(date: Date | null | undefined, format?: Intl.DateTimeFormatOptions): string | null {
    if (!date) return null;

    if (format && !('hour12' in format)) format.hour12 = false;

    const locale = this.authService.context?.app.locale || 'en-US';
    const formatter = new Intl.DateTimeFormat(locale, format);

    return formatter.format(date);
  }
}
