/**
 * @name ranges-merge
 * @fileoverview Merge and sort string index ranges
 * @version 9.0.15
 * @author Roy Revelt, Codsen Ltd
 * @license MIT
 * {@link https://codsen.com/os/ranges-merge/}
 */

import { rSort as p } from "ranges-sort";
var u = "9.0.15";
var y = u,
  d = {
    mergeType: 1,
    progressFn: null,
    joinRangesThatTouchEdges: !0
  };
function b(i, r) {
  function l(e) {
    return !!e && typeof e == "object" && !Array.isArray(e);
  }
  if (!Array.isArray(i) || !i.length) return null;
  let n;
  if (r) {
    if (l(r)) {
      if (n = {
        ...d,
        ...r
      }, n.progressFn && l(n.progressFn) && !Object.keys(n.progressFn).length) n.progressFn = null;else if (n.progressFn && typeof n.progressFn != "function") throw new Error(`ranges-merge: [THROW_ID_01] opts.progressFn must be a function! It was given of a type: "${typeof n.progressFn}", equal to ${JSON.stringify(n.progressFn, null, 4)}`);
      if (![1, 2, "1", "2"].includes(n.mergeType)) throw new Error(`ranges-merge: [THROW_ID_02] opts.mergeType was customised to a wrong thing! It was given of a type: "${typeof n.mergeType}", equal to ${JSON.stringify(n.mergeType, null, 4)}`);
      if (typeof n.joinRangesThatTouchEdges != "boolean") throw new Error(`ranges-merge: [THROW_ID_04] opts.joinRangesThatTouchEdges was customised to a wrong thing! It was given of a type: "${typeof n.joinRangesThatTouchEdges}", equal to ${JSON.stringify(n.joinRangesThatTouchEdges, null, 4)}`);
    } else throw new Error(`emlint: [THROW_ID_03] the second input argument must be a plain object. It was given as:
${JSON.stringify(r, null, 4)} (type ${typeof r})`);
  } else n = {
    ...d
  };
  let g = i.filter(e => Array.isArray(e)).map(e => [...e]).filter(e => e[2] !== void 0 || e[0] !== e[1]),
    s,
    o,
    t;
  n.progressFn ? s = p(g, {
    progressFn: e => {
      t = Math.floor(e / 5), t !== o && (o = t, n.progressFn(t));
    }
  }) : s = p(g);
  let a = s.length - 1;
  for (let e = a; e > 0; e--) n.progressFn && (t = Math.floor((1 - e / a) * 78) + 21, t !== o && t > o && (o = t, n.progressFn(t))), (s[e][0] <= s[e - 1][0] || !n.joinRangesThatTouchEdges && s[e][0] < s[e - 1][1] || n.joinRangesThatTouchEdges && s[e][0] <= s[e - 1][1]) && (s[e - 1][0] = Math.min(s[e][0], s[e - 1][0]), s[e - 1][1] = Math.max(s[e][1], s[e - 1][1]), s[e][2] !== void 0 && (s[e - 1][0] >= s[e][0] || s[e - 1][1] <= s[e][1]) && s[e - 1][2] !== null && (s[e][2] === null && s[e - 1][2] !== null ? s[e - 1][2] = null : s[e - 1][2] != null ? +n.mergeType == 2 && s[e - 1][0] === s[e][0] ? s[e - 1][2] = s[e][2] : s[e - 1][2] += s[e][2] : s[e - 1][2] = s[e][2]), s.splice(e, 1), e = s.length);
  return s.length ? s : null;
}
export { d as defaults, b as rMerge, y as version };