import { HttpErrorResponse } from '@angular/common/http';

import {
  concatMap,
  filter,
  map,
  mergeMap,
  Observable,
  OperatorFunction,
  pipe,
  Subject,
  take,
  tap,
  throwError,
  timer,
  UnaryFunction,
} from 'rxjs';

export const includeScalingRetryStrategy =
  ({
    maxRetryAttempts = 3,
    scalingDuration = 1000,
    includedStatusCodes = [],
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    includedStatusCodes?: number[];
  } = {}) =>
  (attempts: Observable<HttpErrorResponse>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        // If maximum number of retries have been met
        // Or response is a status code that we don't wish to retry, throw error
        if (
          retryAttempt > maxRetryAttempts ||
          ('status' in error && includedStatusCodes.find((e) => e !== error.status))
        ) {
          return throwError(error);
        }

        // Retry after scalingDuration * 1 , scalingDuration * 2, etc...
        return timer(retryAttempt * scalingDuration);
      }),
    );
  };

export function waitUntil(conditionCallback: () => boolean, conditionCheckIntervalMs = 25): Observable<number> {
  return timer(0, conditionCheckIntervalMs).pipe(
    filter(() => conditionCallback()),
    take(1),
  );
}

export function concatWaitUntil(
  conditionCallback: () => Observable<boolean>,
  conditionCheckIntervalMs = 25,
): Observable<boolean> {
  return timer(0, conditionCheckIntervalMs).pipe(
    concatMap(() => conditionCallback()),
    filter((v) => v),
    take(1),
  );
}

// Kudos to https://stackoverflow.com/a/62971842/15181911
export function filterNullish<T>(): UnaryFunction<Observable<T | null | undefined>, Observable<T>> {
  return pipe(filter((value) => Boolean(value)) as OperatorFunction<T | null | undefined, T>);
}

export enum Visibility {
  Hidden = 'Hidden',
  Visible = 'Visible',
}

export function fromIntersectionObserver(
  element: HTMLElement,
  config: IntersectionObserverInit,
): Observable<Visibility> {
  const intersectionSubject = new Subject<IntersectionObserverEntry>();
  const intersectionObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => intersectionSubject.next(entry));
  }, config);

  intersectionObserver.observe(element);

  return intersectionSubject.pipe(
    filter(Boolean),
    map((entry) => (entry.isIntersecting ? Visibility.Visible : Visibility.Hidden)),
    tap({ complete: () => intersectionObserver.disconnect() }),
  );
}
