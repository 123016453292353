import { Component, inject, Input } from '@angular/core';

import { HintService } from '../../services/hint.service';

import { InteractiveHintType } from '../../models/components/interactive-hint.model';

@Component({
  selector: 'app-interactive-hint',
  templateUrl: './interactive-hint.component.html',
  styleUrls: ['./interactive-hint.component.scss'],
})
export class InteractiveHintComponent {
  public readonly hintService = inject(HintService);

  @Input({ required: true }) public hintType!: InteractiveHintType;
}
