import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { combineLatest, concat, filter, of, Subject, take, takeUntil } from 'rxjs';
import { app } from '@microsoft/teams-js';

import { ApplicationInsightsService } from 'src/app/shared/services/application-insights.service';
import { CollaborationToolService } from 'src/app/shared/services/collaboration-tool.service';
import { UserService } from 'src/app/shared/services/user.service';

import { TranslateCollaborationToolNamePipe } from 'src/app/shared/pipes/translate-collaboration-tool-name.pipe';

import { LinkList } from 'src/app/shared/models/components/collaboration-tool.model';
import { ConvertedListItem } from 'src/app/shared/models/components/link-list.model';
import {
  ParameterlessEvent,
  USER_CLICKED_COLLABORATION_TOOL,
} from 'src/app/shared/models/services/application-insights.model';

import { addProtocolToUrlIfMissing, isDomainValidator } from 'src/app/shared/utils/network.util';
import { waitUntil } from 'src/app/shared/utils/rxjs.util';

@Component({
  selector: 'app-link-list-hover',
  templateUrl: './link-list-hover.component.html',
  styleUrls: ['./link-list-hover.component.scss'],
})
export class LinkListHoverComponent implements OnChanges, OnDestroy {
  private unsubscribe = new Subject<void>();
  private unsubscribeEditing = new Subject<void>();

  public convertedItems: ConvertedListItem[] = [];
  public editing = false;
  public submittingData = false;
  public anyEditChangesExist = false;

  public forms = {
    groupName: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(26)]),
    newItem: new FormGroup({
      name: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(28)]),
      webLink: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(2048),
        isDomainValidator,
      ]),
    }),
  };

  @Input({ required: true }) public linkList!: LinkList;
  @Output() public editingChanged = new EventEmitter<boolean>();

  @ViewChildren('item', { read: ElementRef }) private items: QueryList<ElementRef> | null = null;

  public constructor(
    private readonly collaborationToolService: CollaborationToolService,
    private readonly translateCollaborationToolName: TranslateCollaborationToolNamePipe,
    private readonly applicationInsightsService: ApplicationInsightsService,
    public readonly userService: UserService,
  ) {}

  public ngOnChanges(): void {
    this.convertedItems = [];
    this.forms.groupName.setValue(this.translateCollaborationToolName.transform(this.linkList.groupName), {
      onlySelf: true,
      emitEvent: false,
    });
    this.forms.groupName.markAsPristine();

    for (const item of this.linkList.items) {
      const url = new URL(item.webLink);

      this.convertedItems.push({
        name: item.name,
        webLink: item.webLink,
        domain: url.hostname,
        faviconLink: item.faviconLink,
      });
    }

    this.editing = false;
    this.editingChanged.emit(this.editing);
    this.anyEditChangesExist = false;
    this.submittingData = false;

    this.unsubscribeEditing.next();
    this.unsubscribeEditing.complete();
    this.unsubscribeEditing = new Subject();
  }

  public openLink(item: ConvertedListItem): void {
    this.applicationInsightsService.logCustomEvent(new ParameterlessEvent(USER_CLICKED_COLLABORATION_TOOL));

    app.openLink(item.webLink);
  }

  public startEdit(): void {
    combineLatest([
      concat(of(null), this.forms.groupName.valueChanges),
      concat(of(null), this.forms.newItem.valueChanges),
    ])
      .pipe(
        takeUntil(this.unsubscribe),
        takeUntil(this.unsubscribeEditing),
        filter(([value1, value2]) => value1 !== null || value2 !== null),
        take(1),
      )
      .subscribe(() => (this.anyEditChangesExist = true));

    this.editing = true;
    this.editingChanged.emit(this.editing);
  }

  public saveEdit(): void {
    if (!this.anyEditChangesExist) {
      this.editing = false;
      this.editingChanged.emit(this.editing);
      return;
    }

    this.submittingData = true;

    const updatedTool = this.linkList;

    // Potentially reverse translate groupName
    if (this.forms.groupName.value) {
      updatedTool.groupName = this.translateCollaborationToolName.reverse(this.forms.groupName.value);
    }

    updatedTool.items = this.convertedItems.map((converted) => ({
      name: converted.name,
      webLink: converted.webLink,
      faviconLink: null,
    }));

    this.collaborationToolService
      .createOrUpdateCollaborationTool(updatedTool)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this.editing = false;
          this.editingChanged.emit(this.editing);
          this.anyEditChangesExist = false;

          this.forms.groupName.reset();
          this.forms.newItem.reset();

          this.forms.groupName.setValue(this.translateCollaborationToolName.transform(updatedTool.groupName), {
            onlySelf: true,
            emitEvent: false,
          });
          this.forms.groupName.markAsPristine();

          this.unsubscribeEditing.next();
          this.unsubscribeEditing.complete();
          this.unsubscribeEditing = new Subject();

          this.submittingData = false;
        },
        (err) => {
          this.submittingData = false;

          throw err;
        },
      );
  }

  public deleteItem(index: number) {
    this.convertedItems.splice(index, 1);

    this.anyEditChangesExist = true;
  }

  public addItem(): void {
    const url = addProtocolToUrlIfMissing(this.forms.newItem.controls.webLink.value!);

    const currentItemCount = this.convertedItems.length;
    this.convertedItems.push({
      name: this.forms.newItem.controls.name.value!,
      webLink: url.toString(),
      domain: url.hostname,
      faviconLink: null,
    });

    this.anyEditChangesExist = true;

    this.forms.newItem.reset();

    // Scroll the item into view once it has been rendered
    waitUntil(() => this.items!.length > currentItemCount)
      .pipe(takeUntil(this.unsubscribe), takeUntil(this.unsubscribeEditing))
      .subscribe(() => {
        this.items!.last.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      });
  }

  public moveItemToDropTarget(event: CdkDragDrop<ConvertedListItem>) {
    moveItemInArray(this.convertedItems, event.previousIndex, event.currentIndex);

    this.anyEditChangesExist = true;
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.unsubscribeEditing.next();
    this.unsubscribeEditing.complete();
  }
}
