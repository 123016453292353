import { Pipe, PipeTransform } from '@angular/core';

import { Emoji } from '../models/services/emoji.model';

import { EMOJIS } from '../utils/services/chat/emoji.util';

@Pipe({
  name: 'getEmoji',
  standalone: true,
})
export class GetEmojiPipe implements PipeTransform {
  public transform(emoji: string): Emoji | undefined {
    return EMOJIS.get(emoji);
  }
}
