/**
 * @name ranges-push
 * @fileoverview Gather string index ranges
 * @version 7.0.15
 * @author Roy Revelt, Codsen Ltd
 * @license MIT
 * {@link https://codsen.com/os/ranges-push/}
 */

import { collWhitespace as m } from "string-collapse-leading-whitespace";
import { existy as a, isStr as u, isInt as g } from "codsen-utils";
import { rSort as c } from "ranges-sort";
var d = "7.0.15";
var f = {
  mergeType: 1,
  progressFn: null,
  joinRangesThatTouchEdges: !0
};
function $(p, t) {
  function r(e) {
    return !!e && typeof e == "object" && !Array.isArray(e);
  }
  if (!Array.isArray(p) || !p.length) return null;
  let s;
  if (t) {
    if (r(t)) {
      if (s = {
        ...f,
        ...t
      }, s.progressFn && r(s.progressFn) && !Object.keys(s.progressFn).length) s.progressFn = null;else if (s.progressFn && typeof s.progressFn != "function") throw new Error(`ranges-merge: [THROW_ID_01] resolvedOpts.progressFn must be a function! It was given of a type: "${typeof s.progressFn}", equal to ${JSON.stringify(s.progressFn, null, 4)}`);
      if (![1, 2, "1", "2"].includes(s.mergeType)) throw new Error(`ranges-merge: [THROW_ID_02] resolvedOpts.mergeType was customised to a wrong thing! It was given of a type: "${typeof s.mergeType}", equal to ${JSON.stringify(s.mergeType, null, 4)}`);
      if (typeof s.joinRangesThatTouchEdges != "boolean") throw new Error(`ranges-merge: [THROW_ID_04] resolvedOpts.joinRangesThatTouchEdges was customised to a wrong thing! It was given of a type: "${typeof s.joinRangesThatTouchEdges}", equal to ${JSON.stringify(s.joinRangesThatTouchEdges, null, 4)}`);
    } else throw new Error(`emlint: [THROW_ID_03] the second input argument must be a plain object. It was given as:
${JSON.stringify(t, null, 4)} (type ${typeof t})`);
  } else s = {
    ...f
  };
  let i = p.filter(e => Array.isArray(e)).map(e => [...e]).filter(e => e[2] !== void 0 || e[0] !== e[1]),
    n,
    o,
    l;
  s.progressFn ? n = c(i, {
    progressFn: e => {
      l = Math.floor(e / 5), l !== o && (o = l, s.progressFn(l));
    }
  }) : n = c(i);
  let h = n.length - 1;
  for (let e = h; e > 0; e--) s.progressFn && (l = Math.floor((1 - e / h) * 78) + 21, l !== o && l > o && (o = l, s.progressFn(l))), (n[e][0] <= n[e - 1][0] || !s.joinRangesThatTouchEdges && n[e][0] < n[e - 1][1] || s.joinRangesThatTouchEdges && n[e][0] <= n[e - 1][1]) && (n[e - 1][0] = Math.min(n[e][0], n[e - 1][0]), n[e - 1][1] = Math.max(n[e][1], n[e - 1][1]), n[e][2] !== void 0 && (n[e - 1][0] >= n[e][0] || n[e - 1][1] <= n[e][1]) && n[e - 1][2] !== null && (n[e][2] === null && n[e - 1][2] !== null ? n[e - 1][2] = null : n[e - 1][2] != null ? +s.mergeType == 2 && n[e - 1][0] === n[e][0] ? n[e - 1][2] = n[e][2] : n[e - 1][2] += n[e][2] : n[e - 1][2] = n[e][2]), n.splice(e, 1), e = n.length);
  return n.length ? n : null;
}
var v = d,
  R = {
    limitToBeAddedWhitespace: !1,
    limitLinebreaksCount: 1,
    mergeType: 1
  },
  y = class {
    constructor(t) {
      let r = {
        ...R,
        ...t
      };
      if (r.mergeType && r.mergeType !== 1 && r.mergeType !== 2) if (u(r.mergeType) && r.mergeType.trim() === "1") r.mergeType = 1;else if (u(r.mergeType) && r.mergeType.trim() === "2") r.mergeType = 2;else throw new Error(`ranges-push: [THROW_ID_02] opts.mergeType was customised to a wrong thing! It was given of a type: "${typeof r.mergeType}", equal to ${JSON.stringify(r.mergeType, null, 4)}`);
      this.opts = r, this.ranges = [];
    }
    ranges;
    opts;
    add(t, r, s) {
      if (t == null && r == null) return;
      if (a(t) && !a(r)) {
        if (Array.isArray(t)) {
          if (t.length) {
            if (t.some(o => Array.isArray(o))) {
              t.forEach(o => {
                Array.isArray(o) && this.add(...o);
              });
              return;
            }
            t.length && g(+t[0]) && g(+t[1]) && this.add(...t);
          }
          return;
        }
        throw new TypeError(`ranges-push/Ranges/add(): [THROW_ID_12] the first input argument, "from" is set (${JSON.stringify(t, null, 0)}) but second-one, "to" is not (${JSON.stringify(r, null, 0)})`);
      } else if (!a(t) && a(r)) throw new TypeError(`ranges-push/Ranges/add(): [THROW_ID_13] the second input argument, "to" is set (${JSON.stringify(r, null, 0)}) but first-one, "from" is not (${JSON.stringify(t, null, 0)})`);
      let i = +t,
        n = +r;
      if (g(s) && (s = String(s)), g(i) && g(n)) {
        if (a(s) && !u(s) && !g(s)) throw new TypeError(`ranges-push/Ranges/add(): [THROW_ID_08] The third argument, the value to add, was given not as string but ${typeof s}, equal to:
${JSON.stringify(s, null, 4)}`);
        if (a(this.ranges) && Array.isArray(this.last()) && i === this.last()[1]) {
          if (this.last()[1] = n, this.last()[2], this.last()[2] !== null && a(s)) {
            let o = this.last()[2] && this.last()[2].length && (!this.opts?.mergeType || this.opts.mergeType === 1) ? `${this.last()[2]}${s}` : s;
            this.opts.limitToBeAddedWhitespace && (o = m(o, this.opts.limitLinebreaksCount)), u(o) && !o.length || (this.last()[2] = o);
          }
        } else {
          this.ranges || (this.ranges = []);
          let o = s !== void 0 && !(u(s) && !s.length) ? [i, n, s && this.opts.limitToBeAddedWhitespace ? m(s, this.opts.limitLinebreaksCount) : s] : [i, n];
          this.ranges.push(o);
        }
      } else throw g(i) && i >= 0 ? new TypeError(`ranges-push/Ranges/add(): [THROW_ID_10] "to" value, the second input argument, must be a natural number or zero! Currently it's of a type "${typeof n}" equal to: ${JSON.stringify(n, null, 4)}`) : new TypeError(`ranges-push/Ranges/add(): [THROW_ID_09] "from" value, the first input argument, must be a natural number or zero! Currently it's of a type "${typeof i}" equal to: ${JSON.stringify(i, null, 4)}`);
    }
    push(t, r, s) {
      this.add(t, r, s);
    }
    current() {
      return Array.isArray(this.ranges) && this.ranges.length ? (this.ranges = $(this.ranges, {
        mergeType: this.opts.mergeType
      }), this.ranges && this.opts.limitToBeAddedWhitespace ? this.ranges.map(t => a(t[2]) ? [t[0], t[1], m(t[2], this.opts.limitLinebreaksCount)] : t) : this.ranges) : null;
    }
    wipe() {
      this.ranges = [];
    }
    replace(t) {
      if (Array.isArray(t) && t.length) {
        if (Array.isArray(t[0]) && g(t[0][0])) this.ranges = Array.from(t);else throw new Error(`ranges-push/Ranges/replace(): [THROW_ID_11] Single range was given but we expected array of arrays! The first element, ${JSON.stringify(t[0], null, 4)} should be an array and its first element should be an integer, a string index.`);
      } else this.ranges = [];
    }
    last() {
      return Array.isArray(this.ranges) && this.ranges.length ? this.ranges[this.ranges.length - 1] : null;
    }
  };
export { y as Ranges, R as defaults, v as version };