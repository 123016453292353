import { ElementRef, inject, Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  timer,
} from 'rxjs';

import { CacheService } from './cache.service';
import { TenantService } from './tenant.service';

import {
  HOVER_INSIDE_DELAY_MS,
  HOVER_OUTSIDE_DELAY_MS,
  SidebarBreakpoint,
  SidebarState,
} from '../models/services/sidebar.model';

import { onHoverOutside } from '../utils/hover.util';
import { filterNullish } from '../utils/rxjs.util';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  private readonly cacheService = inject(CacheService);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly tenantService = inject(TenantService);

  private readonly selectedTabIndexSubject = new BehaviorSubject<number>(0);
  private readonly headerElementRefSubject = new BehaviorSubject<ElementRef | null>(null);
  private readonly sidebarElementRefSubject = new BehaviorSubject<ElementRef | null>(null);

  private readonly hoveringChatCount = new BehaviorSubject<number>(0);
  private readonly dragInProgress = new BehaviorSubject<boolean>(false);
  private readonly state = new BehaviorSubject<SidebarState>(SidebarState.Collapsed);

  private readonly hoverInsideHeader$ = this.headerElementRefSubject.pipe(
    filterNullish(),
    switchMap((headerElementRef) =>
      fromEvent(headerElementRef.nativeElement, 'pointerenter').pipe(map(() => headerElementRef)),
    ),
    switchMap((headerElementRef) =>
      timer(HOVER_INSIDE_DELAY_MS).pipe(
        takeUntil(fromEvent(headerElementRef.nativeElement, 'pointerleave')),
        map(() => true),
      ),
    ),
    startWith(false),
  );

  private readonly hoverInsideSidebar$ = this.sidebarElementRefSubject.pipe(
    filterNullish(),
    switchMap((sidebarElementRef) =>
      fromEvent(sidebarElementRef.nativeElement, 'pointerenter').pipe(map(() => sidebarElementRef)),
    ),
    switchMap((sidebarElementRef) =>
      timer(HOVER_INSIDE_DELAY_MS).pipe(
        takeUntil(fromEvent(sidebarElementRef.nativeElement, 'pointerleave')),
        map(() => true),
      ),
    ),
    startWith(false),
  );

  private readonly hoveringChat$ = this.hoveringChatCount.pipe(
    map((count) => count > 0),
    startWith(false),
  );

  private readonly hoverInside$ = combineLatest([
    this.hoverInsideHeader$,
    this.hoverInsideSidebar$,
    this.hoveringChat$,
  ]).pipe(
    map(([hoveringHeader, hoveringSidebar, hoveringChats]) => ({
      hovering: hoveringHeader || hoveringSidebar || hoveringChats,
      hoveringChats,
    })),
  );

  private readonly hoverOutsideSidebar$ = combineLatest([
    this.sidebarElementRefSubject.pipe(filterNullish()),
    this.headerElementRefSubject.pipe(filterNullish()),
  ]).pipe(
    switchMap(([sidebarElementRef, headerElementRef]) =>
      onHoverOutside([sidebarElementRef.nativeElement, headerElementRef.nativeElement], true, HOVER_OUTSIDE_DELAY_MS),
    ),
    map(() => true),
  );

  private readonly hoveringSidebar$ = this.hoverInside$.pipe(
    switchMap(({ hovering, hoveringChats }) => {
      if (!hovering) return of(false);

      return merge(
        of(true),
        this.hoverOutsideSidebar$.pipe(
          filter(() => !hoveringChats && !this.dragInProgress.value),
          map(() => false),
        ),
      );
    }),
  );

  public readonly state$ = this.state.asObservable().pipe(distinctUntilChanged());

  public isPinnedInitially = false;

  public breakpoints$ = merge(
    this.breakpointObserver.observe('(max-width: 950px)').pipe(
      filter((state) => state.matches),
      map(() => SidebarBreakpoint.Collapsed),
    ),
    this.breakpointObserver.observe('(min-width: 951px)').pipe(
      filter((state) => state.matches),
      map(() => SidebarBreakpoint.Pinned),
    ),
  ).pipe(
    startWith(
      this.breakpointObserver.isMatched('(min-width: 951px)') ? SidebarBreakpoint.Pinned : SidebarBreakpoint.Collapsed,
    ),
    shareReplay(1),
  );

  public readonly selectedTabIndex$ = this.selectedTabIndexSubject.asObservable();

  public initialize(): Observable<void> {
    // Listen to changes in media queries and update state accordingly
    this.breakpoints$
      .pipe(
        concatMap((breakpoint) =>
          this.cacheService
            .getGeneralData()
            .pipe(map(({ sidebarPinningEnabled }) => ({ sidebarPinningEnabled, breakpoint }))),
        ),
      )
      .subscribe(({ sidebarPinningEnabled, breakpoint }) => {
        const collapse = breakpoint === SidebarBreakpoint.Collapsed || !sidebarPinningEnabled;
        this.setState(collapse ? SidebarState.Collapsed : SidebarState.Pinned);

        if (collapse && this.tenantService.settings?.featureFlags.chat) this.setSelectedTabIndex(0).subscribe();
      });

    combineLatest([this.hoveringSidebar$, this.state$])
      .pipe(
        concatMap(([hoveringSidebar, sidebarState]) => {
          if (sidebarState === SidebarState.Pinned) {
            return of(null);
          }

          const setState =
            (hoveringSidebar && sidebarState === SidebarState.Collapsed) ||
            (!hoveringSidebar && sidebarState === SidebarState.Hovering);
          if (setState) {
            this.setState(hoveringSidebar ? SidebarState.Hovering : SidebarState.Collapsed);
          }

          if (!hoveringSidebar && this.tenantService.settings?.featureFlags.chat) {
            return this.setSelectedTabIndex(0);
          }

          return of(null);
        }),
      )
      .subscribe();

    // Set the initial selected tab index and state
    return this.cacheService.getGeneralData().pipe(
      concatMap(({ sidebarPinningEnabled, sidebarLastSelectedTabIndex }) =>
        this.breakpoints$.pipe(
          take(1),
          map((breakpoint) => ({ sidebarPinningEnabled, sidebarLastSelectedTabIndex, breakpoint })),
        ),
      ),
      map(({ sidebarPinningEnabled, sidebarLastSelectedTabIndex, breakpoint }) => {
        this.isPinnedInitially = breakpoint === SidebarBreakpoint.Pinned && sidebarPinningEnabled;
        this.setState(this.isPinnedInitially ? SidebarState.Pinned : SidebarState.Collapsed);

        if (this.isPinnedInitially && sidebarLastSelectedTabIndex > 0) {
          this.selectedTabIndexSubject.next(sidebarLastSelectedTabIndex);
        }
      }),
    );
  }

  public setState(state: SidebarState): void {
    if (state === this.state.value) return;

    this.state.next(state);
  }

  public setHoveringChat(hovering: boolean): void {
    const currentCount = this.hoveringChatCount.value;
    this.hoveringChatCount.next(hovering ? currentCount + 1 : currentCount - 1);
  }

  public setDragInProgress(dragInProgress: boolean): void {
    this.dragInProgress.next(dragInProgress);
  }

  public setSelectedTabIndex(index: number): Observable<void> {
    if (index === this.selectedTabIndexSubject.value) return of(undefined);

    return this.cacheService
      .updateGeneralData({ sidebarLastSelectedTabIndex: index })
      .pipe(map(() => this.selectedTabIndexSubject.next(index)));
  }

  public setHeaderElementRef(elementRef: ElementRef): void {
    this.headerElementRefSubject.next(elementRef);
  }

  public setSidebarElementRef(elementRef: ElementRef): void {
    this.sidebarElementRefSubject.next(elementRef);
  }
}
