import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, catchError, concatMap, map, merge, Observable, of, take, tap } from 'rxjs';
import { Organization } from '@microsoft/microsoft-graph-types';
import { base64ToFile } from 'ngx-image-cropper';

import { AuthService } from './auth.service';
import { CacheService } from './cache.service';

import { TenantSettings } from '../models/services/tenant.model';

import { GRAPH_API_BASE } from '../constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private readonly http = inject(HttpClient);
  private readonly cacheService = inject(CacheService);
  private readonly authService = inject(AuthService);

  private readonly settingsSubject = new BehaviorSubject<TenantSettings | undefined>(undefined);

  public readonly settings$ = this.settingsSubject.asObservable();
  public settings?: TenantSettings;

  public isAdminConsentGiven(): Observable<boolean> {
    const request = this.http
      .get<boolean>(
        `${environment.backendUrl}/api/tenant/is-admin-consent-given?tenantObjectId=${
          this.authService.context!.user!.tenant!.id
        }`,
      )
      .pipe(
        concatMap((isAdminConsentGiven) =>
          this.cacheService.updateBackendData({ isAdminConsentGiven }).pipe(map(() => isAdminConsentGiven)),
        ),
      );

    return this.cacheService
      .getBackendData()
      .pipe(
        concatMap((backendData) =>
          backendData.isAdminConsentGiven === undefined
            ? request
            : merge(of(backendData.isAdminConsentGiven), request).pipe(take(2)),
        ),
      );
  }

  public getSettings(): Observable<TenantSettings> {
    return this.getOrganization(this.authService.context!.user!.tenant!.id).pipe(
      concatMap((organization) =>
        this.http
          .get<TenantSettings>(
            `${environment.backendUrl}/api/tenant/settings?tenantDisplayName=${organization?.displayName}&tenantVerifiedDomainsCsv=${organization?.verifiedDomainsCsv}`,
          )
          .pipe(
            tap((tenantSettings) => {
              this.settings = tenantSettings;
              this.settingsSubject.next({ ...tenantSettings });
            }),
            concatMap((tenantSettings) =>
              this.cacheService.updateBackendData({ tenantSettings }).pipe(map(() => tenantSettings)),
            ),
          ),
      ),
    );
  }

  public uploadLogo(base64: string): Observable<boolean> {
    const logo1000 = base64;
    // TODO: Implement image compression, currently there is a bug that the image created in `compressImage` has no dimensions
    const logo500 = base64; // compressImage(base64, 500)
    const logo100 = base64; // compressImage(base64, 100)

    const body = new FormData();
    body.append('logo1000', base64ToFile(logo1000));
    body.append('logo500', base64ToFile(logo500));
    body.append('logo100', base64ToFile(logo100));

    return this.http.post<boolean>(`${environment.backendUrl}/api/tenant/logo`, body);
  }

  private getOrganization(objectId: string): Observable<{ displayName?: string; verifiedDomainsCsv?: string } | null> {
    return this.http
      .get<Organization>(`${GRAPH_API_BASE}/organization/${objectId}?$select=displayName,verifiedDomains`)
      .pipe(
        map(({ displayName, verifiedDomains }) => ({
          displayName: displayName || undefined,
          verifiedDomainsCsv: verifiedDomains?.map((domain) => domain.name).join(','),
        })),
        catchError(() => of(null)),
      );
  }
}
