<div *transloco="let t; read: hintType">
  {{
    hintService.callParticipantCase === "oneParticipant"
      ? t("tooltips.interactiveHint.oneParticipant", { displayName: hintService.commaSeparatedNames })
      : hintService.callParticipantCase === "twoOrThreeParticipants"
        ? t("tooltips.interactiveHint.twoOrThreeParticipants", {
            commaSeparatedNames: hintService.commaSeparatedNames,
            lastName: hintService.lastName
          })
        : t("tooltips.interactiveHint.moreThanThreeParticipants", {
            commaSeparatedNames: hintService.commaSeparatedNames
          })
  }}
</div>
