<ng-container *transloco="let tEmojis; read: 'emojis'">
  <div class="search-container">
    <app-search [placeholder]="tEmojis('search')" (searchValueChanges)="onSearchChanged($event)" />
  </div>

  <div #scrollContainer [ngClass]="(isSearching$ | async) === false ? 'emoji-container' : 'emoji-container-search'">
    <ng-container *ngFor="let group of (emojis$ | async)! | groupEmojis; let index = index">
      <!--Emojis with category name-->
      <app-emoji-group
        #category
        *ngIf="(isSearching$ | async) === false && (index === 0 || index === 1 || index <= loadedCategoriesCount)"
        [emojis]="group.emojis"
        [id]="group.category"
        [name]="tEmojis('categories.' + group.category)"
        (emojiChange)="emojiChange.emit($event)"
        (loadingFinished)="$event && (loadedCategoriesCount = loadedCategoriesCount + 1)"
      />

      <app-button
        *ngIf="enableEmojiReset && index === 0 && (isSearching$ | async) === false"
        class="absolute-css-values"
        fontWeight="400"
        height="22px"
        padding="0 10px"
        [fontSize]="(authService.isMobileWidth$ | async) ? '8px' : '10px'"
        (buttonClick)="emojiChange.emit(null)"
        >{{ tEmojis("reset") | uppercase }}</app-button
      >

      <!--Emojis w/o category name-->
      <ng-container *ngIf="(isSearching$ | async) === true">
        <app-emoji
          *ngFor="let emoji of group.emojis"
          [animateOnHover]="true"
          [emoji]="emoji"
          (emojiClick)="emojiChange.emit($event)"
        />
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<div *ngIf="(isSearching$ | async) === false" class="category-container">
  <app-emoji-category
    [categories]="(categories$ | async)!"
    [selectedCategory]="(selectedCategory$ | async)!"
    (categoryChange)="onCategoryChanged($event)"
  />
</div>
