export const environment = {
  production: true,
  enableLogging: true,
  enableServiceWorker: true,
  appInsights: {
    instrumentationKey: 'fb2f4393-e048-461c-a3bf-f3b44452f283',
  },
  backendUrl: 'https://api-testing.bnear.io',
  azureActiveDirectory: {
    clientId: '46d048e6-2fbb-4ac1-a589-5ac03f745492',
    webApiScope: 'api://app-testing.bnear.io/46d048e6-2fbb-4ac1-a589-5ac03f745492/access_as_user',
  },
  blobStorageBaseUri: 'https://bneartestingstorage.blob.core.windows.net',
};
