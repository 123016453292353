import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { TranslocoModule } from '@ngneat/transloco';

import { AvatarComponent } from './avatar/avatar.component';
import { ButtonComponent } from './button/button.component';
import { EmojiPickerComponent } from './emoji-picker/emoji-picker.component';
import { HorizontalAvatarScrollerComponent } from './horizontal-avatar-scroller/horizontal-avatar-scroller.component';
import { HostedDropdownComponent } from './hosted-dropdown/hosted-dropdown.component';
import { InteractiveHintComponent } from './interactive-hint/interactive-hint.component';
import { LinkListHoverComponent } from './link-list/hover/link-list-hover/link-list-hover.component';
import { LinkListComponent } from './link-list/link-list.component';
import { NotificationBadgeComponent } from './notification-badge/notification-badge.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { OfflineComponent } from './toasts/offline/offline.component';
import { TeamSwitchedComponent } from './toasts/team-switched/team-switched.component';

import { GetEmojiUrlPipe } from '../pipes/get-emoji-url.pipe';
import { TranslateCollaborationToolNamePipe } from '../pipes/translate-collaboration-tool-name.pipe';

import { NgForTrackByPropertyDirective } from '../directives/track-by-property.directive';

import { TooltipModule } from '../directives/tooltip/tooltip.module';
import { ToastModule } from '../services/toast/toast.module';

@NgModule({
  declarations: [
    LinkListComponent,
    LinkListHoverComponent,
    InteractiveHintComponent,
    OfflineComponent,
    TeamSwitchedComponent,
  ],

  imports: [
    CommonModule,
    AvatarComponent,
    TranslocoModule,
    ReactiveFormsModule,
    DragDropModule,
    OverlayModule,
    ScrollingModule,
    TooltipModule,
    ToastModule,
    HostedDropdownComponent,
    ButtonComponent,
    NotificationBadgeComponent,
    SpinnerComponent,
    EmojiPickerComponent,
    NgForTrackByPropertyDirective,
    TranslateCollaborationToolNamePipe,
    GetEmojiUrlPipe,
    HorizontalAvatarScrollerComponent,
  ],
  exports: [
    AvatarComponent,
    HorizontalAvatarScrollerComponent,
    LinkListComponent,
    EmojiPickerComponent,
    ButtonComponent,
    NgForTrackByPropertyDirective,
    TooltipModule,
    InteractiveHintComponent,
    HostedDropdownComponent,
    NotificationBadgeComponent,
    SpinnerComponent,
  ],
  providers: [TranslateCollaborationToolNamePipe, GetEmojiUrlPipe],
})
export class SharedModule {}
