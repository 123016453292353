import { Pipe, PipeTransform } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

const DEFAULT_NAME_TOOLS = 'defaultTools';
const DEFAULT_NAME_RESOURCES = 'defaultResources';
const DEFAULT_NAME_BOARDS = 'defaultBoards';

@Pipe({ name: 'translateCollaborationToolName', pure: true, standalone: true })
export class TranslateCollaborationToolNamePipe implements PipeTransform {
  public constructor(private readonly translocoService: TranslocoService) {}

  public transform(groupName: string): string {
    const canBeTranslated =
      groupName === DEFAULT_NAME_TOOLS || groupName === DEFAULT_NAME_RESOURCES || groupName === DEFAULT_NAME_BOARDS;

    if (canBeTranslated) return this.translocoService.translate(`linkList.${groupName}`);

    return groupName;
  }

  public reverse(groupName: string): string {
    if (groupName === this.transform(DEFAULT_NAME_TOOLS)) {
      return DEFAULT_NAME_TOOLS;
    } else if (groupName === this.transform(DEFAULT_NAME_RESOURCES)) {
      return DEFAULT_NAME_RESOURCES;
    } else if (groupName === this.transform(DEFAULT_NAME_BOARDS)) {
      return DEFAULT_NAME_BOARDS;
    }

    return groupName;
  }
}
