<div
  class="container"
  [appTooltip]="hoverPicker"
  [appTooltipContainerOffsetCrossAxisShifted]="'scale-by-office(3)'"
  [appTooltipContainerOffsetMainAxis]="'scale-by-office(0.5)'"
  [appTooltipDirection]="(hoverPositions$ | async)!"
  [appTooltipMaxWidth]="'none'"
  [appTooltipOpenDelayMs]="250"
  [appTooltipPadding]="'0'"
  [appTooltipPinOffset]="!authService.isIPadOs || isScrolledToBottom ? 'scale-by-office(0)' : 'scale-by-office(1.7)'"
  [appTooltipZIndex]="'high'"
  [(appTooltipCloseTrigger)]="hoverCloseTrigger"
  (appTooltipOpenChange)="hoverOpen.emit($event)"
>
  <app-emoji
    [emoji]="(selectingEmoji || selectedEmoji || '😃' | getEmoji)!"
    [emojiStyle]="animateEmoji ? 'Animated' : '3D'"
    [enableTitle]="false"
    [ngClass]="{
      'greyed-out': !selectingEmoji && !selectedEmoji
    }"
  />
</div>

<ng-template #hoverPicker>
  <app-emoji-picker-hover [emojiSelected]="selectedEmoji !== null" (emojiChange)="onEmojiChanged($event)" />
</ng-template>
