import { delay, filter, fromEvent, map, Observable, throttleTime } from 'rxjs';

function onEventOutside(
  hitboxElements: HTMLElement[],
  eventName: 'click' | 'pointerover',
  ignoreCdkOverlay = false,
  outsideEventTriggerDelayMs = 125,
): Observable<MouseEvent | PointerEvent> {
  const composedPathInsideHitbox = (pathElement: EventTarget, hitboxElement: HTMLElement) => {
    if (pathElement === hitboxElement) return true;

    if ('isSameNode' in pathElement) {
      const element = pathElement as HTMLElement;
      if (element.isSameNode(hitboxElement)) return true;

      if (ignoreCdkOverlay && 'classList' in element && element.classList.contains('cdk-overlay-container'))
        return true;
    }

    return false;
  };

  // Delay outside events by `outsideEventTriggerDelayMs` and only emit if event is still in active outside after the delay
  let isOutsideEvent = false;
  const outsideEvents = fromEvent(window, eventName, { passive: true }).pipe(
    map((event: Event) => (eventName === 'click' ? (event as MouseEvent) : (event as PointerEvent))),
    filter((event) => {
      const isOutsideHitbox = !hitboxElements.some((hitboxElement) =>
        event.composedPath().some((pathElement) => composedPathInsideHitbox(pathElement, hitboxElement)),
      );

      isOutsideEvent = isOutsideHitbox;

      return isOutsideHitbox;
    }),
  );

  return outsideEvents.pipe(
    throttleTime(outsideEventTriggerDelayMs, undefined, { leading: true, trailing: true }),
    delay(outsideEventTriggerDelayMs),
    filter(() => isOutsideEvent),
  );
}

// Returns an Observable that emits if a pointerover event was triggered on an element that is not in the provided list of elements
export function onHoverOutside(
  hitboxElements: HTMLElement[],
  ignoreCdkOverlay = false,
  outsideHoverTriggerDelayMs = 125,
): Observable<PointerEvent> {
  const result = onEventOutside(hitboxElements, 'pointerover', ignoreCdkOverlay, outsideHoverTriggerDelayMs);

  return result as Observable<PointerEvent>;
}

// Returns an Observable that emits if a click event was triggered on an element that is not in the provided list of elements
export function onClickOutside(
  hitboxElements: HTMLElement[],
  ignoreCdkOverlay = false,
  outsideHoverTriggerDelayMs = 125,
): Observable<MouseEvent> {
  const result = onEventOutside(hitboxElements, 'click', ignoreCdkOverlay, outsideHoverTriggerDelayMs);

  return result as Observable<MouseEvent>;
}
