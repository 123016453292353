import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { OnDestroy } from '@angular/core';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import {
  concatMap,
  debounceTime,
  finalize,
  fromEvent,
  map,
  merge,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
  timer,
  withLatestFrom,
} from 'rxjs';
import { app } from '@microsoft/teams-js';
import { TranslocoModule } from '@ngneat/transloco';
import { addMinutes, roundToNearestMinutes } from 'date-fns';
import { stripHtml } from 'string-strip-html';

import { ButtonComponent } from '../../../button/button.component';
import { EmojiPickerComponent } from '../../../emoji-picker/emoji-picker.component';
import { EmojiComponent } from '../../../emojis/emoji/emoji.component';
import { AgendaIconComponent } from '../../../icons/agenda.component';
import { OtrIconComponent } from '../../../icons/otr.component';
import { PresenceIconComponent } from '../../../icons/presence/presence.component';
import { ResetIconComponent } from '../../../icons/reset/reset.component';
import { NotificationBadgeComponent } from '../../../notification-badge/notification-badge.component';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { StatusBadgeComponent } from '../../status-badge/status-badge.component';
import { SendButtonComponent } from 'src/app/shared/components/avatar/hover/avatar-hover/send-button/send-button.component';

import { ApplicationInsightsService } from 'src/app/shared/services/application-insights.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CacheService } from 'src/app/shared/services/cache.service';
import { CalendarService } from 'src/app/shared/services/calendar.service';
import { CallService } from 'src/app/shared/services/call.service';
import { ChatService } from 'src/app/shared/services/chat.service';
import { GlobalSpinnerService } from 'src/app/shared/services/global-spinner.service';
import { OnlineMeetingService } from 'src/app/shared/services/online-meeting.service';
import { UserService } from 'src/app/shared/services/user.service';

import { GetAvailabilityColorPipe } from 'src/app/shared/components/avatar/hover/avatar-hover/get-availability-color.pipe';
import { GetAvailabilityTranslationKeyPipe } from 'src/app/shared/components/avatar/hover/avatar-hover/get-availability-translation-key.pipe';
import { DateWithLocalePipe } from 'src/app/shared/pipes/date-with-locale.pipe';
import { GetEmojiPipe } from 'src/app/shared/pipes/get-emoji.pipe';

import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';

import { ScheduleAvailability, ScheduleBlock } from 'src/app/shared/models/components/avatar-hover.model';
import {
  ParameterlessEvent,
  USER_HOVERED_AVATAR,
  USER_OPENED_CHAT,
  USER_PLANNED_MEETING,
  USER_SET_STATUS_MESSAGE,
  UserInvitingToCallEvent,
  UserJoinedCallEvent,
} from 'src/app/shared/models/services/application-insights.model';
import { AuthType } from 'src/app/shared/models/services/auth.model';
import { Call, CallContext } from 'src/app/shared/models/services/call.model';
import { TeamInfo } from 'src/app/shared/models/services/team.model';
import { FrontendAvailability, OutOfOfficeSettings } from 'src/app/shared/models/services/user.model';
import { Location, WorkLocation } from 'src/app/shared/models/services/user.model';

import { enterGrowVertical, leaveShrinkVertical } from 'src/app/shared/utils/animation.util';
import {
  getImpromptuMeetingSubject,
  getNextAvailableTimeSlot,
  isEligibleForSchedule,
  mapToPresence,
} from 'src/app/shared/utils/components/avatar-hover.util';
import {
  handleIndividualWorkLocationInput,
  handleWorkLocationClick,
} from 'src/app/shared/utils/components/check-in-questions.util';
import { fetchSchedule, openCreateMeeting } from 'src/app/shared/utils/services/calendar.util';
import { openChat } from 'src/app/shared/utils/services/call.util';

@Component({
  selector: 'app-avatar-hover',
  standalone: true,
  templateUrl: './avatar-hover.component.html',
  styleUrls: ['./avatar-hover.component.scss'],
  animations: [leaveShrinkVertical, enterGrowVertical],
  imports: [
    NgIf,
    NgClass,
    NgStyle,
    NgForOf,
    TooltipDirective,
    TranslocoModule,
    ButtonComponent,
    DateWithLocalePipe,
    NotificationBadgeComponent,
    ReactiveFormsModule,
    SpinnerComponent,
    SendButtonComponent,
    AgendaIconComponent,
    StatusBadgeComponent,
    EmojiComponent,
    EmojiPickerComponent,
    GetEmojiPipe,
    PresenceIconComponent,
    ResetIconComponent,
    GetAvailabilityTranslationKeyPipe,
    GetAvailabilityColorPipe,
    OtrIconComponent,
  ],
})
export class AvatarHoverComponent implements OnInit, OnChanges, OnDestroy {
  private unsubscribe: Subject<void> = new Subject<void>();

  public Availability = FrontendAvailability;
  public WorkLocation = WorkLocation;
  public Location = Location;
  public ScheduleAvailability = ScheduleAvailability;

  public handleWorkLocationClick = handleWorkLocationClick;
  public handleIndividualWorkLocationInput = handleIndividualWorkLocationInput;

  public inviteButtonDisabled = false;
  public callButtonDisabled = false;

  public nameAnimationDisabled = false;
  public jobTitleAnimationDisabled = false;
  public statusAnimationDisabled = false;
  public sendChatAnimationSuccessTrigger = false;
  public sendChatAnimationFailureTrigger = false;
  public isEmptyHover = false;
  public isDropdownOpen = false;
  public presenceUpdateInProgress = false;

  public showSchedule = false;
  public scheduleHoveredBlockIndex?: number;

  public workLocationFormControl: FormControl<WorkLocation | null> = new FormControl<WorkLocation | null>(null);
  public individualWorkLocationFormControl: FormControl<string | null> = new FormControl<string | null>(null);
  public statusFormControl = new FormControl<string | null>(null);

  public dropdownAvailabilities?: FrontendAvailability[];
  public schedule?: ScheduleBlock[] | null;
  public chatMessage = new FormControl<string | null>(null, [Validators.required]);

  public pointerEnterCounter = 0;
  public pointerLeaveCounter = 0;
  public insideOutsideHoverInitialPosition: 'inside' | 'outside' | null = null;

  @ViewChild('chatMessageInput') public chatMessageInput?: ElementRef<HTMLInputElement>;

  @Input({ required: true }) public enableSchedule!: boolean;
  @Input({ required: true }) public enableAvailabilityBadge!: boolean;
  @Input({ required: true }) public enableOutOfOfficeStatus!: boolean;
  @Input({ required: true }) public showSendChatMessage!: boolean;
  @Input({ required: true }) public showCallButton!: boolean;
  @Input({ required: true }) public showInviteButton!: boolean;
  @Input({ required: true }) public showCalendarButton!: boolean;
  @Input({ required: true }) public enableTimelinePlanning!: boolean;
  @Input({ required: true }) public showChatButton!: boolean;
  @Input({ required: true }) public showWorkLocation!: boolean;
  @Input({ required: true }) public showInOtherRoomBadge!: boolean;
  @Input({ required: true }) public showStatusMessage!: boolean;
  @Input({ required: true }) public showJobTitle!: boolean;
  @Input({ required: true }) public enablePresenceChange!: boolean;
  @Input({ required: true }) public enableEmojiChange!: boolean;
  @Input({ required: true }) public showLicenseInvalidBadge!: boolean;

  @Input() public otherRoomInfo?: TeamInfo;
  @Input() public showAutoCheckInHover?: boolean;

  @Input({ required: true }) public userObjectId!: string;
  @Input({ required: true }) public isMe!: boolean;
  @Input() public myCall?: Call | null;

  @Input({ required: true }) public availability!: FrontendAvailability | null | undefined;
  @Input({ required: true }) public workLocation!: WorkLocation | null;
  @Input({ required: true }) public individualWorkLocation!: string | null;
  @Input({ required: true }) public selectedEmoji!: string | null;

  @Input({ required: true }) public isAnonymized!: boolean;

  @Input() public displayName?: string;
  @Input() public firstName?: string;
  @Input() public lastName?: string;
  @Input() public userEmailAddress?: string;
  @Input() public userPrincipalName?: string;
  @Input() public jobTitle?: string | null;

  @Input() public statusMessage?: string | null;
  @Input() public hasStatusBeenRead?: boolean;

  @Input() public outOfOfficeSettings?: OutOfOfficeSettings;

  @Input() public unreadChatMessageCount = 0;

  @Output() public workLocationChange = new EventEmitter<WorkLocation | null>();
  @Output() public individualWorkLocationChange = new EventEmitter<string | null>();
  @Output() public statusChange = new EventEmitter<string | null>();
  @Output() public readStatusChange = new EventEmitter<void>();
  @Output() public openedChatViaHover = new EventEmitter<void>();
  @Output() public insideOutsideHover = new EventEmitter<void>();
  @Output() public badgeColor = new EventEmitter<string>();

  @ViewChild('dropdown')
  private dropdown?: ElementRef<HTMLDivElement>;

  public constructor(
    private readonly onlineMeetingService: OnlineMeetingService,
    public readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly callService: CallService,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly calendarService: CalendarService,
    private readonly chatService: ChatService,
    private readonly cacheService: CacheService,
    private readonly globalSpinnerService: GlobalSpinnerService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly hostElement: ElementRef,
  ) {
    timer(3_500)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.applicationInsightsService.logCustomEvent(new ParameterlessEvent(USER_HOVERED_AVATAR)));

    timer(2_000)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        if (this.hasStatusBeenRead) return;

        this.readStatusChange.emit();
      });
  }

  public ngOnInit(): void {
    if (!this.isMe) return;

    merge(
      this.workLocationFormControl.valueChanges,
      this.individualWorkLocationFormControl.valueChanges.pipe(debounceTime(1_000)),
    )
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(() =>
          this.userService.updateWorkLocation(
            this.workLocationFormControl.value,
            this.individualWorkLocationFormControl.value,
          ),
        ),
        tap(() => {
          this.workLocationChange.emit(this.workLocationFormControl.value);
          this.individualWorkLocationChange.emit(this.individualWorkLocationFormControl.value);
        }),
      )
      .subscribe();

    this.statusFormControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(250),
        switchMap(() =>
          this.userService.setStatusMessage(this.statusFormControl.value?.replace(/\n/gi, '<br />') ?? null),
        ),
        tap(() => this.statusChange.emit(this.statusFormControl.value)),
      )
      .subscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Disable loading animations if data is already loaded
    if ('displayName' in changes && changes.displayName.isFirstChange() && this.displayName !== undefined) {
      this.nameAnimationDisabled = true;
    }
    if ('jobTitle' in changes && changes.jobTitle.isFirstChange() && this.jobTitle !== undefined) {
      this.jobTitleAnimationDisabled = true;
    }
    if ('statusMessage' in changes && changes.statusMessage.isFirstChange() && this.statusMessage !== undefined) {
      this.statusAnimationDisabled = true;
    }

    if ('showWorkLocation' in changes || 'workLocation' in changes || 'isMe' in changes) {
      this.isEmptyHover = this.getIsEmptyHover();
    }

    if ('userObjectId' in changes && changes.userObjectId.isFirstChange()) {
      this.cacheService
        .getGraphData()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(({ chats: cachedChats }) => {
          const oneOnOneChat = cachedChats.find(
            (chat) =>
              chat.chatType === 'oneOnOne' && chat.members.find((member) => member.userId === this.userObjectId),
          );

          if (oneOnOneChat?.metadata.currentComposingMessage) {
            this.chatMessage.setValue(stripHtml(oneOnOneChat.metadata.currentComposingMessage).result);
          }
        });
    }

    if (!this.enableSchedule) {
      [this.showSchedule, this.schedule] = [false, null];
    } else if (('userPrincipalName' in changes || 'userEmailAddress' in changes) && !this.schedule) {
      this.cacheService
        .getBackendData()
        .pipe(
          takeUntil(this.unsubscribe),
          concatMap(({ tenantSettings }) => {
            const user = { userPrincipalName: this.userPrincipalName, emailAddress: this.userEmailAddress };
            const isEligibleData = { me: this.userService.userInfo?.user || null, user, tenantSettings };

            this.showSchedule = isEligibleForSchedule(isEligibleData);
            if (!this.showSchedule) return of(null);

            const myScheduleId =
              this.userService.userInfo!.user.emailAddress ?? this.userService.userInfo!.user.userPrincipalName!;
            const theirScheduleId = this.userEmailAddress ?? this.userPrincipalName!;
            return fetchSchedule({ myScheduleId, theirScheduleId, endDateAddMinutes: 30 }, this.calendarService);
          }),
        )
        .subscribe((schedule) => (this.schedule = schedule));
    }

    if (this.availability && !this.showInOtherRoomBadge) {
      const getAvailabilityColorPipe = new GetAvailabilityColorPipe();
      this.badgeColor.emit(getAvailabilityColorPipe.transform(this.availability, this.outOfOfficeSettings));
    } else if (this.showInOtherRoomBadge) {
      this.badgeColor.emit('var(--teams-magenta)');
    }

    if (!this.isMe) return;

    if ('workLocation' in changes) {
      this.workLocationFormControl.setValue(this.workLocation, { emitEvent: false });
    }

    if ('individualWorkLocation' in changes) {
      this.individualWorkLocationFormControl.setValue(this.individualWorkLocation, { emitEvent: false });
    }

    if ('statusMessage' in changes) {
      const statusWithNewlines = this.statusMessage?.replace(/<br \/>/gi, '\r\n') || null;
      this.statusFormControl.setValue(statusWithNewlines, {
        emitEvent: false,
      });
    }

    if ('availability' in changes) {
      this.updateSelectableAvailabilities();
    }
  }

  public ngOnDestroy(): void {
    if (this.chatMessage.dirty) {
      this.cacheService
        .updateGraphDataFn(({ chats: cachedChats }) => {
          const oneOnOneChat = cachedChats.find(
            (chat) =>
              chat.chatType === 'oneOnOne' && chat.members.find((member) => member.userId === this.userObjectId),
          );

          if (!oneOnOneChat) return null;

          oneOnOneChat.metadata.currentComposingMessage = this.chatMessage.value || undefined;

          return { chats: cachedChats };
        })
        .subscribe();
    }

    if (!this.statusFormControl.pristine && this.statusFormControl.value) {
      this.applicationInsightsService.logCustomEvent(new ParameterlessEvent(USER_SET_STATUS_MESSAGE));
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public setPreferredAvailability(availability: FrontendAvailability): void {
    const presence = mapToPresence(availability);
    if (!presence) return;

    [this.isDropdownOpen, this.presenceUpdateInProgress] = [false, true];

    this.userService
      .updatePreferredPresence(presence)
      .pipe(
        finalize(() => {
          this.presenceUpdateInProgress = false;
          this.changeDetectorRef.detectChanges();
        }),
      )
      .subscribe(() => {
        this.updateSelectableAvailabilities();
        this.changeDetectorRef.detectChanges();
      });
  }

  public clearPreferredPresence(): void {
    [this.isDropdownOpen, this.presenceUpdateInProgress] = [false, true];

    this.userService
      .clearPreferredPresence()
      .pipe(
        finalize(() => {
          this.presenceUpdateInProgress = false;
          this.changeDetectorRef.detectChanges();
        }),
      )
      .subscribe(() => {
        this.updateSelectableAvailabilities();
        this.changeDetectorRef.detectChanges();
      });
  }

  public call(): void {
    if (this.callButtonDisabled) return;

    this.applicationInsightsService.logCustomEvent(new UserJoinedCallEvent(CallContext.ImpromptuMeeting, true));

    this.callButtonDisabled = true;
    this.userService
      .checkInIfNecessary()
      .pipe(
        takeUntil(this.unsubscribe),
        concatMap(() =>
          this.onlineMeetingService.create(
            CallContext.ImpromptuMeeting,
            getImpromptuMeetingSubject(this.userService),
            this.userService.userInfo!.user.currentTeamObjectId!,
            [this.userObjectId],
          ),
        ),
        concatMap((call) => app.openLink(call.joinWebUrl!)),
      )
      .subscribe(
        () => {
          this.callButtonDisabled = false;
        },
        (err) => {
          this.callButtonDisabled = false;
          throw err;
        },
      );
  }

  public invite(): void {
    if (this.inviteButtonDisabled || !this.myCall?.joinWebUrl) return;

    this.applicationInsightsService.logCustomEvent(new UserInvitingToCallEvent(this.myCall.context));

    this.inviteButtonDisabled = true;
    this.callService
      .inviteParticipant(this.myCall.joinWebUrl, this.userObjectId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => (this.inviteButtonDisabled = false),
        (err) => {
          this.inviteButtonDisabled = false;
          throw err;
        },
      );
  }

  public planMeeting(blockIndex?: number): void {
    if ((!this.enableTimelinePlanning && !this.showCalendarButton) || !this.schedule) return;

    // TODO: Handle case: blockIndex === schedule.length - 1

    const attendees = [this.userPrincipalName ?? this.userEmailAddress!];

    // If blockIndex !== undefined, planing a meeting via timeline
    if (blockIndex !== undefined) {
      let block = this.schedule[blockIndex];
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!block?.isFullHour && !block?.isHalfHour) block = this.schedule[blockIndex - 1];

      // This can only happen if blockIndex === 0 and (!isFullHour && !isHalfHour)
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!block) block = this.schedule[blockIndex + 1];
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!block) return;

      // Maybe change addMinutes(block.startDateTime, 30) to block.endDateTime
      const [startDate, endDate] = [block.startDateTime, addMinutes(block.startDateTime, 30)];
      this.createMeeting({ startDate: startDate, endDate: endDate, attendees: attendees });

      return;
    }

    // Assume that the hover is not open more than 15 minutes
    let nextAvailableTimeSlot = getNextAvailableTimeSlot(this.schedule);
    if (!nextAvailableTimeSlot) {
      nextAvailableTimeSlot = roundToNearestMinutes(new Date(), { nearestTo: 15, roundingMethod: 'ceil' });
    }

    const [startDate, endDate] = [nextAvailableTimeSlot, addMinutes(nextAvailableTimeSlot, 30)];
    this.createMeeting({ startDate: startDate, endDate: endDate, attendees: attendees });
  }

  public chat(message?: string): void {
    if (!message) this.applicationInsightsService.logCustomEvent(new ParameterlessEvent(USER_OPENED_CHAT));

    this.userService
      .checkInIfNecessary()
      .pipe(
        takeUntil(this.unsubscribe),
        concatMap(() => {
          this.openedChatViaHover.emit();

          return openChat(this.authService.context!.user!.id!, this.userObjectId, message, this.chatService);
        }),
      )
      .subscribe();
  }

  public sendChatMessage(): void {
    if (this.chatMessage.invalid || this.chatMessage.disabled) return;

    this.chatMessage.disable();

    const resetChatMessage = () => {
      this.chatMessage.reset();
      this.chatMessage.enable();
      this.chatMessageInput?.nativeElement.focus();
    };

    // External accounts did not grant permission, private accounts do not support the API
    if (this.authService.authType !== AuthType.Organization) {
      this.chat(this.chatMessage.value!);

      resetChatMessage();

      return;
    }

    this.userService
      .checkInIfNecessary()
      .pipe(
        concatMap(() =>
          this.chatService.getOrCreateChat('oneOnOne', [this.authService.context!.user!.id, this.userObjectId]),
        ),
        concatMap((chat) =>
          this.chatService.sendChatMessage(chat.id!, this.chatMessage.value!, undefined, undefined, true),
        ),
        finalize(() => resetChatMessage()),
      )
      .subscribe({
        next: () => {
          this.sendChatAnimationSuccessTrigger = true;
        },
        error: () => {
          this.sendChatAnimationFailureTrigger = true;
        },
      });
  }

  public selectTeam(teamObjectId: string): void {
    this.globalSpinnerService.showSpinner(true);
    this.router.navigateByUrl(`elevator?teamObjectId=${teamObjectId}&skipEnabled=true`);
  }

  public workLocationInputFocused(
    workLocationFormControl: FormControl,
    individualWorkLocationFormControl: FormControl,
  ): void {
    handleIndividualWorkLocationInput(workLocationFormControl, individualWorkLocationFormControl);
  }

  public onPointerEnter(): void {
    this.pointerEnterCounter++;

    if (this.pointerLeaveCounter === 0) this.insideOutsideHoverInitialPosition = 'inside';

    this.emitInsideOutsideHover();
  }

  public onPointerLeave(): void {
    this.pointerLeaveCounter++;

    if (this.pointerEnterCounter === 0) this.insideOutsideHoverInitialPosition = 'outside';

    this.emitInsideOutsideHover();
  }

  public onDropdownHover(): void {
    const mouseMove$ = fromEvent(this.hostElement.nativeElement, 'mousemove').pipe(startWith(null));
    timer(150)
      .pipe(
        withLatestFrom(mouseMove$),
        map(([_, event]) => {
          const mouseEvent = event as MouseEvent;

          return (this.dropdown?.nativeElement as HTMLElement).contains(mouseEvent.target as HTMLElement);
        }),
        tap((onHover) => (this.isDropdownOpen = onHover)),
      )
      .subscribe();
  }

  private emitInsideOutsideHover(): void {
    if (
      this.insideOutsideHoverInitialPosition === 'inside' &&
      this.pointerEnterCounter === 2 &&
      this.pointerLeaveCounter === 2
    ) {
      this.insideOutsideHover.emit();

      [this.pointerEnterCounter, this.pointerLeaveCounter] = [0, 0];
      this.insideOutsideHoverInitialPosition = null;
    }

    if (
      this.insideOutsideHoverInitialPosition === 'outside' &&
      this.pointerEnterCounter === 1 &&
      this.pointerLeaveCounter === 2
    ) {
      this.insideOutsideHover.emit();

      [this.pointerEnterCounter, this.pointerLeaveCounter] = [0, 0];
      this.insideOutsideHoverInitialPosition = null;
    }
  }

  private createMeeting(data: { startDate: Date; endDate: Date; attendees: string[] }): void {
    this.applicationInsightsService.logCustomEvent(new ParameterlessEvent(USER_PLANNED_MEETING));

    const { startDate, endDate, attendees } = data;
    this.userService
      .checkInIfNecessary()
      .pipe(
        takeUntil(this.unsubscribe),
        concatMap(() => openCreateMeeting({ startDate: startDate, endDate: endDate, attendees: attendees })),
      )
      .subscribe();
  }

  private getIsEmptyHover(): boolean {
    const isMe = this.isMe;
    const isExternal = this.authService.authType !== AuthType.Organization;
    const workLocationIsEmpty = !this.showWorkLocation || this.workLocation === null;

    return !isMe && isExternal && workLocationIsEmpty;
  }

  private updateSelectableAvailabilities(): void {
    const selectableAvailabilities: FrontendAvailability[] = [
      FrontendAvailability.Available,
      FrontendAvailability.Busy,
      FrontendAvailability.DoNotDisturb,
      FrontendAvailability.Away,
    ];

    if (this.availability) {
      const index = selectableAvailabilities.findIndex((presence) => presence === this.availability);
      if (index >= 0) selectableAvailabilities.splice(index, 1);
    }

    this.dropdownAvailabilities = selectableAvailabilities;
  }
}
