import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-circled-cross',
  standalone: true,
  template: `
    <svg
      xml:space="preserve"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      class=""
      height="512"
      style="enable-background:new 0 0 512 512"
      version="1.1"
      viewBox="0 0 24 24"
      width="512"
      x="0"
      xmlns="http://www.w3.org/2000/svg"
      y="0"
    >
      <g>
        <path
          d="M12 1a11 11 0 1 0 11 11A11.013 11.013 0 0 0 12 1zm4.242 13.829a1 1 0 1 1-1.414 1.414L12 13.414l-2.828 2.829a1 1 0 0 1-1.414-1.414L10.586 12 7.758 9.171a1 1 0 1 1 1.414-1.414L12 10.586l2.828-2.829a1 1 0 1 1 1.414 1.414L13.414 12z"
          [attr.fill]="fill"
        ></path>
      </g>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircledCrossIconComponent {
  @Input() public fill = '#9c9c9c';
}
