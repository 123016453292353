import { catchError, from, map, Observable, of } from 'rxjs';
import { Event as MSGraphEvent, NullableOption, WorkingHours } from '@microsoft/microsoft-graph-types';
import { DayOfWeek } from '@microsoft/microsoft-graph-types';
import { app, calendar } from '@microsoft/teams-js';
import { addMinutes, endOfDay, isFuture, NearestMinutes, parseJSON, subDays } from 'date-fns';
import { addHours, eachMinuteOfInterval, isSameHour, isSameMinute, roundToNearestMinutes, subMinutes } from 'date-fns';

import { CalendarService } from '../../services/calendar.service';

import { ScheduleAvailability, ScheduleBlock } from '../../models/components/avatar-hover.model';
import { Event } from '../../models/services/calendar.model';
import { Call } from '../../models/services/call.model';
import { DismissedEvent } from '../../models/services/user.model';

import { DEEP_LINK_BASE } from '../../constants';

export type MSGraphSensitivity = 'confidential' | 'normal' | 'personal' | 'private';

export function filterEventsByCalls(events: Event[], calls: Call[]): Event[] {
  return events.filter((event) => !isEventCall(calls, event));
}

export function findEvent<T extends Event | MSGraphEvent>(
  events: T[],
  eventId: string | null | undefined,
  joinWebUrl: string | null | undefined,
  iCalUId: string | null | undefined,
): T | undefined {
  // If an event of a series is dismissed, there may be multiple matching events
  // We can only differentiate those by iCalUId
  const eventCandidates = events.filter(
    (event) =>
      event.id === eventId ||
      (iCalUId !== null && event.iCalUId === iCalUId) ||
      (joinWebUrl &&
        (('joinWebUrl' in event && event.joinWebUrl === joinWebUrl) ||
          ('onlineMeeting' in event && getJoinWebUrlFromMSGraphEvent(event).joinWebUrl === joinWebUrl))),
  );

  if (eventCandidates.length === 0) return undefined;

  if (!iCalUId) return eventCandidates[0];

  return events.find((event) => event.iCalUId === iCalUId) ?? eventCandidates[0];
}

export function createEventByGraphEvent(event: MSGraphEvent): Event {
  const { joinWebUrl, fromBody, isZoomMeeting, isGoogleMeeting } = getJoinWebUrlFromMSGraphEvent(event);

  const parsedStartDateTime = parseJSON(event.start!.dateTime!);
  const parsedEndDateTime = parseJSON(event.end!.dateTime!);

  let fixedStartDateTime = parsedStartDateTime;
  let fixedEndDateTime = parsedEndDateTime;

  if (event.isAllDay) {
    const { startDateTime, endDateTime } = fixStartAndEndTime(parsedStartDateTime, parsedEndDateTime);
    fixedStartDateTime = startDateTime;
    fixedEndDateTime = endDateTime;
  }

  return {
    id: event.id!,
    subject: event.subject!,
    startDate: fixedStartDateTime,
    endDate: fixedEndDateTime,
    createdDate: parseJSON(event.createdDateTime!),
    webLink: decodeURI(event.webLink!),
    isAllDay: event.isAllDay || false,
    isZoomMeeting,
    isGoogleMeeting,
    joinWebUrl: joinWebUrl,
    joinWebUrlFromBody: fromBody,
    iCalUId: event.iCalUId!,
    shouldBeIgnored: event.sensitivity ? event.sensitivity !== 'normal' : false,
  };
}

export function openCreateMeeting(data: { startDate: Date; endDate: Date; attendees: string[] }): Observable<void> {
  if (calendar.isSupported()) {
    return from(
      calendar.composeMeeting({
        attendees: data.attendees,
        startTime: data.startDate.toJSON(),
        endTime: data.endDate.toJSON(),
      }),
    );
  }

  return from(
    app.openLink(
      `${DEEP_LINK_BASE}/meeting/new?attendees=${encodeURIComponent(
        data.attendees.join(','),
      )}&startTime=${encodeURIComponent(data.startDate.toJSON())}&endTime=${encodeURIComponent(data.endDate.toJSON())}`,
    ),
  );
}

export function fetchSchedule(
  data: {
    myScheduleId: string;
    theirScheduleId: string;
    chunkLengthMinutes?: number;
    chunksBeforeNow?: number;
    timelineLengthHours?: number;
    endDateAddMinutes?: number;
  },
  calendarService: CalendarService,
): Observable<ScheduleBlock[] | null> {
  // Note that if these parameters change:
  // 1) the width of individual chunks, and
  // 2) the position of the "now" Element must be recalculated and set in CSS
  const { myScheduleId, theirScheduleId } = data;
  const chunkLengthMinutes = data.chunkLengthMinutes ?? 15;
  const chunksBeforeNow = data.chunksBeforeNow ?? 2;
  const timelineLengthHours = data.timelineLengthHours ?? 8;
  const endDateAddHours = data.endDateAddMinutes ?? chunkLengthMinutes;

  if (chunkLengthMinutes < 1 || chunkLengthMinutes > 30) throw new Error('chunkLengthMinutes must be between 1 and 30');

  const now = new Date();
  const nearestChunkToNow = roundToNearestMinutes(now, {
    nearestTo: chunkLengthMinutes as NearestMinutes,
    roundingMethod: 'floor',
  });
  const timelineStart = subMinutes(nearestChunkToNow, chunksBeforeNow * chunkLengthMinutes);
  const timelineEnd = subMinutes(addHours(timelineStart, timelineLengthHours), 5);

  return calendarService.getSchedules([myScheduleId, theirScheduleId], timelineStart, timelineEnd).pipe(
    map((schedules) => {
      const mySchedule = schedules.find((s) => s.scheduleId === myScheduleId);
      const theirSchedule = schedules.find((s) => s.scheduleId === theirScheduleId);

      if (!mySchedule?.availabilityView || !theirSchedule?.availabilityView) return null;

      const timelineChunks = eachMinuteOfInterval(
        { start: timelineStart, end: timelineEnd },
        { step: chunkLengthMinutes },
      );

      const calculatedSchedule = [];
      for (let chunkIndex = 0; chunkIndex < timelineChunks.length; chunkIndex++) {
        const myAvailability = mySchedule.availabilityView[chunkIndex];
        const theirAvailability = theirSchedule.availabilityView[chunkIndex];
        const theirWorkingHours = theirSchedule.workingHours;

        const chunk = timelineChunks[chunkIndex];

        const matchedAvailability = mapToScheduledAvailability(
          myAvailability,
          theirAvailability,
          theirWorkingHours,
          chunk,
          chunkLengthMinutes,
        );

        calculatedSchedule.push({
          isNow: isSameHour(chunk, nearestChunkToNow) && isSameMinute(chunk, nearestChunkToNow),
          isHalfHour: chunk.getMinutes() === 30,
          isFullHour: chunk.getMinutes() === 0,

          startDateTime: chunk,
          startDateTimeHour: chunk.getHours(),
          endDateTime: addMinutes(chunk, endDateAddHours),

          availability: matchedAvailability,
        });
      }

      return calculatedSchedule;
    }),
    catchError(() => of(null)),
  );
}

export function isDismissedEvent(event: MSGraphEvent, dismissedEvents: DismissedEvent[]): boolean {
  const { joinWebUrl } = getJoinWebUrlFromMSGraphEvent(event);
  const dismissedEvent = findEvent(
    dismissedEvents,
    event.id,
    // Only find dismissed events by joinWebUrl if the event is a single event, otherwise all series instances are hidden
    event.type === 'singleInstance' ? joinWebUrl : null,
    event.iCalUId,
  );

  return Boolean(dismissedEvent);
}

export function isRelevantEvent(event: Event): boolean {
  // Ignore events with irrelevant time spans
  const isActive = isFuture(event.endDate);
  const isComingUp = isEventComingUp(event, 10);

  return isActive && isComingUp;
}

export function isEventComingUp(event: Event, minutes: number): boolean {
  const now = new Date();
  const minutesBeforeStart = subMinutes(event.startDate, minutes);

  // If the event is coming up in less than `minutes` minutes, show the event again
  return now >= minutesBeforeStart;
}

// ScheduledMeetings marked as 'isAllDay' sometimes do not begin at midnight.
// As a result, they would be displayed for an extra day
export function fixStartAndEndTime(startDateTime: Date, endDateTime: Date): { startDateTime: Date; endDateTime: Date } {
  if (startDateTime.getHours() !== 0) startDateTime.setHours(0);

  if (endDateTime.getHours() !== 0) {
    endDateTime = endOfDay(subDays(new Date(endDateTime), 1));
  }

  return { startDateTime, endDateTime };
}

function mapToScheduledAvailability(
  myAvailabilityView: string,
  theirAvailabilityView: string,
  theirWorkingHours: NullableOption<WorkingHours> | undefined,
  chunk: Date,
  chunkLengthMinutes: number,
): ScheduleAvailability {
  if (myAvailabilityView === '0' && theirAvailabilityView !== '0') return ScheduleAvailability.OnlyTheyAreUnavailable;
  if (myAvailabilityView !== '0' && theirAvailabilityView !== '0') return ScheduleAvailability.BothUnavailable;

  if (theirWorkingHours?.daysOfWeek && theirWorkingHours.daysOfWeek.length > 0) {
    if (!isChunkWorkingDay(chunk, theirWorkingHours)) return ScheduleAvailability.theyAreOutsideWorkingHours;

    if (theirWorkingHours.startTime && theirWorkingHours.endTime) {
      const workingStartTime = convertToDate(theirWorkingHours.startTime, chunk);
      const workingEndTime = convertToDate(theirWorkingHours.endTime, chunk);
      const chunkStartTime = chunk;
      const chunkEndTime = addMinutes(chunk, chunkLengthMinutes);
      const chunkIsWithinWorkingTime = workingStartTime <= chunkStartTime && chunkEndTime <= workingEndTime;

      if (!chunkIsWithinWorkingTime) return ScheduleAvailability.theyAreOutsideWorkingHours;
    }
  }

  if (myAvailabilityView === '0' && theirAvailabilityView === '0') return ScheduleAvailability.BothFree;
  if (myAvailabilityView !== '0' && theirAvailabilityView === '0') return ScheduleAvailability.OnlyIAmUnavailable;

  return ScheduleAvailability.BothUnavailable;
}

function mapToDayOfWeek(numericDayOfWeek: number): DayOfWeek | null {
  switch (numericDayOfWeek) {
    case 0:
      return 'sunday';
    case 1:
      return 'monday';
    case 2:
      return 'tuesday';
    case 3:
      return 'wednesday';
    case 4:
      return 'thursday';
    case 5:
      return 'friday';
    case 6:
      return 'saturday';
    default:
      return null;
  }
}

function convertToDate(stringDate: string, chunk: Date): Date {
  const [hours, minutes, seconds] = stringDate.split(':');
  const [year, month, date] = [chunk.getFullYear(), chunk.getMonth(), chunk.getDate()];
  const convertedDate = new Date(year, month, date, parseInt(hours), parseInt(minutes), parseInt(seconds));

  return convertedDate;
}

function isChunkWorkingDay(chunk: Date, theirWorkingHours: WorkingHours): boolean {
  const chunkDayOfWeek = mapToDayOfWeek(chunk.getDay());

  return chunkDayOfWeek !== null && theirWorkingHours.daysOfWeek!.includes(chunkDayOfWeek);
}

function getJoinWebUrlFromMSGraphEvent(event: MSGraphEvent): {
  joinWebUrl: string | null;
  fromBody: boolean;
  isZoomMeeting: boolean;
  isGoogleMeeting: boolean;
} {
  // Case: Event has an onlineMeeting with a joinUrl
  if (event.onlineMeeting?.joinUrl)
    return { joinWebUrl: event.onlineMeeting.joinUrl, fromBody: false, isZoomMeeting: false, isGoogleMeeting: false };

  // Case: Get joinWebUrl from body
  if (event.body?.content) {
    return {
      joinWebUrl: getJoinWebUrl(event.body.content),
      fromBody: true,
      isZoomMeeting: false,
      isGoogleMeeting: false,
    };
  }

  // Case: Get joinWebUrl from location
  if (event.location?.uniqueId) {
    // Case: Event is a teams meeting
    const joinWebUrlFromLocation = getJoinWebUrl(event.location.uniqueId);
    if (joinWebUrlFromLocation)
      return { joinWebUrl: joinWebUrlFromLocation, fromBody: false, isZoomMeeting: false, isGoogleMeeting: false };

    // Case: Event is a zoom or google meeting
    const isZoom = isZoomMeeting(event.location.uniqueId);
    const isGoogle = isGoogleMeeting(event.location.uniqueId);
    if (isZoom || isGoogle) {
      return { joinWebUrl: event.location.uniqueId, fromBody: false, isZoomMeeting: isZoom, isGoogleMeeting: isGoogle };
    }
  }

  return { joinWebUrl: null, fromBody: false, isZoomMeeting: false, isGoogleMeeting: false };
}

function getJoinWebUrl(content: string): string | null {
  const startIndex = content.indexOf('https://teams.microsoft.com');
  if (startIndex === -1) return null;

  content = content.substring(startIndex);

  let endIndex = -1;
  if (content.indexOf('}') !== -1) endIndex = content.indexOf('}') + 1;
  else if (content.indexOf('%7d') !== -1) endIndex = content.indexOf('%7d') + 3;
  else if (content.indexOf('%7D') !== -1) endIndex = content.indexOf('%7D') + 3;

  return endIndex === -1 ? null : content.substring(0, endIndex);
}

function isEventCall(calls: Call[], event: Event): boolean {
  return calls.some(
    (c) =>
      (c.joinWebUrl !== null &&
        event.joinWebUrl !== null &&
        decodeURIComponent(c.joinWebUrl) === decodeURIComponent(event.joinWebUrl)) ||
      (c.iCalUId !== null && c.iCalUId === event.iCalUId),
  );
}

const zoomRegex = /^((https)|(zoom)):\/\/.*?zoom\.us\/.*?\/.*?$/;
const isZoomMeeting = (link: string | null | undefined) => link !== null && link !== undefined && zoomRegex.test(link);

const googleRegex = /^((https)|(gmeet)):\/\/.*?meet\.google.com\/.*/;
const isGoogleMeeting = (link: string | null | undefined) =>
  link !== null && link !== undefined && googleRegex.test(link);
