import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';

import {
  asapScheduler,
  BehaviorSubject,
  catchError,
  concatMap,
  forkJoin,
  map,
  Observable,
  of,
  retry,
  scheduled,
  Subject,
  tap,
  throwError,
  timer,
} from 'rxjs';
import {
  AadUserConversationMember,
  Channel,
  Chat as MSGraphChat,
  ChatMessage as MSGraphChatMessage,
  ChatType,
  Subscription,
} from '@microsoft/microsoft-graph-types';
import { TranslocoService } from '@ngneat/transloco';
import { subMinutes } from 'date-fns';

import { ApplicationInsightsService } from './application-insights.service';
import { AuthService } from './auth.service';
import { CacheService } from './cache.service';
import { UserService } from './user.service';
import { WebhookService } from './webhook.service';

import {
  ParameterlessEvent,
  USER_SENT_CHAT_MESSAGE,
  UserSetChatMessageReactionEvent,
} from '../models/services/application-insights.model';
import { Chat, ChatEvent, GetChatOptions } from '../models/services/chat/chat.model';
import { ChatMessage, PendingMessage, ReplyToData } from '../models/services/chat/message.model';

import { arrayBufferToDataUrl } from '../utils/misc.util';
import { getGraphPages } from '../utils/network.util';
import { concatWaitUntil } from '../utils/rxjs.util';
import {
  createGetChatsEndpoint,
  filterChatsBySearch,
  filterPendingMessages,
  mapToChat,
  mapToMemberForRequest,
} from '../utils/services/chat/chat.util';
import {
  createGetChatMessagesEndpoint,
  createInvitationAdaptiveCard,
  createMessageData,
  filterChatMessages,
  GetChatMessagesOptions,
  mapToChatMessage,
  mapToPendingMessage,
  sortChatsMessagesByCreatedDateTime,
} from '../utils/services/chat/message.util';

import { SET_OFFLINE_ON_FAILURE } from './interceptors/client-offline.interceptor';

import { GRAPH_API_BASE } from '../constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private chatsSubject = new BehaviorSubject<Chat[]>([]);
  private chatEventSubject = new Subject<ChatEvent>();

  private readonly http = inject(HttpClient);
  private readonly userService = inject(UserService);
  private readonly translocoService = inject(TranslocoService);
  private readonly cacheService = inject(CacheService);
  private readonly authService = inject(AuthService);
  private readonly applicationInsightsService = inject(ApplicationInsightsService);
  private readonly webhookService = inject(WebhookService);

  public chats$ = this.chatsSubject.asObservable();
  public chatEvent$ = this.chatEventSubject.asObservable();

  public getOrCreateChat(chatType: 'oneOnOne', targetUserObjectIds: string[]): Observable<Chat | MSGraphChat> {
    const cachedChat = this.chatsSubject.value.find(
      (chat) =>
        chat.chatType === chatType &&
        targetUserObjectIds.every((targetUserObjectId) =>
          chat.members.some((member) => member.userId === targetUserObjectId),
        ),
    );
    if (cachedChat) return of(cachedChat);

    const members = targetUserObjectIds.map((targetUserObjectId) => mapToMemberForRequest(targetUserObjectId));
    return this.http.post<MSGraphChat>(`${GRAPH_API_BASE}/chats?$expand=members,lastMessagePreview`, {
      chatType,
      members,
    });
  }

  public getPrimaryChannel(teamId: string): Observable<Channel> {
    return this.http.get<Channel>(`${GRAPH_API_BASE}/teams/${teamId}/primaryChannel`);
  }

  public getChats(
    options: GetChatOptions = { emitChats: true, chatCount: 50, resolvePaging: false },
  ): Observable<{ chats: Chat[]; nextLink?: string }> {
    if (!this.userService.userInfo) throw new Error('No user available');

    const getChatPage = (endpoint: string) =>
      this.http.get<{ value: MSGraphChat[]; '@odata.nextLink': string | undefined }>(endpoint).pipe(
        map(({ value: graphChats, '@odata.nextLink': nextLink }) => ({
          graphChats,
          nextLink: nextLink || undefined,
        })),
      );

    const initialEndpoint = createGetChatsEndpoint(options);

    const getChatPages$ = getGraphPages<MSGraphChat>(initialEndpoint, true, this.http);

    const getChats$ = options.resolvePaging
      ? getChatPages$.pipe(map(({ collection, nextLink }) => ({ graphChats: collection, nextLink })))
      : getChatPage(initialEndpoint);

    return getChats$.pipe(
      concatMap(({ graphChats, nextLink }) =>
        this.cacheService.getGraphData().pipe(map(({ chats: cachedChats }) => ({ graphChats, cachedChats, nextLink }))),
      ),
      concatMap(({ graphChats, cachedChats, nextLink }) => {
        if (graphChats.length === 0) return of({ chats: [], nextLink });

        const filteredGraphChats = options.searchString
          ? filterChatsBySearch(options.searchString, this.userService.userInfo!.user.objectId, graphChats)
          : graphChats;

        return forkJoin(
          filteredGraphChats.map((graphChat) =>
            mapToChat(graphChat, cachedChats, this.userService, this.translocoService),
          ),
        ).pipe(map((chats) => ({ chats, nextLink })));
      }),
      concatMap(({ chats, nextLink }) =>
        this.cacheService
          .upsertChatsFn(
            chats.map((chat) => chat.id),
            (chatId) => chats.find((chat) => chat.id === chatId)!,
            (chatId) => {
              const updatedChat = chats.find((chat) => chat.id === chatId)!;

              return {
                name: updatedChat.name,
                members: updatedChat.members,
                deepLink: updatedChat.deepLink,

                lastUpdatedDateTime: updatedChat.lastUpdatedDateTime,
                lastMessageReadDateTime: updatedChat.lastMessageReadDateTime,
                lastMessagePreview: updatedChat.lastMessagePreview,
              };
            },
          )
          .pipe(map(() => ({ chats, nextLink }))),
      ),
      concatMap(({ chats, nextLink }) =>
        (options.emitChats ? this.emitChatsUpdate() : of(chats)).pipe(map(() => ({ chats, nextLink }))),
      ),
    );
  }

  public minimizeChat(chatId: string, minimized: boolean): void {
    this.chatEventSubject.next({ chatId, minimized, type: 'minimized' });
  }

  public closeChat(chatId: string): void {
    this.chatEventSubject.next({ chatId, type: 'closed' });
  }

  public openChat(chatId: string): Observable<Subscription | null> {
    // If the chat exists we can open it. Otherwise it needs to be updated first
    const chatExists = this.chatsSubject.value.some((chat) => chat.id === chatId);

    return of(null).pipe(
      concatMap(() => (!chatExists ? this.updateChat(chatId) : of(null))),
      tap(() => this.chatEventSubject.next({ chatId, type: 'opened' })),
      concatMap(() => this.cacheService.getChat(chatId)),
      concatMap((chat) => (chat?.metadata.unreadMessageCount ? this.setReadState(chatId, true) : of(null))),
      concatMap(() => this.updateChat(chatId)),
      concatMap(() => this.cacheService.getChat(chatId)),
      concatMap((cachedChat) =>
        cachedChat ? this.webhookService.createOrRefreshChatMessageWebhook(cachedChat) : of(null),
      ),
    );
  }

  public updateChat(id: string, chat?: Chat): Observable<Chat[]> {
    const resolveUpdate = (updatedChat: Chat, cachedChat: Chat) => {
      return {
        name: updatedChat.name,
        members: updatedChat.members,
        deepLink: updatedChat.deepLink,

        lastUpdatedDateTime: updatedChat.lastUpdatedDateTime,
        lastMessageReadDateTime: updatedChat.lastMessageReadDateTime,
        lastMessagePreview: updatedChat.lastMessagePreview,

        messages: updatedChat.messages,
        messagesNextLink: updatedChat.messagesNextLink,
        pendingMessages: filterPendingMessages(cachedChat.pendingMessages, updatedChat.messages),

        metadata: {
          ...cachedChat.metadata,

          messagesLastFetched: new Date(),
          unreadMessageCount: filterChatMessages(
            updatedChat.messages ?? [],
            updatedChat.chatType,
            updatedChat.lastMessageReadDateTime,
            true,
            this.authService.context!.user!.id,
          ).length,
        },
      };
    };

    const updatedChat = chat ? of(chat) : this.getChat(id);
    return updatedChat.pipe(
      concatMap((updatedChat) =>
        this.getChatMessages(id, updatedChat.chatType, updatedChat.members).pipe(
          map(({ messages, nextLink }) => ({ ...updatedChat, messages, messagesNextLink: nextLink })),
        ),
      ),
      concatMap((updatedChat) =>
        this.cacheService.upsertChatsFn(
          [id],
          () => updatedChat,
          (_, cachedChat) => resolveUpdate(updatedChat, cachedChat),
        ),
      ),
      concatMap(() => this.emitChatsUpdate()),
    );
  }

  public sendChatMessage(
    chatId: string,
    message?: string,
    replyData?: ReplyToData,
    pendingMessage?: PendingMessage,
    trackSend?: boolean,
  ): Observable<ChatMessage | null> {
    if (trackSend) this.applicationInsightsService.logCustomEvent(new ParameterlessEvent(USER_SENT_CHAT_MESSAGE));

    const createdDate = pendingMessage?.createdDate ?? new Date();
    const temporaryId = pendingMessage?.temporaryId ?? window.crypto.randomUUID();

    const data$ = pendingMessage
      ? of(pendingMessage.data)
      : createMessageData(message!, replyData, this, this.translocoService).pipe(
          concatMap((messageData) =>
            this.cacheService
              .updateChatsFn([chatId], (chat) => {
                const pendingMessage = mapToPendingMessage(messageData, createdDate, temporaryId);
                chat.pendingMessages.push(pendingMessage);

                return { pendingMessages: chat.pendingMessages };
              })
              .pipe(map(() => messageData)),
          ),
          concatMap((messageData) => this.emitChatsUpdate().pipe(map(() => messageData))),
        );

    return data$.pipe(
      // Send messages in order by waiting until this is the oldest pending message
      concatMap((messageData) =>
        concatWaitUntil(
          () =>
            this.cacheService
              .getGraphData()
              .pipe(
                map(({ chats: cachedChats }) =>
                  cachedChats
                    .find((chat) => chat.id === chatId)!
                    .pendingMessages.every(
                      (pendingMessage) =>
                        pendingMessage.temporaryId === temporaryId ||
                        pendingMessage.createdDate >= createdDate ||
                        pendingMessage.sendingFailed,
                    ),
                ),
              ),
          125,
        ).pipe(map(() => messageData)),
      ),

      concatMap((messageData) => {
        const body = {
          ...messageData,
          attachments: messageData.graphAttachments,
        };

        return this.http.post<MSGraphChatMessage>(`${GRAPH_API_BASE}/chats/${chatId}/messages`, body).pipe(
          // Retry every 10s until the message is older than 5 minutes. If it still fails, mark sending as failed
          retry({
            delay: (_, retryCount) =>
              createdDate > subMinutes(new Date(), 5)
                ? timer(retryCount === 1 ? 1_000 : 10_000)
                : throwError(() => new Error('retry timeout reached')),
          }),
        );
      }),

      // Once the message has been sent, set its graphId as quickly as possible
      concatMap((graphMessage) =>
        scheduled(
          mapToChatMessage(graphMessage, [], this.userService).pipe(
            catchError((error) => {
              if (environment.enableLogging) console.error(error);
              this.applicationInsightsService.logCustomException(error);

              return of(null);
            }),
            concatMap((chatMessage) =>
              this.cacheService
                .updateChatsFn([chatId], (chat) => {
                  const pendingMessage = chat.pendingMessages.find(
                    (pendingMessage) => pendingMessage.temporaryId === temporaryId,
                  )!;

                  if (chatMessage) pendingMessage.graphId = chatMessage.id;
                  else pendingMessage.sendingFailed = true;

                  const pendingMessages = filterPendingMessages(chat.pendingMessages, chat.messages);

                  return { pendingMessages };
                })
                .pipe(map(() => chatMessage)),
            ),
          ),
          asapScheduler,
        ),
      ),

      concatMap((messageData) => this.emitChatsUpdate().pipe(map(() => messageData))),
    );
  }

  public sendInvitationAdaptiveCardInChannel(
    teamObjectId: string,
    teamDisplayName: string,
    channelId: string,
  ): Observable<MSGraphChatMessage> {
    return createInvitationAdaptiveCard(
      teamDisplayName,
      this.userService,
      this.authService,
      this.translocoService,
    ).pipe(
      concatMap((message) =>
        this.http.post<MSGraphChatMessage>(
          `${GRAPH_API_BASE}/teams/${teamObjectId}/channels/${channelId}/messages`,
          message,
        ),
      ),
    );
  }

  public sendInvitationAdaptiveCardInChat(chatId: string, teamDisplayName: string): Observable<MSGraphChatMessage> {
    return createInvitationAdaptiveCard(
      teamDisplayName,
      this.userService,
      this.authService,
      this.translocoService,
    ).pipe(
      concatMap((message) => this.http.post<MSGraphChatMessage>(`${GRAPH_API_BASE}/chats/${chatId}/messages`, message)),
    );
  }

  public setReaction(
    chatId: string,
    messageId: number,
    reactionType: string,
    isActive: boolean,
    markChatAsRead: boolean,
  ): Observable<void> {
    this.applicationInsightsService.logCustomEvent(new UserSetChatMessageReactionEvent(isActive));

    const endpoint = isActive ? 'setReaction' : 'unsetReaction';
    const request = this.http.post<void>(`${GRAPH_API_BASE}/chats/${chatId}/messages/${messageId}/${endpoint}`, {
      reactionType,
    });

    if (markChatAsRead) return request.pipe(concatMap(() => this.setReadState(chatId, true)));
    else return request;
  }

  public setReadState(chatId: string, isRead: boolean): Observable<void> {
    const endpoint = isRead ? 'markChatReadForUser' : 'markChatUnreadForUser';

    return this.cacheService
      .updateChatsFn([chatId], (chat) => ({
        lastMessageReadDateTime:
          (!isRead &&
            chat.messages &&
            chat.messages.length > 2 &&
            chat.messages[chat.messages.length - 2]?.createdDateTime) ||
          chat.lastMessageReadDateTime,

        metadata: {
          ...chat.metadata,

          unreadMessageCount: isRead ? 0 : 1,
        },
      }))
      .pipe(
        concatMap(() => this.emitChatsUpdate()),
        concatMap(() =>
          this.http.post<void>(`${GRAPH_API_BASE}/chats/${chatId}/${endpoint}`, {
            user: {
              id: this.authService.context!.user!.id,
              tenantId: this.authService.context!.user!.tenant!.id,
            },
          }),
        ),
      );
  }

  public getChatMessages(
    chatId: string,
    chatType: ChatType,
    chatMembers: AadUserConversationMember[],
    options: GetChatMessagesOptions = { messageCount: 20 },
  ): Observable<{ messages: ChatMessage[]; nextLink?: string }> {
    const endpoint = createGetChatMessagesEndpoint(chatId, options);

    return this.http.get<{ value: MSGraphChatMessage[]; '@odata.nextLink': string | undefined }>(endpoint).pipe(
      concatMap(({ value: graphChats, '@odata.nextLink': nextLink }) => {
        if (graphChats.length === 0) return of({ messages: [], nextLink: nextLink || undefined });

        return forkJoin(
          graphChats.map((graphChatMessage) => mapToChatMessage(graphChatMessage, chatMembers, this.userService)),
        ).pipe(
          map((chatMessages) => filterChatMessages(chatMessages, chatType, options.lastMessageRead)),
          map((chatMessages) => chatMessages.sort(sortChatsMessagesByCreatedDateTime)),
          map((messages) => ({ messages, nextLink: nextLink || undefined })),
        );
      }),
      catchError((error) => {
        if (environment.enableLogging) console.error(error);
        this.applicationInsightsService.logCustomException(error);

        return of({ messages: [] as ChatMessage[], nextLink: undefined });
      }),
    );
  }

  public getImage(url: string): Observable<string | null> {
    if (url.startsWith('../hostedContents/')) return of(null);

    return this.http
      .get(url, { responseType: 'arraybuffer', context: new HttpContext().set(SET_OFFLINE_ON_FAILURE, false) })
      .pipe(
        map((response) => arrayBufferToDataUrl(response)!),
        catchError(() => of(null)),
      );
  }

  public emitChatsUpdate(): Observable<Chat[]> {
    return this.cacheService.getGraphData().pipe(
      tap(({ chats }) => this.chatsSubject.next(chats)),
      map(({ chats }) => chats),
    );
  }

  public getChat(chatId: string): Observable<Chat> {
    return this.http.get<MSGraphChat>(`${GRAPH_API_BASE}/chats/${chatId}?$expand=members,lastMessagePreview`).pipe(
      concatMap((graphChat) =>
        this.cacheService.getGraphData().pipe(map(({ chats }) => ({ graphChat, cachedChats: chats }))),
      ),
      concatMap(({ graphChat, cachedChats }) =>
        mapToChat(graphChat, cachedChats, this.userService, this.translocoService),
      ),
    );
  }
}
