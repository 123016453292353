import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';

import { TranslocoDirective } from '@ngneat/transloco';

import { ButtonComponent } from '../button/button.component';
import { EmojiComponent } from '../emojis/emoji/emoji.component';
import { ArrowIconComponent } from '../icons/arrow.component';

import { TourService } from '../../services/tour.service';

import { GetEmojiPipe } from '../../pipes/get-emoji.pipe';
import { SafePipe } from '../../pipes/safe.pipe';

@Component({
  selector: 'app-tour-tooltip',
  standalone: true,
  imports: [TranslocoDirective, SafePipe, ButtonComponent, ArrowIconComponent, EmojiComponent, GetEmojiPipe],
  templateUrl: './tour-tooltip.component.html',
  styleUrls: ['./tour-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourTooltipComponent {
  public readonly tourService = inject(TourService);

  @Input({ required: true }) public tourId!: string;
  @Input({ required: true }) public stepId!: string;
  @Input() public isFirstStep = false;
  @Input() public isLastStep = false;
  @Input() public name?: string;
}
