import { Component, ElementRef, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { AfterViewInit, OnChanges, OnDestroy } from '@angular/core';
import { ViewChild, ViewChildren } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';

import { of, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { debounceTime, fromEvent, interval } from 'rxjs';
import { HostClientType } from '@microsoft/teams-js';

import { AvatarComponent } from '../avatar/avatar.component';

import { AuthService } from '../../services/auth.service';
import { TourService } from '../../services/tour.service';
import { UserService } from '../../services/user.service';

import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';
import { TooltipStylesDirective } from '../../directives/tooltip/tooltip-styles.directive';
import { NgForTrackByPropertyDirective } from '../../directives/track-by-property.directive';

import { DataWithTracking } from '../../models/tracking.model';
import { Call } from 'src/app/shared/models/services/call.model';
import { FrontendAvailability, Location, User } from 'src/app/shared/models/services/user.model';

@Component({
  selector: 'app-horizontal-avatar-scroller',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    AsyncPipe,
    AvatarComponent,
    TooltipDirective,
    TooltipStylesDirective,
    NgForTrackByPropertyDirective,
  ],
  templateUrl: './horizontal-avatar-scroller.component.html',
  styleUrls: ['./horizontal-avatar-scroller.component.scss'],
})
export class HorizontalAvatarScrollerComponent implements AfterViewInit, OnChanges, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  private resizeObserver?: ResizeObserver;

  public Availability = FrontendAvailability;
  public Location = Location;

  // Whether the container is currently scrolling
  public isScrolling = false;
  // The index of the avatar that is on the left
  public currentLeftAvatarIndex = 0;
  public currentAvatarCount = 0;
  public calculatedPageSize = 0;

  @Input() public enableDoNotDisturbBadge = false;
  @Input() public enableInACallBadge = false;
  @Input() public enablePresentingBadge = false;
  @Input() public enableInOtherRoomBadge = false;
  @Input() public enableOutOfOfficeBadge = false;
  @Input() public enableVisitorBadge = true;
  @Input() public enableCallButton = true;
  @Input() public enableChatOpening = true;
  @Input() public enableEmoji = false;
  @Input() public enableSimpleHover = false;
  @Input() public enableLicenseInvalidBadge = false;

  @Input() public useStaticSizing = false;
  @Input() public useCompactSizing = false;
  @Input() public disableHover = false;

  @Input() public myCall?: Call;
  @Input({ required: true }) public usersWithTracking!: DataWithTracking<User[]>;

  @Output() public avatarHoverIsActive = new EventEmitter<boolean>();

  @ViewChild('avatarContainer') private avatarContainer?: ElementRef;
  @ViewChildren('avatar', { read: ElementRef }) private avatars?: QueryList<ElementRef>;

  public constructor(
    public readonly userService: UserService,
    public readonly tourService: TourService,
    private readonly authService: AuthService,
  ) {
    this.resizeObserver = new ResizeObserver(() => {
      this.calculatePageSize();
      this.scrollToOnResize();
    });
  }

  public ngAfterViewInit(): void {
    this.resizeObserver!.observe(this.avatarContainer!.nativeElement);

    // Properly update state (e.g. hover status) on mobile devices
    const updateIndices$ = fromEvent(this.avatarContainer!.nativeElement, 'scroll', {
      passive: true,
    }).pipe(
      tap(() => {
        const avatarScrollWidth = this.getAvatarScrollWidth(true);
        if (avatarScrollWidth === 0) return;

        const scrollLeft = this.avatarContainer!.nativeElement.scrollLeft;
        if (scrollLeft <= 20) {
          this.currentLeftAvatarIndex = 0;
        } else {
          this.currentLeftAvatarIndex = Math.max(0, Math.floor(scrollLeft / avatarScrollWidth) + 1);
        }
      }),
    );

    this.authService.isMobileWidth$
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap((isMobileWidth) => (isMobileWidth ? updateIndices$ : of(null))),
      )
      .subscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('usersWithTracking' in changes) {
      this.setUsersWithTracking(
        this.usersWithTracking,
        changes.usersWithTracking.previousValue?.users?.length !== this.usersWithTracking.data.length,
      );

      if (changes.usersWithTracking.isFirstChange()) {
        interval(125)
          .pipe(takeUntil(this.unsubscribe), take(6))
          .subscribe(() => {
            this.calculatePageSize();
          });
      }
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.resizeObserver?.disconnect();
  }

  public scrollLeft(event: MouseEvent): void {
    event.stopPropagation();

    this.listenForScrollFinish();

    const listScrolledToLeft =
      this.currentLeftAvatarIndex === 0 || this.currentLeftAvatarIndex - this.calculatedPageSize <= 0;
    const listScrolledToRight =
      this.currentLeftAvatarIndex + this.calculatedPageSize >= this.usersWithTracking.data.length;

    this.currentLeftAvatarIndex = Math.max(
      0,
      this.currentLeftAvatarIndex -
        Math.max(1, this.calculatedPageSize - (listScrolledToLeft ? 0 : listScrolledToRight ? -1 : 1)),
    );

    const pageLeftOffset = this.getPageLeftOffset();
    this.scrollTo(pageLeftOffset);
  }

  public scrollRight(event: MouseEvent): void {
    event.stopPropagation();

    this.listenForScrollFinish();

    const listScrolledToLeft = this.currentLeftAvatarIndex === 0;
    const listScrolledToRight =
      this.currentLeftAvatarIndex + this.calculatedPageSize >= this.usersWithTracking.data.length;

    this.currentLeftAvatarIndex = Math.min(
      this.usersWithTracking.data.length,
      this.currentLeftAvatarIndex +
        Math.max(1, this.calculatedPageSize - (listScrolledToLeft || listScrolledToRight ? 0 : 1)),
    );

    const pageLeftOffset = this.getPageLeftOffset();
    this.scrollTo(pageLeftOffset);
  }

  public onUserDeleted(index: number): void {
    this.usersWithTracking.data.splice(index, 1);
    this.setUsersWithTracking(this.usersWithTracking, true);
  }

  private calculatePageSize(): void {
    if (!this.avatarContainer?.nativeElement) return;

    const avatarScrollWidthWithMargin = this.getAvatarScrollWidth(true);
    if (avatarScrollWidthWithMargin === 0) {
      this.calculatedPageSize = 0;
      return;
    }

    const containerWidth = this.avatarContainer.nativeElement.clientWidth;
    const pageSizeWithMargin = Math.floor(containerWidth / avatarScrollWidthWithMargin);

    // Check if one additional element fits (minus margin), if all avatars fit
    const avatarScrollWidthWithoutMargin = this.getAvatarScrollWidth(false);
    if (
      this.usersWithTracking.data.length <= pageSizeWithMargin + 1 &&
      containerWidth >= pageSizeWithMargin * avatarScrollWidthWithMargin + avatarScrollWidthWithoutMargin
    ) {
      this.calculatedPageSize = pageSizeWithMargin + 1;
    } else {
      this.calculatedPageSize = pageSizeWithMargin;
    }

    // If any avatar does not fit, pretend that there is one more avatar to require fewer special cases
    if (this.calculatedPageSize < this.usersWithTracking.data.length)
      this.currentAvatarCount = this.usersWithTracking.data.length + 1;
    else this.currentAvatarCount = this.usersWithTracking.data.length;
  }

  private setUsersWithTracking(value: DataWithTracking<User[]>, avatarCountChanged: boolean): void {
    this.currentAvatarCount = value.data.length;

    if (value.data.length === 0) {
      this.currentLeftAvatarIndex = 0;
      this.currentAvatarCount = 0;

      this.scrollTo(0);

      return;
    }

    if (avatarCountChanged && this.avatarContainer?.nativeElement) {
      this.scrollTo(0);

      this.currentLeftAvatarIndex = 0;
    }
  }

  private listenForScrollFinish(): void {
    this.isScrolling = true;

    fromEvent(this.avatarContainer!.nativeElement, 'scroll', { passive: true })
      .pipe(takeUntil(this.unsubscribe), debounceTime(250), take(1))
      .subscribe(() => {
        this.isScrolling = false;
      });
  }

  private scrollTo(left: number, behavior = 'smooth'): void {
    if (!this.avatarContainer?.nativeElement) return;

    // Element.scrollTo with smooth scrolling does not work on iOS/iPadOS
    if (
      this.authService.context!.app.host.clientType === HostClientType.ipados ||
      this.authService.context!.app.host.clientType === HostClientType.ios
    ) {
      this.avatarContainer.nativeElement.scrollTo(left, 0);
    } else {
      this.avatarContainer.nativeElement.scrollTo({
        left,
        behavior,
      });
    }
  }

  private scrollToOnResize(): void {
    if (this.currentLeftAvatarIndex === 0) {
      this.scrollTo(0, 'auto');
      return;
    }

    if (this.currentLeftAvatarIndex + this.calculatedPageSize >= this.usersWithTracking.data.length) {
      this.scrollTo(this.avatarContainer!.nativeElement.scrollWidth, 'auto');
      return;
    }

    const pageLeftOffset = this.getPageLeftOffset();
    this.scrollTo(pageLeftOffset, 'auto');
  }

  private getAvatarScrollWidth(useMargin: boolean): number {
    if (!this.avatars?.first) return 0;

    const anyAvatar = this.avatars.first.nativeElement;
    const avatarStyle = window.getComputedStyle(anyAvatar);

    return (
      parseInt(anyAvatar.clientWidth) +
      (useMargin
        ? parseFloat(avatarStyle.marginLeft.slice(0, -2)) + parseFloat(avatarStyle.marginRight.slice(0, -2))
        : 0)
    );
  }

  private getPageLeftOffset(): number {
    const avatarScrollWidth = this.getAvatarScrollWidth(true);
    return avatarScrollWidth * Math.max(0, this.currentLeftAvatarIndex - 1);
  }
}
