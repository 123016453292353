import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ScaleByOfficePipe } from '../../pipes/scale-by-office.pipe';

@Component({
  selector: 'app-spinner',
  standalone: true,
  template: `<div class="circle"></div>`,
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnChanges {
  // An RGB color code, e.g. '255, 255, 255' for white
  @Input()
  @HostBinding('style.--spinner-color')
  public color?: string;

  // Any valid `CSS` is applicable. In addition our internal `scale-by-office($factor)` method can be used.
  @Input()
  public size?: string;

  @HostBinding('style.--spinner-size')
  public spinnerSize?: string;

  public constructor(private readonly scaleByOfficePipe: ScaleByOfficePipe) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ('size' in changes && this.size) this.spinnerSize = this.scaleByOfficePipe.transform(this.size);
  }
}
