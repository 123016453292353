import { catchError, concatMap, map, merge, Observable, of } from 'rxjs';
import { AadUserConversationMember, Chat as MSGraphChat, ConversationMember } from '@microsoft/microsoft-graph-types';
import { TranslocoService } from '@ngneat/transloco';
import { differenceInSeconds, parseISO } from 'date-fns';

import { SignalrService } from 'src/app/shared/services/signalr.service';
import { TeamService } from 'src/app/shared/services/team.service';
import { UserService } from 'src/app/shared/services/user.service';

import { Chat, GetChatOptions } from '../../../models/services/chat/chat.model';
import { ChatMessage, PendingMessage } from 'src/app/shared/models/services/chat/message.model';
import { Location, UserOrParticipant } from 'src/app/shared/models/services/user.model';

import { isUUID } from '../../misc.util';
import { concatTeamInfoData } from '../team.util';
import { mapToChatMessageInfo } from './message.util';

import { GRAPH_API_BASE, TRANSLATION_KEYS } from '../../../constants';

export const UNREAD_MESSAGE_REFRESH_TIME_MS = 30 * 1_000;

export function mapGraphDateStringToDate(rawDate: string | undefined): Date | undefined {
  if (!rawDate) return undefined;

  const date = parseISO(rawDate);

  return (date.getFullYear(), date.getMonth(), date.getDate()) ? date : undefined;
}

export function mapToChat(
  graphChat: MSGraphChat,
  cachedChats: Chat[],
  userService: UserService,
  translocoService: TranslocoService,
): Observable<Chat> {
  const members = removeNonUserChatMembers(graphChat.members!);

  return computeChatName(graphChat, members, userService, translocoService).pipe(
    concatMap((name) =>
      mapToChatMessageInfo(graphChat.lastMessagePreview ?? undefined, userService).pipe(
        map((lastMessagePreview) => ({ name, lastMessagePreview })),
      ),
    ),
    map(({ name, lastMessagePreview }) => {
      const lastMessageReadDateTime = mapGraphDateStringToDate(
        graphChat.viewpoint?.lastMessageReadDateTime ?? undefined,
      );
      const lastUpdatedDateTime = mapGraphDateStringToDate(graphChat.lastUpdatedDateTime ?? undefined);

      const cachedChat = cachedChats.find((cachedChat) => cachedChat.id === graphChat.id);

      const mappedGraphChat: Chat = {
        id: graphChat.id!,
        chatType: graphChat.chatType!,

        name,
        members,
        deepLink: graphChat.webUrl!,

        messages: cachedChat?.messages,
        messagesNextLink: cachedChat?.messagesNextLink,
        pendingMessages: cachedChat?.pendingMessages ?? [],

        lastUpdatedDateTime,
        lastMessageReadDateTime,
        lastMessagePreview,

        subscription: cachedChat?.subscription,

        metadata: {
          order: cachedChat?.metadata.order,
          isMinimized: cachedChat?.metadata.isMinimized ?? false,

          currentComposingMessage: cachedChat?.metadata.currentComposingMessage,

          messagesLastFetched: cachedChat?.metadata.messagesLastFetched,
          unreadMessageCount: cachedChat?.metadata.unreadMessageCount,

          lastOpenedDateTime: cachedChat?.metadata.lastOpenedDateTime,
        },
      };

      return mappedGraphChat;
    }),
  );
}

export function removeNonUserChatMembers(members: ConversationMember[]): AadUserConversationMember[] {
  const isUser = (odataType: string): boolean => {
    return (
      odataType === '#microsoft.graph.aadUserConversationMember' ||
      odataType === '#microsoft.graph.microsoftAccountUserConversationMember'
    );
  };

  return members.filter(
    (member) => '@odata.type' in member && isUser(member['@odata.type'] as string),
  ) as AadUserConversationMember[];
}

export function sortChatsByOrder(left: Chat, right: Chat): number {
  if (left.metadata.order === undefined && right.metadata.order === undefined) return 0;

  if (left.metadata.order === undefined) return 1;
  if (right.metadata.order === undefined) return -1;

  return left.metadata.order - right.metadata.order;
}

export function sortChatsByType(left: Chat, right: Chat): number {
  if (left.chatType === 'oneOnOne' && right.chatType === 'oneOnOne') return 0;

  if (left.chatType === 'oneOnOne') return -1;
  if (right.chatType === 'oneOnOne') return 1;

  return 0;
}

export function sortChatsByRecency(left: Chat, right: Chat): number {
  const getMostRecentDate = (chat: Chat): Date | undefined => {
    const lastMessagePreviewCreatedDateTime = chat.lastMessagePreview?.createdDateTime;

    if (chat.lastUpdatedDateTime && !lastMessagePreviewCreatedDateTime) return chat.lastUpdatedDateTime;
    if (!chat.lastUpdatedDateTime && lastMessagePreviewCreatedDateTime) return lastMessagePreviewCreatedDateTime;

    if (!chat.lastUpdatedDateTime || !lastMessagePreviewCreatedDateTime) return undefined;

    if (chat.lastUpdatedDateTime >= lastMessagePreviewCreatedDateTime) return chat.lastUpdatedDateTime;
    if (chat.lastUpdatedDateTime < lastMessagePreviewCreatedDateTime) return lastMessagePreviewCreatedDateTime;

    return undefined;
  };

  const relevantLeftDate = getMostRecentDate(left);
  const relevantRightDate = getMostRecentDate(right);

  if (!relevantLeftDate) return 1;
  if (!relevantRightDate) return -1;

  if (relevantLeftDate >= relevantRightDate) return -1;
  if (relevantLeftDate < relevantRightDate) return 1;

  return 0;
}

export function mapToMemberForRequest(userObjectId: string): {
  '@odata.type': string;
  'user@odata.bind': string;
  roles: string[];
} {
  return {
    '@odata.type': '#microsoft.graph.aadUserConversationMember',
    'user@odata.bind': `https://graph.microsoft.com/v1.0/users('${userObjectId}')`,
    roles: ['owner'],
  };
}

export function createGetChatsEndpoint(options: GetChatOptions): string {
  const baseUrl = `${GRAPH_API_BASE}/me/chats`;
  const top = `$top=${options.chatCount}`;
  const orderBy = `$orderBy=lastMessagePreview/createdDateTime desc`;
  const expand = `$expand=lastMessagePreview,members`;

  // Set chatFilter
  let chatFilter = "(members/$count gt 1 or chatType ne 'oneOnOne') and chatType ne 'unknownFutureValue'";
  if (options.chatType) chatFilter += ` and chatType eq '${options.chatType}`;

  let contentFilter = 'lastMessagePreview ne null';

  if (options.searchString) {
    const search = options.searchString.replace(/'/g, '').toLocaleLowerCase();
    const containsSearch = (property: string) => `contains(tolower(${property}), '${search}')`;

    // Set lastMessagePreviewFilter
    let lastMessagePreviewFilter = '';
    if (options.includeLastMessagePreviewInSearch) {
      lastMessagePreviewFilter = `or ${containsSearch('lastMessagePreview/body/content')}`;
    }

    // Set contentFilter
    contentFilter = `${containsSearch('topic')} or members/any(m: ${containsSearch('m/displayName')})`;
    contentFilter += ` ${lastMessagePreviewFilter}`;
  }

  return `${baseUrl}?${top}&${orderBy}&${expand}&$filter=${chatFilter} and (${contentFilter})`;
}

export function filterChatsBySearch(search: string, myUserObjectId: string, chats: MSGraphChat[]): MSGraphChat[] {
  const searchLower = search.toLocaleLowerCase();
  return chats.filter(
    (chat) =>
      chat.topic?.toLocaleLowerCase().includes(searchLower) ||
      chat.lastMessagePreview?.body?.content?.toLocaleLowerCase().includes(searchLower) ||
      chat.members?.some(
        (member) =>
          'userId' in member &&
          member.userId !== myUserObjectId &&
          member.displayName?.toLocaleLowerCase().includes(searchLower),
      ),
  );
}

function computeChatName(
  graphChat: MSGraphChat,
  members: AadUserConversationMember[],
  userService: UserService,
  translocoService: TranslocoService,
): Observable<string> {
  if (graphChat.chatType === 'oneOnOne') {
    const otherUser = members.find((member) => member.userId !== userService.userInfo?.user.objectId)!;
    if (!isUUID(otherUser.userId)) return of(otherUser.userId!);

    return userService.getCachedOrFreshGraphUser(otherUser.userId!).pipe(
      catchError(() => of({ displayName: otherUser.displayName, firstName: undefined, lastName: undefined })),
      map((graphUser) => {
        if (graphUser?.firstName && graphUser.lastName) {
          return `${graphUser.firstName} ${graphUser.lastName}`;
        }

        return (
          graphUser?.displayName || otherUser.displayName || translocoService.translate(TRANSLATION_KEYS.chat.unknown)
        );
      }),
    );
  }

  if (graphChat.topic) return of(graphChat.topic);

  if (graphChat.chatType === 'group') return of(translocoService.translate<string>(TRANSLATION_KEYS.chat.groupChat));

  return of(translocoService.translate<string>(TRANSLATION_KEYS.chat.unknown));
}

export function getUserFromTeamInfoOrCreateDummy(
  targetUserObjectId: string,
  teamService: TeamService,
  signalrService: SignalrService,
): Observable<UserOrParticipant> {
  const dummyUserOrParticipant: UserOrParticipant = {
    objectId: targetUserObjectId,
    isExternalAccount: false,
    baseLocation: Location.Inactive,
    availability: null,
    selectedEmoji: null,
    workLocation: null,
    individualWorkLocation: null,
    currentTeamObjectId: null,
    selectedTeamObjectId: null,
    visitingTeamObjectId: null,
  };

  return merge(teamService.teamInfoData$, signalrService.teamInfoDataUpdated).pipe(
    map(
      (teamInfoData) =>
        concatTeamInfoData(teamInfoData)
          .map((teamInfo) => teamInfo.users)
          .flat()
          .find((user) => user.objectId === targetUserObjectId) || dummyUserOrParticipant,
    ),
  );
}

export function chatNeedsUpdate(oldChat: Chat | undefined, newChat: Chat): boolean {
  const chatIsOpenWithNoMessages = !newChat.messages && newChat.metadata.order !== undefined;
  if (chatIsOpenWithNoMessages) return true;

  const chatHasUnreadMessages = Boolean(newChat.metadata.unreadMessageCount);
  if (chatHasUnreadMessages) return true;

  const now = new Date();
  const membersOrTopicHaveChanged =
    differenceInSeconds(oldChat?.lastUpdatedDateTime ?? now, newChat.lastUpdatedDateTime ?? now) > 0;
  const viewpointHasChanged =
    differenceInSeconds(oldChat?.lastMessageReadDateTime ?? now, newChat.lastMessageReadDateTime ?? now) > 0;

  const lastMessageHasChanged = oldChat?.lastMessagePreview?.id !== newChat.lastMessagePreview?.id;

  const lastMessageCreated = newChat.lastMessagePreview?.createdDateTime;
  const newMessageSinceLastCheck =
    Boolean(newChat.lastMessagePreview) &&
    (!oldChat?.metadata.messagesLastFetched ||
      differenceInSeconds(lastMessageCreated ?? now, oldChat.metadata.messagesLastFetched) > 0);

  return viewpointHasChanged || lastMessageHasChanged || membersOrTopicHaveChanged || newMessageSinceLastCheck;
}

export function filterPendingMessages(pendingMessages: PendingMessage[], messages?: ChatMessage[]): PendingMessage[] {
  return pendingMessages.filter(
    (pendingMessage) =>
      pendingMessage.graphId === undefined || !messages?.some((message) => message.id === pendingMessage.graphId),
  );
}
