/**
 * @name ranges-sort
 * @fileoverview Sort string index ranges
 * @version 6.0.11
 * @author Roy Revelt, Codsen Ltd
 * @license MIT
 * {@link https://codsen.com/os/ranges-sort/}
 */

var l = "6.0.11";
var y = l,
  d = {
    strictlyTwoElementsInRangeArrays: !1,
    progressFn: null
  };
function g(t, u) {
  if (!Array.isArray(t) || !t.length) return t;
  let n = {
      ...d,
      ...u
    },
    s,
    o;
  if (n.strictlyTwoElementsInRangeArrays && !t.every((e, r) => !Array.isArray(e) || e.length !== 2 ? (s = r, o = e.length, !1) : !0)) throw new TypeError(`ranges-sort: [THROW_ID_03] The first argument should be an array and must consist of arrays which are natural number indexes representing TWO string index ranges. However, ${s}th range (${JSON.stringify(t[s], null, 4)}) has not two but ${o} elements!`);
  if (!t.every((e, r) => !Array.isArray(e) || !Number.isInteger(e[0]) || e[0] < 0 || !Number.isInteger(e[1]) || e[1] < 0 ? (s = r, !1) : !0)) throw new TypeError(`ranges-sort: [THROW_ID_04] The first argument should be an array and must consist of arrays which are natural number indexes representing string index ranges. However, ${s}th range (${JSON.stringify(t[s], null, 4)}) does not consist of only natural numbers!`);
  let p = t.length ** 2,
    i = 0;
  return Array.from(t).sort((e, r) => (n.progressFn && (i += 1, n.progressFn(Math.floor(i * 100 / p))), e[0] === r[0] ? e[1] < r[1] ? -1 : e[1] > r[1] ? 1 : 0 : e[0] < r[0] ? -1 : 1));
}
export { d as defaults, g as rSort, y as version };