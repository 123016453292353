import { isToday } from 'date-fns';

import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

import { Hints } from '../../models/cache.model';
import { InteractiveHintType } from '../../models/components/interactive-hint.model';
import { ActiveCalls, Call, CallContext, CallParticipant } from '../../models/services/call.model';
import { InteractiveHintText } from '../../models/services/hint.model';
import { Location } from '../../models/services/user.model';

// See: https://github.com/OfficeDev/microsoft-teams-library-js/issues/2005
// eslint-disable-next-line no-unused-vars
export function isEligibleForPopOutHint(authService: AuthService, userService: UserService): boolean {
  return false;
}

// Proves if system conditions are correct for displaying the pop-out-hint
/*
export function isEligibleForPopOutHint(authService: AuthService, userService: UserService): boolean {
  const hostClientType = authService.context!.app.host.clientType;
  const appIconPosition = authService.context!.app.iconPositionVertical;

  const validAppIconPosition = appIconPosition !== undefined && appIconPosition! >= 0;
  const isMultiWindow = authService.context!.page.isMultiWindow;
  const isDesktop = hostClientType === HostClientType.desktop;
  const firstAppUsage = !userService.userInfo || !userService.userInfo.hasUsedAppAtLeastOnce;
  const openedByNotification = Boolean(authService.appOpenedByNotificationEntity);

  return (
    !firstAppUsage &&
    isDesktop &&
    !isMultiWindow &&
    !authService.fromChannelTab &&
    !openedByNotification &&
    validAppIconPosition
  );
}
*/

export function isEligibleForJoinDesksCallHint(
  data: { myCall?: Call; hints: Hints },
  userService: UserService,
): boolean {
  const { myCall } = data;

  // Do not display join-desks-call-hint when user is in a call or is visiting the room
  if (myCall || userService.userInfo!.user.visitingTeamObjectId !== null) {
    // When user is currently sitting at desks we don't want to show the hint today
    if (myCall?.context === CallContext.Desks) data.hints.joinDesksCallHintLastShown = new Date();

    return false;
  }

  return true;
}

export function isEligibleForEmojiHint(
  data: { myCall?: Call; userOpenedEmojiPicker: boolean },
  userService: UserService,
): boolean {
  const { myCall, userOpenedEmojiPicker } = data;
  const hasEmojiSelected = userService.userInfo?.user.selectedEmoji;

  return !hasEmojiSelected && !userOpenedEmojiPicker && !myCall;
}

export function shouldInteractiveHintBeShown(data: {
  myCall?: Call;
  activeCalls?: ActiveCalls;
  hints: Hints;
}): InteractiveHintType | null {
  const { myCall, activeCalls } = data;

  const anyTopicMeetingCallHasParticipants =
    activeCalls?.topicMeetings.some((call) => call.participants.some((participant) => participant.isActive)) ?? false;
  const kitchenCallHasParticipants = (activeCalls?.kitchen && activeCalls.kitchen.participants.length > 0) ?? false;
  const desksCallHasParticipants = (activeCalls?.desks && activeCalls.desks.participants.length > 0) ?? false;

  const interactiveHintsLastDisplayed = data.hints.interactiveHintsLastShown;
  const interactiveHintsMoreThanFiveMinutesAgo =
    (interactiveHintsLastDisplayed && isDateMoreThanFiveMinutesAgo(interactiveHintsLastDisplayed)) ||
    !interactiveHintsLastDisplayed;

  const isEligibleForHint = Boolean(interactiveHintsMoreThanFiveMinutesAgo) && Boolean(!myCall);

  if (isEligibleForHint && anyTopicMeetingCallHasParticipants) return 'topicMeeting';
  if (isEligibleForHint && kitchenCallHasParticipants) return 'kitchen';
  if (isEligibleForHint && desksCallHasParticipants) return 'desks';

  return null;
}

function isDateMoreThanFiveMinutesAgo(date: Date): boolean {
  const fiveMinutesAgo = new Date();
  fiveMinutesAgo.setMinutes(fiveMinutesAgo.getMinutes() - 5);

  return date < fiveMinutesAgo;
}

export function shouldPopOutHintBeShown(hints: Hints): boolean {
  return Boolean(hints.popOut.count < 3 && (!hints.popOut.lastShown || !isToday(hints.popOut.lastShown)));
}

export function shouldJoinDesksCallHintBeShown(hints: Hints): boolean {
  return !hints.joinDesksCallHintLastShown || !isToday(hints.joinDesksCallHintLastShown);
}

export function shouldEmojiHintBeShown(
  data: { mainTableHintShown: boolean; kitchenHintShown: boolean; hints: Hints },
  userService: UserService,
): boolean {
  const { mainTableHintShown, kitchenHintShown } = data;

  const emojiHintDisplayedCount = data.hints.emojiPicker.count;
  const emojiHintLastDisplayed = data.hints.emojiPicker.lastShown;
  const emojiHintDisplayedToday = emojiHintLastDisplayed && isToday(emojiHintLastDisplayed);
  const iAmCheckedIn = userService.userInfo?.user.baseLocation !== Location.Inactive;

  return (
    emojiHintDisplayedCount < 5 && !emojiHintDisplayedToday && iAmCheckedIn && !(mainTableHintShown || kitchenHintShown)
  );
}

export function getTopicMeetingIndexForHint(topicMeetings: Call[] | undefined): number | null {
  if (!topicMeetings || topicMeetings.length === 0) return null;

  let [topicMeetingIndex, lowestParticipantCount] = [0, Number.MAX_SAFE_INTEGER];
  topicMeetings.forEach((topicMeeting, index) => {
    const activeParticipantCount = topicMeeting.participants.filter((participant) => participant.isActive).length;
    if (activeParticipantCount > 0 && activeParticipantCount < lowestParticipantCount) {
      topicMeetingIndex = index;
      lowestParticipantCount = activeParticipantCount;
    }
  });

  return lowestParticipantCount < Number.MAX_SAFE_INTEGER ? topicMeetingIndex : null;
}

export function computeInteractiveText(callParticipants?: CallParticipant[] | null): InteractiveHintText {
  let commaSeparatedNames: string;

  const participantsNames: string[] = [];
  for (const participant of callParticipants ?? []) {
    if (!participant.isActive) continue;

    participantsNames.push(participant.firstName ?? participant.displayName ?? '');
  }

  if (participantsNames.length === 1) {
    return {
      commaSeparatedNames: participantsNames[0],
      nameOfLastParticipant: undefined,
      callParticipantCase: 'oneParticipant',
    };
  }

  commaSeparatedNames = participantsNames[0];
  for (let i = 1; i <= participantsNames.length - 2; i++) commaSeparatedNames += ', ' + participantsNames[i];

  if (participantsNames.length <= 3) {
    return {
      commaSeparatedNames,
      nameOfLastParticipant: participantsNames[participantsNames.length - 1],
      callParticipantCase: 'twoOrThreeParticipants',
    };
  }

  return {
    commaSeparatedNames: commaSeparatedNames,
    nameOfLastParticipant: undefined,
    callParticipantCase: 'moreThanThreeParticipants',
  };
}
