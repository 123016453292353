/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Pipe, PipeTransform } from '@angular/core';

import { CATEGORY_ORDER, Emoji, EmojiGroup } from 'src/app/shared/models/services/emoji.model';

@Pipe({
  name: 'groupEmojis',
  standalone: true,
})
export class GroupEmojisPipe implements PipeTransform {
  public transform(emojis?: Emoji[]): EmojiGroup[] {
    if (!emojis) return [];

    const groupedEmojis: { [group: string]: Emoji[] } = {};

    // Group emojis by category
    emojis.forEach((emoji) => {
      if (!groupedEmojis[emoji.group]) {
        groupedEmojis[emoji.group] = [];
      }
      groupedEmojis[emoji.group].push(emoji);
    });

    const sortedGroups = Object.keys(groupedEmojis).sort(
      (a, b) => CATEGORY_ORDER.indexOf(a) - CATEGORY_ORDER.indexOf(b),
    );

    // Sort the emojis within each group by the sortOrder property, skip recent
    sortedGroups.forEach((group) => {
      if (group === 'Recent') return;

      groupedEmojis[group].sort((a, b) => a.sortOrder - b.sortOrder);
    });

    // Map grouped emojis to an array of objects
    return sortedGroups.map((key) => ({
      category: key,
      emojis: groupedEmojis[key],
    }));
  }
}
