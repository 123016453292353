import { inject, Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';

import {
  BehaviorSubject,
  distinctUntilChanged,
  forkJoin,
  Observable,
  of,
  shareReplay,
  startWith,
  throwError,
} from 'rxjs';
import { from, interval } from 'rxjs';
import { map, retryWhen, takeWhile, tap } from 'rxjs';
import { catchError, concatMap, delay, filter } from 'rxjs';
import { app, authentication, HostClientType } from '@microsoft/teams-js';
import { fromUnixTime } from 'date-fns';

import { CacheService } from './cache.service';
import { GlobalSpinnerService } from './global-spinner.service';
import { LogService } from './log.service';

import {
  AccessTokenResult,
  AUTHENTICATION_CACHE_STORAGE_PREFIX,
  BACKEND_SCOPE,
  OFFLINE_ERRORS,
  Resource,
  ScopeVersion,
} from '../models/services/auth.model';
import { BackendAccessTokenResult } from '../models/services/auth.model';
import { AuthType } from '../models/services/auth.model';

import { parseJwt } from '../utils/misc.util';
import { filterNullish } from '../utils/rxjs.util';
import {
  createAuthenticationUrl,
  getAuthType,
  getCachedAuthenticationResult,
  getGraphScope,
  getIsExternalAccount,
  isAccessTokenNearExpired,
  scopesHaveChanged,
  setCachedAuthenticationResult,
} from '../utils/services/auth.util';

import { AUTHENTICATION_REQUIRES_CONSENT, MOBILE_MEDIA_QUERY } from '../constants';
import { environment } from 'src/environments/environment';

const LOGGING_CONTEXT = 'AuthService';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly http = inject(HttpClient);
  private readonly globalSpinnerService = inject(GlobalSpinnerService);
  private readonly cacheService = inject(CacheService);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly logService = inject(LogService);

  private readonly contextSubject = new BehaviorSubject<app.Context | undefined>(undefined);

  private ssoToken: AccessTokenResult | null = null;
  private graphScope?: string;

  public backendScopeVersion: ScopeVersion = ScopeVersion.BackendV1;
  public graphScopeVersion?: ScopeVersion;

  public context?: app.Context;
  public authType?: AuthType;
  public isExternalAccount = false;

  public fromChannelTab = false;
  public appOpenedByNotificationEntity?: string;

  public isMobileDevice = false;
  public isIPadOs = false;
  public isMobileWidth$ = this.breakpointObserver.observe(MOBILE_MEDIA_QUERY).pipe(
    map((state) => state.matches),
    startWith(this.breakpointObserver.isMatched(MOBILE_MEDIA_QUERY)),
    distinctUntilChanged(),
    shareReplay(1),
  );

  public readonly context$ = this.contextSubject.pipe(filterNullish());

  public initializeContext(): Observable<app.Context> {
    return from(app.getContext()).pipe(
      tap((context) => {
        this.context = context;
        this.contextSubject.next(context);

        this.fromChannelTab = Boolean(this.context!.team?.groupId);
        this.appOpenedByNotificationEntity = context.page.subPageId;

        const clientType = context.app.host.clientType;
        this.isMobileDevice =
          clientType === HostClientType.android ||
          clientType === HostClientType.ios ||
          clientType === HostClientType.ipados;
        this.isIPadOs = clientType === HostClientType.ipados;
      }),
    );
  }

  public getCachedTokensOrAuthenticate(
    resource: Resource,
    assumeUserConsentIsGiven: boolean,
    findGraphScopeVersion = false,
  ): Observable<BackendAccessTokenResult> {
    const authenticate = (scopeVersion: ScopeVersion, enableRetry: boolean) => {
      if (scopeVersion === ScopeVersion.GraphV1 || scopeVersion === ScopeVersion.GraphV2) {
        this.graphScope = getGraphScope(this.authType!, scopeVersion);
      }

      return this.getTokenCache(resource).pipe(
        concatMap((cachedToken) =>
          cachedToken && cachedToken !== 'scopeChanged'
            ? of(cachedToken)
            : this.getAndCacheAccessTokenFromServer(
                resource,
                assumeUserConsentIsGiven && cachedToken !== 'scopeChanged',
                enableRetry,
              ),
        ),
      );
    };

    if (resource === 'Backend') {
      return this.getAndCacheSSOToken(false).pipe(concatMap(() => authenticate(ScopeVersion.BackendV1, true)));
    }

    if (!findGraphScopeVersion) return authenticate(this.graphScopeVersion || ScopeVersion.GraphV1, true);

    // Check if we have the admin consent for Graph V2, and if not try Graph V1
    return this.getAndCacheSSOToken(false).pipe(
      concatMap(() =>
        authenticate(ScopeVersion.GraphV2, false).pipe(
          tap(() => (this.graphScopeVersion = ScopeVersion.GraphV2)),
          catchError(() =>
            authenticate(ScopeVersion.GraphV1, true).pipe(tap(() => (this.graphScopeVersion = ScopeVersion.GraphV1))),
          ),
        ),
      ),
    );
  }

  public triggerAuthenticationPopup(): Observable<unknown> {
    let waitingForAuthentication = true;

    const isAuthenticationComplete = () => {
      const authenticationResult = localStorage.getItem(`${AUTHENTICATION_CACHE_STORAGE_PREFIX}.authentication-result`);
      const popupLoaded = localStorage.getItem(`${AUTHENTICATION_CACHE_STORAGE_PREFIX}.popup-loaded`);

      if (authenticationResult) {
        localStorage.removeItem(`${AUTHENTICATION_CACHE_STORAGE_PREFIX}.authentication-result`);
        const result = JSON.parse(authenticationResult);
        this.logService.logDebug(LOGGING_CONTEXT, result);

        const popupLoadedTime = popupLoaded != null ? parseInt(popupLoaded) : null;
        if (result.error === 'CancelledByUser' && popupLoadedTime && Date.now() - popupLoadedTime < 850) return false;

        localStorage.removeItem(`${AUTHENTICATION_CACHE_STORAGE_PREFIX}.popup-loaded`);

        waitingForAuthentication = false;

        if (!result) throw `Auth success but empty code response for scopes: ${BACKEND_SCOPE}; ${this.graphScope}`;
        if (result.error) throw result.error;
        if (!result.tokenResponse) {
          throw `Auth success but invalid code response for scopes: ${BACKEND_SCOPE}; ${this.graphScope}`;
        }

        return true;
      }

      return false;
    };

    const authUrl = createAuthenticationUrl(this.context!, this.isMobileDevice, this.graphScope!);
    from(authentication.authenticate({ url: authUrl, width: 650, height: 800 })).subscribe({
      error: (err) => {
        // window.close() may trigger this error on some clients, even if auth succeeded
        const cachedAuthenticationResult = getCachedAuthenticationResult();
        if (waitingForAuthentication && !cachedAuthenticationResult) {
          setCachedAuthenticationResult(JSON.stringify({ error: err }));
        }
      },
    });

    return interval(250).pipe(
      takeWhile(() => waitingForAuthentication),
      filter(() => isAuthenticationComplete()),
    );
  }

  public refreshCachedTokens(): Observable<unknown> {
    if (!this.ssoToken) return of(false);

    return forkJoin([
      this.getAndCacheAccessTokenFromServer('Backend', true, true),
      this.getAndCacheAccessTokenFromServer('Graph', true, true),
    ]);
  }

  public getAndCacheSSOToken(ignoreCache: boolean): Observable<AccessTokenResult> {
    const getAuthTokenFactory = (silent: boolean): Observable<AccessTokenResult> => {
      if (this.ssoToken && this.ssoToken.expires_on > new Date() && !ignoreCache) {
        return of(this.ssoToken);
      }

      return from(
        authentication.getAuthToken({
          silent,
        }),
      ).pipe(
        map((token: string) => {
          this.logService.logDebug(LOGGING_CONTEXT, `SSO token:`, token);

          if (!token) throw new Error('Get empty SSO token from Teams');

          const ssoTokenJwt = parseJwt(token);
          if (ssoTokenJwt.ver !== '1.0' && ssoTokenJwt.ver !== '2.0') {
            throw new Error(`SSO token is not valid with an unknown version: ${ssoTokenJwt.ver}`);
          }

          const expiresOn = fromUnixTime(ssoTokenJwt.exp);
          const ssoToken: AccessTokenResult = { access_token: token, expires_on: expiresOn, scope: ssoTokenJwt.scp };

          this.authType = getAuthType(ssoTokenJwt);
          this.isExternalAccount = getIsExternalAccount(this.authType);

          this.ssoToken = ssoToken;
          return ssoToken;
        }),
        catchError((errMessage: Error | string | null) => {
          const isOfflineError = (errorString: string) =>
            OFFLINE_ERRORS.some((offlineError) => errorString.includes(offlineError));

          if (
            (errMessage && errMessage instanceof Error && isOfflineError(errMessage.message)) ||
            (errMessage && !(errMessage instanceof Error) && isOfflineError(errMessage))
          ) {
            this.globalSpinnerService.showOfflineScreen();
          }

          return throwError(() => new Error(`Get SSO token failed with error: ${errMessage}`));
        }),
      );
    };

    return getAuthTokenFactory(true).pipe(
      catchError((err) => {
        if (
          err instanceof Error &&
          (err.message.includes('resourceRequiresConsent') || err.message.includes('tokenRevoked'))
        ) {
          return getAuthTokenFactory(false);
        }

        return throwError(err);
      }),
    );
  }

  private getAndCacheAccessTokenFromServer(
    resource: Resource,
    assumeUserConsentIsGiven: boolean,
    enableRetry: boolean,
  ): Observable<BackendAccessTokenResult> {
    return this.getTokenCache(resource)
      .pipe(
        concatMap((cachedToken) => {
          cachedToken = cachedToken === 'scopeChanged' ? null : cachedToken;

          const scope = cachedToken?.scope ?? (resource === 'Backend' ? BACKEND_SCOPE : this.graphScope);

          this.logService.logDebug(LOGGING_CONTEXT, `Get access token with scope`, scope);

          return this.http.post<BackendAccessTokenResult>(
            `${environment.backendUrl}/api/auth/acquire-on-behalf-of-token`,
            {
              scope,
              tenantObjectId: this.context!.user!.tenant!.id,
              refreshToken: cachedToken?.refreshToken,
            },
          );
        }),
      )
      .pipe(
        retryWhen((errors) => {
          // Do not show the popup as it needs to be triggered manually by a button click
          if (!enableRetry || (this.context!.app.host.clientType === HostClientType.web && !assumeUserConsentIsGiven)) {
            throw AUTHENTICATION_REQUIRES_CONSENT;
          }

          let retryCount = 0;
          return errors.pipe(
            concatMap((err) => {
              if (retryCount === 29) return throwError(err);

              retryCount += 1;
              // 'AadUiRequiredException' is from popUp and 'interaction_required' from backend
              if (err.error?.type === 'AadUiRequiredException' || err.error === 'interaction_required') {
                if (assumeUserConsentIsGiven) {
                  this.logService.logDebug(LOGGING_CONTEXT, 'UI is required, but we just got consent! Retry');

                  return of(true);
                }

                this.logService.logDebug(LOGGING_CONTEXT, 'Show consent UI');
                assumeUserConsentIsGiven = true;

                return this.triggerAuthenticationPopup();
              }

              return throwError(err);
            }),
            delay(500),
          );
        }),
        concatMap((accessToken) =>
          this.cacheService.upsertAuthData(resource, { accessToken }).pipe(map(() => accessToken)),
        ),
      );
  }

  private getTokenCache(resource: Resource): Observable<BackendAccessTokenResult | 'scopeChanged' | null> {
    return this.cacheService.getAuthData(resource).pipe(
      map((data) => {
        if (!data) {
          this.logService.logDebug(LOGGING_CONTEXT, `No cached access token`, resource);
          return null;
        }

        if (isAccessTokenNearExpired(data.accessToken)) {
          this.logService.logDebug(LOGGING_CONTEXT, `Cached access token is expired`, resource);
          return null;
        }

        if (scopesHaveChanged(data.accessToken.scope, resource, this.graphScope!)) {
          this.logService.logDebug(LOGGING_CONTEXT, `Cached access token has changed scope`, resource);
          return null;
        }

        return data.accessToken;
      }),
    );
  }
}
