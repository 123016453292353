export enum ScheduleAvailability {
  OnlyIAmUnavailable = 0,
  OnlyTheyAreUnavailable = 1,
  BothUnavailable = 2,
  BothFree = 3,
  theyAreOutsideWorkingHours = 4,
}

export interface ScheduleBlock {
  isNow: boolean;
  isHalfHour: boolean;
  isFullHour: boolean;

  startDateTime: Date;
  startDateTimeHour: number;
  endDateTime: Date;

  availability: ScheduleAvailability;
}
