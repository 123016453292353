import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { BehaviorSubject, concatMap, map, merge, Observable, of, take, tap } from 'rxjs';
import { catchError, throwError } from 'rxjs';
import { AadUserConversationMember, Group as MSGraphGroup } from '@microsoft/microsoft-graph-types';

import { AuthService } from './auth.service';
import { CacheService } from './cache.service';

import {
  AdminTeamInfo,
  CreateOrUpdateTeamData,
  Embeds,
  JoinedTeam,
  TeamInfoData,
  UpdateEmbedsBody,
  UpdateWebEmbedsData,
} from '../models/services/team.model';
import { Location, User } from '../models/services/user.model';

import { arrayBufferToDataUrl, getBase64FromDataUrl } from '../utils/misc.util';
import { getEmptyTeamInfoData, getTeamSettingsMap } from '../utils/services/team.util';

import { GRAPH_API_BASE } from '../constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  private readonly http = inject(HttpClient);
  private readonly authService = inject(AuthService);
  private readonly cacheService = inject(CacheService);

  // Note that this value is only set once on app startup, and if team settings change.
  // For updated values see `teamInfoDataUpdated` in `SignalrService`
  public readonly teamInfoData$ = new BehaviorSubject<TeamInfoData | null>(null);

  public getTeamInfoData(checkForWhitelistedTeams?: boolean): Observable<TeamInfoData> {
    const queryParams =
      checkForWhitelistedTeams !== undefined ? `?checkForWhitelistedTeams=${checkForWhitelistedTeams}` : '';

    return this.http.get<TeamInfoData>(`${environment.backendUrl}/api/team/team-info-data${queryParams}`).pipe(
      concatMap((teamInfoData) =>
        this.cacheService
          .updateGeneralDataFn(() => ({ teamSettings: getTeamSettingsMap(teamInfoData) }))
          .pipe(map(() => teamInfoData)),
      ),
      tap((teamInfoData) => this.teamInfoData$.next(teamInfoData)),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) return of(getEmptyTeamInfoData());

        throw err;
      }),
    );
  }

  public createOrUpdateEmbeds(data: UpdateWebEmbedsData): Observable<Embeds> {
    const body: UpdateEmbedsBody = { key: data.removeEmbed ? data.removeEmbed.key : data.embed!.key };
    if (data.removeEmbed) body.remove = true;

    if (data.embed) {
      body.title = data.embed.title;
      body.urlOrIFrame = data.embed.urlOrIFrame;
      body.base64Thumbnail = data.embed.thumbnail === null ? null : getBase64FromDataUrl(data.embed.thumbnail);
    }

    return this.http.put<Embeds>(`${environment.backendUrl}/api/team/settings/embeds`, body);
  }

  public getMyTeams(): Observable<JoinedTeam[]> {
    const request = this.http
      .get<{ value: { id: string; displayName: string }[] }>(`${GRAPH_API_BASE}/me/joinedTeams`)
      .pipe(
        map(({ value }) => value.map((value) => ({ objectId: value.id, displayName: value.displayName }))),
        concatMap((joinedTeams) =>
          this.cacheService.updateGraphDataFn(() => ({ joinedTeams })).pipe(map(() => joinedTeams)),
        ),
      );

    const externalRequest = this.getChannelTeamByGroups().pipe(map((channelTeam) => [channelTeam]));

    return this.cacheService.getGraphData().pipe(
      concatMap((graphData) => {
        const isExternal = this.authService.isExternalAccount;

        if (graphData.joinedTeams.length > 0) {
          return merge(of(graphData.joinedTeams), isExternal ? externalRequest : request).pipe(take(2));
        }

        return this.authService.isExternalAccount ? externalRequest : request;
      }),
    );
  }

  public getPhoto(teamObjectId: string): Observable<string | null> {
    if (this.authService.isExternalAccount) return of(null);
    const request = (endpoint: 'groups' | 'teams'): Observable<string | null> =>
      this.http
        .get(`${GRAPH_API_BASE}/${endpoint}/${teamObjectId}/photo/$value`, {
          headers: { Accept: 'image/jpg' },
          responseType: 'arraybuffer',
        })
        .pipe(
          map((photo) => arrayBufferToDataUrl(photo)),
          catchError((err) =>
            endpoint === 'groups' && err.status === HttpStatusCode.Forbidden ? request('teams') : of(null),
          ),
        );

    return request('groups');
  }

  public isTeamsDefaultPhoto(teamObjectId: string | undefined): Observable<boolean> {
    if (!teamObjectId || this.authService.isExternalAccount) return of(false);

    return this.getPhotoMetadata(teamObjectId).pipe(
      map(
        (metadata) =>
          (metadata?.height === 432 && metadata.width === 432) || (metadata?.height === 648 && metadata.width === 648),
      ),
    );
  }

  public getTeamMembers(teamObjectId: string): Observable<User[]> {
    return this.http
      .get<{ value: AadUserConversationMember[] }>(`${GRAPH_API_BASE}/teams/${teamObjectId}/members`)
      .pipe(
        map((res) =>
          res.value.map(
            (member) =>
              ({
                objectId: member.userId!,
                displayName: member.displayName!,
                emailAddress: member.email!,
                availability: null,
                baseLocation: Location.Inactive,
                currentTeamObjectId: null,
                individualWorkLocation: null,
                isExternalAccount: false,
                selectedEmoji: null,
                selectedTeamObjectId: null,
                visitingTeamObjectId: null,
                workLocation: null,
              }) satisfies User,
          ),
        ),
      );
  }

  public createOrUpdateTeam(createOrUpdateTeamData: CreateOrUpdateTeamData): Observable<AdminTeamInfo> {
    return this.http.put<AdminTeamInfo>(`${environment.backendUrl}/api/admin/team/consumer`, {
      ...createOrUpdateTeamData,
    });
  }

  private getPhotoMetadata(teamObjectId: string): Observable<{ height: number; width: number } | null> {
    return this.http.get<{ height: number; width: number }>(`${GRAPH_API_BASE}/teams/${teamObjectId}/photo`).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) return of(null);

        return throwError(() => err);
      }),
    );
  }

  private getChannelTeamByGroups(): Observable<JoinedTeam> {
    return this.http.get<MSGraphGroup>(`${GRAPH_API_BASE}/groups/${this.authService.context!.team?.groupId}`).pipe(
      map((group) => ({ objectId: group.id!, displayName: group.displayName! })),
      concatMap((channelTeam) =>
        this.cacheService.updateGraphDataFn(() => ({ joinedTeams: [channelTeam] })).pipe(map(() => channelTeam)),
      ),
    );
  }
}
