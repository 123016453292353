import { app } from '@microsoft/teams-js';
import { parseISO, subMinutes } from 'date-fns';

import {
  AUTHENTICATION_CACHE_STORAGE_PREFIX,
  AUTHENTICATION_CACHE_STORAGE_SEPARATOR,
  AUTHENTICATION_CACHE_STORAGE_TOKEN_PREFIX,
  AuthType,
  BACKEND_SCOPE,
  BackendAccessTokenResult,
  Resource,
  ScopeVersion,
  SSOTokenInfoBase,
} from '../../models/services/auth.model';

import { parseJwt } from '../misc.util';

import { environment } from 'src/environments/environment';

export function getAccessTokenCacheKey(
  accessTokenRaw: string,
  resource: 'Backend' | 'Graph',
  graphScope?: string,
): string {
  const scope = resource == 'Backend' ? BACKEND_SCOPE : graphScope;
  const accessTokenJwt = parseJwt(accessTokenRaw);

  const clientId = environment.azureActiveDirectory.clientId;
  const userObjectId = accessTokenJwt.oid;
  const tenantId = accessTokenJwt.tid;

  const key = [
    AUTHENTICATION_CACHE_STORAGE_PREFIX,
    AUTHENTICATION_CACHE_STORAGE_TOKEN_PREFIX,
    'v2',
    userObjectId,
    clientId,
    tenantId,
    scope,
  ]
    .join(AUTHENTICATION_CACHE_STORAGE_SEPARATOR)
    .replace(/' '/g, '_');
  return key;
}

export function isAccessTokenNearExpired(token: BackendAccessTokenResult): boolean {
  const expireDate = parseISO(token.expiresOn);
  return subMinutes(expireDate, 3) <= new Date();
}

export function scopesHaveChanged(grantedScopes: string, resource: Resource, graphScope: string): boolean {
  const expectedScopesArray = (resource === 'Backend' ? BACKEND_SCOPE : graphScope)
    .replace('offline_access', '')
    .replace('openid', '')
    .replace(/\s{2,}/gi, ' ')
    .trim()
    .split(' ');
  const grantedScopesArray = grantedScopes
    .replace(/\s{2,}/gi, ' ')
    .trim()
    .split(' ');

  return expectedScopesArray.some((scope) => !grantedScopesArray.includes(scope));
}

export function getAuthType(sso: SSOTokenInfoBase): AuthType {
  if (!sso.idp) return AuthType.Organization;
  else if (sso.idp === 'live.com') return AuthType.PrivateExternal;

  return AuthType.FederatedExternal;
}

export function getGraphScope(authType: AuthType, version: ScopeVersion.GraphV1 | ScopeVersion.GraphV2): string {
  const baseScope = ['openid', 'profile', 'offline_access'];

  // Same permissions as AuthType.PrivateExternal
  // But a private external has these as default (no need to get them explicitly)
  if (authType === AuthType.FederatedExternal) {
    baseScope.push(
      'https://graph.microsoft.com/Group.ReadWrite.All',
      'https://graph.microsoft.com/GroupMember.ReadWrite.All',
      'https://graph.microsoft.com/TeamMember.ReadWrite.All',
    );
  } else if (authType === AuthType.Organization) {
    // Note that the scope 'https://graph.microsoft.com/Group.ReadWrite.All' is used to fetch photos of groups,
    // but it is not requested here because it is granted via (and requires) admin consent
    baseScope.push(
      'https://graph.microsoft.com/User.ReadWrite',
      'https://graph.microsoft.com/User.ReadBasic.All',
      'https://graph.microsoft.com/Presence.Read.All',
      'https://graph.microsoft.com/OnlineMeetings.ReadWrite',
      'https://graph.microsoft.com/Team.ReadBasic.All',
      'https://graph.microsoft.com/TeamsAppInstallation.ReadWriteSelfForUser',
      'https://graph.microsoft.com/Calendars.Read',
      'https://graph.microsoft.com/Chat.ReadWrite',
      'https://graph.microsoft.com/Presence.ReadWrite',
      'https://graph.microsoft.com/Sites.Read.All',
      'https://graph.microsoft.com/Tasks.ReadWrite',
    );

    if (version === ScopeVersion.GraphV2) {
      baseScope.push(
        'https://graph.microsoft.com/Channel.ReadBasic.All',
        'https://graph.microsoft.com/ChannelMessage.Send',
      );
    }
  }

  return baseScope.join(' ');
}

export function getIsExternalAccount(authType: AuthType): boolean {
  return authType === AuthType.FederatedExternal || authType === AuthType.PrivateExternal;
}

export function getCachedAuthenticationResult(): string | null {
  return localStorage.getItem(`${AUTHENTICATION_CACHE_STORAGE_PREFIX}.authentication-result`);
}

export function setCachedAuthenticationResult(authenticationResult: string): void {
  localStorage.setItem(`${AUTHENTICATION_CACHE_STORAGE_PREFIX}.authentication-result`, authenticationResult);
}

export function createAuthenticationUrl(context: app.Context, isMobileDevice: boolean, graphScope: string): string {
  const baseUrl = `${window.location.origin}/auth-popup.html`;
  const clientId = encodeURIComponent(environment.azureActiveDirectory.clientId);
  const scopes = encodeURIComponent(JSON.stringify([BACKEND_SCOPE, graphScope]));
  const loginHint = encodeURIComponent(context.user!.loginHint!);
  const tenantId = encodeURIComponent(context.channel?.ownerTenantId || context.user!.tenant!.id);

  return `${baseUrl}?clientId=${clientId}&scopes=${scopes}&loginHint=${loginHint}&tenantId=${tenantId}&isMobileDevice=${isMobileDevice}`;
}
