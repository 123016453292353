import { FormControl } from '@angular/forms';

import { WorkLocation } from '../../models/services/user.model';

export function handleWorkLocationClick(
  workLocation: WorkLocation,
  workLocationControl: FormControl<WorkLocation | null>,
  individualWorkLocationControl: FormControl<string | null>,
): void {
  const workLocationHasChanged = workLocationControl.value !== workLocation;
  if (!workLocationHasChanged) {
    workLocationControl.setValue(null);
    return;
  }

  const individualWorkLocation = individualWorkLocationControl.value;
  const individualWorkLocationIsValid = individualWorkLocation && individualWorkLocation.length > 0;
  if (workLocation === WorkLocation.Other && !individualWorkLocationIsValid) {
    workLocationControl.setValue(null);
    return;
  }

  workLocationControl.setValue(workLocation);
}

export function handleIndividualWorkLocationInput(
  workLocationControl: FormControl<WorkLocation | null>,
  individualWorkLocationControl: FormControl<string | null>,
): void {
  const individualWorkLocation = individualWorkLocationControl.value;
  if (!individualWorkLocation || individualWorkLocation.length === 0) {
    workLocationControl.setValue(null);
    return;
  }

  workLocationControl.setValue(WorkLocation.Other);
}
