import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { catchError, concatMap, map, Observable, of, throwError } from 'rxjs';
import { Subscription } from '@microsoft/microsoft-graph-types';
import { addMilliseconds } from 'date-fns';

import { ApplicationInsightsService } from './application-insights.service';
import { AuthService } from './auth.service';
import { CacheService } from './cache.service';

import { BatchResponse } from '../models/misc.model';
import { Chat } from '../models/services/chat/chat.model';
import { CHAT_WEBHOOK_EXPIRATION_MS, CHAT_WEBHOOK_REFRESH_MS } from '../models/services/webhook.model';

import {
  getChatMessageNotificationLifecycleUrl,
  getChatMessageNotificationUrl,
  getChatsForWebhookRequest,
  getFailedRefreshResponses,
  getFailedResponses,
  getSubscription,
  getSuccessResponses,
  getWebhookBatchPayloads,
  getWebhookCreateBatchPayload,
  getWebhookRefreshDueInMs,
} from '../utils/services/webhook.util';

import { GRAPH_API_BASE } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class WebhookService {
  private readonly http = inject(HttpClient);
  private readonly cacheService = inject(CacheService);
  private readonly authService = inject(AuthService);
  private readonly applicationInsightsService = inject(ApplicationInsightsService);

  private readonly chatMessageNotificationUrl = getChatMessageNotificationUrl(this.authService);
  private readonly chatMessageNotificationLifecycleUrl = getChatMessageNotificationLifecycleUrl(this.authService);

  public createOrRefreshChatMessageWebhookBatch(chats: Chat[]): Observable<BatchResponse[] | null> {
    chats = getChatsForWebhookRequest(chats);

    const requestPayloads = getWebhookBatchPayloads(
      chats,
      this.chatMessageNotificationUrl,
      this.chatMessageNotificationLifecycleUrl,
    );
    if (requestPayloads.length === 0) return of(null);

    return this.http
      .post<{ responses: BatchResponse[] }>(`${GRAPH_API_BASE}/$batch`, { requests: requestPayloads })
      .pipe(
        concatMap(({ responses: allResponses }) => {
          const failedRefreshResponses = getFailedRefreshResponses(chats, allResponses);
          if (failedRefreshResponses.length === 0) return of(allResponses);

          // Create webhooks for chats that failed to refresh
          const createWebhookChatIds = failedRefreshResponses.map((failedRefreshResponse) => failedRefreshResponse.id);
          return this.createChatMessageWebhookBatch(createWebhookChatIds).pipe(
            map((responses) => [...allResponses, ...responses]),
          );
        }),
        concatMap((responses) => {
          const failedResponses = getFailedResponses(responses);
          for (const failedResponse of failedResponses) {
            this.applicationInsightsService.logCustomException(new Error(JSON.stringify(failedResponse.body)));
          }

          const successResponses = getSuccessResponses(responses);
          const successChatIds = successResponses.map((successResponse) => successResponse.id);

          return this.cacheService
            .updateChatsFn(successChatIds, (chat) => ({ subscription: getSubscription(chat.id, successResponses) }))
            .pipe(map(() => responses));
        }),
      );
  }

  public createOrRefreshChatMessageWebhook(chat: Chat): Observable<Subscription | null> {
    const webhookRefreshDueInMs = getWebhookRefreshDueInMs(chat);
    const webhookExists = webhookRefreshDueInMs > 0;

    // Only refresh the subscription if soon expiring or not yet created
    if (webhookRefreshDueInMs > CHAT_WEBHOOK_REFRESH_MS) return of(null);

    const webhookRequest = webhookExists
      ? this.refreshChatMessageWebhook(chat)
      : this.createChatMessageWebhook(chat.id);

    return webhookRequest.pipe(
      concatMap((subscription) =>
        this.cacheService
          .updateChatsFn([chat.id!], () => ({
            subscription: { id: subscription.id!, expiration: new Date(subscription.expirationDateTime!) },
          }))
          .pipe(map(() => subscription)),
      ),
    );
  }

  private createChatMessageWebhookBatch(chatIds: string[]): Observable<BatchResponse[]> {
    const requestPayloads = chatIds.map((chatId) =>
      getWebhookCreateBatchPayload(chatId, this.chatMessageNotificationUrl, this.chatMessageNotificationLifecycleUrl),
    );

    return this.http
      .post<{ responses: BatchResponse[] }>(`${GRAPH_API_BASE}/$batch`, { requests: requestPayloads })
      .pipe(map(({ responses }) => responses));
  }

  // `notifiedUserObjectId` will be notified via SignalR by the backend when an update arrives
  private createChatMessageWebhook(chatId: string): Observable<Subscription> {
    return this.http.post<Subscription>(`${GRAPH_API_BASE}/subscriptions`, {
      changeType: 'created,updated,deleted',
      notificationUrl: this.chatMessageNotificationUrl,
      lifecycleNotificationUrl: this.chatMessageNotificationLifecycleUrl,
      resource: `/chats/${chatId}/messages`,
      expirationDateTime: addMilliseconds(new Date(), CHAT_WEBHOOK_EXPIRATION_MS).toISOString(),
    });
  }

  private refreshChatMessageWebhook(chat: Chat): Observable<Subscription> {
    return this.http
      .patch<Subscription>(`${GRAPH_API_BASE}/subscriptions/${chat.subscription!.id}`, {
        expirationDateTime: addMilliseconds(new Date(), CHAT_WEBHOOK_EXPIRATION_MS).toISOString(),
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.NotFound) return this.createChatMessageWebhook(chat.id);

          return throwError(() => new Error(err.message));
        }),
      );
  }
}
