import { TooltipDirectionList } from '../tooltip.model';

export interface Emoji {
  glyph: string;
  group: string;
  keywords: string[];
  dirName: string;
  styles?: Styles;
  skintones?: Skintones;
  sortOrder: number;
}

export enum Group {
  Recent = 'Recent',
  Activities = 'Activities',
  AnimalsNature = 'Animals & Nature',
  Flags = 'Flags',
  FoodDrink = 'Food & Drink',
  Objects = 'Objects',
  PeopleBody = 'People & Body',
  SmileysEmotion = 'Smileys & Emotion',
  Symbols = 'Symbols',
  TravelPlaces = 'Travel & Places',
}

export interface Skintones {
  Dark?: Styles;
  Default?: Styles;
  Light?: Styles;
  Medium?: Styles;
  MediumDark?: Styles;
  MediumLight?: Styles;
}

export interface Styles {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '3D'?: string;
  Animated?: string;
}
export interface EmojiGroup {
  category: string;
  emojis: Emoji[];
}

export type EmojiStyle = '3D' | 'Animated';
export type EmojiSkintone = 'Dark' | 'Default' | 'Light' | 'Medium' | 'MediumDark' | 'MediumLight';

export const PICKER_POSITIONS = new TooltipDirectionList(['right', 'left', 'bottom', 'top']);

export const UNSUPPORTED_CHAT_EMOJIS = [
  '🏴',
  '🐱',
  '🏁',
  '🎌',
  '✉️',
  '😳',
  '😸',
  '😀',
  '⏳',
  '🫵',
  '🏩',
  '👯‍♂️',
  '🤼‍♂️',
  '🖕',
  '😣',
  '🧙',
  '🚵',
  '🤾',
  '🤽',
  '👯',
  '🤼',
  '🏴‍☠️',
  '🏳️‍🌈',
  '🧷',
  '🦭',
  '🏳️‍⚧️',
  '🚩',
  '🏳️',
  '👩‍🍼',
  '👯‍♀️',
  '🤼‍♀️',
  '🥴',
  '😟',
];

export const MAX_RECENT_EMOJIS = 21;

export const CATEGORY_ORDER = [
  'Recent',
  'Smileys & Emotion',
  'People & Body',
  'Animals & Nature',
  'Food & Drink',
  'Travel & Places',
  'Objects',
  'Activities',
  'Symbols',
  'Flags',
];

export const BE_RIGHT_BACK_EMOJI = '🍴';
