import { Component, HostListener, Inject, OnDestroy, OnInit, Type } from '@angular/core';
import { AnimationEvent } from '@angular/animations';

import { BehaviorSubject, filter, interval, NEVER, Subject, switchMap, take, takeUntil, tap } from 'rxjs';

import {
  AUTOCLOSE_DEFAULT_TIME_MS,
  MILLISECONDS_PER_SECOND,
  ToastAnimationState,
  ToastData,
  ToastRef,
} from '../../models/services/toast.model';

import { slideInOutTriggered } from '../../utils/animation.util';
import { TOAST_DATA, TOAST_REF } from '../../utils/tokens.util';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [slideInOutTriggered],
})
export class ToastComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject<void>();
  private hover: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public animationState: ToastAnimationState = 'default';

  public text?: string;
  public component?: Type<unknown>;

  @HostListener('pointerenter')
  public pointerEnter(): void {
    this.hover.next(true);
  }

  @HostListener('pointerleave')
  public pointerLeave(): void {
    this.hover.next(false);
  }

  public constructor(
    @Inject(TOAST_DATA) public readonly data: ToastData<unknown>,
    @Inject(TOAST_REF) public readonly toastRef: ToastRef,
  ) {}

  public ngOnInit(): void {
    if (typeof this.data.content === 'string') this.text = this.data.content;
    else this.component = this.data.content;

    if (this.data.options?.autoCloseEnabled === false) return;

    const autoCloseTimeMs = this.data.options?.autoCloseTimeMs ?? AUTOCLOSE_DEFAULT_TIME_MS;

    // Used for hovering over a toast to pause the timer and resume on hover out
    // It is not possible to pause a timer in RxJS, so we need to keep track of the elapsed time in an interval
    // and subtract it from the total time to get the remaining time.
    // We also need to subtract 1 second because the interval starts at 0 and the first emmision is after 1 second.
    // There is still a time discrepancy of roughly 1 second, but it is not noticeable.
    // In the hover chain, we check if it is hovered and return NEVER, to pause the timer.
    // If it is not hovered, we resume the timer with the remaining time.
    let elapsedSeconds = 0;
    const resumableTimer = (timeInSeconds: number) =>
      interval(MILLISECONDS_PER_SECOND).pipe(
        tap((secondsElapsed) => (elapsedSeconds = secondsElapsed)),
        filter((value) => value === timeInSeconds),
        take(1),
      );

    this.hover
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap((hover) =>
          hover ? NEVER : resumableTimer(autoCloseTimeMs / MILLISECONDS_PER_SECOND - elapsedSeconds - 1),
        ),
      )
      .subscribe(() => (this.animationState = 'hidden'));
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.hover.complete();
  }

  public close(): void {
    this.toastRef.close();
  }

  public onFadeFinished(event: AnimationEvent): void {
    const willBeHidden = (event.toState as ToastAnimationState) === 'hidden';
    const isHidden = this.animationState === 'hidden';

    if (willBeHidden && isHidden) this.close();
  }
}
