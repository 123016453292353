<ng-container *ngIf="isAnonymized">
  <div *transloco="let t; read: 'avatar.hover'" class="container">
    <div class="pin"></div>

    <div class="name">{{ t("anonymizedParticipant") }}</div>
    <div class="margin-above-badges"></div>
  </div>
</ng-container>

<ng-container *ngIf="!isAnonymized">
  <div
    *transloco="let t; read: 'avatar.hover'"
    [ngClass]="{ container: true, 'is-empty': isEmptyHover }"
    (pointerenter)="onPointerEnter()"
    (pointerleave)="onPointerLeave()"
  >
    <div class="name-and-emoji-container">
      <div
        class="left-side"
        [@.disabled]="nameAnimationDisabled || showAutoCheckInHover"
        @enterGrowVertical
        [ngClass]="{ 'full-width': !displayName && !showAutoCheckInHover, 'auto-check-in': showAutoCheckInHover }"
      >
        <div
          *ngIf="!displayName && !showAutoCheckInHover"
          class="skeleton"
          [@.disabled]="nameAnimationDisabled || showAutoCheckInHover"
          @leaveShrinkVertical
        ></div>

        <span
          *ngIf="displayName || showAutoCheckInHover"
          class="name"
          [@.disabled]="nameAnimationDisabled || showAutoCheckInHover"
          @enterGrowVertical
          >{{
            showAutoCheckInHover
              ? t("automaticallyCheckedIn")
              : firstName && lastName
                ? firstName + " " + lastName
                : displayName
          }}</span
        >

        <span
          *ngIf="enableEmojiChange && isMe && (displayName || showAutoCheckInHover)"
          class="mood"
          [@.disabled]="nameAnimationDisabled || showAutoCheckInHover"
          @enterGrowVertical
        >
          {{ t("mood") }}
        </span>
      </div>

      <div
        *ngIf="enableEmojiChange && isMe && (displayName || showAutoCheckInHover)"
        class="emoji"
        [@.disabled]="nameAnimationDisabled"
        @enterGrowVertical
      >
        <div class="background" [@.disabled]="nameAnimationDisabled || showAutoCheckInHover" @enterGrowVertical></div>

        <img
          src="assets/img/icons/arrows/doodle.svg"
          [@.disabled]="nameAnimationDisabled || showAutoCheckInHover"
          @enterGrowVertical
        />

        <app-emoji-picker
          #emojiPicker
          [@.disabled]="nameAnimationDisabled || showAutoCheckInHover"
          @enterGrowVertical
          [animateEmoji]="true"
          [selectedEmoji]="selectedEmoji"
        />
      </div>
    </div>

    <div
      *ngIf="showJobTitle && !isMe"
      class="job-title"
      [ngClass]="{
        'is-empty': jobTitle === null && jobTitleAnimationDisabled,
        'is-empty-animation': jobTitle === null && !jobTitleAnimationDisabled
      }"
    >
      <div
        *ngIf="jobTitle === undefined && !isMe"
        class="skeleton"
        [@.disabled]="jobTitleAnimationDisabled"
        @leaveShrinkVertical
      ></div>
      <span *ngIf="jobTitle" [@.disabled]="jobTitleAnimationDisabled" @enterGrowVertical>{{ jobTitle }}</span>
    </div>

    @if (enablePresenceChange) {
      <hr *ngIf="isMe" class="division" />

      <div *ngIf="isMe" class="presences">
        <img src="assets/img/icons/presence.svg" />

        <div
          #dropdown
          class="dropdown"
          [ngClass]="{ 'is-dropdown-open': isDropdownOpen }"
          (mouseleave)="isDropdownOpen = false"
          (pointerenter)="onDropdownHover()"
        >
          <div class="current-presence">
            <app-presence-icon
              *ngIf="availability && !presenceUpdateInProgress"
              [backgroundColor]="availability | getAvailabilityColor"
              [kind]="availability"
            ></app-presence-icon>

            <span *ngIf="availability && !presenceUpdateInProgress">{{
              t(availability | getAvailabilityTranslationKey: { longForm: false })
            }}</span>

            <app-spinner
              *ngIf="!availability || presenceUpdateInProgress"
              [size]="'scale-by-office(1, $min-value: 10px, $max-value: 14px)'"
            ></app-spinner>
          </div>

          <div class="dropdown-icon">
            <img class="chevron-down" src="assets/img/icons/chevron-purple.svg" />
          </div>

          <div *ngIf="isDropdownOpen" class="dropdown-content">
            <div
              *ngFor="let availability of dropdownAvailabilities"
              class="item"
              (click)="setPreferredAvailability(availability)"
            >
              <app-presence-icon
                [backgroundColor]="availability | getAvailabilityColor"
                [kind]="availability"
              ></app-presence-icon>
              <span>{{ t(availability | getAvailabilityTranslationKey: { longForm: false }) }}</span>
            </div>

            <div class="item" (click)="clearPreferredPresence()">
              <app-reset-icon></app-reset-icon>
              <span>{{ t("resetPresence") }}</span>
            </div>
          </div>
        </div>

        <div
          class="help"
          [appTooltip]="availabilityHelp"
          [appTooltipMaxWidth]="'scale-by-office(32, $min-value: 320px, $max-value: 448px)'"
          [appTooltipPadding]="'scale-by-office(2, $min-value: 20px, $max-value: 28px)'"
          [appTooltipZIndex]="'high'"
        >
          i
        </div>

        <ng-template #availabilityHelp>
          <div class="help-tooltip">{{ t("availabilityHelp") }}</div>
        </ng-template>
      </div>
    }

    <div *ngIf="!isMe && showWorkLocation && workLocation !== null" class="work-location">
      <img src="assets/img/icons/location.svg" />

      <span *ngIf="workLocation === WorkLocation.Office || workLocation === WorkLocation.Home">
        {{ t(workLocation === WorkLocation.Office ? "office" : "home") }}
      </span>
      <span *ngIf="workLocation === WorkLocation.Other && individualWorkLocation">{{ individualWorkLocation }}</span>
    </div>

    <div *ngIf="isMe && showWorkLocation" class="work-location is-me">
      <img src="assets/img/icons/location.svg" />

      <label>
        <input
          class="radio-button"
          name="workLocation"
          type="radio"
          [formControl]="workLocationFormControl"
          [value]="WorkLocation.Office"
          (click)="
            handleWorkLocationClick(WorkLocation.Office, workLocationFormControl, individualWorkLocationFormControl)
          "
        />
        {{ t("office") }}
      </label>
      <label>
        <input
          class="radio-button"
          name="workLocation"
          type="radio"
          [formControl]="workLocationFormControl"
          [value]="WorkLocation.Home"
          (click)="
            handleWorkLocationClick(WorkLocation.Home, workLocationFormControl, individualWorkLocationFormControl)
          "
        />
        {{ t("home") }}
      </label>

      <label class="other">
        <input
          class="radio-button"
          name="workLocation"
          type="radio"
          [formControl]="workLocationFormControl"
          [value]="WorkLocation.Other"
          (click)="
            handleWorkLocationClick(WorkLocation.Other, workLocationFormControl, individualWorkLocationFormControl)
          "
        />

        <input
          maxlength="20"
          name="individualLocation"
          type="text"
          [formControl]="individualWorkLocationFormControl"
          [placeholder]="t('other')"
          (focus)="workLocationInputFocused(workLocationFormControl, individualWorkLocationFormControl)"
          (input)="handleIndividualWorkLocationInput(workLocationFormControl, individualWorkLocationFormControl)"
        />
      </label>
    </div>

    <div
      *ngIf="showStatusMessage && !isMe && !authService.isExternalAccount"
      class="status"
      [ngClass]="{
        'is-empty': statusMessage === null && statusAnimationDisabled,
        'is-empty-animation': statusMessage === null && !statusAnimationDisabled
      }"
    >
      <div
        *ngIf="statusMessage === undefined"
        class="skeleton"
        [@.disabled]="statusAnimationDisabled"
        @leaveShrinkVertical
      ></div>

      <div class="content">
        <app-status-badge
          *ngIf="statusMessage && (hasStatusBeenRead || hasStatusBeenRead === false)"
          [@.disabled]="statusAnimationDisabled"
          @enterGrowVertical
          [unread]="hasStatusBeenRead === false"
        ></app-status-badge>

        <span
          *ngIf="statusMessage"
          [@.disabled]="statusAnimationDisabled"
          @enterGrowVertical
          [innerHTML]="statusMessage"
        ></span>
      </div>
    </div>

    <div *ngIf="showStatusMessage && isMe && !authService.isExternalAccount" class="status">
      <div class="content">
        <app-status-badge [unread]="false"></app-status-badge>

        <textarea
          *ngIf="isMe"
          class="input"
          maxlength="280"
          name="status"
          [formControl]="statusFormControl"
          [placeholder]="t('statusPlaceholder')"
        ></textarea>
      </div>
    </div>

    <hr *ngIf="showSchedule && schedule !== null" />

    <div *ngIf="showSchedule && schedule !== null" class="schedule">
      <div *ngIf="schedule === undefined" class="skeleton"></div>

      <div *ngIf="schedule" class="timeline" (pointerleave)="scheduleHoveredBlockIndex = undefined">
        <div class="now"></div>

        <div
          *ngFor="let scheduleBlock of schedule; let i = index; first as first; last as last"
          class="block"
          [appTooltip]="planMeetingTooltip"
          [appTooltipContainerOffsetCrossAxisCenter]="'scale-by-office(0.254, $min-value: 2.54px, $max-value: 3.556px)'"
          [appTooltipDisabled]="!scheduleBlock.isFullHour && !scheduleBlock.isHalfHour"
          [appTooltipOpen]="
            ((last && !scheduleBlock.isFullHour && !scheduleBlock.isHalfHour) || !last) &&
            enableTimelinePlanning &&
            ((scheduleHoveredBlockIndex === i && (scheduleBlock.isFullHour || scheduleBlock.isHalfHour)) ||
              (scheduleHoveredBlockIndex === i + 1 &&
                schedule[i + 1] &&
                !schedule[i + 1].isFullHour &&
                !schedule[i + 1].isHalfHour))
          "
          [appTooltipOpenDelayMs]="175"
          [appTooltipZIndex]="'high'"
          [ngClass]="{
            first: first,
            last: last,
            'is-active':
              ((first && (scheduleBlock.isFullHour || scheduleBlock.isHalfHour)) || !first) &&
              ((last && !scheduleBlock.isFullHour && !scheduleBlock.isHalfHour) || !last) &&
              (scheduleHoveredBlockIndex === i ||
                (scheduleHoveredBlockIndex === i - 1 &&
                  schedule[i - 1] &&
                  (schedule[i - 1].isFullHour || schedule[i - 1].isHalfHour)) ||
                (scheduleHoveredBlockIndex === i + 1 &&
                  schedule[i + 1] &&
                  !schedule[i + 1].isFullHour &&
                  !schedule[i + 1].isHalfHour)),
            'allow-planning': enableTimelinePlanning,
            'only-i-am-unavailable':
              scheduleBlock.availability === ScheduleAvailability.OnlyIAmUnavailable ||
              (isMe && scheduleBlock.availability === ScheduleAvailability.BothUnavailable),
            'only-they-are-unavailable': scheduleBlock.availability === ScheduleAvailability.OnlyTheyAreUnavailable,
            'both-unavailable': scheduleBlock.availability === ScheduleAvailability.BothUnavailable,
            'both-free': scheduleBlock.availability === ScheduleAvailability.BothFree,
            'they-are-outside-working-hours':
              scheduleBlock.availability === ScheduleAvailability.theyAreOutsideWorkingHours
          }"
          (click)="planMeeting(i)"
          (pointerenter)="scheduleHoveredBlockIndex = i"
        >
          <div *ngIf="scheduleBlock.isHalfHour && !first" class="half-hour">
            <div class="top"></div>
            <div class="bottom"></div>
          </div>

          <div *ngIf="scheduleBlock.isFullHour && !first" class="full-hour">
            <div class="top"></div>
            <span class="hour">{{ scheduleBlock.startDateTimeHour }}</span>
            <div class="bottom"></div>
          </div>

          <ng-template #planMeetingTooltip>
            <div class="plan-meeting-tooltip">
              <div>{{ t("tooltips.planMeeting") }}</div>
              <div *ngIf="scheduleBlock.isFullHour || scheduleBlock.isHalfHour" [ngStyle]="{ 'font-weight': 400 }">
                {{ scheduleBlock.startDateTime | dateWithLocale: { hour: "2-digit", minute: "2-digit" } }}h -
                {{ scheduleBlock.endDateTime | dateWithLocale: { hour: "2-digit", minute: "2-digit" } }}h
              </div>

              <div
                *ngIf="!scheduleBlock.isFullHour && !scheduleBlock.isHalfHour && schedule[i - 1]"
                [ngStyle]="{ 'font-weight': 400 }"
              >
                {{ schedule[i - 1].startDateTime | dateWithLocale: { hour: "2-digit", minute: "2-digit" } }}h -
                {{ schedule[i - 1].endDateTime | dateWithLocale: { hour: "2-digit", minute: "2-digit" } }}h
              </div>

              <div
                *ngIf="!scheduleBlock.isFullHour && !scheduleBlock.isHalfHour && !schedule[i - 1]"
                [ngStyle]="{ 'font-weight': 400 }"
              >
                {{ schedule[i + 1].startDateTime | dateWithLocale: { hour: "2-digit", minute: "2-digit" } }}h -
                {{ schedule[i + 1].endDateTime | dateWithLocale: { hour: "2-digit", minute: "2-digit" } }}h
              </div>
            </div>
          </ng-template>
        </div>
      </div>

      <div
        *ngIf="schedule"
        class="help"
        [appTooltip]="scheduleHelp"
        [appTooltipMaxWidth]="'scale-by-office(30, $min-value: 300px, $max-value:420px)'"
        [appTooltipPadding]="'scale-by-office(2, $min-value: 20px, $max-value: 28px)'"
        [appTooltipZIndex]="'high'"
      >
        ?
      </div>

      <ng-template #scheduleHelp>
        <div *ngIf="isMe" class="help-tooltip">
          <span class="row title" [innerHTML]="t('timelineHelp.myAvailability')"></span>

          <div class="row">
            <div class="color red"></div>
            <span [innerHTML]="t('timelineHelp.iAmUnavailable')"></span>
          </div>

          <div class="row">
            <div class="color green"></div>
            <span [innerHTML]="t('timelineHelp.iAmAvailable')"></span>
          </div>

          <div class="row">
            <div class="color blue"></div>
            <div class="column">
              <span [innerHTML]="t('timelineHelp.iAmOutOfWorkingTime')"></span>
              <span [innerHTML]="t('timelineHelp.adjustableInOutlook')"></span>
            </div>
          </div>
        </div>

        <div *ngIf="!isMe" class="help-tooltip">
          <span
            class="row title"
            [innerHTML]="t('timelineHelp.theirAvailability', { displayName: firstName || displayName })"
          ></span>

          <div class="row">
            <div class="color red"></div>
            <span [innerHTML]="t('timelineHelp.theyAreUnavailable', { displayName: firstName || displayName })"></span>
          </div>

          <div class="row">
            <div class="color yellow"></div>
            <span [innerHTML]="t('timelineHelp.onlyIAmUnavailable', { displayName: firstName || displayName })"></span>
          </div>

          <div class="row">
            <div class="color green"></div>
            <span [innerHTML]="t('timelineHelp.bothAvailable')"></span>
          </div>

          <div class="row">
            <div class="color blue"></div>
            <span
              [innerHTML]="t('timelineHelp.theyAreOutsideWorkingHours', { displayName: firstName || displayName })"
            ></span>
          </div>
        </div>
      </ng-template>
    </div>

    <hr *ngIf="showCallButton || showInviteButton || showCalendarButton || showChatButton || showSendChatMessage" />

    <div *ngIf="showCallButton || showInviteButton || showCalendarButton || showChatButton" class="buttons">
      <div *ngIf="showCallButton && !userPrincipalName && !userEmailAddress" class="skeleton"></div>
      <app-button
        *ngIf="showCallButton && (userPrincipalName || userEmailAddress)"
        class="min-max-css-values"
        [appTooltip]="callTooltip"
        [appTooltipOpenDelayMs]="125"
        [appTooltipZIndex]="'high'"
        [disabled]="callButtonDisabled"
        (buttonClick)="call()"
      >
        <app-spinner
          *ngIf="callButtonDisabled"
          [color]="'255, 255, 255'"
          [size]="'scale-by-office(1, $min-value: 10px, $max-value: 14px)'"
        ></app-spinner>
        <img *ngIf="!callButtonDisabled" src="assets/img/icons/phone.svg" />
      </app-button>

      <ng-template #callTooltip>
        <div class="help-tooltip">{{ t("buttons.call", { displayName: firstName || displayName }) }}</div>
      </ng-template>

      <div *ngIf="showInviteButton && !userPrincipalName && !userEmailAddress" class="skeleton"></div>
      <app-button
        *ngIf="showInviteButton && (userPrincipalName || userEmailAddress)"
        class="min-max-css-values"
        [appTooltip]="inviteTooltip"
        [appTooltipOpenDelayMs]="125"
        [appTooltipZIndex]="'high'"
        [disabled]="inviteButtonDisabled"
        (buttonClick)="invite()"
      >
        <app-spinner
          *ngIf="inviteButtonDisabled"
          [color]="'255, 255, 255'"
          [size]="'scale-by-office(1, $min-value: 10px, $max-value: 14px)'"
        ></app-spinner>
        <img *ngIf="!inviteButtonDisabled" src="assets/img/icons/phone-add.svg" />
      </app-button>

      <ng-template #inviteTooltip>
        <div class="help-tooltip">{{ t("buttons.inviteToCall", { displayName: firstName || displayName }) }}</div>
      </ng-template>

      <div *ngIf="showCalendarButton && !userPrincipalName && !userEmailAddress" class="skeleton"></div>
      <app-button
        *ngIf="showCalendarButton && (userPrincipalName || userEmailAddress)"
        class="min-max-css-values"
        [appTooltip]="planMeetingTooltip"
        [appTooltipOpenDelayMs]="125"
        [appTooltipZIndex]="'high'"
        (buttonClick)="planMeeting()"
      >
        <app-agenda-icon fill="white"></app-agenda-icon>
      </app-button>

      <ng-template #planMeetingTooltip>
        <div class="help-tooltip">{{ t("buttons.planMeeting", { displayName: firstName || displayName }) }}</div>
      </ng-template>

      <div *ngIf="showChatButton && !userPrincipalName && !userEmailAddress" class="skeleton"></div>
      <app-button
        *ngIf="showChatButton && (userPrincipalName || userEmailAddress)"
        class="min-max-css-values"
        [appTooltip]="chatTooltip"
        [appTooltipOpenDelayMs]="125"
        [appTooltipZIndex]="'high'"
        (buttonClick)="chat(this.chatMessage.value ?? undefined)"
      >
        <app-notification-badge
          *ngIf="unreadChatMessageCount > 0"
          [count]="unreadChatMessageCount"
        ></app-notification-badge>

        <img src="assets/img/icons/message.svg" />
      </app-button>

      <ng-template #chatTooltip>
        <div class="help-tooltip">{{ t("buttons.message", { displayName: firstName || displayName }) }}</div>
      </ng-template>
    </div>

    <div *ngIf="showSendChatMessage" class="send-chat-message">
      <div *ngIf="!userPrincipalName && !userEmailAddress" class="skeleton input"></div>
      <div *ngIf="!userPrincipalName && !userEmailAddress" class="skeleton button"></div>

      <input
        #chatMessageInput
        *ngIf="userPrincipalName || userEmailAddress"
        maxlength="256"
        type="text"
        [formControl]="chatMessage"
        [placeholder]="t('sendChatMessage')"
        (keyup.enter)="sendChatMessage()"
      />

      <app-send-button
        *ngIf="showChatButton && (userPrincipalName || userEmailAddress)"
        [disabled]="chatMessage.invalid || chatMessage.disabled"
        [(triggerAnimationFailure)]="sendChatAnimationFailureTrigger"
        [(triggerAnimationSuccess)]="sendChatAnimationSuccessTrigger"
        (buttonClick)="sendChatMessage()"
      ></app-send-button>
    </div>

    <div class="margin-above-badges"></div>

    <div
      *ngIf="!isMe && enableAvailabilityBadge && availability && !showInOtherRoomBadge"
      class="status-badge"
      [ngClass]="{
        'has-out-of-office-message': enableOutOfOfficeStatus && outOfOfficeSettings?.message
      }"
      [ngStyle]="{
        'background-color':
          availability | getAvailabilityColor: (enableOutOfOfficeStatus ? outOfOfficeSettings : undefined)
      }"
    >
      <div class="hover-badge">
        <app-presence-icon
          *ngIf="
            availability === Availability.InACall ||
            availability === Availability.DoNotDisturb ||
            availability === Availability.BeRightBack ||
            (enableOutOfOfficeStatus && outOfOfficeSettings?.isOutOfOffice)
          "
          class="badge-icon"
          [iconWithinCircle]="false"
          [kind]="enableOutOfOfficeStatus && outOfOfficeSettings?.isOutOfOffice ? 'outOfOffice' : availability"
        ></app-presence-icon>

        <div class="badge-text">
          {{
            t(
              availability
                | getAvailabilityTranslationKey
                  : { outOfOfficeSettings: enableOutOfOfficeStatus ? outOfOfficeSettings : undefined, longForm: true }
            )
          }}
        </div>
      </div>

      <span
        *ngIf="enableAvailabilityBadge && enableOutOfOfficeStatus && outOfOfficeSettings?.message"
        class="out-of-office-message"
        [innerHTML]="outOfOfficeSettings!.message"
      ></span>
    </div>

    <div *ngIf="showInOtherRoomBadge" class="hover-badge otr">
      <app-otr-icon class="badge-icon" fill="white" />

      <div
        class="badge-text"
        [appTooltip]="t('otherRoomHint')"
        [appTooltipBackgroundColor]="'var(--teams-magenta)'"
        [appTooltipColor]="'white'"
        [appTooltipDisabled]="!otherRoomInfo"
        [appTooltipZIndex]="'high'"
        [ngClass]="{ underline: otherRoomInfo }"
        (click)="otherRoomInfo && selectTeam(otherRoomInfo.objectId)"
      >
        <span *ngIf="!otherRoomInfo">{{ t("otherRoom") }}</span>
        <span *ngIf="otherRoomInfo" class="other-room-name">{{
          t("otherRoomWithName", { displayName: otherRoomInfo.displayName })
        }}</span>
      </div>
    </div>

    <div *ngIf="showLicenseInvalidBadge" class="hover-badge license-invalid">
      <div class="badge-text">{{ t("licenseInvalid") }}</div>
    </div>
  </div>
</ng-container>
