import { inject, Injectable } from '@angular/core';

import {
  combineLatest,
  distinctUntilChanged,
  map,
  merge,
  of,
  scan,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  take,
} from 'rxjs';

import { AuthService } from './auth.service';
import { CalendarService } from './calendar.service';
import { PreloadDataService } from './preload-data.service';
import { TeamService } from './team.service';
import { UserService } from './user.service';

import { DEFAULT_LAST_STEP, DEFAULT_STEPS, OFFICE_TOUR_STEPS, Tour } from '../models/tour.model';

import { filterNullish } from '../utils/rxjs.util';
import { isRelevantEvent } from '../utils/services/calendar.util';
import { concatActiveCalls } from '../utils/services/call.util';

interface Action {
  id: 'next' | 'previous';
  stepIdentifier: string;
}

interface SkippedStep {
  identifier: string;
  skip: boolean;
}

interface TourState {
  tour: Tour;
  completed: boolean;
}

@Injectable({ providedIn: 'root' })
export class TourService {
  private readonly calendarService = inject(CalendarService);
  private readonly preloadDataService = inject(PreloadDataService);
  private readonly userService = inject(UserService);
  private readonly teamService = inject(TeamService);
  private readonly authService = inject(AuthService);

  private readonly previousStepSubject = new Subject<string>();
  private readonly nextStepSubject = new Subject<string>();

  private readonly userInfo$ = this.userService.userInfo$.pipe(take(1), shareReplay(1));

  private readonly isExternalUser$ = this.userInfo$.pipe(
    filterNullish(),
    map((userInfo) => userInfo.user.isExternalAccount),
    shareReplay(1),
  );

  private readonly isVisitor$ = this.userInfo$.pipe(
    filterNullish(),
    map((userInfo) => userInfo.user.visitingTeamObjectId !== null),
    shareReplay(1),
  );

  private readonly tourSteps$ = this.userInfo$.pipe(
    map((userInfo) => {
      if (userInfo === undefined) {
        return DEFAULT_STEPS.map((step) => ({ ...step, isCompleted: true }));
      }

      const steps = userInfo?.tourSteps ?? DEFAULT_STEPS;
      let incompleteSteps = [...steps];

      if (steps.find((step) => step.identifier === DEFAULT_LAST_STEP)?.isCompleted) {
        incompleteSteps = steps.filter((step) => !step.isCompleted);
      }

      if (incompleteSteps.length > 0) {
        incompleteSteps[0].isFirstStep = true;
        incompleteSteps[incompleteSteps.length - 1].isLastStep = true;
      }

      steps.forEach((step) => {
        const incompleteStep = incompleteSteps.find((incompleteStep) => incompleteStep.identifier === step.identifier);
        if (incompleteStep) {
          step.isCompleted = incompleteStep.isCompleted;
          step.isFirstStep = incompleteStep.isFirstStep;
          step.isLastStep = incompleteStep.isLastStep;
        }
      });

      return steps;
    }),
  );

  private readonly events$ = this.calendarService.events$.pipe(
    map((events) => events.filter((event) => isRelevantEvent(event))),
  );

  private readonly meetings$ = this.preloadDataService.officeData$.pipe(
    map((officeData) => {
      const activeCalls = { ...officeData.activeCalls };
      activeCalls.desks = null;
      activeCalls.kitchen = null;
      activeCalls.topicMeetings = [];

      return concatActiveCalls(activeCalls).length;
    }),
  );

  private readonly topicMeetings$ = this.preloadDataService.officeData$.pipe(
    map((officeData) => officeData.activeCalls.topicMeetings.length),
  );

  private readonly skipMeetingOverview$ = combineLatest([this.events$, this.meetings$]).pipe(
    map(
      ([events, meetings]) =>
        ({
          identifier: OFFICE_TOUR_STEPS.meetingOverview.identifier,
          skip: events.length === 0 && meetings === 0,
        }) satisfies SkippedStep,
    ),
  );

  private readonly skipBreakoutMeeting$ = combineLatest([this.teamService.teamInfoData$, this.isExternalUser$]).pipe(
    map(
      ([teamInfo, isExternalUser]) =>
        ({
          identifier: OFFICE_TOUR_STEPS.newBreakoutMeeting.identifier,
          skip: isExternalUser || !teamInfo?.myTeam?.settings.breakoutMeetingsEnabled,
        }) satisfies SkippedStep,
    ),
  );

  private readonly skipTopicTables$ = combineLatest([this.topicMeetings$, this.isExternalUser$, this.isVisitor$]).pipe(
    map(
      ([topicMeetings, isExternalUser, isVisitor]) =>
        ({
          identifier: OFFICE_TOUR_STEPS.topicTables.identifier,
          skip: (isExternalUser || isVisitor) && topicMeetings === 0,
        }) satisfies SkippedStep,
    ),
  );

  private readonly usersInMyTeam$ = this.teamService.teamInfoData$.pipe(
    map((teamInfo) => teamInfo?.myTeam?.users ?? []),
  );

  private readonly inactiveUsers$ = this.preloadDataService.officeData$.pipe(
    map((officeData) => officeData.usersNotInACall.inactive),
  );

  private readonly skipOTM$ = combineLatest([this.usersInMyTeam$, this.inactiveUsers$]).pipe(
    map(
      ([usersInMyTeam, inactiveUsers]) =>
        ({
          identifier: OFFICE_TOUR_STEPS.otm.identifier,
          skip: usersInMyTeam.length === 0 || inactiveUsers.length === 0,
        }) satisfies SkippedStep,
    ),
  );

  private readonly skippedSteps$ = combineLatest([
    this.skipMeetingOverview$,
    this.skipBreakoutMeeting$,
    this.skipOTM$,
    this.skipTopicTables$,
  ]).pipe(
    map((skippedSteps) => new Set(skippedSteps.filter((step) => step.skip).map((step) => step.identifier))),
    shareReplay(1),
  );

  private readonly action$ = merge(
    this.previousStepSubject.pipe(map((stepIdentifier) => ({ id: 'previous', stepIdentifier }) satisfies Action)),
    this.nextStepSubject.pipe(map((stepIdentifier) => ({ id: 'next', stepIdentifier }) satisfies Action)),
  ).pipe(shareReplay(1));

  private readonly initialTourState$ = combineLatest([this.skippedSteps$, this.tourSteps$]).pipe(
    map(
      ([skippedSteps, tourSteps]) =>
        ({
          tour: {
            identifier: 'office',
            steps: tourSteps,
          },
          completed: tourSteps.filter((step) => !skippedSteps.has(step.identifier)).every((step) => step.isCompleted),
        }) satisfies TourState,
    ),
    take(1),
  );

  private readonly tour$ = this.initialTourState$.pipe(
    switchMap((initialState) =>
      this.action$.pipe(
        switchMap((value) => combineLatest([of(value), this.skippedSteps$.pipe(take(1))])),
        scan((state, [action, skippedSteps]) => {
          const newState = { ...state };

          if (newState.completed) return newState;

          if (action.id === 'previous') {
            let index = newState.tour.steps.findIndex((step) => step.identifier === action.stepIdentifier) - 1;

            while (index >= 0 && skippedSteps.has(newState.tour.steps[index].identifier)) {
              index--;
            }

            if (index !== -1) newState.tour.steps[index].isCompleted = false;
          } else {
            newState.tour.steps.find((step) => step.identifier === action.stepIdentifier)!.isCompleted = true;
          }

          newState.completed = newState.tour.steps
            .filter((step) => !skippedSteps.has(step.identifier))
            .every((step) => step.isCompleted);

          return newState;
        }, initialState),
        switchMap((state) => this.updateDB(state)),
        startWith(initialState),
      ),
    ),
    shareReplay(1),
  );

  private readonly isTourCompleted$ = this.tour$.pipe(
    map((tour) => tour.completed),
    distinctUntilChanged(),
  );

  public readonly isActive$ = combineLatest([this.isTourCompleted$, this.authService.isMobileWidth$]).pipe(
    map(([completed, isMobile]) => !completed && !isMobile),
    distinctUntilChanged(),
    shareReplay(1),
  );

  public readonly currentStep$ = this.tour$.pipe(
    switchMap((state) => combineLatest([of(state.tour), this.skippedSteps$.pipe(take(1))])),
    map(([tour, skippedSteps]) => {
      // Filter out the steps that should not be shown
      const filteredSteps = tour.steps.filter((step) => !step.isCompleted && !skippedSteps.has(step.identifier));

      if (filteredSteps.length === 0) return null;

      return filteredSteps[0];
    }),
    shareReplay(1),
  );

  public previousStep(id: string): void {
    this.previousStepSubject.next(id);
  }

  public nextStep(identifier: string): void {
    this.nextStepSubject.next(identifier);
  }

  private updateDB(state: TourState) {
    return merge(of(state), this.userService.updateTourSteps(state.tour.steps).pipe(map(() => null))).pipe(
      filterNullish(),
    );
  }
}
