<div
  *transloco="let t; read: 'linkList'"
  class="container"
  (click)="authService.isMobileDevice ? showHover() : null"
  (pointerenter)="!authService.isMobileDevice ? showHover() : null"
>
  <app-button backgroundColor="white" class="group-name" color="var(--bnear-brand)">
    <div *ngIf="!linkList" class="skeleton"></div>

    <span *ngIf="linkList">{{ linkList.groupName | translateCollaborationToolName }}</span>

    <div #hoverOrigin="cdkOverlayOrigin" *ngIf="linkList" cdkOverlayOrigin class="ellipsis"></div>
  </app-button>
</div>

<ng-template
  *ngIf="hoverOrigin"
  cdkConnectedOverlay
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="false"
  [cdkConnectedOverlayOpen]="hovering"
  [cdkConnectedOverlayOrigin]="hoverOrigin"
  [cdkConnectedOverlayPositions]="HoverPositions"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategies.close()"
  (detach)="hovering = false"
>
  <app-link-list-hover #hover [linkList]="linkList!" (editingChanged)="editingChanged($event)"></app-link-list-hover>
</ng-template>
