import { Component, HostBinding, Inject, Input, TemplateRef } from '@angular/core';
import { AnimationEvent } from '@angular/animations';

import { AnimationState } from '../../models/animation.model';
import { TooltipRef } from '../../models/tooltip.model';

import { fadeInOutTriggered } from '../../utils/animation.util';
import { TOOLTIP_REF } from '../../utils/tokens.util';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  animations: [fadeInOutTriggered],
})
export class TooltipComponent {
  @Input() public animationState: AnimationState = 'visible';

  @Input() public text?: string;
  @Input() public template?: TemplateRef<unknown>;
  @Input() public fadeInAnimationDurationMs?: number;
  @Input() public fadeInAnimationDelayMs?: number;

  @Input() public fadeOutAnimationDurationMs?: number;
  @Input() public fadeOutAnimationDelayMs?: number;

  @Input()
  @HostBinding('class.bounce-animation')
  public bounceAnimation = false;

  @Input()
  @HostBinding('style.--tooltip-container-offset-main-axis')
  public containerOffsetMainAxis!: string;

  @Input()
  @HostBinding('style.--tooltip-container-offset-cross-axis-center')
  public containerOffsetCrossAxisCenter!: string;

  @Input()
  @HostBinding('style.--tooltip-container-offset-cross-axis-shifted')
  public containerOffsetCrossAxisShifted!: string;

  @Input()
  @HostBinding('style.--tooltip-pin-size')
  public pinSize!: string;

  @Input()
  @HostBinding('style.--tooltip-pin-offset')
  public pinOffset!: string;

  @Input()
  @HostBinding('style.--tooltip-pin-color')
  public pinColor!: string;

  @Input()
  @HostBinding('style.--tooltip-container-color')
  public color!: string;

  @Input()
  @HostBinding('style.--tooltip-container-bg-color')
  public backgroundColor!: string;

  @Input()
  @HostBinding('style.--tooltip-container-padding')
  public padding!: string;

  @Input()
  @HostBinding('style.--tooltip-container-min-width')
  public minWidth!: string;

  @Input()
  @HostBinding('style.--tooltip-container-max-width')
  public maxWidth!: string;

  @Input()
  @HostBinding('style.--tooltip-container-max-height')
  public maxHeight!: string;

  @Input()
  @HostBinding('style.--tooltip-container-border-radius')
  public borderRadius!: string;

  @Input()
  @HostBinding('style.--tooltip-container-font-size')
  public fontSize!: string;

  @Input()
  @HostBinding('style.--tooltip-container-z-index')
  public zIndex?: string;

  public constructor(@Inject(TOOLTIP_REF) public readonly tooltipRef: TooltipRef) {}

  public onFadeFinished(event: AnimationEvent): void {
    const willBeHidden = (event.toState as AnimationState) === 'hidden';
    const isHidden = this.animationState === 'hidden';

    if (willBeHidden && isHidden) {
      this.tooltipRef.close();
    }
  }
}
