import { InjectionToken } from '@angular/core';

import { TooltipDirectionList } from '../tooltip.model';

export type FauxBorderColor = 'green' | 'grey' | 'magenta' | 'purple' | 'red' | 'yellow';

export interface AvatarConfig {
  enableAvailabilityRingColor: boolean;
  enableDoNotDisturbBadge: boolean;
  enableInACallBadge: boolean;
  enablePresentingBadge: boolean;
  enableInOtherRoomBadge: boolean;
  enableOutOfOfficeBadge: boolean;
  enableVisitorBadge: boolean;
  enableCallButton: boolean;
  enableChatOpening: boolean;
  enableEmoji: boolean;
  enableEmojiHint: boolean;
  enableUnreadMessageCount: boolean;
  enableStatus: boolean;
  enableGraphCache: boolean;
  enableAutomaticCheckInHover: boolean;
  enableWorkLocation: boolean;
  enableQuickMessage: boolean;
  enableCalendarButton: boolean;
  enableJobTitle: boolean;
  enableSchedule: boolean;
  enableTimeLinePlanning: boolean;
  enablePresenceChange: boolean;
  enableEmojiChange: boolean;
  enableLicenseInvalidBadge: boolean;
  disableHover: boolean;
}

export const userStatusRefreshIntervalMs = 3 * 60 * 1_000;

export const AVATAR_HOVER_DIRECTIONS = new TooltipDirectionList(['bottom', 'top', 'right', 'left']);

export const AVATAR_CONFIG = new InjectionToken<AvatarConfig | null>('AvatarConfig', {
  providedIn: 'root',
  factory: () => null,
});
