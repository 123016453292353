<ng-container *transloco="let tCategories; read: 'emojis.categories'">
  <div
    *ngFor="let category of categories"
    class="category"
    [ngClass]="{ selected: selectedCategory === category }"
    (click)="categoryChange.emit(category)"
  >
    <img [src]="'/assets/img/icons/emoji-categories/' + category + '.svg'" [title]="tCategories(category)" />
  </div>
</ng-container>
