import { Directive, Host, Input } from '@angular/core';
import { NgForOf } from '@angular/common';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngForTrackByProperty]',
  standalone: true,
})
export class NgForTrackByPropertyDirective<T> {
  @Input({ required: true }) public ngForTrackByProperty!: keyof T;

  public constructor(@Host() ngForOf: NgForOf<T>) {
    ngForOf.ngForTrackBy = (_, item) => item[this.ngForTrackByProperty];
  }
}
