/**
 * @name codsen-utils
 * @fileoverview Various utility functions
 * @version 1.6.4
 * @author Roy Revelt, Codsen Ltd
 * @license MIT
 * {@link https://codsen.com/os/codsen-utils/}
 */

var s = "1.6.4";
import p from "rfdc";
var l = p(),
  k = s,
  a = "\u2018",
  f = "\u2019",
  d = "\u201C",
  m = "\u201D",
  S = "\u2013",
  O = "\u2014",
  C = "\xA0",
  N = "\u2026",
  A = "\u200A",
  j = "\u2009",
  E = "\u2032",
  T = "\u2033",
  J = "\\",
  R = "\uFFFD",
  $ = "`",
  L = "\xD7",
  D = [".", ",", ";", "!", "?"];
function U(t) {
  return r(t) && t.charCodeAt(0) >= 48 && t.charCodeAt(0) <= 57;
}
function x(t) {
  return r(t) && "\u060B$\u20BC\u17DB\xA5\u20A1\u20B1\xA3\u20AC\xA2\u20B9\uFDFC\u20AA\u20A9\u20AD\u20A8\u20AE\u20A6\u20BD\u20AB\u0E3F\u20A9\u20BA\u20B4".includes(t);
}
function F(t) {
  let e = new Set(["$U", "$b", "B/.", "BZ$", "Br", "Bs", "C$", "CHF", "Ft", "Gs", "J$", "KM", "K\u010D", "L", "MT", "NT$", "P", "Q", "R", "R$", "RD$", "RM", "Rp", "S", "S/.", "TT$", "Z$", "kn", "kr", "lei", "z\u0142", "\u0192", "\u0414\u0438\u043D.", "\u0434\u0435\u043D", "\u043B\u0432", "\u062F.\u0625", "Lek"]);
  return r(t) && !!t.trim() && (x(t) || e.has(t));
}
function i(t) {
  return r(t) && t.length === 1 && t.toUpperCase() !== t.toLowerCase();
}
function I(t) {
  return !!(t && r(t) && (t.charCodeAt(0) > 64 && t.charCodeAt(0) < 91 || t.charCodeAt(0) > 96 && t.charCodeAt(0) < 123));
}
function P(t) {
  return r(t) && (t === '"' || t === "'" || t === a || t === f || t === d || t === m);
}
function M(t) {
  return !r(t) || !i(t) ? !1 : t === t.toLowerCase() && t !== t.toUpperCase();
}
function Q(t) {
  return !r(t) || !i(t) ? !1 : t === t.toUpperCase() && t !== t.toLowerCase();
}
function V(t) {
  return !r(t) || !t ? !1 : !t[0].trim();
}
var B = t => r(t) && t.length && t.endsWith("/") ? t.slice(0, -1).trim() : t;
function u(t) {
  if (t == null || typeof t != "object") return !1;
  let e = Object.getPrototypeOf(t);
  return e !== null && e !== Object.prototype && Object.getPrototypeOf(e) !== null ? !1 : !(Symbol.iterator in t) && !(Symbol.toStringTag in t);
}
function K(t = "", e = 0, n = 0, o = "") {
  return e < 0 && (e += t.length, e < 0 && (e = 0)), t.slice(0, e) + (o || "") + t.slice(e + n);
}
function r(t) {
  return typeof t == "string";
}
function W(t) {
  return Number.isFinite(t);
}
function q(t) {
  return Number.isSafeInteger(t) && t >= 0;
}
function H(t) {
  return typeof t == "boolean";
}
function Z(t) {
  return t === null;
}
function b(t) {
  return t instanceof RegExp;
}
function _(t, e) {
  if (!r(t) || !r(e)) return [];
  let n = [],
    o = -1;
  for (; (o = t.indexOf(e, o + 1)) >= 0;) n.push(o);
  return n;
}
function z(t = [], e = []) {
  return !t || !Array.isArray(t) || !t.length ? [] : !e || !Array.isArray(e) || !e.length ? t : t.filter(n => !e.includes(n));
}
function G(t) {
  return t != null;
}
function X(t) {
  return [...new Set(t)];
}
function g(t) {
  if (!(typeof t != "string" || !t)) {
    if (t.includes(`\r
`)) return `\r
`;
    if (t.includes(`
`)) return `
`;
    if (t.includes("\r")) return "\r";
  }
}
function Y(t, e, n = `
`) {
  if (![`\r
`, "\r", `
`].includes(n)) throw new Error(`codsen-utils/resolveEolSetting(): the input argument defaultEolChar should be one of EOL values: "\\n", "\\r", or "\\r\\n", but it was given as ${JSON.stringify(n, null, 0)}`);
  return e === "crlf" ? `\r
` : e === "cr" ? "\r" : e === "lf" ? `
` : g(t) || n;
}
function v(t, e) {
  return u(t) && r(e) && e in t;
}
function tt(t, e) {
  return t.localeCompare(e);
}
var et = ["area", "base", "br", "col", "embed", "hr", "img", "input", "link", "meta", "param", "source", "track", "wbr"],
  nt = new Set(["a", "abbr", "acronym", "audio", "b", "bdi", "bdo", "big", "br", "button", "canvas", "cite", "code", "data", "datalist", "del", "dfn", "em", "embed", "i", "iframe", "img", "input", "ins", "kbd", "label", "map", "mark", "meter", "noscript", "object", "output", "picture", "progress", "q", "ruby", "s", "samp", "script", "select", "slot", "small", "span", "strong", "sub", "sup", "svg", "template", "textarea", "time", "u", "tt", "var", "video", "wbr"]);
function rt(t, e) {
  return !Array.isArray(t) || !t.length ? !1 : t.some(n => b(n) && e.match(n) || typeof n == "string" && e === n);
}
function ot(t = [], e = []) {
  return !t || !e ? [] : Array.from(new Set(Array.from(t).filter(n => new Set(e).has(n))));
}
function st(t, e = []) {
  if (!t) return t;
  if (!u(t)) throw new Error(`codsen-utils/omit(): [THROW_ID_01] Input must be a plain object! It was given as ${JSON.stringify(t, null, 4)} (typeof is "${typeof t}")`);
  let n = l(t);
  return e.forEach(o => {
    delete n[o];
  }), n;
}
export { J as backslash, $ as backtick, tt as compareFn, g as detectEol, T as doublePrime, N as ellipsis, G as existy, _ as findAllIdx, A as hairspace, v as hasOwnProp, rt as includes, nt as inlineTags, ot as intersection, H as isBool, x as isCurrencyChar, F as isCurrencySymbol, q as isInt, I as isLatinLetter, i as isLetter, M as isLowercaseLetter, Z as isNull, W as isNum, U as isNumberChar, u as isPlainObject, P as isQuote, b as isRegExp, r as isStr, Q as isUppercaseLetter, V as isWhitespaceChar, d as leftDoubleQuote, a as leftSingleQuote, L as multiplicationSign, st as omit, z as pullAll, D as punctuationChars, O as rawMDash, S as rawNDash, C as rawNbsp, R as rawReplacementMark, B as removeTrailingSlash, Y as resolveEolSetting, m as rightDoubleQuote, f as rightSingleQuote, E as singlePrime, K as stringSplice, j as thinSpace, X as uniq, k as version, et as voidTags };