import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

import { SafeType } from '../models/pipes/safe.model';

@Pipe({ name: 'safe', pure: true, standalone: true })
export class SafePipe implements PipeTransform {
  public constructor(private readonly domSanitizer: DomSanitizer) {}

  public transform(url: string, safeType: SafeType): SafeHtml | SafeResourceUrl | SafeScript | SafeStyle | SafeUrl {
    if (safeType === 'html') return this.domSanitizer.bypassSecurityTrustHtml(url);
    if (safeType === 'resourceUrl') return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    if (safeType === 'script') return this.domSanitizer.bypassSecurityTrustScript(url);
    if (safeType === 'style') return this.domSanitizer.bypassSecurityTrustStyle(url);

    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
}
