import { Component, Inject } from '@angular/core';

import { ToastData, ToastRef } from 'src/app/shared/models/services/toast.model';

import { TOAST_DATA, TOAST_REF } from 'src/app/shared/utils/tokens.util';

@Component({
  selector: 'app-team-switched',
  templateUrl: './team-switched.component.html',
  styleUrls: ['./team-switched.component.scss'],
})
export class TeamSwitchedComponent {
  public constructor(
    @Inject(TOAST_DATA) public readonly data: ToastData<string>,
    @Inject(TOAST_REF) public readonly toastRef: ToastRef,
  ) {}

  public close() {
    this.toastRef.getComponentRef()!.instance.animationState = 'hidden';
  }
}
