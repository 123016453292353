import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-notification-badge',
  template: `{{ count > 99 ? '99+' : count }}`,
  styles: [
    `
      @import 'src/styles/variables';
      @import 'src/styles/utils/functions';

      :host {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        height: 30%;
        border-radius: 100%;
        background-color: $bnear-danger;
        color: white;
        font-weight: 600;
        font-size: scale-by-office(1);
        line-height: 1.4;
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationBadgeComponent {
  @Input({ required: true }) public count!: number;
}
