import { inject, Injectable } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';

import {
  BehaviorSubject,
  catchError,
  concatMap,
  map,
  merge,
  Observable,
  of,
  scan,
  shareReplay,
  skip,
  startWith,
  Subject,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { TodoTask, TodoTaskList as MSTodoTaskList } from '@microsoft/microsoft-graph-types';

import { UserService } from './user.service';
import { ApplicationInsightsService } from 'src/app/shared/services/application-insights.service';
import { CacheService } from 'src/app/shared/services/cache.service';

import { UserInfo } from '../models/services/user.model';

import { filterNullish } from '../utils/rxjs.util';
import { getGraphPages } from 'src/app/shared/utils/network.util';

import { ERROR_MAILBOX_API_DISABLED, GRAPH_API_BASE } from 'src/app/shared/constants';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TodoService {
  private readonly applicationInsightsService = inject(ApplicationInsightsService);
  private readonly cacheService = inject(CacheService);
  private readonly userService = inject(UserService);
  private readonly http = inject(HttpClient);

  private readonly createTodoSubject = new Subject<[string, string]>();
  private readonly updateTodoSubject = new Subject<[Partial<TodoTask>, string]>();
  private readonly deleteTodoSubject = new Subject<[string, string]>();
  private readonly todoReorderedSubject = new Subject<[number, number]>();
  private readonly getFreshTodosSubject = new Subject<void>();
  private readonly apiEnabledSubject = new BehaviorSubject<boolean>(true);

  private readonly createTodo$ = this.createTodoSubject.pipe(
    concatMap(([title, listId]) => {
      const tempId = `tempId-${window.crypto.randomUUID()}`;

      return merge(
        of({ id: tempId, title }),
        this.http.post<TodoTask>(`${GRAPH_API_BASE}/me/todo/lists/${listId}/tasks`, { title }).pipe(
          switchMap((todo) =>
            this.cacheService
              .updateGraphDataFn(({ todos }) => {
                todos.unshift(todo);

                return { todos };
              })
              .pipe(map(() => ({ todo, tempId }))),
          ),
        ),
      );
    }),
  );

  private readonly updateTodo$ = this.updateTodoSubject.pipe(
    concatMap(([todo, listId]) =>
      // Update UI immediately and the backend in the background
      merge(
        of(todo),
        this.http.patch<TodoTask>(`${GRAPH_API_BASE}/me/todo/lists/${listId}/tasks/${todo.id}`, todo).pipe(
          switchMap((todo) =>
            this.cacheService
              .updateGraphDataFn(({ todos }) => {
                const existingTodoIndex = todos.findIndex((cachedTodo) => cachedTodo.id === todo.id);
                const existingTodo = todos[existingTodoIndex];
                todos[existingTodoIndex] = { ...existingTodo, ...todo };

                return { todos };
              })
              .pipe(map(() => todo)),
          ),
          skip(1),
        ),
      ),
    ),
  );

  private readonly deleteTodo$ = this.deleteTodoSubject.pipe(
    switchMap(([todoId, listId]) =>
      // Update UI immediately and the backend in the background
      merge(
        of(todoId),
        this.http.delete(`${GRAPH_API_BASE}/me/todo/lists/${listId}/tasks/${todoId}`).pipe(
          switchMap(() => {
            return this.cacheService
              .updateGraphDataFn(({ todos }) => {
                const filteredTodos = todos.filter((todo) => todo.id !== todoId);
                return { todos: filteredTodos };
              })
              .pipe(map(() => todoId));
          }),
          skip(1),
        ),
      ),
    ),
  );

  private readonly cachedOrFreshTodoListId$ = this.cacheService.getGraphData().pipe(
    map(({ todoListId }) => todoListId),
    switchMap((listId) => (listId ? of(listId) : this.getTodoListId())),
  );

  private readonly freshTodos$ = this.cachedOrFreshTodoListId$.pipe(switchMap((id) => this.getTodos(id ?? '')));

  private readonly cachedTodos$ = this.cacheService.getGraphData().pipe(map(({ todos }) => todos));

  private readonly cachedOrFreshTodos$ = this.getFreshTodosSubject.pipe(
    startWith(void 0),
    switchMap(() => this.userService.getUserInfo()),
    switchMap((userInfo) =>
      merge(this.cachedTodos$, this.freshTodos$).pipe(
        map((todos) => {
          const todoOrder: string[] = userInfo?.todosOrderCsv?.split(',') ?? [];

          return todos
            .filter((todo) => todo.status !== 'completed')
            .sort((a, b) => todoOrder.indexOf(a.id!) - todoOrder.indexOf(b.id!));
        }),
      ),
    ),
  );

  private readonly mergedTodos$ = merge(
    this.cachedOrFreshTodos$,
    this.createTodo$,
    this.updateTodo$,
    this.deleteTodo$,
  ).pipe(
    scan((todos, value) => {
      // Value is an array of todos either cached or freshly fetched
      if (value instanceof Array) {
        return [...value];
      }

      // Value is a deleted todo's id
      if (typeof value === 'string') {
        return todos.filter((todo) => todo.id !== value);
      }

      // Value is a new todo with a correct id to replace the todo with the tempId
      if ('tempId' in value) {
        const existingTodoIndex = todos.findIndex((todo) => todo.id === value.tempId);
        if (existingTodoIndex !== -1) {
          todos[existingTodoIndex] = { ...todos[existingTodoIndex], ...value.todo };
        }
      }
      // Value is an updated todo or a new todo with a tempId
      else {
        const existingTodoIndex = todos.findIndex((todo) => todo.id === value.id);
        if (existingTodoIndex !== -1) {
          todos[existingTodoIndex] = { ...todos[existingTodoIndex], ...value };
        } else {
          todos.unshift(value);
        }
      }

      return todos;
    }, [] as TodoTask[]),
    shareReplay(1),
  );

  private readonly reorderedTodo$ = this.todoReorderedSubject.pipe(
    withLatestFrom(this.mergedTodos$),
    switchMap(([[previousIndex, currentIndex], todos]) => {
      moveItemInArray(todos, previousIndex, currentIndex);

      // Return sorted array immediately to update UI, update the user in background
      return merge(of(todos), this.userService.updateUser({ todosOrder: todos.map(({ id }) => id!) }));
    }),
    map((value) => (!this.isUser(value) ? value : null)),
    filterNullish(),
  );

  public readonly apiEnabled$ = this.apiEnabledSubject.asObservable();

  public readonly todos$ = merge(this.mergedTodos$, this.reorderedTodo$).pipe(shareReplay(1));

  public readonly todoListId$ = this.cachedOrFreshTodoListId$;

  public createTodo(title: string, listId: string): void {
    this.createTodoSubject.next([title, listId]);
  }

  public updateTodo(todo: TodoTask, listId: string): void {
    this.updateTodoSubject.next([todo, listId]);
  }

  public deleteTodoById(todoId: string, listId: string): void {
    this.deleteTodoSubject.next([todoId, listId]);
  }

  public reorderTodo(previousIndex: number, currentIndex: number): void {
    this.todoReorderedSubject.next([previousIndex, currentIndex]);
  }

  public getFreshTodos(): void {
    this.getFreshTodosSubject.next();
  }

  private getTodoListId(): Observable<string | undefined> {
    if (!this.apiEnabledSubject.value) return of(undefined);

    const endpoint = `${GRAPH_API_BASE}/me/todo/lists`;

    return getGraphPages<MSTodoTaskList>(endpoint, true, this.http).pipe(
      map(({ collection }) => collection.filter((list) => list.wellknownListName! === 'defaultList')[0].id),
      switchMap((id) => this.cacheService.updateGraphDataFn(() => ({ todoListId: id })).pipe(map(() => id))),
      catchError((error) => {
        if (error.status === 404 && error.message.includes(ERROR_MAILBOX_API_DISABLED)) {
          this.apiEnabledSubject.next(false);
          return of(undefined);
        }

        if (environment.enableLogging) console.error(error);
        this.applicationInsightsService.logCustomException(error);

        return of(undefined);
      }),
    );
  }

  private getTodos(taskListId: string): Observable<TodoTask[]> {
    if (!this.apiEnabledSubject.value || !taskListId) return of([]);

    const endpoint = `${GRAPH_API_BASE}/me/todo/lists/${taskListId}/tasks`;

    return getGraphPages<TodoTask>(endpoint, true, this.http).pipe(
      map(({ collection }) => collection),
      switchMap((todos) => this.cacheService.updateGraphDataFn(() => ({ todos })).pipe(map(() => todos))),
      catchError((error) => {
        if (error.status === 404 && error.message.includes(ERROR_MAILBOX_API_DISABLED)) {
          this.apiEnabledSubject.next(false);
          return of([]);
        }

        if (environment.enableLogging) console.error(error);
        this.applicationInsightsService.logCustomException(error);

        return of([]);
      }),
    );
  }

  private isUser(todoOrUser: TodoTask[] | UserInfo): todoOrUser is UserInfo {
    return 'user' in todoOrUser;
  }
}
