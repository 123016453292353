import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { CdkOverlayOrigin, ScrollStrategyOptions } from '@angular/cdk/overlay';

import { concatMap, Subject, take, takeUntil } from 'rxjs';

import { AuthService } from '../../services/auth.service';

import { OVERLAY_BOTTOM } from '../../models/tooltip.model';
import { LinkList } from 'src/app/shared/models/components/collaboration-tool.model';

import { onHoverOutside } from '../../utils/hover.util';
import { waitUntil } from '../../utils/rxjs.util';

@Component({
  selector: 'app-link-list',
  templateUrl: './link-list.component.html',
  styleUrls: ['./link-list.component.scss'],
})
export class LinkListComponent implements OnDestroy {
  private unsubscribe = new Subject<void>();
  private unsubscribeEditing = new Subject<void>();
  public HoverPositions = [OVERLAY_BOTTOM];

  public hovering = false;
  public submittingData = false;
  public anyEditChangesExist = false;

  public hoverOrigin?: CdkOverlayOrigin;

  @Input() public linkList?: LinkList;

  @ViewChild('hoverOrigin')
  public set _hoverOrigin(value: CdkOverlayOrigin | undefined) {
    this.hoverOrigin = value;
    this.changeDetector.detectChanges();
  }
  @ViewChild('hover', { read: ElementRef }) private hover?: ElementRef;

  public constructor(
    private readonly hostElement: ElementRef,
    private readonly changeDetector: ChangeDetectorRef,
    public readonly authService: AuthService,
    public readonly scrollStrategies: ScrollStrategyOptions,
  ) {}

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.unsubscribeEditing.next();
    this.unsubscribeEditing.complete();
  }

  public showHover(): void {
    if (this.hovering) return;

    this.hovering = true;
    this.closeHoverOnLeave();
  }

  public editingChanged(editing: boolean): void {
    this.unsubscribeEditing.next();
    this.unsubscribeEditing.complete();
    this.unsubscribeEditing = new Subject();

    if (!editing) this.closeHoverOnLeave();
  }

  private closeHoverOnLeave(): void {
    waitUntil(() => Boolean(this.hostElement.nativeElement) && Boolean(this.hover?.nativeElement))
      .pipe(
        concatMap(() => onHoverOutside([this.hostElement.nativeElement, this.hover?.nativeElement])),
        takeUntil(this.unsubscribe),
        takeUntil(this.unsubscribeEditing),
        take(1),
      )
      .subscribe(() => (this.hovering = false));
  }
}
