import { Pipe, PipeTransform } from '@angular/core';

import { FrontendAvailability, OutOfOfficeSettings } from '../../../../models/services/user.model';

@Pipe({
  name: 'getAvailabilityTranslationKey',
  standalone: true,
})
export class GetAvailabilityTranslationKeyPipe implements PipeTransform {
  public transform(
    availability: FrontendAvailability,
    data: {
      outOfOfficeSettings?: OutOfOfficeSettings;
      longForm: boolean;
    },
  ): string {
    if (data.outOfOfficeSettings?.isOutOfOffice) {
      return 'availability.outOfOffice';
    }

    switch (availability) {
      case FrontendAvailability.Available:
        return data.longForm ? 'availability.availableOnline' : 'availability.available';
      case FrontendAvailability.Away:
        return data.longForm ? 'availability.awaySeenRecently' : 'availability.away';
      case FrontendAvailability.BeRightBack:
        return 'availability.beRightBack';
      case FrontendAvailability.Busy:
        return 'availability.busy';
      case FrontendAvailability.DoNotDisturb:
        return 'availability.doNotDisturb';
      case FrontendAvailability.InACall:
        return 'availability.inACall';
      case FrontendAvailability.Presenting:
        return 'availability.presenting';
      default:
        return 'availability.offline';
    }
  }
}
