/**
 * @name ranges-apply
 * @fileoverview Take an array of string index ranges, delete/replace the string according to them
 * @version 7.0.16
 * @author Roy Revelt, Codsen Ltd
 * @license MIT
 * {@link https://codsen.com/os/ranges-apply/}
 */

import { rMerge as b } from "ranges-merge";
import w from "tiny-invariant";
var m = "7.0.16";
var T = m;
function _(s, n, r) {
  let t = 0,
    p = 0;
  if (arguments.length === 0) throw new Error("ranges-apply: [THROW_ID_01] inputs missing!");
  if (typeof s != "string") throw new TypeError(`ranges-apply: [THROW_ID_02] first input argument must be a string! Currently it's: ${typeof s}, equal to: ${JSON.stringify(s, null, 4)}`);
  if (n && !Array.isArray(n)) throw new TypeError(`ranges-apply: [THROW_ID_03] second input argument must be an array (or null)! Currently it's: ${typeof n}, equal to: ${JSON.stringify(n, null, 4)}`);
  if (r && typeof r != "function") throw new TypeError(`ranges-apply: [THROW_ID_04] the third input argument must be a function (or falsey)! Currently it's: ${typeof r}, equal to: ${JSON.stringify(r, null, 4)}`);
  if (!n?.filter(e => e).length) return s;
  let i;
  Array.isArray(n) && Number.isInteger(n[0]) && Number.isInteger(n[1]) ? i = [Array.from(n)] : i = Array.from(n);
  let f = i.length,
    c = 0;
  i.filter(e => e).forEach((e, a) => {
    if (r && (t = Math.floor(c / f * 10), t !== p && (p = t, r(t))), !Array.isArray(e)) throw new TypeError(`ranges-apply: [THROW_ID_05] ranges array, second input arg., has ${a}th element not an array: ${JSON.stringify(e, null, 4)}, which is ${typeof e}`);
    if (!Number.isInteger(e[0])) {
      if (!Number.isInteger(+e[0]) || +e[0] < 0) throw new TypeError(`ranges-apply: [THROW_ID_06] ranges array, second input arg. has ${a}th element, array ${JSON.stringify(e, null, 0)}. Its first element is not an integer, string index, but ${typeof e[0]}, equal to: ${JSON.stringify(e[0], null, 4)}.`);
      i[a][0] = +i[a][0];
    }
    if (!Number.isInteger(e[1])) {
      if (!Number.isInteger(+e[1]) || +e[1] < 0) throw new TypeError(`ranges-apply: [THROW_ID_07] ranges array, second input arg. has ${a}th element, array ${JSON.stringify(e, null, 0)}. Its second element is not an integer, string index, but ${typeof e[1]}, equal to: ${JSON.stringify(e[1], null, 4)}.`);
      i[a][1] = +i[a][1];
    }
    c += 1;
  });
  let o = b(i, {
    progressFn: e => {
      r && (t = 10 + Math.floor(e / 10), t !== p && (p = t, r(t)));
    }
  });
  w(o);
  let u = o.length;
  if (u > 0) {
    let e = s.slice(o[u - 1][1]);
    s = o.reduce((a, $, l, y) => {
      r && (t = 20 + Math.floor(l / u * 80), t !== p && (p = t, r(t)));
      let g = l === 0 ? 0 : y[l - 1][1],
        d = y[l][0];
      return `${a}${s.slice(g, d)}${y[l][2] || ""}`;
    }, ""), s += e;
  }
  return s;
}
export { _ as rApply, T as version };