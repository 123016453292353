import { Pipe, PipeTransform } from '@angular/core';

import { Emoji, EmojiSkintone, EmojiStyle, Skintones, Styles } from '../models/services/emoji.model';

import { environment } from 'src/environments/environment';

@Pipe({
  name: 'getEmojiUrl',
  standalone: true,
})
export class GetEmojiUrlPipe implements PipeTransform {
  public transform(
    emoji: Emoji,
    style: EmojiStyle,
    skintone: EmojiSkintone = 'Default',
    fallbackStyle: EmojiStyle = '3D',
  ): string {
    const skin = emoji.skintones ? `${skintone}/` : '';

    const filename = emoji.skintones
      ? emoji.skintones![skintone as keyof Skintones]![style as keyof Styles]
      : emoji.styles![style as keyof Styles];

    const fallbackFilename = emoji.skintones
      ? emoji.skintones![skintone as keyof Skintones]![fallbackStyle]
      : emoji.styles![fallbackStyle];

    const url =
      style === 'Animated' && filename
        ? `animated-emojis/${filename}`
        : `fluent-emojis/${emoji.dirName}/${skin}${filename ? style : fallbackStyle}/${filename ?? fallbackFilename}`;

    return `${environment.blobStorageBaseUri}/app-assets/${url}`;
  }
}
