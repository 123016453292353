import { ActiveCalls, CallParticipant } from '../../models/services/call.model';
import { GraphUserBaseData, UserOrParticipant } from '../../models/services/user.model';
import { User, UsersNotInCall } from '../../models/services/user.model';
import { FrontendAvailability, Location } from '../../models/services/user.model';
import { UserBaseData } from '../../models/services/user.model';

import { groupBy } from '../misc.util';
import { concatActiveCalls } from './call.util';

export function isUserOrParticipantAnonymized(userOrParticipant: UserOrParticipant): boolean {
  return 'isAnonymized' in userOrParticipant && Boolean(userOrParticipant.isAnonymized);
}

export function isUserOrParticipantMeetingGuest(userOrParticipant: UserOrParticipant): boolean {
  return 'isMeetingGuest' in userOrParticipant && Boolean(userOrParticipant.isMeetingGuest);
}

export function resolveUserGraphUpdate(
  sourceUserOrParticipant: GraphUserBaseData,
  targetUserOrParticipant: GraphUserBaseData & { isAnonymized?: boolean; isMeetingGuest?: boolean },
): void {
  // If true, targetUserOrParticipant is a participant! (applies to both)
  if (targetUserOrParticipant.isAnonymized || targetUserOrParticipant.isMeetingGuest) {
    // Reset graph data (edgeCase)
    // Can only happen if whitelist gets set and user is still active in application
    targetUserOrParticipant.displayName = undefined;
    targetUserOrParticipant.firstName = undefined;
    targetUserOrParticipant.lastName = undefined;
    targetUserOrParticipant.emailAddress = undefined;
    targetUserOrParticipant.userPrincipalName = undefined;
    targetUserOrParticipant.avatar = null;
    targetUserOrParticipant.jobTitle = null;
    targetUserOrParticipant.statusMessage = null;
    targetUserOrParticipant.outOfOfficeSettings = undefined;

    return;
  }

  if (sourceUserOrParticipant.displayName !== undefined)
    targetUserOrParticipant.displayName = sourceUserOrParticipant.displayName;
  if (sourceUserOrParticipant.firstName !== undefined)
    targetUserOrParticipant.firstName = sourceUserOrParticipant.firstName;
  if (sourceUserOrParticipant.lastName !== undefined)
    targetUserOrParticipant.lastName = sourceUserOrParticipant.lastName;

  if (sourceUserOrParticipant.emailAddress !== undefined)
    targetUserOrParticipant.emailAddress = sourceUserOrParticipant.emailAddress;
  if (sourceUserOrParticipant.userPrincipalName !== undefined)
    targetUserOrParticipant.userPrincipalName = sourceUserOrParticipant.userPrincipalName;

  if (sourceUserOrParticipant.jobTitle !== undefined)
    targetUserOrParticipant.jobTitle = sourceUserOrParticipant.jobTitle;

  if (sourceUserOrParticipant.statusMessage !== undefined)
    targetUserOrParticipant.statusMessage = sourceUserOrParticipant.statusMessage;

  if (sourceUserOrParticipant.avatar !== undefined) targetUserOrParticipant.avatar = sourceUserOrParticipant.avatar;

  if (sourceUserOrParticipant.outOfOfficeSettings !== undefined)
    targetUserOrParticipant.outOfOfficeSettings = sourceUserOrParticipant.outOfOfficeSettings;
}

export function resolveUserBaseDataUpdate(
  sourceUserOrParticipant: UserBaseData,
  targetUserOrParticipant: UserBaseData,
): void {
  targetUserOrParticipant.isLicenseValid = sourceUserOrParticipant.isLicenseValid;
  targetUserOrParticipant.baseLocation = sourceUserOrParticipant.baseLocation;
  targetUserOrParticipant.availability = sourceUserOrParticipant.availability;
  targetUserOrParticipant.workLocation = sourceUserOrParticipant.workLocation;
  targetUserOrParticipant.individualWorkLocation = sourceUserOrParticipant.individualWorkLocation;
  targetUserOrParticipant.currentTeamObjectId = sourceUserOrParticipant.currentTeamObjectId;
  targetUserOrParticipant.selectedTeamObjectId = sourceUserOrParticipant.selectedTeamObjectId;
  targetUserOrParticipant.visitingTeamObjectId = sourceUserOrParticipant.visitingTeamObjectId;
  targetUserOrParticipant.selectedEmoji = sourceUserOrParticipant.selectedEmoji;

  if (sourceUserOrParticipant.hashedEmailAddress !== undefined) {
    targetUserOrParticipant.hashedEmailAddress = sourceUserOrParticipant.hashedEmailAddress;
  }
}

export function resolveUsersUpdate(
  targetUsers: User[],
  sourceUsers: User[],
  usersNotInACall?: UsersNotInCall,
  activeCalls?: ActiveCalls,
) {
  for (const sourceUser of sourceUsers) {
    const targetIndex = targetUsers.findIndex((targetUser) => targetUser.objectId === sourceUser.objectId);

    if (targetIndex !== -1) {
      resolveUserBaseDataUpdate(sourceUser, targetUsers[targetIndex]);

      const userWithGraphData = findUserOrParticipant(sourceUser, usersNotInACall, activeCalls);

      if (userWithGraphData) {
        resolveUserGraphUpdate(userWithGraphData, targetUsers[targetIndex]);
      }
    }

    if (targetIndex === -1) targetUsers.push(sourceUser);
  }

  // Remove users that are no longer present
  for (let idx = targetUsers.length - 1; idx >= 0; idx--) {
    const userWasRemoved = !sourceUsers.some((uSource) => uSource.objectId === targetUsers[idx].objectId);

    if (userWasRemoved) targetUsers.splice(idx, 1);
  }
}

export function sortUsers(
  targets: UserOrParticipant[],
  myUserObjectId: string,
  myCurrentTeamObjectId: string | undefined = undefined,
  useAvailability = false,
  useBadges = false,
): void {
  const duplicatedParticipantObjectIdsCsv = getDuplicatedParticipantObjectIdsCsv(targets);
  const users = targets.filter((target) => !isParticipantGuard(target)).map((target) => target as User);
  const groupedUsers = groupBy(users, 'objectId');

  for (let i = targets.length - 1; i >= 0; i--) {
    const participant = isParticipantGuard(targets[i]) ? (targets[i] as CallParticipant) : undefined;
    const user = !isParticipantGuard(targets[i]) ? (targets[i] as User) : undefined;

    // Check if participant is a duplicate
    if (participant !== undefined) {
      const isDuplicated = duplicatedParticipantObjectIdsCsv.includes(participant.objectId);
      if (isDuplicated) targets.splice(i, 1);
    }

    // Check if user is a duplicate
    if (user !== undefined) {
      if (groupedUsers[user.objectId].length > 1) {
        groupedUsers[user.objectId].splice(0, 1);
        targets.splice(i, 1);
      }
    }
  }

  targets.sort((left: UserOrParticipant, right: UserOrParticipant): number => {
    const leftIsMe = (isParticipantGuard(left) ? left.userObjectId : left.objectId) === myUserObjectId;
    const rightIsMe = (isParticipantGuard(right) ? right.userObjectId : right.objectId) === myUserObjectId;

    // Me always to the left
    if (leftIsMe) return -1;
    if (rightIsMe) return 1;

    // External users always to the right
    const leftIsMeetingGuest = 'isMeetingGuest' in left && left.isMeetingGuest;
    const rightIsMeetingGuest = 'isMeetingGuest' in right && right.isMeetingGuest;

    const leftIsActive = 'isActive' in left && left.isActive;
    const rightIsActive = 'isActive' in right && right.isActive;

    if (rightIsActive && rightIsMeetingGuest && leftIsActive) return -1;
    if (leftIsActive && leftIsMeetingGuest && rightIsActive) return 1;

    if (leftIsActive && !rightIsActive) return -1;
    if (rightIsActive && !leftIsActive) return 1;

    if (useBadges) {
      // Users that are beRightBack to the left
      const userHasBeRightBackBadge = (user: UserOrParticipant) => {
        const isCheckedIn = user.baseLocation !== Location.Inactive;
        const isBeRightBack = user.availability === FrontendAvailability.BeRightBack;

        return !isCheckedIn && isBeRightBack && user.currentTeamObjectId === myCurrentTeamObjectId;
      };

      const leftHasBeRightBackBadge = userHasBeRightBackBadge(left);
      const rightHasBeRightBackBadge = userHasBeRightBackBadge(right);

      if (leftHasBeRightBackBadge && !rightHasBeRightBackBadge) return -1;
      if (rightHasBeRightBackBadge && !leftHasBeRightBackBadge) return 1;

      // Users in another room to the left
      const userHasOtherRoomBadge = (user: UserOrParticipant) => {
        const isCheckedIn = user.baseLocation !== Location.Inactive;
        const isVisiting = user.visitingTeamObjectId !== null;
        const myTeamIsSelected = user.selectedTeamObjectId === myCurrentTeamObjectId;
        const myTeamIsVisited = user.visitingTeamObjectId === myCurrentTeamObjectId;

        return isCheckedIn && ((!isVisiting && !myTeamIsSelected) || (isVisiting && !myTeamIsVisited));
      };

      const leftHasOtherRoomBadge = userHasOtherRoomBadge(left);
      const rightHasOtherRoomBadge = userHasOtherRoomBadge(right);

      if (leftHasOtherRoomBadge && !rightHasOtherRoomBadge) return -1;
      if (rightHasOtherRoomBadge && !leftHasOtherRoomBadge) return 1;
    }

    if (useAvailability) {
      const leftHasAvailability = left.availability !== null;
      const rightHasAvailability = right.availability !== null;

      if (leftHasAvailability && !rightHasAvailability) return -1;
      if (rightHasAvailability && !leftHasAvailability) return 1;

      if (leftHasAvailability && rightHasAvailability && left.availability !== right.availability) {
        const availabilitySortingOrder = [
          FrontendAvailability.Available,
          FrontendAvailability.Away,
          FrontendAvailability.Busy,
          FrontendAvailability.Presenting,
          FrontendAvailability.InACall,
          FrontendAvailability.DoNotDisturb,
          FrontendAvailability.BeRightBack,
          FrontendAvailability.Offline,
        ];

        const leftAvailabilityOrder = availabilitySortingOrder.indexOf(left.availability!);
        const rightAvailabilityOrder = availabilitySortingOrder.indexOf(right.availability!);

        if (leftAvailabilityOrder !== -1 && rightAvailabilityOrder !== -1) {
          return leftAvailabilityOrder - rightAvailabilityOrder;
        }
      }
    }

    const leftIsParticipant = isParticipantGuard(left);
    const rightIsParticipant = isParticipantGuard(right);

    if (leftIsParticipant && rightIsParticipant) {
      if (left.userObjectId < right.userObjectId) return -1;
      if (right.userObjectId < left.userObjectId) return 1;
    } else if ('objectId' in left && 'objectId' in right) {
      if (left.objectId < right.objectId) return -1;
      if (right.objectId < left.objectId) return 1;
    }

    return 0;
  });
}

export function mapParticipantToUser(participant: CallParticipant, baseLocation: Location): User {
  return {
    // User data
    objectId: participant.userObjectId,

    isLicenseValid: participant.isLicenseValid,

    isExternalAccount: participant.isExternalAccount,

    baseLocation: baseLocation,
    availability: participant.availability,

    selectedEmoji: participant.selectedEmoji,
    workLocation: participant.workLocation,
    individualWorkLocation: participant.individualWorkLocation,

    currentTeamObjectId: participant.currentTeamObjectId,
    selectedTeamObjectId: participant.selectedTeamObjectId,
    visitingTeamObjectId: participant.visitingTeamObjectId,

    // Graph data
    displayName: participant.displayName,
    firstName: participant.firstName,
    lastName: participant.lastName,

    emailAddress: participant.emailAddress,
    userPrincipalName: participant.userPrincipalName,

    avatar: participant.avatar,
    jobTitle: participant.jobTitle,
    statusMessage: participant.statusMessage,
    outOfOfficeSettings: participant.outOfOfficeSettings,
  };
}

function findUserOrParticipant(
  newUser: User,
  usersNotInACall?: UsersNotInCall,
  activeCalls?: ActiveCalls,
): UserOrParticipant | undefined {
  if (!usersNotInACall && !activeCalls) return undefined;

  const findUser = (users: User[] | undefined): User | undefined =>
    users?.find((uOld) => uOld.objectId === newUser.objectId);

  const findParticipant = (participants: CallParticipant[] | undefined): CallParticipant | undefined =>
    participants?.find((pOld) => pOld.userObjectId === newUser.objectId);

  const desksUsers = usersNotInACall?.desks;
  const inactiveUsers = usersNotInACall?.inactive;

  let userWithGraphData: UserOrParticipant | undefined = findUser(inactiveUsers);
  if (!userWithGraphData) userWithGraphData = findUser(desksUsers);

  if (!userWithGraphData) {
    const allCalls = concatActiveCalls(activeCalls);
    for (const call of allCalls) {
      userWithGraphData = findParticipant(call.participants);

      if (userWithGraphData) break;
    }
  }

  return userWithGraphData;
}

export function isParticipantGuard(obj: UserOrParticipant): obj is CallParticipant {
  return 'userObjectId' in obj && 'objectId' in obj && 'isMeetingGuest' in obj && 'isAnonymized' in obj;
}

function getDuplicatedParticipantObjectIdsCsv(userOrParticipants: UserOrParticipant[]): string {
  const participants = userOrParticipants
    .filter((userOrParticipant) => isParticipantGuard(userOrParticipant))
    .map((p) => p as CallParticipant);

  const duplicatedParticipantObjectIdsCsv: string[] = [];
  const participantGroups = groupBy(participants, 'userObjectId');

  for (const participantGroup of Object.keys(participantGroups).map((key) => participantGroups[key])) {
    if (participantGroup.length === 1) continue;

    const activeParticipants = participantGroup.filter((p) => p.isActive);
    const inactiveParticipants = participantGroup.filter((p) => !p.isActive);

    let duplicatedParticipants: CallParticipant[] = [];
    if (activeParticipants.length > 0) {
      // Keep only one active participant
      duplicatedParticipants = participantGroup.filter((p) => p.objectId !== activeParticipants[0].objectId);
    } else {
      // Keep only one inactive participant
      duplicatedParticipants = participantGroup.filter((p) => p.objectId !== inactiveParticipants[0].objectId);
    }

    duplicatedParticipantObjectIdsCsv.push(...duplicatedParticipants.map((p) => p.objectId));
  }

  return duplicatedParticipantObjectIdsCsv.join(',');
}
