import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalSpinnerService {
  private _showGlobalSpinner = new Subject<boolean>();
  private _showGlobalOfflineScreen = new Subject<void>();

  public showGlobalSpinner = this._showGlobalSpinner.asObservable();
  public showGlobalOfflineScreen = this._showGlobalOfflineScreen.asObservable();

  // Once the offline screen is triggered it only disappears when the app is reloaded
  public offlineScreenIsActive = false;

  public showSpinner(showSpinner: boolean): void {
    this._showGlobalSpinner.next(showSpinner);
  }

  public showOfflineScreen(): void {
    this.offlineScreenIsActive = true;
    this._showGlobalOfflineScreen.next();
  }
}
