import { Chat } from '../models/services/chat/chat.model';
import { TeamSettings } from '../models/services/team.model';

import { IndexedDB } from './indexed-db';

export function migrateChatIntegration(db: IndexedDB): void {
  db.version(3)
    .stores({ auth: '', general: '', backend: '', graph: '' })
    .upgrade((transaction) => {
      transaction
        .table('graph')
        .toCollection()
        .modify((graphData) => {
          graphData.chats = [];
        });
    });

  db.version(4)
    .stores({ auth: '', general: '', backend: '', graph: '' })
    .upgrade((transaction) => {
      transaction
        .table('graph')
        .toCollection()
        .modify((graphData) => {
          graphData.chats = [];
        });
    });

  db.version(4.1)
    .stores({ auth: '', general: '', backend: '', graph: '' })
    .upgrade((transaction) => {
      transaction
        .table('graph')
        .toCollection()
        .modify((graphData) => {
          graphData.chats.forEach((chat: Chat) => {
            chat.pendingMessages = [];
          });
        });
    });

  db.version(4.2)
    .stores({ auth: '', general: '', backend: '', graph: '' })
    .upgrade((transaction) => {
      transaction
        .table('general')
        .toCollection()
        .modify((generalData) => {
          generalData.sidebarLastSelectedTabIndex = 0;
        });
    });
}

export function migrateSidebarFilesIntegration(db: IndexedDB): void {
  db.version(5)
    .stores({ auth: '', general: '', backend: '', graph: '' })
    .upgrade((transaction) => {
      transaction
        .table('graph')
        .toCollection()
        .modify((graphData) => {
          graphData.recentFiles = [];
          graphData.trendingFiles = [];
        });
    });
}

export function migrateUnreadStatusBadge(db: IndexedDB): void {
  db.version(6)
    .stores({ auth: '', general: '', backend: '', graph: '' })
    .upgrade((transaction) => {
      transaction
        .table('general')
        .toCollection()
        .modify((generalData) => {
          generalData.readUserStatuses = [];
        });
    });
}

export function migrateEmojiPicker(db: IndexedDB): void {
  db.version(7)
    .stores({ auth: '', general: '', backend: '', graph: '' })
    .upgrade((transaction) => {
      transaction
        .table('general')
        .toCollection()
        .modify((generalData) => {
          // DEFAULT_RECENT_EMOJIS removed due to https://github.com/bNear/bNear/pull/4252
          generalData.recentEmojis = [];
        });
    });
}

export function migrateTodos(db: IndexedDB): void {
  db.version(8)
    .stores({ auth: '', general: '', backend: '', graph: '' })
    .upgrade((transaction) => {
      transaction
        .table('graph')
        .toCollection()
        .modify((generalData) => {
          generalData.todos = [];
        });
    });
}

export function migrateTeamSettings(db: IndexedDB): void {
  db.version(9)
    .stores({ auth: '', general: '', backend: '', graph: '' })
    .upgrade((transaction) => {
      transaction
        .table('general')
        .toCollection()
        .modify((generalData) => {
          generalData.teamSettings = new Map<string, TeamSettings>();
        });
    });
}

export function migrateEvents(db: IndexedDB): void {
  db.version(9.1)
    .stores({ auth: '', general: '', backend: '', graph: '' })
    .upgrade((transaction) => {
      transaction
        .table('graph')
        .toCollection()
        .modify((generalData) => {
          delete generalData.eventReminders;
          generalData.events = [];
        });
    });
}

export function migrateCubicles(db: IndexedDB): void {
  db.version(10)
    .stores({ auth: '', general: '', backend: '', graph: '' })
    .upgrade((transaction) => {
      transaction
        .table('backend')
        .toCollection()
        .modify((backendData) => {
          backendData.tenantSettings = undefined;
        });
    });

  db.version(10.1)
    .stores({ auth: '', general: '', backend: '', graph: '' })
    .upgrade((transaction) => {
      transaction
        .table('general')
        .toCollection()
        .modify((general) => {
          general.hints.joinDesksCallHintLastShown = general.hints.joinTableCallHintLastShown;
          delete general.hints.joinTableCallHintLastShown;
        });
    });
}

export function migrateRemoveReadUserStatuses(db: IndexedDB): void {
  db.version(10.2)
    .stores({ auth: '', general: '', backend: '', graph: '' })
    .upgrade((transaction) => {
      transaction
        .table('general')
        .toCollection()
        .modify((general) => {
          delete general.readUserStatuses;
        });
    });
}

export function migrateRemoveRecentEmojis(db: IndexedDB): void {
  db.version(10.3)
    .stores({ auth: '', general: '', backend: '', graph: '' })
    .upgrade((transaction) => {
      transaction
        .table('general')
        .toCollection()
        .modify((general) => {
          delete general.recentEmojis;
        });
    });
}

export function migrateRemoveDisableLastUserLocationReload(db: IndexedDB): void {
  db.version(10.4)
    .stores({ auth: '', general: '', backend: '', graph: '' })
    .upgrade((transaction) => {
      transaction
        .table('general')
        .toCollection()
        .modify((general) => {
          delete general.disableLastUserLocationDuringReload;
        });
    });
}
