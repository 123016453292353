import {
  AfterViewInit,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {
  ConnectedOverlayPositionChange,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import {
  asyncScheduler,
  BehaviorSubject,
  concat,
  concatMap,
  delay,
  EMPTY,
  filter,
  fromEvent,
  interval,
  map,
  merge,
  NEVER,
  Observable,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  throttleTime,
  timer,
  withLatestFrom,
} from 'rxjs';

import { TooltipComponent } from './tooltip.component';

import { AuthService } from '../../services/auth.service';

import { ScaleByOfficePipe } from '../../pipes/scale-by-office.pipe';

import { ZIndex } from '../../models/directives/tooltip.model';
import { TooltipDirectionList, TooltipGeneralDirection, TooltipRef } from '../../models/tooltip.model';

import {
  getClassForZIndex,
  getPositions,
  getZIndexValue,
  hasRectangleChangedFactory,
} from '../../utils/directives/tooltip.util';
import { onClickOutside, onHoverOutside } from '../../utils/hover.util';
import { waitUntil } from '../../utils/rxjs.util';
import { computeScaleFactorPx } from '../../utils/style.util';
import { TOOLTIP_REF } from '../../utils/tokens.util';

@Directive({
  selector: '[appTooltip]',
  standalone: true,
  exportAs: 'appTooltip',
})
export class TooltipDirective implements OnChanges, AfterViewInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject<void>();
  private unsubscribeHover: Subject<void> = new Subject<void>();
  private autoCloseEnabled$ = new BehaviorSubject<boolean>(false);

  private autoClose$?: Observable<boolean>;

  private desktopCenteredPicker = false;
  private hoveredAtLeastOnce = false;
  private isDestroyed = false;

  private overlayRef?: OverlayRef;
  private componentRef?: ComponentRef<TooltipComponent>;

  @Input('appTooltip') public content!: TemplateRef<unknown> | number | string;
  @Input('appTooltipDirection') public direction?: TooltipDirectionList | TooltipGeneralDirection = 'bottom';

  // If `open` is set you have to manually control the open/close state of the tooltip from the outside
  @Input('appTooltipOpen') public open?: boolean;
  // If the pointer hovers over the host element, delay the opening of the tooltip
  @Input('appTooltipOpenDelayMs') public openDelayMs?: number;

  @Input('appTooltipDisabled') public disabled = false;

  // Force the tooltip to close once
  @Input('appTooltipCloseTrigger') public closeTrigger = false;

  // `disableClose` disables closing by hover but not by `autoClose`
  @Input('appTooltipDisableClose') public disableClose = false;

  // If `autoCloseEnabled` is set to `true` and `appTooltipOpen` is defined you have to listen to the `appTooltipOpen` event to manually reset the variable.
  // If `autoCloseEnabled` is set to true after the tooltip is already opened it won't have any effect until the tooltip is re-opened.
  // If `autoCloseEnabled` is set to true bear in mind that despite setting it manually open, the autoClose still triggers.
  @Input('appTooltipAutoCloseEnabled') public autoCloseEnabled = false;
  @Input('appTooltipAutoCloseTimeMs') public autoCloseTimeMs = 20_000;

  // If hovering outside but over another overlay, ignore that outside hover
  // This property does not support dynamic updates
  @Input('appTooltipIgnoreCdkOverlayOutsideHover') public ignoreCdkOverlayOutsideHover = false;

  @Input('appTooltipBounceAnimation') public bounceAnimation = false;
  @Input('appTooltipFadeInAnimationDurationMs') public fadeInAnimationDurationMs?: number;
  @Input('appTooltipFadeInAnimationDelayMs') public fadeInAnimationDelayMs?: number;
  @Input('appTooltipFadeOutAnimationDurationMs') public fadeOutAnimationDurationMs?: number;
  @Input('appTooltipFadeOutAnimationDelayMs') public fadeOutAnimationDelayMs?: number;

  // Any valid `CSS` is applicable
  @Input('appTooltipColor') public color = 'var(--bnear-brand)';
  @Input('appTooltipBackgroundColor') public backgroundColor = 'white';

  // Any valid `CSS` is applicable. In addition our internal `scale-by-office($factor)` method can be used.
  @Input('appTooltipPadding') public padding? = 'scale-by-office(1) scale-by-office(1.2)';
  @Input('appTooltipMinWidth') public minWidth? = 'fit-content';
  @Input('appTooltipMaxWidth') public maxWidth = 'scale-by-office(20)';
  @Input('appTooltipMaxHeight') public maxHeight = 'unset';
  @Input('appTooltipBorderRadius') public borderRadius = 'scale-by-office(0.5)';
  @Input('appTooltipFontSize') public fontSize = 'scale-by-office(0.9)';
  @Input('appTooltipPinOffset') public pinOffset = 'scale-by-office(0)';
  @Input('appTooltipZIndex') public zIndex: ZIndex = 'medium';

  // Either `scale-by-office($factor)` or a pixel value without unit
  @Input('appTooltipContainerOffsetMainAxis') public containerOffsetMainAxis = 'scale-by-office(1)';
  @Input('appTooltipContainerOffsetCrossAxisCenter') public containerOffsetCrossAxisCenter = 'scale-by-office(0)';
  @Input('appTooltipContainerOffsetCrossAxisShifted') public containerOffsetCrossAxisShifted = 'scale-by-office(1.5)';
  @Input('appTooltipPinSize') public pinSize? = 'scale-by-office(0.8)';
  // `pinColor` falls back to the background color if not set
  @Input('appTooltipPinColor') public pinColor?: string;

  @Output('appTooltipOpenChange') public openChange = new EventEmitter();
  @Output('appTooltipCloseTriggerChange') public closeTriggerChange = new EventEmitter();
  @Output('appTooltipOutsideClick') public outsideClick = new EventEmitter();
  @Output('appTooltipPositionChange') public positionChange = new EventEmitter<
    'bottom' | 'center' | 'left' | 'right' | 'top'
  >();

  public constructor(
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
    private readonly elementRef: ElementRef,
    private readonly overlay: Overlay,
    private readonly authService: AuthService,
    private readonly scaleByOfficePipe: ScaleByOfficePipe,
    private readonly injector: Injector,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ('content' in changes && this.componentRef) {
      this.setContent(this.componentRef.instance, this.content);
    }

    if ('open' in changes && !changes['open'].firstChange && !changes['open'].previousValue && this.open) {
      this.show();
    }
    if ('open' in changes && !changes['open'].firstChange && !this.open) this.hide();

    if ('disabled' in changes && this.disabled) this.hide();

    if ('closeTrigger' in changes) {
      this.hide();

      this.closeTrigger = false;
      this.closeTriggerChange.emit(false);
    }

    if ('autoCloseEnabled' in changes) {
      this.autoCloseEnabled$.next(this.autoCloseEnabled);
    }

    if (!this.componentRef) return;

    if ('bounceAnimation' in changes) this.componentRef.instance.bounceAnimation = this.bounceAnimation;
    if ('color' in changes) this.componentRef.instance.color = this.color;
    if ('backgroundColor' in changes) this.componentRef.instance.backgroundColor = this.backgroundColor;
    if ('padding' in changes)
      this.componentRef.instance.padding = this.scaleByOfficePipe.transform(
        this.padding || 'scale-by-office(1) scale-by-office(1.2)',
      );
    if ('minWidth' in changes)
      this.componentRef.instance.minWidth = this.scaleByOfficePipe.transform(this.minWidth || 'fit-content');
    if ('maxWidth' in changes) this.componentRef.instance.maxWidth = this.scaleByOfficePipe.transform(this.maxWidth);
    if ('maxHeight' in changes) this.componentRef.instance.maxHeight = this.scaleByOfficePipe.transform(this.maxHeight);
    if ('borderRadius' in changes)
      this.componentRef.instance.borderRadius = this.scaleByOfficePipe.transform(this.borderRadius);
    if ('zIndex' in changes) this.componentRef.instance.zIndex = getZIndexValue(this.zIndex);
    if ('fontSize' in changes) this.componentRef.instance.fontSize = this.scaleByOfficePipe.transform(this.fontSize);

    if ('containerOffsetMainAxis' in changes)
      this.componentRef.instance.containerOffsetMainAxis = this.scaleByOfficePipe.transform(
        this.containerOffsetMainAxis,
      );

    if ('containerOffsetCrossAxisCenter' in changes)
      this.componentRef.instance.containerOffsetCrossAxisCenter = this.scaleByOfficePipe.transform(
        this.containerOffsetCrossAxisCenter,
      );

    if ('containerOffsetCrossAxisShifted' in changes)
      this.componentRef.instance.containerOffsetCrossAxisShifted = this.scaleByOfficePipe.transform(
        this.containerOffsetCrossAxisShifted,
      );

    if ('pinSize' in changes)
      this.componentRef!.instance.pinSize = this.scaleByOfficePipe.transform(this.pinSize || 'scale-by-office(0.8)');

    if ('pinColor' in changes) this.componentRef!.instance.pinColor = this.pinColor ?? this.backgroundColor;

    if ('pinOffset' in changes)
      this.componentRef!.instance.pinOffset = this.scaleByOfficePipe.transform(this.pinOffset);

    if (
      'containerOffsetMainAxis' in changes ||
      'containerOffsetCrossAxisCenter' in changes ||
      'containerOffsetCrossAxisShifted' in changes
    ) {
      const positionStrategy = this.getPositionStrategy();
      this.overlayRef?.updatePositionStrategy(positionStrategy);
    }
  }

  public ngAfterViewInit(): void {
    if (this.open && !this.openDelayMs) this.show();
    if (this.open && this.openDelayMs) timer(this.openDelayMs).subscribe(() => this.show());

    const showIfEligible = () => {
      if (!this.disabled && this.open === undefined) this.show();
    };

    fromEvent(this.elementRef.nativeElement, 'click')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => showIfEligible());

    if (this.openDelayMs === undefined) {
      fromEvent(this.elementRef.nativeElement, 'pointerenter')
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => showIfEligible());

      return;
    }

    // Delay opening the tooltip and only open it if the user is still hovering over the element
    let isHovering = false;
    fromEvent(this.elementRef.nativeElement, 'pointerenter')
      .pipe(
        takeUntil(this.unsubscribe),
        tap(() => (isHovering = true)),
        throttleTime(this.openDelayMs, undefined, { leading: true, trailing: true }),
        delay(this.openDelayMs),
        filter(() => isHovering),
      )
      .subscribe(() => showIfEligible());

    fromEvent(this.elementRef.nativeElement, 'pointerleave')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => (isHovering = false));
  }

  public ngOnDestroy(): void {
    this.isDestroyed = true;

    this.hide();

    this.unsubscribe.complete();
    this.unsubscribeHover.complete();
  }

  private show(): void {
    if (this.overlayRef?.hasAttached() || this.isDestroyed) return;

    this.openChange.emit(true);

    const positionStrategy = this.getPositionStrategy();
    const scrollStrategy =
      this.open === undefined && !this.authService.isMobileDevice
        ? this.overlay.scrollStrategies.close()
        : this.overlay.scrollStrategies.reposition();

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: false,
      panelClass: getClassForZIndex(this.zIndex),
    });

    const tooltipRef = new TooltipRef(this.overlayRef);
    const injector = Injector.create({
      parent: this.injector,
      providers: [{ provide: TOOLTIP_REF, useValue: tooltipRef }],
    });

    this.componentRef = this.overlayRef.attach(new ComponentPortal(TooltipComponent, undefined, injector));
    this.setInitialVariables();

    this.updatePositionInBackground();
    this.updatePositionStrategyInBackground();

    this.waitForCloseInBackground();
  }

  private hide(): void {
    if (!this.overlayRef?.hasAttached()) return;

    this.overlayRef = undefined;
    this.componentRef!.instance.animationState = 'hidden';
    this.componentRef = undefined;

    this.autoClose$ = undefined;
    this.hoveredAtLeastOnce = false;

    this.unsubscribeHover.next();

    this.openChange.emit(false);
  }

  private setContent(component: TooltipComponent, content: unknown): void {
    if (content instanceof TemplateRef) component.template = content;
    else if (typeof content === 'string') component.text = content;
    else if (typeof content === 'number') component.text = content.toString();
    else throw new Error('Tooltip content can only be of type string, number or TemplateRef');
  }

  private setInitialVariables() {
    this.setContent(this.componentRef!.instance, this.content);
    this.componentRef!.instance.fadeInAnimationDurationMs = this.fadeInAnimationDurationMs;
    this.componentRef!.instance.fadeInAnimationDelayMs = this.fadeInAnimationDelayMs;
    this.componentRef!.instance.fadeOutAnimationDurationMs = this.fadeOutAnimationDurationMs;
    this.componentRef!.instance.fadeOutAnimationDelayMs = this.fadeOutAnimationDelayMs;
    this.componentRef!.instance.bounceAnimation = this.bounceAnimation;
    this.componentRef!.instance.color = this.color;
    this.componentRef!.instance.backgroundColor = this.backgroundColor;
    this.componentRef!.instance.containerOffsetMainAxis = this.scaleByOfficePipe.transform(
      this.containerOffsetMainAxis,
    );
    this.componentRef!.instance.containerOffsetCrossAxisCenter = this.scaleByOfficePipe.transform(
      this.containerOffsetCrossAxisCenter,
    );
    this.componentRef!.instance.containerOffsetCrossAxisShifted = this.scaleByOfficePipe.transform(
      this.containerOffsetCrossAxisShifted,
    );
    this.componentRef!.instance.pinSize = this.scaleByOfficePipe.transform(this.pinSize || 'scale-by-office(0.8)');
    this.componentRef!.instance.pinColor = this.pinColor ?? this.backgroundColor;
    this.componentRef!.instance.pinOffset = this.scaleByOfficePipe.transform(this.pinOffset);
    this.componentRef!.instance.padding = this.scaleByOfficePipe.transform(
      this.padding || 'scale-by-office(1) scale-by-office(1.2)',
    );
    this.componentRef!.instance.minWidth = this.scaleByOfficePipe.transform(this.minWidth || 'fit-content');
    this.componentRef!.instance.maxWidth = this.scaleByOfficePipe.transform(this.maxWidth);
    this.componentRef!.instance.maxHeight = this.scaleByOfficePipe.transform(this.maxHeight);
    this.componentRef!.instance.borderRadius = this.scaleByOfficePipe.transform(this.borderRadius);
    this.componentRef!.instance.fontSize = this.scaleByOfficePipe.transform(this.fontSize);
    this.componentRef!.instance.zIndex = getZIndexValue(this.zIndex);
    this.componentRef!.instance.animationState = 'visible';
  }

  private updatePositionStrategyInBackground() {
    fromEvent(window, 'resize')
      .pipe(throttleTime(125, asyncScheduler, { leading: true, trailing: true }))
      .pipe(takeUntil(this.unsubscribeHover))
      .subscribe(() => {
        if (!this.overlayRef) return;

        const positionStrategy = this.getPositionStrategy();
        this.overlayRef.updatePositionStrategy(positionStrategy);
      });
  }

  private getPositionStrategy(): FlexibleConnectedPositionStrategy {
    const containerOffsetMainAxisPx = this.getPropertyPx(this.containerOffsetMainAxis);
    const containerOffsetCrossAxisCenterPx = this.getPropertyPx(this.containerOffsetCrossAxisCenter);

    const pinSizeHalf = this.getPropertyPx(this.pinSize || 'scale-by-office(0.8)') / 2;
    const containerOffsetCrossAxisShiftedPx = pinSizeHalf + this.getPropertyPx(this.containerOffsetCrossAxisShifted);

    const positions = getPositions(
      this.direction || 'bottom',
      containerOffsetMainAxisPx,
      containerOffsetCrossAxisCenterPx,
      containerOffsetCrossAxisShiftedPx,
    );
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withGrowAfterOpen(true)
      .withPush(true)
      .withPositions(positions);

    positionStrategy.positionChanges
      .pipe(takeUntil(this.unsubscribeHover), withLatestFrom(this.authService.isMobileWidth$))
      .subscribe(([positionChange, isMobileWidth]) => this.onPositionChange(positionChange, isMobileWidth));

    return positionStrategy;
  }

  private onPositionChange(position: ConnectedOverlayPositionChange, isMobileWidth: boolean): void {
    if (!isMobileWidth) this.desktopCenteredPicker = position.connectionPair.panelClass === 'overlay-push-required';

    let direction: 'bottom' | 'center' | 'left' | 'right' | 'top' = 'center';
    if (Array.isArray(position.connectionPair.panelClass)) {
      if (position.connectionPair.panelClass.includes('overlay-position-top')) direction = 'top';
      else if (position.connectionPair.panelClass.includes('overlay-position-right')) direction = 'right';
      else if (position.connectionPair.panelClass.includes('overlay-position-bottom')) direction = 'bottom';
      else if (position.connectionPair.panelClass.includes('overlay-position-left')) direction = 'left';
    }

    this.positionChange.emit(direction);
  }

  private updatePositionInBackground() {
    const hasHostRectangleChanged = hasRectangleChangedFactory(this.elementRef.nativeElement as HTMLElement);
    const hasOverlayRectangleChanged = hasRectangleChangedFactory(this.overlayRef!.overlayElement);
    concat(timer(0, 25).pipe(take(5)), interval(250))
      .pipe(
        takeUntil(this.unsubscribeHover),
        filter(() => hasHostRectangleChanged() || hasOverlayRectangleChanged()),
      )
      .subscribe(() => this.overlayRef?.updatePosition());

    // If overlay is centered only close it on an outside hover if the pointer has entered the hover once,
    // otherwise the hover may not be usable because it is in the middle of the screen and a hover over e.g. the background
    // is triggered before an emoji can be selected
    if (!this.authService.isMobileDevice) {
      fromEvent(this.overlayRef!.overlayElement, 'pointerenter')
        .pipe(
          takeUntil(this.unsubscribeHover),
          concatMap(() => this.authService.isMobileWidth$.pipe(take(1))),
          filter((isMobileWidth) => !isMobileWidth),
        )
        .subscribe(() => (this.hoveredAtLeastOnce = true));
    }
  }

  private waitForCloseInBackground() {
    if (this.autoCloseEnabled || this.authService.isMobileDevice) {
      this.autoClose$ = this.autoCloseEnabled$.pipe(
        switchMap((autoCloseEnabled) => (autoCloseEnabled ? timer(this.autoCloseTimeMs) : NEVER)),
        takeUntil(this.unsubscribeHover),
        map(() => true),
      );
    }

    waitUntil(() => Boolean(this.elementRef.nativeElement) && Boolean(this.componentRef?.location.nativeElement))
      .pipe(
        switchMap(() =>
          merge(
            onHoverOutside(
              [this.elementRef.nativeElement, this.overlayRef!.overlayElement],
              this.ignoreCdkOverlayOutsideHover,
            ).pipe(
              filter(
                () =>
                  !this.disableClose &&
                  (!this.desktopCenteredPicker || this.hoveredAtLeastOnce) &&
                  (this.open === undefined || this.authService.isMobileDevice),
              ),
            ),
            this.autoClose$ ?? EMPTY,
          ),
        ),
        takeUntil(this.unsubscribeHover),
        take(1),
      )
      .subscribe(() => this.hide());

    waitUntil(() => Boolean(this.elementRef.nativeElement) && Boolean(this.componentRef?.location.nativeElement))
      .pipe(
        switchMap(() =>
          merge(
            onClickOutside(
              [this.elementRef.nativeElement, this.overlayRef!.overlayElement],
              this.ignoreCdkOverlayOutsideHover,
            ),
            this.autoClose$ ?? EMPTY,
          ),
        ),
        takeUntil(this.unsubscribeHover),
        take(1),
      )
      .subscribe(() => this.outsideClick.emit());
  }

  private getPropertyPx(value: string): number {
    const scaleFactor = this.getScaleByOfficeFactor(value);
    return scaleFactor !== undefined ? computeScaleFactorPx(scaleFactor) : parseFloat(value);
  }

  private getScaleByOfficeFactor(value: string): number | undefined {
    if (!value.includes('scale-by-office')) return undefined;

    return parseFloat(value.replace(/scale-by-office\((.*?)\)/g, '$1'));
  }
}
