export interface Tour {
  identifier: string;
  steps: TourStep[];
}

export interface TourStep {
  identifier: string;
  isCompleted: boolean;
  isFirstStep?: boolean;
  isLastStep?: boolean;
}

export const OFFICE_TOUR_STEPS = {
  introduction: {
    identifier: 'introduction',
    isCompleted: false,
  },
  avatar: {
    identifier: 'avatar',
    isCompleted: false,
  },
  desks: {
    identifier: 'desks',
    isCompleted: false,
  },
  joinVoice: {
    identifier: 'joinVoice',
    isCompleted: false,
  },
  topicTables: {
    identifier: 'topicTables',
    isCompleted: false,
  },
  reminder: {
    identifier: 'reminder',
    isCompleted: false,
  },
  meetingOverview: {
    identifier: 'meetingOverview',
    isCompleted: false,
  },
  newBreakoutMeeting: {
    identifier: 'newBreakoutMeeting',
    isCompleted: false,
  },
  kitchen: {
    identifier: 'kitchen',
    isCompleted: false,
  },
  otm: {
    identifier: 'otm',
    isCompleted: false,
  },
  leaveOffice: {
    identifier: 'leaveOffice',
    isCompleted: false,
  },
  noop: {
    identifier: 'noop',
    isCompleted: false,
  },
};

export const EXCLUDED_STEPS = [OFFICE_TOUR_STEPS.noop.identifier, OFFICE_TOUR_STEPS.reminder.identifier];

export const DEFAULT_STEPS: TourStep[] = Object.values(OFFICE_TOUR_STEPS).filter(
  (step) => !EXCLUDED_STEPS.includes(step.identifier),
);

export const DEFAULT_LAST_STEP = DEFAULT_STEPS[DEFAULT_STEPS.length - 1].identifier;

export const DIRECTIVE_CLASS_NAMES = {
  disablePointerEvents: 'app-tour-tooltip-directive-disable-pointer-events',
  dim: 'app-tour-tooltip-directive-dim',
  transition: 'app-tour-tooltip-directive-transition',
  backgroundColor: 'app-tour-tooltip-directive-background-color',
};
