import { UserService } from '../../services/user.service';

import { ScheduleAvailability, ScheduleBlock } from '../../models/components/avatar-hover.model';
import { TenantSettings } from '../../models/services/tenant.model';
import { FrontendAvailability, Presence, User } from '../../models/services/user.model';

export function isEligibleForSchedule(data: {
  me: User | null;
  user: { emailAddress?: string; userPrincipalName?: string };
  tenantSettings: TenantSettings | undefined;
}): boolean {
  return (
    Boolean(data.tenantSettings?.featureFlags.avatarHoverAvailability) &&
    (Boolean(data.me?.emailAddress) || Boolean(data.me?.userPrincipalName)) &&
    (Boolean(data.user.emailAddress) || Boolean(data.user.userPrincipalName))
  );
}

export function getNextAvailableTimeSlot(schedule: ScheduleBlock[]): Date | null {
  let nextAvailableTimeSlot: Date | null = null;

  const nowIndex = schedule.findIndex((b) => b.isNow);
  for (const block of schedule.slice(nowIndex + 1)) {
    if (block.availability === ScheduleAvailability.BothFree) {
      nextAvailableTimeSlot = block.startDateTime;
      break;
    }
  }

  return nextAvailableTimeSlot;
}

export function getImpromptuMeetingSubject(userService: UserService): string {
  const me = userService.userInfo!.user;
  const name = me.firstName || me.displayName;

  return name ? `Spontaneous meeting w/ ${name}` : 'Spontaneous meeting';
}

export function mapToPresence(availability: FrontendAvailability): Presence | null {
  switch (availability) {
    case FrontendAvailability.Available:
      return { availability: 'Available', activity: 'Available', iso8601ExpirationDuration: 'PT2H30M' };
    case FrontendAvailability.Away:
      return { availability: 'Away', activity: 'Away', iso8601ExpirationDuration: 'PT2H30M' };
    case FrontendAvailability.BeRightBack:
      return { availability: 'BeRightBack', activity: 'BeRightBack', iso8601ExpirationDuration: 'PT2H30M' };
    case FrontendAvailability.Busy:
      return { availability: 'Busy', activity: 'Busy', iso8601ExpirationDuration: 'PT2H30M' };
    case FrontendAvailability.DoNotDisturb:
      return { availability: 'DoNotDisturb', activity: 'DoNotDisturb', iso8601ExpirationDuration: 'PT2H30M' };
    default:
      return null;
  }
}
