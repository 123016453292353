import { NgModule } from '@angular/core';
import { NgIf, NgTemplateOutlet } from '@angular/common';

import { TooltipComponent } from './tooltip.component';

import { ScaleByOfficePipe } from '../../pipes/scale-by-office.pipe';

import { TooltipDirective } from './tooltip.directive';

@NgModule({
  declarations: [TooltipComponent],
  imports: [NgIf, NgTemplateOutlet, TooltipDirective],
  exports: [TooltipDirective],
  providers: [ScaleByOfficePipe],
})
export class TooltipModule {}
