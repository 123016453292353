import { MeetingParticipants } from '@microsoft/microsoft-graph-types';
import { OnlineMeeting as MSGraphOnlineMeeting } from '@microsoft/microsoft-graph-types';
import { addDays, formatISO } from 'date-fns';

export function createOnlineMeeting(subject: string, inviteUserObjectIds?: string[]): MSGraphOnlineMeeting {
  let participants: MeetingParticipants | undefined;

  if (inviteUserObjectIds) {
    const attendees = inviteUserObjectIds.map((userObjectId) => ({ identity: { user: { id: userObjectId } } }));
    participants = { attendees: attendees };
  }

  const onlineMeeting: MSGraphOnlineMeeting = {
    startDateTime: formatISO(new Date()),
    endDateTime: formatISO(addDays(new Date(), 1)),
    subject,

    allowAttendeeToEnableCamera: true,
    allowAttendeeToEnableMic: true,
    allowTeamworkReactions: true,
    allowMeetingChat: 'enabled',
    allowedPresenters: 'everyone',

    lobbyBypassSettings: { scope: 'organizationAndFederated' },
    participants: participants,
  };

  return onlineMeeting;
}

export function getEntryByEvent<T extends { joinWebUrl: string | null; iCalUId: string | null }>(
  joinWebUrl: string | null,
  iCalUId: string | null,
  onlineMeetings: T[],
): T | null {
  const entry = onlineMeetings.find(
    (onlineMeeting) =>
      (onlineMeeting.joinWebUrl !== null &&
        joinWebUrl !== null &&
        decodeURIComponent(onlineMeeting.joinWebUrl) === decodeURIComponent(joinWebUrl)) ||
      (onlineMeeting.iCalUId !== null && onlineMeeting.iCalUId === iCalUId),
  );

  return entry ?? null;
}
