import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { ChatMessageReaction as MSGraphChatMessageReaction } from '@microsoft/microsoft-graph-types';

import { UserService } from 'src/app/shared/services/user.service';

import { Reaction } from 'src/app/shared/models/services/chat/chat.model';
import { Emoji, MAX_RECENT_EMOJIS } from 'src/app/shared/models/services/emoji.model';

import { mapGraphDateStringToDate } from './chat.util';

import EMOJI_DATA from 'src/assets/fluent-emojis/metadata.json';

// Map from glyph (string) to emoji (Emoji)
export const EMOJIS = new Map<string, Emoji>(Object.entries(EMOJI_DATA));

export const DEFAULT_EMOJIS = {
  like: EMOJIS.get('👍')!,
  heart: EMOJIS.get('♥️')!,
  laugh: EMOJIS.get('😆')!,
  surprised: EMOJIS.get('😮')!,
  sad: EMOJIS.get('🙁')!,
};

export function mapToReactions(
  graphReactions: MSGraphChatMessageReaction[] | undefined,
  userService: UserService,
): Observable<Reaction[]> {
  if (!graphReactions || graphReactions.length === 0) return of([]);

  return forkJoin(graphReactions.map((graphReaction) => mapToReaction(graphReaction, userService))).pipe(
    map((reactions) => reactions.filter((reaction) => Boolean(reaction)) as Reaction[]),
  );
}

export function getRecentlyUsedEmojis(emojis: string[], emoji: string | null): string[] {
  const recentEmojis = Array.from(emojis);

  if (emoji) {
    const index = recentEmojis.indexOf(emoji);

    if (index !== -1) {
      const removedEmoji = recentEmojis.splice(index, 1)[0];

      recentEmojis.unshift(removedEmoji);
    } else {
      recentEmojis.unshift(emoji);

      if (recentEmojis.length > MAX_RECENT_EMOJIS) recentEmojis.pop();
    }
  }

  return recentEmojis;
}

function mapToReaction(
  graphReaction: MSGraphChatMessageReaction,
  userService: UserService,
): Observable<Reaction | undefined> {
  // TODO: Do complete mapping here? F.e. count and userObjectIds are mapped later in the code (not ideal)
  if (!graphReaction.user?.user?.id) return of(undefined);

  return userService.getCachedOrFreshGraphUser(graphReaction.user.user.id).pipe(
    catchError(() =>
      of({ displayName: graphReaction.user!.user!.displayName, firstName: undefined, lastName: undefined }),
    ),
    map((graphUser) => {
      return {
        userObjectId: graphReaction!.user!.user!.id!,
        userDisplayName: graphUser?.displayName ? graphUser.displayName : graphReaction.user!.user!.displayName!,
        userFirstName: graphUser?.firstName,
        userLastName: graphUser?.lastName,

        reactionType: mapToReactionType(graphReaction.reactionType!),
        createdDateTime: mapGraphDateStringToDate(graphReaction.createdDateTime!),
        emoji: mapToEmoji(graphReaction.reactionType),
        users: [],
        count: 0,
      };
    }),
  );
}

function mapToReactionType(reactionType: string): string {
  if (reactionType === 'like') return '👍';
  if (reactionType === 'heart') return '♥️';
  if (reactionType === 'laugh') return '😆';
  if (reactionType === 'surprised') return '😮';
  if (reactionType === 'sad') return '🙁';

  return reactionType;
}

function mapToEmoji(reactionType: string | undefined): Emoji {
  if (reactionType === 'like') return EMOJIS.get('👍')!;
  if (reactionType === 'heart') return EMOJIS.get('♥️')!;
  if (reactionType === 'laugh') return EMOJIS.get('😆')!;
  if (reactionType === 'surprised') return EMOJIS.get('😮')!;
  if (reactionType === 'sad') return EMOJIS.get('🙁')!;

  if (reactionType && EMOJIS.get(reactionType)) return EMOJIS.get(reactionType)!;

  return EMOJIS.get('❓')!;
}
