import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgStyle, UpperCasePipe } from '@angular/common';

import { TranslocoModule } from '@ngneat/transloco';

import { EmojisComponent } from '../../../emojis/emojis.component';

import { Emoji } from 'src/app/shared/models/services/emoji.model';

@Component({
  selector: 'app-emoji-picker-hover',
  standalone: true,
  templateUrl: './emoji-picker-hover.component.html',
  styleUrls: ['./emoji-picker-hover.component.scss'],
  imports: [NgStyle, TranslocoModule, UpperCasePipe, EmojisComponent],
})
export class EmojiPickerHoverComponent {
  @Input() public emojiSelected = false;

  @Output() public emojiChange = new EventEmitter<string | null | undefined>();

  public onEmojiChanged(emoji?: Emoji | null) {
    const output = emoji === undefined ? undefined : emoji?.glyph ?? null;

    this.emojiChange.emit(output);
  }
}
