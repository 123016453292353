import { HashMap } from '@ngneat/transloco';

import { JoinWebUrlContext, SubjectData } from '../../models/components/meeting.model';
import { Call, CallContext, CallParticipant } from '../../models/services/call.model';
import { Location, UserInfo } from '../../models/services/user.model';

import { parseJson } from '../misc.util';

import { TRANSLATION_KEYS } from '../../constants';

export function getTenantObjectIdByJoinWebUrl(joinWebUrl: string): string {
  const context = new URL(joinWebUrl).searchParams.get('context');
  if (!context) throw new Error('Context of meeting can not be mull');

  return parseJson<JoinWebUrlContext>(context).Tid;
}

export function getChatIdByJoinWebUrl(joinWebUrl: string): string | null {
  try {
    const url = new URL(joinWebUrl);
    const pathSegments = url.pathname.split('/');
    const indexBeforeThreadId = pathSegments.findIndex((segment) => segment === 'meetup-join');

    if (indexBeforeThreadId === -1 || indexBeforeThreadId >= pathSegments.length - 1) {
      throw new Error(`Invalid joinWebUrl: ${joinWebUrl}`);
    }

    const chatThreadIdSegment = pathSegments[indexBeforeThreadId + 1];
    const chatThreadId = decodeURIComponent(chatThreadIdSegment.replace(/\/$/, ''));

    return chatThreadId;
  } catch (error) {
    return null;
  }
}

export function sortMeetings(myUserObjectId: string, meetings: Call[], context: CallContext): void {
  meetings.sort((left: Call, right: Call): number => {
    if (context === CallContext.BreakoutMeeting) {
      return left.breakoutMeetingData!.index - right.breakoutMeetingData!.index;
    }

    if (context === CallContext.TopicMeeting) {
      return left.topicMeetingData!.index - right.topicMeetingData!.index;
    }

    // Sort empty meetings to the top
    if (left.participants.length == 0 && right.participants.length == 0) return 0;
    if (left.participants.length == 0) return -1;
    if (right.participants.length == 0) return 1;

    // Sort my meeting after empty meetings
    if (left.participants.some((p) => p.userObjectId === myUserObjectId)) return -1;
    if (right.participants.some((p) => p.userObjectId === myUserObjectId)) return 1;

    return left.identifier < right.identifier ? -1 : 1;
  });
}

export function meetingShouldBeShown(userInfo: UserInfo, call: Call): boolean {
  const me = userInfo.user;
  const optOutMeetingOverview = userInfo.settings.optOutMeetingOverview;

  if (call.context === CallContext.TopicMeeting) return true;

  if (call.context === CallContext.BreakoutMeeting) return call.participants.length > 0;

  const myHashedEmailAddress = me.hashedEmailAddress;
  const myUserObjectId = me.objectId;
  const myCurrentTeamObjectId = me.currentTeamObjectId;

  const anyParticipantsPresent = call.participants.length > 0;
  const iAmAttendeeImpromptu = call.impromptuMeetingData?.attendeeUserObjectsIds.includes(myUserObjectId);
  const iAmAttendeeScheduled = call.scheduledMeetingData?.hashedAttendeeEmailAddresses.some(
    (hashedAttendeeEmailAddress) => hashedAttendeeEmailAddress === myHashedEmailAddress,
  );

  const isActiveAndHasSelectedMyTeam = (participant: CallParticipant): boolean =>
    participant.baseLocation !== Location.Inactive && participant.selectedTeamObjectId === myCurrentTeamObjectId;

  const anyParticipantIsActiveAndHasSelectedMyTeam = call.participants.some((p) => isActiveAndHasSelectedMyTeam(p));
  const iAmAttendee = Boolean(iAmAttendeeImpromptu || iAmAttendeeScheduled);

  return (
    (!optOutMeetingOverview && anyParticipantIsActiveAndHasSelectedMyTeam) || (anyParticipantsPresent && iAmAttendee)
  );
}

export function getSubjectData(call: Call, organizerDisplayName?: string): SubjectData {
  let translationKey = TRANSLATION_KEYS.meeting.runningMeeting;
  let params: HashMap | undefined = undefined;
  let subject = call.scheduledMeetingData?.subject;

  if (call.context === CallContext.ImpromptuMeeting) {
    translationKey = TRANSLATION_KEYS.meeting.impromptuMeetingSubject;
    subject = call.impromptuMeetingData!.subject;
    params = {
      displayName: organizerDisplayName,
    };
  } else if (call.context === CallContext.BreakoutMeeting) {
    translationKey = TRANSLATION_KEYS.meeting.breakoutMeetingSubject;
    subject = call.breakoutMeetingData!.subject;
    params = {
      index: call.breakoutMeetingData!.index + 1,
    };
  }

  return { subject, translationKey: translationKey, params };
}

export function removeOptedOutParticipants(call: Call, hashedEmailAddress: string): Call {
  const iAmAttendee = call.scheduledMeetingData?.hashedAttendeeEmailAddresses.includes(hashedEmailAddress);

  if (iAmAttendee) return { ...call };

  return {
    ...call,
    participants: (call.participants = call.participants.filter((participant) => !participant.optOutMeetingOverview)),
  };
}
