import { concatMap, from, Observable, of } from 'rxjs';
import { Dexie, Table } from 'dexie';

import { AuthData, BackendData, GeneralData, GraphData, PrimaryKey, PrimaryKeyAuth } from '../models/cache.model';
import { Chat } from '../models/services/chat/chat.model';

import { getInitialGeneralData, getInitialGraphData } from '../utils/indexed-db.util';
import { minBy } from '../utils/misc.util';
import { sortChatsByRecency } from '../utils/services/chat/chat.util';

import {
  migrateChatIntegration,
  migrateCubicles,
  migrateEmojiPicker,
  migrateEvents,
  migrateRemoveDisableLastUserLocationReload,
  migrateRemoveReadUserStatuses,
  migrateRemoveRecentEmojis,
  migrateSidebarFilesIntegration,
  migrateTeamSettings,
  migrateTodos,
  migrateUnreadStatusBadge,
} from './migrations';

export class IndexedDB extends Dexie {
  public auth!: Table<AuthData, PrimaryKeyAuth>;
  public general!: Table<GeneralData, PrimaryKey>;
  public backend!: Table<BackendData, PrimaryKey>;
  public graph!: Table<GraphData, PrimaryKey>;

  public constructor() {
    super('appData');

    migrateChatIntegration(this);
    migrateSidebarFilesIntegration(this);
    migrateUnreadStatusBadge(this);
    migrateEmojiPicker(this);
    migrateTodos(this);
    migrateTeamSettings(this);
    migrateEvents(this);
    migrateCubicles(this);
    migrateRemoveReadUserStatuses(this);
    migrateRemoveRecentEmojis(this);
    migrateRemoveDisableLastUserLocationReload(this);
  }

  // Insert default data if not present to simplify updates
  public initialize(primaryKey: PrimaryKey): Observable<PrimaryKey | number> {
    return from(this.general.get(primaryKey)).pipe(
      concatMap((generalData) =>
        generalData ? of(null) : this.general.add(getInitialGeneralData(primaryKey), primaryKey),
      ),
      concatMap(() => this.backend.get(primaryKey)),
      concatMap((backendData) => (backendData ? of(null) : this.backend.add({}, primaryKey))),
      concatMap(() => this.graph.get(primaryKey)),
      concatMap((graphData) => {
        if (!graphData) return from(this.graph.add(getInitialGraphData(), primaryKey));

        const cleanedChats = this.cleanChats(graphData.chats);

        return this.graph.update(primaryKey, { chats: cleanedChats });
      }),
    );
  }

  private cleanChats(chats: Chat[]): Chat[] {
    // Only keep the 50 most recent chats cached
    const mostRecentChats = chats.sort(sortChatsByRecency).slice(0, 50);

    // Ensure that the open chats have a continuous order
    const openChats = mostRecentChats.filter((chat) => chat.metadata.order !== undefined);
    for (let order = 0; order < openChats.length; order++) {
      const chatWithLowestOrder = minBy(
        openChats.filter((chat) => chat.metadata.order! >= order),
        (minElement, otherElement) => otherElement.metadata.order! < minElement.metadata.order!,
      );

      if (!chatWithLowestOrder || chatWithLowestOrder.metadata.order === undefined) break;

      chatWithLowestOrder.metadata.order = order;
    }

    return mostRecentChats;
  }
}
