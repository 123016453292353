import { catchError, concatMap, forkJoin, from, map, Observable, of } from 'rxjs';
import {
  AadUserConversationMember,
  ChatMessage as MSGraphChatMessage,
  ChatMessageAttachment,
  ChatMessageAttachment as MSGraphChatMessageAttachment,
  ChatMessageHostedContent,
  ChatMessageInfo as MSGraphChatMessageInfo,
  ChatType,
  Identity,
} from '@microsoft/microsoft-graph-types';
import { TranslocoService } from '@ngneat/transloco';
import linkifyHtml from 'linkify-html';
import { stripHtml } from 'string-strip-html';

import { AuthService } from 'src/app/shared/services/auth.service';
import { ChatService } from 'src/app/shared/services/chat.service';
import { UserService } from 'src/app/shared/services/user.service';

import { ChatMessageInfo } from 'src/app/shared/models/services/chat/chat.model';
import {
  Attachment,
  AttachmentContentType,
  ChatMessage,
  EventMessageDetail,
  MessageContentType,
  MessageData,
  MSGraphEventMessageDetail,
  PendingMessage,
  ReplyAttachmentData,
  ReplyToData,
  SystemEventType,
} from 'src/app/shared/models/services/chat/message.model';
import { GraphUserBaseData } from 'src/app/shared/models/services/user.model';

import { mapGraphDateStringToDate } from './chat.util';
import { mapToReactions } from './emoji.util';

import { GRAPH_API_BASE, TRANSLATION_KEYS } from 'src/app/shared/constants';
import { environment } from 'src/environments/environment';

export interface GetChatMessagesOptions {
  messageCount: number;
  lastMessageRead?: Date;
  nextLink?: string;
}

export function mapToChatMessage(
  graphChatMessage: MSGraphChatMessage,
  chatMembers: AadUserConversationMember[],
  userService: UserService,
): Observable<ChatMessage> {
  const messageData = getChatMessageData(graphChatMessage);

  return mapToReactions(graphChatMessage.reactions ?? undefined, userService).pipe(
    concatMap((reactions) =>
      mapToEventMessageDetail(graphChatMessage, chatMembers, userService).pipe(
        map((eventDetail) => ({ reactions, eventDetail })),
      ),
    ),
    map(({ reactions, eventDetail }) => ({
      id: parseFloat(graphChatMessage.id!),
      chatId: graphChatMessage.chatId!,
      createdDateTime: mapGraphDateStringToDate(graphChatMessage.createdDateTime!),
      deletedDateTime: mapGraphDateStringToDate(graphChatMessage.deletedDateTime ?? undefined),
      message: graphChatMessage.body?.content ?? '',
      parsedMessage: messageData.parsedMessage,
      contentType: messageData.contentType,
      fromUserObjectId: graphChatMessage.from?.user?.id ?? undefined,
      fromUserDisplayName: graphChatMessage.from?.user?.displayName ?? undefined,
      reactions,
      eventDetail,
      attachments: mapToAttachments(graphChatMessage.attachments ?? []),
    })),
  );
}

export function createMessageData(
  message: string,
  replyData: ReplyToData | undefined,
  chatService: ChatService,
  translocoService: TranslocoService,
): Observable<MessageData> {
  let formattedMessage = linkifyHtml(message, {
    defaultProtocol: 'https',
    target: { url: '_blank' },
  });

  const graphAttachments: ChatMessageAttachment[] = [];
  let attachments: Attachment[] = [];
  const hostedContents: ChatMessageHostedContent[] = [];

  if (replyData) {
    const attachmentId = crypto.randomUUID();
    graphAttachments.push(
      createReplyAttachment(
        attachmentId,
        replyData.messageId,
        replyData.fromUserObjectId,
        replyData.fromUserDisplayName,
        replyData.parsedMessage,
      ),
    );

    formattedMessage = `<attachment id="${attachmentId}"></attachment> ${formattedMessage}`;

    attachments = mapToAttachments(graphAttachments);
  }

  const dom = new DOMParser().parseFromString(formattedMessage, 'text/html');
  const imageTags = Array.from(dom.querySelectorAll('img'));

  if (imageTags.length === 0) {
    return of({
      body: { contentType: 'html', content: dom.body.innerHTML },
      graphAttachments,
      attachments,
      hostedContents: [],
    });
  }

  // Read image dimensions so that we can appropriately size the image
  // Images that are placed with URLs may or may not be accessible, they need to be either converted to dataUrls or <a> tags
  const getImageDimensions = (
    imageSource: string,
  ): Observable<{ width: number; height: number; isFailedLink: boolean } | undefined> => {
    const getFromDataUrl = (dataUrl: string) =>
      from(
        new Promise<{ width: number; height: number; isFailedLink: boolean }>((resolve, reject) => {
          const image = new Image();
          image.setAttribute('crossOrigin', 'anonymous');

          image.onload = () => resolve({ width: image.width, height: image.height, isFailedLink: false });
          image.onabort = () => reject();
          image.onerror = () => reject();

          image.src = dataUrl;
        }),
      );

    if (imageSource.startsWith('data:')) {
      return getFromDataUrl(imageSource).pipe(catchError(() => of(undefined)));
    } else {
      return chatService.getImage(imageSource).pipe(
        concatMap((imageBlob) => {
          if (!imageBlob) return of(undefined);

          return getFromDataUrl(imageBlob);
        }),
        catchError(() => of(undefined)),
      );
    }
  };

  const imagesWithDimensions$ = imageTags.map((image) =>
    getImageDimensions(image.src).pipe(
      map((imageWithDimensions) =>
        imageWithDimensions
          ? {
              image,
              dimensions: { width: imageWithDimensions.width, height: imageWithDimensions.height },
              isFailedLink: imageWithDimensions.isFailedLink,
            }
          : { image, dimensions: undefined, isFailedLink: true },
      ),
    ),
  );

  return forkJoin(imagesWithDimensions$).pipe(
    map((imagesWithDimensions) => {
      for (const imageWithDimensions of imagesWithDimensions) {
        const { image, dimensions, isFailedLink } = imageWithDimensions;

        // If the image is not a data url it does not need to be uploaded as hosted content
        if (isFailedLink) {
          const anchorElement = document.createElement('a');
          anchorElement.href = image.src;
          anchorElement.target = '_blank';
          anchorElement.innerText = translocoService.translate(TRANSLATION_KEYS.chat.message.failedImageLink);

          image.replaceWith(anchorElement);

          continue;
        }

        if (dimensions) {
          image.setAttribute('width', dimensions.width.toString());
          image.setAttribute('height', dimensions.height.toString());
        }

        if (!image.src.startsWith('data:')) continue;

        const index = hostedContents.length;

        const imageBase64 = image.src.replace(/data:.*?;base64,/, '');
        const hostedContent = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '@microsoft.graph.temporaryId': index.toString(),
          contentBytes: imageBase64,
          contentType: 'image/jpeg',
        };

        hostedContents.push(hostedContent);

        image.setAttribute('src', `../hostedContents/${index}/$value`);
      }

      return {
        body: { contentType: 'html', content: dom.body.innerHTML },
        graphAttachments,
        attachments,
        hostedContents,
      };
    }),
  );
}

function createReplyAttachment(
  attachmentId: string,
  messageId: number,
  userObjectId: string,
  userDisplayName: string | undefined,
  parsedMessage: string,
): ChatMessageAttachment {
  // The preview cannot contain HTML tags, ensure that it is stripped
  const messagePreview = stripHtml(parsedMessage).result;

  return {
    id: attachmentId,
    contentType: 'messageReference',
    content: JSON.stringify({
      messageId,
      messagePreview,
      messageSender: {
        application: null,
        device: null,
        user: {
          userIdentityType: 'aadUser',
          id: userObjectId,
          displayName: userDisplayName ?? 'required',
        },
      },
    }),
  };
}

export function createInvitationAdaptiveCard(
  teamDisplayName: string,
  userService: UserService,
  authService: AuthService,
  translocoService: TranslocoService,
): Observable<unknown> {
  const attachmentId = crypto.randomUUID();

  return userService.getCachedOrFreshGraphUser(authService.context!.user!.id).pipe(
    map((graphUser) => {
      const displayName = graphUser?.displayName || '☝';
      const firstOrLastName = graphUser?.firstName || displayName;

      const title = translocoService.translate(TRANSLATION_KEYS.chat.adaptiveCard.bNearInvitation.title, {
        displayName: firstOrLastName,
      });
      const description1 = translocoService.translate(TRANSLATION_KEYS.chat.adaptiveCard.bNearInvitation.description1);
      const description2 = translocoService.translate(TRANSLATION_KEYS.chat.adaptiveCard.bNearInvitation.description2, {
        userDisplayName: displayName,
        teamDisplayName,
      });
      const imageAlt = translocoService.translate(TRANSLATION_KEYS.chat.adaptiveCard.bNearInvitation.imageAlt);
      const openApp = translocoService.translate(TRANSLATION_KEYS.chat.adaptiveCard.bNearInvitation.openApp);

      const appDeeplink = `https://teams.microsoft.com/l/app/5d655b39-963c-465a-89ab-bdad7ab7af7f?tenantId=${
        authService.context!.user!.tenant!.id
      }`;

      return {
        body: {
          contentType: 'html',
          content: `<attachment id="${attachmentId}"></attachment>`,
        },
        attachments: [
          {
            id: attachmentId,
            contentType: 'application/vnd.microsoft.card.adaptive',
            content: `{
            "type": "AdaptiveCard",
            "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
            "version": "1.5",
            "body": [
              {
                  "type": "TextBlock",
                  "text": "${title}",
                  "wrap": true,
                  "size": "Default",
                  "weight": "Bolder"
              },
              {
                  "type": "TextBlock",
                  "text": "${description1}",
                  "wrap": true
              },
              {
                  "type": "Image",
                  "url": "${environment.blobStorageBaseUri}/app-assets/adaptive-card-images/bNear_500p.png",
                  "altText": "${imageAlt}"
              },
              {
                  "type": "TextBlock",
                  "text": "${description2}",
                  "wrap": true,
                  "weight": "Bolder"
              }
            ],
            "actions": [
                {
                  "type": "Action.OpenUrl",
                  "title": "${openApp}",
                  "url": "${appDeeplink}"
                }
              ]
            }`,
          },
        ],
      };
    }),
  );
}

export function mapToPendingMessage(data: MessageData, createdDate: Date, temporaryId: string): PendingMessage {
  return {
    temporaryId,

    createdDate,
    sendingFailed: false,

    parsedMessage: parseMessage(data.body.content),
    data,
  };
}

export interface GetChatMessagesOptions {
  messageCount: number;
  lastMessageRead?: Date;
  nextLink?: string;
}

export function createGetChatMessagesEndpoint(chatId: string, options: GetChatMessagesOptions): string {
  if (options.nextLink) return options.nextLink;

  const baseUrl = `${GRAPH_API_BASE}/me/chats/${chatId}/messages`;
  const top = `$top=${options.messageCount}`;
  const orderBy = `$orderBy=lastModifiedDateTime desc`;

  let endpoint = `${baseUrl}?${top}&${orderBy}`;

  if (options.lastMessageRead) {
    const filter = `$filter=lastModifiedDateTime gt ${options.lastMessageRead.toISOString()}`;
    endpoint += `&${filter}`;
  }

  return endpoint;
}

export function sortChatsMessagesByCreatedDateTime(left: ChatMessage, right: ChatMessage): number {
  if (!left.createdDateTime) return -1;
  if (!right.createdDateTime) return 1;

  if (left.createdDateTime >= right.createdDateTime) return 1;

  return -1;
}

export function filterChatMessages(
  chatMessages: ChatMessage[],
  chatType: ChatType,
  lastMessageRead?: Date,
  removeSystemMessages = false,
  removeByUserObjectId?: string,
): ChatMessage[] {
  const filterMembersAddedInOneOnOneChat = (chatMessage: ChatMessage): boolean =>
    chatType !== 'oneOnOne' ||
    (chatMessage.eventDetail?.type !== 'memberAdded' && chatMessage.eventDetail?.type !== 'membersAdded');

  // Note that the messages are sorted in reverse order
  const filterCallStartedIfFollowedByEnded = (chatMessage: ChatMessage, index: number): boolean => {
    if (index === 0) return true;
    if (chatMessage.eventDetail?.type !== 'callStarted') return true;

    const nextMessage = chatMessages[index - 1];
    return !nextMessage.eventDetail || nextMessage.eventDetail.type !== 'callEnded';
  };

  return chatMessages.filter((chatMessage, index) => {
    return (
      // Remove deleted messages
      !chatMessage.deletedDateTime &&
      filterMembersAddedInOneOnOneChat(chatMessage) &&
      filterCallStartedIfFollowedByEnded(chatMessage, index) &&
      // Optionally remove messages that were read
      (!lastMessageRead || (chatMessage.createdDateTime && chatMessage.createdDateTime > lastMessageRead)) &&
      // Optionally remove system messages
      (!removeSystemMessages || chatMessage.contentType !== 'systemEvent') &&
      // Optionally remove messages by user
      (!removeByUserObjectId || chatMessage.fromUserObjectId !== removeByUserObjectId)
    );
  });
}

export function mapToChatMessageInfo(
  chatMessageInfo: MSGraphChatMessageInfo | undefined,
  userService: UserService,
): Observable<ChatMessageInfo | undefined> {
  let messageContent = chatMessageInfo?.body?.content;
  const contentType = chatMessageInfo?.body?.contentType;

  if (!messageContent || !contentType) return of(undefined);

  const fromUser = chatMessageInfo.from?.user;
  if (!fromUser?.id) return of(undefined);

  if (contentType === 'html') messageContent = stripHtml(messageContent).result;

  return userService.getCachedOrFreshGraphUser(fromUser.id).pipe(
    catchError(() => of({ displayName: fromUser.displayName, firstName: undefined, lastName: undefined })),
    map((graphUser) => {
      return {
        id: chatMessageInfo.id!,
        createdDateTime: mapGraphDateStringToDate(chatMessageInfo.createdDateTime ?? undefined),
        parsedMessage: messageContent!,

        fromUserObjectId: fromUser.id as string,
        fromUserDisplayName: graphUser?.displayName ? graphUser.displayName : fromUser.displayName!,
        fromUserFirstName: graphUser?.firstName,
        fromUserLastName: graphUser?.lastName,
      };
    }),
  );
}

function parseMessage(message: string): string {
  return stripHtml(message, {
    onlyStripTags: ['script'],
    skipHtmlDecoding: true,
  }).result;
}

function getChatMessageData(graphMessage: MSGraphChatMessage): {
  parsedMessage: string;
  contentType: MessageContentType;
} {
  const content = graphMessage.body!.content!;
  const contentType = graphMessage.body!.contentType!;

  const messageDom = new DOMParser().parseFromString(content, 'text/html');
  if (messageDom.querySelector('video')) {
    return { parsedMessage: content, contentType: 'unsupportedMessage' };
  }

  messageDom.querySelectorAll('a').forEach((link) => link.setAttribute('target', '_blank'));
  const parsedMessage = parseMessage(messageDom.body.innerHTML);

  if (contentType === 'html') {
    if (graphMessage.attachments && graphMessage.attachments.length > 0) {
      if (
        graphMessage.attachments.some(
          (attachment) => attachment.contentType === 'messageReference' || attachment.contentType === 'reference',
        )
      ) {
        return { parsedMessage, contentType: 'html' };
      }

      return { parsedMessage, contentType: 'unsupportedMessage' };
    } else if (graphMessage.eventDetail) return { parsedMessage, contentType: 'systemEvent' };
  }

  return { parsedMessage, contentType: 'html' };
}

function getSystemEventType(graphMessage: MSGraphChatMessage): SystemEventType {
  if (!(graphMessage.eventDetail && '@odata.type' in graphMessage.eventDetail)) return 'unknownSystemEvent';

  const eventDetail = graphMessage.eventDetail as MSGraphEventMessageDetail;

  switch (graphMessage.eventDetail['@odata.type']) {
    case '#microsoft.graph.callStartedEventMessageDetail':
      return 'callStarted';
    case '#microsoft.graph.callEndedEventMessageDetail':
      return 'callEnded';
    case '#microsoft.graph.chatRenamedEventMessageDetail':
      return 'chatRenamed';
    case '#microsoft.graph.membersAddedEventMessageDetail':
      return eventDetail.members?.length === 1 ? 'memberAdded' : 'membersAdded';
    case '#microsoft.graph.membersDeletedEventMessageDetail':
      return 'membersDeleted';
    case '#microsoft.graph.membersJoinedEventMessageDetail':
      return 'memberInvited';
    case '#microsoft.graph.teamsAppInstalledEventMessageDetail':
      return 'teamsAppInstalled';
    default:
      return 'unknownSystemEvent';
  }
}

function mapToEventMessageDetail(
  graphChatMessage: MSGraphChatMessage,
  chatMembers: AadUserConversationMember[],
  userService: UserService,
): Observable<EventMessageDetail | undefined> {
  if (!graphChatMessage.eventDetail) return of(undefined);

  const userObjectIds = new Set<string>();

  // Get all userObjectIds to fetch displayNames
  const eventDetail = graphChatMessage.eventDetail as MSGraphEventMessageDetail;
  if (eventDetail.initiator && eventDetail.initiator.user?.id) {
    userObjectIds.add(eventDetail.initiator.user.id);
  }

  if (eventDetail.members) {
    eventDetail.members.forEach((member) => userObjectIds.add(member.id!));
  } else if (eventDetail.callParticipants) {
    eventDetail.callParticipants = eventDetail.callParticipants.filter((info) => info.participant?.user?.id);
    eventDetail.callParticipants.forEach((info) => userObjectIds.add(info.participant!.user!.id!));
  }

  const userObjectIdsArray = [...userObjectIds.keys()];

  const graphUsers =
    userObjectIdsArray.length === 0
      ? of([])
      : forkJoin(
          userObjectIdsArray.map((userObjectId) =>
            userService.getCachedOrFreshGraphUser(userObjectId).pipe(map((graphUser) => ({ userObjectId, graphUser }))),
          ),
        );

  return graphUsers.pipe(
    map((graphUsers) => {
      const eventType = getSystemEventType(graphChatMessage);
      const eventDetail = graphChatMessage.eventDetail as MSGraphEventMessageDetail | undefined;

      let initiator: { userObjectId: string; displayName: string } | undefined;
      if (eventDetail?.initiator?.user?.id) {
        const displayName = getDisplayName(eventDetail.initiator.user, graphUsers, chatMembers!);
        initiator = {
          userObjectId: eventDetail.initiator.user.id,
          displayName,
        };
      }

      let users: { userObjectId: string; displayName: string }[] = [];
      if (eventDetail?.members) {
        users = eventDetail.members.map((member) => {
          const displayName = getDisplayName(member, graphUsers, chatMembers);
          return {
            userObjectId: member.id!,
            displayName,
          };
        });
      } else if (eventDetail?.callParticipants) {
        users = eventDetail.callParticipants.map((callParticipant) => {
          const displayName = getDisplayName(callParticipant.participant!.user!, graphUsers, chatMembers);
          return {
            userObjectId: callParticipant.participant!.user!.id!,
            displayName,
          };
        });
      }

      const callDuration = eventDetail?.callDuration ?? undefined;
      const chatDisplayName = eventDetail?.chatDisplayName ?? undefined;
      const teamsAppDisplayName = eventDetail?.teamsAppDisplayName ?? undefined;

      return {
        type: eventType,
        users,
        initiator,
        callDuration,
        chatDisplayName,
        teamsAppDisplayName,
      };
    }),
  );
}

function mapToAttachments(graphAttachments: MSGraphChatMessageAttachment[]): Attachment[] {
  const attachments: Attachment[] = [];

  for (const attachment of graphAttachments) {
    let contentType: AttachmentContentType;
    switch (attachment.contentType) {
      case 'reference':
      case 'messageReference':
        contentType = attachment.contentType;
        break;
      default:
        contentType = 'unsupportedAttachment';
        break;
    }

    let replyAttachment: ReplyAttachmentData | undefined;
    if (contentType === 'messageReference') {
      const replyData = JSON.parse(attachment.content!);
      replyAttachment = {
        messageId: replyData.messageId,
        messagePreview: replyData.messagePreview,
        fromUserObjectId: replyData.messageSender.user.id,
        fromUserDisplayName: replyData.messageSender.user.displayName,
      };
    }

    attachments.push({
      id: attachment.id!,
      contentType,
      content: replyAttachment,
      name: attachment.name ?? null,
      contentUrl: attachment.contentUrl ?? null,
    });
  }

  return attachments;
}

function getDisplayName(
  identity: Identity,
  graphUsers: { userObjectId: string; graphUser: GraphUserBaseData | null }[],
  fallbackChatMembers: AadUserConversationMember[],
) {
  const graphUser = graphUsers.find((graphUser) => graphUser.userObjectId === identity.id)?.graphUser;

  if (!graphUser) {
    const chatMember = fallbackChatMembers.find((chatMember) => chatMember.userId === identity.id);
    if (chatMember) {
      return chatMember.user?.givenName && chatMember.user.surname
        ? `${chatMember.user.givenName} ${chatMember.user.surname}`
        : chatMember.displayName || identity.displayName || '';
    }

    return identity.displayName || '';
  }

  const { firstName, lastName, displayName } = graphUser;
  return firstName && lastName ? `${firstName} ${lastName}` : displayName ?? '';
}
