/**
 * @name string-collapse-leading-whitespace
 * @fileoverview Collapse the leading and trailing whitespace of a string
 * @version 7.0.7
 * @author Roy Revelt, Codsen Ltd
 * @license MIT
 * {@link https://codsen.com/os/string-collapse-leading-whitespace/}
 */

var a = "7.0.7";
var E = a;
function D(n, u = 1) {
  let $ = "\xA0";
  function g(e) {
    return Array.from(e).reverse().join("");
  }
  function p(e, s, r) {
    let t = r ? `
` : "\r",
      i = r ? "\r" : `
`;
    if (!e) return e;
    let m = 0,
      c = 0,
      o = "";
    for (let l = 0, f = e.length; l < f; l++) (e[l] === t || e[l] === i && e[l - 1] !== t) && c++, `\r
`.includes(e[l]) || e[l] === $ ? (m = 0, e[l] === $ ? o += e[l] : e[l] === t ? c <= s && (o += e[l], e[l + 1] === i && (o += e[l + 1], l++)) : e[l] === i && e?.[l - 1] !== t && c <= s && (o += e[l])) : (m++, !e[l + 1] && !c && (o += " "));
    return o;
  }
  if (typeof n == "string" && n.length) {
    let e = 1;
    typeof +u == "number" && Number.isInteger(+u) && +u >= 0 && (e = +u);
    let s = "",
      r = "";
    if (!n.trim()) s = n;else if (!n[0].trim()) {
      for (let t = 0, i = n.length; t < i; t++) if (n[t].trim()) {
        s = n.slice(0, t);
        break;
      }
    }
    if (n.trim() && (n.slice(-1).trim() === "" || n.slice(-1) === $)) {
      for (let t = n.length; t--;) if (n[t].trim()) {
        r = n.slice(t + 1);
        break;
      }
    }
    return `${p(s, e, !1)}${n.trim()}${g(p(g(r), e, !0))}`;
  }
  return n;
}
export { D as collWhitespace, E as version };