import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'scaleByOffice', pure: true, standalone: true })
export class ScaleByOfficePipe implements PipeTransform {
  public transform(value: string): string {
    const regex = /scale-by-office\(([^)]+)\)/g;
    const transformedValue = value.replace(regex, (match, args) => {
      const [baseValue, argsMinValue, argsMaxValue] = args.split(', ').map((arg: string) => arg.trim());
      const minValue = argsMinValue?.replace('$min-value: ', '');
      const maxValue = argsMaxValue?.replace('$max-value: ', '');

      if (!maxValue) {
        if (!minValue) return `calc(${baseValue} * var(--scale-factor))`;

        return `max(calc(${baseValue} * var(--scale-factor)), ${minValue})`;
      }

      if (!minValue) return `min(calc(${baseValue} * var(--scale-factor)), ${maxValue})`;

      return `max(min(calc(${baseValue} * var(--scale-factor)), ${maxValue}), ${minValue})`;
    });

    return transformedValue;
  }
}
