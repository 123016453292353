import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AsyncPipe, NgClass, NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  withLatestFrom,
} from 'rxjs';
import { TranslocoModule } from '@ngneat/transloco';

import { ButtonComponent } from '../button/button.component';
import { SearchComponent } from '../search/search.component';
import { EmojiComponent } from './emoji/emoji.component';
import { EmojiCategoryComponent } from './emoji-category/emoji-category.component';
import { EmojiGroupComponent } from './emoji-group/emoji-group.component';

import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

import { GroupEmojisPipe } from './pipes/group-emojis.pipe';

import { CATEGORY_ORDER, Emoji, Group, UNSUPPORTED_CHAT_EMOJIS } from '../../models/services/emoji.model';

import { filterNullishArray } from '../../utils/misc.util';
import { filterNullish } from '../../utils/rxjs.util';
import { scrollToElement } from '../../utils/scroll.util';
import { EMOJIS } from '../../utils/services/chat/emoji.util';

@Component({
  selector: 'app-emojis',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    AsyncPipe,
    UpperCasePipe,
    EmojiComponent,
    EmojiCategoryComponent,
    EmojiGroupComponent,
    SearchComponent,
    GroupEmojisPipe,
    TranslocoModule,
    ButtonComponent,
  ],
  templateUrl: './emojis.component.html',
  styleUrls: ['./emojis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojisComponent implements AfterViewInit {
  private readonly userService = inject(UserService);
  private readonly destroyRef = inject(DestroyRef);

  private readonly searchValueSubject = new BehaviorSubject<string | null>(null);

  private readonly allEmojis$ = of(Array.from(EMOJIS.values())).pipe(
    map((emojis) => {
      if (this.filterUnsupported) {
        return emojis.filter((emoji) => !UNSUPPORTED_CHAT_EMOJIS.includes(emoji.glyph));
      }

      return emojis;
    }),
    shareReplay(1),
  );

  private readonly recentEmojis$ = this.userService.userInfo$.pipe(
    map((userInfo) => userInfo?.recentEmojis ?? []),
    withLatestFrom(this.allEmojis$),
    map(([recentEmojis, allEmojis]) =>
      recentEmojis
        .map((recentEmoji) => {
          const found = allEmojis.find((emoji) => emoji.glyph === recentEmoji);
          return found
            ? {
                ...found,
                group: Group.Recent,
              }
            : null;
        })
        .filter(filterNullishArray),
    ),
  );

  private readonly searchedEmojis$ = combineLatest([this.allEmojis$, this.searchValueSubject]).pipe(
    map(([emojis, search]) =>
      search
        ? emojis.filter((emoji) =>
            emoji.keywords.some((keyword) => keyword.toLocaleLowerCase().startsWith(search.toLocaleLowerCase())),
          )
        : null,
    ),
    shareReplay(1),
  );

  public readonly authService = inject(AuthService);

  public readonly emojis$ = combineLatest([this.allEmojis$, this.recentEmojis$, this.searchedEmojis$]).pipe(
    map(([emojis, recentEmojis, searchedEmojis]) => searchedEmojis ?? [...recentEmojis, ...emojis]),
  );

  public readonly categories$ = this.emojis$.pipe(
    map((emojis) => {
      const category = new Set<string>();

      for (const emoji of emojis) {
        category.add(emoji.group);
      }

      return Array.from(category).sort((a, b) => CATEGORY_ORDER.indexOf(a) - CATEGORY_ORDER.indexOf(b));
    }),
  );

  public selectedCategory$!: Observable<string>;
  public loadedCategoriesCount = 0;

  public readonly isSearching$ = this.searchValueSubject.pipe(map((value) => Boolean(value)));

  @Input() public enableEmojiReset = true;
  @Input() public filterUnsupported = false;

  @Output() public readonly emojiChange = new EventEmitter<Emoji | null>();

  @ViewChild('scrollContainer', { read: ElementRef })
  public readonly scrollContainer?: ElementRef<HTMLDivElement>;

  @ViewChildren('category', { read: ElementRef })
  public readonly categoryElements?: QueryList<ElementRef<HTMLElement>>;

  public ngAfterViewInit(): void {
    const selectedGroupByUserScroll$ = fromEvent(this.scrollContainer!.nativeElement, 'scroll').pipe(
      map((event) => {
        const categories = this.categoryElements?.toArray() ?? [];
        const lastCategory = categories[categories.length - 1].nativeElement;

        const scrollPosition = (event.target as HTMLElement).scrollTop;
        const scrollHeight = (event.target as HTMLElement).scrollHeight;
        const clientHeight = (event.target as HTMLElement).clientHeight;
        const maxScrollPosition = scrollHeight - clientHeight;

        const offset = 150;

        // Check if the scroll position is close to the bottom or exceeds the maximum scroll position
        if (
          scrollPosition + offset >= lastCategory.offsetTop + lastCategory.offsetHeight ||
          scrollPosition >= maxScrollPosition - offset
        ) {
          return Group.Flags.toString();
        }

        // Iterate through the categories to determine the current category based on the scroll position
        for (let i = 0; i < categories.length; i++) {
          const category = categories[i].nativeElement;
          const nextCategory = categories[i + 1]?.nativeElement;

          // Check if the scroll position falls within the range of the current category
          if (
            scrollPosition >= category.offsetTop - offset &&
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            (!nextCategory || scrollPosition < nextCategory.offsetTop - offset)
          ) {
            return category.id;
          }
        }

        // If no specific category is found, return the string representation of the Group.Recent enum
        return Group.Recent.toString();
      }),
      filterNullish(),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    );

    this.selectedCategory$ = merge(
      selectedGroupByUserScroll$,
      this.searchValueSubject.pipe(
        filter((value) => !value),
        map(() => Group.Recent),
      ),
    );
  }

  public onSearchChanged(search: string | null): void {
    this.searchValueSubject.next(search);
  }

  public onCategoryChanged(category: string): void {
    const element = (this.categoryElements?.toArray() ?? []).find((element) => element.nativeElement.id === category);

    if (this.scrollContainer && element)
      scrollToElement(element.nativeElement, this.scrollContainer.nativeElement, 'smooth', 5);
  }
}
