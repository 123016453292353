import { combineLatest, concat, concatMap, map, Observable, of } from 'rxjs';

import { UserService } from '../../services/user.service';

import { FauxBorderColor } from '../../models/components/avatar.model';
import { TeamInfo, TeamInfoData } from '../../models/services/team.model';
import {
  FrontendAvailability,
  GraphUserBaseData,
  OutOfOfficeSettings,
  User,
  UserOrParticipant,
} from '../../models/services/user.model';

import { concatTeamInfoData } from '../services/team.util';
import {
  isParticipantGuard,
  isUserOrParticipantAnonymized,
  isUserOrParticipantMeetingGuest,
  resolveUserGraphUpdate,
} from '../services/user.util';

export function computeFauxBorderColor(
  availability: FrontendAvailability | null | undefined,
  enableAvailabilityRingColor: boolean,
  outOfOfficeSettings: OutOfOfficeSettings | undefined,
): FauxBorderColor {
  if (availability === null || !enableAvailabilityRingColor) {
    return 'purple';
  }

  if (availability === FrontendAvailability.BeRightBack) {
    return 'yellow';
  }

  if (
    availability === FrontendAvailability.DoNotDisturb ||
    availability === FrontendAvailability.InACall ||
    availability === FrontendAvailability.Presenting ||
    availability === FrontendAvailability.Busy
  ) {
    return 'red';
  }

  if (availability === FrontendAvailability.Available) {
    return 'green';
  }

  if (outOfOfficeSettings?.isOutOfOffice) {
    return 'magenta';
  }

  if (availability === FrontendAvailability.Away) {
    return 'yellow';
  }

  if (availability === FrontendAvailability.Offline) {
    return 'grey';
  }

  return 'purple';
}

export function computeIsCurrentlyInMyTeam(me: User | null, userCurrentTeamObjectId?: string | null): boolean {
  return Boolean(me?.currentTeamObjectId) && me!.currentTeamObjectId === userCurrentTeamObjectId;
}

export function computeInitials(data: { firstName?: string; lastName?: string; displayName?: string }): string {
  // Given name and surname are optional on user accounts
  if (data.firstName && data.lastName) {
    const firstGraphemeOfFirstName = Array.from(data.firstName.normalize().trim())[0];
    const firstGraphemeOfLastName = Array.from(data.lastName.normalize().trim())[0];

    return `${firstGraphemeOfFirstName}${firstGraphemeOfLastName}`;
  }

  let normalizedDisplayName = '';
  if (data.displayName) {
    normalizedDisplayName = data.displayName
      .normalize()
      .replace(/\((guest|gast)\)/gi, '')
      .trim();
  }

  const delimiter = normalizedDisplayName.includes(' ') ? ' ' : '.';

  const names = normalizedDisplayName.split(delimiter);
  if (names.length === 1) return Array.from(names[0])[0];
  if (names.length > 1) return Array.from(names[0])[0] + Array.from(names[names.length - 1])[0];

  return '';
}

export function getOtherRoomInfo(
  teamInfoData: TeamInfoData,
  userOrParticipant: UserOrParticipant,
): TeamInfo | undefined {
  return concatTeamInfoData(teamInfoData).find((team) => team.objectId === userOrParticipant.currentTeamObjectId);
}

// Returns null if the user does not exist
export function updateUserGraphData(
  userService: UserService,
  userOrParticipant: UserOrParticipant,
  useCache = false,
): Observable<UserOrParticipant | null> {
  // `displayName` is always present for `Participant`s, so also check the `emailAddress`
  const graphDataIsMissing = !userOrParticipant.displayName || !userOrParticipant.emailAddress;
  // If the avatar is undefined, the image was previously not found
  const avatarFetchedBefore = userOrParticipant.avatar !== undefined;

  const anyGraphDataIsMissing = graphDataIsMissing || !avatarFetchedBefore;
  if (!anyGraphDataIsMissing) return of(userOrParticipant);

  const isUser = !isUserOrParticipantMeetingGuest(userOrParticipant);
  const userObjectId = isParticipantGuard(userOrParticipant)
    ? userOrParticipant.userObjectId
    : userOrParticipant.objectId;
  const isMeetingGuest = isUserOrParticipantMeetingGuest(userOrParticipant);
  const isExternalAccount = 'isExternalAccount' in userOrParticipant && Boolean(userOrParticipant.isExternalAccount);
  const isAnonymized = isUserOrParticipantAnonymized(userOrParticipant);

  const userIsEligibleForGraphData = isUser || (!isMeetingGuest && !isAnonymized);

  // Update user data
  let userRequest: Observable<UserOrParticipant | null> = of(userOrParticipant);
  if (graphDataIsMissing && userIsEligibleForGraphData) {
    const userBaseRequest = useCache
      ? userService.getCachedOrFreshGraphUser(userObjectId)
      : userService.getGraphUser(userObjectId);

    const updateUserOrParticipant = (graphUser: GraphUserBaseData | null) => {
      if (graphUser) resolveUserGraphUpdate(graphUser, userOrParticipant);
      else return null;

      return userOrParticipant;
    };

    userRequest = userBaseRequest.pipe(
      concatMap((graphUser) => {
        if (graphUser) return of(graphUser);

        // If current user is external and the target-user is not found in the graph,
        // request the backend for fetching the graphUser from graphAPI
        if (userService.userInfo?.user.isExternalAccount) return userService.getGraphUserViaBackend(userObjectId);

        return of(null);
      }),
      map((user) => updateUserOrParticipant(user)),
    );
  }

  // Update avatar
  if (
    // If the current user already has an avatar in userService we can make use of this
    userObjectId === userService.userInfo?.user.objectId &&
    (userService.userInfo.user.avatar || userService.avatar)
  ) {
    userOrParticipant.avatar = userService.userInfo.user.avatar ?? userService.avatar;
  } else if (
    // If the avatar is undefined, the image was previously not found
    !avatarFetchedBefore &&
    userIsEligibleForGraphData
  ) {
    const avatarRequest = useCache
      ? userService.getCachedOrFreshAvatar(userObjectId, isExternalAccount)
      : userService.getAvatar(userObjectId, isExternalAccount);

    // First emit the user object, then fetch the avatar and emit again
    return combineLatest([userRequest, concat(of(undefined), avatarRequest)]).pipe(
      map(([user, avatar]) => {
        if (user && avatar !== undefined) user.avatar = avatar;

        return user;
      }),
    );
  }

  return userRequest;
}

export async function sha256(message?: string): Promise<string | null> {
  if (!message) return null;

  const buffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
  return hashHex;
}
