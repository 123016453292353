import { inject, Injectable, Injector } from '@angular/core';
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';

import {
  BehaviorSubject,
  filter,
  from,
  Observable,
  of,
  switchMap,
  take,
  tap,
  throwError,
  timeout,
  withLatestFrom,
} from 'rxjs';
import { concatMap, map } from 'rxjs';
import { catchError, retryWhen } from 'rxjs';
import { AadUserConversationMember, User as MSGraphUser } from '@microsoft/microsoft-graph-types';
import { endOfToday, isToday } from 'date-fns';
import linkifyHtml from 'linkify-html';
import { stripHtml } from 'string-strip-html';

import { ApplicationInsightsService } from './application-insights.service';
import { AuthService } from './auth.service';
import { CacheService } from './cache.service';
import { CalendarService } from './calendar.service';

import { LeaveOffice } from '../models/components/leave-office.model';
import {
  ParameterlessEvent,
  USER_CHANGED_WORK_LOCATION,
  USER_CHECKED_IN,
  USER_HAS_WORK_LOCATION,
  USER_SELECTED_EMOJI,
  UserSelectedTeamEvent,
  UserUpdatedLocationEvent,
} from '../models/services/application-insights.model';
import { UserCheckedOutEvent } from '../models/services/application-insights.model';
import { Event } from '../models/services/calendar.model';
import { BE_RIGHT_BACK_EMOJI } from '../models/services/emoji.model';
import {
  DismissedEvent,
  GraphUserBaseData,
  Location,
  Presence,
  PresenceBeta,
  UpdateUserData,
  UserInfo,
  UserSettings,
  UsersNotInCall,
  WorkLocation,
} from '../models/services/user.model';
import { TourStep } from '../models/tour.model';

import { arrayBufferToDataUrl, isUUID } from '../utils/misc.util';
import { includeScalingRetryStrategy } from '../utils/rxjs.util';
import { getRecentlyUsedEmojis } from '../utils/services/chat/emoji.util';
import { resolveUserGraphUpdate } from '../utils/services/user.util';

import {
  GRAPH_API_BASE,
  GRAPH_API_BETA_BASE,
  SUPPORT_USER_AVATAR,
  SUPPORT_USER_DATA,
  SUPPORT_USER_ID,
} from '../constants';
import { environment } from 'src/environments/environment';

const CACHE_TIMEOUT_MS = environment.enableServiceWorker ? 0 : 1_000;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly injector = inject(Injector);

  private readonly graphUserCache$: BehaviorSubject<Map<string, GraphUserBaseData | null>> = new BehaviorSubject(
    new Map<string, GraphUserBaseData | null>([[SUPPORT_USER_ID, SUPPORT_USER_DATA]]),
  );

  private readonly graphAvatarCache$: BehaviorSubject<Map<string, string>> = new BehaviorSubject(
    new Map([[SUPPORT_USER_ID, SUPPORT_USER_AVATAR]]),
  );

  private readonly userInfoSubject = new BehaviorSubject<UserInfo | null | undefined>(undefined);
  private readonly avatarSubject = new BehaviorSubject<string | null>(null);

  // This is only used if current user is externalAccount
  private conversationMembers?: { value: AadUserConversationMember[] };

  public readonly userInfo$ = this.userInfoSubject.asObservable();

  public userInfo: UserInfo | null = null;

  // This is only used if user === null (before user creation in database)
  public avatar$ = this.avatarSubject.asObservable();

  // This is only used if user === null (before user creation in database)
  public avatar: string | null = null;

  public constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly cacheService: CacheService,
  ) {}

  public getUserInfo(): Observable<UserInfo | null> {
    return this.http.get<UserInfo>(`${environment.backendUrl}/api/user/info`).pipe(
      concatMap((userInfo) =>
        this.getGraphUser(userInfo.user.objectId).pipe(
          map((graphUser) => {
            if (this.userInfo?.user) resolveUserGraphUpdate(this.userInfo.user, userInfo.user);

            const user = userInfo.user;
            user.displayName = graphUser!.displayName!;
            user.firstName = graphUser!.firstName;
            user.lastName = graphUser!.lastName;
            user.emailAddress = graphUser!.emailAddress;
            user.userPrincipalName = graphUser!.userPrincipalName!;

            this.userInfo = userInfo;

            return this.userInfo;
          }),
        ),
      ),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) return of(null);

        throw err;
      }),
      tap((userInfo) => this.userInfoSubject.next(userInfo)),
    );
  }

  public getGraphUser(userObjectId: string): Observable<GraphUserBaseData | null> {
    const select = 'displayName,givenName,surname,mail,userPrincipalName,jobTitle';
    const request = this.http.get<MSGraphUser>(`${GRAPH_API_BASE}/users/${userObjectId}?$select=${select}`).pipe(
      map((graphUser) => ({
        displayName: graphUser.displayName!,
        firstName: graphUser.givenName || undefined,
        lastName: graphUser.surname || undefined,
        emailAddress: graphUser.mail!,
        userPrincipalName: graphUser.userPrincipalName!,
        jobTitle: graphUser.jobTitle || null,
      })),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) return of(null);

        return throwError(err);
      }),
    );

    const mapConversationMember = (conversationMember: AadUserConversationMember) => ({
      displayName: conversationMember.displayName!,
      firstName: conversationMember.user?.givenName || undefined,
      lastName: conversationMember.user?.surname || undefined,
      emailAddress: conversationMember.email!,
      userPrincipalName: conversationMember.user?.userPrincipalName || conversationMember.email!,
      jobTitle: conversationMember.user?.jobTitle || null,
    });

    const externalRequest = this.http
      .get<{
        value: AadUserConversationMember[];
      }>(`${GRAPH_API_BASE}/teams/${this.authService.context!.team?.groupId}/members`)
      .pipe(
        map((conversationMembers) => {
          if (!this.conversationMembers) this.conversationMembers = conversationMembers;

          const conversationMember = conversationMembers.value.find(
            (conversationMember) => conversationMember.userId === userObjectId,
          );
          if (!conversationMember) return null;

          return mapConversationMember(conversationMember);
        }),
      );

    if (this.authService.isExternalAccount && this.conversationMembers) {
      const conversationMember = this.conversationMembers.value.find(
        (conversationMember) => conversationMember.userId === userObjectId,
      );
      if (!conversationMember) return of(null);

      return of(mapConversationMember(conversationMember));
    }

    const executedRequest = this.authService.isExternalAccount ? externalRequest : request;
    return executedRequest.pipe(
      tap((graphUser) => {
        const graphUserCache = this.graphUserCache$.value;
        graphUserCache.set(userObjectId, graphUser);
        this.graphUserCache$.next(graphUserCache);
      }),
    );
  }

  public getCachedOrFreshGraphUser(
    userObjectId: string,
    timeoutMs = CACHE_TIMEOUT_MS,
  ): Observable<GraphUserBaseData | null> {
    // Check if uuid is valid (conversationMember of type 'microsoftAccountUserConversationMember' do not have a valid uuid as userId)
    if (!isUUID(userObjectId)) return of(null);

    return this.graphUserCache$.pipe(
      map((graphUserCache) => graphUserCache.get(userObjectId)),
      filter((graphUser) => graphUser !== undefined),
      map((graphUser) => graphUser as GraphUserBaseData | null),
      take(1),
      timeout(timeoutMs),
      catchError(() => this.getGraphUser(userObjectId)),
    );
  }

  public getGraphUserViaBackend(userObjectId: string): Observable<GraphUserBaseData | null> {
    return this.http.get<GraphUserBaseData>(
      `${environment.backendUrl}/api/user/graph-user?userObjectId=${userObjectId}`,
    );
  }

  public getUsersOutsideCall(teamObjectId: string): Observable<UsersNotInCall> {
    return this.http.get<UsersNotInCall>(
      `${environment.backendUrl}/api/user/users-outside-call?teamObjectId=${encodeURIComponent(teamObjectId)}`,
    );
  }

  public selectOrVisitTeam(teamObjectId: string): Observable<UserInfo | null> {
    // Set user locale if not set or if it has changed
    const teamsLocale = this.authService.context!.app.locale.toLowerCase();

    return this.http
      .put<UserInfo>(`${environment.backendUrl}/api/user/select-or-visit-team`, { teamObjectId, teamsLocale })
      .pipe(
        map((userInfo) => {
          if (this.userInfo) resolveUserGraphUpdate(userInfo.user, userInfo.user);

          this.userInfo = userInfo;

          const isVisitor = Boolean(userInfo.user.visitingTeamObjectId);
          this.applicationInsightsService.logCustomEvent(
            new UserSelectedTeamEvent(userInfo.hasUsedAppAtLeastOnce, isVisitor),
          );

          return this.userInfo;
        }),
        retryWhen(
          includeScalingRetryStrategy({
            includedStatusCodes: [HttpStatusCode.ServiceUnavailable],
            maxRetryAttempts: 5,
            scalingDuration: 1000,
          }),
        ),
        switchMap((userInfo) => this.checkInIfNecessary().pipe(map(() => userInfo))),
        tap((userInfo) => this.userInfoSubject.next(userInfo)),
      );
  }

  public updateSelectedEmoji(selectedEmoji: string | null): Observable<UserInfo> {
    return of(selectedEmoji).pipe(
      withLatestFrom(this.userInfo$),
      switchMap(([emoji, userInfo]) => {
        const recentEmojis = getRecentlyUsedEmojis(userInfo?.recentEmojis ?? [], emoji);

        return this.updateUser({ recentEmojis, selectedEmoji });
      }),
    );
  }

  public updateLocation(data: { baseLocation: Location }): Observable<UserInfo> {
    return this.updateUser({ baseLocation: data.baseLocation });
  }

  public updateWorkLocation(
    workLocation: WorkLocation | null,
    individualWorkLocation?: string | null,
  ): Observable<UserInfo> {
    return this.updateUser({
      workLocation: workLocation,
      individualWorkLocation: individualWorkLocation,
    });
  }

  public updateSettings(settings: UserSettings): Observable<UserInfo> {
    return this.updateUser({ settings });
  }

  public updateTourSteps(tourSteps: TourStep[]): Observable<UserInfo> {
    return this.updateUser({ tourSteps });
  }

  public updateRecentEmojis(emoji: string): Observable<UserInfo> {
    return of(emoji).pipe(
      withLatestFrom(this.userInfo$),
      switchMap(([emoji, userInfo]) => {
        const recentEmojis = getRecentlyUsedEmojis(userInfo?.recentEmojis ?? [], emoji);

        return this.updateUser({ recentEmojis });
      }),
    );
  }

  public updateUser(userData: UpdateUserData): Observable<UserInfo> {
    // Log changes to AppInsights
    const userCheckingIn =
      userData.baseLocation !== undefined &&
      userData.baseLocation !== Location.Inactive &&
      this.userInfo!.user.baseLocation === Location.Inactive;
    const userChangedWorkLocation =
      userData.workLocation !== undefined || userData.individualWorkLocation !== undefined;
    const userHasWorkLocation = this.userInfo!.user.workLocation !== null || userChangedWorkLocation;
    if (userCheckingIn) {
      this.applicationInsightsService.logCustomEvent(new ParameterlessEvent(USER_CHECKED_IN));
    }

    if (userData.baseLocation !== undefined) {
      this.applicationInsightsService.logCustomEvent(
        new UserUpdatedLocationEvent(this.userInfo!.user.baseLocation, userData.baseLocation),
      );
    }

    if (userData.selectedEmoji) {
      this.applicationInsightsService.logCustomEvent(new ParameterlessEvent(USER_SELECTED_EMOJI));
    }

    if (userChangedWorkLocation) {
      this.applicationInsightsService.logCustomEvent(new ParameterlessEvent(USER_CHANGED_WORK_LOCATION));
    }

    // Set default values and fallbacks
    if (userData.selectedEmoji === undefined) userData.selectedEmoji = this.userInfo?.user.selectedEmoji ?? null;
    if (userData.workLocation === undefined) userData.workLocation = this.userInfo?.user.workLocation ?? null;
    if (userData.individualWorkLocation === undefined) {
      userData.individualWorkLocation = this.userInfo?.user.individualWorkLocation ?? null;
    }

    return this.cacheService
      .updateGeneralDataFn((generalData) => {
        if (
          (!generalData.lastUserHasWorkLocationEventTracked ||
            !isToday(generalData.lastUserHasWorkLocationEventTracked)) &&
          userCheckingIn &&
          userHasWorkLocation
        ) {
          this.applicationInsightsService.logCustomEvent(new ParameterlessEvent(USER_HAS_WORK_LOCATION));

          return { lastUserHasWorkLocationEventTracked: new Date() };
        }

        return null;
      })
      .pipe(
        concatMap(() => this.http.put<UserInfo>(`${environment.backendUrl}/api/user/info`, userData)),
        map((userInfo) => {
          if (this.userInfo?.user) resolveUserGraphUpdate(this.userInfo.user, userInfo.user);

          this.userInfo = userInfo;

          return this.userInfo;
        }),
        retryWhen(
          includeScalingRetryStrategy({
            includedStatusCodes: [HttpStatusCode.ServiceUnavailable],
            maxRetryAttempts: 5,
            scalingDuration: 1000,
          }),
        ),
        tap((userInfo) => this.userInfoSubject.next(userInfo)),
      );
  }

  public getAvatar(userObjectId: string, isExternalAccount = false, size?: string): Observable<string | null> {
    const request = this.http
      .get(`${GRAPH_API_BASE}/users/${userObjectId}/photos/240x240/$value`, {
        headers: { Accept: 'image/jpg, image/webp, application/octet-stream' },
        responseType: 'arraybuffer',
      })
      .pipe(
        map((avatar) => {
          const mappedAvatar = arrayBufferToDataUrl(avatar);
          if (mappedAvatar) this.setAvatar(userObjectId, mappedAvatar);

          return mappedAvatar;
        }),
        catchError((err: HttpErrorResponse) => handleError(err)),
      );

    const externalRequest = this.http
      .get(`${environment.backendUrl}/api/user/${userObjectId}/avatar/${size ?? '240x240'}`, {
        headers: { Accept: 'image/jpg, image/webp, application/octet-stream' },
        responseType: 'arraybuffer',
      })
      .pipe(
        map((avatar) => {
          const mappedAvatar = arrayBufferToDataUrl(avatar, isExternalAccount ? 'image/webp' : 'image/jpg');
          if (mappedAvatar) this.setAvatar(userObjectId, mappedAvatar);

          return mappedAvatar;
        }),
        catchError((err: HttpErrorResponse) => handleError(err)),
      );

    const handleError = (err: HttpErrorResponse): Observable<string | null> => {
      if (err.status === 404) return of(null);

      return throwError(err);
    };

    const executedRequest = this.authService.isExternalAccount || isExternalAccount ? externalRequest : request;

    return executedRequest.pipe(
      tap((avatar) => {
        if (!avatar) return;

        const graphAvatarCache = this.graphAvatarCache$.value;
        graphAvatarCache.set(userObjectId, avatar);
        this.graphAvatarCache$.next(graphAvatarCache);
      }),
    );
  }

  public getCachedOrFreshAvatar(
    userObjectId: string,
    isExternalAccount = false,
    size?: string,
    timeoutMs = CACHE_TIMEOUT_MS,
  ): Observable<string | null> {
    return this.graphAvatarCache$.pipe(
      map((graphAvatarCache) => graphAvatarCache.get(userObjectId)),
      filter((graphAvatarCache) => graphAvatarCache !== undefined),
      map((graphAvatarCache) => graphAvatarCache as string | null),
      take(1),
      timeout(timeoutMs),
      catchError(() => this.getAvatar(userObjectId, isExternalAccount, size)),
    );
  }

  public updateMyAvatar(avatar: Blob): Observable<boolean> {
    const useBackend = this.authService.isExternalAccount;
    const route = useBackend ? `${environment.backendUrl}/api/user/avatar` : `${GRAPH_API_BASE}/me/photo/$value`;

    return this.http.put<void>(route, avatar).pipe(
      map(() => {
        from(avatar.arrayBuffer()).subscribe((avatar) => {
          const mappedAvatar = arrayBufferToDataUrl(avatar, useBackend ? 'image/webp' : 'image/jpg');

          if (!this.userInfo) {
            this.avatar = mappedAvatar;
            this.avatarSubject.next(mappedAvatar);
          } else {
            this.userInfo.user.avatar = mappedAvatar;
            this.userInfoSubject.next({ ...this.userInfo });
          }
        });

        return true;
      }),
      catchError(() => of(false)),
    );
  }

  public checkInIfNecessary(): Observable<UserInfo | null> {
    if (this.userInfo?.user.baseLocation === Location.Inactive) {
      return this.updateLocation({ baseLocation: Location.Desks });
    }

    return of(null);
  }

  public checkout(leaveOffice: LeaveOffice): Observable<UserInfo> {
    const isLeaveOffice = leaveOffice === 'leave';
    this.applicationInsightsService.logCustomEvent(new UserCheckedOutEvent(!isLeaveOffice));

    const updateUserData: UpdateUserData = { beRightBack: !isLeaveOffice, baseLocation: Location.Inactive };

    if (leaveOffice === 'beRightBackWithEmoji') {
      updateUserData.selectedEmoji = BE_RIGHT_BACK_EMOJI;
    }

    return this.updateUser(updateUserData);
  }

  public getPresence(userObjectId: string, convertLinksToTags = true): Observable<PresenceBeta> {
    const mapHtmlContent = (content: string): string => {
      // Strip all tags except for line breaks
      const strippedHtml = stripHtml(content, {
        ignoreTags: ['br'],
      }).result;

      const contentWithAtMostTwoSequentialBreaks = strippedHtml
        .replace(/(\s*<br\s*\/?>\s*){3,}/gi, '<br><br>')
        .replace(/\s*<br\s*\/?>\s*/gi, '<br />');

      if (convertLinksToTags) {
        const contentWithConvertedLinks = linkifyHtml(contentWithAtMostTwoSequentialBreaks, {
          defaultProtocol: 'https',
          target: { url: '_blank' },
        });

        return contentWithConvertedLinks;
      } else {
        return contentWithAtMostTwoSequentialBreaks;
      }
    };

    // Use /me for my user to avoid caching issues, since we update the status from within the app
    const endpoint =
      userObjectId === this.authService.context!.user!.id
        ? `${GRAPH_API_BETA_BASE}/me/presence`
        : `${GRAPH_API_BETA_BASE}/users/${userObjectId}/presence`;

    return this.http.get<PresenceBeta>(endpoint).pipe(
      map((presence) => {
        if (presence.statusMessage?.message?.content) {
          presence.statusMessage.message.content = mapHtmlContent(presence.statusMessage.message.content);
        }

        if (presence.outOfOfficeSettings?.message) {
          presence.outOfOfficeSettings.message = mapHtmlContent(presence.outOfOfficeSettings.message);
        }

        return presence;
      }),
    );
  }

  public updatePreferredPresence(presence: Presence): Observable<void> {
    return this.http.put<void>(`${environment.backendUrl}/api/user/presence`, presence);
  }

  public clearPreferredPresence(): Observable<void> {
    return this.http.delete<void>(`${environment.backendUrl}/api/user/presence`);
  }

  public setStatusMessage(status: string | null, expiryDate?: Date): Observable<void> {
    const expiryDateString = (expiryDate ?? endOfToday()).toUTCString();

    return this.http.post<void>(
      `${GRAPH_API_BETA_BASE}/users/${this.authService.context!.user!.id}/presence/setStatusMessage`,
      {
        statusMessage: {
          expiryDateTime: { dateTime: expiryDateString, timeZone: 'UTC' },
          message: { content: status, contentType: 'text' },
        },
      },
    );
  }

  public dismissEvent(
    eventId: string | null | undefined,
    joinWebUrl: string | null | undefined,
    iCalUId: string | null | undefined,
    endDateTime: string,
  ): Observable<Event[]> {
    const calendarService = this.injector.get(CalendarService);
    return this.http
      .put<DismissedEvent[]>(`${environment.backendUrl}/api/user/dismiss-event`, {
        identifier: eventId,
        iCalUId,
        joinWebUrl,
        endDateTime,
      })
      .pipe(
        tap((dismissedEvents) => (this.userInfo!.dismissedEvents = dismissedEvents)),
        concatMap(() => calendarService.emitEvents()),
        concatMap(() => calendarService.getEvents(false)),
      );
  }

  public updateUsageRights(): Observable<UserInfo> {
    return this.http.post<UserInfo>(`${environment.backendUrl}/api/user/update-usage-rights`, null).pipe(
      tap((userInfo) => {
        this.userInfo = userInfo;
        this.userInfoSubject.next(userInfo);
      }),
    );
  }

  private setAvatar(userObjectId: string, mappedAvatar: string): void {
    const myUserObjectId = this.authService.context!.user!.id;

    if (myUserObjectId === userObjectId) {
      if (!this.userInfo) {
        this.avatar = mappedAvatar;
        this.avatarSubject.next(mappedAvatar);
      } else {
        this.userInfo.user.avatar = mappedAvatar;
        this.userInfoSubject.next({ ...this.userInfo });
      }
    }
  }
}
