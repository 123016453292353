import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-otr-icon',
  standalone: true,
  template: `
    <svg version="1.1" viewBox="1 9.06 304.46 287.946" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M 36.206 10.557 C 33.294 11.340, 28.434 13.195, 25.405 14.678 C 21.933 16.378, 17.406 19.998, 13.151
      24.478 C 7.247 30.693, 6.066 32.603, 3.702 39.756 L 1 47.930 1 153 L 1 258.070 3.694 266.222 C 6.023 273.269,
      7.272 275.311, 12.900 281.277 C 16.992 285.614, 21.561 289.264, 25.198 291.100 C 28.382 292.707, 33.559 294.692,
      36.703 295.511 C 41.411 296.737, 51.576 297, 94.267 297 C 130.480 297, 147.513 296.648, 150.752 295.832 C 154.091
      294.991, 156.844 293.243, 160.590 289.582 C 164.971 285.301, 165.991 283.594, 167.055 278.750 C 168.052 274.213,
      168.065 271.867, 167.117 267.630 C 166.316 264.052, 164.696 260.837, 162.263 257.994 C 160.255 255.648, 156.787
      252.899, 154.556 251.886 C 150.787 250.174, 146.875 250.042, 99.250 250.022 L 48 250 48 153 L 48 56 99.250 55.978
      C 146.875 55.958, 150.787 55.826, 154.556 54.114 C 156.787 53.101, 160.239 50.371, 162.227 48.048 C 164.432
      45.472, 166.306 41.893, 167.030 38.876 C 167.728 35.965, 167.926 31.995, 167.511 29.228 C 167.124 26.642, 166.190
      23.334, 165.437 21.878 C 164.683 20.421, 162.023 17.430, 159.525 15.231 C 156.840 12.868, 153.170 10.777, 150.549
      10.117 C 147.547 9.361, 129.217 9.021, 93.807 9.066 C 51.840 9.119, 40.454 9.413, 36.206 10.557 M 207.544 65.589 C
      205.369 66.386, 202.514 68.042, 201.199 69.269 C 199.885 70.496, 197.615 73.525, 196.155 76 C 193.952 79.734,
      193.504 81.692, 193.526 87.500 C 193.545 92.370, 194.120 95.565, 195.417 98 C 196.442 99.925, 203.342 107.575,
      210.751 115 L 224.222 128.500 173.861 129 C 123.765 129.497, 123.475 129.512, 118.814 131.808 C 116.236 133.078,
      112.565 135.846, 110.655 137.960 C 108.201 140.676, 106.842 143.446, 106.020 147.402 C 105.137 151.655, 105.137
      154.345, 106.020 158.598 C 106.838 162.537, 108.202 165.324, 110.621 168.002 C 112.512 170.095, 116.183 172.863,
      118.779 174.154 C 123.499 176.500, 123.509 176.500, 173.827 177 L 224.153 177.500 210.716 191 C 203.325 198.425,
      196.440 206.075, 195.416 208 C 194.120 210.434, 193.545 213.632, 193.526 218.500 C 193.504 224.308, 193.952
      226.266, 196.155 230 C 197.615 232.475, 199.878 235.497, 201.183 236.716 C 202.489 237.935, 205.513 239.623,
      207.904 240.466 C 210.295 241.310, 214.332 241.966, 216.876 241.924 C 219.419 241.882, 222.948 241.320, 224.719
      240.674 C 226.739 239.937, 241.734 225.965, 265.002 203.136 C 285.387 183.136, 302.830 165.361, 303.763 163.636 C
      304.930 161.481, 305.460 158.155, 305.460 153 C 305.460 147.845, 304.930 144.519, 303.763 142.364 C 302.830
      140.639, 285.387 122.864, 265.002 102.864 C 241.734 80.035, 226.739 66.063, 224.719 65.326 C 222.948 64.680,
      219.250 64.150, 216.500 64.147 C 213.750 64.143, 209.720 64.793, 207.544 65.589"
        fill-rule="evenodd"
        stroke="none"
        [attr.fill]="fill"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;

        svg {
          width: 100%;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtrIconComponent {
  @Input() public fill = 'var(--bnear-brand)';
}
