<button
  [@failureAnimation]="triggerFailureAnimation"
  [@successAnimation]="triggerSuccessAnimation"
  [disabled]="disabled"
  [ngStyle]="{
    cursor,
    width: width ? (width | scaleByOffice) : undefined,
    height: height ? (height | scaleByOffice) : undefined,
    'min-width': minWidth | scaleByOffice,
    'min-height': minHeight | scaleByOffice,
    padding: padding ? (padding | scaleByOffice) : undefined,
    'border-radius': borderRadius ? (borderRadius | scaleByOffice) : undefined,
    'font-size': fontSize ? (fontSize | scaleByOffice) : undefined,
    'font-weight': fontWeight ?? undefined,
    color: color ?? undefined,
    'background-color': backgroundColor ? backgroundColor : undefined,
    border: border ? (border | scaleByOffice) : undefined,
    'box-shadow': boxShadow ? (boxShadow | scaleByOffice) : undefined,
    'justify-content': justifyContent ? justifyContent : undefined
  }"
  (@failureAnimation.done)="onAnimationDone()"
  (@successAnimation.done)="onAnimationDone()"
  (click)="buttonClick.emit($event)"
>
  <ng-content></ng-content>
</button>
