<div
  class="container"
  [@slideInOutTriggered]="{ value: animationState, params: { slideInMs: 200, slideOutMs: 300 } }"
  (@slideInOutTriggered.done)="onFadeFinished($event)"
>
  <div class="content">
    <span *ngIf="data.options?.header" class="header">{{ data.options?.header }}</span>
    <span *ngIf="text">{{ text }}</span>
    <ng-container *ngIf="component" [ngComponentOutlet]="component"></ng-container>
  </div>
  <img
    *ngIf="data.options?.hasCloseButton"
    alt="close"
    src="assets/img/icons/close.svg"
    style="width: 10px"
    (click)="animationState = 'hidden'"
  />
</div>
