<div class="container" [ngClass]="{ 'use-static-sizing': useStaticSizing, 'use-compact-sizing': useCompactSizing }">
  <div
    *ngIf="calculatedPageSize && currentAvatarCount > calculatedPageSize && currentLeftAvatarIndex > 0"
    class="scroll-btn prev"
    (click)="scrollLeft($event)"
  >
    <img src="assets/img/icons/chevron-purple.svg" />
  </div>
  <div
    *ngIf="
      calculatedPageSize &&
      currentAvatarCount > calculatedPageSize &&
      currentLeftAvatarIndex + calculatedPageSize < currentAvatarCount
    "
    class="scroll-btn next"
    (click)="scrollRight($event)"
  >
    <img src="assets/img/icons/chevron-purple.svg" />
  </div>

  <div
    #avatarContainer
    cdkScrollable
    class="avatars"
    [ngClass]="{
      'is-scrollable-to-the-right':
        currentAvatarCount > calculatedPageSize && currentLeftAvatarIndex + calculatedPageSize < currentAvatarCount,
      'is-scrolled': currentLeftAvatarIndex > 0 && currentLeftAvatarIndex + calculatedPageSize < currentAvatarCount,
      'is-fully-scrolled':
        currentAvatarCount > calculatedPageSize && currentLeftAvatarIndex + calculatedPageSize >= currentAvatarCount
    }"
  >
    <app-avatar
      #avatar
      #tooltip="appTooltip"
      *ngFor="let user of usersWithTracking.data; index as idx; trackByProperty: 'objectId'"
      [appTooltip]="user.displayName ?? ''"
      [appTooltipDisabled]="!enableSimpleHover"
      [appTooltipStyles]="tooltip"
      [appTooltipStylesAbsoluteCssValues]="true"
      [availability]="user.availability"
      [baseLocation]="user.baseLocation"
      [currentTeamObjectId]="user.currentTeamObjectId"
      [disableHover]="
        enableSimpleHover ||
        disableHover ||
        isScrolling ||
        (currentLeftAvatarIndex + calculatedPageSize < currentAvatarCount && idx < currentLeftAvatarIndex) ||
        (currentLeftAvatarIndex + calculatedPageSize >= currentAvatarCount &&
          idx < currentAvatarCount - 1 - calculatedPageSize) ||
        (currentLeftAvatarIndex + calculatedPageSize < currentAvatarCount &&
          idx >= currentLeftAvatarIndex + (calculatedPageSize - (currentLeftAvatarIndex === 0 ? 0 : 1)))
      "
      [enableCallButton]="enableCallButton"
      [enableChatOpening]="enableChatOpening"
      [enableDoNotDisturbBadge]="enableDoNotDisturbBadge"
      [enableEmoji]="enableEmoji"
      [enableInACallBadge]="enableInACallBadge"
      [enableInOtherRoomBadge]="enableInOtherRoomBadge"
      [enableLicenseInvalidBadge]="enableLicenseInvalidBadge"
      [enableOutOfOfficeBadge]="enableOutOfOfficeBadge"
      [enablePresentingBadge]="enablePresentingBadge"
      [enableTour]="false"
      [enableVisitorBadge]="enableVisitorBadge"
      [individualWorkLocation]="user.individualWorkLocation"
      [myCall]="myCall"
      [selectedEmoji]="user.selectedEmoji"
      [showBounceAnimation]="false"
      [tourIsActive]="(tourService.isActive$ | async) || false"
      [userObjectId]="user.objectId"
      [userOrParticipant]="user"
      [visitingTeamObjectId]="user.visitingTeamObjectId"
      [workLocation]="user.workLocation"
      (avatarHoverIsActive)="avatarHoverIsActive.emit($event)"
      (userDeleted)="onUserDeleted(idx)"
    >
    </app-avatar>
  </div>
</div>
