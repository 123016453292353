import { NullableOption } from '@microsoft/microsoft-graph-types';
import { jwtDecode } from 'jwt-decode';

import { MimeType } from '../models/misc.model';
import { SSOTokenInfoBase } from '../models/services/auth.model';

const uuidRegex =
  /^(?:[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[1-5][a-fA-F0-9]{3}-[89abAB][a-fA-F0-9]{3}-[a-fA-F0-9]{12}|[a-fA-F0-9]{8}[a-fA-F0-9]{4}[a-fA-F0-9]{4}[a-fA-F0-9]{4}[a-fA-F0-9]{12})$/;

function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = '';

  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return window.btoa(binary);
}

export function arrayBufferToDataUrl(arrayBuffer: ArrayBuffer, mimeType: MimeType = 'image/jpg'): string | null {
  if (arrayBuffer.byteLength === 0) return null;

  return `data:${mimeType};base64,${arrayBufferToBase64(arrayBuffer)}`;
}

export function getBase64FromDataUrl(base64DataUrl: string): string {
  const start = base64DataUrl.indexOf('base64,') + 'base64,'.length;
  const end = base64DataUrl.length;

  return base64DataUrl.substring(start, end);
}

export function parseJwt(token: string): SSOTokenInfoBase {
  const ssoToken = jwtDecode<SSOTokenInfoBase | null>(token);
  if (!ssoToken) throw new Error('SSO Token is null.');
  if (!ssoToken.exp) throw new Error('Expiration claim is null.');

  return ssoToken;
}

export function parseJson<T>(value: string): T {
  return JSON.parse(value) as T;
}

export function parseArrayToQueryString(array: unknown[], key: string, missingKey?: string): string {
  if (array.length === 0) return `${key}=''`;

  return array.map((element) => `${key}=${element}`).join('&') + (missingKey ? `&${missingKey}=''` : '');
}

export function isNullOrUndefined(value: unknown): boolean {
  return value === null || value === undefined;
}

export function isUUID(content?: NullableOption<string> | string): boolean {
  if (!content) return false;

  return uuidRegex.test(content);
}

export function groupBy<T>(elements: T[], key: keyof T): { [key: string]: T[] } {
  return elements.reduce(
    (groups, element) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!groups[element[key] as string]) groups[element[key] as string] = [];

      groups[element[key] as string].push(element);

      return groups;
    },
    {} as { [key: string]: T[] },
  );
}

export function minBy<T>(elements: T[], comparator: (minElement: T, otherElement: T) => boolean): T | undefined {
  if (elements.length === 0) return undefined;

  let minElement = elements[0];
  for (const element of elements) {
    if (comparator(minElement, element)) minElement = element;
  }

  return minElement;
}

export function filterNullishArray<T>(value: T | null | undefined): value is T {
  return Boolean(value);
}
