import { ComponentRef, Type } from '@angular/core';
import { OverlayRef, PositionStrategy } from '@angular/cdk/overlay';

import { ToastComponent } from '../../services/toast/toast.component';

export const OFFSET_MODIFIER = 50;
export const MILLISECONDS_PER_SECOND = 1_000;
export const AUTOCLOSE_DEFAULT_TIME_MS = 15_000;

export interface ToastData<T> {
  content: Type<unknown> | string;
  options?: ToastOptions<T>;
}

export interface ToastOptions<T> {
  header?: string;
  autoCloseEnabled?: boolean;
  autoCloseTimeMs?: number;
  hasCloseButton?: boolean;
  horizontalPosition?: ToastHorizontalPosition;
  verticalPosition?: ToastVerticalPosition;
  data?: T;
}

export interface ToastPosition {
  horizontalPosition: ToastHorizontalPosition;
  verticalPosition: ToastVerticalPosition;
}

export type ToastHorizontalPosition = 'center' | 'left' | 'right';
export type ToastVerticalPosition = 'bottom' | 'top';
export type ToastAnimationState = 'default' | 'fromBottom' | 'fromLeft' | 'fromRight' | 'fromTop' | 'hidden';

export class ToastRef {
  private componentRef?: ComponentRef<ToastComponent>;

  public constructor(
    private overlayRef: OverlayRef,
    private positionOffset: number,
    public readonly positionGroup: string,
  ) {}

  public close(): void {
    this.overlayRef.dispose();
  }

  public getPosition(): DOMRect | undefined {
    return this.overlayRef.hasAttached() ? this.overlayRef.overlayElement.getBoundingClientRect() : undefined;
  }

  public updatePosition(positionStrategy: PositionStrategy): void {
    this.overlayRef.updatePositionStrategy(positionStrategy);
  }

  public setOffset(offset: number): void {
    this.positionOffset = offset;
  }

  public getOffset(): number {
    return this.positionOffset;
  }

  public setComponentRef(componentRef: ComponentRef<ToastComponent>): void {
    this.componentRef = componentRef;
  }

  public getComponentRef(): ComponentRef<ToastComponent> | undefined {
    return this.componentRef;
  }
}
