import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgStyle } from '@angular/common';

import { ScaleByOfficePipe } from 'src/app/shared/pipes/scale-by-office.pipe';

@Component({
  selector: 'app-reset-icon',
  standalone: true,
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
  imports: [NgStyle, ScaleByOfficePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetIconComponent {
  @Input() public fill = 'var(--bnear-brand)';
}
