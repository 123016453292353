import { inject, Injectable } from '@angular/core';

import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { AuthService } from './auth.service';
import { CacheService } from './cache.service';
import { CallService } from './call.service';
import { OnlineMeetingService } from './online-meeting.service';
import { SidebarService } from './sidebar.service';
import { TeamService } from './team.service';
import { TenantService } from './tenant.service';
import { TodoService } from './todo.service';
import { UserService } from './user.service';

import { OfficeData } from '../models/components/office.model';
import { ActiveCalls, Call, CallContext } from '../models/services/call.model';
import { TeamInfoData } from '../models/services/team.model';
import { Location, User, UsersNotInCall } from '../models/services/user.model';
import { Event } from 'src/app/shared/models/services/calendar.model';

import { meetingShouldBeShown, removeOptedOutParticipants, sortMeetings } from '../utils/components/meeting.util';
import { fixStartAndEndTimeOfAllDayMeetings, getInitialOfficeSizing } from '../utils/components/office.util';
import { filterNullish } from '../utils/rxjs.util';
import { filterEventsByCalls } from '../utils/services/calendar.util';
import { concatActiveCalls } from '../utils/services/call.util';
import { sortUsers } from '../utils/services/user.util';

@Injectable({ providedIn: 'root' })
export class PreloadDataService {
  private readonly userService = inject(UserService);
  private readonly teamService = inject(TeamService);
  private readonly callService = inject(CallService);
  private readonly tenantService = inject(TenantService);
  private readonly cacheService = inject(CacheService);
  private readonly sidebarService = inject(SidebarService);
  private readonly todoService = inject(TodoService);
  private readonly authService = inject(AuthService);
  private readonly onlineMeetingService = inject(OnlineMeetingService);

  private readonly officeDataSubject = new BehaviorSubject<OfficeData | null>(null);

  public readonly officeData$ = this.officeDataSubject.pipe(filterNullish(), shareReplay(1));

  public preloadData(teamObjectId: string): Observable<OfficeData> {
    return combineLatest([
      this.cacheService.getGeneralData(),
      this.cacheService.getBackendData(),
      this.cacheService.getGraphData(),
      this.sidebarService.state$.pipe(take(1)),
      this.getTeamInfoDataAndActiveCalls(teamObjectId),
      // Trigger observable once so todos are ready once the tab gets opened and the todos can be displayed immediately
      this.todoService.todos$.pipe(take(1)),
      this.authService.isMobileWidth$.pipe(take(1)),
    ]).pipe(
      map(
        ([
          { elevatorPreviewIsPinned },
          { tenantSettings: cachedTenantSettings },
          { chats, events, recentFiles, trendingFiles, todoListId },
          sidebarState,
          { activeCalls, teamInfoData },
          todos,
          isMobileWidth,
        ]) => {
          const tenantSettings = this.tenantService.settings || cachedTenantSettings;

          const chatFeatureEnabled = tenantSettings!.featureFlags.chat && !this.authService.isExternalAccount;
          const initialOfficeContainerStyles = isMobileWidth
            ? undefined
            : getInitialOfficeSizing(sidebarState, chatFeatureEnabled);

          const eventJoinWebUrls = events
            .map((event) => decodeURIComponent(event.joinWebUrl ?? ''))
            .filter((joinWebUrl) => Boolean(joinWebUrl));

          const initialActiveScheduledMeetings = activeCalls.scheduledMeetings.filter(
            (meeting) => !eventJoinWebUrls.includes(meeting.joinWebUrl ?? ''),
          ).length;

          activeCalls.scheduledMeetings.forEach((call) =>
            removeOptedOutParticipants(call, this.userService.userInfo!.user.hashedEmailAddress ?? ''),
          );

          const officeData: OfficeData = {
            initialOfficeContainerStyles,
            teamInfoData,

            initialScheduledMeetingCount: initialActiveScheduledMeetings,

            childData: {
              elevatorPreviewIsPinnedInitially: elevatorPreviewIsPinned,
              cachedChats: chats,
              cachedEvents: events,
              cachedRecentFiles: recentFiles,
              cachedTrendingFiles: trendingFiles,
              cachedTodoListId: todoListId ?? '',
              cachedTodos: todos,
            },

            ...this.processData({ ...teamInfoData }, { ...activeCalls }, [...events]),
          };

          return officeData;
        },
      ),
      map((officeData) => this.sortUsersInActiveCalls(officeData, this.userService.userInfo!.user.objectId)),
      tap((officeData) => this.officeDataSubject.next({ ...officeData })),
    );
  }

  private processData(
    teamInfoData: TeamInfoData,
    activeCalls: ActiveCalls,
    events: Event[],
  ): {
    activeCalls: ActiveCalls;
    usersNotInACall: UsersNotInCall;
    events: Event[];
  } {
    const userInfo = this.userService.userInfo!;
    const me = userInfo.user;

    // Set call data
    fixStartAndEndTimeOfAllDayMeetings(activeCalls.scheduledMeetings);

    // Delete meetings if (no participant has selected this team)
    // or (I am not an attendee and no one is present)
    // or (i am a visitor, not an attendee and no participant is present)
    const removeMeetingsThatShouldNotBeShown = (targetArray: Call[]) => {
      for (let i = targetArray.length - 1; i >= 0; i--) {
        if (!meetingShouldBeShown(userInfo, targetArray[i])) targetArray.splice(i, 1);
      }
    };

    removeMeetingsThatShouldNotBeShown(activeCalls.scheduledMeetings);
    removeMeetingsThatShouldNotBeShown(activeCalls.impromptuMeetings);
    removeMeetingsThatShouldNotBeShown(activeCalls.breakoutMeetings);

    sortMeetings(me.objectId, activeCalls.scheduledMeetings, CallContext.ScheduledMeeting);
    sortMeetings(me.objectId, activeCalls.impromptuMeetings, CallContext.ImpromptuMeeting);
    sortMeetings(me.objectId, activeCalls.breakoutMeetings, CallContext.BreakoutMeeting);
    sortMeetings(me.objectId, activeCalls.topicMeetings, CallContext.TopicMeeting);

    // Set outside call data
    const allCalls = concatActiveCalls(activeCalls);
    const usersNotInACall = this.getUsersNotInACall(teamInfoData, allCalls);

    sortUsers(usersNotInACall.desks, me.objectId, me.currentTeamObjectId!, true);
    sortUsers(usersNotInACall.inactive, me.objectId, me.currentTeamObjectId!, true, true);

    const filteredEvents = filterEventsByCalls(events, allCalls);

    return { activeCalls, usersNotInACall, events: filteredEvents };
  }

  private getUsersNotInACall(teamInfoData: TeamInfoData, allCalls: Call[]): UsersNotInCall {
    return {
      inactive: this.getUsersOutsideCall(teamInfoData.myTeam!.users, allCalls, Location.Inactive),
      desks: this.getUsersOutsideCall(teamInfoData.myTeam!.users, allCalls, Location.Desks),
    };
  }

  private getUsersOutsideCall(allUsers: User[], allCalls: Call[], baseLocation: Location): User[] {
    const isActiveInMyTeam = (u: User) => u.currentTeamObjectId === this.userService.userInfo!.user.currentTeamObjectId;
    const isInAnyCall = (u: User) => allCalls.some((c) => c.participants.some((p) => p.userObjectId === u.objectId));

    if (baseLocation === Location.Desks) {
      const desksUsersNotInAnyCall = allUsers.filter(
        (u: User) => u.baseLocation === Location.Desks && isActiveInMyTeam(u) && !isInAnyCall(u),
      );

      return desksUsersNotInAnyCall;
    }

    // In the inactive case users that are inactive AND active in other teams need to be considered
    return allUsers.filter(
      (u: User) => !isInAnyCall(u) && (u.baseLocation === Location.Inactive || !isActiveInMyTeam(u)),
    );
  }

  private getTeamInfoDataAndActiveCalls(teamObjectId: string) {
    return combineLatest([this.callService.getActiveCalls(teamObjectId), this.teamService.getTeamInfoData()]).pipe(
      switchMap(([activeCalls, teamInfoData]) =>
        this.createEternalCalls(activeCalls, teamInfoData.myTeam!.displayName).pipe(
          switchMap((meetings) =>
            meetings.length > 0
              ? this.callService.getActiveCalls(teamObjectId).pipe(map((activeCalls) => ({ meetings, activeCalls })))
              : of({ meetings, activeCalls }),
          ),
          switchMap(({ meetings, activeCalls }) => {
            const filteredMeetings = meetings.filter((meeting) => meeting.context !== CallContext.Kitchen);

            const addChatMembersRequest$ =
              filteredMeetings.length > 0
                ? this.addUsersToEternalCalls(filteredMeetings, teamInfoData.myTeam!.users)
                : EMPTY;

            return merge(of({ activeCalls, teamInfoData }), addChatMembersRequest$);
          }),
        ),
      ),
    );
  }

  private createEternalCalls(
    activeCalls: ActiveCalls,
    teamDisplayName: string,
  ): Observable<
    {
      joinWebUrl: string;
      context: CallContext;
    }[]
  > {
    if (this.userService.userInfo!.user.isExternalAccount || this.userService.userInfo!.user.visitingTeamObjectId)
      return of([]);

    return this.callService.createEternalCalls(
      activeCalls,
      teamDisplayName,
      this.tenantService.settings!.featureFlags.sharedKitchen,
    );
  }

  private addUsersToEternalCalls(
    meetings: {
      joinWebUrl: string;
      context: CallContext;
    }[],
    users: User[],
  ) {
    return this.onlineMeetingService
      .addUsersToChats(
        meetings.map((url) => url.joinWebUrl),
        users.map((user) => user.objectId),
      )
      .pipe(switchMap(() => EMPTY));
  }

  private sortUsersInActiveCalls(officeData: OfficeData, userObjectId: string): OfficeData {
    const newOfficeData = { ...officeData };

    if (newOfficeData.activeCalls.desks) sortUsers(newOfficeData.activeCalls.desks.participants, userObjectId);

    if (newOfficeData.activeCalls.kitchen) sortUsers(newOfficeData.activeCalls.kitchen.participants, userObjectId);

    newOfficeData.activeCalls.impromptuMeetings.forEach((meeting) => sortUsers(meeting.participants, userObjectId));
    newOfficeData.activeCalls.scheduledMeetings.forEach((meeting) => sortUsers(meeting.participants, userObjectId));
    newOfficeData.activeCalls.breakoutMeetings.forEach((meeting) => sortUsers(meeting.participants, userObjectId));
    newOfficeData.activeCalls.topicMeetings.forEach((meeting) => sortUsers(meeting.participants, userObjectId));

    return newOfficeData;
  }
}
