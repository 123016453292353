/**
 * @name string-left-right
 * @fileoverview Looks up the first non-whitespace character to the left/right of a given index
 * @version 6.0.17
 * @author Roy Revelt, Codsen Ltd
 * @license MIT
 * {@link https://codsen.com/os/string-left-right/}
 */

import { isPlainObject as p, isStr as b, isInt as R } from "codsen-utils";
import I from "rfdc";
var S = "6.0.17";
var $ = I(),
  j = S,
  c = "\xA0";
function J(n) {
  let e = {
    value: n,
    hungry: !1,
    optional: !1
  };
  return (e.value.endsWith("?*") || e.value.endsWith("*?")) && e.value.length > 2 ? (e.value = e.value.slice(0, e.value.length - 2), e.optional = !0, e.hungry = !0) : e.value.endsWith("?") && e.value.length > 1 ? (e.value = e.value.slice(0, ~-e.value.length), e.optional = !0) : e.value.endsWith("*") && e.value.length > 1 && (e.value = e.value.slice(0, ~-e.value.length), e.hungry = !0), e;
}
function D({
  str: n,
  idx: e = 0,
  stopAtNewlines: l = !1,
  stopAtRawNbsp: o = !1
}) {
  if (typeof n != "string" || !n.length || ((!e || typeof e != "number") && (e = 0), !n[e + 1])) return null;
  if (n[e + 1] && (n[e + 1].trim() || l && `
\r`.includes(n[e + 1]) || o && n[e + 1] === c)) return e + 1;
  if (n[e + 2] && (n[e + 2].trim() || l && `
\r`.includes(n[e + 2]) || o && n[e + 2] === c)) return e + 2;
  for (let t = e + 1, m = n.length; t < m; t++) if (n[t].trim() || l && `
\r`.includes(n[t]) || o && n[t] === c) return t;
  return null;
}
function E(n, e = 0) {
  return D({
    str: n,
    idx: e,
    stopAtNewlines: !1,
    stopAtRawNbsp: !1
  });
}
function k(n, e) {
  return D({
    str: n,
    idx: e,
    stopAtNewlines: !0,
    stopAtRawNbsp: !1
  });
}
function M(n, e) {
  return D({
    str: n,
    idx: e,
    stopAtNewlines: !1,
    stopAtRawNbsp: !0
  });
}
function V({
  str: n,
  idx: e,
  stopAtNewlines: l,
  stopAtRawNbsp: o
}) {
  if (typeof n != "string" || !n.length || ((!e || typeof e != "number") && (e = 0), e < 1)) return null;
  if (n[~-e] && (n[~-e].trim() || l && `
\r`.includes(n[~-e]) || o && n[~-e] === c)) return ~-e;
  if (n[e - 2] && (n[e - 2].trim() || l && `
\r`.includes(n[e - 2]) || o && n[e - 2] === c)) return e - 2;
  for (let t = e; t--;) if (n[t] && (n[t].trim() || l && `
\r`.includes(n[t]) || o && n[t] === c)) return t;
  return null;
}
function y(n, e = 0) {
  return V({
    str: n,
    idx: e,
    stopAtNewlines: !1,
    stopAtRawNbsp: !1
  });
}
function W(n, e) {
  return V({
    str: n,
    idx: e,
    stopAtNewlines: !0,
    stopAtRawNbsp: !1
  });
}
function _(n, e) {
  return V({
    str: n,
    idx: e,
    stopAtNewlines: !1,
    stopAtRawNbsp: !0
  });
}
function O(n, e, l, o, t) {
  if (typeof e != "string" || !e.length || (typeof l != "number" && (l = 0), n === "right" && !e[l + 1] || n === "left" && !e[~-l])) return null;
  let m = l,
    u = [],
    f,
    i,
    s,
    r = 0;
  for (; r < t.length;) {
    if (!b(t[r]) || !t[r].length) {
      r += 1;
      continue;
    }
    let {
        value: a,
        optional: w,
        hungry: T
      } = J(t[r]),
      g = n === "right" ? E(e, m) : y(e, m);
    if (o.i && e[g].toLowerCase() === a.toLowerCase() || !o.i && e[g] === a) {
      let N = n === "right" ? E(e, g) : y(e, g);
      T && (o.i && e[N].toLowerCase() === a.toLowerCase() || !o.i && e[N] === a) ? s = !0 : r += 1, typeof g == "number" && n === "right" && g > m + 1 ? u.push([m + 1, g]) : n === "left" && typeof g == "number" && g < ~-m && u.unshift([g + 1, m]), m = g, n === "right" ? (f === void 0 && (f = g), i = g) : (i === void 0 && (i = g), f = g);
    } else if (w) {
      r += 1;
      continue;
    } else if (s) {
      r += 1, s = void 0;
      continue;
    } else return null;
  }
  return f === void 0 || i === void 0 ? null : {
    gaps: u,
    leftmostChar: f,
    rightmostChar: i
  };
}
var d = {
  i: !1
};
function L(n, e, ...l) {
  if (!l?.length) throw new Error("string-left-right/leftSeq(): only two input arguments were passed! Did you intend to use left() method instead?");
  let o;
  return p(l[0]) ? o = {
    ...d,
    ...l.shift()
  } : o = d, O("left", n, e, o, Array.from(l).reverse());
}
function A(n, e, ...l) {
  if (!l?.length) throw new Error("string-left-right/rightSeq(): only two input arguments were passed! Did you intend to use right() method instead?");
  let o;
  return p(l[0]) ? o = {
    ...d,
    ...l.shift()
  } : o = d, O("right", n, e, o, l);
}
function h(n, e, l, o, t = []) {
  if (typeof e != "string" || !e.length || ((!l || typeof l != "number") && (l = 0), n === "right" && !e[l + 1] || n === "left" && +l == 0)) return null;
  let m = null,
    u = null;
  do m = n === "right" ? A(e, typeof u == "number" ? u : l, ...t) : L(e, typeof u == "number" ? u : l, ...t), m !== null && (u = n === "right" ? m.rightmostChar : m.leftmostChar); while (m);
  if (u != null && n === "right" && (u += 1), u === null) return null;
  if (n === "right") {
    if (e[u]?.trim()) return u;
    let i = E(e, u);
    if (!o || o.mode === 0) {
      if (i === u + 1) return u;
      if (e.slice(u, i || e.length).trim() || e.slice(u, i || e.length).includes(`
`) || e.slice(u, i || e.length).includes("\r")) {
        for (let s = u, r = e.length; s < r; s++) if (`
\r`.includes(e[s])) return s;
      } else return i ? ~-i : e.length;
    } else {
      if (o.mode === 1) return u;
      if (o.mode === 2) {
        let s = e.slice(u);
        if (s.trim() || s.includes(`
`) || s.includes("\r")) {
          for (let r = u, a = e.length; r < a; r++) if (e[r].trim() || `
\r`.includes(e[r])) return r;
        }
        return e.length;
      }
    }
    return i || e.length;
  }
  if (e[u] && e[~-u]?.trim()) return u;
  let f = y(e, u);
  if (!o || o.mode === 0) {
    if (f === u - 2) return u;
    if (e.slice(0, u).trim() || e.slice(0, u).includes(`
`) || e.slice(0, u).includes("\r")) {
      for (let i = u; i--;) if (`
\r`.includes(e[i]) || e[i].trim()) return i + 1 + (e[i].trim() ? 1 : 0);
    }
    return 0;
  }
  if (o.mode === 1) return u;
  if (o.mode === 2) {
    let i = e.slice(0, u);
    if (i.trim() || i.includes(`
`) || i.includes("\r")) {
      for (let s = u; s--;) if (e[s].trim() || `
\r`.includes(e[s])) return s + 1;
    }
    return 0;
  }
  return f !== null ? f + 1 : 0;
}
function H(n, e, ...l) {
  if (!l.length || l.length === 1 && p(l[0])) return null;
  let o = {
    mode: 0
  };
  if (p(l[0])) {
    let t = {
      ...o,
      ...$(l[0])
    };
    if (!t.mode) t.mode = 0;else if (b(t.mode) && t.mode.length === 1 && "0123".includes(t.mode)) t.mode = +t.mode;else if (!R(t.mode)) throw new Error(`string-left-right/chompLeft(): [THROW_ID_01] the opts.mode is wrong! It should be 0, 1, 2 or 3. It was given as ${t.mode} (type ${typeof t.mode})`);
    return h("left", n, e, t, $(l).slice(1));
  }
  return b(l[0]) ? h("left", n, e, o, $(l)) : h("left", n, e, o, $(l).slice(1));
}
function P(n, e, ...l) {
  if (!l.length || l.length === 1 && p(l[0])) return null;
  let o = {
    mode: 0
  };
  if (p(l[0])) {
    let t = {
      ...o,
      ...$(l[0])
    };
    if (!t.mode) t.mode = 0;else if (b(t.mode) && "0123".includes(t.mode)) t.mode = Number.parseInt(t.mode, 10);else if (!R(t.mode)) throw new Error(`string-left-right/chompRight(): [THROW_ID_02] the opts.mode is wrong! It should be 0, 1, 2 or 3. It was given as ${t.mode} (type ${typeof t.mode})`);
    return h("right", n, e, t, $(l).slice(1));
  }
  return b(l[0]) ? h("right", n, e, o, $(l)) : h("right", n, e, o, $(l).slice(1));
}
export { H as chompLeft, P as chompRight, y as left, L as leftSeq, W as leftStopAtNewLines, _ as leftStopAtRawNbsp, E as right, A as rightSeq, k as rightStopAtNewLines, M as rightStopAtRawNbsp, j as version };