import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass, NgIf, NgStyle } from '@angular/common';

import { FrontendAvailability } from '../../../models/services/user.model';

@Component({
  selector: 'app-presence-icon',
  standalone: true,
  templateUrl: './presence.component.html',
  styleUrls: ['./presence.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgStyle, NgIf, NgClass],
})
export class PresenceIconComponent {
  public Availability = FrontendAvailability;

  // Only FrontendAvailability.BeRightBack, FrontendAvailability.InACall, and FrontendAvailability.DoNotDisturb are supported
  @Input({ required: true }) public kind!: FrontendAvailability | 'outOfOffice';
  @Input() public fill = 'white';
  @Input() public backgroundColor = 'var(--bnear-brand)';
  @Input() public iconWithinCircle = true;
}
