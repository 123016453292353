/**
 * Scrolls the container element to the specified element.
 * @param scrollToElement The element to scroll to.
 * @param container The container element to scroll within.
 * @param behavior The scroll behavior, defaults to 'smooth'.
 * @param offset The offset from the top of the element to scroll to, defaults to 60.
 */
export function scrollToElement(
  scrollToElement: HTMLElement,
  container: HTMLElement,
  behavior: ScrollBehavior = 'smooth',
  offset = 60,
): void {
  // Get the bounding rectangles of the container and the element to scroll to.
  const containerRect = container.getBoundingClientRect();
  const elementRect = scrollToElement.getBoundingClientRect();

  // Calculate the top position to scroll to, taking into account the container's position and the specified offset.
  const top = elementRect.top - containerRect.top + container.scrollTop - offset;

  // Scroll the container to the specified position.
  container.scroll({
    top,
    behavior,
  });
}
