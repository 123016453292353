import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgFor } from '@angular/common';

import { TranslocoModule } from '@ngneat/transloco';

import { Group } from 'src/app/shared/models/services/emoji.model';

@Component({
  selector: 'app-emoji-category',
  standalone: true,
  imports: [NgFor, NgClass, TranslocoModule],
  templateUrl: './emoji-category.component.html',
  styleUrls: ['./emoji-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiCategoryComponent {
  @Input() public categories: string[] = [];

  @Input() public selectedCategory: string = Group.Recent;

  @Output() public categoryChange = new EventEmitter<string>();
}
