import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NgClass } from '@angular/common';

import { StatusIconComponent } from '../../icons/status.component';

@Component({
  selector: 'app-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [StatusIconComponent, NgClass],
})
export class StatusBadgeComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input({ transform: (unread: boolean) => (unread ? 'var(--bnear-danger)' : 'unset') })
  @HostBinding('style.--status-bg-color')
  public unread = 'unset';
}
