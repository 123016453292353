import {
  BreakoutMeetingData,
  ImpromptuMeetingData,
  ScheduledMeetingData,
  TopicMeetingData,
} from '../components/meeting.model';
import { GraphUserBaseData, UserBaseData } from './user.model';

export interface Call {
  identifier: string;
  context: CallContext;
  participants: CallParticipant[];
  // Is only null if user is a visitor AND not part of the meeting
  joinWebUrl: string | null;

  // Is null if joinWebUrl exists
  iCalUId: string | null;

  impromptuMeetingData: ImpromptuMeetingData | null;
  scheduledMeetingData: ScheduledMeetingData | null;
  breakoutMeetingData: BreakoutMeetingData | null;
  topicMeetingData: TopicMeetingData | null;
}

export interface CallParticipant extends UserBaseData, GraphUserBaseData {
  objectId: string;
  userObjectId: string;
  // Is false if the participant is only sitting in the call
  isActive: boolean;
  isMeetingGuest: boolean;
  isAnonymized: boolean;
  optOutMeetingOverview: boolean;
}

export enum CallContext {
  Desks = 0,
  Kitchen = 1,
  ImpromptuMeeting = 2,
  ScheduledMeeting = 3,
  BreakoutMeeting = 5,
  TopicMeeting = 6,
}

export interface ActiveCalls {
  desks: Call | null;
  kitchen: Call | null;
  impromptuMeetings: Call[];
  scheduledMeetings: Call[];
  breakoutMeetings: Call[];
  topicMeetings: Call[];
}
