export const HOVER_INSIDE_DELAY_MS = 300;
export const HOVER_OUTSIDE_DELAY_MS = 1_000;

export const HOVER_LOADING_TRANSITION_MS = 200;

export enum SidebarState {
  Collapsed,
  Hovering,
  Pinned,
}

export enum SidebarBreakpoint {
  Collapsed,
  Pinned,
}
