import { catchError, concatMap, forkJoin, map, Observable, of, switchMap, tap, timer } from 'rxjs';
import { Subscription } from '@microsoft/microsoft-graph-types';
import { parseISO, subMinutes } from 'date-fns';

import { ApplicationInsightsService } from '../../services/application-insights.service';
import { CacheService } from '../../services/cache.service';
import { ChatService } from '../../services/chat.service';
import { WebhookService } from '../../services/webhook.service';

import { CHAT_REFRESH_PERIOD_MS, OfficeContainerStyles } from '../../models/components/office.model';
import { Event } from '../../models/services/calendar.model';
import { ActiveCalls, Call } from '../../models/services/call.model';
import { Chat } from '../../models/services/chat/chat.model';
import { ChatMessage } from '../../models/services/chat/message.model';
import { SidebarState } from '../../models/services/sidebar.model';

import { filterEventsByCalls, fixStartAndEndTime, isRelevantEvent } from '../services/calendar.util';
import { concatActiveCalls } from '../services/call.util';
import { chatNeedsUpdate } from '../services/chat/chat.util';

import { environment } from 'src/environments/environment';
import { SIDEBAR_WIDTH, SIDEBAR_WIDTH_COLLAPSED } from 'src/styles/variables';

export const SIDEBAR_HIDDEN_MEDIA_QUERY = '(max-height: 550px) or (max-width: 660px)';

export function getInitialOfficeSizing(sidebarBreakpoint: SidebarState, chatFeatureEnabled: boolean) {
  const topMenuHeightAdjustment = 75;
  const sidebarWidthAdjustment = !chatFeatureEnabled
    ? 0
    : sidebarBreakpoint === SidebarState.Pinned
      ? SIDEBAR_WIDTH
      : SIDEBAR_WIDTH_COLLAPSED;

  const rootElement = document.documentElement;
  const rootElementStyle = window.getComputedStyle(rootElement);
  let width = parseFloat(rootElementStyle.width.slice(0, -2)) - sidebarWidthAdjustment;
  let height = parseFloat(rootElementStyle.height.slice(0, -2)) - topMenuHeightAdjustment;

  if (rootElement.offsetHeight > rootElement.offsetWidth * 0.5625) {
    height = width * 0.5625;
  } else {
    width = height / 0.5625;
  }

  return updateOfficeSizing(document.documentElement, width, height);
}

export function updateOfficeSizing(
  container: HTMLElement,
  initialWidth?: number,
  initialHeight?: number,
): OfficeContainerStyles {
  const computedContainerStyle = window.getComputedStyle(container);
  const width = initialWidth || parseFloat(computedContainerStyle.width.slice(0, -2));
  const height = initialHeight || parseFloat(computedContainerStyle.height.slice(0, -2));

  const marginHorizontal =
    parseFloat(computedContainerStyle.marginLeft.slice(0, -2)) +
    parseFloat(computedContainerStyle.marginRight.slice(0, -2));

  let officeContainerStyles: OfficeContainerStyles;
  const rootElement = document.documentElement;
  if (rootElement.offsetHeight > (rootElement.offsetWidth - marginHorizontal) * 0.5625) {
    officeContainerStyles = {
      width: `calc(100% - ${marginHorizontal}px)`,
      height: `${width * 0.5625}px`,
    };
  } else {
    const marginVertical =
      parseFloat(computedContainerStyle.marginTop.slice(0, -2)) +
      parseFloat(computedContainerStyle.marginBottom.slice(0, -2));

    officeContainerStyles = {
      width: `${height / 0.5625}px`,
      height: `calc(100% - ${marginVertical}px)`,
    };
  }

  // If this is updated, style.util.ts also needs to be updated
  const scaleFactor = `min(${width / 100}px, ${(height / 100) * 1.75}px)`;
  rootElement.style.setProperty('--scale-factor', scaleFactor);

  return officeContainerStyles;
}

export function resetOfficeSizing(): void {
  const rootElement = document.documentElement;
  const rootStyle = window.getComputedStyle(rootElement);
  const width = parseFloat(rootStyle.width.slice(0, -2));

  if (width <= 400) {
    rootElement.style.setProperty('--scale-factor', '2.4vw');
  } else if (width <= 500) {
    rootElement.style.setProperty('--scale-factor', '2.5vw');
  } else if (width <= 600) {
    rootElement.style.setProperty('--scale-factor', '1.8vw');
  } else {
    rootElement.style.setProperty('--scale-factor', 'min(1vw, 1.75vh)');
  }
}

export function createChatSubscriptionsInBackground(
  cacheService: CacheService,
  chatService: ChatService,
  webhookService: WebhookService,
  applicationInsightsService: ApplicationInsightsService,
): Observable<(Chat[] | null)[] | Subscription[] | null> {
  let requestPending = false;

  return timer(0, CHAT_REFRESH_PERIOD_MS).pipe(
    switchMap(() => cacheService.getGraphData().pipe(map(({ chats }) => chats))),
    switchMap((oldCachedChats) =>
      chatService.getChats().pipe(map(({ chats: updatedChats }) => ({ updatedChats, oldCachedChats }))),
    ),
    concatMap(({ updatedChats, oldCachedChats }) => {
      // Create webhooks for the 20 most recent chats
      updatedChats = updatedChats.slice(0, Math.min(20, updatedChats.length));
      if (updatedChats.length === 0 || requestPending) return of(null);

      // Set requestPending to true to prevent multiple requests from being sent at the same time
      requestPending = true;

      // Start by updating all chats that need to be updated, then create webhooks afterwards
      const chatUpdates = updateChats(
        updatedChats,
        oldCachedChats,
        chatService,
        cacheService,
        applicationInsightsService,
      );

      const requests: Observable<(Chat[] | null)[] | Subscription[] | null>[] = [];

      // Process chat updates in chunks to increase responsiveness
      const chunkSize = 2;
      for (let i = 0; i < chatUpdates.length; i += chunkSize) {
        const chunk = chatUpdates.slice(i, i + chunkSize);
        requests.push(forkJoin(chunk));
      }

      const webhookRequests = webhookService.createOrRefreshChatMessageWebhookBatch(updatedChats);
      requests.push(webhookRequests);

      requests.push(of(null).pipe(tap(() => (requestPending = false))));

      return requests.reduce((acc, curr) => acc.pipe(concatMap(() => curr)));
    }),
  );
}

function updateChats(
  chats: Chat[],
  oldCachedChats: Chat[],
  chatService: ChatService,
  cacheService: CacheService,
  applicationInsightsService: ApplicationInsightsService,
): Observable<Chat[] | null>[] {
  return chats.map((chat) => {
    const oldCachedChat = oldCachedChats.find((cachedChat) => cachedChat.id === chat.id);
    if (!chatNeedsUpdate(oldCachedChat, chat)) return of(null);

    return cacheService.getChat(chat.id).pipe(
      concatMap((updatedCachedChat) => chatService.updateChat(chat.id, updatedCachedChat)),
      catchError((error) => {
        if (environment.enableLogging) console.error(error);
        applicationInsightsService.logCustomException(error);

        return of(null);
      }),
    );
  });
}

export function sendRecentPendingMessagesInBackground(
  cacheService: CacheService,
  chatService: ChatService,
): Observable<(ChatMessage | null)[] | null> {
  let anyPendingMessageFailed = false;
  return cacheService
    .updateGraphDataFn(({ chats: cachedChats }) => {
      const fiveMinutesAgo = subMinutes(new Date(), 5);

      // Mark all pending messages older than 5 minutes as failed
      for (const chat of cachedChats) {
        chat.pendingMessages.forEach((pendingMessage) => {
          if (pendingMessage.createdDate < fiveMinutesAgo) {
            pendingMessage.sendingFailed = true;
            anyPendingMessageFailed = true;
          }
        });
      }

      return { chats: cachedChats };
    })
    .pipe(
      concatMap(() =>
        anyPendingMessageFailed
          ? chatService.emitChatsUpdate()
          : cacheService.getGraphData().pipe(map(({ chats }) => chats)),
      ),
      concatMap((cachedChats) => {
        const sendMessageRequests: Observable<ChatMessage | null>[] = [];

        for (const cachedChat of cachedChats) {
          const fiveMinutesAgo = subMinutes(new Date(), 5);
          const retryPendingMessages = cachedChat.pendingMessages.filter(
            (pendingMessage) =>
              pendingMessage.createdDate > fiveMinutesAgo && !pendingMessage.sendingFailed && !pendingMessage.graphId,
          );

          sendMessageRequests.push(
            ...retryPendingMessages.map((pendingMessage) =>
              chatService.sendChatMessage(cachedChat.id, undefined, undefined, pendingMessage),
            ),
          );
        }

        if (sendMessageRequests.length === 0) return of(null);

        return forkJoin(sendMessageRequests);
      }),
    );
}

export function filterEvents(events: Event[], activeCalls: ActiveCalls | undefined): Event[] {
  const calls = concatActiveCalls(activeCalls);
  const eventsFilteredByCalls = filterEventsByCalls(events, calls);

  return eventsFilteredByCalls.filter((event) => isRelevantEvent(event));
}

// ScheduledMeetings marked as 'isAllDay' sometimes do not begin at midnight.
// As a result, they would be displayed for an extra day
export function fixStartAndEndTimeOfAllDayMeetings(scheduledMeetings: Call[]): Call[] {
  scheduledMeetings.forEach((meeting) => {
    if (!meeting.scheduledMeetingData?.isAllDay) return;

    const parsedStartDateTime = parseISO(meeting.scheduledMeetingData.startDateTime);
    const parsedEndDateTime = parseISO(meeting.scheduledMeetingData.endDateTime);

    const { startDateTime: fixedStartDateTime, endDateTime: fixedEndDateTime } = fixStartAndEndTime(
      parsedStartDateTime,
      parsedEndDateTime,
    );

    meeting.scheduledMeetingData.startDateTime = fixedStartDateTime.toISOString();
    meeting.scheduledMeetingData.endDateTime = fixedEndDateTime.toISOString();
  });

  return scheduledMeetings;
}
