import { NgModule } from '@angular/core';
import { NgComponentOutlet, NgIf } from '@angular/common';

import { ToastComponent } from './toast.component';

@NgModule({
  declarations: [ToastComponent],
  imports: [NgIf, NgComponentOutlet],
})
export class ToastModule {}
