import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

import { ButtonComponent } from 'src/app/shared/components/button/button.component';

@Component({
  selector: 'app-send-button',
  templateUrl: './send-button.component.html',
  standalone: true,
  imports: [NgIf, NgClass, ButtonComponent],
  styleUrls: ['./send-button.component.scss'],
})
export class SendButtonComponent {
  @Input() public triggerAnimationSuccess = false;
  @Input() public triggerAnimationFailure = false;

  @Input() public disabled = false;

  @Output() public buttonClick = new EventEmitter<MouseEvent>();
  @Output() public triggerAnimationSuccessChange = new EventEmitter<boolean>();
  @Output() public triggerAnimationFailureChange = new EventEmitter<boolean>();
}
