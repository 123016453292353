<div
  class="container"
  [@fadeInOutTriggered]="{
    value: animationState,
    params: {
      fadeInMs: fadeInAnimationDurationMs,
      fadeOutMs: fadeOutAnimationDurationMs,
      delayInMs: fadeInAnimationDelayMs,
      delayOutMs: fadeOutAnimationDelayMs
    }
  }"
  (@fadeInOutTriggered.done)="onFadeFinished($event)"
>
  <span class="pin"></span>

  <ng-container *ngIf="template; else textTemplate">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </ng-container>

  <ng-template #textTemplate> {{ text }} </ng-template>
</div>
