import { ChangeDetectionStrategy, Component, HostBinding, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [NgClass, ReactiveFormsModule, SpinnerComponent],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent {
  public readonly searchFormControl = new FormControl<string | null>(null);

  @Input()
  @HostBinding('class.search-pending')
  public searchPending = false;

  @Input() public placeholder = '';

  @Output() public searchValueChanges = this.searchFormControl.valueChanges;
}
