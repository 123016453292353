import { AuthData, GeneralData, GraphData, PrimaryKey } from '../models/cache.model';
import { TeamSettings } from '../models/services/team.model';

export function authDataGuard(data: Partial<AuthData>): data is AuthData {
  return 'accessToken' in data;
}

export function getInitialGeneralData(primaryKey: PrimaryKey): GeneralData {
  const generalData: GeneralData = {
    lastVersionCheck: new Date(),
    showTourVideo: true,
    elevatorPreviewIsPinned: false,
    sidebarPinningEnabled: false,
    sidebarLastSelectedTabIndex: 0,

    hints: {
      popOut: {
        count: 0,
      },

      emojiPicker: {
        count: 0,
      },
    },

    teamSettings: new Map<string, TeamSettings>(),
  };

  return migrateLocalStorageData(generalData, primaryKey);
}

// Only read relevant settings that cannot be fetched easier via network
export function migrateLocalStorageData(generalData: GeneralData, primaryKey: PrimaryKey): GeneralData {
  const getLocalStorageKey = (name: string) => `bNearDataCache__${name}_${primaryKey[0]}_${primaryKey[1]}`;

  const getLocalDate = (key: string) => {
    const raw = localStorage.getItem(key);
    return raw ? new Date(raw) : undefined;
  };
  const getNumber = (key: string) => {
    const raw = localStorage.getItem(key);
    return raw === null ? undefined : parseInt(raw);
  };

  generalData.showTourVideo = localStorage.getItem(getLocalStorageKey('showTourVideo')) === 'true';
  generalData.elevatorPreviewIsPinned = localStorage.getItem(getLocalStorageKey('elevatorPreviewIsPinned')) === 'true';

  generalData.hints.interactiveHintsLastShown = getLocalDate('interactiveHintsLastShownDate');
  generalData.hints.joinDesksCallHintLastShown = getLocalDate('joinTableCallHintLastShownDate');

  generalData.hints.popOut.lastShown = getLocalDate('showPopupHintLastShownDate');
  generalData.hints.popOut.count = getNumber('countPopupHint') || 0;

  generalData.hints.emojiPicker.lastShown = getLocalDate('emojiHintLastShownDate');
  generalData.hints.emojiPicker.count = getNumber('countEmojiHint') || 0;

  return generalData;
}

export function getInitialGraphData(): GraphData {
  return { joinedTeams: [], events: [], chats: [], recentFiles: [], trendingFiles: [], todos: [] };
}
