import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { CollaborationToolBase } from '../models/components/collaboration-tool.model';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CollaborationToolService {
  public constructor(private readonly http: HttpClient) {}

  public getCollaborationTools(): Observable<CollaborationToolBase[]> {
    return this.http.get<CollaborationToolBase[]>(`${environment.backendUrl}/api/collaboration-tools`);
  }

  public createOrUpdateCollaborationTool(tool: CollaborationToolBase): Observable<void> {
    return this.http.put<void>(`${environment.backendUrl}/api/collaboration-tools/collaboration-tool`, { tool });
  }
}
