import { Presence as GraphPresence } from '@microsoft/microsoft-graph-types';

import { TourStep } from '../tour.model';
import { CallParticipant } from './call.model';

export interface UserBaseData {
  isExternalAccount: boolean;

  // This is only set if the user is in my team
  isLicenseValid?: boolean;

  baseLocation: Location;
  availability: FrontendAvailability | null;

  selectedEmoji: string | null;
  workLocation: WorkLocation | null;
  individualWorkLocation: string | null;

  currentTeamObjectId: string | null;
  selectedTeamObjectId: string | null;
  visitingTeamObjectId: string | null;

  hashedEmailAddress?: string | null;
}

export interface GraphUserBaseData {
  displayName?: string;
  firstName?: string;
  lastName?: string;

  emailAddress?: string;
  userPrincipalName?: string;

  avatar?: string | null;
  jobTitle?: string | null;
  statusMessage?: string | null;
  outOfOfficeSettings?: OutOfOfficeSettings;
}

export interface User extends UserBaseData, GraphUserBaseData {
  objectId: string;
}

export interface UserInfo {
  user: User;
  settings: UserSettings;

  license: License;

  hasUsedAppAtLeastOnce: boolean;
  automaticallyCheckedIn: boolean;

  todosOrderCsv?: string;
  dismissedEvents: DismissedEvent[];
  tourSteps?: TourStep[];

  readStatus: ReadUserStatus[];

  recentEmojis: string[];
}

export type UserOrParticipant = CallParticipant | User;

export interface License {
  state: LicenseState;
  trialStartedAt: string | null;
  isValid: boolean;
}

export enum LicenseState {
  Trial = 0,
  Purchased = 1,
  PurchaseSuspended = 2,
}

export enum Location {
  Inactive = 0,
  Desks = 1,
}

export enum FrontendAvailability {
  Away = 1,
  Busy = 2,
  Offline = 3,
  BeRightBack = 4,
  InACall = 5,
  Presenting = 6,
  DoNotDisturb = 7,
  Available = 8,
}

export interface UsersNotInCall {
  inactive: User[];
  desks: User[];
}

export interface UserSettings {
  teamsLocale: string | null;
  enhancedPrivacy: boolean;
  optOutMeetingOverview: boolean;
  autoCheckIn: boolean;
  elevator: ElevatorSettings;
}

export interface ElevatorSettings {
  skipEnabled: boolean;
}

export enum WorkLocation {
  Office = 0,
  Home = 1,
  Other = 2,
}

export interface UpdateUserData {
  selectedEmoji?: string | null;
  beRightBack?: boolean;
  baseLocation?: Location;
  workLocation?: WorkLocation | null;
  individualWorkLocation?: string | null;
  settings?: UserSettings;
  todosOrder?: string[];
  tourSteps?: TourStep[];
  readStatus?: ReadUserStatus[];
  recentEmojis?: string[];
}

export interface PresenceBeta extends GraphPresence {
  outOfOfficeSettings?: OutOfOfficeSettings;
}

export interface OutOfOfficeSettings {
  isOutOfOffice: boolean;
  message?: string;
}

export interface Presence {
  activity: string;
  availability: string;
  iso8601ExpirationDuration: string;
}

export interface DismissedEvent {
  identifier: string;
  iCalUId: string;
  joinWebUrl: string | null;
  dismissedAt: string;
}

export interface ReadUserStatus {
  userId: string;
  hashedStatus: string;
}
