import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LogService {
  private readonly enableHistory = environment.enableLogging;
  private readonly historyCount = 100;
  private readonly history: string[] = [];

  private enableDebugStream = false;

  private readonly logsSubject = new Subject<string>();
  public readonly logs$ = this.logsSubject.asObservable();

  public log(context: string, message: unknown, ...optionalParams: unknown[]): void {
    this.handleDebugLogging(context, message, optionalParams);

    console.log(this.getPrefix(context), message, optionalParams);
  }

  public logDebug(context: string, message: unknown, ...optionalParams: unknown[]): void {
    if (!environment.production || environment.enableLogging) {
      this.handleDebugLogging(context, message, optionalParams);

      console.log(this.getPrefix(context), message, optionalParams);
    }
  }

  public logDebugTrace(context: string, message: unknown, ...optionalParams: unknown[]): void {
    if (!environment.production || environment.enableLogging) {
      this.handleDebugLogging(context, message, optionalParams);

      console.trace(this.getPrefix(context), message, optionalParams);
    }
  }

  public toggleDebugStream(): void {
    this.enableDebugStream = !this.enableDebugStream;

    if (!this.enableDebugStream) return;

    for (const log of this.history) {
      this.logsSubject.next(log);
    }
  }

  private getPrefix(context: string): string {
    return `[${context}; ${new Date().toJSON()}]`;
  }

  private handleDebugLogging(context: string, message: unknown, ...optionalParams: unknown[]): void {
    if (this.enableHistory || this.enableDebugStream) {
      const log = `${this.getPrefix(context)} ${JSON.stringify(message)} ${JSON.stringify(optionalParams)}`;
      this.logsSubject.next(log);

      if (this.enableHistory) {
        this.history.push(log);

        if (this.history.length > this.historyCount) this.history.shift();
      }
    }
  }
}
