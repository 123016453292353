// See src/styles/functions.scss
export function computeScaleFactorPx(factor: number, minValue?: number, maxValue?: number): number {
  const rootElement = document.documentElement;
  const scaleFactorRaw = window.getComputedStyle(rootElement).getPropertyValue('--scale-factor');

  // absoluteFunctionPattern matches strings like 'min(1px, 2px)'
  const absoluteFunctionPattern = /min\((\d+[.]{0,1}\d*)px, (\d+[.]{0,1}\d*)px\)/gi;
  const absoluteFunctionMatches = scaleFactorRaw.matchAll(absoluteFunctionPattern).next().value;
  if (absoluteFunctionMatches) {
    const value = factor * Math.min(parseFloat(absoluteFunctionMatches[1]), parseFloat(absoluteFunctionMatches[2]));

    if (!minValue || !maxValue) return value;

    return Math.max(Math.min(value, maxValue), minValue);
  }

  const [oneViewportWidth, oneViewportHeight] = [rootElement.clientWidth / 100, rootElement.clientHeight / 100];

  // relativeFunctionPattern matches strings like 'min(1vw, 2vh)'
  const relativeFunctionPattern = /min\((\d+[.]{0,1}\d*)vw, (\d+[.]{0,1}\d*)vh\)/gi;
  const relativeFunctionMatches = scaleFactorRaw.matchAll(relativeFunctionPattern).next().value;
  if (relativeFunctionMatches) {
    const viewportWidthFactor = parseFloat(relativeFunctionMatches[1]);
    const viewportHeightFactor = parseFloat(relativeFunctionMatches[2]);
    const value = factor * Math.min(viewportWidthFactor * oneViewportWidth, viewportHeightFactor * oneViewportHeight);

    if (!minValue || !maxValue) return value;

    return Math.max(Math.min(value, maxValue), minValue);
  }

  // absoluteValuePattern matches strings like '1vw'
  const relativeValuePattern = /(\d+[.]{0,1}\d*)vw/gi;
  const relativeValueMatches = scaleFactorRaw.matchAll(relativeValuePattern).next().value;
  if (relativeValueMatches) {
    const value = factor * parseFloat(relativeValueMatches[1]) * oneViewportWidth;

    if (!minValue || !maxValue) return value;

    return Math.max(Math.min(value, maxValue), minValue);
  }

  return 0;
}
