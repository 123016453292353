import { Injectable } from '@angular/core';
import { HttpContextToken, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { HttpErrorResponse, HttpInterceptor } from '@angular/common/http';

import { catchError, Observable } from 'rxjs';

import { GlobalSpinnerService } from '../global-spinner.service';

import { environment } from 'src/environments/environment';

export const SET_OFFLINE_ON_FAILURE = new HttpContextToken(() => true);

@Injectable()
export class ClientOfflineInterceptor implements HttpInterceptor {
  public constructor(private readonly globalSpinnerService: GlobalSpinnerService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.globalSpinnerService.offlineScreenIsActive) return next.handle(request);

    const setOfflineOnFailure = request.context.get(SET_OFFLINE_ON_FAILURE);
    if (!setOfflineOnFailure) return next.handle(request);

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (
          !this.globalSpinnerService.offlineScreenIsActive &&
          (err.status === 0 ||
            (request.url.startsWith(environment.backendUrl) && (err.status === 502 || err.status === 504)))
        ) {
          this.globalSpinnerService.showOfflineScreen();
        }

        throw err;
      }),
    );
  }
}
