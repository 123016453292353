/* eslint-disable @angular-eslint/no-input-rename */
import {
  AfterViewChecked,
  AfterViewInit,
  Directive,
  ElementRef,
  inject,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { BehaviorSubject, combineLatest, distinctUntilKeyChanged, of, startWith, switchMap, tap } from 'rxjs';

import { TourService } from '../../services/tour.service';

import { TooltipDirective } from '../tooltip/tooltip.directive';

import { DIRECTIVE_CLASS_NAMES } from '../../models/tour.model';

@Directive({
  selector: '[appTour]',
  standalone: true,
  exportAs: 'appTour',
  hostDirectives: [
    {
      directive: TooltipDirective,
      inputs: [
        'appTooltip: tourTooltip',
        'appTooltipOpen: tourTooltipOpen',
        'appTooltipDirection: tourTooltipDirection',
        'appTooltipBackgroundColor: tourTooltipBackgroundColor',
        'appTooltipBorderRadius: tourTooltipBorderRadius',
        'appTooltipCloseTrigger: tourTooltipCloseTrigger',
        'appTooltipDisabled: tourTooltipDisabled',
        'appTooltipIgnoreCdkOverlayOutsideHover: tourTooltipIgnoreCdkOverlayOutsideHover',
        'appTooltipMinWidth: tourTooltipMinWidth',
        'appTooltipMaxWidth: tourTooltipMaxWidth',
        'appTooltipOpenDelayMs: tourTooltipOpenDelayMs',
        'appTooltipPadding: tourTooltipPadding',
        'appTooltipPinSize: tourTooltipPinSize',
        'appTooltipPinColor: tourTooltipPinColor',
        'appTooltipZIndex: tourTooltipZIndex',
        'appTooltipAutoCloseTimeMs: tourTooltipAutoCloseTimeMs',
        'appTooltipBounceAnimation: tourTooltipBounceAnimation',
        'appTooltipAutoCloseEnabled: tourTooltipAutoCloseEnabled',
        'appTooltipFadeInAnimationDelayMs: tourTooltipFadeInAnimationDelayMs',
      ],
      outputs: [
        'appTooltipOpenChange: tourTooltipOpenChange',
        'appTooltipOutsideClick: tourTooltipOutsideClick',
        'appTooltipPositionChange: tourTooltipPositionChange',
      ],
    },
  ],
})
export class TourDirective implements OnChanges, AfterViewInit, AfterViewChecked {
  private readonly hostElement = inject<ElementRef<HTMLElement>>(ElementRef);
  private readonly tourService = inject(TourService);

  private readonly childElementsChangedSubject = new BehaviorSubject<Element[]>([]);
  private readonly currentStep$ = this.tourService.currentStep$;

  private readonly show$ = combineLatest([
    this.tourService.isActive$,
    this.childElementsChangedSubject.pipe(distinctUntilKeyChanged('length'), startWith(void 0)),
  ]).pipe(
    switchMap(([isActive]) => (isActive ? this.currentStep$ : of(null))),
    tap((step) => {
      this.setClass(this.hostElement.nativeElement, DIRECTIVE_CLASS_NAMES.transition, this.isActive);

      if (step === null) {
        this.setStyles(false);
        this.showTourTooltip = false;

        return;
      }

      const isCurrentStep = Boolean(step.identifier === this.identifier);
      const highlightAtOtherSteps = Boolean(
        this.highlightAtOtherSteps?.includes(step.identifier) && this.context?.includes(step.identifier),
      );

      isCurrentStep || highlightAtOtherSteps ? this.setStyles(false) : this.setStyles(true);
      this.showTourTooltip = isCurrentStep && !highlightAtOtherSteps;

      if (this.showTourTooltip && this.scrollElementIntoView?.nativeElement) {
        this.scrollElementIntoView.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }),
    takeUntilDestroyed(),
  );

  private readonly state$ = combineLatest([this.tourService.isActive$, this.show$]).pipe(
    tap(([isActive, step]) => {
      this.isActive = isActive;

      this.isFirstStep = Boolean(step?.isFirstStep);
      this.isLastStep = Boolean(step?.isLastStep);
    }),
  );

  private isDimmed = false;

  public showTourTooltip = false;
  public isActive = false;
  public isFirstStep = false;
  public isLastStep = false;

  @Input({ required: true, alias: 'appTour' }) public identifier?: string;

  /**
   * A string or a string array of step IDs to set the context of tour step to highlight the step at other steps.
   * This property works in conjunction with the `highlightAtOtherSteps` property.
   *
   * @example
   * context: ['id1', 'id2', 'id3']
   *
   * @alias tourContext
   */
  @Input({ alias: 'tourContext' }) public context?: string[] | string;

  /**
   * An array of step IDs to be included in those specified steps.
   *
   * This property needs to have the `context` property set to properly work
   *
   * @example
   * highlightAtOtherSteps: ['id1', 'id2', 'id3']
   *
   * @alias tourHighlightAtOtherSteps
   */
  @Input({ alias: 'tourHighlightAtOtherSteps' }) public highlightAtOtherSteps?: string[];
  @Input({ alias: 'tourEnabled' }) public enabled = true;
  @Input({ alias: 'tourDarkenBackground' }) public darkenBackground = true;
  @Input({ alias: 'tourWatchForChildElementChanges' }) public watchForChildElementChanges = false;

  @Input({ alias: 'tourDisablePointerEvents' }) public disablePointerEvents: boolean = true;

  @Input({ alias: 'tourScrollElementIntoView' }) public scrollElementIntoView?: ElementRef<HTMLElement>;

  @Input({ alias: 'tourExcludeElementsFromDim' }) public excludeElementsFromDim?: QueryList<ElementRef<HTMLElement>>;

  public ngOnChanges(changes: SimpleChanges): void {
    // If the QueryList is resolved slowly, properly consider the exclusion list once set
    if (
      'excludeElementsFromDim' in changes &&
      !changes.excludeElementsFromDim.previousValue &&
      this.excludeElementsFromDim &&
      this.isDimmed
    ) {
      this.setStyles(false, true);
      this.setStyles(true);
    }

    if (!('tourDisablePointerEvents' in changes)) return;

    if (this.disablePointerEvents)
      this.setClass(this.hostElement.nativeElement, DIRECTIVE_CLASS_NAMES.disablePointerEvents);
    else this.setClass(this.hostElement.nativeElement, DIRECTIVE_CLASS_NAMES.disablePointerEvents, false);

    Array.from(this.hostElement.nativeElement.children).forEach((element) => {
      if (!(element instanceof HTMLElement)) return;

      if (this.disablePointerEvents) this.setClass(element, DIRECTIVE_CLASS_NAMES.disablePointerEvents);
      else this.setClass(element, DIRECTIVE_CLASS_NAMES.disablePointerEvents, false);
    });
  }

  public ngAfterViewInit(): void {
    if (typeof this.context === 'string') this.context = [this.context];

    if (!this.enabled) return;

    this.state$.subscribe();
  }

  public ngAfterViewChecked(): void {
    if (!this.watchForChildElementChanges) return;

    this.childElementsChangedSubject.next(Array.from(this.hostElement.nativeElement.children));
  }

  private setClass(element: HTMLElement, name: string, isEnabled = true): void {
    if (isEnabled) element.classList.add(name);
    else element.classList.remove(name);
  }

  private setStyles(isDimmed: boolean, ignoreExcludedElements = false): void {
    this.isDimmed = isDimmed;

    if (!this.excludeElementsFromDim || ignoreExcludedElements) {
      this.setClass(this.hostElement.nativeElement, DIRECTIVE_CLASS_NAMES.dim, isDimmed);
      this.setClass(this.hostElement.nativeElement, DIRECTIVE_CLASS_NAMES.disablePointerEvents, isDimmed);

      return;
    }

    // Find the nodes that are to be excluded
    const excludedNodes = this.findExcludedNodes(this.hostElement.nativeElement);

    // For each excluded node, we go through its siblings.
    excludedNodes.forEach((excludedNode) => {
      Array.from(excludedNode.parentNode?.children ?? []).forEach((node) => {
        if (!(node instanceof HTMLElement) || this.isNodeExcluded(node)) return;

        this.setClass(node, DIRECTIVE_CLASS_NAMES.dim, isDimmed);
        this.setClass(node, DIRECTIVE_CLASS_NAMES.disablePointerEvents, isDimmed);
      });
    });

    // If there are no excluded nodes, we apply the styles to the element itself.
    if (excludedNodes.length === 0) {
      this.setClass(this.hostElement.nativeElement, DIRECTIVE_CLASS_NAMES.dim, isDimmed);
      this.setClass(this.hostElement.nativeElement, DIRECTIVE_CLASS_NAMES.disablePointerEvents, isDimmed);
    } else if (this.darkenBackground) {
      // If there are excluded nodes, we only set the background color of the element.
      this.setClass(this.hostElement.nativeElement, DIRECTIVE_CLASS_NAMES.backgroundColor, isDimmed);
    }
  }

  private isNodeExcluded(node: HTMLElement): boolean {
    // Check if the current node's id is in the excludeElements array
    return Boolean(this.excludeElementsFromDim?.some((excludedNode) => excludedNode.nativeElement.isSameNode(node)));
  }

  private findExcludedNodes(element: HTMLElement): HTMLElement[] {
    // Initialize an empty array to store the excluded nodes.
    let excludedNodes: HTMLElement[] = [];

    // Iterate over each child node of the given element.
    element.childNodes.forEach((node) => {
      // If the node is an instance of HTMLElement, then we check if it is to be excluded.
      if (node instanceof HTMLElement) {
        // If the node is to be excluded, we add it to the excludedNodes array.
        if (this.isNodeExcluded(node)) {
          excludedNodes.push(node);
        } else {
          // If the node is not to be excluded, we recursively call this method on the node
          // to check its child nodes and add the returned nodes to the excludedNodes array.
          excludedNodes = [...excludedNodes, ...this.findExcludedNodes(node)];
        }
      }
    });

    // Return the array of excluded nodes.
    return excludedNodes;
  }
}
