import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AsyncPipe, NgClass, NgIf, NgStyle, UpperCasePipe } from '@angular/common';

import {
  BehaviorSubject,
  distinctUntilChanged,
  from,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
  tap,
  timer,
  withLatestFrom,
} from 'rxjs';
import { TranslocoModule } from '@ngneat/transloco';

import { EmojiPickerComponent } from '../emoji-picker/emoji-picker.component';
import { EmojiComponent } from '../emojis/emoji/emoji.component';
import { OtrIconComponent } from '../icons/otr.component';
import { PresenceIconComponent } from '../icons/presence/presence.component';
import { NotificationBadgeComponent } from '../notification-badge/notification-badge.component';
import { TourTooltipComponent } from '../tour-tooltip/tour-tooltip.component';
import { AvatarHoverComponent } from './hover/avatar-hover/avatar-hover.component';
import { StatusBadgeComponent } from './status-badge/status-badge.component';

import { ApplicationInsightsService } from '../../services/application-insights.service';
import { AuthService } from '../../services/auth.service';
import { ChatService } from '../../services/chat.service';
import { HintService } from '../../services/hint.service';
import { TeamService } from '../../services/team.service';
import { TenantService } from '../../services/tenant.service';
import { UserService } from '../../services/user.service';

import { GetEmojiPipe } from '../../pipes/get-emoji.pipe';

import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';
import { TourDirective } from '../../directives/tour/tour.directive';

import {
  AVATAR_CONFIG,
  AVATAR_HOVER_DIRECTIONS,
  FauxBorderColor,
  userStatusRefreshIntervalMs,
} from '../../models/components/avatar.model';
import { ParameterlessEvent, USER_OPENED_CHAT } from '../../models/services/application-insights.model';
import { HintType } from '../../models/services/hint.model';
import { TeamInfo } from '../../models/services/team.model';
import { OFFICE_TOUR_STEPS } from '../../models/tour.model';
import { Call } from 'src/app/shared/models/services/call.model';
import {
  FrontendAvailability,
  Location,
  UserOrParticipant,
  WorkLocation,
} from 'src/app/shared/models/services/user.model';

import { fadeInOut } from '../../utils/animation.util';
import {
  computeFauxBorderColor,
  computeInitials,
  computeIsCurrentlyInMyTeam,
  getOtherRoomInfo,
  sha256,
  updateUserGraphData,
} from '../../utils/components/avatar.util';
import { filterNullish } from '../../utils/rxjs.util';
import { openChat } from '../../utils/services/call.util';
import {
  isUserOrParticipantAnonymized,
  isUserOrParticipantMeetingGuest,
  resolveUserGraphUpdate,
} from '../../utils/services/user.util';

import { CircledCrossIconComponent } from '../icons/circled-cross';

@Component({
  selector: 'app-avatar',
  standalone: true,
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  imports: [
    NgIf,
    NgClass,
    NgStyle,
    TooltipDirective,
    TranslocoModule,
    AvatarHoverComponent,
    EmojiPickerComponent,
    UpperCasePipe,
    NotificationBadgeComponent,
    StatusBadgeComponent,
    EmojiComponent,
    GetEmojiPipe,
    AsyncPipe,
    OtrIconComponent,
    PresenceIconComponent,
    TourDirective,
    TourTooltipComponent,
    CircledCrossIconComponent,
  ],
  animations: [fadeInOut],
})
export class AvatarComponent implements OnChanges, AfterViewInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  private unsubscribeHover: Subject<void> = new Subject();
  private unsubscribeChanges?: Subject<void>;
  private resizeObserver?: ResizeObserver;
  private markStatusReadSubject = new Subject<void>();
  private refreshStatusSubject = new BehaviorSubject<void>(void 0);

  private readonly statusRefreshed$ = this.refreshStatusSubject.pipe(
    withLatestFrom(this.userService.userInfo$),
    switchMap(([_, userInfo]) =>
      from(sha256(this.userOrParticipant!.statusMessage || undefined)).pipe(
        map((hashedStatus) => ({ hashedStatus, userInfo })),
      ),
    ),
    map(({ hashedStatus, userInfo }) => {
      const readStatus = userInfo?.readStatus ?? [];

      const cachedStatus = readStatus.find((userStatus) => userStatus.userId === this.userObjectId);

      return cachedStatus?.hashedStatus === hashedStatus;
    }),
  );

  private readonly statusMarkedAsRead$ = this.markStatusReadSubject.pipe(
    switchMap(() => from(sha256(this.userOrParticipant!.statusMessage || undefined))),
    withLatestFrom(this.userService.userInfo$),
    switchMap(([hashedStatus, userInfo]) => {
      if (!this.userOrParticipant?.statusMessage) return of(true);

      const readUserStatuses = userInfo?.readStatus ?? [];

      const index = readUserStatuses.findIndex((userStatus) => userStatus.userId === this.userObjectId);
      if (index !== -1) {
        if (readUserStatuses[index].hashedStatus === hashedStatus) return of(true);

        readUserStatuses[index].hashedStatus = hashedStatus ?? '';
      } else {
        readUserStatuses.push({ userId: this.userObjectId!, hashedStatus: hashedStatus ?? '' });
      }

      return this.userService.updateUser({ readStatus: readUserStatuses }).pipe(map(() => true));
    }),
  );

  public avatarConfig = inject(AVATAR_CONFIG);

  public Availability = FrontendAvailability;
  public Location = Location;
  public HintType = HintType;

  public AvatarHoverDirections = AVATAR_HOVER_DIRECTIONS;
  public OfficeTourSteps = OFFICE_TOUR_STEPS;
  public tourHighlightAtOtherSteps = [
    OFFICE_TOUR_STEPS.desks.identifier,
    OFFICE_TOUR_STEPS.meetingOverview.identifier,
    OFFICE_TOUR_STEPS.topicTables.identifier,
    OFFICE_TOUR_STEPS.kitchen.identifier,
    OFFICE_TOUR_STEPS.joinVoice.identifier,
  ];

  public initialsSize?: number;
  public initials?: string;
  public fauxBorderColor!: FauxBorderColor;
  public enableHover = false;
  public closeAvatarHover = false;
  public hoverBadgeColor?: string;
  public hoverPinColor?: string;

  public unreadMessageCount = 0;

  public isCurrentlyInMyTeam = false;
  public isInOtherRoom = false;
  public otherRoomInfo?: TeamInfo;
  public isActiveInMyCall = false;
  public isVisitor = false;
  public isMe = false;
  public isMeetingGuest = false;
  public isAnonymized = false;

  public showInACallBadge = false;
  public showDoNotDisturbBadge = false;
  public showPresentingBadge = false;
  public showInOtherRoomBadge = false;
  public showOutOfOfficeBadge = false;
  public showBeRightBackBadge = false;
  public showLicenseInvalidBadge = false;

  public showInviteButton = false;
  public showWorkLocation = false;
  public showVisitorBadge = false;
  public enableSchedule = false;
  public showSendChatMessage = false;
  public showCallButton = false;
  public showCalendarButton = false;
  public enableTimelinePlanning = false;
  public enableNotificationBadge = false;
  public showChatButton = false;
  public showEmoji = false;
  public showChatOnDoubleClick = false;
  public hoverOverlayOpen = false;
  public showAutoCheckInHover = false;

  public readonly statusHasBeenRead$ = merge(this.statusMarkedAsRead$, this.statusRefreshed$).pipe(
    distinctUntilChanged(),
    shareReplay(1),
  );

  @ViewChild('avatarBox', { read: ElementRef }) public avatarBox?: ElementRef<HTMLDivElement>;

  @Input() public enableAvailabilityRingColor = true;
  @Input() public enableDoNotDisturbBadge = false;
  @Input() public enableInACallBadge = false;
  @Input() public enablePresentingBadge = false;
  @Input() public enableInOtherRoomBadge = false;
  @Input() public enableOutOfOfficeBadge = false;
  @Input() public enableVisitorBadge = true;
  @Input() public enableCallButton = true;
  @Input() public enableChatOpening = true;
  @Input() public enableEmoji = true;
  @Input() public enableEmojiHint = false;
  @Input() public enableUnreadMessageCount = true;
  @Input() public enableStatus = true;
  @Input() public enableGraphCache = false;
  @Input() public enableAutomaticCheckInHover?: boolean = false;
  @Input() public enableTour = true;
  @Input() public enableLicenseInvalidBadge = false;

  @Input({ required: true }) public tourIsActive!: boolean;

  @Input() public tourContext?: string[] | string;

  @Input() public showWaveAnimation = false;
  @Input() public showBounceAnimation = true;
  @Input() public disableHover = false;

  @Input() public userObjectId?: string;
  @Input() public userOrParticipant?: UserOrParticipant;
  @Input() public myCall?: Call;

  @Input() public individualWorkLocation: string | null = null;
  @Input() public workLocation: WorkLocation | null = null;
  @Input() public selectedEmoji: string | null = null;
  @Input() public currentTeamObjectId?: string | null;
  @Input() public visitingTeamObjectId?: string | null;
  @Input() public availability?: FrontendAvailability | null;
  @Input() public baseLocation?: Location;

  // Graph data that is fetched on init via `userOrParticipant` or these fallback values
  @Input() public fallbackAvatar?: string | null;
  @Input() public fallbackDisplayName?: string;
  @Input() public fallbackFirstName?: string;
  @Input() public fallbackLastName?: string;

  // Emits if the user does not exist anymore, this basically can only happen in OTM because they would be idle
  @Output() public userDeleted = new EventEmitter<void>();
  @Output() public avatarHoverIsActive = new EventEmitter<boolean>();
  @Output() public autoCheckInHoverChange = new EventEmitter<boolean>();

  public constructor(
    public readonly userService: UserService,
    public readonly authService: AuthService,
    public readonly hintService: HintService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly teamService: TeamService,
    private readonly chatService: ChatService,
    private readonly tenantService: TenantService,
    private readonly applicationInsightsService: ApplicationInsightsService,
  ) {
    this.resizeObserver = new ResizeObserver(() => {
      this.computeScaling();

      this.changeDetector.detectChanges();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.unsubscribeChanges?.next();
    this.unsubscribeChanges?.complete();
    this.unsubscribeChanges = new Subject();

    this.handleChanges(changes);
  }

  public ngAfterViewInit(): void {
    this.resizeObserver!.observe(this.avatarBox!.nativeElement, { box: 'border-box' });

    if (this.showAutoCheckInHover) this.autoCheckInHoverChange.emit(true);
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.unsubscribeHover.next();
    this.unsubscribeHover.complete();

    this.resizeObserver?.disconnect();
  }

  public onAvatarDoubleClick(): void {
    if (!this.showChatOnDoubleClick) return;

    this.applicationInsightsService.logCustomEvent(new ParameterlessEvent(USER_OPENED_CHAT));

    openChat(this.authService.context!.user!.id, this.userObjectId!, undefined, this.chatService)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  public onAvatarHoverOpen(isOpen: boolean): void {
    if (this.showAutoCheckInHover && !isOpen) {
      this.showAutoCheckInHover = false;

      if (this.userService.userInfo) {
        this.userService.userInfo.automaticallyCheckedIn = false;
      }

      this.autoCheckInHoverChange.emit(false);
    }

    this.avatarHoverIsActive.emit(isOpen);
    this.hoverOverlayOpen = isOpen;

    if (!isOpen) this.unsubscribeHover.next();

    this.changeDetector.detectChanges();
  }

  public hideAutoCheckInHover(): void {
    if (!this.showAutoCheckInHover) return;

    this.showAutoCheckInHover = false;
    if (this.userService.userInfo) {
      this.userService.userInfo.automaticallyCheckedIn = false;
    }

    this.autoCheckInHoverChange.emit(false);
  }

  public markStatusAsRead(): void {
    this.markStatusReadSubject.next();
  }

  public disableEmojiHint(): void {
    this.hintService.updateUserOpenedEmojiPicker(true);
  }

  public showNextHint(tooltipOpened: boolean): void {
    if (!tooltipOpened) this.hintService.setNextHint();
  }

  public onAvatarHoverPositionChange(direction: 'bottom' | 'center' | 'left' | 'right' | 'top') {
    if (this.isMe) return;

    if (direction === 'top') this.hoverPinColor = this.hoverBadgeColor;
    else this.hoverPinColor = undefined;
  }

  private computeScaling(): void {
    if (!this.avatarBox) return;

    this.initialsSize = this.avatarBox.nativeElement.getBoundingClientRect().width / 2;
  }

  private handleChanges(changes: SimpleChanges): void {
    this.isMe = this.userService.userInfo?.user.objectId === this.userObjectId;

    this.isCurrentlyInMyTeam = computeIsCurrentlyInMyTeam(
      this.userService.userInfo?.user || null,
      this.currentTeamObjectId,
    );

    this.isVisitor = Boolean(this.visitingTeamObjectId) && this.isCurrentlyInMyTeam;
    this.isMeetingGuest = Boolean(this.userOrParticipant) && isUserOrParticipantMeetingGuest(this.userOrParticipant!);
    this.isAnonymized = Boolean(this.userOrParticipant) && isUserOrParticipantAnonymized(this.userOrParticipant!);

    // Set showSendChatMessage, showChatOnDoubleClick, showChatButton, enableNotificationBadge
    this.setChatSettings().pipe(takeUntil(this.unsubscribeChanges!)).subscribe();

    // Update graph data
    if ('userOrParticipant' in changes && this.userOrParticipant) {
      if (changes.userOrParticipant.isFirstChange()) {
        this.showAutoCheckInHover =
          this.isMe &&
          Boolean(this.avatarConfig?.enableAutomaticCheckInHover ?? this.enableAutomaticCheckInHover) &&
          Boolean(this.userService.userInfo?.automaticallyCheckedIn) &&
          !this.tourIsActive;

        if (this.showAutoCheckInHover) this.autoCheckInHoverChange.emit(true);

        this.handleInitialUserOrParticipantChange();
      } else if (changes.userOrParticipant.previousValue) {
        resolveUserGraphUpdate(changes.userOrParticipant.previousValue, this.userOrParticipant);
      }

      if (this.teamService.teamInfoData$.value) {
        this.otherRoomInfo = getOtherRoomInfo(this.teamService.teamInfoData$.value, this.userOrParticipant);
      }
    }

    // Set initials if fallback provided
    if (
      !this.initials &&
      ('fallbackFirstName' in changes || 'fallbackLastName' in changes || 'fallbackDisplayName' in changes)
    ) {
      this.initials = computeInitials({
        firstName: this.fallbackFirstName,
        lastName: this.fallbackLastName,
        displayName: this.fallbackDisplayName,
      });
    }

    if ('myCall' in changes) {
      this.isActiveInMyCall = Boolean(
        this.myCall?.participants.some((p) => p.userObjectId === this.userObjectId && p.isActive),
      );
    }

    this.enableHover =
      this.userObjectId !== undefined &&
      !(this.avatarConfig?.disableHover ?? this.disableHover) &&
      !this.isMeetingGuest;

    this.showWorkLocation =
      ((this.avatarConfig?.enableWorkLocation ?? true) && this.baseLocation !== Location.Inactive) ||
      this.availability === FrontendAvailability.BeRightBack;

    this.showLicenseInvalidBadge =
      !this.userOrParticipant?.isLicenseValid &&
      Boolean(this.avatarConfig?.enableLicenseInvalidBadge ?? this.enableLicenseInvalidBadge);

    this.showVisitorBadge =
      (this.avatarConfig?.enableVisitorBadge ?? this.enableVisitorBadge) &&
      !this.showLicenseInvalidBadge &&
      this.isVisitor &&
      !this.isMeetingGuest;
    this.showInACallBadge =
      (this.avatarConfig?.enableInACallBadge ?? this.enableInACallBadge) &&
      !this.showLicenseInvalidBadge &&
      !this.showVisitorBadge &&
      this.availability === FrontendAvailability.InACall;
    this.showDoNotDisturbBadge =
      (this.avatarConfig?.enableDoNotDisturbBadge ?? this.enableDoNotDisturbBadge) &&
      !this.showLicenseInvalidBadge &&
      !this.showVisitorBadge &&
      this.availability === FrontendAvailability.DoNotDisturb;
    this.showPresentingBadge =
      (this.avatarConfig?.enablePresentingBadge ?? this.enablePresentingBadge) &&
      !this.showLicenseInvalidBadge &&
      !this.showVisitorBadge &&
      this.availability === FrontendAvailability.Presenting;

    const isCheckedIn = this.baseLocation !== undefined && this.baseLocation !== Location.Inactive;
    this.isInOtherRoom = !this.isMeetingGuest && isCheckedIn && !this.isCurrentlyInMyTeam;

    const isBeRightBack = this.availability === FrontendAvailability.BeRightBack;
    this.showBeRightBackBadge =
      this.isCurrentlyInMyTeam && isBeRightBack && !this.isInOtherRoom && !this.isMeetingGuest;

    this.showInOtherRoomBadge =
      (this.avatarConfig?.enableInOtherRoomBadge ?? this.enableInOtherRoomBadge) &&
      !this.showLicenseInvalidBadge &&
      this.isInOtherRoom;

    const iAmAVisitor = Boolean(this.userService.userInfo?.user.visitingTeamObjectId);
    this.showEmoji =
      (this.avatarConfig?.enableEmoji ?? this.enableEmoji) &&
      this.isCurrentlyInMyTeam &&
      !this.isVisitor &&
      !this.isMeetingGuest &&
      !this.isInOtherRoom &&
      !iAmAVisitor;

    this.enableSchedule =
      (this.avatarConfig?.enableSchedule ?? true) &&
      !this.authService.isExternalAccount &&
      ((this.userOrParticipant && !this.userOrParticipant.isExternalAccount) ?? false) &&
      !this.isMeetingGuest &&
      !this.isAnonymized;

    const iAmActiveInMyCall = Boolean(
      this.myCall?.participants.some((p) => p.userObjectId === this.userService.userInfo?.user.objectId && p.isActive),
    );
    this.showInviteButton = iAmActiveInMyCall && !this.isActiveInMyCall && !this.isMeetingGuest && !this.isMe;

    this.showCallButton =
      !this.showInviteButton &&
      (this.avatarConfig?.enableCallButton ?? this.enableCallButton) &&
      !this.authService.isExternalAccount &&
      !this.isMeetingGuest &&
      !this.isMe;

    this.showCalendarButton =
      (this.avatarConfig?.enableCalendarButton ?? true) &&
      !this.authService.isExternalAccount &&
      !this.isMeetingGuest &&
      !this.isMe;

    this.enableTimelinePlanning =
      (this.avatarConfig?.enableTimeLinePlanning ?? true) &&
      !this.authService.isExternalAccount &&
      !this.isMeetingGuest;

    this.fauxBorderColor = computeFauxBorderColor(
      this.availability,
      this.avatarConfig?.enableAvailabilityRingColor ?? this.enableAvailabilityRingColor,
      this.avatarConfig?.enableOutOfOfficeBadge ?? this.enableOutOfOfficeBadge
        ? this.userOrParticipant?.outOfOfficeSettings
        : undefined,
    );
  }

  private handleInitialUserOrParticipantChange(): void {
    updateUserGraphData(
      this.userService,
      this.userOrParticipant!,
      this.avatarConfig?.enableGraphCache ?? this.enableGraphCache,
    )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((userOrParticipant) => {
        if (userOrParticipant === null) {
          this.userDeleted.emit();

          return;
        }

        // Even though `updateUserGraphData` works in-place on the `userOrParticipant` object passed into it,
        // this reference may change if a new object is input while the request is in-flight. This happens if a SignalR update occurs in just the right second
        resolveUserGraphUpdate(userOrParticipant, this.userOrParticipant!);
        this.initials = computeInitials({
          firstName: this.userOrParticipant!.firstName,
          lastName: this.userOrParticipant!.lastName,
          displayName: this.userOrParticipant!.displayName,
        });

        this.changeDetector.detectChanges();
      });

    if (this.authService.isExternalAccount) return;

    if (
      (this.avatarConfig?.enableStatus ?? this.enableStatus) ||
      (this.avatarConfig?.enableOutOfOfficeBadge ?? this.enableOutOfOfficeBadge)
    ) {
      timer(0, userStatusRefreshIntervalMs)
        .pipe(
          takeUntil(this.unsubscribe),
          switchMap(() => this.userService.getPresence(this.userObjectId!, !this.isMe)),
          tap((presence) => {
            if (this.avatarConfig?.enableStatus ?? this.enableStatus) {
              this.userOrParticipant!.statusMessage = presence.statusMessage?.message?.content || null;
            }

            if (this.avatarConfig?.enableOutOfOfficeBadge ?? this.enableOutOfOfficeBadge) {
              this.userOrParticipant!.outOfOfficeSettings = presence.outOfOfficeSettings;
              this.showOutOfOfficeBadge =
                !this.showVisitorBadge &&
                this.availability !== FrontendAvailability.BeRightBack &&
                Boolean(this.userOrParticipant?.outOfOfficeSettings?.isOutOfOffice);

              this.fauxBorderColor = computeFauxBorderColor(
                this.availability,
                this.avatarConfig?.enableAvailabilityRingColor ?? this.enableAvailabilityRingColor,
                this.userOrParticipant?.outOfOfficeSettings,
              );
            }

            this.refreshStatusSubject.next();
          }),
        )
        .subscribe(() => this.changeDetector.detectChanges());
    }

    if (this.isMe || !(this.avatarConfig?.enableUnreadMessageCount ?? this.enableUnreadMessageCount)) return;

    this.handleChatUpdate();
  }

  private handleChatUpdate(): void {
    this.chatService.chats$
      .pipe(
        takeUntil(this.unsubscribe),
        map((chats) =>
          chats.find(
            (chat) => chat.chatType === 'oneOnOne' && chat.members.find((user) => user.userId === this.userObjectId),
          ),
        ),
        filterNullish(),
        takeUntil(this.unsubscribe),
      )
      .subscribe((chat) => (this.unreadMessageCount = chat.metadata.unreadMessageCount ?? 0));
  }

  private setChatSettings(): Observable<unknown> {
    return this.authService.isMobileWidth$.pipe(
      tap((isMobileWidth) => {
        const chatFeatureEnabled =
          Boolean(this.tenantService.settings?.featureFlags.chat) && !this.authService.isExternalAccount;

        this.showSendChatMessage =
          (this.avatarConfig?.enableQuickMessage ?? true) &&
          chatFeatureEnabled &&
          !this.isMe &&
          !isMobileWidth &&
          !this.isMeetingGuest &&
          !this.isAnonymized;
        this.showChatOnDoubleClick = this.showSendChatMessage && chatFeatureEnabled;
        this.showChatButton = this.showSendChatMessage && chatFeatureEnabled;
        this.enableNotificationBadge = chatFeatureEnabled;

        this.changeDetector.detectChanges();
      }),
    );
  }
}
