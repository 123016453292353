import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, concatMap, Observable, shareReplay, Subject, tap } from 'rxjs';
import { catchError, map, merge, of } from 'rxjs';
import { Event as MSGraphEvent, ScheduleInformation } from '@microsoft/microsoft-graph-types';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { addDays } from 'date-fns';

import { ApplicationInsightsService } from './application-insights.service';
import { AuthService } from './auth.service';
import { CacheService } from './cache.service';
import { OnlineMeetingService } from './online-meeting.service';
import { UserService } from './user.service';

import { Event } from '../models/services/calendar.model';
import { Presence } from '../models/services/user.model';

import { getGraphPages } from '../utils/network.util';
import { createEventByGraphEvent, findEvent, isDismissedEvent } from '../utils/services/calendar.util';
import { getEntryByEvent } from '../utils/services/online-meeting.util';

import { ERROR_MAILBOX_API_DISABLED, GRAPH_API_BASE } from '../constants';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CalendarService {
  private readonly http = inject(HttpClient);
  private readonly userService = inject(UserService);
  private readonly authService = inject(AuthService);
  private readonly cacheService = inject(CacheService);
  private readonly onlineMeetingService = inject(OnlineMeetingService);
  private readonly applicationInsightsService = inject(ApplicationInsightsService);

  private readonly eventsSubject = new Subject<Event[]>();

  private readonly apiEnabledSubject = new BehaviorSubject<boolean>(true);
  public readonly apiEnabled$ = this.apiEnabledSubject.asObservable();

  public readonly events$ = merge(
    this.cacheService.getGraphData().pipe(map(({ events }) => events)),
    this.eventsSubject,
  ).pipe(
    map((events) => events.filter((event) => this.keepEvent(event))),
    shareReplay(1),
  );

  public getSchedules(
    userEmailAddresses: string[],
    start: Date,
    end: Date,
    availabilityViewInterval = 15,
  ): Observable<ScheduleInformation[]> {
    if (!this.apiEnabledSubject.value) return of([]);

    return this.http
      .post<{ value: ScheduleInformation[] }>(
        `${GRAPH_API_BASE}/me/calendar/getSchedule`,
        {
          availabilityViewInterval,
          schedules: userEmailAddresses,
          startTime: { dateTime: start.toUTCString(), timeZone: 'UTC' },
          endTime: { dateTime: end.toUTCString(), timeZone: 'UTC' },
        },
        { headers: { Prefer: 'IdType="ImmutableId"' } },
      )
      .pipe(
        map((s) => s.value.filter((s) => !s.error)),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404 && error.message.includes(ERROR_MAILBOX_API_DISABLED)) {
            this.apiEnabledSubject.next(false);
            return of([]);
          }

          if (environment.enableLogging) console.error(error);
          this.applicationInsightsService.logCustomException(error);

          return of([]);
        }),
      );
  }

  public emitEvents(): Observable<Event[]> {
    return this.cacheService.getGraphData().pipe(
      map(({ events }) => events.filter((event) => this.keepEvent(event))),
      tap((events) => this.eventsSubject.next(events)),
    );
  }

  public getEvents(checkIgnoredEvents: boolean): Observable<Event[]> {
    if (this.authService.isExternalAccount) return of([]);
    if (!this.apiEnabledSubject.value) return of([]);

    const now = new Date();
    const startDate = startOfDay(subDays(now, 1));
    const endDate = endOfDay(addDays(now, 7));

    return this.getMyEvents(startDate, endDate, checkIgnoredEvents).pipe(
      tap((events) => this.eventsSubject.next(events)),
    );
  }

  public updatePreferredPresence(presence: Presence): Observable<void> {
    return this.http.put<void>(`${environment.backendUrl}/api/user/presence`, presence);
  }

  public hideMeeting(
    eventId: string | null | undefined,
    joinWebUrl: string | null | undefined,
    iCalUId: string | null | undefined,
  ): Observable<Event[]> {
    return this.cacheService
      .updateGraphDataFn(({ events }) => {
        const event = findEvent(events, eventId, joinWebUrl, iCalUId);
        if (!event) return null;

        event.shouldBeIgnored = true;

        return { events };
      })
      .pipe(concatMap(() => this.emitEvents()));
  }

  private getMyEvents(startDateTime: Date, endDateTime: Date, checkIgnoredEvents: boolean): Observable<Event[]> {
    return this.getMyGraphEvents(startDateTime, endDateTime).pipe(
      map((events) => {
        const filteredEvents = events.filter((event) => this.keepEvent(event) && !event.isCancelled);
        const mappedEvents = filteredEvents.map((event) => createEventByGraphEvent(event));

        mappedEvents.sort((a, b) => (a.startDate < b.startDate ? -1 : 1));

        return mappedEvents;
      }),
      concatMap((events) => this.mapEvents(events, checkIgnoredEvents)),
      concatMap((events) => this.cacheService.updateGraphDataFn(() => ({ events })).pipe(map(() => events))),
    );
  }

  private getMyGraphEvents(startDateTime: Date, endDateTime: Date): Observable<MSGraphEvent[]> {
    const startDateTimeString = encodeURIComponent(startDateTime.toISOString());
    const endDateTimeString = encodeURIComponent(endDateTime.toISOString());

    const initialEndpoint = `${GRAPH_API_BASE}/me/calendar/calendarView?startDateTime=${startDateTimeString}&endDateTime=${endDateTimeString}`;

    return getGraphPages<MSGraphEvent>(initialEndpoint, false, this.http, { Prefer: 'IdType="ImmutableId"' }).pipe(
      map(({ collection }) => collection),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404 && error.message.includes(ERROR_MAILBOX_API_DISABLED)) {
          this.apiEnabledSubject.next(false);
          return of([]);
        }

        if (environment.enableLogging) console.error(error);
        this.applicationInsightsService.logCustomException(error);

        return of([]);
      }),
    );
  }

  private keepEvent(event: Event | MSGraphEvent): boolean {
    return (
      Boolean(event.id) &&
      (('endDate' in event && event.endDate > new Date()) || !('endDate' in event)) &&
      !this.iDeclined(event) &&
      !isDismissedEvent(event, this.userService.userInfo?.dismissedEvents ?? [])
    );
  }

  private iDeclined(event: MSGraphEvent): boolean {
    if (!event.attendees) return false;

    const myEmailAddress = this.userService.userInfo!.user.emailAddress;
    const me = event.attendees.find((a) => a.emailAddress === myEmailAddress);

    return me?.status?.response === 'declined';
  }

  private mapEvents(events: Event[], checkIgnoredEvents: boolean): Observable<Event[]> {
    if (events.length === 0) return of([]);

    if (!checkIgnoredEvents) {
      return this.cacheService.getGraphData().pipe(
        map(({ events: cachedEvents }) =>
          events.map((event) => {
            const cachedEvent = getEntryByEvent(event.joinWebUrl, event.iCalUId, cachedEvents);

            return {
              ...event,
              shouldBeIgnored: cachedEvent?.shouldBeIgnored ?? false,
            };
          }),
        ),
      );
    }

    const joinWebUrls: string[] = [];
    const iCalUIds: string[] = [];

    events.forEach((event) => {
      const isTeamsMeeting = !event.isZoomMeeting && !event.isGoogleMeeting;

      if (event.joinWebUrl && isTeamsMeeting) joinWebUrls.push(decodeURIComponent(event.joinWebUrl));
      else if (event.iCalUId) iCalUIds.push(event.iCalUId);
    });

    return this.onlineMeetingService.getOnlineMeetings(joinWebUrls, iCalUIds, true).pipe(
      map((onlineMeetings) =>
        events.map((event) => {
          const onlineMeeting = getEntryByEvent(event.joinWebUrl, event.iCalUId, onlineMeetings);

          return {
            ...event,
            // If meeting is hidden via outlook, event.shouldBeIgnored is set to true and we use this value
            // If false, we need to check if the meeting was hidden via bNear
            shouldBeIgnored: event.shouldBeIgnored ? event.shouldBeIgnored : onlineMeeting?.shouldBeIgnored ?? false,
            isExternal: onlineMeeting?.isExternal ?? false,
          };
        }),
      ),
    );
  }
}
