import { ConnectedPosition, OverlayRef } from '@angular/cdk/overlay';

export class TooltipRef {
  public constructor(public readonly overlayRef: OverlayRef) {}

  public close(): void {
    this.overlayRef.detach();
    this.overlayRef.dispose();
  }
}

export type TooltipGeneralDirection = 'bottom' | 'left' | 'right' | 'top';

export type TooltipSpecificDirection =
  | TooltipGeneralDirection
  | 'bottom-center'
  | 'bottom-left'
  | 'bottom-right'
  | 'left-bottom'
  | 'left-center'
  | 'left-top'
  | 'right-bottom'
  | 'right-center'
  | 'right-top'
  | 'top-center'
  | 'top-left'
  | 'top-right';

export class TooltipDirectionList {
  public constructor(public directions: TooltipSpecificDirection[]) {}
}

// Bottom positions
export const OVERLAY_BOTTOM: ConnectedPosition = {
  originY: 'bottom',
  originX: 'center',
  overlayX: 'center',
  overlayY: 'top',
  panelClass: ['overlay-position-bottom', 'overlay-position-center'],
};

export const OVERLAY_BOTTOM_CENTER_LEFT_TOP: ConnectedPosition = {
  originY: 'bottom',
  originX: 'center',
  overlayX: 'end',
  overlayY: 'top',
  panelClass: ['overlay-position-bottom', 'overlay-position-end'],
};

export const OVERLAY_BOTTOM_CENTER_RIGHT_TOP: ConnectedPosition = {
  originY: 'bottom',
  originX: 'center',
  overlayX: 'start',
  overlayY: 'top',
  panelClass: ['overlay-position-bottom', 'overlay-position-start'],
};

export const OVERLAY_BOTTOM_LEFT_LEFT_TOP: ConnectedPosition = {
  originY: 'bottom',
  originX: 'end',
  overlayX: 'end',
  overlayY: 'top',
  panelClass: ['overlay-position-bottom', 'overlay-position-end'],
};

// Top positions
export const OVERLAY_TOP: ConnectedPosition = {
  originY: 'top',
  originX: 'center',
  overlayX: 'center',
  overlayY: 'bottom',
  panelClass: ['overlay-position-top', 'overlay-position-center'],
};

export const OVERLAY_TOP_CENTER_LEFT_BOTTOM: ConnectedPosition = {
  originY: 'top',
  originX: 'center',
  overlayX: 'end',
  overlayY: 'bottom',
  panelClass: ['overlay-position-top', 'overlay-position-end'],
};

export const OVERLAY_TOP_CENTER_RIGHT_BOTTOM: ConnectedPosition = {
  originY: 'top',
  originX: 'center',
  overlayX: 'start',
  overlayY: 'bottom',
  panelClass: ['overlay-position-top', 'overlay-position-start'],
};

export const OVERLAY_TOP_LEFT_LEFT_BOTTOM: ConnectedPosition = {
  originY: 'top',
  originX: 'end',
  overlayX: 'end',
  overlayY: 'bottom',
  panelClass: ['overlay-position-top', 'overlay-position-end'],
};

// Left positions
export const OVERLAY_LEFT: ConnectedPosition = {
  originY: 'center',
  originX: 'start',
  overlayX: 'end',
  overlayY: 'center',
  panelClass: ['overlay-position-left', 'overlay-position-center'],
};

export const OVERLAY_LEFT_CENTER_BOTTOM_RIGHT: ConnectedPosition = {
  originY: 'center',
  originX: 'start',
  overlayX: 'end',
  overlayY: 'bottom',
  panelClass: ['overlay-position-left', 'overlay-position-end'],
};

export const OVERLAY_LEFT_CENTER_TOP_RIGHT: ConnectedPosition = {
  originY: 'center',
  originX: 'start',
  overlayX: 'end',
  overlayY: 'top',
  panelClass: ['overlay-position-left', 'overlay-position-start'],
};

// Right positions
export const OVERLAY_RIGHT: ConnectedPosition = {
  originY: 'center',
  originX: 'end',
  overlayX: 'start',
  overlayY: 'center',
  panelClass: ['overlay-position-right', 'overlay-position-center'],
};

export const OVERLAY_RIGHT_CENTER_BOTTOM_LEFT: ConnectedPosition = {
  originY: 'center',
  originX: 'end',
  overlayX: 'start',
  overlayY: 'bottom',
  panelClass: ['overlay-position-right', 'overlay-position-end'],
};

export const OVERLAY_RIGHT_CENTER_TOP_LEFT: ConnectedPosition = {
  originY: 'center',
  originX: 'end',
  overlayX: 'start',
  overlayY: 'top',
  panelClass: ['overlay-position-right', 'overlay-position-start'],
};

// Fallback position
export const OVERLAY_CENTER_PUSH_REQUIRED: ConnectedPosition = {
  originY: 'center',
  originX: 'center',
  overlayX: 'center',
  overlayY: 'center',
  panelClass: 'overlay-push-required',
};
