import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

import { DEFAULT_SLIDE_DISTANCE_PERCENTAGE } from '../models/animation.model';

export const fadeInTriggered = trigger('fadeInTriggered', [
  state('hidden', style({ opacity: 0 })),
  state('visible', style({ opacity: 1 })),
  transition('hidden => visible', [animate('{{ fadeInMs }}ms {{ delayInMs }}ms ease-in')], {
    params: { fadeInMs: 225, delayInMs: 0 },
  }),
]);

export const fadeInOutTriggered = trigger('fadeInOutTriggered', [
  state('*', style({ opacity: 1 })),
  transition('void => visible', [style({ opacity: 0 }), animate('{{ fadeInMs }}ms {{ delayInMs }}ms ease-in')], {
    params: { fadeInMs: 225, delayInMs: 0 },
  }),
  transition('hidden => visible', [style({ opacity: 0 }), animate('{{ fadeInMs }}ms {{ delayInMs }}ms ease-in')], {
    params: { fadeInMs: 225, delayInMs: 0 },
  }),

  transition(
    'visible => hidden',
    [style({ opacity: 1 }), animate('{{ fadeOutMs }}ms {{ delayOutMs }}ms ease-out', style({ opacity: 0 }))],
    {
      params: { fadeOutMs: 225, delayOutMs: 0 },
    },
  ),
  transition(
    'visible => void',
    [style({ opacity: 1 }), animate('{{ fadeOutMs }}ms {{ delayOutMs }}ms ease-out', style({ opacity: 0 }))],
    {
      params: { fadeOutMs: 225, delayOutMs: 0 },
    },
  ),
]);

export const fadeInOut = trigger('fadeInOut', [
  state('void', style({ opacity: 0 })),
  transition(':enter', [animate('{{ fadeInMs }}ms {{ delayInMs }}ms ease-in')], {
    params: { fadeInMs: 800, delayInMs: 0 },
  }),
  transition(':leave', [animate('{{ fadeOutMs }}ms {{ delayOutMs }}ms ease-out')], {
    params: { fadeOutMs: 800, delayOutMs: 0 },
  }),
]);

export const growInOutTriggered = trigger('growInOutTriggered', [
  state('void', style({ height: '0', opacity: 0 })),
  state('hidden', style({ height: '0', opacity: 0 })),
  state('visible', style({ height: '*', opacity: 1 })),
  transition('hidden <=> visible', [animate('{{ durationMs }}ms {{ delayMs }}ms ease-in-out')], {
    params: { durationMs: 250, delayMs: 0 },
  }),
  transition('void <=> visible', [animate('{{ durationMs }}ms {{ delayMs }}ms ease-in-out')], {
    params: { durationMs: 250, delayMs: 0 },
  }),
]);

export const enterGrowVertical = trigger('enterGrowVertical', [
  transition(
    ':enter',
    [
      state('void', style({ height: '0', opacity: 0 })),
      style([{ height: '0', opacity: 0, overflow: 'hidden' }]),
      animate('{{ durationMs }}ms {{ delayMs }}ms ease-in', style([{ height: '*' }, { opacity: 1 }])),
    ],
    { params: { durationMs: 300, delayMs: 700 } },
  ),
]);

export const leaveShrinkVertical = trigger('leaveShrinkVertical', [
  transition(
    ':leave',
    [style({ height: '*' }), animate('{{ durationMs }}ms {{ delayMs }}ms ease-out', style({ height: '0' }))],
    { params: { durationMs: 300, delayMs: 700 } },
  ),
]);

export const slideInOutTriggered = trigger('slideInOutTriggered', [
  state('*', style({ opacity: 1 })),
  transition(
    'void => fromBottom',
    [
      style({ transform: 'translateY({{ slideDistancePercentage }}%)', opacity: 0 }),
      animate('{{ slideInMs }}ms ease-in-out'),
    ],
    { params: { slideDistancePercentage: DEFAULT_SLIDE_DISTANCE_PERCENTAGE, slideInMs: 300 } },
  ),
  transition(
    'void => fromRight',
    [
      style({ transform: 'translateX({{ slideDistancePercentage }}%)', opacity: 0 }),
      animate('{{ slideInMs }}ms ease-in-out'),
    ],
    { params: { slideDistancePercentage: DEFAULT_SLIDE_DISTANCE_PERCENTAGE, slideInMs: 300 } },
  ),
  transition(
    'void => fromTop',
    [
      style({ transform: 'translateY(-{{ slideDistancePercentage }}%)', opacity: 0 }),
      animate('{{ slideInMs }}ms ease-in-out'),
    ],
    { params: { slideDistancePercentage: DEFAULT_SLIDE_DISTANCE_PERCENTAGE, slideInMs: 300 } },
  ),
  transition(
    'void => fromLeft',
    [
      style({ transform: 'translateX(-{{ slideDistancePercentage }}%)', opacity: 0 }),
      animate('{{ slideInMs }}ms ease-in-out'),
    ],
    { params: { slideDistancePercentage: DEFAULT_SLIDE_DISTANCE_PERCENTAGE, slideInMs: 300 } },
  ),

  transition(
    'fromBottom => hidden',
    animate(
      '{{ slideOutMs }}ms ease-in-out',
      style({ transform: 'translateY({{ slideDistancePercentage }}%)', opacity: 0 }),
    ),
    { params: { slideDistancePercentage: DEFAULT_SLIDE_DISTANCE_PERCENTAGE, slideOutMs: 300 } },
  ),
  transition(
    'fromRight => hidden',
    animate(
      '{{ slideOutMs }}ms ease-in-out',
      style({ transform: 'translateX({{ slideDistancePercentage }}%)', opacity: 0 }),
    ),
    { params: { slideDistancePercentage: DEFAULT_SLIDE_DISTANCE_PERCENTAGE, slideOutMs: 300 } },
  ),
  transition(
    'fromTop => hidden',
    animate(
      '{{ slideOutMs }}ms ease-in-out',
      style({ transform: 'translateY(-{{ slideDistancePercentage }}%)', opacity: 0 }),
    ),
    { params: { slideDistancePercentage: DEFAULT_SLIDE_DISTANCE_PERCENTAGE, slideOutMs: 300 } },
  ),
  transition(
    'fromLeft => hidden',
    animate(
      '{{ slideOutMs }}ms ease-in-out',
      style({ transform: 'translateX(-{{ slideDistancePercentage }}%)', opacity: 0 }),
    ),
    { params: { slideDistancePercentage: DEFAULT_SLIDE_DISTANCE_PERCENTAGE, slideOutMs: 300 } },
  ),
]);

export const slideInOutBottom = trigger('slideInOutBottom', [
  state('*', style({ opacity: 1 })),
  transition(
    ':enter',
    [
      style({ transform: 'translateY({{ slideDistancePercentage }}%)', opacity: 0 }),
      animate('{{ slideInMs }}ms ease-in-out'),
    ],
    { params: { slideDistancePercentage: DEFAULT_SLIDE_DISTANCE_PERCENTAGE, slideInMs: 300 } },
  ),
  transition(
    ':leave',
    animate(
      '{{ slideOutMs }}ms ease-in-out',
      style({ transform: 'translateY({{ slideDistancePercentage }}%)', opacity: 0 }),
    ),
    { params: { slideDistancePercentage: DEFAULT_SLIDE_DISTANCE_PERCENTAGE, slideOutMs: 300 } },
  ),
]);

export const bounceTriggered = trigger('bounceTriggered', [
  transition(
    '* => bounce',
    [
      animate(
        '{{ durationMs }}ms ease-in-out',
        keyframes([
          style({ transform: 'translateY(0)' }),
          style({ transform: 'translateY({{ yTransformationDistance }})' }),
          style({ transform: 'translateY(0)' }),
        ]),
      ),
    ],
    { params: { yTransformationDistance: '10px', durationMs: 500 } },
  ),
]);
