<app-button
  class="min-max-css-values"
  [disabled]="disabled"
  [triggerFailureAnimation]="triggerAnimationFailure"
  [triggerSuccessAnimation]="triggerAnimationSuccess"
  (buttonClick)="buttonClick.emit($event)"
  (failureAnimationChange)="triggerAnimationFailureChange.emit($event)"
  (successAnimationChange)="triggerAnimationSuccessChange.emit($event)"
>
  <img
    class="send"
    src="assets/img/icons/send-message.svg"
    [ngClass]="{
      'animation-running': triggerAnimationSuccess
    }"
  />

  <img
    src="assets/img/icons/checkmark-white.svg"
    [ngClass]="{
      success: true,
      'animation-running': triggerAnimationSuccess
    }"
  />
</app-button>
