import { Injectable, Injector } from '@angular/core';

import { ICustomProperties } from '@microsoft/applicationinsights-core-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { app } from '@microsoft/teams-js';

import { AuthService } from './auth.service';
import { UserService } from './user.service';

import { ICustomEvent } from '../models/services/application-insights.model';

import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ApplicationInsightsService {
  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsights.instrumentationKey,
      isCookieUseDisabled: true,
      enableAutoRouteTracking: true,
      enableAjaxErrorStatusText: true,
      maxBatchInterval: 1000 * 60,
      isRetryDisabled: true,
      samplingPercentage: 50,
    },
  });

  public constructor(
    private readonly injector: Injector,
    private readonly authService: AuthService,
  ) {}

  public initializeAppInsights(context?: app.Context): void {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!environment.appInsights.instrumentationKey || this.appInsights.core.isInitialized!()) return;

    this.appInsights.loadAppInsights();

    if (context) {
      this.appInsights.setAuthenticatedUserContext(`${context.user!.tenant!.id}:${context.user!.id}`);
    }
  }

  public logEvent(name: string, properties?: ICustomProperties): void {
    this.appInsights.trackEvent({ name: name, properties: properties });
  }

  public logPageView(name?: string, uri?: string): void {
    this.appInsights.trackPageView({ name, uri });
  }

  public logCustomException(customException: Error, exceptionId?: string): void {
    const exceptionTelemetry: IExceptionTelemetry = {
      exception: customException,
      id: exceptionId,
    };
    this.appInsights.trackException(exceptionTelemetry);
    this.appInsights.flush();
  }

  public logCustomEvent(event: ICustomEvent): void {
    const context = this.authService.context;

    const telemetry = event.intoEventTelemetry({
      tenantObjectId: context!.user!.tenant!.id!,
      teamObjectId: this.injector.get(UserService).userInfo?.user.currentTeamObjectId ?? undefined,
      userObjectId: context!.user!.id!,
      appSessionId: context!.app.sessionId,
      teamsSessionId: context!.app.host.sessionId,
    });
    this.appInsights.trackEvent(telemetry.event, telemetry.properties);
  }
}
