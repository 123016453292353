<ng-container *transloco="let t; read: 'linkList'">
  <!-- Display list -->
  <div *ngIf="!editing" class="tooltip-container">
    <div class="pin"></div>

    <div class="header" (click)="!userService.userInfo!.user.visitingTeamObjectId && startEdit()">
      <span class="name">{{ linkList.groupName | translateCollaborationToolName }}</span>
      <img src="assets/img/icons/settings.svg" />
    </div>

    <div class="items">
      <div
        *ngIf="convertedItems.length === 0"
        class="add-item-hint"
        (click)="!userService.userInfo!.user.visitingTeamObjectId && startEdit()"
      >
        {{ t("hintAddFirstCard") }}

        <img class="arrow" src="assets/img/icons/arrows/doodle.svg" />
      </div>

      <div *ngFor="let item of convertedItems" class="item" (click)="openLink(item)">
        <img [src]="item.faviconLink ?? 'assets/img/icons/link.svg'" />
        <div class="text">
          <span class="name">{{ item.name }}</span>
          <span class="domain">{{ item.domain }}</span>
        </div>
      </div>
      <div class="last-element"></div>
    </div>
  </div>

  <!-- Edit list -->
  <div *ngIf="editing" class="tooltip-container editing">
    <div class="pin"></div>

    <div class="header">
      <input
        class="name"
        maxlength="26"
        type="text"
        [formControl]="forms.groupName"
        [placeholder]="linkList.groupName | translateCollaborationToolName"
      />
      <app-button
        [disabled]="submittingData || (anyEditChangesExist && forms.groupName.invalid)"
        [ngClass]="{ 'submitting-data': submittingData }"
        (buttonClick)="saveEdit()"
      >
        {{ t("done") }}
      </app-button>
    </div>

    <div cdkDropList class="drag-container items" (cdkDropListDropped)="moveItemToDropTarget($event)">
      <div
        #item
        *ngFor="let item of convertedItems; index as i"
        cdkDrag
        cdkDragBoundary=".drag-container"
        cdkDragLockAxis="y"
        class="item"
      >
        <img [src]="item.faviconLink ?? 'assets/img/icons/link.svg'" />

        <div cdkDragHandle class="text">
          <span class="name">{{ item.name }}</span>
          <span class="domain">{{ item.domain }}</span>
        </div>

        <div
          class="delete"
          [ngStyle]="submittingData ? { opacity: '0.5' } : {}"
          (click)="!submittingData && deleteItem(i)"
        >
          <img src="assets/img/icons/delete.svg" />
        </div>
      </div>
    </div>

    <div *ngIf="convertedItems.length > 0" class="divider"></div>

    <div class="add item">
      <span class="title">{{ t("title") }}</span>
      <span class="subtitle">{{ t("subtitle") }}</span>

      <form [formGroup]="forms.newItem">
        <input formControlName="name" maxlength="28" type="text" [placeholder]="t('name')" />
        <input formControlName="webLink" maxlength="2048" type="text" [placeholder]="t('permalink')" />
      </form>

      <app-button [disabled]="forms.newItem.invalid || submittingData" (buttonClick)="addItem()">
        {{ t("add") }}
      </app-button>
    </div>
  </div>
</ng-container>
