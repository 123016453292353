import { AbstractControl } from '@angular/forms';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { expand, last, map, Observable, of, switchMap, take, takeWhile, tap, timer } from 'rxjs';
import { catchError, delay, retryWhen, timeout } from 'rxjs';
import { differenceInSeconds, subSeconds } from 'date-fns';

import { CacheService } from '../services/cache.service';
import { LogService } from '../services/log.service';

import { filterNullish } from './rxjs.util';

import { environment } from 'src/environments/environment';

const LOGGING_CONTEXT = 'NetworkUtil';

export function reloadApp(
  httpClient: HttpClient,
  logService: LogService,
  cacheService: CacheService,
  assumeNetworkConnected: boolean,
): void {
  logService.logDebugTrace('Reload app', assumeNetworkConnected);

  cacheService.generalData$
    .pipe(
      filterNullish(),
      take(1),
      switchMap((generalData) =>
        differenceInSeconds(new Date(), generalData.lastAppReloadAt ?? subSeconds(new Date(), 5)) <= 5
          ? timer(5_000)
          : of(0),
      ),
      switchMap(() => cacheService.updateGeneralData({ lastAppReloadAt: new Date() })),
      tap(() => {
        if (assumeNetworkConnected) {
          window.location.replace(window.location.href);
        } else {
          // Figure out at what point we have an internet connection
          waitForBackendConnectivityOrTimeout(httpClient, 30 * 1_000).subscribe((isOnline) => {
            if (isOnline) logService.logDebug(LOGGING_CONTEXT, 'Online, reloading now...');
            else logService.logDebug(LOGGING_CONTEXT, 'Not online, reloading now anyways...');

            window.location.replace(window.location.href);
          });
        }
      }),
    )
    .subscribe();
}

export function waitForBackendConnectivityOrTimeout(
  httpClient: HttpClient,
  timeoutMs: number,
  retryDelayMs = 250,
  useRouteWithAuthentication = false,
): Observable<boolean> {
  const route = !useRouteWithAuthentication ? environment.backendUrl : `${environment.backendUrl}/api/user/info`;

  return httpClient.get(route, { responseType: 'text' }).pipe(
    retryWhen((errors) =>
      errors.pipe(
        delay(retryDelayMs),
        tap((err: HttpErrorResponse) => {
          if (err.status !== 0 && err.status !== 503 && err.status !== 504) throw err;
        }),
      ),
    ),
    map(() => true),
    timeout({ each: timeoutMs }),
    catchError(() => of(false)),
    take(1),
  );
}

export const isDomainValidator = (control: AbstractControl<string>) => {
  const tldRegex = /.+?\..+?/;

  try {
    const url = addProtocolToUrlIfMissing(control.value);

    return url.hostname && tldRegex.test(url.hostname) ? null : { domain: 'invalidDomain' };
  } catch {
    return { domain: 'invalidDomain' };
  }
};

export const addProtocolToUrlIfMissing = (url: string) =>
  url.startsWith('https://') || url.startsWith('http://') ? new URL(url) : new URL(`https://${url}`);

export function getGraphPages<T>(
  initialEndpoint: string,
  emitPagesInParallel: boolean,
  httpService: HttpClient,
  requestHeaders?:
    | HttpHeaders
    | {
        [header: string]: string[] | string;
      },
): Observable<{ collection: T[]; nextLink: string | undefined }> {
  const getPage = (endpoint: string) =>
    httpService
      .get<{ value: T[]; '@odata.nextLink': string | undefined }>(endpoint, {
        headers: requestHeaders,
      })
      .pipe(
        map(({ value: collection, '@odata.nextLink': nextLink }) => ({
          collection,
          nextLink: nextLink || undefined,
        })),
      );

  const getPages$ = getPage(initialEndpoint).pipe(
    expand(({ collection: currentCollection, nextLink }) =>
      nextLink
        ? getPage(nextLink).pipe(
            map(({ collection, nextLink }) => ({ collection: [...currentCollection, ...collection], nextLink })),
          )
        : of({ collection: currentCollection, nextLink }),
    ),
    takeWhile(({ nextLink }) => Boolean(nextLink), true),
  );

  if (emitPagesInParallel) return getPages$;

  return getPages$.pipe(last());
}
