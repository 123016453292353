import { inject, Injectable } from '@angular/core';

import { concatMap, filter, fromEvent, map, Subject, switchMap, take, tap, timer } from 'rxjs';

import { AuthService } from './auth.service';
import { CacheService } from './cache.service';
import { TourService } from './tour.service';
import { UserService } from './user.service';

import { Hints } from '../models/cache.model';
import { ActiveCalls, Call, CallParticipant } from '../models/services/call.model';
import { CallParticipantCase, HintType } from '../models/services/hint.model';

import {
  computeInteractiveText,
  getTopicMeetingIndexForHint,
  isEligibleForEmojiHint,
  isEligibleForJoinDesksCallHint,
  isEligibleForPopOutHint,
  shouldEmojiHintBeShown,
  shouldInteractiveHintBeShown,
  shouldJoinDesksCallHintBeShown,
  shouldPopOutHintBeShown,
} from '../utils/services/hint.util';

@Injectable({
  providedIn: 'root',
})
export class HintService {
  private readonly authService = inject(AuthService);
  private readonly userService = inject(UserService);
  private readonly cacheService = inject(CacheService);
  private readonly tourService = inject(TourService);

  private activeCalls?: ActiveCalls;
  private myCall: Call | undefined;

  private userOpenedEmojiPicker = false;
  private desksHintShown = false;
  private kitchenHintShown = false;

  private myCallChange = new Subject<Call | undefined>();
  private activeCallsChange = new Subject<ActiveCalls>();
  private userOpenedEmojiPickerChange = new Subject<boolean>();
  private currentHintChange = new Subject<HintType | null>();

  private readonly isTourActive$ = this.tourService.isActive$.pipe(filter((isActive) => !isActive));

  private readonly currentHintChange$ = this.currentHintChange.pipe(
    switchMap((hint) => this.isTourActive$.pipe(map(() => hint))),
    tap((value) => (this.currentHint = value)),
  );
  private readonly myCallChange$ = this.myCallChange.pipe(tap((value) => (this.myCall = value)));
  private readonly activeCallsChange$ = this.activeCallsChange.pipe(tap((value) => (this.activeCalls = value)));
  private readonly userOpenedEmojiPickerChange$ = this.userOpenedEmojiPickerChange.pipe(
    tap((value) => (this.userOpenedEmojiPicker = value)),
  );

  public currentHint: HintType | null = null;
  public topicMeetingIndexForHint: number | null = null;

  public enableHints = false;
  public automaticAvatarHoverIsOpen = false;

  public callParticipantCase?: CallParticipantCase;
  public commaSeparatedNames?: string;
  public lastName?: string;

  public constructor() {
    this.currentHintChange$.subscribe();
    this.myCallChange$.subscribe();
    this.activeCallsChange$.subscribe();
    this.userOpenedEmojiPickerChange$.subscribe();
  }

  public updateMyCall(myCall: Call | undefined): void {
    if (myCall?.joinWebUrl === this.myCall?.joinWebUrl) return;

    this.myCallChange.next((this.myCall = myCall));

    if (
      (this.currentHint === HintType.JoinDesksCall ||
        this.currentHint === HintType.InteractiveJoinDesksCall ||
        this.currentHint === HintType.InteractiveJoinKitchen ||
        this.currentHint === HintType.InteractiveJoinTopicCall) &&
      myCall
    ) {
      this.setNextHint();
    }
  }

  public updateActiveCalls(activeCalls: ActiveCalls): void {
    this.activeCallsChange.next((this.activeCalls = activeCalls));
  }

  public updateUserOpenedEmojiPicker(userOpenedEmojiPicker: boolean): void {
    this.userOpenedEmojiPickerChange.next((this.userOpenedEmojiPicker = userOpenedEmojiPicker));

    if (userOpenedEmojiPicker && this.currentHint === HintType.Emoji) this.setNextHint();
  }

  // This function sets the interpolated strings for interactive hints
  public setInteractiveHintText(callParticipants?: CallParticipant[] | null): void {
    [this.commaSeparatedNames, this.lastName] = [undefined, undefined];

    const interactiveText = computeInteractiveText(callParticipants);
    this.commaSeparatedNames = interactiveText.commaSeparatedNames;
    this.lastName = interactiveText.nameOfLastParticipant;
    this.callParticipantCase = interactiveText.callParticipantCase;
  }

  // This function handles the logic in which order the hints are displayed and is called again after each hint is closed
  public setNextHint(): void {
    this.currentHintChange.next(null);

    if (!this.enableHints || this.automaticAvatarHoverIsOpen) return;

    const updateHintData = (hints: Hints): Hints => {
      if (isEligibleForPopOutHint(this.authService, this.userService) && shouldPopOutHintBeShown(hints)) {
        this.showPopOutHint(hints);
        return hints;
      }

      const showInteractiveHint = shouldInteractiveHintBeShown({
        myCall: this.myCall,
        activeCalls: this.activeCalls,
        hints,
      });
      if (showInteractiveHint === 'topicMeeting') {
        this.showInteractiveTopicMeetingHint(hints);
        return hints;
      } else if (showInteractiveHint === 'kitchen') {
        this.showInteractiveKitchenHint(hints);
        return hints;
      } else if (showInteractiveHint === 'desks') {
        this.showInteractiveDesksHint(hints);
        return hints;
      }

      if (
        isEligibleForJoinDesksCallHint({ myCall: this.myCall, hints }, this.userService) &&
        shouldJoinDesksCallHintBeShown(hints)
      ) {
        this.showJoinDesksCallHint(hints);
        return hints;
      }

      if (
        isEligibleForEmojiHint(
          { myCall: this.myCall, userOpenedEmojiPicker: this.userOpenedEmojiPicker },
          this.userService,
        ) &&
        shouldEmojiHintBeShown(
          {
            mainTableHintShown: this.desksHintShown,
            kitchenHintShown: this.kitchenHintShown,
            hints,
          },
          this.userService,
        )
      ) {
        this.showEmojiHint(hints);
        return hints;
      }

      return hints;
    };

    timer(2 * 1000)
      .pipe(concatMap(() => this.cacheService.updateGeneralDataFn(({ hints }) => ({ hints: updateHintData(hints) }))))
      .subscribe();
  }

  private showPopOutHint(hints: Hints): void {
    this.currentHintChange.next(HintType.PopOut);

    fromEvent(window, 'resize')
      .pipe(take(1))
      .subscribe(() => {
        if (this.currentHint === HintType.PopOut) this.setNextHint();
      });

    hints.popOut.count += 1;
    hints.popOut.lastShown = new Date();
  }

  private showJoinDesksCallHint(hints: Hints): void {
    this.currentHintChange.next(HintType.JoinDesksCall);

    this.desksHintShown = true;
    hints.joinDesksCallHintLastShown = new Date();
  }

  private showEmojiHint(hints: Hints): void {
    this.currentHintChange.next(HintType.Emoji);

    hints.emojiPicker.count += 1;
    hints.emojiPicker.lastShown = new Date();
  }

  private showInteractiveDesksHint(hints: Hints): void {
    this.showInteractiveHint(HintType.InteractiveJoinDesksCall, this.activeCalls?.desks?.participants, hints);
    this.desksHintShown = true;
  }

  private showInteractiveKitchenHint(hints: Hints): void {
    this.showInteractiveHint(HintType.InteractiveJoinKitchen, this.activeCalls?.kitchen?.participants, hints);
    this.kitchenHintShown = true;
  }

  private showInteractiveTopicMeetingHint(hints: Hints): void {
    const topicMeetingIndex = getTopicMeetingIndexForHint(this.activeCalls?.topicMeetings);
    this.topicMeetingIndexForHint = topicMeetingIndex;

    if (topicMeetingIndex === null) return;

    this.showInteractiveHint(
      HintType.InteractiveJoinTopicCall,
      this.activeCalls?.topicMeetings[topicMeetingIndex]?.participants,
      hints,
    );
  }

  private showInteractiveHint(type: HintType, callParticipants: CallParticipant[] | undefined, hints: Hints): void {
    this.setInteractiveHintText(callParticipants);

    this.currentHintChange.next(type);
    hints.joinDesksCallHintLastShown = new Date();
    hints.interactiveHintsLastShown = new Date();
  }
}
