import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgFor } from '@angular/common';

import { EmojiComponent } from '../emoji/emoji.component';

import { Emoji } from 'src/app/shared/models/services/emoji.model';

@Component({
  selector: 'app-emoji-group',
  standalone: true,
  imports: [NgFor, EmojiComponent],
  template: `
    <span>{{ name }}</span>
    <div class="emojis">
      <app-emoji
        *ngFor="let emoji of emojis"
        [animateOnHover]="true"
        [emoji]="emoji"
        (emojiClick)="emojiChange.emit($event)"
        (loadingFinished)="onLoadingFinished($event)"
      />
    </div>
  `,
  styleUrls: ['./emoji-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiGroupComponent {
  public loadedEmojiCount = 0;

  @Input({ required: true }) public name!: string;
  @Input({ required: true }) public emojis!: Emoji[];

  @Output() public readonly emojiChange = new EventEmitter<Emoji>();
  @Output() public readonly loadingFinished = new EventEmitter<boolean>();

  public onLoadingFinished(isLoaded: boolean): void {
    if (isLoaded) this.loadedEmojiCount++;
    else this.loadedEmojiCount = Math.max(this.loadedEmojiCount - 1, 0);

    this.loadingFinished.emit(this.loadedEmojiCount === this.emojis.length);
  }
}
