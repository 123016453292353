import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { GetEmojiUrlPipe } from 'src/app/shared/pipes/get-emoji-url.pipe';

import { Emoji, EmojiStyle } from 'src/app/shared/models/services/emoji.model';

@Component({
  selector: 'app-emoji',
  standalone: true,
  imports: [GetEmojiUrlPipe],
  template: `<img
    [src]="emoji | getEmojiUrl: emojiStyle : 'Default'"
    [title]="enableTitle ? emoji.dirName : ''"
    (load)="loadingFinished.emit(true)"
  />`,
  styles: [
    `
      img {
        width: 100%;
        pointer-events: all;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiComponent implements OnChanges {
  @Input({ required: true }) public emoji!: Emoji;

  @Input() public emojiStyle: EmojiStyle = '3D';
  @Input() public animateOnHover = false;

  @Input() public enableTitle = true;

  @Output() public readonly emojiClick = new EventEmitter<Emoji>();
  @Output() public readonly loadingFinished = new EventEmitter<boolean>();

  @HostListener('click') public onClick(): void {
    this.emojiClick.emit(this.emoji);
  }

  @HostListener('pointerenter') public onPointerEnter(): void {
    if (!this.animateOnHover) return;

    this.emojiStyle = 'Animated';
  }

  @HostListener('pointerleave') public onPointerLeave(): void {
    if (!this.animateOnHover) return;

    this.emojiStyle = '3D';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('emoji' in changes && !changes.emoji.isFirstChange()) this.loadingFinished.next(false);
  }
}
