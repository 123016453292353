import { concatMap, Observable } from 'rxjs';
import { Subscription } from '@microsoft/microsoft-graph-types';

import { ChatService } from '../../services/chat.service';

import { ActiveCalls, Call } from '../../models/services/call.model';
import { CallParticipant } from '../../models/services/call.model';
import { User } from '../../models/services/user.model';

import { resolveUserBaseDataUpdate, resolveUserGraphUpdate } from './user.util';

export function concatActiveCalls(activeCalls?: ActiveCalls): Call[] {
  if (!activeCalls) return [];

  return activeCalls.impromptuMeetings.concat(
    activeCalls.scheduledMeetings,
    activeCalls.breakoutMeetings,
    activeCalls.topicMeetings,
    activeCalls.kitchen ? [activeCalls.kitchen] : [],
    activeCalls.desks ? [activeCalls.desks] : [],
  );
}

export function getDroppedParticipants(oldCall: Call, newCall: Call): CallParticipant[] {
  return oldCall.participants.filter(
    (oldP) => !newCall.participants.some((newP) => oldP.userObjectId === newP.userObjectId),
  );
}

export function resolveCallUpdate(oldCall: Call, newCall: Call): Call {
  // Write data that was fetched via graph into the updated user
  const oldParticipants = oldCall.participants;

  for (const participant of newCall.participants) {
    const index = oldCall.participants.findIndex((p) => p.userObjectId === participant.userObjectId);

    if (index !== -1) resolveUserGraphUpdate(participant, oldParticipants[index]);
  }

  return newCall;
}

export function resolveTeamsInfoDataUpdateForCallParticipants(users: User[], call: Call): Call {
  for (const participant of call.participants) {
    const user = users.find((u) => u.objectId === participant.userObjectId);
    if (user) resolveUserBaseDataUpdate(user, participant);
  }

  return call;
}

// TODO: Pre-fill chat with message if provided, what if the chat already has a composing message?
export function openChat(
  myUserObjectId: string,
  targetUserObjectId: string,
  message: string | undefined,
  chatService: ChatService,
): Observable<Subscription | null> {
  return chatService
    .getOrCreateChat('oneOnOne', [myUserObjectId, targetUserObjectId])
    .pipe(concatMap((chat) => chatService.openChat(chat.id!)));
}

export function geDesksCallSubject(teamDisplayName: string): string {
  return `${teamDisplayName} – Desks`;
}

export function getTopicCallSubject(subject: string): string {
  return `${subject} – Topic Table`;
}

export function getKitchenCallSubject(teamDisplayName: string, sharedKitchen: boolean): string {
  return sharedKitchen ? 'Kitchen' : `${teamDisplayName} – Kitchen`;
}
