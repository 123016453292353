import { ICustomProperties } from '@microsoft/applicationinsights-web';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';

import { AuthService } from '../../services/auth.service';

import { CallContext } from './call.model';
import { Location } from './user.model';

export const USER_OPENED_CHAT = 'UserOpenedChat';
export const USER_CLICKED_COLLABORATION_TOOL = 'UserClickedCollaborationTool';
export const USER_SELECTED_EMOJI = 'UserSelectedEmoji';
export const USER_CHANGED_WORK_LOCATION = 'UserChangedWorkLocation';
export const USER_HAS_WORK_LOCATION = 'UserHasWorkLocation';
export const USER_CHECKED_IN = 'UserCheckedIn';
export const USER_HOVERED_AVATAR = 'UserHoveredAvatar';
export const USER_PLANNED_MEETING = 'UserPlannedMeeting';
export const USER_SENT_CHAT_MESSAGE = 'UserSentChatMessage';
export const USER_SET_STATUS_MESSAGE = 'UserSetStatusMessage';
export const USER_OPENED_FILE = 'UserOpenedFile';
export const USER_OPENED_EMBED = 'UserOpenedEmbed';

interface CustomProperties extends ICustomProperties {
  tenantObjectId: string;
  teamObjectId: string | undefined;
  userObjectId: string;
  appSessionId: string;
  teamsSessionId: string;
}

export abstract class ICustomEvent {
  public constructor(public name: string) {}

  public intoEventTelemetry(_: CustomProperties): {
    event: IEventTelemetry;
    properties: CustomProperties;
  } {
    throw 'intoEventTelemetry is not implemented';
  }

  public intoEventTelemetryHelper(properties: CustomProperties): {
    event: IEventTelemetry;
    properties: CustomProperties;
  } {
    return {
      event: { name: this.name },
      properties: properties,
    };
  }
}

export class ParameterlessEvent extends ICustomEvent {
  public constructor(name: string) {
    super(name);
  }

  public override intoEventTelemetry(properties: CustomProperties): {
    event: IEventTelemetry;
    properties: CustomProperties;
  } {
    return super.intoEventTelemetryHelper(properties);
  }
}

export class UserOpenedAppEvent extends ICustomEvent {
  public constructor(public authService: AuthService) {
    super('UserOpenedApp');
  }

  public override intoEventTelemetry(properties: CustomProperties): {
    event: IEventTelemetry;
    properties: CustomProperties;
  } {
    return super.intoEventTelemetryHelper({
      triggeredByNotification: this.authService.appOpenedByNotificationEntity || null,
      hostClientType: this.authService.context?.app.host.clientType || null,
      isMultiWindow: this.authService.context?.page.isMultiWindow || false,
      isExternalAccount: this.authService.isExternalAccount,
      ...properties,
    });
  }
}

export class UserLoadedOfficeEvent extends ICustomEvent {
  public constructor(public isExternalAccount: boolean) {
    super('UserLoadedOffice');
  }

  public override intoEventTelemetry(properties: CustomProperties): {
    event: IEventTelemetry;
    properties: CustomProperties;
  } {
    return super.intoEventTelemetryHelper({ isExternalAccount: this.isExternalAccount, ...properties });
  }
}

export class UserJoinedCallEvent extends ICustomEvent {
  public constructor(
    public callContext: CallContext,
    public isInitiatingImpromptuMeeting = false,
    public isJoiningInactively = false,
  ) {
    super('UserJoinedCall');
  }

  public override intoEventTelemetry(properties: CustomProperties): {
    event: IEventTelemetry;
    properties: CustomProperties;
  } {
    return super.intoEventTelemetryHelper({
      callContext: CallContext[this.callContext],
      isInitiatingImpromptuMeeting: this.isInitiatingImpromptuMeeting,
      isJoiningInactively: this.isJoiningInactively,
      ...properties,
    });
  }
}

export class UserInvitingToCallEvent extends ICustomEvent {
  public constructor(public callContext: CallContext) {
    super('UserInvitingToCall');
  }

  public override intoEventTelemetry(properties: CustomProperties): {
    event: IEventTelemetry;
    properties: CustomProperties;
  } {
    return super.intoEventTelemetryHelper({ callContext: CallContext[this.callContext], ...properties });
  }
}

export class UserSelectedTeamEvent extends ICustomEvent {
  public constructor(
    public isNewUser: boolean,
    public isVisitor: boolean,
  ) {
    super('UserSelectedTeam');
  }

  public override intoEventTelemetry(properties: CustomProperties): {
    event: IEventTelemetry;
    properties: CustomProperties;
  } {
    return super.intoEventTelemetryHelper({ isNewUser: this.isNewUser, isVisitor: this.isVisitor, ...properties });
  }
}

export class UserCheckedOutEvent extends ICustomEvent {
  public constructor(public beRightBack: boolean) {
    super('UserCheckedOut');
  }

  public override intoEventTelemetry(properties: CustomProperties): {
    event: IEventTelemetry;
    properties: CustomProperties;
  } {
    return super.intoEventTelemetryHelper({ beRightBack: this.beRightBack, ...properties });
  }
}

export class UserSetChatMessageReactionEvent extends ICustomEvent {
  public constructor(public isActive: boolean) {
    super('UserSetChatMessageReaction');
  }

  public override intoEventTelemetry(properties: CustomProperties): {
    event: IEventTelemetry;
    properties: CustomProperties;
  } {
    return super.intoEventTelemetryHelper({ isActive: this.isActive, ...properties });
  }
}

export class UserUpdatedLocationEvent extends ICustomEvent {
  public constructor(
    public sourceBaseLocation: Location,
    public targetBaseLocation: Location,
  ) {
    super('UserUpdatedLocation');
  }

  public override intoEventTelemetry(properties: CustomProperties): {
    event: IEventTelemetry;
    properties: CustomProperties;
  } {
    return super.intoEventTelemetryHelper({
      sourceBaseLocation: this.sourceBaseLocation,
      targetBaseLocation: this.targetBaseLocation,
      ...properties,
    });
  }
}

export class UserModifiedTodoEvent extends ICustomEvent {
  public constructor(public operation: 'complete' | 'create' | 'incomplete' | 'reorder' | 'update') {
    super('UserModifiedTodo');
  }

  public override intoEventTelemetry(properties: CustomProperties): {
    event: IEventTelemetry;
    properties: CustomProperties;
  } {
    return super.intoEventTelemetryHelper({
      operation: this.operation,
      ...properties,
    });
  }
}

export class UserModifiedEmbedEvent extends ICustomEvent {
  public constructor(
    public operation: 'create' | 'update',
    public hasThumbnail: boolean,
  ) {
    super('UserModifiedEmbed');
  }

  public override intoEventTelemetry(properties: CustomProperties): {
    event: IEventTelemetry;
    properties: CustomProperties;
  } {
    return super.intoEventTelemetryHelper({
      operation: this.operation,
      hasThumbnail: this.hasThumbnail,
      ...properties,
    });
  }
}

export class UserModifiedTopicMeetingEvent extends ICustomEvent {
  public constructor(public operation: 'add' | 'remove') {
    super('UserModifiedTopicMeeting');
  }

  public override intoEventTelemetry(properties: CustomProperties): {
    event: IEventTelemetry;
    properties: CustomProperties;
  } {
    return super.intoEventTelemetryHelper({
      operation: this.operation,
      ...properties,
    });
  }
}
