import { combineLatest, map, merge, Observable, of, Subject, takeUntil } from 'rxjs';

import { TeamService } from '../../services/team.service';

import { ActiveCalls } from '../../models/services/call.model';
import { SignalRTeamInfo, SignalRTeamInfoData } from '../../models/services/signalr.model';
import { JoinedTeam, TeamInfo, TeamInfoData, TeamSettings } from '../../models/services/team.model';
import { Location, UsersNotInCall } from '../../models/services/user.model';

import { resolveUsersUpdate } from './user.util';

export function concatTeamInfoData(teamInfoData?: TeamInfoData | null): TeamInfo[] {
  if (!teamInfoData) return [];

  const teams = teamInfoData.selectableTeams.concat(teamInfoData.visitableTeams);

  if (teamInfoData.myTeam && !teams.find((t) => t.objectId === teamInfoData.myTeam!.objectId)) {
    teams.push(teamInfoData.myTeam);
  }

  return teams;
}

export function myTeamMustBeReloaded(
  myUserObjectId: string,
  oldMyTeam: TeamInfo | null | undefined,
  newMyTeam: TeamInfo | null,
  iAmInACall = false,
): boolean {
  if (!oldMyTeam || !newMyTeam) return false;

  const oldMe = oldMyTeam.users.find((u) => u.objectId === myUserObjectId);
  const newMe = newMyTeam.users.find((u) => u.objectId === myUserObjectId);
  if (!oldMe || !newMe) return false;

  const myTeamHasChanged = oldMyTeam.objectId !== newMyTeam.objectId;
  const iWasCheckedIn = oldMe.baseLocation !== Location.Inactive;
  const iAmNowCheckedIn = newMe.baseLocation !== Location.Inactive;

  return (
    (myTeamHasChanged && iAmNowCheckedIn) ||
    (!iWasCheckedIn && iAmNowCheckedIn && !iAmInACall) ||
    (iWasCheckedIn && !iAmNowCheckedIn)
  );
}

export function resolveTeamInfoUpdate(
  newTeam: TeamInfo,
  oldTeams: TeamInfo[],
  usersNotInACall?: UsersNotInCall,
  activeCalls?: ActiveCalls,
) {
  const oldTeam = oldTeams.find((t) => t.objectId === newTeam.objectId);
  if (oldTeam) resolveUsersUpdate(oldTeam.users, newTeam.users, usersNotInACall, activeCalls);
}

export function resolveTeamsInfoDataUpdate(
  newTeamInfoData: TeamInfoData,
  oldTeamInfoData: TeamInfoData,
  usersNotInACall?: UsersNotInCall,
  activeCalls?: ActiveCalls,
) {
  const myTeamHasChanged = oldTeamInfoData.myTeam?.objectId !== newTeamInfoData.myTeam?.objectId;
  if (myTeamHasChanged) oldTeamInfoData.myTeam = newTeamInfoData.myTeam;
  else resolveTeamInfoUpdate(newTeamInfoData.myTeam!, [oldTeamInfoData.myTeam!], usersNotInACall, activeCalls);

  for (const newTeam of newTeamInfoData.selectableTeams) {
    resolveTeamInfoUpdate(newTeam, oldTeamInfoData.selectableTeams, usersNotInACall, activeCalls);
  }

  for (const newTeam of newTeamInfoData.visitableTeams) {
    resolveTeamInfoUpdate(newTeam, oldTeamInfoData.visitableTeams, usersNotInACall, activeCalls);
  }
}

export function updateTeamPhotosInBackground(
  teamInfoData: TeamInfoData,
  notCreatedTeams: JoinedTeam[],
  teamService: TeamService,
  unsubscribe: Subject<void>,
) {
  getPhotos(teamInfoData, notCreatedTeams, teamService)
    .pipe(takeUntil(unsubscribe))
    .subscribe((photos) => {
      for (const photo of photos) {
        if (!photo) continue;

        if (photo.teamObjectId === teamInfoData!.myTeam?.objectId) {
          teamInfoData!.myTeam.photo = photo.base64Image;
          continue;
        }

        const teamInSelectableTeams = teamInfoData!.selectableTeams.find((t) => t.objectId === photo.teamObjectId);
        if (teamInSelectableTeams) {
          teamInSelectableTeams.photo = photo.base64Image;
          continue;
        }

        const teamInVisitableTeams = teamInfoData!.visitableTeams.find((t) => t.objectId === photo.teamObjectId);
        if (teamInVisitableTeams) {
          teamInVisitableTeams.photo = photo.base64Image;
          continue;
        }

        const teamInNotCreatedTeams = notCreatedTeams.find((t) => t.objectId === photo.teamObjectId);
        if (teamInNotCreatedTeams) {
          teamInNotCreatedTeams.photo = photo.base64Image;
          continue;
        }
      }
    });
}

export function cachedTeamSettingsAreOutdated(
  signalRTeamInfoData: SignalRTeamInfoData,
  settings: Map<string, TeamSettings>,
): boolean {
  const allTeams = signalRTeamInfoData.selectableTeams.concat(signalRTeamInfoData.visitableTeams);
  if (signalRTeamInfoData.myTeam) allTeams.push(signalRTeamInfoData.myTeam);

  const lastUpdatedDates = allTeams.map((team) => team.settingsLastUpdated.toString()).sort();
  const cachedLastUpdatedDates = [...settings.values()].map((settings) => settings.lastUpdated.toString()).sort();

  return lastUpdatedDates.join(',') !== cachedLastUpdatedDates.join(',');
}

export function getTeamSettingsMap(teamInfoData: TeamInfoData): Map<string, TeamSettings> {
  const settings = new Map<string, TeamSettings>();

  if (teamInfoData.myTeam) settings.set(teamInfoData.myTeam.objectId, teamInfoData.myTeam.settings);

  for (const selectableTeam of teamInfoData.selectableTeams) {
    settings.set(selectableTeam.objectId, selectableTeam.settings);
  }

  for (const visitableTeam of teamInfoData.visitableTeams) {
    settings.set(visitableTeam.objectId, visitableTeam.settings);
  }

  return settings;
}

export function mapSignalRTeamInfoDataToTeamInfoData(
  signalRTeamInfoData: SignalRTeamInfoData,
  settings: Map<string, TeamSettings>,
): TeamInfoData {
  let myTeam: TeamInfo | null = null;
  const signalRMyTeam = signalRTeamInfoData.myTeam;
  if (signalRMyTeam) myTeam = mapSignalRTeamInfoToTeamInfo(signalRMyTeam, settings.get(signalRMyTeam.objectId)!);

  const selectableTeams: TeamInfo[] = [];
  const signalRSelectableTeams = signalRTeamInfoData.selectableTeams;
  for (const signalRTeam of signalRSelectableTeams) {
    selectableTeams.push(mapSignalRTeamInfoToTeamInfo(signalRTeam, settings.get(signalRTeam.objectId)!));
  }

  const visitableTeams: TeamInfo[] = [];
  const signalRVisitableTeams = signalRTeamInfoData.visitableTeams;
  for (const signalRTeam of signalRVisitableTeams) {
    visitableTeams.push(mapSignalRTeamInfoToTeamInfo(signalRTeam, settings.get(signalRTeam.objectId)!));
  }

  return { myTeam, selectableTeams, visitableTeams };
}

export function getEmptyTeamInfoData(): TeamInfoData {
  return { selectableTeams: [], visitableTeams: [], myTeam: null };
}

function getPhotos(
  teamInfoData: TeamInfoData | undefined,
  notCreatedTeams: JoinedTeam[],
  teamService: TeamService,
): Observable<({ teamObjectId: string; base64Image: string | null } | null)[]> {
  if (!teamInfoData) return of([]);

  const requestFactory = (teamObjectId: string) =>
    merge(
      of(null),
      teamService.getPhoto(teamObjectId).pipe(map((photo) => ({ teamObjectId: teamObjectId, base64Image: photo }))),
    );

  let teams: { objectId: string; photo?: string | null }[] = concatTeamInfoData(teamInfoData);
  teams = teams.concat(notCreatedTeams);

  const requests = [];
  for (const team of teams) if (!team.photo) requests.push(requestFactory(team.objectId));

  return combineLatest(requests);
}

function mapSignalRTeamInfoToTeamInfo(signalRTeamInfo: SignalRTeamInfo, teamSettings: TeamSettings): TeamInfo {
  const team: TeamInfo = { ...signalRTeamInfo, settings: teamSettings };

  return team;
}
