import { Injectable } from '@angular/core';
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';

import { concatMap, forkJoin, map, Observable, of, throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs';

import { OnlineMeetingService } from './online-meeting.service';
import { UserService } from './user.service';

import { ActiveCalls, Call, CallContext } from '../models/services/call.model';

import { includeScalingRetryStrategy } from '../utils/rxjs.util';
import { geDesksCallSubject, getKitchenCallSubject } from '../utils/services/call.util';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CallService {
  public constructor(
    private readonly http: HttpClient,
    private readonly userService: UserService,
    private readonly onlineMeetingService: OnlineMeetingService,
  ) {}

  public createOrGetCall(
    context: CallContext.BreakoutMeeting | CallContext.Desks | CallContext.Kitchen | CallContext.TopicMeeting,
    // `subject` only applies when creating a new call
    subject: string,
    // `index` is only relevant for main table and breakout meetings since they are visually sorted by index
    index?: number,
  ): Observable<{ joinWebUrl: string }> {
    if (index !== undefined && context !== CallContext.TopicMeeting && context !== CallContext.BreakoutMeeting) {
      throw new Error(`Index is only relevant for topic and breakout meetings`);
    }

    const myCurrentTeamObjectId = this.userService.userInfo!.user.currentTeamObjectId!;
    const getCall = this.getCall(context, myCurrentTeamObjectId, index).pipe(
      map((c) => ({ joinWebUrl: c.joinWebUrl! })),
    );

    return getCall.pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.NotFound) {
          return this.onlineMeetingService
            .create(context, subject, this.userService.userInfo!.user.currentTeamObjectId!, undefined, index)
            .pipe(
              map((m) => ({ joinWebUrl: m.joinWebUrl! })),
              catchError((error: HttpErrorResponse) => {
                if (error.status === HttpStatusCode.Conflict) {
                  return getCall.pipe(
                    retryWhen(
                      includeScalingRetryStrategy({
                        includedStatusCodes: [HttpStatusCode.NotFound],
                        maxRetryAttempts: 6,
                        scalingDuration: 500,
                      }),
                    ),
                  );
                }

                return throwError(error);
              }),
            );
        }

        throw error;
      }),
    );
  }

  public getActiveCalls(teamObjectId: string): Observable<ActiveCalls> {
    return this.http.get<ActiveCalls>(`${environment.backendUrl}/api/call/active-calls?teamObjectId=${teamObjectId}`);
  }

  public inviteParticipant(joinWebUrl: string, targetUserObjectId: string): Observable<void> {
    return this.http
      .post<void>(`${environment.backendUrl}/api/call/invite-participant`, {
        joinWebUrl,
        targetUserObjectId,
      })
      .pipe(
        retryWhen(
          includeScalingRetryStrategy({
            includedStatusCodes: [HttpStatusCode.ServiceUnavailable],
            maxRetryAttempts: 5,
            scalingDuration: 1000,
          }),
        ),
      );
  }

  public joinEventInactively(data: { eventId: string; iCalUId?: string | null }): Observable<void> {
    return this.onlineMeetingService
      .getOrCreateOnlineMeeting({ eventId: data.eventId, iCalUId: data.iCalUId })
      .pipe(concatMap((onlineMeeting) => this.joinInactively(onlineMeeting.callIdentifier!)));
  }

  public joinInactively(callIdentifier: string): Observable<void> {
    return this.http.post<void>(`${environment.backendUrl}/api/call/join-inactively`, {
      displayName: this.userService.userInfo!.user.displayName!,
      callIdentifier,
    });
  }

  public createEternalCalls(
    activeCalls: ActiveCalls,
    teamDisplayName: string,
    sharedKitchen: boolean,
  ): Observable<{ joinWebUrl: string; context: CallContext }[]> {
    const desksCallRequests$ =
      activeCalls.desks === null
        ? this.createOrGetCall(CallContext.Desks, geDesksCallSubject(teamDisplayName)).pipe(
            map(({ joinWebUrl }) => ({ joinWebUrl, context: CallContext.Desks })),
          )
        : null;

    const kitchenCallRequests$ =
      activeCalls.kitchen === null
        ? this.createOrGetCall(CallContext.Kitchen, getKitchenCallSubject(teamDisplayName, sharedKitchen)).pipe(
            map(({ joinWebUrl }) => ({ joinWebUrl, context: CallContext.Kitchen })),
          )
        : null;

    const requests$ = [];

    if (desksCallRequests$) requests$.push(desksCallRequests$);
    if (kitchenCallRequests$) requests$.push(kitchenCallRequests$);

    if (requests$.length === 0) return of([]);

    return forkJoin(requests$);
  }

  // `index` is only relevant for main table and breakout meetings since they are visually sorted by index
  private getCall(context: CallContext, teamObjectId: string, index?: number): Observable<Call> {
    if (index !== undefined && context !== CallContext.TopicMeeting && context !== CallContext.BreakoutMeeting) {
      throw new Error(`Index is only relevant for topic and breakout meetings`);
    }

    const encodedContext = encodeURIComponent(context);
    const encodedTeamObjectId = encodeURIComponent(teamObjectId);
    const encodedIndex = index === undefined ? '' : index;

    return this.http.get<Call>(
      `${environment.backendUrl}/api/call?context=${encodedContext}&teamObjectId=${encodedTeamObjectId}&index=${encodedIndex}`,
    );
  }
}
