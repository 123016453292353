import { Pipe, PipeTransform } from '@angular/core';

import { FrontendAvailability, OutOfOfficeSettings } from '../../../../models/services/user.model';

@Pipe({
  name: 'getAvailabilityColor',
  standalone: true,
})
export class GetAvailabilityColorPipe implements PipeTransform {
  public transform(availability: FrontendAvailability, outOfOfficeSettings?: OutOfOfficeSettings): string {
    if (outOfOfficeSettings?.isOutOfOffice) {
      return 'var(--teams-magenta)';
    }

    switch (availability) {
      case FrontendAvailability.Available:
        return 'var(--teams-green)';
      case FrontendAvailability.Away:
        return 'var(--teams-yellow)';
      case FrontendAvailability.BeRightBack:
        return 'var(--teams-yellow)';
      case FrontendAvailability.Busy:
        return 'var(--teams-red)';
      case FrontendAvailability.DoNotDisturb:
        return 'var(--teams-red)';
      case FrontendAvailability.InACall:
      case FrontendAvailability.Presenting:
        return 'var(--teams-red)';
      default:
        return 'var(--teams-grey)';
    }
  }
}
